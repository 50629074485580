import instance from "../../../../services/api";

export const getRequests = async body => {
  return await instance(true)
    .post("partner/provider_requests/", body)
    .then(res => res?.data);
};
export const getDetailRequest = async provider_request_id => {
  return await instance(true)
    .get(`partner/provider_requests/${provider_request_id}/`)
    .then(res => res?.data);
};
export const getResidents = async () => {
  return await instance(true)
    .get(`partner/commons/resident_list/`)
    .then(res => res?.data);
};
export const getRequestStatuses = async () => {
  return await instance(true)
    .get(`partner/commons/provider_request_statuses/`)
    .then(res => res?.data);
};
export const getRemontStatuses = async () => {
  return await instance(true)
    .get(`partner/commons/remont_statuses/`)
    .then(res => res?.data);
};
export const getRequestShipHistory = async requestId => {
  return await instance(true)
    .get(`partner/provider_requests/${requestId}/shipping_hist/`)
    .then(res => res?.data);
};
export const getRequestStatusesHistory = async requestId => {
  return await instance(true)
    .get(`partner/provider_requests/${requestId}/item_hist/`)
    .then(res => res?.data);
};
export const getRequestItemStatuses = async () => {
  return await instance(true)
    .get(`/partner/commons/provider_request_statuses/specific/`)
    .then(res => res?.data);
};
