import React from "react";
import styles from "./styles.module.scss";
import { CustomDatePicker } from "../../components/common/form/CustomDatePicker";
import { CButton } from "../../components/common/buttons";

export const JurDateWarrantyFilters = ({
                                         filters, onChange, loading,
                                         getData
                                       }) => {

  return <div className={styles.filters}>
    <div className={styles.filters__item}>
      <CustomDatePicker
        fullWidth
        label="Дата начала"
        placeholder="Дата начала"
        value={filters.warranty_date_begin}
        onChange={e => onChange("warranty_date_begin", e.target.value)}
      />
    </div>
    <div className={styles.filters__item}>
      <CustomDatePicker
        fullWidth
        label="Дата конца"
        placeholder="Дата конца"
        value={filters.warranty_date_end}
        onChange={e => onChange("warranty_date_end", e.target.value)}
      />
    </div>
    <CButton mode={"auto"} loading={loading} onClick={getData}>Поиск</CButton>
  </div>;
};