import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getCheckListPhotos } from "../services/api";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../components/common/CustomTable/CustomTable";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { MAIN_URL } from "../../../constants";

const useStyles = makeStyles({
  photo_link: {
    display: "block",
    width: "100%",
    height: "100%"
  },
  photo_wrapper: {
    width: 150,
    height: 200
  },
  photo: {
    width: "100%",
    height: "100%",
    objectFit: "contain"
  }
});

const headerTitles = [
  { label: "ID" },
  { label: "Дата" },
  { label: "ОКК" },
  { label: "Коментарии" },
  { label: "Фото" },
  { label: "Статус" }
];

const CheckListModal = ({ data }) => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    getCheckListPhotos(data.remontId, data.checklistId)
      .then(res => {
        setPhotos(res.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [data.remontId, data.checklistId, addCloseSnack]);

  return (
    <CustomTable headItems={headerTitles} withMaxHeight={false}>
      {photos.map((el, i) => {
        return (
          <CustomTableRow key={el.file_name}>
            <CustomTableCell style={{ verticalAlign: "top" }}>
              {i + 1}
            </CustomTableCell>
            <CustomTableCell style={{ verticalAlign: "top" }}>
              {el.date_create}
            </CustomTableCell>
            <CustomTableCell style={{ verticalAlign: "top" }}>
              {el.fio}
            </CustomTableCell>
            <CustomTableCell style={{ verticalAlign: "top" }}>
              {el.comments}
            </CustomTableCell>
            <CustomTableCell style={{ verticalAlign: "top" }}>
              <a
                href={`${MAIN_URL}${el.content_url}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.photo_link}
              >
                <div
                  style={{ width: 150, height: 200 }}
                  className={classes.photo_wrapper}
                >
                  <img
                    src={`${MAIN_URL}${el.content_url}`}
                    alt="check_list_modal_ig"
                    className={classes.photo}
                  />
                </div>
              </a>
            </CustomTableCell>
            <CustomTableCell style={{ verticalAlign: "top" }}>
              {el.is_accepted === 0 ? "Не принято" : "Принято"}
            </CustomTableCell>
          </CustomTableRow>
        );
      })}
    </CustomTable>
  );
};

export default CheckListModal;
