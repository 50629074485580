import instance from "../../services/api";

export const getUserProfileInfo = () => {
  return instance(true)
    .get("/user-info/")
    .then(res => res?.data);
};

export const acceptChangesInUserSettings = params => {
  return instance(true).put("/auth/change-details/", params);
};
