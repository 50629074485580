import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import reducers from "./index";
import thunk from "redux-thunk";

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers
    }),
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );
}
