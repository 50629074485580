import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useForm } from "react-hook-form";
import { useHttp } from "../../../utils/hooks/http.hook";
import { CButton } from "../../../components/common/buttons";
import { CustomDatePicker } from "../../../components/common/form/CustomDatePicker";
import { InputWarning } from "../../../components/common/form/warnings";
import { AutoSuggest } from "../../../components/common/form/AutoSuggest";
import CustomInput from "../../../components/common/form/CustomInput";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { getConvertedValue, getMaterials, getMaterialTypes, getRequestReasons } from "./api";
import { useParams } from "react-router-dom";
import { CustomTable, CustomTableCell, CustomTableRow } from "../../../components/common/CustomTable/CustomTable";
import { CustomImage } from "../../../components/common/blocks/ImgWrapper";
import NumberFormat from "react-number-format";
import { InputSumMask } from "../../../components/common/form/InputSumMask";
import { showRightDrawerAction } from "../../../services/redux/actions/remonts-actions";
import { useDispatch } from "react-redux";
import { Checkbox } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { CustomSearch } from "../../../components/common/form/CustomSearch";

export const AddRequest = ({ show }) => {
  const dispatch = useDispatch();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors }
  } = useForm();
  const { request, loading } = useHttp();
  const { addDefaultSnack, addCloseSnack } = useCustomSnackbar();
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [materialTypes, setMaterialTypes] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [search, setSearch] = useState("");
  const [isActualData, setIsActualData] = useState(true);

  const reason_id = watch("reason_id");
  const material_type_id = watch("material_type_id");

  useEffect(() => {
    if (!show) {
      reset();
      setMaterials([]);
    }
  }, [show, reset]);

  useEffect(() => {
    const getReasons = async () => {
      try {
        const res = await getRequestReasons();
        setReasons(res);
      } catch (e) {}
    };
    getReasons();
  }, []);

  useEffect(() => {
    const getMaterialTypesData = async () => {
      try {
        const res = await getMaterialTypes();
        setMaterialTypes(res);
      } catch (e) {}
    };
    getMaterialTypesData();
  }, []);

  useEffect(() => {
    const getMaterialsData = async () => {
      try {
        setIsFetching(true);
        const res = await getMaterials({
          remont_id,
          reason_id,
          material_type_id: material_type_id || ""
        });
        setIsFetching(false);
        if (res) setMaterials(res);
      } catch (e) {
        setIsFetching(false);
      }
    };
    if (reason_id) getMaterialsData();
  }, [reason_id, material_type_id, remont_id]);

  const onSubmit = async body => {
    const provider_request_material_ids = materials
      ?.filter(item => !!item?.is_checked)
      .map(item => item?.provider_request_material_id);
    if (!provider_request_material_ids?.length)
      return addCloseSnack("Выберите материалы!");

    const res = await request("/partner/request_additional/create/", "post", {
      ...body,
      remont_id,
      provider_request_material_ids
    });
    if (res) {
      dispatch(showRightDrawerAction(false));
      addDefaultSnack("Заявка успешно создана");
    }
  };

  const handleChange = async (value, provider_request_material_id, name) => {
    setIsActualData(false);
    setMaterials(prev =>
      prev?.map(item => {
        if (item?.provider_request_material_id === provider_request_material_id)
          return { ...item, [name]: value, is_checked: true };
        return item;
      })
    );
  };

  const getMeasureData = useCallback(
    async (material_id, provider_request_material_id, type) => {
      if (isActualData) return;
      const rowName = type === "atom" ? "qtyAtom" : "qtyConsume";
      const value = materials?.find(
        item =>
          item?.provider_request_material_id === provider_request_material_id
      )[rowName];
      const body = {
        material_cnt: value || 0,
        remont_id,
        type,
        provider_request_material_id,
        material_id
      };
      try {
        const res = await getConvertedValue(body);
        if (res)
          setMaterials(prev =>
            prev?.map(item => {
              if (
                item?.provider_request_material_id ===
                provider_request_material_id
              )
                return {
                  ...item,
                  qtyConsume: res?.qty_consume || 0,
                  qtyAtom: res?.qty_atom || 0
                };
              return item;
            })
          );
      } catch (e) {
        const { error } = e?.response?.data;
        !!error && addCloseSnack(error);
      }
    },
    [materials, remont_id, isActualData, addCloseSnack]
  );

  const onCheckChange = (provider_request_material_id, is_checked) => {
    setMaterials(prev =>
      prev.map(item => {
        if (item?.provider_request_material_id === provider_request_material_id)
          return { ...item, is_checked };
        return item;
      })
    );
  };

  const handleCheckAll = is_checked => {
    setMaterials(prev =>
      prev.map(item => {
        return { ...item, is_checked };
      })
    );
  };

  const checkAll = useMemo(() => !!materials?.every(item => item?.is_checked), [
    materials
  ]);

  const handleSearchChange = e => {
    setSearch(e?.target.value);
  };

  const computedMaterials = useMemo(() => {
    if (!search) return materials;
    const searchLower = search.toLowerCase();
    return materials?.filter(
      item =>
        item?.material_orig_name?.toLowerCase().includes(searchLower) ||
        item?.material_name_extra?.toLowerCase().includes(searchLower)
    );
  }, [search, materials]);

  const checkedMaterialsCount = useMemo(() => {
    return materials?.filter(item => item?.is_checked)?.length;
  }, [materials]);

  const headItems = [
    {
      label: "",
      render: () => (
        <Checkbox
          id={"select_all_items"}
          checked={!!checkAll}
          onChange={e => handleCheckAll(!!e?.target?.checked)}
        />
      )
    },
    {
      label: "",
      render: () => (
        <>
          ID
          <br /> Материала
        </>
      )
    },
    { label: "Фото" },
    { label: "Название материала" },
    { label: "У постовщика" },
    { label: "Статус" },
    { label: "Цена за одну единицу" },
    { label: "Кол-во в ед.расхода" },
    { label: "Кол-во в мин.ед" }
  ];
  return (
    <div className={classes.wrapper}>
      <div>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formItem}>
            <Controller
              name="date_shipping_plan"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  fullWidth
                  label="Дата отгрузки"
                  placeholder="Введите дату"
                  value={value}
                  onChange={onChange}
                />
              )}
              defaultValue={""}
              rules={{ required: true }}
            />
            {errors.date_shipping_plan && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
          <div className={classes.formItem}>
            <Controller
              name="reason_id"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <AutoSuggest
                    withMargin
                    list={reasons}
                    showKey={"reason_name"}
                    valueKey={"provider_request_additional_reason_id"}
                    placeholder="Выберите причину"
                    label="Причина"
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
              defaultValue={""}
              rules={{
                required: true
              }}
            />
            {errors.reason_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
          <div className={classes.formItem}>
            <Controller
              name="material_type_id"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <AutoSuggest
                    withMargin
                    list={materialTypes}
                    showKey={"material_type_name"}
                    valueKey={"material_type_id"}
                    placeholder="Выберите тип материала"
                    label="Тип материала"
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
              defaultValue={""}
            />
          </div>
          <div className={classes.formItem}>
            <Controller
              name="comment"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomInput
                  type="text"
                  fullWidth
                  maxRows="3"
                  multiline
                  label="Комментарий"
                  placeholder="Введите комментарий"
                  value={value}
                  onChange={onChange}
                />
              )}
              defaultValue={""}
            />
          </div>
          <div className={classes.submit_btn_wrapper}>
            <CButton loading={loading} type={'submit'} disabled={loading || !materials?.length}>
              Создать заявку
            </CButton>
          </div>
        </form>

        <Box width={300} mt={2}>
          <CustomSearch
            value={search}
            placeholder={"Введите название"}
            handleChange={handleSearchChange}
          />
        </Box>
        {!!materials?.length && (
          <p className={classes.checkedCount}>
            Выбрано материалов: {checkedMaterialsCount || 0}
          </p>
        )}
        <div className={classes.table}>
          <CustomTable
            stickyHeader
            headItems={headItems}
            isFetching={isFetching}
            isEmpty={!materials?.length}
          >
            {computedMaterials?.map(item => {
              return (
                <CustomTableRow key={item?.provider_request_material_id}>
                  <CustomTableCell align={"center"}>
                    <Checkbox
                      checked={!!item?.is_checked}
                      onChange={e =>
                        onCheckChange(
                          item?.provider_request_material_id,
                          !!e?.target?.checked
                        )
                      }
                    />
                  </CustomTableCell>
                  <CustomTableCell align={"center"}>
                    {item?.material_id}
                  </CustomTableCell>
                  <CustomTableCell align={"center"} width={70}>
                    <CustomImage
                      src={item?.photo_url}
                      className={classes.photo}
                    />
                  </CustomTableCell>
                  <CustomTableCell>{item?.material_orig_name}</CustomTableCell>
                  <CustomTableCell>{item?.material_name_extra}</CustomTableCell>
                  <CustomTableCell>
                    {item?.provider_request_status_name}
                  </CustomTableCell>
                  <CustomTableCell align={"right"}>
                    <NumberFormat
                      value={item?.price}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                    />
                  </CustomTableCell>
                  <CustomTableCell align={"center"}>
                    <InputSumMask
                      type="number"
                      fullWidth
                      placeholder={`0 ${item?.atom_unit_name}`}
                      label={""}
                      suffix={` ${item?.atom_unit_name}`}
                      className={classes.inputNumber}
                      value={item?.qtyAtom}
                      onEnter={() => {
                        setIsActualData(true);
                        getMeasureData(
                          item?.material_id,
                          item?.provider_request_material_id,
                          "atom"
                        );
                      }}
                      onBlur={() =>
                        getMeasureData(
                          item?.material_id,
                          item?.provider_request_material_id,
                          "atom"
                        )
                      }
                      onChange={e => {
                        if (e.target.value === item?.qtyAtom) return;
                        handleChange(
                          e.target.value,
                          item?.provider_request_material_id,
                          "qtyAtom"
                        );
                      }}
                    />
                  </CustomTableCell>
                  <CustomTableCell align={"center"}>
                    <InputSumMask
                      type="number"
                      fullWidth
                      placeholder={`0 ${item?.consume_unit_name}`}
                      label={""}
                      suffix={` ${item?.consume_unit_name}`}
                      className={classes.inputNumber}
                      value={item?.qtyConsume}
                      onEnter={() => {
                        setIsActualData(true);
                        getMeasureData(
                          item?.material_id,
                          item?.provider_request_material_id,
                          "consume"
                        );
                      }}
                      onBlur={() =>
                        getMeasureData(
                          item?.material_id,
                          item?.provider_request_material_id,
                          "consume"
                        )
                      }
                      onChange={e => {
                        if (e.target.value === item?.qtyConsume) return;
                        handleChange(
                          e.target.value,
                          item?.provider_request_material_id,
                          "qtyConsume"
                        );
                      }}
                    />
                  </CustomTableCell>
                </CustomTableRow>
              );
            })}
          </CustomTable>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: 15
  },
  form: {
    display: 'flex',
    flexDirection: "column",
    gap: "15px",
    paddingBottom: '0 !important'
  },
  photo: {
    maxWidth: 55,
    maxHeight: 80,
    width: 55,
    height: 80
  },
  table: {
    marginTop: 15
  },
  inputNumber: {
    "& .MuiInputBase-input": {
      textAlign: "center"
    }
  },
  submit_btn_wrapper: {
    display: "flex",
    justifyContent: "center"
  },
  checkedCount: {
    fontSize: 16,
    fontWeight: "bold"
  }
}));
