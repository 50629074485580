import {
  SET_REMONTS_REPORT,
  GET_RESIDENT,
  GET_RESIDENTS_FILTER,
  GET_RESIDENTS_LIST, RESET_REMONT_STATE,
  RESET_RESIDENT,
  SET_LOADING,
  SET_MASTER_CONSTRACTORS,
  SET_MASTERS_LIST,
  SET_MESSAGES,
  SET_PROJECTS,
  SET_REMONTS_PAYMENTS,
  SET_STATUSES_LIST,
  SHOW_RIGHT_DRAWER,
  UPDATE_REMONT_WORK_VALUES,
} from "../actions/remonts-actions";

const initialState = {
  residents: [],
  residentsFilter: [],
  resident: {},
  masters: [],
  loading: false,
  showRightDrawer: {
    show: false,
    data: {}
  },
  constractors: [],
  remontReports: [],
  projects: [],
  statusesList: [],
  messages: [],
  payments: [],
};

export const remontsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_REMONT_STATE:
      return { ...state, ...initialState };
    case GET_RESIDENTS_LIST:
      return { ...state, residents: payload, loading: false };
    case UPDATE_REMONT_WORK_VALUES: {
      const edited = state?.residents?.map(item => {
        if (item?.resident_id === payload?.resident_id) {
          const remontObj = payload?.remonts?.length ? payload?.remonts[0] : {};
          return {
            ...item,
            remonts: item?.remonts?.map(remont => {
              if (remont?.remont_id === remontObj.remont_id) return remontObj;
              return remont;
            })
          };
        }
        return item;
      });
      return { ...state, residents: edited };
    }
    case SET_MASTER_CONSTRACTORS:
      return { ...state, constractors: payload };
    case SET_MASTERS_LIST:
      return { ...state, masters: payload };
    case GET_RESIDENTS_FILTER:
      return { ...state, residentsFilter: payload };
    case GET_RESIDENT:
      return { ...state, resident: payload };
    case RESET_RESIDENT:
      return { ...state, resident: {} };
    case SET_LOADING:
      return { ...state, loading: payload };
    case SHOW_RIGHT_DRAWER:
      return {
        ...state,
        showRightDrawer: payload
      };
    case SET_REMONTS_REPORT:
      return { ...state, remontReports: payload };
    case SET_PROJECTS:
      return { ...state, projects: payload };
    case SET_STATUSES_LIST:
      return { ...state, statusesList: payload };
    case SET_MESSAGES:
      return { ...state, messages: payload };
    case SET_REMONTS_PAYMENTS:
      return { ...state, payments: payload };
    default:
      return state;
  }
};
