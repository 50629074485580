import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, List, ListItemText, ListSubheader, Paper, Tooltip, Typography } from "@material-ui/core";
import EmptyOrShow from "components/common/EmptyOrShow";
import clsx from "clsx";
import { getResidents, getResidentsForFilter } from "../../../services/redux/actions/remonts-actions";
import { PageTitle } from "components/common/pageTitle";
import { getStagesList } from "../services/api";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { WrapperScrollbar } from "../../../components/common/WrapperScrollbar";
import { NotFound } from "../../../components/common/text";
import { AutoSuggest } from "../../../components/common/form/AutoSuggest";
import { CustomSearchBar } from "../../../components/common/form/CustomSearchBar";
import { makeStyles } from "@material-ui/core/styles";
import { getStageName, serviceStatuses, serviceStatusLabels, serviceStatusOptions } from "./services";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { HtmlTooltip } from "../../../components/common/HtmlTooltip";
import { CollapseBlock } from "../../../components/common/CollapseBlock/collapseBlock";

export const ComplexesList = ({
                                pageTitle,
                                remontRoute,
                                min = false,
                              }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const query = useParams();
  const statusId = query?.remont_status;
  const { dataUser } = useSelector(state => state.appState);
  const { residentsFilter, residents } = useSelector(
    state => state.remontsState
  );
  const [stages, setStages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeRemontId, setActiveRemontId] = useState(
    Number(query?.remont_id)
  );
  const [filters, setFilters] = useState({
    search: "",
    residentId: "",
    stageId: "",
    service_status: null
  });

  useEffect(() => {
    setActiveRemontId(Number(query?.remont_id));
  }, [query.remont_id]);

  useEffect(() => {
    getStagesList().then(res => {
      setStages(res.data);
    });
  }, []);

  useEffect(() => {
    dispatch(getResidentsForFilter(statusId || 1));
  }, [statusId, dispatch]);

  useEffect(() => {
    dispatch(getResidents(statusId || 1, filters.stageId, filters.service_status, setLoading));
  }, [filters.stageId, filters.service_status, dispatch, statusId]);

  const residentsState = useMemo(() => {
    let edited = residents ? [...residents] : [];
    if (filters.residentId && filters.residentId !== "Все жилые комплексы") {
      edited = edited.filter(item => item?.resident_id === filters.residentId);
    }
    if (!!filters.search) {
      edited = edited.map(item => {
        return {
          ...item,
          remonts: item?.remonts.filter(
            item =>
              item?.flat_num?.includes(filters.search) ||
              String(item?.remont_id)?.includes(filters.search)
          )
        };
      });
      edited = edited.filter(item => !!item?.remonts?.length);
    }
    return edited;
  }, [filters.search, filters.residentId, residents]);

  const handleChangeFilter = (key, value) => {
    if (value === "ALL")
      return setFilters(prev => ({ ...prev, [key]: null }));
    return setFilters(prev => ({ ...prev, [key]: value }));
  };

  const totalRemonts = useMemo(
    () => residentsState?.reduce((acc, curr) => acc + curr.remonts.length, 0),
    [residentsState]
  );

  const onRemontClick = remontId => {
    setActiveRemontId(remontId);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      className={clsx(classes.wrapper)}
    >
      <PageTitle
        title={pageTitle || `Ремонты ${totalRemonts || ""}`}
      />
      <div className={classes.content}>
        <CollapseBlock tabletMode className={classes.filters}>
          <Box>
            <CustomSearchBar
              value={filters.search}
              onChange={value => handleChangeFilter("search", value)}
              className={classes.search}
            />
            <Box
              mt="10px"
              className={clsx(classes.filterItem, {
                [classes.filterItemMin]: min
              })}
            >
              <Typography className={classes.title}>Жилой комплекс:</Typography>
              <FormControl
                className={clsx(classes.selectWrapper, {
                  [classes.selectWrapperMin]: min
                })}
              >
                <AutoSuggest
                  id="residents-select"
                  list={residentsFilter}
                  value={filters?.residentId}
                  showKey={"resident_name"}
                  min={min}
                  valueKey={"resident_id"}
                  placeholder="Выберите ЖК"
                  className={classes.select}
                  label="Выберите ЖК"
                  onChange={e => {
                    const { value } = e.target;
                    handleChangeFilter(
                      "residentId",
                      Number(value) || "Все жилые комплексы"
                    );
                  }}
                />
              </FormControl>
            </Box>
            <Box
              mt="10px"
              mb="10px"
              className={clsx(classes.filterItem, {
                [classes.filterItemMin]: min
              })}
            >
              <Typography className={classes.title}>Этап:</Typography>
              <FormControl
                className={clsx(classes.selectStage, {
                  [classes.selectWrapperMin]: min
                })}
              >
                <AutoSuggest
                  id="stages-select"
                  list={stages}
                  value={filters?.stageId}
                  showKey={"stage_name"}
                  valueKey={"stage_id"}
                  placeholder="Выберите этап"
                  className={classes.select}
                  label="Выберите этап"
                  onChange={e => handleChangeFilter("stageId", e.target.value)}
                />
              </FormControl>
            </Box>
            {dataUser?.is_service_master && <Box
              mt="10px"
              mb="10px"
              className={clsx(classes.filterItem, {
                [classes.filterItemMin]: min
              })}
            >
              <Typography className={classes.title}>Статус услуги:</Typography>
              <FormControl
                className={clsx(classes.selectStage, {
                  [classes.selectWrapperMin]: min
                })}
              >
                <AutoSuggest
                  id="stages-select"
                  list={serviceStatusOptions}
                  value={filters?.service_status === null ? 'ALL' : filters?.service_status}
                  showKey={"label"}
                  valueKey={"value"}
                  placeholder="Выберите статус"
                  className={classes.select}
                  label="Выберите статус"
                  onChange={e => handleChangeFilter("service_status", e.target.value)}
                />
              </FormControl>
            </Box>}
          </Box>
        </CollapseBlock>
        <WrapperScrollbar className={classes.nav}>
          <EmptyOrShow loading={loading}>
            {!!residentsState?.length ? (
              residentsState?.map((item, ind) => {
                return (
                  <List
                    component="nav"
                    key={ind}
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader
                        component="div"
                        className={classes.listHeader}
                        id="nested-list-subheader"
                      >
                        {item?.resident_name}
                      </ListSubheader>
                    }
                  >
                    {item?.remonts.map((el, idx) => {
                      return (
                        <Paper
                          data-index={idx}
                          key={el.remont_id}
                          elevation={0}
                          className={clsx(classes.paper, {
                            [classes.onSelected]:
                            activeRemontId === el.remont_id
                          })}
                          onClick={() => onRemontClick(el.remont_id)}
                        >
                          {el.errors?.length && (
                            <HtmlTooltip
                              title={
                                <div>
                                  {el.errors.map(item => (
                                    <p style={{ margin: 0 }} key={item}>
                                      {item}
                                    </p>
                                  ))}
                                </div>
                              }
                              aria-label="attached masters"
                            >
                              <div className={classes.warning}>
                                <ReportProblemIcon />
                              </div>
                            </HtmlTooltip>
                          )}
                          <Link
                            to={`${remontRoute}/${el.remont_id}`}
                            className={classes.link}
                          >
                            <div className={classes.listItem}>
                              <ListItemText
                                primary={
                                  <Typography>
                                    {`КВ ${el.flat_num} (ID: ${el.remont_id})`}{" "}
                                    <span className={classes.stage}>
                                      {getStageName(el.stage_id)}
                                      {!!el.service_status && (
                                        <span
                                          className={
                                            classes[
                                              `stage__${el.service_status ||
                                              "PROCESSING"}`
                                              ]
                                          }
                                        >
                                          {` | ${serviceStatusLabels[
                                            el.service_status
                                            ] || ""}`}
                                        </span>
                                      )}
                                    </span>
                                  </Typography>
                                }
                              />

                              <div>
                                <Typography style={{ whiteSpace: "nowrap" }}>
                                  {" "}
                                  {el.done} из {el.out_of}
                                </Typography>
                                {(!!el.service_out_of || !!el.service_done) && (
                                  <Tooltip title="Услуги">
                                    <div className={classes.serviceText}>
                                      ({el.service_done || 0} из{" "}
                                      {el.service_out_of || 0})
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </Link>
                        </Paper>
                      );
                    })}
                  </List>
                );
              })
            ) : (
              <NotFound />
            )}
          </EmptyOrShow>
        </WrapperScrollbar>
      </div>
    </Box>
  );
};

export const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#9194A9",
    marginRight: "10px"
  },
  nav: {
    flexGrow: 1,
    overflowY: "scroll",
    padding: "0 10px 0 10px",
    minHeight: "380px"
  },
  paper: {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#fff",
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)",
    borderRadius: " 4px",
    marginTop: "10px",
    marginBottom: "10px",
    cursor: "pointer",
    transition: "all .3s",
    "&:hover": {
      boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.22)",
      backgroundColor: "rgba(243, 243, 243, .0)"
    }
  },
  selectWrapper: {
    maxWidth: 220,
    width: "100%"
  },
  warning: {
    position: "absolute",
    right: "-12px",
    top: "-19px",
    height: "45px",
    width: "30px",
    background: "#d76f2f",
    transform: "rotate(-45deg)",
    "& > svg": {
      zIndex: 0,
      fill: "#ffffff",
      fontSize: "12px",
      transform: "rotate(45deg)",
      marginTop: "15px",
      marginLeft: "2px"
    }
  },
  selectStage: {
    maxWidth: 150,
    width: "100%"
  },
  selectWrapperMin: {
    maxWidth: "100%"
  },
  select: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#0C4B86",
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },
  onSelected: {
    backgroundColor: "#D4D9F8",
    "&:hover": {
      backgroundColor: "#cdd2f8"
    }
  },
  link: {
    textDecoration: "none",
    color: "unset"
  },
  filters: {marginBottom: 10},
  search: {
    background: "#FFFFFF",
    boxShadow: "0px 5px 10px rgba(108, 108, 108, 0.1)",
    borderRadius: "4px",
    margin: "20px 10px",
    height: 48
  },
  wrapper: { height: "100vh", borderRight: "1px solid #D2D3D9" },
  filterItem: {
    padding: "0 10px 0 10px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "flex-end",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  filterItemMin: {
    flexDirection: "column",
    alignItems: "normal",
    justifyContent: "space-between",
    marginBottom: 15
  },
  stage: {
    display: "block",
    fontSize: 12,
    color: "#9396AF"
  },
  [`stage__${serviceStatuses.DONE}`]: {
    fontSize: 12,
    color: "#0aa116"
  },
  [`stage__${serviceStatuses.WAITING}`]: {
    fontSize: 12,
    color: "#c70b0b"
  },
  [`stage__${serviceStatuses.PROCESSING}`]: {
    fontSize: 12,
    color: "#ce920f"
  },
  serviceText: {
    fontSize: "12px",
    textAlign: "right"
  },
  listHeader: {
    position: "relative",
    lineHeight: "18px"
  },
  listItem: {
    padding: "5px 16px",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 576px)": {
      padding: "5px 8px"
    }
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 64px)",
    minWidth: 220
  },
}));
