import React from "react";
import { Box, Collapse } from "@material-ui/core";
import RowItem from "./RowItem";
import { useStyles } from "./AddWorksTable";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { CustomTable, CustomTableCell, CustomTableRow } from "../../../components/common/CustomTable/CustomTable";

const AddWorksRow = ({
  ds_wset_works,
  room_name,
  wset_cnt,
  wset_name,
  wset_price,
  wset_sum,
  index,
  posCode
}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const iconStyles = {
    color: "#659DF1",
    display: "inline-block",
    marginLeft: 9,
    transform: "translateY(3px)"
  };

  return (
    <>
      <CustomTableRow
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
        className={open ? classes.active_row : ""}
      >
        <CustomTableCell className={classes.cell_sm}>
          {index + 1}
        </CustomTableCell>
        <CustomTableCell className={classes.cell_name}>
          {wset_name}
          {open ? (
            <KeyboardArrowUpIcon style={iconStyles} />
          ) : (
            <KeyboardArrowDownIcon style={iconStyles} />
          )}
        </CustomTableCell>
        <CustomTableCell className={classes.cell} align="center">
          {room_name}
        </CustomTableCell>
        <CustomTableCell className={classes.cell} align="right">
          {wset_cnt}
        </CustomTableCell>
        {posCode === "CONTRACTOR" ? (
          <>
            <CustomTableCell className={classes.cell} align="right">
              {wset_price} тг
            </CustomTableCell>
            <CustomTableCell className={classes.cell} align="right">
              {wset_sum} тг
            </CustomTableCell>
          </>
        ) : null}
      </CustomTableRow>
      <CustomTableRow>
        <CustomTableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <CustomTable>
                {ds_wset_works?.map(el => {
                  return (
                    <RowItem key={el.wset_work_id} {...el} posCode={posCode} />
                  );
                })}
              </CustomTable>
            </Box>
          </Collapse>
        </CustomTableCell>
      </CustomTableRow>
    </>
  );
};
export default AddWorksRow;
