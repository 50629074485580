import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import React, { useEffect, useState } from "react";
import { getFiltersData } from "./services";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";
import styles from "./executive.module.scss";
import { FiltersWrapper } from "../../components/common/blocks";

export const ExecutiveReportFilters = ({ onFiltersChange, filters }) => {
  const [filtersData, setFiltersData] = useState({
    contractors: [],
    residents: [],
    techProjectEmployees: [],
    projectManagers: [],
    prorabs: [],
    techProjectDevs: [],
    salePoints: [],
    headDirections: []
  });

  useEffect(() => {
    const getData = async () => {
      const {
        contractors,
        residents,
        techProjectEmployees,
        projectManagers,
        prorabs,
        techProjectDevs,
        salePoints,
        headDirections
      } = await getFiltersData();
      setFiltersData({
        contractors,
        residents,
        techProjectEmployees,
        projectManagers,
        prorabs,
        techProjectDevs,
        salePoints,
        headDirections
      });
    };
    getData();
  }, []);

  return (
    <CollapseBlock className={styles.executive__filters}>
      <FiltersWrapper>
        <div className={styles.executive__filters_item}>
          <AutoSuggest
            withMargin
            multiple
            list={filtersData.contractors}
            showMultipleSelected
            showKey={"contractor_name"}
            valueKey={"contractor_id"}
            placeholder="Выберите подрядчика"
            label="Подрядчик"
            value={filters?.contractor_id_arr || ""}
            onChange={e => onFiltersChange("contractor_id_arr", e?.target?.value)}
          />
        </div>
        <div className={styles.executive__filters_item}>
          <AutoSuggest
            withMargin
            multiple
            list={filtersData.residents}
            showKey={"resident_name"}
            valueKey={"resident_id"}
            showMultipleSelected
            placeholder="Выберите ЖК"
            label="ЖК"
            value={filters?.resident_id_arr || ""}
            onChange={e => onFiltersChange("resident_id_arr", e?.target?.value)}
          />
        </div>
        <div
          className={`${styles.executive__filters_item} ${styles.executive__filters_itemLong}`}
        >
          <AutoSuggest
            withMargin
            multiple
            list={filtersData.techProjectEmployees}
            showKey={"fio"}
            valueKey={"employee_id"}
            showMultipleSelected
            placeholder="Выберите разработчик тех.проекта"
            label="Разработчик тех.проекта (Пользователь)"
            value={filters?.techproject_employee_id_arr || ""}
            onChange={e =>
              onFiltersChange("techproject_employee_id_arr", e?.target?.value)
            }
          />
        </div>
        <div className={styles.executive__filters_item}>
          <AutoSuggest
            withMargin
            multiple
            list={filtersData.prorabs}
            showKey={"fio"}
            showMultipleSelected
            valueKey={"employee_id"}
            placeholder="Выберите прораба"
            label="Прораб"
            value={filters?.manager_project_id_arr || ""}
            onChange={e =>
              onFiltersChange("manager_project_id_arr", e?.target?.value)
            }
          />
        </div>
        <div className={styles.executive__filters_item}>
          <AutoSuggest
            withMargin
            multiple
            list={filtersData.projectManagers}
            showKey={"fio"}
            showMultipleSelected
            valueKey={"employee_id"}
            placeholder="Выберите менеджера"
            label="Проектный менеджер"
            value={filters?.inner_master_id_arr || ""}
            onChange={e =>
              onFiltersChange("inner_master_id_arr", e?.target?.value)
            }
          />
        </div>
        <div className={styles.executive__filters_item}>
          <AutoSuggest
            withMargin
            multiple
            list={filtersData.salePoints}
            showKey={"point_name"}
            showMultipleSelected
            valueKey={"sale_point_id"}
            placeholder="Выберите точки продаж"
            label="Точки продаж"
            value={filters?.sale_point_id_arr || ""}
            onChange={e => onFiltersChange("sale_point_id_arr", e?.target?.value)}
          />
        </div>
        <div
          className={`${styles.executive__filters_item} ${styles.executive__filters_itemLong}`}
        >
          <AutoSuggest
            withMargin
            multiple
            list={filtersData.techProjectDevs}
            showKey={"techproject_developer_name"}
            showMultipleSelected
            valueKey={"techproject_developer_id"}
            placeholder="Выберите разработчик тех.проекта"
            label="Разработчик тех.проекта (Контрагент)"
            value={filters?.techproject_developer_id_arr || ""}
            onChange={e =>
              onFiltersChange("techproject_developer_id_arr", e?.target?.value)
            }
          />
        </div>
        <div className={styles.executive__filters_item}>
          <AutoSuggest
            withMargin
            multiple
            list={filtersData.headDirections}
            showKey={"fio"}
            showMultipleSelected
            valueKey={"employee_id"}
            placeholder="Выберите начальника дирекции"
            label="Начальник дирекции"
            value={filters?.head_direction_id_arr || ""}
            onChange={e =>
              onFiltersChange("head_direction_id_arr", e?.target?.value)
            }
          />
        </div>
      </FiltersWrapper>
    </CollapseBlock>
  );
};
