import React, { useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Checkbox, Collapse, ListItem, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { ExpandLess } from "@material-ui/icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { htmlDecode, numberWithCommas } from "../../../utils";
import { workStatuses } from "../../../constants";
import { callMasterToWorkset, completeWorkset, masterCompleteWorkset } from "./services";
import { updateResidentWorksValues } from "../../../services/redux/actions/remonts-actions";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { LoaderData } from "../../../components/common/LoaderData";
import { CButton } from "../../../components/common/buttons";
import CheckIcon from "@material-ui/icons/Check";
import styles from "./worksets.module.scss";

export const WorksetItem = ({
                              el,
                              setData,
                              work_set_check_group_id,
                              disabledCheckbox = false
                            }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addDefaultSnack } = useCustomSnackbar();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const actionsRef = useRef();
  const [openTab, setOpenTab] = useState(false);
  const [loading, setLoading] = useState(false);
  const [callMasterLoading, setCallMasterLoading] = useState(false);
  const [masterWorksetCompleting, setMasterWorksetCompleting] = useState(false);
  const { dataUser } = useSelector(state => state.appState);

  const onCheckboxChange = async (
    work_set_id = "",
    is_ready,
    remont_work_set_id = ""
  ) => {
    const body = {
      work_set_check_group_id: "",
      is_ready,
      work_set_id,
      remont_work_set_id
    };
    if (work_set_id) body.work_set_check_group_id = work_set_check_group_id;
    const res = await completeWorkset(remont_id, body, setLoading);
    if (!res) return;
    setData(res);
    dispatch(updateResidentWorksValues(remont_id));
    addDefaultSnack("Успешно");
  };

  const completeMasterWorkset = async () => {
    const body = {
      work_set_id: el?.work_set_id,
      work_set_check_group_id: work_set_check_group_id || null,
    };
    const res = await masterCompleteWorkset(remont_id, body, setMasterWorksetCompleting);
    if (!res) return;
    setData(res);
    dispatch(updateResidentWorksValues(remont_id));
  };

  const handleCheckBoxClick = (e, remont_work_set_id = "") => {
    onCheckboxChange &&
    onCheckboxChange(el?.work_set_id, e?.target?.checked, remont_work_set_id);
  };

  const handleClick = e => {
    const isActionsRef = actionsRef.current?.contains(e.target);
    if (isActionsRef) return;
    setOpenTab(!openTab);
  };

  const handleCallMaster = async () => {
    const { work_set_id } = el;
    const res = await callMasterToWorkset(
      remont_id,
      { work_set_check_group_id, work_set_id },
      setCallMasterLoading
    );
    if (!res) return;
    setData(res);
    addDefaultSnack("Мастер успешно вызван");
  };

  const isReady = el?.is_ready_for_work_set === workStatuses.full;
  const isHalf = el?.is_ready_for_work_set === workStatuses.half;

  const showMasterComplete = useMemo(() => {
    if(dataUser?.is_service_master) return true
    return el?.is_master_completed
  }, [el, dataUser]);
  return (
    <ListItem className={clsx(classes.listItem)}>
      <div className={classes.listItemRow}>
        <Checkbox
          indeterminate={isHalf}
          checked={isReady}
          disabled={disabledCheckbox}
          onClick={handleCheckBoxClick}
          classes={{ disabled: classes.disabled }}
          className={clsx(classes.checkbox, {
            [classes.readyCheckbox]: isReady
          })}
          color={"primary"}
        />
        <Box className={classes.box} onClick={handleClick}>
          <div className={classes.labelWrap}>
            <div className={classes.nameLabel}>
              {el?.work_set_name}
              {el?.is_optional ? (
                <Tooltip title={"Опциональные работы"}>
                  <span className={classes.bold}>*</span>
                </Tooltip>
              ) : (
                ""
              )}
              {` (${el?.work_set_done || 0} из ${el?.work_set_num || 0})`}
              {el?.team_master_fio && (
                <div ref={actionsRef} className={styles.worksets__actions}>
                  <Tooltip title={"Назначен"} aria-label="date">
                    <span className={styles.worksets__actions_itemSpan}>
                      {el?.team_master_fio}{" "}
                    </span>
                  </Tooltip>
                </div>
              )}
              {el?.is_call && (
                <div ref={actionsRef} className={styles.worksets__actions}>
                  {el?.is_master_called ? (
                    <>
                      <span className={styles.worksets__actions_succeed}>
                        Мастер вызван <CheckIcon />
                      </span>
                      {(el?.is_master_called_fio ||
                        el?.is_master_called_date) && (
                        <Tooltip
                          title={"Кто вызвал - Дата вызова"}
                          aria-label="date"
                        >
                          <span>{`${el?.is_master_called_fio} ${
                            el?.is_master_called_date
                              ? `(${el?.is_master_called_date})`
                              : ""
                          }`}</span>
                        </Tooltip>
                      )}
                      {el?.ready_date && !el?.is_master_completed && (
                        <Tooltip title={"Дата завершения"} aria-label="date">
                          <span className={styles.worksets__actions_clickable}>
                            {el?.ready_date}
                          </span>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <CButton
                      onClick={handleCallMaster}
                      className={clsx(
                        styles.worksets__actions_btn,
                        styles.worksets__actions_btnMargin
                      )}
                      disabled={el?.is_master_called || callMasterLoading}
                      loading={callMasterLoading}
                    >
                      Вызов мастера
                    </CButton>
                  )}
                  {showMasterComplete && el?.is_master_completed_date && el?.is_master_completed && (
                    <Tooltip title={"Дата завершения"} aria-label="date">
                        <span className={styles.worksets__actions_clickable}>
                          {el?.is_master_completed_date}
                        </span>
                    </Tooltip>
                  )}
                  {showMasterComplete && <CButton
                    onClick={() => !el.is_master_completed && completeMasterWorkset()}
                    className={clsx(
                      styles.worksets__actions_btn,
                      styles.worksets__actions_btnComplete,
                      styles.worksets__actions_btnMargin
                    )}
                    loading={masterWorksetCompleting}
                  >
                    {el.is_master_completed ? "Завершено" : "Завершить"}
                  </CButton>}
                </div>
              )}
            </div>
            {!!el.work_sum && <span title={"Сумма"} className={classes.sum}>{numberWithCommas(el.work_sum)}</span>}
            {" "}
            {loading && (
              <div>
                <LoaderData small />
              </div>
            )}
          </div>
          {openTab ? <ExpandLess /> : <ChevronRightIcon />}
        </Box>
      </div>
      <Collapse in={openTab} timeout="auto" unmountOnExit>
        <div className={classes.roomList}>
          {!!el?.rooms?.length ? (
            el?.rooms?.map(item => {
              return (
                <div className={classes.roomListItem} key={item?.room_id}>
                  <Checkbox
                    size={"small"}
                    disabled={disabledCheckbox}
                    checked={item?.is_ready}
                    onClick={e =>
                      handleCheckBoxClick(e, item?.remont_work_set_id)
                    }
                    className={classes.checkbox}
                    color={"primary"}
                  />{" "}
                  <span>
                    <div
                      className={classes.roomName}
                      dangerouslySetInnerHTML={{
                        __html: htmlDecode(item?.room_name)
                      }}
                    />
                    {el?.is_optional && (
                      <Tooltip title={"Опциональные работы"}>
                        <span className={classes.bold}>*</span>
                      </Tooltip>
                    )}
                  </span>
                  {!!item.work_sum && <span title={"Сумма"} className={classes.sum}>{numberWithCommas(item.work_sum)}</span>}
                </div>
              );
            })
          ) : (
            <div className={classes.roomListItem}>Нет комнат</div>
          )}
        </div>
      </Collapse>
    </ListItem>
  );
};
export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#9194A9",
    marginRight: "10px"
  },
  onSelected: {
    backgroundColor: "#D4D9F8"
  },
  bold: {
    fontWeight: 700,
    fontSize: 16
  },
  checkbox: {
    color: "#0C4B86",
    borderColor: "#0C4B86"
  },
  readyCheckbox: {
    color: "#1aab09 !important",
    borderColor: "#1aab09 !important"
  },
  disabled: {
    color: "rgba(0, 0, 0, 0.26) !important"
  },
  nameLabel: {
    color: "#494C62",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
    gap: 8,
    flexWrap: "wrap",
    rowGap: "4px",
    padding: "5px 0"
  },
  listItem: {
    borderBottom: "1px solid #F0F2F6",
    cursor: "pointer",
    display: "block",
    padding: 0,
    "&:last-child": {
      borderBottom: "none"
    }
  },
  listItemRow: {
    display: "flex",
    width: "100%",
    alignContent: "center"
  },
  labelWrap: { display: "flex", alignItems: "center", gap: 5, flexWrap: 'wrap', width: "100%" },
  box: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: 5,
    justifyContent: "space-between",
    padding: "0 10px"
  },
  roomName: {
    "& p": {
      margin: 0
    },
    "& span": {
      fontWeight: "bold",
      fontSize: 15
    }
  },
  roomLabel: { fontSize: 14, color: "#ccc" },
  readyRoom: { color: "#1dbd25" },
  roomList: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    backgroundColor: "rgb(251 ,251, 251)",
    border: "1px solid #b4bae3"
  },
  roomListItem: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "5px",
    gap: "5px",
    cursor: "pointer",
    borderBottom: "1px solid #F0F2F6",
    "&:hover": {
      backgroundColor: "#f5f5f5"
    }
  },
  sum: {
    whiteSpace: 'nowrap',
    padding: '3px',
    background: 'khaki',
    color: 'darkgreen',
    fontSize: '13px'
  }
}));
