import React, { useCallback, useEffect, useState } from "react";
import styles from "./amount.module.scss";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../common/CustomTable/CustomTable";
import { PageTitle } from "../../../common/pageTitle";
import { getDebtsAmount } from "./api";
import { numberWithCommas } from "../../../../utils";
import DownloadXlsx from "../../../common/DownloadXlsx";

export const DebtAmount = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState(null);

  const getDebtData = useCallback(async params => {
    try {
      setIsFetching(true);
      const res = await getDebtsAmount(params);
      setIsFetching(false);
      if (res) {
        setData(res?.team_debts);
      }
    } catch (e) {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    getDebtData();
  }, [getDebtData]);

  return (
    <>
      <PageTitle title={"Отчет по долгам"} />
      <div className={styles.amount}>
        <div className={styles.amount__header}>
          <DownloadXlsx
            url="/partner/teams/debts/report/"
            withoutMargin
            title="Отчет долгов бригады"
            reqType="get"
          />
        </div>
        <CustomTable
          isFetching={isFetching}
          headItems={headItems}
          stickyHeader
          isEmpty={!data?.length}
        >
          {data?.map(item => {
            return <DebtTableRow key={item?.team_id} item={item} />;
          })}
        </CustomTable>
      </div>
    </>
  );
};

const DebtTableRow = ({ item }) => {
  return (
    <>
      <CustomTableRow>
        <CustomTableCell>{item?.team_name}</CustomTableCell>
        <CustomTableCell align={"right"}>
          {numberWithCommas(item?.debt_sum)}
        </CustomTableCell>
        <CustomTableCell align={"right"}>
          {numberWithCommas(item?.debt_sum_paid)}
        </CustomTableCell>
        <CustomTableCell align={"right"}>
          {numberWithCommas(item?.debt_sum_remaining)}
        </CustomTableCell>
      </CustomTableRow>
    </>
  );
};

const headItems = [
  { label: "Бригада", align: "left" },
  { label: "Сумма долгов", align: "right" },
  { label: "Погашено", align: "right" },
  { label: "Осталось", align: "right" }
];
