import React, { useEffect, useState } from "react";
import { getRequestShipHistory } from "../../api";
import { ShipHistoryContent } from "./ShipHistoryContent";

export const RequestShipHistoryModal = ({ data }) => {
  const { provider_request_id } = data;
  const [isFetching, setIsFetching] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    setIsFetching(true);
    getRequestShipHistory(provider_request_id)
      .then(res => {
        setIsFetching(false);
        setHistory(res);
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, [provider_request_id]);

  return (
    <ShipHistoryContent history={history} isFetching={isFetching} data={data} />
  );
};
