import React, { useLayoutEffect, useMemo, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Typography
} from "@material-ui/core";
import UtilityBtn from "../remonts/RemontInfo/UtilityBtn";
import { ExpandMore } from "@material-ui/icons";
import Popper from "./Popper";
import { signXml } from "../../utils/signXml";
import {
  getAccessionXml,
  getSpecificationXml,
  signAccessionXml,
  signSpecificationXml
} from "./api";
import clsx from "clsx";
import NumberFormat from "react-number-format";
import { htmlDecode } from "../../utils";
import { CButton } from "../../components/common/buttons";
import { useDownloadFile } from "../remonts/RemontInfo/Gpr/useDownloadFile";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

export const gridTemplateColumns = "65px 65px 1fr 2fr 90px 1fr 130px 2fr";
const useStyles = makeStyles({
  row: {
    display: "grid",
    gridTemplateColumns,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%"
  },
  border: {
    border: "1px solid #F0F2F6",
    borderTop: "none",
    padding: 5,
    overflow: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: 5,
      height: 5
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0C4B86",
      borderRadius: 4
    }
  },
  signBtn: {
    marginTop: 10,
    padding: 4,
    width: "auto",
    height: "auto",
    fontSize: 12,
    marginLeft: 10
  },
  unloading: {
    color: "#659DF1",
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center"
  },
  popper_body: {
    position: "relative"
  },
  doc_name: {
    overflow: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: "#0C4B86 #fff"
  },
  contragent: {
    color: "#118308",
    fontWeight: 700
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end"
  }
});

const ContractJournalRow = ({
  setData,
  item,
  contractor_agreement_list_id,
  remont_id,
  document_name,
  agreement_sum,
  sign_id,
  contragent_name,
  status_name,
  type_code,
  employee_fio,
  filters,
  contractor_id,
  is_signed,
  resident_name,
  flat_num,
  document_url
}) => {
  const classes = useStyles();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const { downloadFile, downloading } = useDownloadFile();
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsPopperOpen(!isPopperOpen);
  };
  const handleClickAway = () => {
    setIsPopperOpen(false);
  };

  const btnText = useMemo(() => {
    if (is_signed !== 0 || !type_code) return null;
    if (type_code === "CONTRACTOR_ACCESSION") {
      return "Подписать договор присоединения";
    }
    return "Подписать";
  }, [is_signed, type_code]);

  useLayoutEffect(() => {
    if (type_code === "CONTRACTOR_ACCESSION") {
      setFileNames(["Договор присоединения"]);
    } else if (!!type_code) {
      setFileNames(["Спецификация (договор)"]);
    }
  }, [type_code]);

  const verifyXml = async e => {
    setIsLoading(true);
    try {
      let xml;
      let signValidatedXml;
      const btnTitle = e.target.textContent;

      if (btnTitle === "Подписать договор присоединения") {
        try {
          const response = await getAccessionXml({ contractor_id });
          xml = response.data;
          signValidatedXml = async params => {
            try {
              const res = await signAccessionXml(params);
              setIsLoading(false);
              setData(res.data);
              addDefaultSnack("Успешно");
            } catch (err) {
              setIsLoading(false);
              addCloseSnack(err.response?.data.error);
            }
          };
        } catch (err) {
          setIsLoading(false);
          addCloseSnack(err.response?.data.error);
        }
      } else {
        try {
          const response = await getSpecificationXml({
            agreement_id: contractor_agreement_list_id,
            remont_id,
            type_code
          });
          xml = response.data;

          signValidatedXml = async params => {
            try {
              const res = await signSpecificationXml(params);
              setIsLoading(false);
              setData(res.data);
              addDefaultSnack("Успешно");
            } catch (err) {
              setIsLoading(false);
              addCloseSnack(err.response?.data.error);
            }
          };
        } catch (err) {
          setIsLoading(false);
          addCloseSnack(err?.response?.data.error);
        }
      }

      signXml("PKCS12", "SIGNATURE", xml, "signXmlBack", xml)
        .then(res => {
          const { unsigned_message, signed_message } = res;
          let params = {
            unsigned_message,
            signed_message,
            remont_id,
            contractor_id_from_filter: filters?.contractor_id || "",
            remont_id_from_filter: filters?.remont_id || "",
            contractor_agreement_list_id,
            type_code
          };
          if (btnTitle === "Подписать договор присоединения") {
            params = {
              unsigned_message,
              signed_message,
              contractor_id,
              contractor_agreement_list_id
            };
          }
          signValidatedXml(params);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } catch (err) {
      setIsLoading(false);
      addCloseSnack(err.response?.data.error);
    }
  };

  const downloadSignDoc = async () => {
    downloadFile(
      `/partner/contractor_agreement_list/signed/${sign_id}/`,
      "Подписанный документ",
      {
        responseType: "arraybuffer"
      },
      "pdf",
      "get"
    );
  };

  return (
    <Box
      className={classes.row}
      style={{
        transform: `translateY(${item.start}px)`,
        height: `${item.size}px`
      }}
    >
      <Box className={classes.border}>{contractor_agreement_list_id}</Box>
      <Box className={classes.border}>{remont_id}</Box>
      <Box className={classes.border}>{`${
        resident_name ? `${resident_name}, ` : ""
      } ${flat_num}`}</Box>
      <Box className={clsx(classes.border, classes.doc_name)}>
        <span dangerouslySetInnerHTML={{ __html: htmlDecode(document_name) }} />
        {!!sign_id && (
          <CButton
            className={classes.signBtn}
            onClick={downloadSignDoc}
            disabled={downloading}
            loading={downloading}
          >
            Скачать подписанный документ
          </CButton>
        )}
      </Box>
      <Box className={clsx(classes.border, classes.alignRight)}>
        {
          <NumberFormat
            value={agreement_sum || "0"}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
          />
        }
      </Box>
      <Box className={clsx(classes.border)}>
        <span className={classes.contragent}>{contragent_name}</span> /{" "}
        <span dangerouslySetInnerHTML={{ __html: htmlDecode(employee_fio) }} />
      </Box>
      <Box className={classes.border}>{status_name}</Box>
      <Box className={classes.border}>
        <Box
          display="flex"
          flexDirection={"column"}
          flexWrap="wrap"
          style={{ gap: 5 }}
        >
          {fileNames.length ? (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Box className={classes.popper_body}>
                <Typography className={classes.unloading} onClick={handleClick}>
                  Выгрузка <ExpandMore />
                </Typography>
                {isPopperOpen ? (
                  <Popper
                    fileNames={fileNames}
                    remont_id={remont_id}
                    type_code={type_code}
                    contractor_agreement_list_id={contractor_agreement_list_id}
                    document_url={document_url}
                  />
                ) : null}
              </Box>
            </ClickAwayListener>
          ) : null}
          {btnText ? (
            <Box>
              <UtilityBtn
                height={24}
                text={btnText}
                handleClick={verifyXml}
                disabled={isLoading}
              />
              {isLoading ? (
                <CircularProgress size={15} style={{ marginLeft: 10 }} />
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default ContractJournalRow;
