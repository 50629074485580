import { PageTitle } from "../../components/common/pageTitle";
import React from "react";
import { JurDateWarranty } from "./JurDateWarranty";

const title = "Ремонты с юр датами по дате перехода на гарантию";
export const JurDateWarrantyPage = () => {
  document.title = title;

  return (
    <>
      <PageTitle title={title} />
      <JurDateWarranty />
    </>
  );
};