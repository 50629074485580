import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AsideBtn from "./AsideBtn";

const useStyles = makeStyles({
  aside_content: {
    width: 168,
    marginLeft: 25
  },
  content_title: {
    fontSize: 12,
    fontWeight: 600,
    color: "#494C62",
    lineHeight: "15px"
  }
});

const AsideContent = ({ col_id, setCardInfo, cardInfo, card_id }) => {
  const classes = useStyles();
  const titles = ["Участники", "Вложения", "Перемещение", "Архивация"];
  return (
    <Box className={classes.aside_content}>
      <Typography
        className={classes.content_title}
        style={{ marginBottom: 10 }}
      >
        Действия
      </Typography>
      {titles.map((el, i) => {
        return (
          <AsideBtn
            title={el}
            key={i}
            col_id={col_id}
            setCardInfo={setCardInfo}
            cardInfo={cardInfo}
            index={i}
            card_id={card_id}
          />
        );
      })}
    </Box>
  );
};

export default AsideContent;
