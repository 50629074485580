import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { useSelector } from "react-redux";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { CButton } from "../../components/common/buttons";
import { Navigate, useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { InputWarning } from "../../components/common/form/warnings";
import { Logo } from "../../components/common/Logo";
import { PAGES } from "../../constants";
import { CLink } from "../../components/common/blocks/CustomLink";
import { registerUser } from "./services";
import { useAuthStyles } from "./login";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

export const RegisterPage = () => {
  const classes = useAuthStyles();
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm();
  const router = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [initialize, setInitialize] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isUserAuthenticated } = useSelector(state => state.appState);
  const { addDefaultSnack } = useCustomSnackbar()

  useEffect(() => {
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage("logout");
    else
      setInitialize(true);
  }, []);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = async body => {
    setLoading(true)
    const res = await registerUser(body)
    setLoading(false)
    if (!res) return;
    router(PAGES.login)
    setDisabledSubmit(true);
    addDefaultSnack('Пользователь успешно зарегистрирован');
  };

  if (!initialize) return null;
  if (isUserAuthenticated) return <Navigate to={PAGES.main} replace={true} />;
  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{ backgroundColor: "#fff", textAlign: "center" }}
    >
      <div className={classes.paper}>
        <Logo className={classes.logo} alt="" size={"large"} />
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <div>
            <Controller
              control={control}
              name="fio"
              render={({ field: { onChange, value } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  value={value}
                  id="fio"
                  label="ФИО"
                  autoComplete="fio"
                  style={{ color: "#818181", fontSize: 18 }}
                  onChange={onChange}
                />
              )}
              rules={{ required: true }}
              defaultValue={""}
            />
            {errors.fio && <InputWarning>Это поле обязательно</InputWarning>}
          </div>
          <div>
            <Controller
              control={control}
              name="login"
              render={({ field: { onChange, value } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  value={value}
                  id="email"
                  label="Email"
                  autoComplete="email"
                  style={{ color: "#818181", fontSize: 18 }}
                  onChange={onChange}
                />
              )}
              rules={{ required: true }}
              defaultValue={""}
            />
            {errors.login && <InputWarning>Это поле обязательно</InputWarning>}
          </div>
          <div>
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  value={value}
                  id="password"
                  label="Пароль"
                  type={showPassword ? "text" : "password"}
                  style={{ color: "#818181", fontSize: 18 }}
                  onChange={onChange}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
              rules={{ required: true }}
              defaultValue={""}
            />
            {errors.password && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
          <div className={classes.link}>
            <CLink to={PAGES.login}>Войти</CLink>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <CButton
              type="submit"
              loading={loading}
              disabled={disabledSubmit}
              className={classes.submit}
              onClick={onSubmit}
            >
              Зарегистрироваться
            </CButton>
          </div>
        </form>
      </div>
    </Container>
  );
};
