import React, { useEffect, useMemo, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { CustomSearchBar } from "../../../components/common/form/CustomSearchBar";
import { useNavigate, useParams } from "react-router";
import { ResidentWorksets } from "./ResidentWorksets";
import styles from "./worksets.module.scss";
import { CustomAccordion } from "../../../components/common/blocks/CustomAccordion";
import EmptyOrShow from "../../../components/common/EmptyOrShow";
import { makeStyles } from "@material-ui/core/styles";
import { NotFound } from "../../../components/common/text";
import { PAGES_ROUTE_KEY, workStatuses } from "../../../constants";
import { updateResidentWorksValues } from "../../../services/redux/actions/remonts-actions";
import { useDispatch } from "react-redux";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { callWorksetAction, completeWorkset, getWorksetsActions, getWorksetsData, updateWorksetList } from "./services";
import { CButton } from "../../../components/common/buttons";
import clsx from "clsx";
import { setHeaderBack } from "../../../services/redux/actions/app-actions";
import { numberWithCommas } from "../../../utils";

export const RemontWorksets = ({singleRemont}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useWorksetsStyles();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const { addDefaultSnack } = useCustomSnackbar();
  const [data, setData] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [btnActions, setBtnActions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState({});
  const [completeLoading, setCompleteLoading] = useState({});

  useEffect(() => {
    const goBack = () => {
      navigate(`${PAGES_ROUTE_KEY.remontInfo}/${remont_id}`);
    };
    remont_id && singleRemont && dispatch(setHeaderBack(goBack));
    return () => {
      dispatch(setHeaderBack(null));
    };
  }, [dispatch, remont_id, navigate, singleRemont]);

  useEffect(() => {
    const getWorksets = async () => {
      const res = await getWorksetsData(remont_id, setIsFetching);
      res && setData(res);
    };
    getWorksets();
  }, [remont_id]);

  useEffect(() => {
    const getData = async () => {
      const res = await getWorksetsActions();
      res && setBtnActions(res);
    };
    getData();
  }, []);

  const onCheckboxChange = async (
    work_set_check_group_id,
    is_ready,
    work_set_id = "",
    remont_work_set_id = ""
  ) => {
    const body = {
      work_set_check_group_id,
      is_ready,
      work_set_id,
      remont_work_set_id
    };
    const res = await completeWorkset(remont_id, body, value =>
      setCompleteLoading(prev => ({
        ...prev,
        [work_set_check_group_id]: value
      }))
    );
    if (!res) return;
    setData(res);
    dispatch(updateResidentWorksValues(remont_id));
    addDefaultSnack("Успешно");
  };
  const handleCheckBoxClick = (e, work_set_check_group_id = "") => {
    onCheckboxChange &&
    onCheckboxChange(work_set_check_group_id, e?.target?.checked);
  };
  const onOptionClick = async (call_type_id, work_set_check_group_id) => {
    if (!work_set_check_group_id) return;
    setIsLoading(prev => ({ ...prev, [work_set_check_group_id]: true }));
    const res = await callWorksetAction(remont_id, {
      call_type_id,
      work_set_check_group_id
    });
    setIsLoading(prev => ({ ...prev, [work_set_check_group_id]: false }));
    if (!res) return;
    setData(prev => ({ ...prev, ...res }));
    addDefaultSnack();
  };

  const updateList = async () => {
    setUpdating(true);
    const res = await updateWorksetList(remont_id);
    setUpdating(false);
    if (!res) return;
    setData(res);
  };

  const computedData = useMemo(() => {
    if (!searchItem || !data?.remont_work_sets?.length)
      return data?.remont_work_sets;
    const search = searchItem.toLowerCase();
    return data?.remont_work_sets.map(item => {
      return {
        ...item,
        work_sets: item?.work_sets?.filter(item =>
          item?.work_set_name?.toLowerCase().includes(search)
        )
      };
    });
  }, [searchItem, data]);

  return (
    <div>
      <Grid container className={classes.header}>
        <CustomSearchBar
          value={searchItem}
          onChange={value => setSearchItem(value)}
        />
      </Grid>

      <div className={styles.worksets__content}>
        <EmptyOrShow loading={isFetching}>
          <div className={styles.worksets__header}>
            <Typography className={classes.label}>
              {`Работы ${data?.num?.done || 0} из ${data?.num?.all || 0}`}
            </Typography>

            {!!data?.amounts?.external_masters_amount && <div className={styles.worksets__header_amount}>
              <div>Оплаченная сумма: <span>{numberWithCommas(data?.amounts.remont_work_payed_sum)}</span></div>
              <div>Общая сумма: <span className={styles.worksets__header_amountAll}>{numberWithCommas(data?.amounts.external_masters_amount)}</span></div>
            </div>}
          </div>
          <div className={classes.wrapperContent}>
            {!!computedData?.length ? (
              computedData?.map(item => {
                const isReady =
                  item?.is_ready_for_work_set_check_group === workStatuses.full;
                const isHalf =
                  item?.is_ready_for_work_set_check_group === workStatuses.half;
                return (
                  <CustomAccordion
                    checkbox
                    disabled={item?.to_disable}
                    loading={completeLoading}
                    valueKey={item.work_set_check_group_id}
                    isChecked={isReady}
                    isHalfChecked={isHalf}
                    handleCheckBoxClick={e =>
                      handleCheckBoxClick(e, item.work_set_check_group_id)
                    }
                    renderData={() => (
                      <div className={styles.worksets__data}>
                        {item.to_update ? (
                          <CButton
                            onClick={updateList}
                            className={clsx(
                              styles.worksets__actions_btn,
                              styles.worksets__actions_btnUpdate
                            )}
                            loading={updating}
                          >
                            Обновить
                          </CButton>
                        ) : (
                          btnActions?.map(action => (
                            <CButton
                              key={action?.call_type_id}
                              onClick={() =>
                                onOptionClick(
                                  action?.call_type_id,
                                  item.work_set_check_group_id
                                )
                              }
                              disabled={
                                !isReady ||
                                isLoading[item.work_set_check_group_id]
                              }
                              className={styles.worksets__actions_btn}
                              loading={isLoading[item.work_set_check_group_id]}
                            >
                              {action?.call_type_name_verb}
                            </CButton>
                          ))
                        )}
                        {!!item.checked_status && (
                          <div
                            style={{
                              backgroundColor: item.checked_status_colour
                            }}
                            className={styles.worksets__data_item}
                          >
                            {item.checked_status}
                          </div>
                        )}
                        {!!item.work_sum && <span title={"Сумма"}
                               className={styles.worksets__sum}>{numberWithCommas(item.work_sum)}</span>}
                      </div>
                    )}
                    key={item.work_set_check_group_id}
                    label={`${item?.work_set_check_group_name} (${item?.done ||
                    "0"} из ${item?.num || "0"})`}
                  >
                    <ResidentWorksets
                      disabledCheckbox={item?.to_disable}
                      data={item?.work_sets}
                      setData={setData}
                      work_set_check_group_id={item.work_set_check_group_id}
                    />
                  </CustomAccordion>
                );
              })
            ) : (
              <NotFound />
            )}
          </div>
        </EmptyOrShow>
      </div>
    </div>
  );
};
export const useWorksetsStyles = makeStyles(() => ({
  header: {
    alignItems: "flex-end",
    gap: 25
  },
  title: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#9194A9",
    marginRight: "10px"
  },
  subHeader: {
    background: "#ECEEFD",
    borderRadius: "4px 4px 0px 0px",
    padding: "16px"
  },
  wrapper: {
    width: "100%"
  },
  wrapperContent: {
    width: "100%",
    marginTop: 15
  },
  label: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#9194A9"
  },
  list: { boxShadow: "0px 5px 20px rgb(108 108 108 / 15%)", padding: 0 }
}));
