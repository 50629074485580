import React, { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Button, Popover } from "@material-ui/core";
import styles from "./CustomDropdown.module.scss";
import { LoaderData } from "../../LoaderData";

export const CustomDropdown = ({
  options,
  onOptionClick,
  valueOption = "id",
  showOption = "label",
  disabled = false,
  loading = false,
  label = "Действие"
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Button
        variant="contained"
        className={styles.popup__btn}
        disabled={disabled}
        sx={{ marginRight: "10px" }}
        aria-describedby={id}
        onClick={handleClick}
      >
        {loading ? (
          <LoaderData small />
        ) : (
          <span className={styles.popup__label}>
            {label} <KeyboardArrowDownIcon />
          </span>
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        className={styles.popup}
      >
        <div className={styles.popup__content}>
          <ul>
            {options?.map(item => {
              return (
                <li
                  key={item[valueOption]}
                  onClick={() => onOptionClick(item[valueOption])}
                >
                  {item[showOption]}
                </li>
              );
            })}
          </ul>
        </div>
      </Popover>
    </>
  );
};
