import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../components/common/CustomTable/CustomTable";
import React, { Fragment } from "react";
import { showPopUp } from "../../../services/redux/actions/app-actions";
import { MODALS_NAMES } from "../../../constants";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

export const CheckListStagesTable = ({
  data,
  parentsItemProp,
  childrenItemProp,
  remont_id,
  worksetMode = false
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const openCheckModal = checklistId =>
    dispatch(
      showPopUp(true, MODALS_NAMES.checkListModal, {
        checklistId,
        remontId: remont_id
      })
    );
  return (
    <>
      {data.map((stage, i) => {
        return (
          <div
            key={stage.stage_id || i}
            style={{ marginBottom: worksetMode ? 10 : 50 }}
          >
            <div className={styles.title}>{stage.stage_name}</div>
            <CustomTable
              isEmpty={!stage[parentsItemProp]?.length}
              headItems={headerTitles}
              withMaxHeight={false}
            >
              {stage[parentsItemProp].map(checklist => {
                return (
                  <Fragment key={checklist.check_list_id}>
                    <CustomTableRow>
                      <CustomTableCell
                        colSpan={10}
                        className={styles.checkName}
                      >
                        {checklist.check_name}
                      </CustomTableCell>
                    </CustomTableRow>
                    {checklist[childrenItemProp].map(el => {
                      return (
                        <CustomTableRow key={el.check_list_id}>
                          <CustomTableCell style={{ width: 600 }}>
                            {el.check_name}
                          </CustomTableCell>
                          <CustomTableCell>
                            <span
                              className={styles.openBtn}
                              onClick={() => openCheckModal(el.check_list_id)}
                            >
                              открыть
                            </span>
                          </CustomTableCell>
                          <CustomTableCell>{el.comments}</CustomTableCell>
                          <CustomTableCell>{el.fio}</CustomTableCell>
                          <CustomTableCell>{el.date_create}</CustomTableCell>
                          <CustomTableCell
                            style={{
                              background:
                                el.is_accepted > 0
                                  ? "#63c940"
                                  : el.is_accepted === 0
                                  ? "#cc3631"
                                  : ""
                            }}
                          >
                            {el.is_accepted > 0
                              ? "Принят"
                              : el.is_accepted === 0
                              ? "Не принят"
                              : ""}
                          </CustomTableCell>
                        </CustomTableRow>
                      );
                    })}
                  </Fragment>
                );
              })}
            </CustomTable>
          </div>
        );
      })}
    </>
  );
};
export const useStyles = makeStyles(() => ({
  title: {
    color: "#0C4B86",
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 10
  },
  checkName: { backgroundColor: "#ffb3001a" },
  openBtn: {
    color: "#0C4B86",
    cursor: "pointer",
    textDecoration: "underline"
  }
}));

const headerTitles = [
  { label: "Название" },
  { label: "Фото" },
  { label: "Комментарий" },
  { label: "Работник" },
  { label: "Дата создания" },
  { label: "Статус" }
];
