import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { showPopUp } from "../../services/redux/actions/app-actions";
import { useDispatch } from "react-redux";
import { MODALS_NAMES } from "../../constants";

const useStyles = makeStyles({
  row: {
    marginBottom: 10,
    verticalAlign: "top",
    borderRadius: 4
  },
  row_table: {
    marginBottom: 10,
    verticalAlign: "top",
    borderRadius: 4,
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)"
  },
  cell: {
    borderLeft: "1px solid #F0F2F6",
    verticalAlign: "top",
    fontSize: 12,
    padding: "5px !important"
  },
  cell_first: {
    verticalAlign: "top",
    fontWeight: 400,
    fontSize: 12
  }
});

function getColor(total_num, shipped_num) {
  if (total_num === shipped_num && total_num !== 0) {
    return "rgb(213, 249, 221)";
  } else if (total_num > shipped_num) {
    if (shipped_num > 0) {
      return "rgb(255, 227, 201)";
    } else {
      return "rgb(245, 196, 193)";
    }
  } else if (total_num === 0) {
    return "#ffffff";
  }
}

export default function RemontWork({ data = {}, additionalData }) {
  const classes = useStyles();
  const row = [];
  const dispatch = useDispatch();
  Object.keys(data).forEach(function(key) {
    row.push(data[key]);
  });
  return row.map((arr, idx) => (
    <TableCell
      key={idx}
      align="left"
      onClick={() => {
        if (arr.total_num > 0) {
          dispatch(
            showPopUp(true, MODALS_NAMES.materialReport, {
              remont_id: additionalData.remont_id,
              material_type_id: arr.material_type_id
            })
          );
        }
      }}
      className={classes.cell}
      style={{
        backgroundColor: getColor(arr.total_num, arr.shipped_num),
        cursor: arr.total_num > 0 ? "pointer" : "default"
      }}
    >
      {arr.total_num > 0 ? (
        <span>
          {arr.shipped_num} из {arr.total_num}
        </span>
      ) : null}
    </TableCell>
  ));
}
