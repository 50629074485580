import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { getPenaltyReport } from "../api";
import EmptyOrShow from "../../../components/common/EmptyOrShow";
import GprTable from "../Tables/GprTable";
import SmetaTable from "../Tables/SmetaTable";

export const useStyles = makeStyles({
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& svg": {
      color: "#0C4B86"
    }
  },
  head_title: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "22px",
    color: "#0C4B86"
  },
  table_row: {
    "&:first-child": {
      borderLeft: "1px solid #F0F2F6 !important"
    }
  },
  cell: {
    fontSize: 12,
    lineHeight: "16px",
    borderRight: "1px solid #F0F2F6 !important",
    borderBottom: "1px solid #F0F2F6 !important",
    padding: 5
  },
  head_cell: {
    fontWeight: 600,
    color: "#696C7C",
    backgroundColor: "#ECEEFD"
  }
});

const PenaltyDetailsModal = ({ data, handleClose }) => {
  const classes = useStyles();
  const [penaltyReport, setPenaltyReport] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getPenaltyReport(data.id)
      .then(res => {
        setLoading(false);
        setPenaltyReport(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [data.id]);

  return (
    <Box sx={{ width: "700px !important", display: "grid", gap: "24px" }}>
      <Box className={classes.head}>
        <Typography component="h2" className={classes.head_title}>
          Штрафы
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <EmptyOrShow loading={loading}>
        {penaltyReport?.penalty_type_code === "GPR_OVERDUE" ? (
          <GprTable penaltyReport={penaltyReport} />
        ) : penaltyReport?.penalty_type_code === "SMETA_DIFF" ? (
          <SmetaTable penaltyReport={penaltyReport} />
        ) : null}
      </EmptyOrShow>
    </Box>
  );
};

export default PenaltyDetailsModal;
