import React from "react";
import { useSelector } from "react-redux";
import MenuSideBar from "./MenuSideBar";
import ArchiveSideBar from "./ArchiveSideBar";
import HistorySideBar from "./HistorySideBar";

const SideBarContent = ({ handleClick }) => {
  const { sideBarContent } = useSelector(state => state.problemsState);
  const getSideBarContent = sideBarContent => {
    switch (sideBarContent) {
      case "Архив":
        return <ArchiveSideBar handleClick={handleClick} />;
      case "История":
        return <HistorySideBar handleClick={handleClick} />;
      default:
        return <MenuSideBar handleClick={handleClick} />;
    }
  };
  return <>{getSideBarContent(sideBarContent)}</>;
};

export default SideBarContent;
