import React, { useEffect, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { getTechFeature } from "./services/api";

const AsideTxtConstructor = ({ text_constructor, remont_id }) => {
  const classes = useStyles();
  const [techFeature, setTechFeature] = useState("");

  useEffect(() => {
    if (remont_id) {
      getTechFeature(remont_id)
        .then(res => {
          setTechFeature(res.data.tech_feature);
        })
        .catch(() => {});
    }
  }, [remont_id]);

  return (
    <Box>
      {techFeature ? (
        <Box width="100%" className={classes.txt_constructor}>
          <Typography className={classes.title} style={{ marginBottom: 10 }}>
            Тех.особенности:
          </Typography>
          <Box>
            <Typography
              style={{
                fontSize: "14px",
                marginBottom: 10,
                whiteSpace: "pre-wrap",
                lineHeight: "1.2"
              }}
            >
              {techFeature.replace(/&nbsp;/g, "")}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <Box width="100%" className={classes.txt_constructor}>
        <Typography className={classes.title} style={{ marginBottom: 10 }}>
          Текстовый конструктор:
        </Typography>
        <Box>
          {text_constructor?.map((el, i) => (
            <Typography key={i} style={{ fontSize: "14px", marginBottom: 10 }}>
              {el.name}{" "}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AsideTxtConstructor;

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "15px",
    color: "#9194A9",
    marginRight: "10px"
  },
  txt_constructor: {
    padding: "0 15px",
    [theme.breakpoints.down(768)]: {
      padding: 0,
    }
  }
}));
