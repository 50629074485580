import { makeStyles } from "@material-ui/core/styles";

export const useMastersStyles = makeStyles({
  starIcon: {
    color: "#ff0000",
    fill: "#ff0000"
  },
  starIconSmall: {
    width: "10px"
  }
});
