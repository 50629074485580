import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    padding: 0
  },
  row: {
    borderBottom: 1,
    borderColor: "#A5A7B5",
    display: "table",
    margin: 0,
    padding: 0,
    width: "100%"
  },
  row_head: {
    marginBottom: 5,
    borderColor: "#A5A7B5",
    borderBottom: "1px solid #F0F2F6",
    display: "table",
    margin: 0,
    width: "100%"
  },
  col: {
    width: "33%",
    float: "left",
    borderCollapse: "collapse",
    wordWrap: "break-word",
    paddingBottom: 5
  },
  title: {
    fontSize: 16,
    color: "#9194A9",
    fontWeight: 600
  },
  textContent: {
    fontSize: 12,
    color: "#A5A7B5",
    fontWeight: 600
  },
  delayRed: {
    fontSize: 12,
    color: "#FF4754",
    fontWeight: 500
  },
  delayGreen: {
    fontSize: 12,
    color: "#3AD493",
    fontWeight: 500
  },
  textBlue: {
    color: "#0C4B86"
  },
  row_td: {
    borderRight: "1px solid #F0F2F6"
  },
  textDate: {
    fontSize: 14,
    color: "#000000",
    fontWeight: 500
  }
});

export default function ReportCard({
  title,
  factStageBegin,
  factStageEnd,
  gprStageBegin,
  gprStageEnd,
  stageDefectAttempt,
  stageDelay,
  stageDuration
}) {
  const classes = useStyles();
  let dayName = "";
  let delay = Math.abs(stageDelay);

  if (delay === 1) {
    dayName = " день";
  } else if (delay === 2 || delay === 3 || delay === 4) {
    dayName = " дня";
  } else {
    dayName = " дней";
  }

  function isDelay(props) {
    if (props < 0) {
      return (
        <span className={classes.delayGreen}>
          Опережение - {Math.abs(props)} {dayName}{" "}
        </span>
      );
    } else if (props > 0) {
      return (
        <span className={classes.delayRed}>
          Опоздание - {Math.abs(props)} {dayName}
        </span>
      );
    } else if (props === 0) {
      return <span className={classes.delayGreen}>Вовремя</span>;
    } else {
      return <span></span>;
    }
  }

  return (
    <CardContent className={classes.row_td}>
      <div className={classes.row_head}>
        <div className={classes.col}>
          <Typography variant="h5" component="h2" className={classes.title}>
            {title}
            {stageDuration > 0 ? <span>({stageDuration} дней)</span> : null}
          </Typography>
        </div>
        <div className={classes.col}>
          {stageDefectAttempt > 0 ? (
            <span className={classes.textContent}>
              Неуспешных попыток: <b>{stageDefectAttempt}</b>{" "}
            </span>
          ) : (
            <span className={classes.textContent}></span>
          )}
        </div>
        <div className={classes.col}>
          {factStageEnd ? isDelay(stageDelay) : null}
        </div>
      </div>
      <div className={classes.row_head}>
        <div className={classes.col}>
          <br />
          <Typography color="textSecondary" className={classes.textContent}>
            План (ГПР)
          </Typography>
          <Typography color="textSecondary" className={classes.textContent}>
            Факт (Этапы)
          </Typography>
        </div>
        <div className={classes.col}>
          <Typography color="textSecondary" className={classes.textContent}>
            Начало
          </Typography>
          <Typography variant="h6" component="p" className={classes.textDate}>
            {gprStageBegin}
          </Typography>
          <Typography variant="h6" component="p" className={classes.textDate}>
            {factStageBegin}
          </Typography>
        </div>
        <div className={classes.col}>
          <Typography color="textSecondary" className={classes.textContent}>
            Окончание
          </Typography>
          <Typography variant="h6" component="p" className={classes.textDate}>
            {gprStageEnd}
          </Typography>
          <Typography variant="h6" component="p" className={classes.textDate}>
            {factStageEnd}
          </Typography>
        </div>
      </div>
      {factStageEnd ? (
        <div className={classes.row}>
          <Typography color="textSecondary" style={{ color: "#ae431f" }}>
            Этап закончен
          </Typography>
        </div>
      ) : null}
    </CardContent>
  );
}
