import React from "react";
import { Box, Button, Checkbox } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { showPopUp } from "../../../services/redux/actions/app-actions";
import { MAIN_URL, MODALS_NAMES } from "../../../constants";
import {
  CustomTableCell,
  CustomTableRow,
} from "../../../components/common/CustomTable/CustomTable";
import { setProjects } from "../../../services/redux/actions/remonts-actions";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { useHttp } from "../../../utils/hooks/http.hook";
import { useDownloadFile } from "../RemontInfo/Gpr/useDownloadFile";
import { LoaderData } from "components/common/LoaderData";

const useStyles = makeStyles({
  cell: {},
  action_cell: {},
  sm_cell: {
    maxWidth: 90,
    textAlign: "center",
  },
  name_cell: {
    maxWidth: 160,
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  btn: {
    height: 24,
    width: "auto",
    color: "#fff",
    boxShadow: "none",
    borderRadius: 4,
    "&:last-child": {
      marginLeft: 11,
    },
  },
  btn_approve: {
    backgroundColor: "#0C4B86",
  },
  btn_reject: {
    backgroundColor: "#FF6262",
  },
  chat: {
    padding: "5px 15px",
    color: "#fff",
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  send: {
    color: "#81CD67",
    width: "100%",
    display: "inline-block",
    textAlign: "center",
  },
  not_send: {
    color: "#FF6262",
  },
  download: {
    color: "#0C4B86",
    fontSize: 13,
    fontWeight: 600,
    cursor: "pointer",
    display: "block",
    lineHeight: "16px",
    padding: "10px 0",
    "&:hover": {
      textDecoration: "underline",
    },
    "& + &": {
      borderTop: "1px solid #ccc",
    },
  },
  text_name: {
    color: "#81CD67",
  },
  root: {
    padding: 5,
  },
  text_center: {
    textAlign: "center",
  },
});
const TableListItem = ({
  fio,
  is_contractor_accepted,
  is_final,
  is_send_chat,
  project_accepted,
  project_remont_comment,
  project_remont_id,
  project_remont_name,
  project_remont_url,
  project_remont_tk_url = "",
  record_date,
  isHistory,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addDefaultSnack } = useCustomSnackbar();
  const { request } = useHttp();
  const { downloadFile, downloading } = useDownloadFile();

  const onSubmit = async (data) => {
    const res = await request(
      `/partner/projects/accept/`,
      "post",
      data,
      {},
      { isModal: true }
    );
    if (res) {
      dispatch(setProjects(res));
      addDefaultSnack("Успешно");
      dispatch(showPopUp(false));
    }
  };

  const handleAcceptClick = () => {
    dispatch(
      showPopUp(true, MODALS_NAMES.confirmModal, {
        title: "Вы уверены что хотите утвердить проект?",
        btnLabel: "Утвердить",
        type: "default",
        onSubmit: () => onSubmit({ project_remont_id }),
      })
    );
  };

  const downloadTK = async () => {
    downloadFile(
      `/partner/projects/${project_remont_id}/`,
      "Проект (плюс ТК)",
      {
        responseType: "arraybuffer",
      },
      "pdf",
      "get"
    );
  };

  return (
    <CustomTableRow>
      <CustomTableCell
        className={classes.sm_cell}
        classes={{ root: classes.root }}
      >
        {project_remont_id}
      </CustomTableCell>
      <CustomTableCell
        className={classes.name_cell}
        classes={{ root: classes.root }}
      >
        <Typography
          component="span"
          className={clsx({ [classes.text_name]: is_contractor_accepted > 0 })}
          title={project_remont_name}
        >
          {project_remont_name}
        </Typography>
      </CustomTableCell>
      <CustomTableCell
        className={classes.sm_cell}
        classes={{ root: classes.root }}
      >
        <a
          href={`${MAIN_URL}${project_remont_url}`}
          target="_blank"
          className={classes.download}
          rel="noopener noreferrer"
        >
          Скачать
        </a>
        {downloading ? (
          <LoaderData small />
        ) : (
          <div onClick={downloadTK} className={classes.download}>
            Скачать (плюс ТК)
          </div>
        )}
      </CustomTableCell>
      <CustomTableCell
        className={classes.cell}
        classes={{ root: classes.root }}
      >
        {project_remont_comment}
      </CustomTableCell>
      <CustomTableCell
        className={classes.sm_cell}
        align="center"
        classes={{ root: classes.root }}
      >
        <Checkbox
          defaultChecked={Boolean(is_final)}
          disabled
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </CustomTableCell>
      <CustomTableCell
        className={classes.sm_cell}
        align="center"
        classes={{ root: classes.root }}
      >
        <Checkbox
          defaultChecked={Boolean(project_accepted)}
          disabled
          color="primary"
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </CustomTableCell>
      <CustomTableCell
        className={clsx(classes.cell, classes.text_center)}
        classes={{ root: classes.root }}
      >
        {is_send_chat ? (
          <Typography
            component="span"
            className={clsx(classes.send, classes.chat)}
          >
            Отправлен
          </Typography>
        ) : (
          <Typography
            component="span"
            className={clsx(classes.not_send, classes.chat)}
          >
            Не отправлен
          </Typography>
        )}
      </CustomTableCell>
      <CustomTableCell
        className={classes.cell}
        classes={{ root: classes.root }}
      >
        {fio}
      </CustomTableCell>
      <CustomTableCell
        className={classes.cell}
        classes={{ root: classes.root }}
      >
        {record_date}
      </CustomTableCell>
      {!isHistory && (
        <CustomTableCell
          className={classes.action_cell}
          classes={{ root: classes.root }}
        >
          <Box display="flex">
            <Button
              className={clsx(classes.btn, classes.btn_approve)}
              variant="contained"
              disabled={is_contractor_accepted > 0 || Boolean(is_final)}
              onClick={handleAcceptClick}
            >
              Уведомлен
            </Button>
          </Box>
        </CustomTableCell>
      )}
    </CustomTableRow>
  );
};

export default React.memo(TableListItem);
