import React from "react";
import MeasureListItem from "./MeasureListItem";

const MeasureList = ({ data, value, remont_id }) => {
  return (
    <>
      {!remont_id ? (
        <p style={{ textAlign: "center", padding: 10 }}>Выберите квартиру</p>
      ) : null}
      {!data.length && remont_id ? (
        <p style={{ textAlign: "center", padding: 10 }}>Нет данных</p>
      ) : null}
      {data?.map(el => {
        return <MeasureListItem key={el?.param_id} {...el} value={value} />;
      })}
    </>
  );
};

export default MeasureList;
