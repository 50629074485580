import instance from "../../../../services/api";

export const getTeamsData = async params => {
  return await instance(true)
    .get("/partner/teams/", { params })
    .then(res => res?.data);
};

export const getTeams = async () => {
  return await instance(true)
    .get("/partner/contractor_commons/teams/")
    .then(res => res?.data);
};

export const getDebtTypesData = async () => {
  return await instance(true)
    .get("/partner/commons/debt-types")
    .then(res => res?.data);
};

export const getHeadTeamEmployees = async () => {
  return await instance(true)
    .get("/partner/commons/head-team-employees")
    .then(res => res?.data);
};

export const getAllMasters = async params => {
  return await instance(true)
    .get("/partner/teams/masters/", {
      params
    })
    .then(res => res?.data);
};

export const getTeamMasters = async team_id => {
  return await instance(true)
    .get(`/partner/teams/${team_id}/masters/`)
    .then(res => res?.data);
};

export const fetchBik = () => {
  return instance(true)
    .get(`/partner/bank_bik/`)
    .then(res => res?.data);
};

export const getTeamMasterSpecializationsAPI = async params => {
  return await instance(true)
    .get(`/partner/team_masters/specialities/read/`, {
      params
    })
    .then(res => res?.data);
};

export const getMastersBySpecializationAPI = async body => {
  return await instance(true)
    .post(`/partner/team_masters/by_speciality/read/`, body)
    .then(res => res?.data);
};
export const getServiceMastersAPI = async params => {
  return await instance(true)
    .get(`partner/teams/service_masters/`, { params })
    .then(res => res?.data);
};
export const getExternalMastersAPI = async params => {
  return await instance(true)
    .get(`partner/teams/external_masters/`, { params })
    .then(res => res?.data);
};

export const getTeamMastersByRemont = async remont_id => {
  return await instance(true)
    .get(`/partner/remont/${remont_id}/brigada/`)
    .then(res => res?.data);
};

export const getMasterTypes = async () => {
  return await instance(true)
    .get(`/partner/master_types/`)
    .then(res => res?.data);
};

export const getContractors = async () => {
  return await instance(true)
    .get("/partner/contractor_commons/contractors/")
    .then(res => res?.data);
};

export const getIntegratedPaymentTypesAPI = async () => {
  return await instance(true)
    .get("/partner/integrated_payments/types/read/")
    .then(res => res?.data);
};

export const getIntegratedPaymentBalanceAPI = async integrated_payment_type_code => {
  return await instance(true)
    .post("/partner/integrated_payments/balance/all/read/", {
      integrated_payment_type_code
    })
    .then(res => res?.data);
};

export const getDebts = async params => {
  return await instance(true)
    .get(`/partner/debts/`, { params })
    .then(res => res?.data);
};

export const getTeamDebts = async (team_id, params) => {
  if (!team_id) return;
  return await instance(true)
    .get(`/partner/debts/team/${team_id}/`, { params })
    .then(res => res?.data);
};
export const getTeamMasterDocumentTypes = async () => {
  return await instance(true)
    .get(`/partner/commons/team_master_document_types/`)
    .then(res => res?.data);
};
export const getTeamMasterDocuments = async team_master_id => {
  return await instance(true)
    .get(`/partner/team_masters/${team_master_id}/documents/read/`)
    .then(res => res?.data);
};
export const uploadTeamMasterDocuments = async (team_master_id, body) => {
  return await instance(true)
    .post(`/partner/team_masters/${team_master_id}/documents/upload/`, body, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    .then(res => res?.data);
};
export const deleteTeamMasterDocument = async (
  team_master_id,
  team_master_document_id
) => {
  return await instance(true)
    .delete(
      `/partner/team_masters/${team_master_id}/documents/${team_master_document_id}/delete/`
    )
    .then(res => res?.data);
};
