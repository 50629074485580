import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import RemontWork from "./remontWork";
import {
  CustomTableCell,
  CustomTableRow
} from "../../components/common/CustomTable/CustomTable";

const useStyles = makeStyles({
  cell: {
    fontSize: 12
  },
  cell_first: {
    fontWeight: 400,
    fontSize: 12
  }
});

const TableRowList = ({ data = [] }) => {
  const classes = useStyles();

  return data.map((arr, idx) => (
    <CustomTableRow key={idx}>
      <CustomTableCell align="left" className={classes.cell}>
        {arr.resident_name}
      </CustomTableCell>
      <CustomTableCell
        component="th"
        scope="row"
        className={classes.cell_first}
      >
        {arr.flat_num}
      </CustomTableCell>
      <CustomTableCell align="left" className={classes.cell}>
        {arr.area}
      </CustomTableCell>
      <CustomTableCell align="left" className={classes.cell}>
        {arr.preset_name}
      </CustomTableCell>
      <CustomTableCell align="center" className={classes.cell}>
        {arr.remont_date_begin}
      </CustomTableCell>
      <CustomTableCell align="center" className={classes.cell}>
        {arr.remont_date_end}
      </CustomTableCell>
      <CustomTableCell align="center" className={classes.cell}>
        {arr.project_accepted_date}
      </CustomTableCell>
      <RemontWork data={arr.remont_works} />
    </CustomTableRow>
  ));
};

export default TableRowList;
