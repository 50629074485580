import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import EstimateTableItems from "./EstimateTableItems";
import { getSmetaDiff } from "../services/api";
import { useParams } from "react-router-dom";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../components/common/CustomTable/CustomTable";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";

export const useStyles = makeStyles({
  root: {
    minWidth: 275,
    padding: 0
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 18,
    color: "#494C62",
    fontWeight: 600,
    margin: "6px 0"
  },
  col: {
    width: "100px",
    padding: "7px !important",
    border: "1px solid rgb(224, 224, 224)"
  },
  col_main: {},
  col_super: {},
  row: {
    background: "#ECEEFD",
    verticalAlign: "middle",
    height: 41
  },
  textData: {
    color: "#000000",
    fontSize: 12,
    fontWeight: 400
  },
  pos: {
    fontSize: 12,
    fontWeight: 600,
    textAlign: "center"
  },
  id: {
    fontSize: 18,
    fontWeight: 600,
    color: "#494C62"
  },
  col_name: {
    width: "27%",
    textAlign: "left"
  },
  aprove_btn: {
    backgroundColor: "#0C4B86"
  },
  materialType: {
    color: "#9194A9",
    fontSize: 18,
    fontWeight: 500
  }
});
const EstimateTable = () => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [items, setItems] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getSmetaDiff({ remont_id })
      .then(res => {
        setLoading(false);
        setItems(res);
      })
      .catch(e => {
        addCloseSnack(e?.response?.data?.error);
        setLoading(false);
      });
  }, [remont_id, addCloseSnack]);

  return (
    <CustomTable isFetching={loading} isEmpty={!items?.length}>
      <CustomTableRow className={classes.row}>
        <CustomTableCell className={classes.col_name} rowSpan={2}>
          <Typography
            className={classes.pos}
            color="textSecondary"
            style={{ textAlign: "left" }}
          >
            Название
          </Typography>
        </CustomTableCell>
        <CustomTableCell className={classes.col} rowSpan={2}>
          <Typography className={classes.pos} color="textSecondary">
            Кол-во смета
          </Typography>
        </CustomTableCell>{" "}
        <CustomTableCell
          className={classes.col}
          colSpan={5}
          style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
        >
          <Typography className={classes.pos} color="textSecondary">
            Доп заявка
          </Typography>
        </CustomTableCell>{" "}
        <CustomTableCell className={classes.col} colSpan={6}>
          <Typography className={classes.pos} color="textSecondary">
            Статусы
          </Typography>
        </CustomTableCell>
      </CustomTableRow>
      <TableRow className={classes.row}>
        <CustomTableCell className={classes.col}>
          <Typography className={classes.pos} color="textSecondary">
            Не хватило материала
          </Typography>
        </CustomTableCell>
        <CustomTableCell className={classes.col}>
          <Typography className={classes.pos} color="textSecondary">
            Несправедливые замеры
          </Typography>
        </CustomTableCell>
        <CustomTableCell className={classes.col}>
          <Typography className={classes.pos} color="textSecondary">
            Изменения по ДС
          </Typography>
        </CustomTableCell>
        <CustomTableCell className={classes.col}>
          <Typography className={classes.pos} color="textSecondary">
            Черновые материалы
          </Typography>
        </CustomTableCell>
        <CustomTableCell
          className={classes.col}
          style={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
        >
          <Typography className={classes.pos} color="textSecondary">
            Повреждение
          </Typography>
        </CustomTableCell>
        <CustomTableCell className={classes.col}>
          <Typography className={classes.pos} color="textSecondary">
            Есть в наличии
          </Typography>
        </CustomTableCell>
        <CustomTableCell className={classes.col}>
          <Typography className={classes.pos} color="textSecondary">
            Утвержден подрядчиком
          </Typography>
        </CustomTableCell>{" "}
        <CustomTableCell className={classes.col}>
          <Typography className={classes.pos} color="textSecondary">
            Создан
          </Typography>
        </CustomTableCell>{" "}
        <CustomTableCell className={classes.col}>
          <Typography className={classes.pos} color="textSecondary">
            Нет в наличии
          </Typography>
        </CustomTableCell>
        <CustomTableCell className={classes.col}>
          <Typography className={classes.pos} color="textSecondary">
            Создано перемещение
          </Typography>
        </CustomTableCell>{" "}
        <CustomTableCell className={classes.col}>
          <Typography className={classes.pos} color="textSecondary">
            Отгруженно
          </Typography>
        </CustomTableCell>
      </TableRow>
      <EstimateTableItems items={items} />
    </CustomTable>
  );
};

export default EstimateTable;
