import React, { useEffect, useState } from "react";
import { RemontMastersWorksets } from "./RemontMastersWorksets";
import { getRemontAmount } from "./services";
import { Box, Divider, Typography } from "@material-ui/core";
import styles from "../../../components/features/teams/detail/team_detail.module.scss";
import { numberWithCommas } from "../../../utils";
import { RemontServiceMasters } from "./RemontServiceMasters";
import { BrigadaInfo } from "./BrigadaInfo";
import CreateIcon from "@material-ui/icons/Create";
import { useStyles } from "../index";
import { showPopUp } from "../../../services/redux/actions/app-actions";
import { MODALS_NAMES } from "../../../constants";
import { useDispatch } from "react-redux";

const Brigada = ({ remontData, setRemontData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState({});

  const { team_name, team_master_fio, team_id, remont_id } = remontData;

  useEffect(() => {
    const getAmount = async () => {
      const res = await getRemontAmount(remont_id);
      if (!res) return;
      setAmount(res);
    };
    getAmount();
  }, [remont_id]);

  const handleTeamClick = () => {
    dispatch(
      showPopUp(true, MODALS_NAMES.brigadaChange, {
        setData: setRemontData,
        remont_id,
        team_id,
      })
    );
  };
  return (
    <>
      <Box display={"flex"} alignItems={"flexStart"} gridGap={"30px"}>
        <Box className={classes.mr}>
          <Typography className={classes.title}>
            Бригада:
            <CreateIcon
              style={{
                width: 14,
                height: 14,
                cursor: "pointer",
                transform: "translate(3px,2px)",
                fill: "#b94a21",
              }}
              onClick={handleTeamClick}
            />
          </Typography>
          <Typography className={classes.subtitle}>{team_name}</Typography>
        </Box>
        <Box className={classes.mr}>
          <Typography className={classes.title}>Бригадир:</Typography>
          <Typography className={classes.subtitle}>
            {team_master_fio || ""}
          </Typography>
        </Box>
      </Box>
      <div className={styles.team_detail__amount_wrapper}>
        {amount?.amount !== undefined && (
          <div className={styles.team_detail__amount}>
            Общая сумма выплат: <span>{numberWithCommas(amount?.amount)}</span>
          </div>
        )}
      </div>
      <BrigadaInfo amount={amount} />
      <Divider className={styles.team_detail__divider} />
      <RemontMastersWorksets amount={amount} remont_id={remont_id} />
      <Divider className={styles.team_detail__divider} />
      <RemontServiceMasters remont_id={remont_id} />
    </>
  );
};

export default Brigada;
