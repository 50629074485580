import React, { useEffect, useState } from "react";
import SmetaMaterialsRow from "./SmetaMaterialsRow";
import { useParams } from "react-router-dom";
import { fetchSmetaMaterials } from "../services/api";
import { CustomLoader } from "../../../../components/common/CustomLoader";
import { makeStyles } from "@material-ui/core/styles";
import { useCustomSnackbar } from "../../../../utils/hooks/useCustomSnackbar";

export const useStyles = makeStyles({
  root: {
    border: "1px solid #D2D3D9",
    borderRadius: 4
  }
});
const SmetaMaterials = () => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [smetaMaterials, setSmetaMaterials] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchSmetaMaterials(remont_id)
      .then(res => {
        setLoading(false);
        setSmetaMaterials(res);
      })
      .catch(e => {
        addCloseSnack(e?.response?.data?.error);
        setLoading(false);
      });
  }, [remont_id, addCloseSnack]);

  const { draft_materials, finishing_materials } = smetaMaterials;

  if (loading) return <CustomLoader />;
  return (
    <div className={classes.root}>
      <SmetaMaterialsRow title="Черновые материалы" data={draft_materials} />
      <SmetaMaterialsRow
        title="Чистовые материалы"
        data={finishing_materials}
      />
    </div>
  );
};

export default SmetaMaterials;
