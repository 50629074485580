import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { CButton } from "../../../../common/buttons";
import { InputWarning } from "../../../../common/form/warnings";
import { AutoSuggest } from "../../../../common/form/AutoSuggest";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { CustomDatePicker } from "../../../../common/form/CustomDatePicker";
import { InputSumMask } from "../../../../common/form/InputSumMask";
import CustomInput from "../../../../common/form/CustomInput";
import { cc_format, formatPhoneNumber } from "../../../../../utils";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CallIcon from "@material-ui/icons/Call";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import { CustomLoader } from "../../../../common/CustomLoader";
import styles from "../add_cost_income.module.scss";
import { getStagesData } from "../../../../../services/redux/actions/remonts-actions";
import { addCostServices } from "./services";
import {
  getCostMastersData,
  getRemontsData,
  getTeamsData
} from "../../../../../services/redux/actions/app-actions";
import { payboxTaxesValue } from "../../../../../constants";

export const AddCostModal = ({ data, handleClose }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, dirtyFields },
    setValue,
    watch
  } = useForm();
  const { remonts, costMasters, stages, teams, modalLoading } = useSelector(
    state => state.appState
  );
  const { contractorCostTypes, costPaymentType, paymentTypes } = useSelector(
    state => state.accountingState
  );

  const [currentMaster, setCurrentMaster] = useState(null);
  const [mastersState, setMastersState] = useState(null);
  const [remontList, setRemontList] = useState(null);
  const type_id = watch("contractor_cost_type_id") || "";
  const remont_id = watch("remont_id");
  const current_remont_id = remont_id || Number(data?.remont_id);

  const isEditMode = !!data?.isEdit;
  const isPaymentMode = !!data?.paymentMode;
  const isMastersPaymentMode = !!data?.mastersPaymentMode;
  const isTeamMode = !!data?.team_id;
  const isRemontMode = !!data?.remont_id;
  const isAccountingMode = !isRemontMode && !isTeamMode;
  const isRemontPaymentMode = isRemontMode && isPaymentMode;

  const costTypeDefaultValue = data?.body?.contractor_cost_type_id || "";
  const current_type_id = dirtyFields?.contractor_cost_type_id
    ? type_id
    : costTypeDefaultValue;

  useEffect(() => {
    dispatch(getStagesData());
  }, [dispatch]);

  useEffect(() => {
    if (costMasters) setMastersState(costMasters);
  }, [costMasters]);

  useEffect(() => {
    if (isMastersPaymentMode) {
      dispatch(getCostMastersData());
      dispatch(getRemontsData());
      dispatch(getTeamsData());
    }
  }, [isMastersPaymentMode, dispatch]);

  const defaultRemont = useMemo(
    () => remonts?.find(item => item.remont_id === data?.remont_id),
    [remonts, data.remont_id]
  );

  const onTeamMasterChange = useCallback(
    async team_master_id => {
      if (!team_master_id) return;
      const masterInfo = await addCostServices.onTeamMasterChange(
        team_master_id,
        isPaymentMode
      );
      setCurrentMaster(masterInfo);
    },
    [isPaymentMode]
  );

  useEffect(() => {
    if (defaultRemont) onTeamMasterChange(defaultRemont?.team_master_id);
  }, [defaultRemont, onTeamMasterChange]);

  const getTeamRemontsData = useCallback(
    async team_id => {
      if (isRemontMode || !team_id) return;
      const res = await addCostServices.getTeamRemontsData(team_id);
      setRemontList(res);
    },
    [isRemontMode]
  );

  useEffect(() => {
    getTeamRemontsData(data.team_id);
  }, [data.team_id, getTeamRemontsData]);

  const getTeamMastersData = useCallback(
    async (team_id, initial) => {
      const res = await addCostServices.getTeamMastersData(team_id);
      const { team_masters, team_master_id } = res;
      setMastersState(team_masters);
      if (!initial) {
        setValue("team_master_id", team_master_id);
        await onTeamMasterChange(team_master_id);
      }
    },
    [setValue, onTeamMasterChange]
  );

  const refreshData = useCallback(() => {
    setMastersState(costMasters);
    setValue("team_id", "");
    setValue("team_master_id", "");
    setCurrentMaster(null);
  }, [costMasters, setValue]);

  const onClearRemont = useCallback(() => {
    if (!!remontList?.length) return;
    refreshData();
  }, [refreshData, remontList]);

  const onClearTeam = useCallback(() => {
    if (!isRemontMode) setRemontList(null);
    refreshData();
  }, [refreshData, isRemontMode]);

  const onTeamChange = useCallback(
    async (team_id, remont_id, initial = false) => {
      setCurrentMaster(null);
      if (remont_id) {
        const remont = remonts?.find(item => item?.remont_id === remont_id);
        setMastersState(remont ? [remont] : []);
      } else if (team_id) {
        await getTeamMastersData(team_id, initial);
        await getTeamRemontsData(team_id);
      } else {
        onClearTeam();
      }
    },
    [remonts, getTeamMastersData, getTeamRemontsData, onClearTeam]
  );

  useEffect(() => {
    const body = data?.body;
    if (!isDirty && body?.contractor_cost_type_id) {
      onTeamChange(body?.team_id, body?.remont_id, true);
      onTeamMasterChange(body?.team_master_id);
    }
  }, [onTeamChange, onTeamMasterChange, isDirty, data]);

  useEffect(() => {
    if (isPaymentMode && data.team_id) {
      onTeamChange(data.team_id);
    }
  }, [data.team_id, isPaymentMode, onTeamChange]);

  const onSubmit = async body => {
    const { onSubmit: submit } = data;
    const reqBody = {
      ...body,
      contractor_cost_id: data?.body?.contractor_cost_id,
      cost_sum: Number(body?.cost_sum),
      remont_id: body?.remont_id || Number(data?.remont_id) || "",
      paymentMode: isPaymentMode
    };
    if (isTeamMode) reqBody.team_id = data.team_id;
    return submit && (await submit(reqBody, data));
  };

  const getSelectedRemontData = useCallback(
    async remont_id => {
      if (!remont_id) return onClearRemont();
      if (!!remontList?.length) return;
      const res = await addCostServices.getRemontsTeamData(remont_id);
      const { team_masters, team_master_id, team_id } = res;
      setMastersState(team_masters);
      setValue("team_id", team_id);
      setValue("team_master_id", team_master_id);
      await onTeamMasterChange(team_master_id);
    },
    [onTeamMasterChange, setValue, onClearRemont, remontList]
  );

  const onRemontChange = useCallback(
    async remontId => {
      if (remonts && isRemontMode) {
        const activeRemont = remonts.find(item => item.remont_id === remontId);
        setValue("team_id", activeRemont?.team_id);
        setValue("team_master_id", activeRemont?.team_master_id);
      } else if (isAccountingMode) {
        await getSelectedRemontData(remontId);
      }
    },
    [remonts, isRemontMode, setValue, isAccountingMode, getSelectedRemontData]
  );

  const isStageType = useMemo(() => {
    if (!current_type_id || !contractorCostTypes) return false;
    const type = contractorCostTypes?.find(
      item => item?.contractor_cost_type_id === Number(current_type_id)
    );
    return type?.contractor_cost_type_code === "BY_STAGE";
  }, [current_type_id, contractorCostTypes]);

  const getMasterInfo = useCallback(() => {
    const mainPhone =
      currentMaster?.phone_numbers?.length &&
      currentMaster?.phone_numbers.find(item => item?.is_main_phone);
    return (
      <div className={styles.accounting__master}>
        {!!mainPhone && (
          <span title={"Номер телефона"}>
            <CallIcon
              className={styles.accounting__master_icon}
              width={15}
              height={15}
            />
            {formatPhoneNumber(mainPhone?.team_master_phone_number)}
          </span>
        )}
        {!!currentMaster?.iban && (
          <span title={"IBAN"}>
            {`${!!mainPhone ? "," : ""} `}
            <AccountBalanceWalletIcon
              className={styles.accounting__master_icon}
              width={15}
              height={15}
            />
            {cc_format(currentMaster?.iban, true)}
          </span>
        )}
        {!!currentMaster?.card_num && (
          <span title={"Номер банковской карты"}>
            {`${!!currentMaster?.iban ? "," : ""} `}
            <CreditCardIcon
              className={styles.accounting__master_icon}
              width={15}
              height={15}
            />
            {`${cc_format(currentMaster?.card_num)}`}
          </span>
        )}
      </div>
    );
  }, [currentMaster]);

  const currentRemonts = useMemo(() => {
    if (isRemontMode) return null;
    if (isTeamMode) return remontList;
    else return remontList || remonts;
  }, [remonts, remontList, isRemontMode, isTeamMode]);

  const teamsComputed = useMemo(() => {
    const remont_id = current_remont_id || data?.body?.remont_id;
    if (remont_id && isPaymentMode) {
      const remont = remonts?.find(item => item?.remont_id === remont_id);
      return remont ? [remont] : [];
    }
    return teams;
  }, [current_remont_id, teams, remonts, data, isPaymentMode]);

  if (!remonts || !costMasters || (!isTeamMode && !isEditMode && !teams))
    return <CustomLoader />;
  const title = isPaymentMode
    ? "Выплата PayBox"
    : ` ${isEditMode ? "Редактирование" : "Добавление"} расхода`;
  return (
    <div className={styles.accounting__cost_modal}>
      <div className={styles.accounting__header}>
        <h2 className={styles.accounting__title}>{title}</h2>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form
        className={styles.accounting__form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.accounting__form_item}>
          <Controller
            name="cost_sum"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputSumMask
                type="number"
                fullWidth
                integer={isPaymentMode}
                min={0}
                label={`Сумма${
                  isPaymentMode ? ` (+${payboxTaxesValue} комиссия)` : ""
                }`}
                placeholder="Введите сумму"
                wrapperClassname={styles.accounting__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.cost_sum || ""}
            rules={{
              required: true
            }}
          />
          {errors.cost_sum && <InputWarning>Это поле обязательно</InputWarning>}
        </div>
        {!isPaymentMode && (
          <div
            className={`${styles.accounting__form_item} ${styles.accounting__form_itemDif}`}
          >
            <Controller
              name="cost_date"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  type="text"
                  fullWidth
                  disableFuture
                  placeholder="Введите дату"
                  wrapperClassname={styles.accounting__input_wrapper}
                  value={value}
                  onChange={onChange}
                />
              )}
              defaultValue={data?.body?.cost_date || ""}
              rules={{
                required: true
              }}
            />
            {errors.cost_date && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}
        {(isPaymentMode ? !!costPaymentType : !!contractorCostTypes) && (
          <div className={`${styles.accounting__form_item}`}>
            <Controller
              name="contractor_cost_type_id"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <AutoSuggest
                    withMargin
                    list={isPaymentMode ? costPaymentType : contractorCostTypes}
                    showKey={"contractor_cost_type_name"}
                    valueKey={"contractor_cost_type_id"}
                    placeholder="Выберите тип расхода"
                    label="Выберите тип расхода"
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
              defaultValue={data?.body?.contractor_cost_type_id || ""}
              rules={{
                required: true
              }}
            />
            {errors.contractor_cost_type_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}
        {isPaymentMode && paymentTypes && (
          <div className={`${styles.accounting__form_item}`}>
            <Controller
              name="team_master_payment_type_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutoSuggest
                  withMargin
                  list={paymentTypes}
                  showKey={"team_master_payment_type_name"}
                  valueKey={"team_master_payment_type_id"}
                  value={value}
                  placeholder="Выберите тип выплаты"
                  label="Выберите тип выплаты"
                  onChange={onChange}
                />
              )}
              defaultValue={data?.body?.team_master_payment_type_id || ""}
            />
            {errors.team_master_payment_type_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}
        {!!currentRemonts && (
          <div className={`${styles.accounting__form_item}`}>
            <Controller
              name="remont_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutoSuggest
                  withMargin
                  list={currentRemonts}
                  showKey={"remont_information"}
                  valueKey={"remont_id"}
                  value={value}
                  placeholder="Выберите ремонт"
                  label="Выберите ремонт"
                  onChange={e => {
                    onRemontChange(e?.target?.value);
                    onChange(e);
                  }}
                />
              )}
              defaultValue={data?.body?.remont_id || ""}
            />
            {errors.remont_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}
        {isStageType && (
          <div className={`${styles.accounting__form_item}`}>
            <Controller
              name="stage_id"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <AutoSuggest
                    withMargin
                    list={stages}
                    showKey={"stage_name"}
                    valueKey={"stage_id"}
                    placeholder="Выберите этап"
                    label="Выберите этап"
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
              defaultValue={data?.body?.stage_id || ""}
              rules={{
                required: true
              }}
            />
            {errors.stage_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}
        {teams && !data?.team_id && !isRemontPaymentMode && (
          <div className={`${styles.accounting__form_item}`}>
            <Controller
              name="team_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutoSuggest
                  withMargin
                  list={teamsComputed}
                  showKey={"team_name"}
                  valueKey={"team_id"}
                  value={value}
                  placeholder="Выберите бригаду"
                  label="Выберите бригаду"
                  onChange={e => {
                    onTeamChange(e?.target?.value);
                    onChange(e);
                  }}
                />
              )}
              defaultValue={data?.body?.team_id || defaultRemont?.team_id || ""}
            />
            {errors.team_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}
        {mastersState && (
          <div className={`${styles.accounting__form_item}`}>
            <Controller
              name="team_master_id"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <AutoSuggest
                    withMargin
                    list={mastersState}
                    showKey={"team_master_fio"}
                    valueKey={"team_master_id"}
                    placeholder="Выберите мастера"
                    label="Выберите мастера"
                    onChange={e => {
                      onTeamMasterChange(e?.target?.value);
                      onChange(e);
                    }}
                    value={value}
                  />
                );
              }}
              defaultValue={
                data?.body?.team_master_id ||
                data?.main_master_id ||
                defaultRemont?.team_master_id ||
                ""
              }
            />
            {currentMaster && getMasterInfo()}
            {errors.team_master_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}

        {isPaymentMode && (
          <div className={styles.accounting__form_item}>
            <Controller
              name="knp"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputSumMask
                  type="text"
                  fullWidth
                  format={"###"}
                  label="КНП"
                  placeholder="Введите КНП"
                  wrapperClassname={styles.add_team__input_wrapper}
                  value={value}
                  onChange={onChange}
                  allowLeadingZeros={true}
                />
              )}
              rules={{
                required: false,
                maxLength: 3,
                minLength: 3
              }}
              defaultValue={data?.body?.knp || ""}
            />
            {errors.knp && (
              <InputWarning>
                {errors.knp?.type === "required"
                  ? "Это поле обязательно"
                  : "Не корректный КНП"}
              </InputWarning>
            )}
          </div>
        )}

        <div className={`${styles.accounting__form_item}`}>
          <Controller
            name="comment"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                type="text"
                fullWidth
                multiline
                label="Комментарий"
                placeholder="Введите комментарий"
                wrapperClassname={styles.add_team__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.comment || ""}
          />
        </div>
        <CButton
          loading={modalLoading}
          disabled={modalLoading}
          type="submit"
          className={styles.accounting__btn}
        >
          {isPaymentMode ? "Оплатить" : "Сохранить"}
        </CButton>
        {isPaymentMode && (
          <p className={styles.accounting__warning}>
            Оплата мгновенно уйдет получателю без возможности изменения или
            удаления!
          </p>
        )}
      </form>
    </div>
  );
};
