import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { RequestsFilters } from "./filters";
import { makeStyles } from "@material-ui/core/styles";
import { getRequests } from "./api";
import { format } from "date-fns";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { CustomDropdown } from "../../common/blocks/CustomDropdown";
import { useDispatch } from "react-redux";
import { showPopUp } from "../../../services/redux/actions/app-actions";
import { useHttp } from "../../../utils/hooks/http.hook";
import Box from "@material-ui/core/Box";
import { InputSumMask } from "../../common/form/InputSumMask";
import { CButton } from "../../common/buttons";
import { MODALS_NAMES } from "../../../constants";
import { CollapseBlock } from "../../common/CollapseBlock/collapseBlock";
import { RequestsTable } from "./requestsTable";
import { useParams } from "react-router-dom";
import { CustomSearch } from "../../common/form/CustomSearch";

export const Requests = ({ setRequestsCount, showFilters = false }) => {
  const { remont_id: remont_id_str } = useParams();
  const classes = useRequestsStyles();
  const dispatch = useDispatch();
  const { request } = useHttp();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const [isPending, startTransition] = useTransition();
  const [showActions] = useState(false);
  const [isFetching, setIsFetching] = useState(null);
  const [data, setData] = useState(null);
  const [checkedRequests, setCheckedRequests] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  const remont_id = useMemo(() => Number(remont_id_str), [remont_id_str]);
  const [lastFilters, setLastFilters] = useState({});
  const [searchFilters, setSearchFilters] = useState({
    remont_id: "",
    provider_request_id: "",
  });
  const [filters, setFilters] = useState({
    date_begin: format(new Date(), "dd.MM.yyyy"),
    date_end: format(new Date(), "dd.MM.yyyy"),
    resident_id: "",
    contractor_id: "",
    material_id: "",
    provider_request_status_id: "",
    remont_status_id: "",
  });

  const getRequestsReq = useCallback(
    async (body) => {
      try {
        setLastFilters(body);
        setIsFetching(true);
        const res = await getRequests(body);
        setIsFetching(false);
        if (res) {
          setCheckedRequests({});
          setRequestsCount && setRequestsCount(res?.length);
          startTransition(() => setData(res));
        }
      } catch (e) {
        setIsFetching(false);
      }
    },
    [startTransition, setRequestsCount]
  );
  const getRequestsData = useCallback(async () => {
    if (!filters.date_begin || !filters.date_end)
      return addCloseSnack("Обе даты должны быть введены!");
    await getRequestsReq(filters);
  }, [getRequestsReq, addCloseSnack, filters]);

  const handleChange = (e) => {
    setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (!remont_id) getRequestsData();
  }, [getRequestsData, remont_id]);

  const countChecked = useMemo(() => {
    const keys = Object.keys(checkedRequests);
    const checkedData = keys.map((item) => ({
      checked: checkedRequests[item],
    }));
    return checkedData.some((item) => item?.checked);
  }, [checkedRequests]);

  const onChangeShipDateSubmit = async (body) => {
    const provider_request_ids = Object.keys(checkedRequests)
      .filter((item) => !!checkedRequests[item])
      .map((item) => Number(item));
    const res = await request(
      "partner/provider_requests/change_dsh/",
      "post",
      {
        ...body,
        date_begin_for_filter: filters.date_begin,
        date_end_for_filter: filters.date_end,
        provider_request_ids,
      },
      {},
      { isModal: true }
    );
    if (res?.status) {
      setCheckedRequests({});
      setData(res?.provider_requests);
      addDefaultSnack("Успешно");
      dispatch(showPopUp(false));
    }
  };

  const onChangeRequestApproveSubmit = async (body) => {
    const provider_request_ids = Object.keys(checkedRequests)
      .filter((item) => !!checkedRequests[item])
      .map((item) => Number(item));
    const res = await request(
      "partner/provider_requests/approve/",
      "post",
      {
        ...body,
        date_begin_for_filter: filters.date_begin,
        date_end_for_filter: filters.date_end,
        provider_request_ids,
      },
      {},
      { isModal: true }
    );
    if (res?.status) {
      setCheckedRequests({});
      setData(res?.provider_requests);
      addDefaultSnack("Успешно");
      dispatch(showPopUp(false));
    }
  };

  const dropdownOptions = [
    { id: "change_ship", label: "Сменить дату отгрузки" },
    { id: "approve_requests", label: "Утвердить подрядчиком" },
  ];

  const onOptionClick = (id) => {
    switch (id) {
      case "change_ship": {
        dispatch(
          showPopUp(true, MODALS_NAMES.requestsDate, {
            onSubmit: onChangeShipDateSubmit,
            close: true,
            isGroup: true,
          })
        );
        break;
      }
      case "approve_requests": {
        dispatch(
          showPopUp(true, MODALS_NAMES.approveRequestModal, {
            onSubmit: onChangeRequestApproveSubmit,
            close: true,
          })
        );
        break;
      }
      default: {
      }
    }
  };
  const handleChangeSearch = (e) => {
    const timeout = setTimeout(() => {
      setSearchTerm(e.target.value);
      clearTimeout(timeout);
    }, 500);
  };

  const getDataBySearch = useCallback(async () => {
    if (!searchFilters.remont_id && !searchFilters.provider_request_id) {
      return await getRequestsData();
    }
    await getRequestsReq(searchFilters);
  }, [getRequestsData, getRequestsReq, searchFilters]);

  useEffect(() => {
    if (remont_id) getRequestsReq({ remont_id });
  }, [getRequestsReq, remont_id]);

  const onSearchFiltersChange = (e) => {
    setSearchFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div className={showFilters ? classes.wrapper : ""}>
      {showFilters && (
        <CollapseBlock>
          <div className={classes.header}>
            <RequestsFilters
              searchTerm={searchTerm}
              handleChangeSearch={handleChangeSearch}
              filters={filters}
              handleChange={handleChange}
            />
            {showActions && (
              <CustomDropdown
                disabled={!countChecked}
                options={dropdownOptions}
                onOptionClick={onOptionClick}
              />
            )}
          </div>
          <Box className={classes.filters}>
            <Box width={120}>
              <InputSumMask
                type="number"
                fullWidth
                min={0}
                name="provider_request_id"
                label="ID заявки"
                placeholder="Введите ID заявки"
                value={searchFilters.provider_request_id}
                onEnter={getDataBySearch}
                onChange={onSearchFiltersChange}
              />
            </Box>
            <Box width={120}>
              <InputSumMask
                type="number"
                fullWidth
                min={0}
                name="remont_id"
                label="ID ремонта"
                integer
                mask={false}
                placeholder="Введите ID ремонта"
                value={searchFilters.remont_id}
                onEnter={getDataBySearch}
                onChange={onSearchFiltersChange}
              />
            </Box>
            <CButton loading={isFetching} onClick={getDataBySearch}>
              Поиск
            </CButton>
          </Box>
        </CollapseBlock>
      )}
      {!showFilters && (
        <Box width={220} marginBottom={1}>
          <CustomSearch
            searchTerm={searchTerm}
            handleChange={handleChangeSearch}
          />
        </Box>
      )}
      <div className={showFilters ? classes.content : ""}>
        <RequestsTable
          data={data}
          setData={setData}
          getData={() => getRequestsReq(lastFilters)}
          isFetching={isFetching}
          searchTerm={searchTerm}
          isPending={isPending}
        />
      </div>
    </div>
  );
};
export const useRequestsStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 20,
  },
  filters: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    gap: 15,
  },
  content: {
    marginTop: 15,
  },
  collapse: {
    padding: "10px 0",
  },
  collapseTable: {
    color: "#0c355f",
    cursor: "pointer",
  },
  collapseTableActive: {
    color: "#4a80ff",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 15,
    marginBottom: 15,
    [theme.breakpoints.down(576)]: {
      flexWrap: "wrap",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },
  title: {
    color: "#0e1e4b",
    margin: "0 0 0 10px",
  },
  link: {
    color: "#0070d3",
  },
  providerName: {
    "& span": {
      fontWeight: 700,
      fontSize: 16,
    },
  },
  provider: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    "& > div": {
      fontSize: 13,
      paddingTop: 10,
      borderTop: "1px solid #ccc",
      "&:first-child": {
        borderTop: "none",
      },
      "&:last-child": {
        paddingBottom: 15,
      },
    },
  },
  requestName: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  tips: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 10,
  },
  tip: {
    borderRadius: 15,
    backgroundColor: "#5d6eb3",
    padding: "3px 7px",
    color: "#fff",
    fontSize: 12,
    width: "fit-content",
  },
  descriptionTip: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    flexWrap: "wrap",
  },
  icon: {
    cursor: "pointer",
    fill: "#0b64b7",
    fontSize: "20px",
  },
  addition: {
    backgroundColor: "#67c346",
  },
  service: {
    backgroundColor: "#d26130",
  },
  order: {
    backgroundColor: "#735fa9",
  },
}));
