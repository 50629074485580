import * as React from "react";
import { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddWorksRow from "./AddWorksRow";
import { CustomTable } from "../../../components/common/CustomTable/CustomTable";

export const useStyles = makeStyles(theme => ({
  table_cont: {
    background: "#fff",
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)"
  },
  table_head: {
    width: 200
  },
  header_type: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    color: "#9194A9"
  },
  type_item: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    color: "#494C62"
  },
  cell_sm: {
    width: 50,
    borderRight: "1px solid #F0F2F6"
  },
  cell_name: {
    borderRight: "1px solid #F0F2F6",
    [theme.breakpoints.down(1500)]: {
      width: "auto"
    }
  },
  cell: {
    width: 102,
    borderRight: "1px solid #F0F2F6"
  },
  banner: {
    backgroundColor: "#ECEEFD",
    borderRadius: "4px 4px 0px 0px",
    padding: "16px 23px",
    marginBottom: 20,
    marginTop: 20,
    "&:first-child": {
      marginTop: 0
    }
  },
  banner_item: {
    marginRight: 10,
    width: 150
  },
  active_row: {
    backgroundColor: "#ECEEFD"
  },
  search: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 4,
    "& input": {
      padding: "9px 9px 9px",
      width: "100%",
      fontSize: 14,
      lineHeight: "17px"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px !important"
    }
  },
  icon: {
    color: "#0C4B86",
    width: 24,
    height: 24
  },
  search_wrapper: {
    width: 335,
    marginBottom: 20
  }
}));

export default function AddWorksTable({
  ds_date,
  ds_sum,
  ds_type_name,
  ds_wset,
  posCode,
  ds_type,
  searchTerm
}) {
  const classes = useStyles();

  let headItems = [
    { label: "ID", className: classes.cell_sm },
    { label: "Название", className: classes.cell_name },
    { label: "Комната", className: classes.cell, align: "center" },
    { label: "Количество", className: classes.cell, align: "center" }
  ];
  if (posCode === "CONTRACTOR") {
    headItems = headItems.concat([
      { label: "Цена", className: classes.cell, align: "center" },
      { label: "Сумма", className: classes.cell, align: "center" }
    ]);
  }

  const computedData = useMemo(() => {
    const search = searchTerm.toLowerCase();
    if (!search) return ds_wset;
    return ds_wset?.filter(
      el =>
        el.room_name.toLowerCase().includes(search) ||
        el.wset_name.toLowerCase().includes(search) ||
        el.wset_price.toString().includes(search) ||
        el.wset_sum.toString().includes(search)
    );
  }, [ds_wset, searchTerm]);

  return (
    <>
      <Box display="flex" className={classes.banner}>
        <Box className={classes.banner_item}>
          <Typography className={classes.header_type}>Тип ДС</Typography>
          <Box component="span" className={classes.type_item}>
            {ds_type_name}
          </Box>
        </Box>
        <Box className={classes.banner_item}>
          <Typography className={classes.header_type}>Дата</Typography>
          <Box component="span" className={classes.type_item}>
            {ds_date}
          </Box>
        </Box>
        {posCode === "CONTRACTOR" ? (
          <Box className={classes.banner_item}>
            <Typography className={classes.header_type}>Сумма ДС</Typography>
            <Box component="span" className={classes.type_item}>
              {ds_sum}
            </Box>
          </Box>
        ) : null}
        <Box className={classes.banner_item}>
          <Typography className={classes.header_type}>Статус ДС</Typography>
          <Box component="span" className={classes.type_item}>
            {ds_type}
          </Box>
        </Box>
      </Box>

      <CustomTable headItems={headItems}>
        {computedData?.map((el, i) => {
          return (
            <AddWorksRow
              key={el.ds_wset_id}
              {...el}
              index={i}
              posCode={posCode}
            />
          );
        })}
      </CustomTable>
    </>
  );
}
