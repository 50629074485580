import React from "react";
import { PageTitle } from "../../components/common/pageTitle";
import RemontsProblem from "../remonts/RemontsProblem";
import { RemontWrapper } from "../../components/common/RemontWrapper";
import { CheckRemontPermission } from "../remonts/CheckRemontPermission";

export const RemontProblems = () => {
  return (
    <CheckRemontPermission>
      <RemontWrapper>
        <PageTitle title="Обращения" />
        <RemontsProblem singleRemont />
      </RemontWrapper>
    </CheckRemontPermission>
  );
};
