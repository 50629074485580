import React, { useEffect, useState } from "react";
import { getCheckListByWorksets } from "../services/api";
import { useParams } from "react-router-dom";
import { CustomLoader } from "../../../components/common/CustomLoader";
import { CheckListStagesTable } from "./CheckListStagesTable";
import { CustomAccordion } from "../../../components/common/blocks/CustomAccordion";

export const CheckListByWorksets = () => {
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [loading, setLoading] = useState(false);
  const [checklists, setChecklists] = useState([]);

  useEffect(() => {
    setLoading(true);
    getCheckListByWorksets(remont_id)
      .then(res => {
        setLoading(false);
        setChecklists(res?.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [remont_id]);

  return (
    <div>
      {loading ? (
        <CustomLoader />
      ) : (
        <div style={{ marginBottom: 5 }}>
          {checklists?.map(worksetGroup => {
            return (
              <CustomAccordion
                key={worksetGroup.work_set_check_group_id}
                label={worksetGroup?.work_set_check_group_name}
              >
                <div>
                  {worksetGroup.work_sets?.map(workSet => {
                    return (
                      <CustomAccordion
                        key={workSet?.work_set_id}
                        label={workSet?.work_set_name}
                      >
                        <CheckListStagesTable
                          data={[workSet]}
                          worksetMode
                          parentsItemProp={"check_list_parents"}
                          childrenItemProp={"check_list"}
                          remont_id={remont_id}
                        />
                      </CustomAccordion>
                    );
                  })}
                </div>
              </CustomAccordion>
            );
          })}
        </div>
      )}
    </div>
  );
};
