import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import NumberFormat from "react-number-format";

const useStyles = makeStyles({
  cell: {
    borderLeft: "1px solid #F0F2F6",
    color: "#000000",
    fontSize: 14,
    fontWeight: 400,
    verticalAlign: "top",
    padding: "5px !important"
  },
  cell_sum: {
    minWidth: 200,
    fontSize: 12,
    fontWeight: 600,
    borderLeft: "1px solid #F0F2F6",
    padding: "5px !important"
  },
  row: {
    "&:nth-child(even)": {
      backgroundColor: "#f9f9f9"
    }
  }
});

const TableContent = ({ data = {} }) => {
  const classes = useStyles();
  const row = [];

  Object.keys(data).forEach(function(key) {
    row.push(data[key]);
  });

  return row.map((arr, i) => (
    <TableRow key={i} className={classes.row}>
      <TableCell align="left" className={classes.cell}>
        {arr.remont_id}, {arr.resident_name}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {arr.flat_num}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {arr.stage_name}
      </TableCell>
      <TableCell component="th" scope="row" className={classes.cell_sum}>
        <NumberFormat
          value={arr.payment_amount}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
        />
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {arr.payment_comment}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {arr.specification_num}
      </TableCell>
      <TableCell align="left" className={classes.cell} />
    </TableRow>
  ));
};

export default TableContent;
