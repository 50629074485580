import React, { useEffect } from "react";
import {
  getContractorsData,
  getCostMastersData,
  getRemontsData,
  getTeamsData, setHeaderBack
} from "../../../services/redux/actions/app-actions";
import {
  getCostContractorTypesData,
  getCostContractorTypesFilterData,
  getCostPaymentObj,
  getIncomeContractorTypesData,
  getPaymentData,
  setAccountingAmount,
  setAccountingAmountTypes
} from "../../../services/redux/actions/accounting-actions";
import { useDispatch, useSelector } from "react-redux";
import { AccountingIncomes } from "./incomes";
import { AccountingCosts } from "./costs";
import styles from "./payment-types.module.scss";
import { numberWithCommas } from "../../../utils";
import { useNavigate, useParams } from "react-router";
import clsx from "clsx";
import { PageTitle } from "../../common/pageTitle";
import { PAGES_ROUTE_KEY } from "../../../constants";

export const AccountingLayout = ({
  type = "cost",
  team_id = "",
  mainMasterId,
  singleRemont,
  alternativeCost = false,
  isOwnPage = false,
  showFilters = true,
  isPaymentMode = false
}) => {
  if (type === "all") document.title = "Учет расходов/доходов";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = remont_id_str ? Number(remont_id_str) : "";

  const { resident } = useSelector(state => state.remontsState);
  const { accountingAmount, accountingAmountTypes } = useSelector(
    state => state.accountingState
  );

  useEffect(() => {
    const goBack = () => {
      navigate(`${PAGES_ROUTE_KEY.remontInfo}/${remont_id}`);
    };
    remont_id && singleRemont && dispatch(setHeaderBack(goBack));
    return () => {
      dispatch(setHeaderBack(null));
    };
  }, [dispatch, remont_id, navigate, singleRemont]);

  useEffect(() => {
    return () => {
      dispatch(setAccountingAmountTypes({ cost: null }));
      dispatch(setAccountingAmountTypes({ income: null }));
      dispatch(setAccountingAmount(null));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(getRemontsData());
    dispatch(getCostPaymentObj());
  }, [dispatch]);

  useEffect(() => {
    if (
      accountingAmountTypes?.cost !== null &&
      accountingAmountTypes?.income !== null
    ) {
      const amount = Number(
        (accountingAmountTypes?.income - accountingAmountTypes?.cost).toFixed(2)
      );
      dispatch(setAccountingAmount(amount));
    }
  }, [accountingAmountTypes.income, accountingAmountTypes.cost, dispatch]);

  useEffect(() => {
    if (type === "income" || type === "all") {
      dispatch(getContractorsData());
      dispatch(getIncomeContractorTypesData());
    }
  }, [type, dispatch]);

  useEffect(() => {
    if (type === "cost" || type === "all") {
      dispatch(getCostContractorTypesData());
      dispatch(getCostContractorTypesFilterData());
      dispatch(getPaymentData());
    }
  }, [type, dispatch]);

  useEffect(() => {
    if (type === "cost" || type === "all") {
      dispatch(getCostMastersData(team_id, remont_id));
    }
  }, [type, dispatch, team_id, remont_id]);

  useEffect(() => {
    if ((type === "cost" || type === "all") && !team_id) {
      dispatch(getTeamsData());
    }
  }, [type, dispatch, team_id]);

  return (
    <>
      {type === "all" && isOwnPage && (
        <PageTitle title={"Учет расходов и доходов"} />
      )}
      <div
        className={clsx(
          type === "all" ? styles.accounting__amount__alternative : "",
          isOwnPage ? "" : styles.accounting__page
        )}
      >
        {type === "all" && (
          <div className={styles.accounting__header_title}>
            <h2 className={styles.accounting__header_titleRemont}>
              {resident?.remont_id
                ? `ID: ${resident?.remont_id}, ${resident?.resident_name}, КВ ${resident?.flat_num}`
                : ""}
            </h2>
            <h2>
              Прибыль/убыток:
              <span
                className={`${styles.accounting__header_titleValue} ${
                  accountingAmount === 0 || accountingAmount === null
                    ? styles.accounting__amount_zero
                    : accountingAmount > 0
                    ? styles.accounting__amount_income
                    : styles.accounting__amount_cost
                }`}
              >
                {numberWithCommas(accountingAmount) || 0}
              </span>
            </h2>
          </div>
        )}
        {type === "cost" && (
          <AccountingCosts
            paymentMode={isPaymentMode}
            alternative={alternativeCost}
            remont_id={remont_id}
            showFilters={showFilters}
            team_id={team_id}
            mainMasterId={mainMasterId}
          />
        )}
        {type === "income" && <AccountingIncomes />}
        {type === "all" && (
          <div>
            <AccountingCosts
              paymentMode
              alternative
              remont_id={remont_id}
              showFilters={showFilters}
              team_id={team_id}
              mainMasterId={mainMasterId}
            />
            <AccountingIncomes alternative remont_id={remont_id} />
          </div>
        )}
      </div>
    </>
  );
};
