import { authAPI } from "./api";

export const doLogin = async (body) => {
  try {
    return await authAPI.login(body)
  } catch (e) {}
}
export const registerUser = async (body) => {
  try {
    await authAPI.register(body)
    return true
  } catch (e) {}
}
export const requestNewPassword = async (body) => {
  try {
    return await authAPI.forgetPassword(body)
  } catch (e) {}
}