import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import AwReportTable from "./AwReportTable";
import EmptyOrShow from "../../components/common/EmptyOrShow";
import { fetchResidentList } from "../Problems/api";
import { makeStyles } from "@material-ui/core/styles";
import { getReportDs } from "./api";
import { PageTitle } from "../../components/common/pageTitle";
import { WrapperScrollbar } from "../../components/common/WrapperScrollbar";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { CustomDatePicker } from "../../components/common/form/CustomDatePicker";
import { CustomSearch } from "../../components/common/form/CustomSearch";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

const useStyles = makeStyles({
  table_container: {
    height: "calc(100vh - 160px)",
    border: "1px solid #ccc",
    overflow: "auto",
    paddingRight: 5
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    alignItems: 'flex-end',
    marginBottom: 10
  }
});

const SCROLL_MAX_HEIGHT = 80;

const slicer = (arr = [], start = 0, finish = 15, search = "") => {
  if (!search) return arr?.slice(start, finish);
  return arr
    .filter(
      el =>
        el.flat_num.toString().includes(search) ||
        el.ds_sum.toString().includes(search) ||
        el.ds_date.includes(search) ||
        el.resident_name.toLowerCase().includes(search)
    )
    .slice(start, finish);
};

export const AdditionalWorksReport = () => {
  const classes = useStyles();
  const tableRef = useRef(null);

  const [works, setWorks] = useState([]);
  const [posCode, setPosCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { addCloseSnack } = useCustomSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const [filterState, setFilterState] = useState({
    resident_ids: null,
    ds_type: "",
    date_begin: null,
    date_end: null
  });
  const [table, setTable] = useState([]);
  const [step, setStep] = useState({
    start: 0,
    finish: 15
  });
  const [filterOptions, setFilterOptions] = useState({
    residentList: [],
    statusList: [
      {
        id: 1,
        status_value: "APPROVED",
        status_name: "Утвержден"
      },
      {
        id: 2,
        status_value: "CREATED",
        status_name: "Создан"
      }
    ]
  });

  const { ds_type, date_begin, date_end } = filterState;
  const { residentList, statusList } = filterOptions;

  const handleFilterChange = event => {
    setFilterState(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }));
  };

  const fetchReportDs = useCallback(
    async params => {
      setIsLoading(true);
      try {
        const data = await getReportDs(params);
        setIsLoading(false);
        if (data?.ds) {
          setWorks(data.ds);
          setTable(slicer(data.ds));
          setPosCode(data.position_code);
        }
      } catch (e) {
        const message = e?.response?.data?.error;
        message && addCloseSnack(message);
        setIsLoading(false);
      }
    },
    [addCloseSnack]
  );

  const handleChange = e => {
    setSearchTerm(e.target.value);
    if (tableRef.current.scrollTop > 200) {
      tableRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    fetchReportDs(filterState);
  }, [fetchReportDs, filterState]);

  useEffect(() => {
    fetchResidentList()
      .then(res => {
        res &&
          setFilterOptions(prev => ({
            ...prev,
            residentList: res?.data
          }));
      })
      .catch(e => {
        const message = e?.response?.data?.error;
        message && addCloseSnack(message);
      });
  }, [addCloseSnack]);

  const handleScroll = () => {
    if (table.length === works.length) return;
    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
    if (
      Math.trunc(scrollHeight - scrollTop) - clientHeight <=
      SCROLL_MAX_HEIGHT
    ) {
      const newData = slicer(works, step.finish, step.finish + 15, searchTerm);
      setStep({
        start: step.finish,
        finish: step.finish + 15
      });

      setTable([...table, ...newData]);
    }
  };

  useEffect(() => {
    if (works.length) {
      const timeout = setTimeout(() => {
        const newData = slicer(works, 0, 15, searchTerm);
        setTable(newData);
        setStep({
          start: 0,
          finish: 15
        });
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [searchTerm, works]);

  return (
    <>
      <PageTitle title={"Отчет по доп.работам"} />
      <div style={{ padding: 20 }}>
        <CollapseBlock className={classes.filters}>
          <Box width={300}>
            <CustomSearch value={searchTerm} handleChange={handleChange} />
          </Box>
          <Box width={250} className={classes.ml}>
            <AutoSuggest
              list={residentList}
              id="resident_id"
              value={filterState.resident_ids || []}
              showKey={"resident_name"}
              valueKey={"resident_id"}
              name={"resident_ids"}
              multiple
              placeholder="Выберите ЖК"
              label="Жилой комплекс"
              onChange={handleFilterChange}
            />
          </Box>
          <Box width={150} className={classes.ml}>
            <AutoSuggest
              list={statusList}
              value={ds_type}
              showKey={"status_name"}
              valueKey={"status_value"}
              name={"ds_type"}
              label={"Статус"}
              placeholder={"Все статусы"}
              onChange={handleFilterChange}
            />
          </Box>
          <Box width={150} className={classes.ml}>
            <CustomDatePicker
              fullWidth
              label={"Дата начала"}
              placeholder={"Выберите дату"}
              name="date_begin"
              value={date_begin}
              onChange={handleFilterChange}
            />
          </Box>
          <Box width={150} className={classes.ml}>
            <CustomDatePicker
              fullWidth
              label={"Дата окончания"}
              placeholder={"Выберите дату"}
              name={"date_end"}
              value={date_end}
              onChange={handleFilterChange}
            />
          </Box>
        </CollapseBlock>
        <EmptyOrShow loading={isLoading}>
          <WrapperScrollbar
            className={classes.table_container}
            onScroll={handleScroll}
            ref={tableRef}
          >
            {table.map((el, i) => {
              return <AwReportTable {...el} key={i} posCode={posCode} />;
            })}
          </WrapperScrollbar>
        </EmptyOrShow>
      </div>
    </>
  );
};
