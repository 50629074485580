import React, { useEffect, useState } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { getDiscrepanciesReport } from "../services/api";
import DownloadXlsx from "../../../components/common/DownloadXlsx";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom";
import { CustomLoader } from "../../../components/common/CustomLoader";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  container: {
    boxShadow: "none",
    borderRadius: 0
  },
  cell: {
    padding: "5px !important",
    border: "1px solid #F0F2F6"
  },
  bg_primary: {
    backgroundColor: "#ECEEFD"
  },
  bg_minimal: {
    backgroundColor: "#CBE0FF"
  },
  bg_sale: {
    backgroundColor: "#D8CBFF"
  },
  bg_consumption: {
    backgroundColor: "#FFE4CB"
  },
  bg_difference: {
    backgroundColor: "#FFCBCB"
  },
  btn_wrapper: {
    "& button": {
      margin: "0 0 20px"
    }
  }
});

const DiscrepanciesReport = () => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (remont_id) {
      setLoading(true);
      getDiscrepanciesReport(remont_id)
        .then(res => {
          setLoading(false);
          setTableData(res);
        })
        .catch(e => {
          addCloseSnack(e?.response?.data?.error);
          setLoading(false);
        });
    }
  }, [remont_id, addCloseSnack]);

  return (
    <>
      {loading ? (
        <CustomLoader />
      ) : (
        <>
          <Box className={classes.btn_wrapper}>
            <DownloadXlsx
              url={`/partner/measurements/remont/${remont_id}/smeta_diff_actual/xlsx/`}
              params={null}
              title="Отчет по расхождениям сметы"
            />
          </Box>
          <TableContainer component={Paper} className={classes.container}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_primary)}
                    rowSpan={2}
                  >
                    ID материала
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_primary)}
                    rowSpan={2}
                  >
                    Название материала
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_minimal)}
                    colSpan={2}
                  >
                    В минимальных
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_sale)}
                    colSpan={2}
                  >
                    В продажах
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_consumption)}
                    colSpan={2}
                  >
                    В расходе
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_primary)}
                    rowSpan={2}
                  >
                    Цена
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_primary)}
                    rowSpan={2}
                  >
                    Сумма
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_difference)}
                    colSpan={3}
                  >
                    Разница
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_primary)}
                    rowSpan={2}
                  >
                    Поставщик
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={clsx(classes.cell, classes.bg_minimal)}>
                    Смета
                  </TableCell>
                  <TableCell className={clsx(classes.cell, classes.bg_minimal)}>
                    Отпуск
                  </TableCell>
                  <TableCell className={clsx(classes.cell, classes.bg_sale)}>
                    Смета
                  </TableCell>
                  <TableCell className={clsx(classes.cell, classes.bg_sale)}>
                    Отпуск
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_consumption)}
                  >
                    Смета
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_consumption)}
                  >
                    Отпуск
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_difference)}
                  >
                    В мин
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_difference)}
                  >
                    В прод
                  </TableCell>
                  <TableCell
                    className={clsx(classes.cell, classes.bg_difference)}
                  >
                    В расх
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.map((el, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell
                        className={clsx(classes.cell, {
                          [classes.bg_difference]:
                            el.sell_diff_cnt > 0 ||
                            el.diff_cnt > 0 ||
                            el.consume_diff_cnt > 0
                        })}
                      >
                        {el.remont_id}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, {
                          [classes.bg_difference]:
                            el.sell_diff_cnt > 0 ||
                            el.diff_cnt > 0 ||
                            el.consume_diff_cnt > 0
                        })}
                      >
                        {el.material_name}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, classes.bg_minimal)}
                        align="right"
                      >
                        {el.smeta_cnt?.toFixed(1)}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, classes.bg_minimal)}
                        align="right"
                      >
                        {el.ship_cnt?.toFixed(1)}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, classes.bg_sale)}
                        align="right"
                      >
                        {el.sell_smeta_qty?.toFixed(1)}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, classes.bg_sale)}
                        align="right"
                      >
                        {el.sell_ship_qty?.toFixed(1)}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, classes.bg_consumption)}
                        align="right"
                      >
                        {el.consume_smeta_qty?.toFixed(1)}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, classes.bg_consumption)}
                        align="right"
                      >
                        {el.consume_ship_qty?.toFixed(1)}
                      </TableCell>
                      <TableCell className={classes.cell} align="right">
                        {el.price?.toFixed(1)}
                      </TableCell>
                      <TableCell className={classes.cell} align="right">
                        {el.summ?.toFixed(1)}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, {
                          [classes.bg_difference]: el.diff_cnt > 0
                        })}
                        align="right"
                      >
                        {el.diff_cnt?.toFixed(1)}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, {
                          [classes.bg_difference]: el.sell_diff_cnt > 0
                        })}
                        align="right"
                      >
                        {el.sell_diff_cnt?.toFixed(1)}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, {
                          [classes.bg_difference]: el.consume_diff_cnt > 0
                        })}
                        align="right"
                      >
                        {el.consume_diff_cnt?.toFixed(1)}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {el.provider_name}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default DiscrepanciesReport;
