import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { Draggable } from "react-beautiful-dnd";
import { showPopUp } from "../../services/redux/actions/app-actions";
import { useDispatch } from "react-redux";
import { setSideBarContent, toggleDrawer } from "../../services/redux/actions/problemsActions";
import { ClickAwayListener } from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import { MoreHoriz } from "@material-ui/icons";
import { MODALS_NAMES } from "../../constants";
import { getProblemColor } from "../../services";
import { ComplexIcon } from "../../components/common/blocks/Amount";

export const useStyles = makeStyles({
  card_header: {
    maxWidth: 395,
    padding: "10px 12px",
    boxShadow: "0px 5px 10px rgba(108, 108, 108, 0.1);",
    border: "1px solid #E2E3ED",
    borderRadius: "4px",
    backgroundColor: "#fff",
    cursor: "pointer",
    marginBottom: 10,
    position: "relative"
  },
  card_wrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px"
  },
  card_rate: {
    display: "inline-flex",
    alignItems: "center",
    padding: "6px 14px",
    textTransform: "uppercase",
    color: "#fff",
    borderRadius: "15px",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Montserrat, sans-serif"
  },
  card_icon: {
    marginLeft: "auto",
    cursor: "pointer",
    backgroundColor: "#F8FAFB",
    borderRadius: 7,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 7
  },
  card_text: {
    paddingBottom: 9,
    borderBottom: "1px solid #E7E9F1"
  },
  resident: {
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 10,
    display: "flex",
    alignItems: "center"
  },
  resident_info: {
    fontSize: 12
  },
  card_footer: {
    marginTop: 7
  },
  card_footer_text: {
    fontSize: 12,
    fontWeight: 500,
    color: "#A5A7B5"
  },
  card_executors: {
    fontSize: 12,
    fontWeight: 500,
    color: "#303030",
    textAlign: "right"
  },
  id: {
    fontSize: 14,
    fontWeight: 500,
    color: "#72768F"
  },
  date: {
    fontSize: 10,
    fontWeight: 400
  },
  dragging: {
    backgroundColor: "lightgreen"
  },
  popper: {
    top: 24,
    right: 41,
    position: "absolute",
    width: 100,
    background: "#fff",
    zIndex: 20,
    padding: 5
  },
  popper_item: {
    fontSize: 16,
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  },
  popper_icon: {
    color: "#0C4B86",
    width: 20,
    height: 20,
    display: "inline-block",
    marginRight: 5
  }
});
const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});
const Card = ({
  card_id,
  card_title,
  date_create,
  index,
  executors,
  priority_name,
  col_id,
  priority_id,
  resident_name,
  flat_num,
  card_type_name
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <Draggable draggableId={card_id.toString()} index={index}>
        {provided => (
          <Paper
            className={classes.card_header}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Box className={classes.card_wrapper}>
              <Box style={{ width: "80px" }}>
                <Typography className={classes.id}>№{card_id}</Typography>
                <Typography className={classes.date}>{date_create}</Typography>
              </Box>
              {priority_name ? (
                <Box
                  className={classes.card_rate}
                  component="span"
                  style={{ backgroundColor: getProblemColor(priority_id) }}
                >
                  {priority_name}
                </Box>
              ) : null}

              <Box className={classes.card_icon}>
                <MoreHoriz
                  style={{ color: "#0C4B86" }}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </Box>
            </Box>
            <Box
              className={classes.card_text}
              onClick={() => {
                dispatch(
                  showPopUp(true, MODALS_NAMES.singleCardModal, {
                    card_id,
                    col_id
                  })
                );
              }}
            >
              <Typography className={classes.resident}>
                <ComplexIcon/>
                {resident_name}, КВ {flat_num}
              </Typography>{" "}
              <Typography className={classes.resident}>{card_title}</Typography>{" "}
              <Typography className={classes.resident_info}>
                Подробное описание обращения показывается внутри карточки
              </Typography>
            </Box>
            <Box className={classes.card_footer}>
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Typography className={classes.card_footer_text}>
                  Тип обращения:
                </Typography>
                <Typography className={classes.card_executors}>
                  {card_type_name}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography className={classes.card_footer_text}>
                  Ответственный:
                </Typography>
                <Typography className={classes.card_executors}>
                  {executors}
                </Typography>
              </Box>
            </Box>
            {open ? (
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Paper elevation={3} className={classes.popper}>
                  <Box
                    onClick={() => {
                      dispatch(setSideBarContent("История", card_id));
                      dispatch(toggleDrawer(true));
                      setOpen(false);
                    }}
                    className={classes.popper_item}
                  >
                    <HistoryIcon className={classes.popper_icon} /> История
                  </Box>
                </Paper>
              </ClickAwayListener>
            ) : null}
          </Paper>
        )}
      </Draggable>
    </ThemeProvider>
  );
};

export default Card;
