import React, { useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Paper from "@material-ui/core/Paper";
import TableRowList from "./tableRowList";
import { CustomTable } from "../../components/common/CustomTable/CustomTable";

const useStyles = makeStyles(theme => ({
  row_table: {
    marginBottom: 10,
    verticalAlign: "top",
    borderRadius: 4,
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)",
    maxWidth: 1400,
    [theme.breakpoints.up("xl")]: {
      maxWidth: 1800
    },
    overflowX: "auto"
  },
  cell: {
    fontSize: 12
  },
  cell_first: {
    fontWeight: 400,
    fontSize: 12
  },
  res_name: {
    fontWeight: 600,
    fontSize: 18,
    marginLeft: 10
  }
}));

const headItemsDefault = [
  { label: "ЖК" },
  { label: "№Кв" },
  { label: "Площадь" },
  { label: "Пакет", width: 100 },
  { label: "Дата начала", width: 90 },
  { label: "Дата сдачи", width: 90 },
  { label: "Проект", width: 90 }
];

const ReportTable = ({ data = {}, isFetching }) => {
  const classes = useStyles();
  const { works, remonts } = data;

  const headItems = useMemo(() => {
    if (!works?.length) return headItemsDefault;
    const worksHeadItems = works?.map(item => ({ label: item?.work_name }));
    return [...headItemsDefault, ...worksHeadItems];
  }, [works]);

  return (
    <>
      <Typography variant="h6" gutterBottom className={classes.res_name}>
        {data["resident_name"]}
      </Typography>
      <Paper className={classes.row_table}>
        <CustomTable
          isFetching={isFetching}
          headItems={headItems}
          isEmpty={!remonts?.length}
        >
          <TableRowList data={remonts} />
        </CustomTable>
      </Paper>
    </>
  );
};

export default ReportTable;
