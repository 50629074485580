import React, { useEffect } from "react";
import { Box, Button, InputAdornment, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { showPopUp } from "../../services/redux/actions/app-actions";
import SearchIcon from "@material-ui/icons/Search";
import {
  setCardTypes,
  setPriorities,
  setResidentsForFilters,
  setSearchTerm
} from "../../services/redux/actions/problemsActions";
import { fetchCardTypes, fetchPriorities, fetchResidentList } from "./api";
import ProblemsSideBar from "./ProblemsSideBar";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { MODALS_NAMES } from "../../constants";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

const useStyles = makeStyles(theme => ({
  btn: {
    backgroundColor: "#3060c7",
    color: "#fff",
    padding: "13px 20px",
    height: 40,
    textTransform: "none",
    marginRight: 24,
    flexShrink: 0,

    "&:hover": {
      backgroundColor: "#1d51b7"
    }
  },
  plus: {
    padding: 5,
    backgroundColor: "#fff",
    color: "#3060c7",
    borderRadius: "50%",
    width: 30,
    height: 30,
    marginRight: 15
  },
  search: {
    width: 335,
    "& input": {
      padding: "9px 9px 9px",
      width: "100%",
      fontSize: 14,
      lineHeigh: "17px"
    },
    [theme.breakpoints.down(768)]: {
      margin: "10px 0"
    },
    [theme.breakpoints.down(576)]: {
      width: '100%',
    }
  },
  icon: {
    color: "#0C4B86",
    width: 24,
    height: 24
  },
  filter_wrapper: {
    width: 150,
    marginLeft: 20,
    marginRight: 10,
    "& .filter-field": {
      margin: "0 !important"
    },
    [theme.breakpoints.down(768)]: {
      marginLeft: 0,
      marginBottom: 10
    }
  },
  multi_select: {
    maxWidth: 220,
    marginLeft: 20,
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%"
    },
    [theme.breakpoints.down(768)]: {
      marginLeft: 0,
      marginBottom: 10
    }
  },
  form_control: {
    width: "100%"
  },
  header: {
    display: 'flex',
    marginBottom: 10,
    alignItems: 'flex-end',
    [theme.breakpoints.down(991)]: {
      flexWrap: "wrap"
    }
  }
}));

const ProblemsHeader = ({ values, setValues }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addCloseSnack } = useCustomSnackbar();
  const { searchTerm, residentsForFilter, cardTypes, priorities } = useSelector(
    state => state.problemsState
  );

  const setFieldValue = (name, newValue) => {
    setValues(prev => ({
      ...prev,
      [name]: newValue
    }));
  };

  const handleChange = e => {
    dispatch(setSearchTerm(e.target.value));
  };

  useEffect(() => {
    fetchResidentList()
      .then(res => {
        dispatch(setResidentsForFilters(res?.data));
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [dispatch, addCloseSnack]);

  useEffect(() => {
    fetchCardTypes()
      .then(res => {
        dispatch(setCardTypes(res?.data));
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [dispatch, addCloseSnack]);

  useEffect(() => {
    fetchPriorities()
      .then(res => {
        dispatch(setPriorities(res?.data));
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [dispatch, addCloseSnack]);

  return (
    <CollapseBlock className={classes.header}>
      <Button
        variant="contained"
        className={classes.btn}
        onClick={() =>
          dispatch(showPopUp(true, MODALS_NAMES.problemsModal, {}))
        }
      >
        <AddIcon className={classes.plus} /> Добавить
      </Button>

      <TextField
        className={classes.search}
        id="outlined-search"
        type="search"
        variant="outlined"
        placeholder="Быстрый поиск..."
        value={searchTerm}
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className={classes.icon} />
            </InputAdornment>
          )
        }}
      />

      <Box className={classes.filter_wrapper}>
        <AutoSuggest
          list={residentsForFilter}
          name="resident_id"
          showKey={"resident_name"}
          valueKey={"resident_id"}
          value={values.resident_id}
          placeholder="Все жк"
          label="Жилой комплекс"
          onChange={e => setFieldValue(e.target.name, e.target.value)}
        />
      </Box>
      <Box className={classes.multi_select}>
        <AutoSuggest
          list={cardTypes}
          name="card_type_ids"
          showKey={"card_type_name"}
          valueKey={"card_type_id"}
          value={values.card_type_ids}
          label="Тип обращения"
          placeholder={`Все типы`}
          multiple
          onChange={e => setFieldValue(e.target.name, e.target.value)}
        />
        <Typography
          className="mb-1"
          style={{ color: "rgba(0, 0, 0, 0.45)" }}
        ></Typography>
      </Box>
      <Box className={classes.filter_wrapper}>
        <AutoSuggest
          list={priorities}
          name="priority_id"
          showKey={"priority_name"}
          valueKey={"priority_id"}
          value={values.priority_id}
          placeholder="Все приоритеты"
          label="Приоритеты"
          className={classes.select}
          onChange={e => setFieldValue(e.target.name, e.target.value)}
        />
      </Box>
      <ProblemsSideBar />
    </CollapseBlock>
  );
};

export default ProblemsHeader;
