import { PageTitle } from "../../components/common/pageTitle";
import React from "react";
import styles from "./styles.module.scss";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { PAGES } from "../../constants";
import clsx from "clsx";

export const ForContractors = () => {
  const classes = useStyles()
  return <div>
    <PageTitle title={"Подрядчикам"} />
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          <p>
            Положительные отзывы о нашей работе приводят к нам новых клиентов, и мы рады сообщить о расширении штата
            сотрудников
            и партнёров. Так как мы дорожим качеством нашей компании, мы выбираем только лучших из лучших.
            Предлагаем ознакомиться с нашими стандартами и заполнить анкету, мы внимательно изучим указанную вами
            информацию
            и обязательно свяжемся с Вами!
          </p>
          <p>Если наши взгляды на процессы и качество отделочных работ близки, мы встретимся с
            Вами и обсудим детали и условия сотрудничества.
            В случае затруднения с информацией по заполнению анкет или возникновения вопросов
            Вы можете обратиться к нашим сотрудникам.
          </p>
          <div className={styles.standards}>
            <h1>Стандарты компании</h1>
            <a target={'_blank'} rel="noopener noreferrer" href="https://office.smartremont.kz/documents/sr_standard_docs/sr_mf_culture.pdf"><DownloadIcon/> Культура производства</a>
          </div>
          <div className={clsx(styles.btns, styles.btns__big)}>
            <Link to={`${PAGES.forContractors}/contractor`}><Button className={classes.btn} color={'primary'}>Заполните анкету подрядчика</Button></Link>
            <Link to={`${PAGES.forContractors}/master`}><Button className={classes.btn} color={'primary'}>Заполните анкету мастера</Button></Link>
          </div>
        </div>
        <img className={styles.img} src={'https://office.smartremont.kz/documents/2023.01.30/front_partner_images/front_partner_image_63d74f34201134.04340566.jpg'} alt="" />
      </div>
    </div>
  </div>;
};
const useStyles = makeStyles({
  btn: {
    padding: 10,
    borderRadius: 4,
    color: "#fff",
    backgroundColor: "#0C4B86",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#1767b2"
    }
  }
})