import React from "react";
import { Box } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { MAIN_URL } from "../../constants";

export const useStyles = makeStyles({
  file: {
    fontSize: 11,
    fontWeight: 500,
    color: "#0C4B86",
    display: "inline-block",
    marginRight: 10,
    textDecoration: "underline",
    cursor: "pointer"
  },
  span: {
    fontSize: 15,
    fontWeight: 500,
    color: "#0C4B86",
    marginRight: 10,
  }
});

const CommentsFile = ({
                        file_path,
                        card_file_id,
                        file_name,
                        card_id,
                        handleDelete, clickable = true
                      }) => {
  const classes = useStyles();

  return (
    <Box style={{ display: "inline-block", marginRight: 25 }}>
      {clickable ? <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${MAIN_URL}${file_path}`}
        className={classes.file}
      >
        {file_name}
      </a> : <span className={classes.span}>{file_name}</span>}

      <CloseIcon
        onClick={() => handleDelete(card_id, card_file_id)}
        style={{
          color: "#FF6262",
          width: 15,
          height: 15,
          transform: "translate(-5px,5px)",
          border: "1px solid #FF6262",
          cursor: "pointer"
        }}
      />
    </Box>
  );
};

export default CommentsFile;
