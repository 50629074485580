import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { getProrabList } from "../services/api";
import Button from "@material-ui/core/Button";
import { AutoSuggest } from "../../../../components/common/form/AutoSuggest";
import { useCustomSnackbar } from "../../../../utils/hooks/useCustomSnackbar";
import { useHttp } from "../../../../utils/hooks/http.hook";
import { CButton } from "../../../../components/common/buttons";

const ChangeProrabModal = ({ data, handleClose }) => {
  const classes = useStyles();
  const { request, loading } = useHttp();
  const { remont_id, setData } = data;
  const [prorab, setProrab] = useState(data?.prorab_employee_id || "");
  const [prorabList, setProrabList] = useState([]);
  const { addDefaultSnack } = useCustomSnackbar();

  useEffect(() => {
    getProrabList()
      .then(res => {
        setProrabList(res.data);
      })
      .catch(() => {
      });
  }, []);

  const handleChange = e => {
    setProrab(e.target.value);
  };

  const handleClick = async () => {
    const res = await request(`/partner/remont/${remont_id}/prorab/`, "post", {
      employee_id: prorab
    });
    if (res) {
      setData(res);
      handleClose();
      addDefaultSnack("Успешно");
    }
  };

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.title}>Изменить прораба</Typography>
      <Box>
        <AutoSuggest
          withMargin
          list={prorabList}
          showKey={"fio"}
          valueKey={"employee_id"}
          label="Выберите прораба"
          value={prorab}
          onChange={handleChange}
        />
        <Box display="flex" mt={2}>
          <Button
            className={classes.btn}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Отменить
          </Button>
          <CButton
            className={classes.btn}
            onClick={handleClick}
            loading={loading}
            disabled={!Boolean(prorab) || loading}
          >
            Сохранить
          </CButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangeProrabModal;

export const useStyles = makeStyles({
  wrapper: {
    width: "300px !important",
    paddingBottom: 10
  },
  select: {
    width: 200
  },
  title: {
    fontSize: 17,
    fontWeight: 500,
    color: "#0C4B86"
  },
  btn: {
    height: "44px !important",
    width: "150px !important",
    padding: 10,
    borderRadius: 4,
    "&:first-child": {
      marginRight: 15
    }
  }
});
