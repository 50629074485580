import instance from "../../services/api";

export const getMaterialsList = () => {
  return instance(true).get("/partner/material_requests_search/materials/");
};

export const searchMaterialRequests = params => {
  return instance(true).post(
    "/partner/material_requests_search/requests/",
    params
  );
};

export const fetchStatuses = () => {
  return instance(true).get("/partner/provider_request_statuses/");
};
