import { Requests } from "components/features/requests";
import { checkRemontPermission } from "./api";
import RemontInfo from "../RemontInfo";
import Measure from "../measure/Measure";
import { RemontWorksets } from "../Works";
import BrigadaInfo from "../brigada";
import Stages from "../Stages";
import { AccountingLayout } from "components/features/accounting/layout";
import Statuses from "../Statuses";
import Projects from "../Projects";
import RemontsProblem from "../RemontsProblem";
import Gpr from "../RemontInfo/Gpr/Gpr";
import TextConstructor from "../RemontInfo/TextConstructor/TextConstructor";
import SmetaMaterials from "../RemontInfo/SmetaMaterials/SmetaMaterials";
import Documents from "../Documents";
import { CheckList } from "../CheckList";
import AdditionalWorks from "../AdditionalWorks";
import DiscrepanciesReport from "../estimate/DiscrepanciesReport";
import { KeyReceive } from "../KeyReceive";
import EstimateTable from "../estimate/EstimateTable";
import Chat from "../Chat";

export const checkRemontPermissionData = async (remont_id) => {
  if (!remont_id) return true;
  if (isNaN(remont_id)) return false;
  try {
    const res = await checkRemontPermission(remont_id);
    return !!res?.status;
  } catch (e) {
    return false;
  }
};

export const remontTabKeys = {
  remontInfo: "P__RemontInfoTab",
  remontInfoRequest: "P__RemontInfoRequestTab",
  remontInfoMeasure: "P__RemontInfoMeasureTab",
  remontInfoWork: "P__RemontInfoWorkTab",
  remontInfoMaster: "P__RemontInfoMasterTab",
  remontInfoStage: "P__RemontInfoStageTab",
  remontInfoAccounting: "P__RemontInfoAccountingTab",
  remontInfoStatus: "P__RemontInfoStatusTab",
  remontInfoProject: "P__RemontInfoProjectTab",
  remontInfoProblems: "P__RemontInfoProblemsTab",
  remontInfoGPR: "P__RemontInfoGPRTab",
  remontInfoTK: "P__RemontInfoTKTab",
  remontInfoMaterialSmeta: "P__RemontInfoMaterialSmetaTab",
  remontInfoMaterialShipHistory: "P__RemontInfoMaterialShipHistoryTab",
  remontInfoKey: "P__RemontInfoKeyTab",
  remontInfoSmetaDiscrepancy: "P__RemontInfoSmetaDiscrepancyTab",
  remontInfoChat: "P__RemontInfoChatTab",
  remontInfoAdditionalWork: "P__RemontInfoAdditionalWorkTab",
  remontInfoCheckList: "P__RemontInfoCheckListTab",
  remontInfoDocument: "P__RemontInfoDocumentTab",
};

export const remontTabComponents = {
  [remontTabKeys.remontInfo]: {
    component: RemontInfo,
    props: { padding: 0, background: "#fff" },
  },
  [remontTabKeys.remontInfoRequest]: {
    component: Requests,
    props: { padding: 21 },
  },
  [remontTabKeys.remontInfoMeasure]: {
    component: Measure,
    props: { padding: 21 },
  },
  [remontTabKeys.remontInfoWork]: { component: RemontWorksets, props: {} },
  [remontTabKeys.remontInfoMaster]: { component: BrigadaInfo, props: {} },
  [remontTabKeys.remontInfoStage]: { component: Stages, props: {} },
  [remontTabKeys.remontInfoAccounting]: {
    component: AccountingLayout,
    propsComponent: { type: "all", showFilters: false },
  },
  [remontTabKeys.remontInfoStatus]: { component: Statuses, props: {} },
  [remontTabKeys.remontInfoProject]: { component: Projects, props: {} },
  [remontTabKeys.remontInfoProblems]: { component: RemontsProblem, props: {} },
  [remontTabKeys.remontInfoGPR]: { component: Gpr, props: {} },
  [remontTabKeys.remontInfoTK]: { component: TextConstructor, props: {} },
  [remontTabKeys.remontInfoMaterialSmeta]: {
    component: SmetaMaterials,
    props: {},
  },
  [remontTabKeys.remontInfoMaterialShipHistory]: {
    component: EstimateTable,
    props: {},
  },
  [remontTabKeys.remontInfoKey]: { component: KeyReceive, props: {} },
  [remontTabKeys.remontInfoSmetaDiscrepancy]: {
    component: DiscrepanciesReport,
    props: {},
  },
  [remontTabKeys.remontInfoChat]: { component: Chat, props: {} },
  [remontTabKeys.remontInfoAdditionalWork]: {
    component: AdditionalWorks,
    props: {},
  },
  [remontTabKeys.remontInfoCheckList]: { component: CheckList, props: {} },
  [remontTabKeys.remontInfoDocument]: { component: Documents, props: {} },
};
