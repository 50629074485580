import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Message from "./Message";
import MessageForm from "./MessageForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessages } from "./api";
import { setMessages } from "../../../services/redux/actions/remonts-actions";
import { useNavigate, useParams } from "react-router";
import { CustomLoader } from "../../../components/common/CustomLoader";
import { WrapperScrollbar } from "../../../components/common/WrapperScrollbar";
import { NotFound } from "../../../components/common/text";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { PAGES_ROUTE_KEY } from "../../../constants";
import { setHeaderBack } from "../../../services/redux/actions/app-actions";

const useStyles = makeStyles({
  list: {
    padding: 24,
    height: "78vh",
    overflow: "auto"
  },
  send_form: {
    height: 50,
    padding: "10px 17px",
    backgroundColor: "#F4F5F7"
  }
});

const MessagesList = ({singleRemont}) => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const { messages } = useSelector(state => state.remontsState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refCont = useRef(null);
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const goBack = () => {
      navigate(`${PAGES_ROUTE_KEY.remontInfo}/${remont_id}`);
    };
    remont_id && singleRemont && dispatch(setHeaderBack(goBack));
    return () => {
      dispatch(setHeaderBack(null));
    };
  }, [dispatch, remont_id, navigate, singleRemont]);

  useEffect(() => {
    if (remont_id) {
      setLoading(true);
      fetchMessages(remont_id)
        .then(res => {
          setLoading(false);
          dispatch(setMessages(res));
          refCont.current.scrollTop = refCont.current.scrollHeight;
        })
        .catch(e => {
          addCloseSnack(e?.response?.data?.error);
          setLoading(false);
        });
    }
  }, [remont_id, dispatch, addCloseSnack]);

  return (
    <>
      <WrapperScrollbar className={classes.list} ref={refCont}>
        {loading ? (
          <CustomLoader />
        ) : messages?.length ? (
          messages?.map(el => {
            return <Message {...el} key={el.stage_chat_id} />;
          })
        ) : (
          <NotFound>Нет сообщений</NotFound>
        )}
      </WrapperScrollbar>
      <MessageForm remont_id={remont_id} />
    </>
  );
};

export default MessagesList;
