import React, { useEffect, useState } from "react";
import { ContractorsForm } from "./contractorsForm";
import { useParams } from "react-router";
import { PageTitle } from "../../components/common/pageTitle";
import styles from "./styles.module.scss";
import { getContractorsData } from "./services";
import { MasterForm } from "./masterForm";
import { Checkbox } from "@material-ui/core";

export const ForContractorsForm = () => {
  const { formType } = useParams();
  const [data, setData] = useState({});

  useEffect(() => {
    const getData = async () => {
      const type = formType === "contractor" ? 1 : 2;
      const res = await getContractorsData(type);
      if (!res) return;
      setData(res);
    };
    getData();
  }, [formType]);

  return <div>
    <PageTitle title={`Анкета ${formType === "contractor" ? "подрядчика" : "мастера"}`} />
    <div className={styles.wrapper}>
      {formType === "contractor" && <ContractorsForm data={data} />}
      {formType === "master" && <MasterForm data={data} />}
    </div>
  </div>;
};

export const CheckBoxItem = ({
                               instrument_name,
                               instrument_id,
                               work_name,
                               photo_url,
                               handleChange,
                               name,
                               instrumentIds = []
                             }) => {
  const setIds = () => {
    let newIds;
    if (instrumentIds.includes(instrument_id)) {
      newIds = instrumentIds.filter((el) => el !== instrument_id);
    } else {
      newIds = [...instrumentIds, instrument_id];
    }

    return newIds;
  };
  return (
    <div className={styles.checkboxes__item}>
      <span>{instrument_name}</span>
      <Checkbox
        checked={instrumentIds?.includes(instrument_id)}
        onChange={() =>
          handleChange({
            target: { name, value: setIds() }
          })
        }
        name={name}
        color="primary"
      />
      <p>{work_name}</p>
      <img src={"https://office.smartremont.kz" + photo_url} alt={instrument_name} />
    </div>
  );
};