import React, { useState } from "react";
import { Button, ClickAwayListener } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Participants from "./Participants";
import Movement from "./Movement";
import Archiving from "./Archiving";
import { ArrowRight, TwoUsers } from "react-iconly";
import Attachments from "./Attachments";
import ArchiveIcon from '@material-ui/icons/Archive';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const AsideBtn = ({ title, col_id, cardInfo, card_id }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickAway = () => {
    setOpen(false);
  };

  const getModal = title => {
    switch (title) {
      case "Участники":
        return <Participants setOpen={setOpen} />;
      case "Вложения":
        return <Attachments setOpen={setOpen} card_id={card_id} />;
      case "Перемещение":
        return <Movement col_id={col_id} setOpen={setOpen} />;
      case "Архивация":
        return <Archiving cardInfo={cardInfo} setOpen={setOpen} />;
      default:
        break;
    }
  };

  const getIcon = title => {
    switch (title) {
      case "Участники":
        return <TwoUsers set="curved" primaryColor="#0C4B86" />;
      case "Вложения":
        return <AttachFileIcon className={classes.attach} />;
      case "Перемещение":
        return <ArrowRight set="light" primaryColor="#0C4B86" />;
      case "Архивация":
        return (
          <ArchiveIcon
            aria-label="ArchiveIcon"
            style={{ color: "#0C4B86", width: 18, height: 18 }}
          />
        );
      default:
        break;
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={classes.aside_wrapper}>
        <Button
          className={classes.aside_btn}
          onClick={handleClick}
          classes={{
            label: classes.label
          }}
        >
          <div className={classes.icon_wrapper}>{getIcon(title)}</div>
          {title}
        </Button>
        {open ? getModal(title) : null}
      </Box>
    </ClickAwayListener>
  );
};
const useStyles = makeStyles({
  aside_btn: {
    fontSize: 12,
    fontWeight: 500,
    color: "#494C62",
    padding: "8px 12px",
    backgroundColor: "#EAECF0",
    borderRadius: 4,
    marginBottom: 10,
    maxWidth: 168,
    width: "100%",
    height: 30,
    textAlign: "left",
    textTransform: "none !important",
    "& i": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    "& svg": {
      width: 24,
      height: 24
    }
  },
  aside_wrapper: {},
  label: {
    justifyContent: "flex-start !important"
  },
  icon_wrapper: {
    width: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12
  },
  attach: {
    width: "18px !important",
    height: "18px !important",
    "& path": {
      stroke: "#0C4B86"
    }
  }
});

export default AsideBtn;
