import React, { useEffect, useState } from "react";
import { getDocs } from "../services/api";
import { MAIN_URL } from "../../../constants";
import { useParams } from "react-router-dom";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../components/common/CustomTable/CustomTable";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";

const headerTitles = [
  { label: "№", align: "left" },
  { label: "Наименование", align: "left" },
  { label: "Тип", align: "left" },
  { label: "Добавил", align: "left" },
  { label: "Дата добавления", align: "left" },
  { label: "", align: "left" }
];

const Documents = () => {
  const { addCloseSnack } = useCustomSnackbar();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    setLoading(true);
    getDocs(remont_id)
      .then(res => {
        setLoading(false);
        setDocs(res);
      })
      .catch(e => {
        addCloseSnack(e?.response?.data?.error);
        setLoading(false);
      });
  }, [remont_id, addCloseSnack]);

  return (
    <CustomTable headItems={headerTitles} isFetching={loading}>
      {docs?.map((el, i) => {
        return (
          <CustomTableRow key={el?.client_request_document_id}>
            <CustomTableCell>{i + 1}</CustomTableCell>
            <CustomTableCell>{el.document_name}</CustomTableCell>
            <CustomTableCell>
              {el.document_type_name}
              <br />
              {el.type_code === "DRAFT_ACCEPT_ACT" && el.draft_akt_date !== null
                ? `Дата подписания акта приема-передачи ЧО:${el.draft_akt_date}`
                : null}
            </CustomTableCell>
            <CustomTableCell>{el.fio}</CustomTableCell>
            <CustomTableCell>{el.rowversion}</CustomTableCell>
            <CustomTableCell>
              <a
                href={MAIN_URL + el.document_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Скачать
              </a>
            </CustomTableCell>
          </CustomTableRow>
        );
      })}
    </CustomTable>
  );
};

export default Documents;
