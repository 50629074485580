import instance from "../../../services/api";

export const fetchStatuses = params => {
  return instance(true)
    .post(`/partner/statuses/`, params)
    .then((res = {}) => res?.data);
};

export const changeStatus = params => {
  return instance(true).post(`/partner/statuses/done/`, params);
};
