import React, { useState } from "react";
import instance from "../../services/api";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { Button } from "@material-ui/core";
import LoopIcon from "@material-ui/icons/Loop";
import LocalPrintshopOutlinedIcon from "@material-ui/icons/LocalPrintshopOutlined";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const DownloadXlsx = ({
  url,
  params = {},
  title,
  reqType = "post",
  className,
  withoutMargin = false,
  small = false,
}) => {
  const [downloading, setDownloading] = useState(false);
  const classes = useStyles({withoutMargin});
  const downloadFile = () => {
    setDownloading(true);
    let args = [url, params, { responseType: "arraybuffer" }];
    if (reqType === "get") {
      args = [url, { params, responseType: "arraybuffer" }];
    }
    instance(true)
      [reqType || "post"](...args)
      .then((res = {}) => {
        const blob = new Blob([res.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, `${title}- ${format(new Date(), "dd.MM.YYY")}.xlsx`);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  return (
    <Button
      className={clsx([classes.button, {[className]: !!className, [classes.buttonSmall]: small}])}
      disabled={downloading}
      onClick={downloadFile}
    >
      {downloading ? (
        <LoopIcon className={clsx([classes.loop, {[classes.loopDark]: small}])} />
      ) : (
        <LocalPrintshopOutlinedIcon />
      )}
    </Button>
  );
};

export default DownloadXlsx;

const useStyles = makeStyles({
  button: {
    backgroundColor: "#21c001",
    height: 38,
    color: "#ffffff",
    marginLeft: props => props.withoutMargin ? 0 : 25,
    marginTop: props => props.withoutMargin ? 0 : 25,
    borderRadius: 4,

    "&:hover": {
      backgroundColor: "#1a9d00"
    },
  },
  buttonSmall: {
    backgroundColor: "unset",
    height: "auto",
    color: "#21c001",
    padding: 0,
    width: "auto",
    border: "none",
    borderRadius: '0',
    minWidth: "unset",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  loop: {
    animation: `$spin 2000ms infinite linear`,
    color: "#fff",
  },
  loopDark: {
    color: "#21c001",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0)"
    },
    "100%": {
      transform: "rotate(-360deg)"
    }
  }
});