import React from "react";
import { useStyles } from "./AddWorksTable";
import {
  CustomTableCell,
  CustomTableRow
} from "../../../components/common/CustomTable/CustomTable";

const RowItem = ({ work_cnt, work_name, work_price, work_sum, posCode }) => {
  const classes = useStyles();
  return (
    <CustomTableRow>
      <CustomTableCell className={classes.cell_sm} />
      <CustomTableCell className={classes.cell_name}>
        {work_name}
      </CustomTableCell>
      <CustomTableCell className={classes.cell} />
      <CustomTableCell className={classes.cell} align="right">
        {work_cnt}
      </CustomTableCell>
      {posCode === "CONTRACTOR" ? (
        <>
          <CustomTableCell className={classes.cell} align="right">
            {work_price} тг
          </CustomTableCell>
          <CustomTableCell className={classes.cell} align="right">
            {work_sum} тг
          </CustomTableCell>
        </>
      ) : null}
    </CustomTableRow>
  );
};

export default RowItem;
