import instance from "../../../services/api";

export const executiveReportAPI = {
  async getData(body = {}) {
    return await instance(true)
      .post("/partner/mng_report/read/", body)
      .then(res => res?.data);
  },
  async getProrabs() {
    return await instance(true)
      .get("/partner/foreman/")
      .then(res => res?.data);
  },
  async getSalePoints() {
    return await instance(true)
      .get("/partner/commons/sale_points/")
      .then(res => res?.data?.sale_points);
  },
  async getHeadDirections() {
    return await instance(true)
      .get("/partner/commons/head_directions/")
      .then(res => res?.data?.head_directions);
  }
};
