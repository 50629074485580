import React, { useEffect } from "react";
import styles from "./main.module.scss";
import { PlaceholderImg } from "../../components/common/blocks/PlaceholderImg";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenuState } from "../../services/redux/actions/app-actions";

export const MainPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { isMainMenuOpen: open } = useSelector(state => state.appState);
  useEffect(() => {
    if((window?.innerWidth <= 768 || window?.ReactNativeWebView) && !open) {
      dispatch(toggleMenuState());
    }
  }, [location, open, dispatch])
  return (
    <div className={styles.main__wrapper}>
      <PlaceholderImg text="Smart Partner" />
    </div>
  );
};
