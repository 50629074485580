import React, { useState } from "react";
import styles from "./styles.module.scss";
import CustomInput from "../../components/common/form/CustomInput";
import { PhoneInput } from "../../components/common/form/InputNumberMask";
import CommentsFile from "../Problems/CommentsFile";
import { UploadFile } from "../../components/common/form/UploadFile";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { CButton } from "../../components/common/buttons";
import { PAGES_ROUTE_KEY } from "../../constants";
import { useNavigate } from "react-router";
import { CustomDatePicker } from "../../components/common/form/CustomDatePicker";
import { Controller, useForm } from "react-hook-form";
import { InputWarning } from "../../components/common/form/warnings";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { registerNewContractor } from "./services";
import { CheckBoxItem } from "./form";
import { BackBtn } from "../../components/common/blocks";

export const MasterForm = ({ data }) => {
  const router = useNavigate();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const [step, setStep] = useState(1);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    work_name: "",
    master_fio: "",
    master_date_birth: "",
    phone: "",
    email: "",
    instrument_id_arr: [],
    arrays: {
      work_files: []
    }
  });

  const onChange = (key, value) => {
    setFormData(prev => ({ ...prev, [key]: value }));
  };
  const nextStep = () => setStep(prevState => prevState + 1);

  const onBackClick = () => {
    if (step === 1)
      return router(PAGES_ROUTE_KEY.forContractors);
    setStep(prevState => prevState - 1);
  };
  const addFile = (key, value) => {
    if (!value) return;
    const newValues = value?.filter(item => !formData.arrays[key].find(file => item.name === file.name && item.lastModified === file.lastModified));
    setFormData(prevState => ({
      ...prevState,
      arrays: { ...prevState.arrays, [key]: prevState.arrays[key] ? [...prevState.arrays[key], ...newValues] : value }
    }));
  };
  const deleteFile = (key, idx) => {
    setFormData(prevState => ({
      ...prevState,
      arrays: { ...prevState.arrays, [key]: prevState.arrays[key]?.filter((item, i) => i !== idx) }
    }));
  };

  const onSubmit = async (body = {}) => {
    if (step === 1) {
      setFormData(prevState => ({ ...prevState, ...body }));
      return nextStep();
    }
    if (step === 2) {
      if (!formData.instrument_id_arr?.length) return addCloseSnack("Выберите инструменты!");
      nextStep();
    }
    if (step === 3) {
      if (!formData.arrays?.work_files?.length) return addCloseSnack("Загрузите фотографии!");
      const params = new FormData();
      const MASTER_TYPE = "2";
      params.append("type", MASTER_TYPE);

      Object.keys(formData).forEach(key => {
        if (key !== "arrays") {
          if (key === "instrument_id_arr")
            params.append(key, `{${formData.instrument_id_arr}}`);
          else
            params.append(key, formData[key]);
        }
      });

      formData.arrays.work_files.forEach(item => {
        params.append("work_files[]", item);
      });
      setLoading(true);
      const res = await registerNewContractor(params);
      setLoading(false);
      if (!res) return;
      addDefaultSnack("Заявка успешно создана");
      router(PAGES_ROUTE_KEY.forContractors);
    }
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return <>
          <h1>Шаг 1. Заполните анкетные данные</h1>
          <div className={styles.row}>
            <div className={styles.row__item}>
              <Controller
                name="work_name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    fullWidth
                    variant={"outlined"}
                    label="Наименование вида услуг/работ"
                    placeholder="Наименование вида услуг/работ"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.work_name || ""}
                rules={{
                  required: true
                }}
              />
              {errors.work_name && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
            <div className={styles.row__item}>
              <Controller
                name="master_fio"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    fullWidth
                    variant={"outlined"}
                    label="ФИО"
                    placeholder="ФИО"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.master_fio || ""}
                rules={{
                  required: true
                }}
              />
              {errors.master_fio && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.row__item}>
              <Controller
                name="master_date_birth"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomDatePicker
                    fullWidth variant={"outlined"}
                    placeholder="Дата рождения"
                    label={"Дата рождения"}
                    name="master_date_birth"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.master_date_birth || ""}
                rules={{
                  required: true
                }}
              />
              {errors.master_date_birth && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
            <div className={styles.row__item}>
              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    fullWidth
                    variant={"outlined"} className={""}
                    label="Телефон"
                    placeholder="Телефон"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.phone || ""}
                rules={{
                  required: true
                }}
              />
              {errors.phone && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.row__item}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    fullWidth
                    variant={"outlined"}
                    label="Email"
                    placeholder="Email"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.email || ""}
                rules={{
                  required: true
                }}
              />
              {errors.email && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
          </div>
        </>;
      case 2:
        return <>
          <h3>Шаг 2. Укажите имеющиеся инструменты</h3>
          <div className={styles.checkboxes}>
            {data?.instrument_list?.map((i) => (
              <CheckBoxItem
                key={i.instrument_id}
                name={"instrument_id_arr"}
                {...i}
                instrumentIds={formData?.instrument_id_arr}
                handleChange={e =>
                  onChange("instrument_id_arr", e?.target?.value)
                }
              />
            ))}
          </div>
        </>;
      case 3:
        return <>
          <h3>Шаг 3. Прикрепите фотографии выполненных работ</h3>
          <div>
            <p>Загрузите фото</p>
            <div className="contractor__row align-items-end">
              {!!formData.arrays?.work_files?.length &&
                formData.arrays?.work_files?.map((item, idx) => (<CommentsFile key={item.lastModified} clickable={false}
                                                                               handleDelete={() => deleteFile("work_files", idx)}
                                                                               file_name={item?.name} />))}
              <UploadFile
                name="work_files"
                isAnketa
                onChange={(e) =>
                  addFile("work_files", Object.values(e.target.files))
                }
                required="required"
                files={formData.arrays.work_files}
                renderUpload={() => (<span className={styles.attach}><AttachFileIcon />Загрузить</span>)} />
            </div>
          </div>
        </>;
      default:
        return null;
    }
  };
  const closeDetail = () => {
    router(PAGES_ROUTE_KEY.forContractors);
  };
  return <>
    <BackBtn onClick={closeDetail} padding mobile={false} />
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      {renderContent()}
      <div className={styles.btns}>
        <CButton type={"button"} disabled={loading} onClick={onBackClick} variant={"secondary"}>Назад</CButton>
        <CButton type={"submit"} loading={loading}>Дальше</CButton>
      </div>
    </form>
  </>;
};