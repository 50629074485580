import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import styles from "./teams.module.scss";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../common/CustomTable/CustomTable";
import { TeamDetail } from "./detail/TeamDetail";
import { ActiveCheckbox } from "./index";
import { showPopUp } from "../../../services/redux/actions/app-actions";
import { useTableStyles } from "../accounting/styles";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { useHttp } from "../../../utils/hooks/http.hook";
import { useDispatch } from "react-redux";
import {
  DeleteIconWrapper,
  EditIconWrapper,
  UpdateTableHeader
} from "../../common/blocks";
import { CustomSearch } from "../../common/form/CustomSearch";
import { TeamFilters } from "./filters";
import { makeStyles } from "@material-ui/core/styles";
import { MODALS_NAMES } from "../../../constants";
import { getTeamsList } from "./services";
import { CollapseBlock } from "../../common/CollapseBlock/collapseBlock";

const useStylesTeams = makeStyles({
  cellNotExist: {
    borderLeft: "4px solid #ff9234"
  }
});

export const TeamsComponent = () => {
  const dispatch = useDispatch();
  const classes = useStylesTeams();
  const classesTable = useTableStyles();
  const { addDefaultSnack } = useCustomSnackbar();
  const { request } = useHttp();
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTeam, setActiveTeam] = useState(null);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [data, setData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const getTeamsData = useCallback(async (filters = {}) => {
    setIsFetching(true);
    const res = await getTeamsList(filters);
    setIsFetching(false);
    if (!res) return;
    setData(res);
  }, []);

  useEffect(() => {
    getTeamsData();
  }, [getTeamsData]);

  const onFiltersChange = async filters => {
    await getTeamsData(filters);
  };

  const onSubmit = async data => {
    const res = await request(
      "/partner/teams/",
      "post",
      data,
      {},
      { isModal: true }
    );
    if (res?.status) {
      addDefaultSnack("Бригада успешно создана");
      setData(res?.teams);
      dispatch(showPopUp(false));
    }
  };

  const onAddClick = () => {
    dispatch(showPopUp(true, MODALS_NAMES.addTeamMember, { onSubmit }));
  };

  const onSubmitUpdate = async data => {
    const res = await request(
      `/partner/teams/${data?.team_id}/update_team/`,
      "put",
      data,
      {},
      { isModal: true }
    );
    if (res?.status) {
      addDefaultSnack("Бригада успешно изменена");
      setData(res?.teams);
      dispatch(showPopUp(false));
    }
  };

  const onEditClick = body => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addTeamMember, {
        onSubmit: onSubmitUpdate,
        body
      })
    );
  };

  const handleDelete = async team_id => {
    const res = await request(
      `/partner/teams/${team_id}/delete_team/`,
      "delete",
      {},
      {},
      { isModal: true }
    );
    if (res?.status) {
      addDefaultSnack("Бригада успешно удалена");
      setData(prev => prev?.filter(item => item?.team_id !== team_id));
      dispatch(showPopUp(false));
    }
  };

  const onDeleteClick = id => {
    dispatch(
      showPopUp(true, MODALS_NAMES.confirmModal, { onSubmit: handleDelete, id })
    );
  };

  const closeDetail = async () => {
    setActiveTeam(null);
    setIsOpenDetail(false);
  };

  const openDetail = team => {
    setIsOpenDetail(true);
    setActiveTeam(team);
  };

  const onTeamChange = team => {
    setData(prev =>
      prev?.map(item => {
        if (item?.team_id === team?.team_id) return { ...item, ...team };
        return item;
      })
    );
    setActiveTeam(prev => ({ ...prev, ...team }));
  };

  const computedData = useMemo(() => {
    const search = searchTerm.trim().toLowerCase();
    if (!search || !data?.length) return data;

    return data?.filter(
      el =>
        el?.team_id
          ?.toString()
          .toLowerCase()
          .includes(search) ||
        el?.team_name?.toLowerCase().includes(search) ||
        el?.team_main_master?.toLowerCase().includes(search) ||
        el?.head_team_employee_fio?.toLowerCase().includes(search)
    );
  }, [data, searchTerm]);

  const renderTableHeader = () => {
    return <UpdateTableHeader onClick={getTeamsData} isFetching={isFetching} />;
  };
  return (
    <div className={styles.teams__component}>
      <CollapseBlock>
        <Button
          className={clsx(classesTable.btn)}
          variant="outlined"
          onClick={onAddClick}
        >
          Добавить
        </Button>
        <div className={classesTable.searchWrapper}>
          <CustomSearch
            value={searchTerm}
            handleChange={e => setSearchTerm(e?.target?.value || "")}
          />
          <TeamFilters onSubmit={onFiltersChange} />
        </div>
      </CollapseBlock>

      <div className={styles.teams__content}>
        <CustomTable
          stickyHeader
          headItems={headItems}
          isEmpty={!computedData?.length}
          containerClassName={classes.root}
          isFetching={isFetching}
          headerRender={renderTableHeader}
        >
          {computedData?.map(item => {
            return (
              <CustomTableRow key={item?.team_id}>
                <CustomTableCell
                  align="center"
                  className={clsx({
                    [classes.cellNotExist]: !item?.is_main_master_exist
                  })}
                  title={
                    !item?.is_main_master_exist ? "Не назначен бригадир" : ""
                  }
                >
                  {item?.team_id}
                </CustomTableCell>
                <CustomTableCell align="left">
                  <span
                    className={styles.teams__team}
                    onClick={() => openDetail(item)}
                  >
                    {item?.team_name}
                  </span>
                </CustomTableCell>
                <CustomTableCell align="right">
                  {item?.count || 0}
                </CustomTableCell>
                <CustomTableCell align="left">
                  {item.head_team_employee_fio || ""}
                </CustomTableCell>
                <CustomTableCell align="left">
                  {item.team_main_master || ""}
                </CustomTableCell>
                <CustomTableCell
                  align="center"
                  className={clsx(
                    classes.cell,
                    item.is_active ? classesTable.active : classesTable.inActive
                  )}
                >
                  {!!item.is_active && <ActiveCheckbox />}
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classesTable.actions}>
                    <EditIconWrapper onClick={() => onEditClick(item)} />
                    <DeleteIconWrapper
                      onClick={() => onDeleteClick(item?.team_id)}
                    />
                  </div>
                </CustomTableCell>
              </CustomTableRow>
            );
          })}
        </CustomTable>
        <TeamDetail
          closeDetail={closeDetail}
          teamData={activeTeam}
          team_id={activeTeam?.team_id}
          onTeamChange={onTeamChange}
          open={isOpenDetail}
        />
      </div>
    </div>
  );
};

const headItems = [
  { label: "ID", align: "center" },
  { label: "Бригада", align: "left" },
  { label: "Кол-во мастеров", align: "left" },
  { label: "Старший бригадир", align: "left" },
  { label: "Бригадир", align: "left" },
  { label: "Активный", align: "left" },
  { label: "", align: "left" }
];
