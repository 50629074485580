import styles from "./styles.module.scss";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import React, { useEffect, useRef, useState } from "react";
import {
  getContractorsData,
  getCostStatuses,
  getMastersData,
  getRemontsData
} from "../../services/redux/actions/app-actions";
import { useDispatch, useSelector } from "react-redux";
import { CustomDatePicker } from "../../components/common/form/CustomDatePicker";

export const MasterPaymentsFilters = ({
  getData,
                                        setFiltersHeight,
  statusFilters = false,
  disabled = false
}) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [filters, setFilters] = useState({ contractor_cost_status_code: "" });
  const { contractors, masters, remonts, costStatuses } = useSelector(state => state.appState);

  useEffect(() => {
    if(setFiltersHeight)
      ref.current?.clientHeight && setFiltersHeight(ref.current?.clientHeight)
  }, [setFiltersHeight]);

  useEffect(() => {
    dispatch(getMastersData());
    dispatch(getRemontsData());
    dispatch(getContractorsData());
  }, [dispatch]);

  useEffect(() => {
    const getData = async () => {
      if (!statusFilters) return;
      dispatch(getCostStatuses())
    };
    getData();
  }, [statusFilters, dispatch]);

  const onChange = (key, value) => {
    const newValue = { ...filters, [key]: value || "" };
    setFilters(newValue);
    getData && getData(newValue);
  };

  return (
    <div ref={ref} className={styles.filters}>
      <CustomDatePicker
        disabled={disabled}
        fullWidth
        label={"Дата начала"}
        placeholder={"Выберите дату"}
        name={"date_begin"}
        value={filters?.date_begin}
        onChange={e => onChange("date_begin", e.target.value)}
      />
      <CustomDatePicker
        disabled={disabled}
        fullWidth
        label={"Дата конца"}
        placeholder={"Выберите дату"}
        name={"date_end"}
        value={filters?.date_end}
        onChange={e => onChange("date_end", e.target.value)}
      />
      {statusFilters && (
        <AutoSuggest
          disabled={disabled}
          list={costStatuses}
          showKey={"contractor_cost_status_name"}
          valueKey={"contractor_cost_status_code"}
          value={filters?.contractor_cost_status_code}
          onChange={e =>
            onChange("contractor_cost_status_code", e.target.value)
          }
          placeholder="Выберите статус"
          label="Статус"
          defaultValue={""}
        />
      )}
      <AutoSuggest
        disabled={disabled}
        list={remonts}
        showKey={"remont_information"}
        valueKey={"remont_id"}
        value={filters?.remont_id}
        onChange={e => onChange("remont_id", e.target.value)}
        placeholder="Выберите ремонт"
        label="Ремонт"
        defaultValue={""}
      />
      <AutoSuggest
        disabled={disabled}
        list={contractors}
        showKey={"contractor_name"}
        valueKey={"contractor_id"}
        value={filters?.contractor_id}
        onChange={e => onChange("contractor_id", e.target.value)}
        placeholder="Выберите подрядчика"
        label="Подрядчик"
        defaultValue={""}
      />
      <AutoSuggest
        disabled={disabled}
        list={masters}
        showKey={"team_master_fio"}
        valueKey={"team_master_id"}
        value={filters?.team_master_id}
        onChange={e => onChange("team_master_id", e.target.value)}
        placeholder="Выберите мастера"
        label="Мастера"
        defaultValue={""}
      />
    </div>
  );
};
