import React, { useMemo, useState } from "react";
import { Box, Collapse } from "@material-ui/core";
import TableListItem from "./TableListItem";
import { CustomTable } from "../../../components/common/CustomTable/CustomTable";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles({
  root_cell: {
    padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: "#000",
    lineHeight: "20px",
    cursor: "pointer"
  },
  table: {
    borderRadius: 0
  }
});
const iconStyles = {
  color: "#000",
  display: "inline-block",
  marginLeft: 5,
  transform: "translateY(3px)",
  marginTop: "-2px"
};
const ProjectsRow = ({ data, title, isHistory = false }) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const headerTitles = useMemo(() => {
    if (isHistory)
      return projectRowHeaderTitles;
    return [...projectRowHeaderTitles, { label: "Действия", align: "left" }];
  }, [isHistory]);

  return (
    <>
      <Box
        onClick={() => setOpen(!open)}
        classes={{ root: classes.root_cell }}
        className={classes.title}
      >
        {title}
        {open ? (
          <KeyboardArrowUpIcon style={iconStyles} />
        ) : (
          <KeyboardArrowDownIcon style={iconStyles} />
        )}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CustomTable
          withMaxHeight={false}
          headItems={headerTitles}
          containerClassName={classes.table}
        >
          {data?.map(el => (
            <TableListItem
              key={el.project_remont_id}
              {...el}
              isHistory={isHistory}
            />
          ))}
        </CustomTable>
      </Collapse>
    </>
  );
};

export default ProjectsRow;

const projectRowHeaderTitles = [
  { label: "ID проекта", align: "left" },
  { label: "Наименование", align: "left" },
  { label: "Файл", align: "left" },
  { label: "Комментарий", align: "left" },
  { label: "Финальный", align: "left" },
  { label: "Утвержден", align: "left" },
  { label: "Отправка в чат", align: "left" },
  { label: "Пользователь", align: "left" },
  { label: "Время записи", align: "left" }
];
