import instance from "../../../services/api";

export const worksetsAPI = {
  getRemontWorksets(remont_id) {
    return instance()
      .get(`/partner/remont_work_set/${remont_id}/`)
      .then(res => res?.data);
  },
  getRemontWorksetsActions() {
    return instance()
      .get(`/partner/commons/call_types/`)
      .then(res => res?.data);
  },
  callAction(remont_id, body) {
    return instance()
      .post(`/partner/remont_work_set/${remont_id}/call/`, body)
      .then(res => res?.data);
  },
  updateList(remont_id) {
    return instance()
      .put(`/partner/remont_work_set/${remont_id}/wcheck/update/`)
      .then(res => res?.data);
  },
  completeWorkset(remont_id, body) {
    return instance()
      .post(`/partner/remont_work_set/${remont_id}/work_sets/complete/`, body)
      .then(res => res?.data);
  },
  masterCompleteWorkset(remont_id, body) {
    return instance()
      .post(`/partner/remont_work_set/${remont_id}/master_complete/`, body)
      .then(res => res?.data);
  },
  callMaster(remont_id, body) {
    return instance()
      .post(`/partner/remont_work_set/${remont_id}/call_master/`, body)
      .then(res => res?.data);
  }
};
