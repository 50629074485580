import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { acceptance } from "../api";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& svg": {
      color: "#0C4B86"
    }
  },
  head_title: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "22px",
    color: "#0C4B86"
  },
  comment: {
    fontSize: 16,
    outline: "none",
    border: "1px solid rgba(0,0,0,0.5)",
    borderRadius: 4,
    width: "100%",
    padding: 5,
    marginBottom: 10,
    resize: "none"
  },
  button_wrapper: {
    textAlign: "right"
  },
  accept: {
    color: "#fff",
    backgroundColor: "#81CD67FF",

    "&:hover": {
      backgroundColor: "#81CD67FF"
    }
  },
  reject: {
    color: "#fff",
    backgroundColor: "#FF6262",

    "&:hover": {
      backgroundColor: "#FF6262"
    }
  }
});

const Comment = ({ handleClose, data, setPenalty }) => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [comment, setComment] = useState("");

  const handleChange = e => {
    setComment(e.target.value);
  };

  const handleClick = () => {
    acceptance(data?.id, {
      is_accept: data?.isAccept,
      acceptance_comment: comment
    })
      .then(res => {
        setPenalty(res);
      })
      .catch(e => {
        addCloseSnack(e?.response?.data?.error);
      });
  };

  return (
    <Box sx={{ width: "700px !important", display: "grid", gap: "24px" }}>
      <Box className={classes.head}>
        <Typography component="h2" className={classes.head_title}>
          Комментарий
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <textarea
          name="acceptance_comment"
          placeholder="Введите комментарий"
          rows="5"
          onChange={handleChange}
          className={classes.comment}
        />
        <Box className={classes.button_wrapper}>
          {data?.isAccept ? (
            <Button
              className={classes.accept}
              variant="contained"
              disableElevation={true}
              size="large"
              onClick={handleClick}
            >
              Принять
            </Button>
          ) : (
            <Button
              className={classes.reject}
              variant="contained"
              disableElevation={true}
              size="large"
              onClick={handleClick}
            >
              Не принимать
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Comment;
