import React from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  TextareaAutosize
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CommentUploadFile from "./CommentUploadFile";

import { useStyles } from "./CommentsBox";

const ProblemCommentBox = ({
  handleChange,
  value,
  disabled,
  handleClick,
  handleClose,
  handleUpload
}) => {
  const classes = useStyles();
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box className={classes.text_area_wrapper}>
        <TextareaAutosize
          className={classes.text_area}
          style={{ border: "none" }}
          onChange={handleChange}
          value={value}
          autoFocus
        />
        <Button
          variant="contained"
          className={classes.text_area_btn}
          disabled={disabled}
          onClick={handleClick}
        >
          Сохранить
        </Button>
        <CloseIcon className={classes.close_icon} onClick={handleClose} />
        <CommentUploadFile handleChange={handleUpload} />
      </Box>
    </ClickAwayListener>
  );
};

export default ProblemCommentBox;
