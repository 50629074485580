
export const getProblemColor = id => {
  switch (id) {
    case 4:
      return "#F16587";
    case 2:
      return "#81CD67";
    case 3:
      return "#F8D270";
    case 5:
      return "#F16587";
    case 1:
      return "#81CD67";
    default:
      break;
  }
};

export const DEFAULT_WALLET = "INTERPAY"