import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { gridTemplateColumns } from "./ContractJournalRow";
import clsx from "clsx";

const useStyles = makeStyles({
  head: {
    backgroundColor: "#ECEEFD",
    borderRadius: "4px 4px 0px 0px",
    display: "grid",
    gridTemplateColumns,
    justifyContent: "center",
    alignItems: "center",
    height: 53,
    minWidth: 800
  },
  cell: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    color: "#696C7C",
    backgroundColor: "#ECEEFD",
    padding: 5
  },
  alignRight: {
    display: "flex",
    justifyContent: "flex-end"
  }
});

const ContactJournalHead = () => {
  const classes = useStyles();
  return (
    <Box className={classes.head}>
      <Box className={classes.cell}>ID документа</Box>
      <Box className={classes.cell}>ID ремонта</Box>
      <Box className={classes.cell}>Адрес</Box>
      <Box className={classes.cell}>Наименование документа</Box>
      <Box className={clsx(classes.cell, classes.alignRight)}>Сумма</Box>
      <Box className={classes.cell}>Контрагент/Инициатор</Box>
      <Box className={classes.cell}>Статус</Box>
      <Box className={classes.cell}>Документы</Box>
    </Box>
  );
};

export default ContactJournalHead;
