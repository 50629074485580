import React, { useEffect, useState } from "react";
import { AutoSuggest } from "../../../common/form/AutoSuggest";
import { CButton } from "../../../common/buttons";
import { makeStyles } from "@material-ui/core/styles";
import { getMastersBySpecialization } from "../../../features/teams/services";

export const AddMasterToWorkset = ({ data }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [masters, setMasters] = useState([]);
  const {
    work_set_name,
    selected_master_id,
    work_set_id,
    speciality_id,
    remont_id,
    onSubmit
  } = data;
  const [selectedMasterId, setSelectedMasterId] = useState(
    selected_master_id || null
  );

  useEffect(() => {
    const getMasters = async () => {
      const res = await getMastersBySpecialization({ speciality_id: speciality_id || null, remont_id });
      if (!res) return;
      setMasters(res);
    };
    getMasters();
  }, [speciality_id, remont_id]);

  const handleSubmit = async () => {
    if (!onSubmit) return;
    const body = {
      team_master_id: selectedMasterId
    };
    if (work_set_id) body.work_set_id = work_set_id;
    else body.speciality_id = speciality_id;
    setIsLoading(true);
    await onSubmit(body);
    setIsLoading(false);
  };
  return (
    <div className={classes.wrapper}>
      <h2>Назначение мастера</h2>
      <h3 className={classes.workset}>{work_set_name}</h3>
      <AutoSuggest
        value={selectedMasterId}
        onChange={e => setSelectedMasterId(e.target?.value)}
        list={masters}
        specialOptionTitle={"Бригадир"}
        getSpecialOption={option => option.is_main_master}
        label={"Мастер"}
        showKey={"team_master_fio"}
        valueKey={"team_master_id"}
      />
      <div className={classes.flex}>
        <CButton
          className={classes.submitBtn}
          disabled={isLoading}
          loading={isLoading}
          onClick={handleSubmit}
        >
          Сохранить
        </CButton>
      </div>
    </div>
  );
};
const useStyles = makeStyles(() => ({
  wrapper: {
    minWidth: 400
  },
  workset: {
    margin: 0
  },
  submitBtn: {
    marginTop: 20
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));
