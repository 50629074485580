export const SET_COLUMNS = "PROBLEMS/SET_COLUMNS";
export const COPY_COLUMNS = "PROBLEMS/COPY_COLUMNS";
export const SET_CARD_ID = "PROBLEMS/SET_CARD_ID";
export const SET_SEARCH_TERM = "PROBLEMS/SET_SEARCH_TERM";
export const SET_SIDEBAR_CONTENT = "PROBLEMS/SET_SIDEBAR_CONTENT";
export const TOGGLE_DRAWER = "PROBLEMS/TOGGLE_DRAWER";
export const SET_CARD_TYPES = "PROBLEMS/SET_CARD_TYPES";
export const SET_PRIORITIES = "PROBLEMS/SET_PRIORITIES";
export const SET_RESIDENTS_FOR_FILTER = "PROBLEMS/SET_RESIDENTS_FOR_FILTER";
export const RESET_PROBLEMS_STATE = "PROBLEMS/RESET_PROBLEMS_STATE";

export const resetProblemsState = () => {
  return {
    type: RESET_PROBLEMS_STATE,
  };
};
export const setColumns = payload => {
  return {
    type: SET_COLUMNS,
    payload
  };
};

export const copyColumns = payload => {
  return {
    type: COPY_COLUMNS,
    payload
  };
};

export const setCardId = payload => {
  return {
    type: SET_CARD_ID,
    payload
  };
};
export const setSearchTerm = payload => {
  return {
    type: SET_SEARCH_TERM,
    payload
  };
};
export const setSideBarContent = (type = "", card_id = null) => {
  return {
    type: SET_SIDEBAR_CONTENT,
    payload: {
      type,
      card_id
    }
  };
};

export const toggleDrawer = payload => {
  return {
    type: TOGGLE_DRAWER,
    payload
  };
};

export const setCardTypes = payload => {
  return {
    type: SET_CARD_TYPES,
    payload
  };
};
export const setPriorities = payload => {
  return {
    type: SET_PRIORITIES,
    payload
  };
};
export const setResidentsForFilters = payload => {
  return {
    type: SET_RESIDENTS_FOR_FILTER,
    payload
  };
};
