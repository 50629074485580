import React from "react";
import { PageTitle } from "../../components/common/pageTitle";
import Measure from "../remonts/measure/Measure";
import { CheckRemontPermission } from "../remonts/CheckRemontPermission";

const RemontMeasure = () => {
  return (
    <CheckRemontPermission>
      <PageTitle title="Замеры" />
      <Measure singleRemont />
    </CheckRemontPermission>
  );
};

export default RemontMeasure;
