import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CustomTabPanel,
  CustomTabs
} from "../../../components/common/CustomTabs";
import { CheckListByStages } from "./CheckListByStages";
import { CheckListByWorksets } from "./CheckListByWorksets";

export const CheckList = () => {
  const styles = useStyles();
  const [activeTabId, setActiveTabId] = useState(0);

  const handleTabChange = (e, id) => {
    setActiveTabId(id);
  };

  return (
    <div>
      <CustomTabs tabs={tabs} value={activeTabId} onChange={handleTabChange} />
      <div className={styles.content}>
        <CustomTabPanel value={activeTabId} index={0}>
          <CheckListByStages />
        </CustomTabPanel>
        <CustomTabPanel value={activeTabId} index={1}>
          <CheckListByWorksets />
        </CustomTabPanel>
      </div>
    </div>
  );
};
const tabs = [{ label: "По этапам" }, { label: "По группам работ" }];

const useStyles = makeStyles(() => ({
  content: {
    marginTop: 10
  }
}));
