import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "./Card";
import { Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { WrapperScrollbar } from "../../components/common/WrapperScrollbar";

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: "#F4F5F7",
    minWidth: 415,
    maxWidth: 415,
    minHeight: 250,
    display: "flex",
    flexDirection: "column",
    marginRight: 25
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10
  },
  header_txt: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: "1px",
    color: "#72768F"
  },
  wrapper_body: {
    padding: 10,
    height: "100%",
    overflow: "auto"
  }
});

const CardList = ({ column }) => {
  const classes = useStyles();
  const { col_name, cards, col_id, card_cnt } = column;
  const wrapperHeight = window.innerHeight - 93;
  const { searchTerm } = useSelector(state => state.problemsState);

  const getBorderColor = id => {
    switch (id) {
      case 35:
        return "#F0AB8E";
      case 36:
        return "#0C4B86";
      case 37:
        return "#970DD8";
      case 38:
        return "#64D17B";
      default:
        return "#F0AB8E";
    }
  };

  const computedCards = useMemo(() => {
    const search = searchTerm?.toLowerCase();
    if (!cards?.length || !search) return cards;

    try {
      return cards?.filter(
        el =>
          el.card_id
            ?.toString()
            ?.toLowerCase()
            .includes(search) ||
          el.resident_name?.toLowerCase().includes(search) ||
          el.flat_num?.includes(search) ||
          el.card_title?.toLowerCase().includes(search) ||
          el.date_create?.toLowerCase().includes(search) ||
          el.priority_name?.toLowerCase().includes(search) ||
          el.executors?.toLowerCase().includes(search) ||
          el.card_type_name?.toLowerCase().includes(search)
      );
    } catch (e) {
      return cards;
    }
  }, [cards, searchTerm]);

  return (
    <Droppable droppableId={col_id.toString()}>
      {provided => (
        <Box
          className={classes.wrapper}
          style={{ maxHeight: wrapperHeight, height: "100%" }}
        >
          <Box
            className={classes.header}
            style={{ borderBottom: `5px solid ${getBorderColor(col_id)}` }}
          >
            <Typography className={classes.header_txt}>{col_name}</Typography>
            <Typography className={classes.header_txt}>{card_cnt}</Typography>
          </Box>
          <WrapperScrollbar
            className={classes.wrapper_body}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {computedCards?.slice(0, 10).map((el, index) => {
              return (
                <Card key={el.card_id} {...el} index={index} col_id={col_id} />
              );
            })}
            {provided.placeholder}
          </WrapperScrollbar>
        </Box>
      )}
    </Droppable>
  );
};

export default CardList;
