import React, { useState } from "react";
import { Box, Collapse, Typography } from "@material-ui/core";
import AddWorksRow from "../remonts/AdditionalWorks/AddWorksRow";
import { useStyles } from "../remonts/AdditionalWorks/AddWorksTable";
import { CustomTable } from "../../components/common/CustomTable/CustomTable";

const AwReportTable = ({
  ds_date,
  ds_sum,
  ds_type_name,
  ds_wset,
  posCode,
  ds_type,
  resident_name,
  flat_num
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  let headItems = [
    { label: "ID" },
    { label: "Название" },
    { label: "Комната", align: "center" },
    { label: "Количество", align: "center" }
  ];

  if (posCode === "CONTRACTOR") {
    headItems = [
      ...headItems,
      { label: "Цена", align: "center" },
      { label: "Сумма", align: "center" }
    ];
  }
  return (
    <>
      <Box
        display="flex"
        className={classes.banner}
        onClick={() => setOpen(!open)}
      >
        <Box className={classes.banner_item}>
          <Typography className={classes.header_type}>Тип ДС</Typography>
          <Box component="span" className={classes.type_item}>
            {ds_type_name}
          </Box>
        </Box>
        <Box className={classes.banner_item}>
          <Typography className={classes.header_type}>Дата</Typography>
          <Box component="span" className={classes.type_item}>
            {ds_date}
          </Box>
        </Box>
        <Box className={classes.banner_item}>
          <Typography className={classes.header_type}>Статус ДС</Typography>
          <Box component="span" className={classes.type_item}>
            {ds_type}
          </Box>
        </Box>
        <Box className={classes.banner_item}>
          <Typography className={classes.header_type}>ЖК</Typography>
          <Box component="span" className={classes.type_item}>
            {resident_name}
          </Box>
        </Box>
        <Box className={classes.banner_item}>
          <Typography className={classes.header_type}>Квартира</Typography>
          <Box component="span" className={classes.type_item}>
            {flat_num}
          </Box>
        </Box>
        {posCode === "CONTRACTOR" ? (
          <Box className={classes.banner_item}>
            <Typography className={classes.header_type}>Сумма ДС</Typography>
            <Box component="span" className={classes.type_item}>
              {ds_sum}
            </Box>
          </Box>
        ) : null}
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CustomTable
          containerClassName={classes.table_cont}
          headItems={headItems}
        >
          {ds_wset.map((el, i) => {
            return (
              <AddWorksRow
                key={el.ds_wset_id}
                {...el}
                index={i}
                posCode={posCode}
              />
            );
          })}
        </CustomTable>
      </Collapse>
    </>
  );
};

export default React.memo(AwReportTable);
