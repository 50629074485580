import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchTextConstructor } from "../services/api";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../../components/common/CustomTable/CustomTable";
import { useCustomSnackbar } from "../../../../utils/hooks/useCustomSnackbar";
import { CustomImage } from "../../../../components/common/blocks/ImgWrapper";

const TextConstructor = () => {
  const { addCloseSnack } = useCustomSnackbar();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  useEffect(() => {
    setLoading(true);
    fetchTextConstructor(remont_id)
      .then(res => {
        setLoading(false);
        setData(res);
      })
      .catch(e => {
        addCloseSnack(e?.response?.data?.error);
        setLoading(false);
      });
  }, [remont_id, addCloseSnack]);

  return (
    <CustomTable
      isFetching={loading}
      isEmpty={!data?.length}
      headItems={headerTitles}
    >
      {data?.map(el => (
        <CustomTableRow key={el.client_material_id}>
          <CustomTableCell>{el?.room_name}</CustomTableCell>
          <CustomTableCell>{el?.work_set_name}</CustomTableCell>
          <CustomTableCell>{el?.material_name}</CustomTableCell>
          <CustomTableCell>{el?.set_name}</CustomTableCell>
          <CustomTableCell align={"center"} width={90}>
            <CustomImage
              src={el?.photo_url} widthOnError={40}
            />
          </CustomTableCell>
        </CustomTableRow>
      ))}
    </CustomTable>
  );
};

export default TextConstructor;

const headerTitles = [
  { label: "Комната", align: "left" },
  { label: "Конструктив", align: "left" },
  { label: "Материал", align: "left" },
  { label: "Набор", align: "left" },
  { label: "Фото", align: "center" }
];