import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { CButton } from "../../../../common/buttons";
import CustomInput from "../../../../common/form/CustomInput";
import { InputWarning } from "../../../../common/form/warnings";
import { Checkbox, IconButton } from "@material-ui/core";
import styles from "./add_team.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import { getHeadTeamEmployeesData } from "../../../../../services/redux/actions/app-actions";
import { AutoSuggest } from "../../../../common/form/AutoSuggest";

export const AddTeamMember = ({ data, handleClose }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const { modalLoading, headTeamEmployees } = useSelector(
    state => state.appState
  );

  useEffect(() => {
    dispatch(getHeadTeamEmployeesData());
  }, [dispatch]);

  const onSubmit = async body => {
    const { onSubmit: submit } = data;
    return submit && (await submit({ ...body, team_id: data?.body?.team_id }));
  };
  return (
    <div>
      <div className={styles.add_team__header}>
        <h2 className={styles.add_team__title}>{` ${
          data?.body ? "Редактирование" : "Добавление"
        } бригады`}</h2>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form className={styles.add_team__form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.add_team__form_item}>
          <Controller
            name="team_name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                type="text"
                fullWidth
                label="Название бригады"
                placeholder="Введите название"
                wrapperClassname={styles.add_team__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.team_name || ""}
            rules={{
              required: true
            }}
          />
          {errors.team_name && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        {headTeamEmployees && (
          <div className={`${styles.add_team__form_item}`}>
            <Controller
              name="head_team_employee_id"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <AutoSuggest
                    withMargin
                    list={headTeamEmployees}
                    showKey={"fio"}
                    valueKey={"employee_id"}
                    placeholder="Выберите старшего бригадира"
                    label="Выберите старшего бригадира"
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
              defaultValue={data?.body?.head_team_employee_id || ""}
            />
          </div>
        )}
        <div className={styles.add_team__form_item}>
          <span>Активный</span>
          <Controller
            name="is_active"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox checked={!!value} onChange={onChange} />
            )}
            defaultValue={
              data?.body?.is_active || data?.body?.is_active !== false
            }
          />
        </div>
        <CButton
          loading={modalLoading}
          disabled={modalLoading}
          type="submit"
          className={styles.add_team__btn}
        >
          Сохранить
        </CButton>
      </form>
    </div>
  );
};
