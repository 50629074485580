import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { AutoSuggest } from "../../../../components/common/form/AutoSuggest";
import { useCustomSnackbar } from "../../../../utils/hooks/useCustomSnackbar";
import { CButton } from "../../../../components/common/buttons";
import { changeRemontContractorData } from "../services";
import { getContractors } from "../../services/api";

export const ChangeContractorModal = ({ data, handleClose }) => {
  const classes = useStyles();
  const { remont_id, setData } = data;
  const [contractor, setContractor] = useState(data?.contractor_id || "");
  const [loading, setLoading] = useState(false);
  const [contractors, setContractors] = useState([]);
  const { addDefaultSnack } = useCustomSnackbar();

  useEffect(() => {
    const getData = async () => {
      const res = await getContractors();
      setContractors(res?.data || []);
    };
    getData();
  }, []);

  const handleChange = e => {
    setContractor(e.target.value);
  };

  const handleClick = async () => {
    setLoading(true);
    const res = await changeRemontContractorData(remont_id, { contractor_id: contractor });
    setLoading(false);
    if (res) {
      setData(res);
      handleClose();
      addDefaultSnack("Успешно");
    }
  };

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.title}>Изменить подрядчика</Typography>
      <Box>
        <AutoSuggest
          withMargin
          list={contractors}
          showKey={"contractor_name"}
          valueKey={"contractor_id"}
          label="Выберите подрядчика"
          value={contractor}
          onChange={handleChange}
        />
        <Box display="flex" mt={2}>
          <Button
            className={classes.btn}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Отменить
          </Button>
          <CButton
            className={classes.btn}
            onClick={handleClick}
            loading={loading}
            disabled={!Boolean(contractor) || loading}
          >
            Сохранить
          </CButton>
        </Box>
      </Box>
    </Box>
  );
};

export const useStyles = makeStyles({
  wrapper: {
    width: "300px !important",
    paddingBottom: 10
  },
  select: {
    width: 200
  },
  title: {
    fontSize: 17,
    fontWeight: 500,
    color: "#0C4B86"
  },
  btn: {
    height: "44px !important",
    width: "150px !important",
    padding: 10,
    borderRadius: 4,
    "&:first-child": {
      marginRight: 15
    }
  }
});
