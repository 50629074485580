import React from "react";
import SearchBar from "material-ui-search-bar";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

export const useStyles = makeStyles(() => ({
  root: {
    margin: "0",
    background: "#FFFFFF",
    boxShadow: "0px 5px 10px rgba(108, 108, 108, 0.1)",
    borderRadius: "4px",
    height: "40px"
  }
}));
export const CustomSearchBar = ({ value, className, onChange }) => {
  const classes = useStyles();
  return (
    <SearchBar
      placeholder="Поиск"
      value={value}
      className={clsx(classes.root, className)}
      onChange={onChange}
      onCancelSearch={() => onChange && onChange("")}
    />
  );
};
