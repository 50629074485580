import React, { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Box, FormControl, Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import EmptyOrShow from "components/common/EmptyOrShow";
import { LOCALSTORAGEFIELDS, PAGES } from "../../../constants";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {
  getCitiesData,
  getCompaniesData,
  getUserData,
  resetReduxState
} from "../../../services/redux/actions/app-actions";
import { changeCities, changeCompany } from "../api";
import cookiesService from "../../../services/cookiesService";
import { useNavigate } from "react-router";

const cookiesServiceInstance = cookiesService.getService();
const SidebarUserbox = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const defaultCity = localStorage.getItem(LOCALSTORAGEFIELDS.city);
  const { sidebarToggle, sidebarHover } = props;
  const { dataUser, cities, companies } = useSelector(state => state.appState);
  const [loading, setLoading] = useState(false);
  const [selectedCity, setSelectedCity] = useState(defaultCity);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");

  useEffect(() => {
    if (!!dataUser?.company_id)
      setSelectedCompanyId(dataUser?.company_id);
  }, [dataUser]);

  useEffect(() => {
    dispatch(getUserData(setLoading));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCitiesData());
    dispatch(getCompaniesData());
  }, [dispatch]);

  const defaultSelected = cities.find(el => {
    return el.is_filter === true;
  });

  const handleChange = async e => {
    const selectedCityId = e.target.value;
    setSelectedCity(selectedCityId);
    localStorage.setItem(LOCALSTORAGEFIELDS.city, selectedCityId);

    try {
      await changeCities({ city_id: parseInt(selectedCityId) });
      window.location.reload();
    } catch (error) {
    }
  };

  const handleChangeCompany = async e => {
    const selectedCompanyId = parseInt(e.target.value);
    setSelectedCompanyId(selectedCompanyId);
    try {
      await changeCompany({ company_id: selectedCompanyId});
      window.location.reload();
    } catch (error) {
    }
  };

  const logout = () => {
    navigate(PAGES.login);
    dispatch(resetReduxState());
    cookiesServiceInstance.clearToken();
  };

  return (
    <Fragment>
      <Box
        className={clsx("app-sidebar-userbox", {
          "app-sidebar-userbox--collapsed": sidebarToggle && !sidebarHover
        })}
      >
        <EmptyOrShow loading={loading}>
          <ExitToAppIcon
            onClick={logout}
            style={{
              transform: "rotate(180deg)",
              color: "black",
              cursor: "pointer"
            }}
            className="profile__icons"
          />
          <Box className="app-sidebar-userbox-name">
            <Box style={{ lineHeight: "1.2" }}>
              <b>{dataUser?.fio}</b>
            </Box>
            {!!dataUser?.bpm_position && <Box className="app-sidebar-userbox-position ">
              {dataUser?.bpm_position}
            </Box>}
            <Box className="app-sidebar-userbox-description">
              {dataUser?.email}
            </Box>
            {!!dataUser?.phone && <Box className="app-sidebar-userbox-description">
              {dataUser?.phone}
            </Box>}
            <FormControl className={classes.selectCity_block}>
              <Select
                value={
                  defaultSelected?.city_id
                    ? defaultSelected?.city_id
                    : selectedCity
                }
                native
                onChange={handleChange}
              >
                <option value="" disabled={selectedCity}>
                  Выберите город
                </option>
                {cities?.map(el => {
                  return (
                    <option value={el.city_id} key={el.city_id}>
                      {el.city_name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.selectCity_block}>
              <Select
                value={selectedCompanyId || ""}
                native
                onChange={handleChangeCompany}
              >
                <option value="" disabled={!!dataUser?.company_id}>
                  Выберите компанию
                </option>
                {companies?.map(el => {
                  return (
                    <option value={el.company_id} key={el.company_id}>
                      {el.company_name}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </EmptyOrShow>
      </Box>
    </Fragment>
  );
};

export default SidebarUserbox;

const useStyles = makeStyles({
  selectCity_block: {
    border: "1px solid #ccc",
    borderRadius: 5,
    width: "100%",
    marginTop: 10,

    "& select": {
      paddingTop: 5,
      paddingLeft: 5,
      paddingBottom: 5
    },
    "& .MuiInput-underline": {
      "&::before": {
        content: "none",
        borderBottom: "none"
      },
      "&::after": {
        content: "none",
        borderBottom: "none"
      }
    }
  }
});
