import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteMasterDocumentData,
  getDefaultDocObj,
  getMasterDocumentsData,
  getMasterDocumentTypesData,
  uploadMasterDocumentData
} from "../../../features/teams/services";
import { AutoSuggest } from "../../../common/form/AutoSuggest";
import EmptyOrShow from "../../../common/EmptyOrShow";
import { CheckIconWrapper, DeleteIconWrapper } from "../../../common/blocks";
import CommentUploadFile from "../../../../pages/Problems/CommentUploadFile";
import { useCustomSnackbar } from "../../../../utils/hooks/useCustomSnackbar";
import { LoaderData } from "../../../common/LoaderData";
import { cropWord } from "../../../../utils";
import { CustomConfirm } from "../../../common/blocks/CustomConfirm";
import AddIcon from "@material-ui/icons/Add";
import styles from "./masterDocumentsModal.module.scss";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../common/CustomTable/CustomTable";
import { MAIN_URL } from "../../../../constants";

const docTableHeads = [
  { label: "ID" },
  { label: "Название" },
  { label: "Расширение" },
  { label: "Дата создания" },
  { label: "Тип документа" },
  { label: "" }
];
export const TeamMasterDocumentsModal = () => {
  const dispatch = useDispatch();
  const { addDefaultSnack, addCloseSnack } = useCustomSnackbar();
  const { popup, masterDocumentTypes } = useSelector(state => state.appState);
  const { data } = popup;

  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState({});
  const [deleteLoading, setDeleteLoading] = useState({});
  const [masterDocuments, setMasterDocuments] = useState([]);
  const [newMasterDocument, setNewMasterDocument] = useState(null);

  const { title, team_master_id } = data || {};

  useEffect(() => {
    dispatch(getMasterDocumentTypesData());
  }, [dispatch]);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const res = await getMasterDocumentsData(team_master_id);
      setIsFetching(false);
      setMasterDocuments(res);
    };
    getData();
  }, [team_master_id]);

  const handleSubmit = async () => {
    const doc = newMasterDocument;
    const docId = newMasterDocument?.team_master_document_id;
    if (!doc) return;
    if (!doc.file) return addCloseSnack("Выберите файл");
    if (!doc.team_master_document_type_id)
      return addCloseSnack("Выберите тип документа");
    const body = new FormData();
    body.append("document", doc.file);
    body.append(
      "team_master_document_type_id",
      doc.team_master_document_type_id
    );
    setLoading(prev => ({ ...prev, [docId]: true }));
    const res = await uploadMasterDocumentData(team_master_id, body);
    setLoading(prev => ({ ...prev, [docId]: false }));
    if (!res) return;
    addDefaultSnack("Файл успешно сохранен!");
    setMasterDocuments(res);
    setNewMasterDocument(null);
  };

  const onDeleteClick = async () => {
    setNewMasterDocument(null);
  };

  const addDoc = () => {
    if (newMasterDocument) return;
    const defaultDocObj = getDefaultDocObj();
    setNewMasterDocument(defaultDocObj);
  };

  const deleteDoc = async team_master => {
    const { team_master_document_id } = team_master;
    setDeleteLoading(prev => ({ ...prev, [team_master_document_id]: true }));
    const res = await deleteMasterDocumentData(
      team_master_id,
      team_master_document_id
    );
    setDeleteLoading(prev => ({ ...prev, [team_master_document_id]: false }));
    if (!res) return;
    setMasterDocuments(res);
    addDefaultSnack();
  };

  const onDocTypeChange = e => {
    const { value: team_master_document_type_id } = e.target;
    setNewMasterDocument(prev => ({ ...prev, team_master_document_type_id }));
  };

  const onFileChange = e => {
    if (!e?.target?.files?.length) return;
    try {
      const { files } = e.target;
      const file = files[0];
      setNewMasterDocument(prev => ({
        ...prev,
        team_master_document_name: file.name,
        file
      }));
    } catch (e) {}
  };

  return (
    <div className={styles.documents__wrapper}>
      <h2>{title}</h2>
      <EmptyOrShow loading={isFetching}>
        <CustomTable
          headItems={docTableHeads}
          isEmpty={!masterDocuments?.length}
          isFetching={isFetching}
        >
          {masterDocuments?.map(item => {
            return (
              <CustomTableRow key={item.team_master_document_id}>
                <CustomTableCell>
                  {item.team_master_document_id}
                </CustomTableCell>
                <CustomTableCell>
                  <a
                    download
                    target={"_blank"}
                    rel="noopener noreferrer"
                    href={`${MAIN_URL}${item?.team_master_document_url}`}
                    className={styles.documents__list_itemLink}
                    title={item?.team_master_document_name || ""}
                  >
                    <DownloadIcon
                      className={styles.documents__list_itemDownload}
                    />
                    {item?.team_master_document_name
                      ? `${cropWord(item?.team_master_document_name, 30)}`
                      : "Скачать"}
                  </a>
                </CustomTableCell>
                <CustomTableCell>
                  {item.team_master_document_extension}
                </CustomTableCell>
                <CustomTableCell>{item.created_date}</CustomTableCell>
                <CustomTableCell>
                  {item.team_master_document_type_name}
                </CustomTableCell>
                <CustomTableCell>
                  <CustomConfirm
                    onSubmit={() => deleteDoc(item)}
                    loading={deleteLoading[item.team_master_document_id]}
                  >
                    <DeleteIconWrapper title={"Удалить файл"} big />
                  </CustomConfirm>
                </CustomTableCell>
              </CustomTableRow>
            );
          })}
        </CustomTable>
        {newMasterDocument && (
          <div className={styles.documents__list}>
            <div
              key={newMasterDocument.team_master_document_id}
              className={styles.documents__list_item}
            >
              <span
                className={styles.documents__list_itemLink}
                title={newMasterDocument?.team_master_document_name || ""}
              >
                {cropWord(
                  newMasterDocument?.team_master_document_name,
                  30,
                  "Файл не выбран"
                )}
              </span>
              <AutoSuggest
                valueKey={"team_master_document_type_id"}
                showKey={"team_master_document_type_name"}
                label={"Выберите тип документа"}
                list={masterDocumentTypes}
                value={newMasterDocument.team_master_document_type_id}
                onChange={onDocTypeChange}
              />
              <div className={styles.documents__list_itemActions}>
                <CommentUploadFile
                  multiple={false}
                  absolute={false}
                  handleChange={onFileChange}
                />
                {deleteLoading[newMasterDocument.team_master_document_id] ? (
                  <LoaderData small />
                ) : (
                  <DeleteIconWrapper
                    title={"Удалить файл"}
                    big
                    onClick={onDeleteClick}
                  />
                )}
                {loading[newMasterDocument.team_master_document_id] ? (
                  <LoaderData small />
                ) : (
                  <CheckIconWrapper
                    big
                    title={"Сохранить файл"}
                    onClick={handleSubmit}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <div className={styles.documents__list_addWrapper}>
          <p onClick={addDoc} className={styles.documents__list_add}>
            Добавить документ <AddIcon />
          </p>
        </div>
      </EmptyOrShow>
    </div>
  );
};
