import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/common/pageTitle";
import { getContractorInfo } from "./services";
import styles from "./styles.module.scss";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { formatPhoneNumber, getLocaleDate } from "../../utils";
import clsx from "clsx";
import { showPopUp } from "../../services/redux/actions/app-actions";
import { MAIN_URL, MODALS_NAMES, PAGES } from "../../constants";
import { useDispatch } from "react-redux";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { LinearProgress } from "@material-ui/core";
import { NotFound } from "../../components/common/text";
import { CButton } from "../../components/common/buttons";
import { useNavigate } from "react-router";

SwiperCore.use([Navigation]);

export const ContractorInfo = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [data, setData] = useState();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const res = await getContractorInfo();
      setIsFetching(false);
      if (!res) return;
      setData(res);
    };
    getData();
  }, []);

  const onImageClick = (idx, images = []) => {
    dispatch(showPopUp(true, MODALS_NAMES.gallery, {
      activeIndex: idx,
      images, photoUrlKey: "photo_url"
    }));
  };

  const toFormPage = () => router(PAGES.forContractors)

  return (
    <div>
      <PageTitle title={"Данные подрядчика/мастера"} />
      <div className={styles.list}>
        {
          isFetching ? <LinearProgress />
            : !!data?.length
              ? data?.map(item => {
                return <div key={item.anketa_id} className={styles.list__item}>
                  <span className={styles.list__item_date}>{getLocaleDate(item.rowversion, true)}</span>
                  <div>
                    <div className={styles.list__item_header}>
                      <p className={styles.list__item_title}>Анкета #{item.anketa_id}</p>
                      <div className={styles.list__item_headerStatusWrapper}>
                        <span>Статус:</span>
                        <p className={styles.list__item_headerStatus}>{item.anketa_status_name}</p>
                      </div>
                    </div>
                    <div className={styles.list__item_data}>
                      {!!item.master_fio && <div>
                        <p>ФИО мастера</p>
                        <span>{item.master_fio}</span>
                      </div>}
                      {!!item.work_name && <div>
                        <p>Наименование вид услуг/работ</p>
                        <span>{item.work_name}</span>
                      </div>}
                      {!!item.master_date_birth && <div>
                        <p>День рождения</p>
                        <span>{getLocaleDate(item.master_date_birth)}</span>
                      </div>}
                      {!!item.city_name && <div>
                        <p>Город</p>
                        <span>{item.city_name}</span>
                      </div>}
                      {!!item.email && <div>
                        <p>Email</p>
                        <span>{item.email}</span>
                      </div>}
                      {!!item.phone && <div>
                        <p>Номер телефона</p>
                        <span>{formatPhoneNumber(item.phone)}</span>
                      </div>}
                      {!!item.contractor_org_name && <div>
                        <p>Наименование организации</p>
                        <span>{item.contractor_org_name}</span>
                      </div>}
                      {!!item.contractor_director_fio && <div>
                        <p>Директор</p>
                        <span>{item.contractor_director_fio}</span>
                      </div>}
                      {!!item.contractor_address_register && <div>
                        <p>Юридический адрес</p>
                        <span>{item.contractor_address_register}</span>
                      </div>}
                      {!!item.fact_address && <div>
                        <p>Фактический адрес</p>
                        <span>{item.fact_address}</span>
                      </div>}
                      {!!item.contractor_bin && <div>
                        <p>ИИН</p>
                        <span>{item.contractor_bin}</span>
                      </div>}
                    </div>

                    {!!item.bank_name && <div>
                      <p className={styles.list__item_dataTitle}>Банковские реквизиты</p>
                      <div className={clsx(styles.list__item_data, styles.list__item_dataMargin)}>
                        {!!item.iik && <div>
                          <p>Наименование банка</p>
                          <span>{item.bank_name}</span>
                        </div>}
                        {!!item.bik && <div>
                          <p>БИК</p>
                          <span>{item.bik}</span>
                        </div>}
                        {!!item.iik && <div>
                          <p>ИИК</p>
                          <span>{item.iik}</span>
                        </div>}
                      </div>
                    </div>}
                    {!!item.accountant_name && <div>
                      <p className={styles.list__item_dataTitle}>Контактные данные бухгалтера, ответственного лица</p>
                      <div className={clsx(styles.list__item_data, styles.list__item_dataMargin)}>
                        {!!item.accountant_name && <div>
                          <p>ФИО</p>
                          <span>{item.accountant_name}</span>
                        </div>}
                        {!!item.accountant_phone && <div>
                          <p>Контактный телефон</p>
                          <span>{formatPhoneNumber(item.accountant_phone)}</span>
                        </div>}
                      </div>
                    </div>}
                  </div>
                  {!!item?.documents?.length && <div className={styles.list__item_downloadWrapper}>
                    <p className={styles.list__item_dataTitle}>Прикрепленные документы</p>
                    {
                      item?.documents?.map(item => {
                        return <div key={item.anketa_document_type_id} className={styles.list__item_download}>
                          <a href={MAIN_URL + item.document_url} target={"_blank"} rel="noopener noreferrer">
                            <DownloadIcon />{documentType[item.anketa_document_type_id]}
                          </a>
                        </div>;
                      })
                    }
                  </div>}
                  {!!item?.work_photos?.length && <div className={styles.list__item_slider}>
                    <p className={styles.list__item_dataTitle}>Фото выполненных работ</p>
                    <Swiper
                      navigation={{
                        nextEl: ".img-swiper-next",
                        prevEl: ".img-swiper-prev"
                      }} spaceBetween={20} slidesPerView={3}
                      breakpoints={{
                        // when window width is >= 640px
                        0: {
                          slidesPerView: 1.3,
                          spaceBetween: 20
                        },
                        // when window width is >= 640px
                        450: {
                          slidesPerView: 2,
                          spaceBetween: 20
                        },
                        // when window width is >= 640px
                        576: {
                          slidesPerView: 2.5,
                          spaceBetween: 20
                        },
                        // when window width is >= 768px
                        768: {
                          slidesPerView: 2.2,
                          spaceBetween: 30
                        },
                        850: {
                          slidesPerView: 2.7,
                          spaceBetween: 30
                        },
                        // when window width is >= 1024px
                        1024: {
                          slidesPerView: 3.5,
                          spaceBetween: 20
                        }
                      }}
                    >
                      {item?.work_photos.map((i, index) => (
                        <SwiperSlide key={index}>
                          <div className={styles.list__item_img}>
                            <img onClick={() => onImageClick(index, item?.work_photos)} alt=""
                                 src={MAIN_URL + i.photo_url} />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>}
                </div>;
              })
              : <div>
                <NotFound>Данных не найдено, перейдите по ссылку для отправки данных</NotFound>
                <div className={styles.flex}><CButton onClick={toFormPage}>Перейти</CButton></div>
              </div>
        }
      </div>
    </div>
  );
};

const documentType = {
  1: "Удостоверение личности",
  2: "Свидетельство или талон индивидуального предпринимателя",
  3: "Копия банковских реквизитов"
};