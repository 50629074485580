import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CustomTable, CustomTableCell, CustomTableRow } from "../../common/CustomTable/CustomTable";
import { useDispatch } from "react-redux";
import { showPopUp } from "../../../services/redux/actions/app-actions";
import { MAIN_URL, MODALS_NAMES, PAGES_ROUTE_KEY } from "../../../constants";
import { Checkbox, Collapse, IconButton } from "@material-ui/core";
import { CLink } from "../../common/blocks/CustomLink";
import { htmlDecode } from "../../../utils";
import clsx from "clsx";
import RestoreIcon from "@material-ui/icons/Restore";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useHttp } from "../../../utils/hooks/http.hook";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { getDetailRequest, getRequestItemStatuses } from "./api";
import { LoaderData } from "../../common/LoaderData";
import { CustomDropdown } from "../../common/blocks/CustomDropdown";
import NumberFormat from "react-number-format";
import { useRequestsStyles } from "./index";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import ImportExportIcon from "@material-ui/icons/ImportExport";

export const RequestsTable = ({
                                isFetching, getData, setData,
                                isPending, data, searchTerm
                              }) => {
  const classes = useRequestsStyles();
  const [statuses, setStatuses] = useState([]);
  const [selectedRequests, setSelectedRequests] = useState({});
  const [allCollapsed, setAllCollapsed] = useState(false);

  const handleSelectRequest = id => {
    setAllCollapsed(false);
    setSelectedRequests(prevState => ({ ...prevState, [id]: !prevState[id] }));
  };

  const handleCollapseAll = () => {
    if (!data?.length) return;
    setAllCollapsed(prevState => !prevState);
    if (allCollapsed) {
      setSelectedRequests({});
      return;
    }
    const all = {};
    data.forEach(item => all[item.provider_request_id] = true);
    setSelectedRequests(all);
  };

  useEffect(() => {
    getRequestItemStatuses()
      .then(res => setStatuses(res))
      .catch(() => {
      });
  }, []);

  const headItems = [
    { label: "ID заявки" },
    { label: "ID Ремонт" },
    { label: "Поставщик" },
    { label: "Подрядчик" },
    { label: "Адрес доставки" },
    { label: "Статус" },
    {
      label: "",
      render: () => <ImportExportIcon
        className={clsx([classes.collapseTable, { [classes.collapseTableActive]: allCollapsed }])}
        onClick={handleCollapseAll} />,
      align: "center"
    }
  ];

  const computedData = useMemo(() => {
    try {
      const search = searchTerm.trim().toLowerCase();
      if (!search) return data;
      return data?.filter(
        item =>
          item?.provider_request_id
            ?.toString()
            .toLowerCase()
            .includes(search) ||
          item?.remont_id
            ?.toString()
            .toLowerCase()
            .includes(search) ||
          item?.provider_name?.toLowerCase().includes(search) ||
          item?.contractor_name?.toLowerCase().includes(search) ||
          item?.address?.toLowerCase().includes(search) ||
          (!!selectedRequests[item.provider_request_id] && item?.materials?.find(item => item.material_name?.toLowerCase()?.includes(search)))
      );
    } catch (e) {
      return data;
    }
  }, [data, searchTerm, selectedRequests]);

  return (
    <CustomTable
      highlight
      stickyHeader={false}
      isFetching={isFetching || isPending}
      headItems={headItems} updateDataReq={getData}
      isEmpty={!data?.length}
    >
      {computedData?.map(item => {
        return (
          <RequestItem
            key={item?.provider_request_id}
            item={item} setData={setData}
            statuses={statuses}
            selectedRequests={selectedRequests}
            handleSelectRequest={handleSelectRequest}
          />
        );
      })}
    </CustomTable>
  );
};

const RequestItem = ({
                       item,
                       selectedRequests,
                       statuses, setData,
                       handleSelectRequest
                     }) => {
  const dispatch = useDispatch();
  const classes = useRequestsStyles();
  const open = useMemo(() => selectedRequests[item.provider_request_id], [
    selectedRequests,
    item.provider_request_id
  ]);

  const showHistoryModal = provider_request_id => {
    dispatch(
      showPopUp(true, MODALS_NAMES.requestShipHistory, {
        provider_request_id,
        close: true
      })
    );
  };
  return (
    <>
      <CustomTableRow highlight>
        <CustomTableCell width={80}>
          {item?.provider_request_id}
        </CustomTableCell>
        <CustomTableCell width={80}>
          <CLink
            to={`${PAGES_ROUTE_KEY.singleRemont}/${item?.remont_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item?.remont_id}
          </CLink>
        </CustomTableCell>
        <CustomTableCell width={430}>
          <div className={classes.provider}>
            <div
              className={classes.providerName}
              dangerouslySetInnerHTML={{
                __html: htmlDecode(item?.provider_name)
              }}
            />
            <div>
              Дата ближайшей отгрузки {item?.date_shipping_plan || ""}
            </div>
            {(!!item?.is_from_warehouse ||
              !!item?.is_addition ||
              !!item?.is_service ||
              item?.provider_request_type_id === 2) && (
              <div className={classes.tips}>
                {!!item?.is_from_warehouse && (
                  <div className={classes.tip}>
                    Со склада {item?.warehouse_name || ""}
                  </div>
                )}
                {!!item?.is_addition && (
                  <div className={classes.descriptionTip}>
                    <div className={clsx(classes.tip, classes.addition)}>
                      Доп. заявка
                    </div>
                    <span>{item?.additional_reason_name || ""}</span>
                  </div>
                )}
                {!!item?.is_service && (
                  <div className={clsx(classes.tip, classes.service)}>
                    Сервис
                  </div>
                )}
                {item?.provider_request_type_id === 2 && (
                  <div className={classes.descriptionTip}>
                    <div className={clsx(classes.tip, classes.order)}>
                      Заказчик подрядчик
                    </div>
                    <span>{item?.provider_contractor_name || ""}</span>
                  </div>
                )}
              </div>
            )}
            <div className={classes.requestName}>
              {`Заявка № ${item?.provider_request_id} от ${item?.date_send}`}{" "}
              <RestoreIcon
                className={classes.icon}
                onClick={() => showHistoryModal(item?.provider_request_id)}
              />
            </div>
          </div>
        </CustomTableCell>
        <CustomTableCell>{item?.contractor_name || ""}</CustomTableCell>
        <CustomTableCell>{`${item?.city_name} ${item?.resident_name} ${item?.flat_num}`}</CustomTableCell>
        <CustomTableCell>
          <div className={classes.provider}>
            <div dangerouslySetInnerHTML={{ __html: item?.statuses }} />
          </div>
        </CustomTableCell>
        <CustomTableCell align={"center"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleSelectRequest(item?.provider_request_id)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </CustomTableCell>
      </CustomTableRow>
      {open && (
        <CustomTableRow>
          <CustomTableCell colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <CollapseTable
                statuses={statuses} setData={setData}
                requestId={item?.provider_request_id}
              />
            </Collapse>
          </CustomTableCell>
        </CustomTableRow>
      )}
    </>
  );
};

const CollapseTable = ({
                         requestId,
                         statuses, setData: setDetailData
                       }) => {
  const classes = useRequestsStyles();
  const dispatch = useDispatch();
  const { request, loading: isLoading } = useHttp();
  const { addDefaultSnack, addCloseSnack } = useCustomSnackbar();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [checkedMaterials, setCheckedMaterials] = useState({});

  const getDetailData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getDetailRequest(requestId);
      setLoading(false);
      res && setData(res);
      setDetailData(prev => prev?.map(item => {
        if (item.provider_request_id === requestId)
          return { ...item, materials: res };
        return item;
      }));
    } catch (e) {
      setLoading(false);
    }
  }, [requestId, setDetailData]);

  useEffect(() => {
    getDetailData();
  }, [getDetailData]);

  const dropdownOptions = [
    { id: "change_ship", label: "Сменить дату отгрузки" },
    { id: "statuses_history", label: "История статусов" }
  ];

  const onStatusesOptionClick = async provider_request_status_id => {
    const provider_request_item_ids = Object.keys(checkedMaterials)
      .filter(item => !!checkedMaterials[item])
      .map(item => Number(item));
    const res = await request(
      `partner/provider_requests/${requestId}/change_status/`,
      "post",
      {
        provider_request_status_id,
        provider_request_item_ids
      }
    );
    if (res?.status) {
      setCheckedMaterials({});
      addDefaultSnack("Успешно");
      setData(res?.provider_request_items);
    }
  };

  const onChangeShipDateSubmit = async body => {
    const provider_request_item_ids = Object.keys(checkedMaterials)
      .filter(item => !!checkedMaterials[item])
      .map(item => Number(item));
    const res = await request(
      `partner/provider_requests/${requestId}/change_dsh/`,
      "post",
      {
        ...body,
        provider_request_item_ids
      },
      {},
      { isModal: true }
    );
    if (res?.status) {
      setCheckedMaterials({});
      setData(res?.provider_request_items);
      addDefaultSnack("Успешно");
      dispatch(showPopUp(false));
    }
  };
  const onOptionClick = id => {
    switch (id) {
      case "change_ship": {
        if (!countChecked) return addCloseSnack("Выберите материалы");
        dispatch(
          showPopUp(true, MODALS_NAMES.requestsDate, {
            onSubmit: onChangeShipDateSubmit,
            close: true
          })
        );
        break;
      }
      case "statuses_history": {
        dispatch(
          showPopUp(true, MODALS_NAMES.requestStatusesHistory, {
            close: true,
            provider_request_id: requestId
          })
        );
        break;
      }
      default: {
      }
    }
  };

  const handleCheckChange = (id, checked) => {
    setCheckedMaterials(prevState => ({ ...prevState, [id]: checked }));
  };

  const handleCheckAll = checked => {
    if (!data) return;
    const checkData = {};
    data.forEach(item => {
      checkData[item?.provider_request_item_id] = checked;
    });
    setCheckedMaterials(checkData);
  };

  const checkAll = useMemo(() => {
    const keys = Object.keys(checkedMaterials);
    if (keys?.length !== data?.length) return false;
    const checkedData = keys.map(item => ({
      checked: checkedMaterials[item]
    }));
    return checkedData.every(item => item?.checked);
  }, [data, checkedMaterials]);

  const countChecked = useMemo(() => {
    const keys = Object.keys(checkedMaterials);
    const checkedData = keys.map(item => ({
      checked: checkedMaterials[item]
    }));
    return checkedData.some(item => item?.checked);
  }, [checkedMaterials]);

  const headMaterialItems = [
    { label: "ID", align: "center" },
    { label: "Материал" },
    { label: "Кол-во в ед. продаж" },
    { label: "Кол-во в ед. отпуска" },
    { label: "Кол-во в ед. расх." },
    { label: "В мин. кол-во" },
    { label: "Цена" },
    { label: "Сумма" },
    { label: "Дата отгрузки", align: "center" },
    { label: "Статус" },
    { label: "Скачать", align: "center" },
    {
      label: "",
      align: "center",
      render: () => (
        <Checkbox
          id={"select_all_items"}
          checked={!!checkAll}
          onChange={e => handleCheckAll(!!e?.target?.checked)}
        />
      )
    }
  ];

  if (loading || isLoading) return <LoaderData small />;

  return (
    <div className={classes.collapse}>
      <div className={classes.header}>
        <h2 className={classes.title}>Материалы заявки #{requestId}</h2>
        <div className={classes.flex}>
          <CustomDropdown
            disabled={!countChecked}
            options={statuses}
            label={"Статус"}
            showOption={"provider_request_status_name"}
            valueOption={"provider_request_status_id"}
            onOptionClick={onStatusesOptionClick}
          />
          <CustomDropdown
            options={dropdownOptions}
            onOptionClick={onOptionClick}
          />
        </div>
      </div>
      <CustomTable
        isEmpty={!data?.length}
        headItems={headMaterialItems}
        withMaxHeight={false}
      >
        {data?.map(item => {
          return (
            <CustomTableRow key={item?.provider_request_item_id}>
              <CustomTableCell width={70} align={"center"}>
                {item?.material_id}
              </CustomTableCell>
              <CustomTableCell width={300}>
                <span dangerouslySetInnerHTML={{ __html: item?.material_name }} />
              </CustomTableCell>
              <CustomTableCell
                width={70}
              >{`${item?.material_cnt} ${item?.sell_unit_name}`}</CustomTableCell>
              <CustomTableCell
                width={70}
              >{`${item?.delivery_cnt} ${item?.delivery_unit_name}`}</CustomTableCell>
              <CustomTableCell
                width={70}
              >{`${item?.consumption_cnt} ${item?.consume_unit_name}`}</CustomTableCell>
              <CustomTableCell
                width={70}
              >{`${item?.qty_atom} ${item?.atom_unit_name}`}</CustomTableCell>
              <CustomTableCell align={"right"}>
                <NumberFormat
                  value={item?.price}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </CustomTableCell>
              <CustomTableCell align={"right"}>
                <NumberFormat
                  value={item?.price_amount}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </CustomTableCell>
              <CustomTableCell align={"center"}>
                <span dangerouslySetInnerHTML={{ __html: item?.date_shipping }} />
              </CustomTableCell>
              <CustomTableCell>
                {item?.provider_request_status_name}
              </CustomTableCell>
              <CustomTableCell align={"center"}>
                {
                  item.expeditor_file_url &&
                  <a className={classes.link}
                     href={`${MAIN_URL}${item.expeditor_file_url}`} target={"_blank"} rel="noopener noreferrer">
                    <DownloadIcon />
                  </a>
                }
              </CustomTableCell>
              <CustomTableCell width={80} align={"center"}>
                <Checkbox
                  id={"select_all_items"}
                  checked={!!checkedMaterials[item.provider_request_item_id]}
                  onChange={e =>
                    handleCheckChange(
                      item.provider_request_item_id,
                      !!e?.target?.checked
                    )
                  }
                />
              </CustomTableCell>
            </CustomTableRow>
          );
        })}
      </CustomTable>
    </div>
  );
};
