import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles({
  btn: {
    backgroundColor: "#0C4B86",
    borderRadius: 4,
    lineHeight: '15px',
    padding: 10,
    color: "#fff",
    whiteSpace: "nowrap",
    width: ({ width }) => width,
    height: ({ height }) => height,
    "&:hover": {
      backgroundColor: "#0C4B86"
    }
  },
  wrap: {
    whiteSpace: "normal",
    height: "auto"
  },
  disabled: {
    backgroundColor: "rgba(0, 0, 0, 0.12)"
  }
});

const UtilityBtn = ({
  text,
  handleClick,
  width,
  wrap,
  height,
  type,
  disabled = false,
  ...otherProps
}) => {
  const props = {
    width,
    height
  };
  const classes = useStyles(props);

  return (
    <Button
      className={clsx(classes.btn, {[classes.wrap]: wrap})}
      onClick={handleClick}
      disabled={disabled}
      classes={{ disabled: classes.disabled }}
      {...otherProps}
    >
      {text}
    </Button>
  );
};

export default UtilityBtn;
