import React, { useState } from "react";
import { Box, Collapse } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { CustomTable, CustomTableCell, CustomTableRow } from "../../../../components/common/CustomTable/CustomTable";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root_cell: {
    padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: "#00000",
    lineHeight: "20px",
    cursor: "pointer"
  },
  table: {
    borderRadius: 0
  }
});

const headerTitles = [
  { label: "ID материала" },
  { label: "Материал" },
  { label: "Тип материала" },
  { label: "Поставщик" },
  { label: "Количество (шт)" },
  { label: "Количество (ед. изм)" }
];
const iconStyles = {
  color: "#00000",
  display: "inline-block",
  marginLeft: 9,
  transform: "translateY(3px)"
};
const SmetaMaterialsRow = ({ data, title }) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  return (
    <>
      <div>
        <Box
          onClick={() => setOpen(!open)}
          classes={{ root: classes.root_cell }}
          className={classes.title}
        >
          {title}
          {open ? (
            <KeyboardArrowUpIcon style={iconStyles} />
          ) : (
            <KeyboardArrowDownIcon style={iconStyles} />
          )}
        </Box>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box>
          <CustomTable
            headItems={headerTitles}
            containerClassName={classes.table}
            isEmpty={!data?.length}
          >
            {data?.map(el => (
              <CustomTableRow key={el.smeta_material_id}>
                <CustomTableCell>{el.material_id}</CustomTableCell>
                <CustomTableCell>{el.material_name}</CustomTableCell>
                <CustomTableCell>{el.material_type_name}</CustomTableCell>
                <CustomTableCell>{el.provider_name}</CustomTableCell>
                <CustomTableCell>{el.qty_atom}</CustomTableCell>
                <CustomTableCell>{el.qty_consume}</CustomTableCell>
              </CustomTableRow>
            ))}
          </CustomTable>
        </Box>
      </Collapse>
    </>
  );
};

export default SmetaMaterialsRow;
