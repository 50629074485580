import {
  RESET_ACCOUNTING_STATE,
  SET_ACCOUNTING_AMOUNT,
  SET_ACCOUNTING_AMOUNT_TYPES,
  SET_COST_CONTRACTOR_TYPES,
  SET_COST_CONTRACTOR_TYPES_FILTER,
  SET_COST_PAYMENT_TYPE,
  SET_DEBT_TYPES,
  SET_INCOME_CONTRACTOR_TYPES,
  SET_PAYBOX_BALANCE,
  SET_PAYMENT_TYPES
} from "../actions/accounting-actions";

const initialState = {
  accountingAmountTypes: {
    income: null,
    cost: null
  },
  accountingAmount: null,
  contractorCostTypes: null,
  contractorCostTypesFilter: null,
  contractorIncomeTypes: null,
  payboxBalance: { balance: 0 },
  paymentTypes: null,
  costPaymentType: null,
  debtTypes: null
};

export const accountingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DEBT_TYPES:
      return { ...state, debtTypes: payload };
    case SET_ACCOUNTING_AMOUNT_TYPES:
      return {
        ...state,
        accountingAmountTypes: {
          ...state.accountingAmountTypes,
          ...payload
        }
      };
    case SET_ACCOUNTING_AMOUNT:
      return { ...state, accountingAmount: payload };
    case SET_COST_CONTRACTOR_TYPES:
      return { ...state, contractorCostTypes: payload };
    case SET_COST_CONTRACTOR_TYPES_FILTER:
      return { ...state, contractorCostTypesFilter: payload };
    case SET_INCOME_CONTRACTOR_TYPES:
      return { ...state, contractorIncomeTypes: payload };
    case SET_PAYBOX_BALANCE:
      return { ...state, payboxBalance: payload };
    case SET_PAYMENT_TYPES:
      return { ...state, paymentTypes: payload };
    case SET_COST_PAYMENT_TYPE:
      return { ...state, costPaymentType: payload };
    case RESET_ACCOUNTING_STATE:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
