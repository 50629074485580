import React, { useEffect, useState } from "react";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { getColumns, moveCards } from "./api";
import { useDispatch, useSelector } from "react-redux";
import { setColumns } from "../../services/redux/actions/problemsActions";
import ModalTitle from "./ModalTitle";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  modal: {
    maxWidth: 375,
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    padding: "25px 20px",
    position: "absolute",
    zIndex: 100,
    right: 138,
    top: 23
  },
  subtitle: {
    color: "#494C62",
    marginTop: 10,
    marginBottom: 20,
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase"
  },
  stage_btn: {
    height: 30,
    backgroundColor: "#EAECF0",
    padding: "8px ",
    borderRadius: 4,
    textTransform: "none",
    justifyContent: "flex-start !important",
    marginBottom: 15
  }
});

const Movement = ({ col_id, setOpen }) => {
  const classes = useStyles();
  const [cols, setCols] = useState([]);
  const dispatch = useDispatch();
  const { cardId } = useSelector(state => state.problemsState);
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const handleClick = to_col_id => {
    const params = {
      from_col_id: col_id,
      to_col_id,
      current_card_id: cardId,
      next_card_id: null,
      prev_card_id: null
    };
    moveCards(params)
      .then(res => {
        dispatch(setColumns(res?.data));
        setOpen(false);
        addDefaultSnack("Карточка успешно перемещена");
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  };
  useEffect(() => {
    getColumns()
      .then(res => {
        setCols(res.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [addCloseSnack]);
  return (
    <Paper className={classes.modal}>
      <ModalTitle title="Перемещение карточки" setOpen={setOpen} />
      <Typography className={classes.subtitle}>
        Выберите куда требуется переместить
      </Typography>
      <Box>
        {cols?.map(el => {
          return (
            <Button
              fullWidth
              className={classes.stage_btn}
              key={el.col_id}
              onClick={() => handleClick(el.col_id)}
            >
              <ArrowForwardIcon style={{ marginRight: 15, color: "#0C4B86" }} />{" "}
              {el.col_name}
            </Button>
          );
        })}
      </Box>
    </Paper>
  );
};

export default Movement;
