import React, { useState } from "react";
import { Box, Collapse } from "@material-ui/core";
import HeaderTitle from "./HeaderTitle";
import RemontsProblemCard from "./RemontsProblemCard";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  content_wrapper: {
    "&:last-child": {
      marginBottom: 140
    }
  }
});

const ProblemContainer = ({ cards, col_name, col_id }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box className={classes.content_wrapper}>
      <Box onClick={handleClick} mb={1}>
        <HeaderTitle
          title={col_name}
          count={cards.length}
          bg={getBgColor(col_id)}
          open={open}
        />
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box>
          {cards?.map(el => {
            return (
              <RemontsProblemCard key={el.card_id} {...el} col_id={col_id} />
            );
          })}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ProblemContainer;

const getBgColor = id => {
  switch (id) {
    case 35:
      return "#F0AB8E";
    case 36:
      return "#0C4B86";
    case 37:
      return "#970DD8";
    case 38:
      return "#64D17B";
    default:
      return "#F0AB8E";
  }
};
