import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

const GoogleAnalytics = () => {
  let history = useNavigate();
  let location = useLocation();

  //gtag is inserted as a global script at /public/index.html
  const gtag = window.gtag;
  useEffect(() => {
    if (typeof gtag === "function") {
      gtag("config", "G-GJK9REH93T", {
        page_title: document.title,
        page_location: location.pathname + location.search,
        page_path: location.pathname
      });
    }
  }, [history, location, gtag]);
  return null;
};

export default GoogleAnalytics;
