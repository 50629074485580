import React, { useCallback, useEffect, useState } from "react";
import { getDebts, getTeamDebts } from "../../api";
import styles from "./debts.module.scss";
import { CustomTable, CustomTableCell, CustomTableRow } from "../../../../common/CustomTable/CustomTable";
import { getTeamsData, showPopUp } from "../../../../../services/redux/actions/app-actions";
import { getDebtTypes } from "../../../../../services/redux/actions/accounting-actions";
import { useDispatch } from "react-redux";
import { useHttp } from "../../../../../utils/hooks/http.hook";
import { useCustomSnackbar } from "../../../../../utils/hooks/useCustomSnackbar";
import { CopyIconWrapper, CustomButton, DeleteIconWrapper, EditIconWrapper } from "../../../../common/blocks";
import clsx from "clsx";
import { useTableStyles } from "../../../accounting/styles";
import { ActiveCheckbox } from "../../index";
import { getLastAndFirstDayOfMonth, numberWithCommas } from "../../../../../utils";
import { PageTitle } from "../../../../common/pageTitle";
import { DebtFilter } from "./filter";
import { ExpandLess } from "@material-ui/icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { getDebtsDetail } from "./api";
import { Collapse, Tooltip } from "@material-ui/core";
import { LoaderData } from "../../../../common/LoaderData";
import { NotFound } from "../../../../common/text";
import { MODALS_NAMES } from "../../../../../constants";
import DownloadXlsx from "../../../../common/DownloadXlsx";
import { CollapseBlock } from "../../../../common/CollapseBlock/collapseBlock";

const {first, last} = getLastAndFirstDayOfMonth()
export const TeamDebt = ({ team_id, teamMode }) => {
  const dispatch = useDispatch();
  const { request } = useHttp();
  const { addDefaultSnack } = useCustomSnackbar();
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState(null);
  const [sumData, setSumData] = useState(null);
  const [filters, setFilters] = useState({
    date_create_begin: first || "",
    date_create_end: last || ""
  });

  let headItems = [
    { label: "Создатель", align: "left" },
    { label: "Бригада", align: "left", all: true },
    { label: "Тип долга", align: "left" },
    { label: "Дата создания", align: "left" },
    { label: "Дата начала списаний", align: "left" },
    { label: "Сумма долга", align: "right" },
    { label: "Погашенная сумма", align: "right" },
    { label: "Остаток", align: "right" },
    { label: "Тип списания", align: "left" },
    { label: "Процент", align: "left" },
    { label: "Списан", align: "left" },
    { label: "Комментарии", align: "left" },
    { label: "", align: "" },
    { label: "", align: "" }
  ];
  if (teamMode) headItems = headItems?.filter(item => !item?.all);

  useEffect(() => {
    dispatch(getTeamsData());
    dispatch(getDebtTypes());
  }, [dispatch]);

  const getDebtData = useCallback(
    async () => {
      try {
        setIsFetching(true);
        const res = teamMode
          ? await getTeamDebts(team_id, filters || {})
          : await getDebts(filters || {});
        setIsFetching(false);
        if (res) {
          const { debts, ...other } = res;
          setData(debts);
          setSumData(other);
        }
      } catch (e) {
        setIsFetching(false);
      }
    },
    [team_id, teamMode, filters]
  );

  useEffect(() => {
    getDebtData();
  }, [getDebtData]);

  const onAddClick = () => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addTeamDebt, {
        onSubmit,
        close: true,
        teamMode
      })
    );
  };

  const onSubmit = async data => {
    let endpoint = "partner/debts/";
    if (teamMode) endpoint = `partner/debts/team/${team_id}/`;
    const res = await request(
      endpoint,
      "post",
      data,
      {},
      { isModal: true, params: filters }
    );
    if (res?.status) {
      addDefaultSnack("Долг успешно создан");
      const { debts, ...other } = res;
      setData(debts);
      setSumData(other);
      dispatch(showPopUp(false));
    }
  };

  const onDeleteClick = debt_id => {
    dispatch(
      showPopUp(true, MODALS_NAMES.confirmModal, {
        onSubmit: () => handleDelete(debt_id)
      })
    );
  };

  const handleDelete = async debt_id => {
    let endpoint = `partner/debts/debt/${debt_id}/`;
    if (teamMode) endpoint = `partner/debts/team/${team_id}/debt/${debt_id}/`;
    const res = await request(
      endpoint,
      "delete",
      { params: filters },
      {},
      { isModal: true }
    );
    if (res?.status) {
      addDefaultSnack("Успешно");
      const { debts, ...other } = res;
      setData(debts);
      setSumData(other);
      dispatch(showPopUp(false));
    }
  };

  const onEditClick = body => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addTeamDebt, {
        onSubmit: data => onSubmitUpdate(data, body?.debt_id),
        body,
        teamMode,
        close: true,
        isEdit: true
      })
    );
  };

  const onCloneCostClick = body => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addTeamDebt, {
        onSubmit,
        body,
        teamMode,
        close: true
      })
    );
  };

  const onSubmitUpdate = async (data, debt_id) => {
    let endpoint = `partner/debts/debt/${debt_id}/`;
    if (teamMode) endpoint = `partner/debts/team/${team_id}/debt/${debt_id}/`;
    const res = await request(
      endpoint,
      "put",
      data,
      {},
      { isModal: true, params: filters }
    );
    if (res?.status) {
      addDefaultSnack("Успешно");
      const { debts, ...other } = res;
      setData(debts);
      setSumData(other);
      dispatch(showPopUp(false));
    }
  };

  const onFilterChange = async filters => {
    setFilters(filters);
  };

  return (
    <>
      {!teamMode && <PageTitle title={"Долги"} />}
      <div className={clsx(styles.debts, { [styles.debts__all]: !teamMode })}>
        <div className={styles.debts__header}>
          <div className={styles.debts__amounts}>
            <p className={styles.debts__amounts_item}>
              Общая сумма:{" "}
              <span>{numberWithCommas(sumData?.debt_sum)} тг.</span>
            </p>
            <p className={styles.debts__amounts_item}>
              Погашено:{" "}
              <span>{numberWithCommas(sumData?.debt_sum_paid)} тг.</span>
            </p>
            <p className={styles.debts__amounts_item}>
              Осталось:{" "}
              <span>{numberWithCommas(sumData?.debt_sum_remaining)} тг.</span>
            </p>
          </div>
          <div className={styles.debts__header_btns}>
            <CustomButton variant="outlined" onClick={onAddClick}>
              Добавить
            </CustomButton>
            <DownloadXlsx
              url="/partner/debts/excel/"
              params={filters}
              withoutMargin
              title="Отчет долгов"
              reqType="get"
            />
          </div>
        </div>
        <CollapseBlock>
          <div className={clsx(styles.debts__header_btns, styles.debts__header_btnsMobile)}>
            <CustomButton variant="outlined" onClick={onAddClick}>
              Добавить
            </CustomButton>
            <DownloadXlsx
              url="/partner/debts/excel/"
              params={filters}
              withoutMargin
              title="Отчет долгов"
              reqType="get"
            />
          </div>
          <DebtFilter
            onFilterChange={onFilterChange}
            showTeamFilter={!teamMode} defaultFilters={filters}
          />
        </CollapseBlock>
        <CustomTable
          isFetching={isFetching}
          headItems={headItems}
          stickyHeader
          isEmpty={!data?.length}
          containerClassName={styles.debts__content}
        >
          {data?.map(item => {
            return (
              <DebtTableRow
                key={item?.debt_id}
                onDeleteClick={onDeleteClick}
                onEditClick={onEditClick}
                onCloneCostClick={onCloneCostClick}
                teamMode={teamMode}
                item={item}
              />
            );
          })}
        </CustomTable>
      </div>
    </>
  );
};

const debtType = {
  1: "От суммы платежа",
  2: "От суммы долга"
};

const IconWrapper = ({ children, is_changeable }) => {
  if (is_changeable) {
    return <span>{children}</span>;
  }
  return (
    <Tooltip title={"У данного долга уже есть списания"}>{children}</Tooltip>
  );
};

const DebtTableRow = ({
                        item,
                        teamMode,
                        onEditClick,
                        onDeleteClick,
                        onCloneCostClick
                      }) => {
  const classesTable = useTableStyles();
  const [openTab, setOpenTab] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [detailData, setDetailData] = useState([]);

  const handleChange = async () => {
    if (item?.is_changeable) return;
    setOpenTab(prevState => !prevState);
    if (!openTab)
      try {
        setIsFetching(true);
        const res = await getDebtsDetail(item?.debt_id);
        if (res) setDetailData(res);
        setIsFetching(false);
      } catch (e) {
        setIsFetching(false);
      }
  };

  return (
    <>
      <CustomTableRow isBinaryDiv={false}>
        <CustomTableCell>{item?.fio}</CustomTableCell>
        {!teamMode && (
          <CustomTableCell>{`${item?.team_id}, ${item?.team_name}`}</CustomTableCell>
        )}
        <CustomTableCell align={"left"}>{item?.debt_type_name}</CustomTableCell>
        <CustomTableCell align={"center"}>{item?.date_create}</CustomTableCell>
        <CustomTableCell align={"center"}>{item?.date_start}</CustomTableCell>
        <CustomTableCell align={"right"}>
          {numberWithCommas(item?.debt_sum)}
        </CustomTableCell>
        <CustomTableCell align={"right"}>
          {numberWithCommas(item?.debt_sum_paid)}
        </CustomTableCell>
        <CustomTableCell align={"right"}>
          {numberWithCommas(item?.debt_sum_remaining)}
        </CustomTableCell>
        <CustomTableCell>{debtType[item?.percent_type]}</CustomTableCell>
        <CustomTableCell width={50} align={"right"}>
          {item?.percent}
        </CustomTableCell>
        <CustomTableCell align={"center"}>
          {!!item.is_finished && <ActiveCheckbox />}
        </CustomTableCell>
        <CustomTableCell>{item?.comments}</CustomTableCell>
        <CustomTableCell align="center">
          <div className={classesTable.actions}>
            <IconWrapper is_changeable={item?.is_changeable}>
              <span className={classesTable.iconWrapper}>
                <EditIconWrapper
                  disabled={!item?.is_changeable}
                  onClick={() => item?.is_changeable && onEditClick(item)}
                />
              </span>
            </IconWrapper>
            <IconWrapper is_changeable={item?.is_changeable}>
              <span className={classesTable.iconWrapper}>
                <DeleteIconWrapper
                  disabled={!item?.is_changeable}
                  onClick={() =>
                    item?.is_changeable && onDeleteClick(item?.debt_id)
                  }
                />
              </span>
            </IconWrapper>
            <Tooltip color={"green"} title={"Клонировать"}>
              <span className={classesTable.iconWrapper}>
                <CopyIconWrapper
                  onClick={() => onCloneCostClick && onCloneCostClick(item)}
                />
              </span>
            </Tooltip>
          </div>
        </CustomTableCell>
        <CustomTableCell>
          <span
            onClick={handleChange}
            className={`${styles.debts__icon} ${
              item?.is_changeable ? styles.debts__icon_disabled : ""
            }`}
          >
            {openTab ? <ExpandLess /> : <ChevronRightIcon />}
          </span>
        </CustomTableCell>
      </CustomTableRow>

      <CustomTableRow isBinaryDiv={false}>
        <CustomTableCell openTab={openTab} colSpan={15}>
          <Collapse in={openTab} timeout="auto" unmountOnExit>
            {isFetching ? (
              <LoaderData small />
            ) : !!detailData?.length ? (
              <CustomTable headItems={collapseTableHeads}>
                {detailData?.map((item, i) => {
                  return (
                    <CustomTableRow key={item?.contractor_cost_id}>
                      <CustomTableCell>{i + 1}</CustomTableCell>
                      <CustomTableCell>
                        {item?.contractor_cost_type_name}
                      </CustomTableCell>
                      <CustomTableCell>
                        {item?.remont_information}
                      </CustomTableCell>
                      <CustomTableCell>
                        {item?.stage_contractor_name}
                      </CustomTableCell>
                      <CustomTableCell align={"center"}>
                        {item?.cost_date}
                      </CustomTableCell>
                      <CustomTableCell align={"right"}>
                        {numberWithCommas(item?.cost_sum)}
                      </CustomTableCell>
                      <CustomTableCell>{item?.comment}</CustomTableCell>
                    </CustomTableRow>
                  );
                })}
              </CustomTable>
            ) : (
              <NotFound />
            )}
          </Collapse>
        </CustomTableCell>
      </CustomTableRow>
    </>
  );
};

const collapseTableHeads = [
  { label: "№" },
  { label: "Тип расхода" },
  { label: "Ремонт" },
  { label: "Этап" },
  { label: "Дата расхода" },
  { label: "Сумма списания" },
  { label: "Комментарии" }
];
