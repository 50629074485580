import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { CButton } from "../../common/buttons";

export const ConfirmModal = ({ data, handleClose }) => {
  const classes = useStyles();
  const [modalLoading, setModalLoading] = useState(false);
  const { onSubmit, id } = data;

  const handleSubmit = async () => {
    setModalLoading(true);
    onSubmit && (await onSubmit(id));
    setModalLoading(false);
  };
  return (
    <div className={classes.wrapper}>
      <h1 className={classes.title}>
        {data?.title || "Вы действительно хотите удалить?"}
      </h1>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.btn_wrap}
      >
        <Button
          className={clsx(classes.btn, classes.cancel)}
          variant="outlined"
          onClick={handleClose}
        >
          Отмена
        </Button>
        <CButton
          loading={modalLoading}
          disabled={modalLoading}
          className={clsx(classes.btn, classes[data?.type || "delete"])}
          onClick={handleSubmit}
        >
          {data?.btnLabel ||
            (data?.type === "default" ? "Сохранить" : "Удалить")}
        </CButton>
      </Box>
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    maxWidth: 525
  },
  title: {
    maxWidth: "fit-content",
    margin: 0,
    textAlign: "center"
  },
  btn: {
    width: 200,
    height: 38,
    padding: 10,
    borderRadius: 4
  },
  btn_wrap: {
    gap: "15px",
    marginTop: "18px"
  },
  delete: {
    color: "#fff",
    backgroundColor: "#FF6262"
  },
  default: {
    color: "#fff",
    backgroundColor: "#0C4B86"
  },
  cancel: {
    borderColor: "#0C4B86",
    color: "#0C4B86"
  }
});
