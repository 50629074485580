import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getMastersPayments } from "./services";
import { CustomTable, CustomTableCell, CustomTableRow } from "../../components/common/CustomTable/CustomTable";
import { numberWithCommas } from "../../utils";
import { PageTitle } from "../../components/common/pageTitle";
import styles from "./styles.module.scss";
import { MasterPaymentsFilters } from "./filters";
import { useParams } from "react-router";
import { Amount } from "../../components/common/blocks/Amount";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { useDispatch, useSelector } from "react-redux";
import { getIntegratedPaymentTypesData } from "../../services/redux/actions/app-actions";
import { DEFAULT_WALLET } from "../../services";
import { LoaderData } from "../../components/common/LoaderData";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { useDownloadFile } from "../remonts/RemontInfo/Gpr/useDownloadFile";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";
import clsx from "clsx";

export const MastersPayments = () => {
  const query = useParams();
  const dispatch = useDispatch();
  const { downloadFile } = useDownloadFile();

  const isCancelled = query.statusCode === "CANCELLED";
  const [payments, setPayments] = useState([]);
  const [filtersHeight, setFiltersHeight] = useState(200);
  const [isFetching, setIsFetching] = useState(false);
  const { integratedPaymentTypes } = useSelector(state => state.appState);
  const [fetchingTypes, setFetchingTypes] = useState(false);
  const [filters, setFilters] = useState(isCancelled ? { integrated_payment_type_code: DEFAULT_WALLET } : {});
  const [downloadingIds, setDownloadingIds] = useState({});

  useEffect(() => {
    dispatch(getIntegratedPaymentTypesData(setFetchingTypes));
  }, [dispatch]);

  const getPayments = useCallback(
    async () => {
      const isCancelled = query.statusCode === "CANCELLED";
      setIsFetching(true);
      const res = await getMastersPayments({
        ...filters,
        integrated_payment_type_code: isCancelled ? filters.integrated_payment_type_code || DEFAULT_WALLET : "",
        contractor_cost_status_code: query.statusCode?.toUpperCase()
      });
      setIsFetching(false);
      setPayments(res);
    }, [query.statusCode, filters]);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  const onFiltersChange = (filters = {}) =>
    setFilters(prev => ({ ...prev, ...filters }));

  const onChange = (key, value) => {
    if (!value) return;
    setFilters(prev => ({ ...prev, [key]: value || "" }));
  };
  const handleDownload = async contractor_cost_id => {
    if (downloadingIds[contractor_cost_id]) return;
    setDownloadingIds(prevState => ({ ...prevState, [contractor_cost_id]: true }));
    await downloadFile(
      "/partner/contractor_costs/master_payment/receipt/",
      "Квитанция",
      { contractor_cost_id },
      "pdf"
    );
    setDownloadingIds(prevState => ({
      ...prevState,
      [contractor_cost_id]: false
    }));
  };

  const columns = useMemo(() => {
    if (query.statusCode === "PAYED")
      return [...headItems, { label: "Квитанция", align: "center" }];
    return headItems;
  }, [query.statusCode]);

  return (
    <div>
      <PageTitle title={"Оплаты мастерам"} description={`(${pageTitle[query.statusCode]})`} />
      <div className={styles.content}>
        <CollapseBlock title={"Показать фильтры"} className={styles.header}>
          {isCancelled && <div className={clsx(styles.filters, styles.header)}>
            <AutoSuggest
              list={integratedPaymentTypes}
              showKey={"integrated_payment_type_name"}
              valueKey={"integrated_payment_type_code"}
              value={filters?.integrated_payment_type_code || DEFAULT_WALLET}
              onChange={e =>
                onChange("integrated_payment_type_code", e.target.value)
              } loading={fetchingTypes}
              placeholder="Выберите кошелек"
              label="Кошелек"
              defaultValue={""}
            />
          </div>}
          <MasterPaymentsFilters getData={onFiltersChange} setFiltersHeight={setFiltersHeight} />
        </CollapseBlock>
        <CustomTable
          headItems={columns}
          isFetching={isFetching}
          isEmpty={!payments?.length}
          withMaxHeight stickyHeader maxHeight={window.innerHeight - filtersHeight - 64 - 45}
        >
          {payments?.map(item => {
            const isAmount = item.team_master_fio === "Итого";
            return (
              <CustomTableRow key={item.contractor_cost_id} fixed={isAmount}>
                <CustomTableCell align={"left"}>
                  {
                    isAmount
                      ? <b>{item.team_master_fio}</b>
                      : `${item.team_master_fio}${item.team_master_id ? ` (${item.team_master_id})` : ""}`
                  }
                </CustomTableCell>
                <CustomTableCell align={"left"}>
                  {item.contractor_name ? `${item.contractor_name} (${item.contractor_id})` : ""}
                </CustomTableCell>
                <CustomTableCell align={"right"}>
                  {item.remont_id}
                </CustomTableCell>
                <CustomTableCell align={"right"}>
                  <strong><Amount value={numberWithCommas(item.cost_sum)} /></strong>
                </CustomTableCell>
                <CustomTableCell align={"right"}>
                  <strong><Amount value={numberWithCommas(item.tax_sum)} /> </strong>
                </CustomTableCell>
                <CustomTableCell align={"right"}>
                  <strong><Amount value={numberWithCommas(item.cost_sum_with_tax)} /></strong>
                </CustomTableCell>
                <CustomTableCell align={"center"}>
                  {item.cost_date}
                </CustomTableCell>
                <CustomTableCell align={"center"}>
                  {item.pay_date || ""}
                </CustomTableCell>
                <CustomTableCell align={"left"}>
                  {item.address}
                </CustomTableCell>
                <CustomTableCell align={"left"}>
                  {item.comment}
                </CustomTableCell>
                {query.statusCode === "PAYED" &&
                  <CustomTableCell align={"center"}>
                    {
                      isAmount
                        ? null
                        : <>
                          {downloadingIds[item.contractor_cost_id] ? (
                            <LoaderData small />
                          ) : (
                            <DownloadIcon
                              className={"clickable pointer"}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownload(item.contractor_cost_id);
                              }}
                            />
                          )}
                        </>
                    }
                  </CustomTableCell>}
              </CustomTableRow>
            );
          })}
        </CustomTable>
      </div>
    </div>
  );
};

const headItems = [
  { label: "ФИО", align: "left" },
  { label: "Подрядчик", align: "left" },
  { label: "ID ремонта", align: "center" },
  { label: "Сумма", align: "right" },
  { label: "Комиссия", align: "right" },
  { label: "Сумма с комиссией", align: "right" },
  { label: "Дата создания", align: "center" },
  { label: "Дата оплаты", align: "center" },
  { label: "Адрес", align: "center" },
  { label: "Комментарий", align: "center" }
];

const pageTitle = {
  CANCELLED: "Отменен",
  PAYED: "Оплачен",
  SIGNED: "Подписан"
};
