import React, { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./team_detail.module.scss";
import { TeamDetailMasters } from "./masters";
import { AccountingLayout } from "../../accounting/layout";
import { CustomTabPanel, CustomTabs } from "../../../common/CustomTabs";
import { TeamDebt } from "./debt";
import { BackBtn } from "../../../common/blocks";

export const TeamDetail = ({
                             open,
                             closeDetail,
                             teamData,
                             onTeamChange,
                             team_id
                           }) => {
  const classes = useStyles();
  const [activeTabId, setActiveTabId] = useState(0);

  const handleTabChange = (e, id) => {
    setActiveTabId(id);
  };

  const tabs = [{ label: "Мастера" }, { label: "Расходы" }, { label: "Долги" }];

  return (
    <Drawer
      className={classes.drawer}
      open={open}
      anchor={"right"}
      onClose={closeDetail}
    >
      <div className={styles.team_detail}>
        <BackBtn onClick={closeDetail} padding />
        <div>{teamData?.team_name &&
          <h1 className={classes.title}>{teamData?.team_name}</h1>}</div>
        <CustomTabs
          onChange={handleTabChange}
          value={activeTabId}
          tabs={tabs}
        />
        {open && (
          <div className={styles.team_detail__content}>
            <CustomTabPanel value={activeTabId} index={0}>
              <TeamDetailMasters
                id={team_id}
                team_id={teamData?.team_id}
                onTeamChange={onTeamChange}
              />
            </CustomTabPanel>
            <CustomTabPanel value={activeTabId} index={1}>
              <AccountingLayout
                team_id={teamData?.team_id}
                mainMasterId={teamData?.team_main_master_id}
                type={"cost"}
                alternativeCost
                isPaymentMode
              />
            </CustomTabPanel>
            <CustomTabPanel value={activeTabId} index={2}>
              <TeamDebt teamMode team_id={team_id} />
            </CustomTabPanel>
          </div>
        )}
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "70%",
    "& .MuiDrawer-paper": {
      width: "70%"
    },
    [theme.breakpoints.down(576)]: {
      width: "100%",
      "& .MuiDrawer-paper": {
        width: "100%"
      }
    }
  },
  title: {
    [theme.breakpoints.down(576)]: {
      fontSize: "18px",
      margin: 0
    }
  },
  tab_root: {
    color: "#000",
    fontSize: "14px",
    textTransform: "capitalize"
  }
}));
