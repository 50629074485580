import React, { useEffect, useMemo, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";

import clsx from "clsx";
import { getPenalty } from "./api";
import { PageTitle } from "../../components/common/pageTitle";
import EmptyOrShow from "../../components/common/EmptyOrShow";
import Filters from "./filters";
import TableRowComp from "./tableRowComp";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const headTitle = [
  "ID",
  "ID ремонта",
  "ЖК",
  "КВ",
  "Подрядчик",
  "Тип штрафа",
  "Этап",
  "Сумма",
  "Дата",
  "Исполнитель",
  "Комментарий",
  "Дата принятия",
  "Статус"
];

export const usePenaltyStyles = makeStyles({
  table_container: {
    height: "calc(100vh - 170px)"
  },
  cell: {
    fontSize: 12,
    lineHeight: "16px",
    borderRight: "1px solid #F0F2F6 !important",
    borderBottom: "1px solid #F0F2F6 !important",
    padding: 5
  },
  head_cell: {
    fontWeight: 600,
    color: "#696C7C",
    backgroundColor: "#ECEEFD"
  },
  limit_cell: {
    "& svg": {
      width: 18,
      height: 18
    }
  },
  filtersWrapper: {
    display: "flex",
    alignItems: "flex-end",
    flexWrap: "wrap",
    gap: "20px",
    rowGap: "10px",
    marginBottom: "20px"
  },
  filtersItem: { width: "180px" },
  rightContent: {
    fontSize: "16px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    gap: "5px",
    flex: "1"
  },
  textSum: { fontWeight: 600 }
});

const ReportPenalty = () => {
  document.title = "Отчет по штрафам";
  const classes = usePenaltyStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [penalty, setPenalty] = useState([]);
  const [filters, setFilters] = useState({
    resident_id: null,
    contractor_id: null
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getPenalty(filters)
      .then(res => {
        setLoading(false);
        setPenalty(res.data);
      })
      .catch(e => {
        addCloseSnack(e?.response?.data?.error);
        setLoading(false);
      });
  }, [filters, addCloseSnack]);

  const handleChange = (name, value) => {
    setFilters({ ...filters, [name]: value || null });
  };

  const totalSum = useMemo(
    () =>
      penalty?.reduce((accumulator, object) => {
        return accumulator + object.penalty_amount;
      }, 0),
    [penalty]
  );

  return (
    <>
      <PageTitle title="Отчет по штрафам" />
      <Box sx={{ padding: 20 }}>
        <Filters
          handleChange={handleChange}
          filters={filters}
          loading={loading}
          totalSum={totalSum}
        />
        <EmptyOrShow loading={loading}>
          <TableContainer className={classes.table_container}>
            <Table aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {headTitle.map(el => {
                    return (
                      <TableCell
                        key={el}
                        align="left"
                        className={clsx(classes.cell, classes.head_cell)}
                      >
                        {el}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {penalty?.map(el => {
                  return (
                    <TableRowComp
                      key={el.penalty_id}
                      {...el}
                      setPenalty={setPenalty}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </EmptyOrShow>
      </Box>
    </>
  );
};

export default ReportPenalty;
