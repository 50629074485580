import { Button } from "@material-ui/core";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useTableStyles } from "../styles";
import { showPopUp } from "../../../../services/redux/actions/app-actions";
import { setAccountingAmountTypes } from "../../../../services/redux/actions/accounting-actions";
import { useDispatch } from "react-redux";
import { useHttp } from "../../../../utils/hooks/http.hook";
import { AccountingFilter } from "../filter";
import { CostsTable } from "./CostsTable";
import { numberWithCommas } from "../../../../utils";
import { useCustomSnackbar } from "../../../../utils/hooks/useCustomSnackbar";
import styles from "../payment-types.module.scss";
import { PageTitle } from "../../../common/pageTitle";
import { MODALS_NAMES } from "../../../../constants";
import DownloadXlsx from "../../../common/DownloadXlsx";
import { getBodyWithType, getCostDataService, getMastersDataService, getRemontsDataService } from "./services";
import { CollapseBlock } from "../../../common/CollapseBlock/collapseBlock";

export const AccountingCosts = ({
  alternative = false,
  showFilters = true,
  remont_id = "",
  mainMasterId,
  team_id = "",
  paymentMode = false
}) => {
  if (!alternative) document.title = "Расходы";
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const { request } = useHttp();
  const { addDefaultSnack } = useCustomSnackbar();

  const [cost, setCost] = useState([]);
  const [remonts, setRemonts] = useState(null);
  const [masters, setMasters] = useState(null);
  const [amount, setAmount] = useState(0);
  const [filters, setFilters] = useState({
    date_of_begin: null,
    date_of_end: null,
    team_master_id: null,
    team_id: team_id || null,
    contractor_cost_type_id: null,
    remont_id: remont_id || null
  });
  const [isFetching, setIsFetching] = useState(false);
  const isRemontMode = !!remont_id;
  const isTeamMode = !!team_id;

  useEffect(() => {
    setFilters(prev => ({ ...prev, team_id, remont_id }));
  }, [team_id, remont_id]);

  const getAmountSum = useCallback(
    arr => {
      try {
        const amount =
          arr?.reduce((prev, item) => prev + item?.cost_sum, 0) || 0;
        dispatch(setAccountingAmountTypes({ cost: amount }));
        setAmount(amount ? Number(amount.toFixed(2)) : amount);
      } catch (e) {}
    },
    [dispatch]
  );

  const getCostData = useCallback(
    async (filtersProp) => {
      const filters = filtersProp || { remont_id, team_id };
      const res = await getCostDataService(team_id, filters, setIsFetching);
      getAmountSum(res);
      setCost(res);
    },
    [team_id, getAmountSum, remont_id]
  );

  useEffect(() => {
    getCostData();
  }, [getCostData]);

  useEffect(() => {
    const getRemontsData = async () => {
      const res = await getRemontsDataService(filters.team_id);
      setRemonts(res);
    };
    getRemontsData();
  }, [filters.team_id]);

  useEffect(() => {
    const getMastersData = async () => {
      const res = await getMastersDataService(filters.team_id);
      setMasters(res);
    };
    !team_id && getMastersData();
  }, [filters.team_id, team_id]);

  const onCostDebtInfoSubmit = async data => {
    const body = getBodyWithType(data, isRemontMode, isTeamMode);
    const res = await request(
      "/partner/contractor_costs/",
      "post",
      body,
      {},
      { isModal: true, params: filters }
    );
    if (res?.status) {
      addDefaultSnack("Расход успешно создан");
      getAmountSum(res?.contractor_costs);
      setCost(res?.contractor_costs);
      dispatch(showPopUp(false));
    }
  };

  const onCostSubmit = async (data, backData = {}) => {
    const body = getBodyWithType(data);
    const res = await request(
      "/partner/contractor_costs/debt-info/",
      "post",
      body,
      {},
      { isModal: true, params: filters }
    );
    if (res?.status) {
      if (res?.with_debts) {
        dispatch(
          showPopUp(true, MODALS_NAMES.addCostDebtInfo, {
            onSubmit: onCostDebtInfoSubmit,
            backData: { ...backData, body },
            close: true,
            ...res
          })
        );
      } else {
        addDefaultSnack("Расход успешно создан");
        getAmountSum(res?.contractor_costs);
        setCost(res?.contractor_costs);
        dispatch(showPopUp(false));
      }
    }
  };

  const onCostAddClick = () => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addCost, {
        onSubmit: onCostSubmit,
        type: "cost",
        team_id,
        main_master_id: mainMasterId,
        remont_id
      })
    );
  };

  const onCostSubmitUpdate = async data => {
    const body = getBodyWithType(data);
    const res = await request(
      `/partner/contractor_costs/${data?.contractor_cost_id}/update/`,
      "put",
      body,
      {},
      { isModal: true, params: filters }
    );
    if (res?.status) {
      addDefaultSnack("Расход успешно изменен");
      getAmountSum(res?.contractor_costs);
      setCost(res?.contractor_costs);
      dispatch(showPopUp(false));
    }
  };

  const onEditCostClick = body => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addCost, {
        onSubmit: onCostSubmitUpdate,
        isEdit: true,
        team_id,
        remont_id,
        body
      })
    );
  };

  const onCloneCostClick = body => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addCost, {
        team_id,
        remont_id,
        body,
        onSubmit: onCostSubmit,
        type: "cost"
      })
    );
  };

  const handleDeleteCost = async costId => {
    const res = await request(
      `/partner/contractor_costs/${costId}/delete/`,
      "delete",
      { params: { team_id, remont_id, ...filters } },
      {},
      { isModal: true }
    );
    if (res?.status) {
      addDefaultSnack("Расход успешно удален");
      getAmountSum(res?.contractor_costs);
      setCost(res?.contractor_costs);
      dispatch(showPopUp(false));
    }
  };

  const onDeleteCostClick = id => {
    dispatch(
      showPopUp(true, MODALS_NAMES.confirmModal, {
        onSubmit: handleDeleteCost,
        id
      })
    );
  };

  const onFilterChange = async options => {
    const filters = {};
    setCost([]);
    for (let key in options) {
      if (!!options[key] && options[key] !== "''") {
        filters[key] = options[key];
      }
    }
    setFilters(filters);
    return await getCostData(filters);
  };

  const showHeader = alternative || isRemontMode;
  const title = `Расходы (${numberWithCommas(amount)})`;
  return (
    <>
      {!alternative && <PageTitle title={title} />}
      <div
        className={`${styles.accounting} ${
          alternative ? styles.accounting__page : ""
        }`}
      >
        {showHeader && (
          <div className={styles.accounting__header}>
            {alternative && <h2>{title}</h2>}
          </div>
        )}
        <div className={styles.accounting__content}>
          <CollapseBlock className={styles.accounting__header}>
              <div className={styles.accounting__btns}>
                <Button
                  className={clsx(classes.btn)}
                  variant="outlined"
                  onClick={onCostAddClick}
                >
                  Добавить
                </Button>
                <DownloadXlsx
                  url="/partner/contractor_costs/excel/"
                  params={filters}
                  withoutMargin
                  title="Отчет расходов"
                  reqType="get"
                />
              </div>
              {showFilters && (
                <AccountingFilter
                  type={"cost"}
                  remonts={remonts}
                  masters={masters}
                  showTeamFilter={!team_id}
                  defaultFilters={filters}
                  onFilterChange={onFilterChange}
                />
              )}
          </CollapseBlock>
          <CostsTable
            data={cost}
            getData={getCostData}
            isRemontMode={isRemontMode}
            isTeamMode={isTeamMode}
            paymentMode={paymentMode}
            isFetching={isFetching}
            onDeleteCostClick={onDeleteCostClick}
            onCloneCostClick={onCloneCostClick}
            onEditCostClick={onEditCostClick}
          />
        </div>
      </div>
    </>
  );
};
