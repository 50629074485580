import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { Link, useLocation } from "react-router-dom";
import { PAGES } from "../../../constants";
import IconButton from "@material-ui/core/IconButton";
import SidebarUserbox from "../sidebarUserBox";
import List from "@material-ui/core/List";
import React, { Fragment, useEffect, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "./MenuItem";
import Drawer from "@material-ui/core/Drawer";
import { useTheme } from "@material-ui/core/styles";
import { resetResident } from "../../../services/redux/actions/remonts-actions";
import { useDispatch, useSelector } from "react-redux";
import { Chart, Chat, Document, Home, Paper, Search, Setting, Wallet } from "react-iconly";
import { useStyles } from "../index";
import EmptyOrShow from "../../common/EmptyOrShow";
import { WrapperScrollbar } from "../../common/WrapperScrollbar";
import { Logo } from "../../common/Logo";
import { getMenu, toggleMenuState } from "../../../services/redux/actions/app-actions";
import { Badge } from "@material-ui/core";

export const Menu = () => {
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyles({ isReactNative: !!window.ReactNativeWebView });
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const { isMainMenuOpen: open, menu } = useSelector(state => state.appState);

  useEffect(() => {
    dispatch(getMenu(setIsFetching));
  }, [dispatch]);

  const handleClick = () => {
    dispatch(resetResident());
    if (window.innerWidth <= 768 || window?.ReactNativeWebView)
      dispatch(toggleMenuState());
  };

  const toggleMenu = () => {
    if ((window?.innerWidth <= 768 || window?.ReactNativeWebView) && location.pathname === "/") return;
    dispatch(toggleMenuState());
  };
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={toggleMenu}
      variant={window.innerWidth <= 768 ? undefined : "permanent"}
      className={clsx(classes.drawer, "menu-layout", {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open
      })}
      classes={{
        paper: clsx(classes.paper, "menu-layout", {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })
      }}
    >
      <div
        className={clsx(classes.toolbar, {
          [classes.toolbarOpen]: open,
          [classes.toolbarClose]: !open
        })}
      >
        <Link to={"/"}>
          <Logo size={"small"} alt="logo_path" />
        </Link>
        <IconButton onClick={toggleMenu} className={classes.menuButton}>
          {theme.direction === "rtl" ? null : (
            <Box>
              <BurgerIcon />
            </Box>
          )}
        </IconButton>
      </div>
      <div
        className={clsx(classes.user, {
          [classes.hide]: !open
        })}
      >
        <SidebarUserbox />
      </div>
      <EmptyOrShow loading={isFetching}>
        <WrapperScrollbar className={classes.listWrapper}>
          <List className={classes.list}>
            {menu?.map(
              ({ menu_action, icon, menu_id, menu_name, sub_menus, count }) => {
                return (
                  <Fragment key={menu_id}>
                    {!!sub_menus?.length ? (
                      <MenuItem
                        icon={icon}
                        sub_menus={sub_menus}
                        menu_name={menu_name}
                        open={open}
                        count={count}
                        handleClick={handleClick}
                        setOpen={toggleMenu}
                        getIcon={getIcon}
                      />
                    ) : (
                      <Link
                        to={PAGES[menu_action] || "/"}
                        menu_action={menu_action ? PAGES[menu_action] : "/"}
                        className={clsx(classes.listItem, {
                          [classes.active]:
                          location?.pathname === PAGES[menu_action]
                        })}
                        onClick={handleClick}
                      >
                        <MenuListItem open={open}>
                          <ListItemIcon
                            className={clsx({
                              [classes.mr]: open,
                              [classes.ml]: !open,
                              [classes.itemIconCollapsed]: !open,
                              [classes.active]:
                              location?.pathname === PAGES[menu_action]
                            })}
                          >
                            {
                              count
                                ? <Badge overlap={"rectangular"} color="secondary" variant="dot" badgeContent={count}>
                                  {getIcon(icon)}
                                </Badge>
                                : getIcon(icon)
                            }
                          </ListItemIcon>
                          {open && <ListItemText primary={menu_name} />}
                        </MenuListItem>
                      </Link>
                    )}
                  </Fragment>
                );
              }
            )}
          </List>
        </WrapperScrollbar>
      </EmptyOrShow>
    </Drawer>
  );
};

export const MenuListItem = ({ open, children, style = {}, ...other }) => {
  return (
    <ListItem
      style={{
        paddingLeft: open ? "10px" : 0,
        paddingRight: open ? "10px" : 0,
        justifyContent: open ? "flex-start" : "center",
        ...style
      }}
      {...other}
    >
      {children}
    </ListItem>
  );
};

const getIcon = icon => {
  switch (icon) {
    case "Home":
      return <Home set="light" />;
    case "Wallet":
      return <Wallet set="light" />;
    case "Chart":
      return <Chart set="light" />;
    case "Document":
      return <Document set="light" />;
    case "Chat":
      return <Chat set="light" />;
    case "Paper":
      return <Paper set="light" />;
    case "Search":
      return <Search set="light" />;
    case "Setting":
      return <Setting set="light" />;
    default:
      return null;
  }
};

export const BurgerIcon = props => (
  <svg
    width="32"
    height="16"
    viewBox="0 0 32 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="6.83862" width="24.1936" height="2" rx="1" fill="#162541" />
    <rect
      x="0.0644531"
      y="7"
      width="30.9677"
      height="2"
      rx="1"
      fill="#162541"
    />
    <rect x="6.83862" y="14" width="24.1936" height="2" rx="1" fill="#162541" />
  </svg>
);
