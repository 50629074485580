import instance from "../../../services/api";

export const fetchMessages = remont_id => {
  return instance(true)
    .get(`/partner/internal_chat/remont/${remont_id}/`)
    .then((res = {}) => res?.data);
};

export const sendMessage = (remont_id, params, options) => {
  return instance(true).post(
    `/partner/internal_chat/remont/${remont_id}/`,
    params,
    options
  );
};
