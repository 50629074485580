import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  search: {
    "& .MuiInputBase-root": {
      height: 35,
      borderRadius: 4
    },
    "& .MuiInputBase-input": {
      padding: "9px 10px 9px 0"
    }
  },
  icon: {
    color: "#c4c4c4"
  },
  input: {
    maxWidth: 300,
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 10
    }
  }
});

export const CustomSearch = ({
  value,
  handleChange,
  placeholder = "",
  name = "search",
  className = "",
  searchRef
}) => {
  const classes = useStyles();
  return (
    <TextField
      ref={searchRef}
      className={clsx(classes.search, className)}
      id="outlined-search"
      fullWidth
      type="search"
      autoFocus
      name={name}
      variant="outlined"
      placeholder={placeholder || "Быстрый поиск"}
      value={value}
      onChange={handleChange}
      classes={{ root: classes.input }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon className={classes.icon} />
          </InputAdornment>
        )
      }}
    />
  );
};
