import React from "react";
import SwiperCore, { Keyboard, Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { showPopUp } from "../../../../services/redux/actions/app-actions";
import { MAIN_URL } from "../../../../constants";

SwiperCore.use([Keyboard, Navigation]);

export const GalleryModal = ({data}) => {
  const dispatch = useDispatch()
  const {images, activeIndex, photoUrlKey} = data
  if (images.length >= 1) {
    return (
      <div className={styles.image_modal}>
        <div className={styles.image_modal__actions}>
          {data.hideClose || (
            <IconButton
              className="_close"
              onClick={() => dispatch(showPopUp(false))}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <Swiper
          navigation={{
            nextEl: ".img-swiper-next",
            prevEl: ".img-swiper-prev",
          }}
          keyboard={true}
          initialSlide={activeIndex}
          loop={images.length > 1}
        >
          {images.map((i, index) => (
            <SwiperSlide key={index}>
              <div className={styles.image_modal__item}>
                <img alt="" src={MAIN_URL + i[photoUrlKey || 'img_url']}/>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={clsx("swiper_navs", styles.image_modal__navs)}
        >
          <button className="img-swiper-prev">
            <KeyboardBackspaceIcon />
          </button>
          <button className="img-swiper-next">
            <KeyboardBackspaceIcon
              className={styles.image_modal__navsNext} />
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="image_modal">
    </div>
  );
};

