import { useCallback, useState } from "react";
import instance from "../../services/api";
import { useDispatch } from "react-redux";
import { setModalLoading } from "../../services/redux/actions/app-actions";

export const useHttp = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const request = useCallback(
    async (url, method = "get", body = {}, headers = {}, optionsProps = {}) => {
      const options = {
        isTokenRequired: true,
        throwError: false,
        showToast: true,
        isModal: false,
        isLoadingRequired: true,
        params: {},
        ...optionsProps
      };
      options?.isLoadingRequired !== false &&
        !options?.isModal &&
        setLoading(true);
      options?.isModal &&
        options?.isLoadingRequired &&
        dispatch(setModalLoading(true));
      try {
        const data = await instance(options?.isTokenRequired, null, {
          throwError: options?.throwError,
          showToast: options?.showToast
        })[method](url, body, { headers, params: options.params });
        if (!data || data?.status === false) {
          new Error(data?.message || "Что-то пошло не так");
        }
        setLoading(false);
        dispatch(setModalLoading(false));
        if (
          !data?.data &&
          (data.status === 200 || data.status === 201) &&
          isNaN(data?.data)
        ) {
          return {
            status: true
          };
        }
        return data?.data;
      } catch (e) {
        setLoading(false);
        dispatch(setModalLoading(false));
        const message = e?.response?.data?.error || e?.response?.data?.message;
        if (options?.throwError)
          throw new Error(message || "Что-то пошло не так");
      }
    },
    [dispatch]
  );

  return { loading, request };
};
