import { worksetsAPI } from "./api";

export const getWorksetsData = async (remont_id, setIsFetching) => {
  try {
    setIsFetching(true);
    const res = await worksetsAPI.getRemontWorksets(remont_id);
    setIsFetching(false);
    return res;
  } catch (e) {
    setIsFetching(false);
  }
};
export const getWorksetsActions = async () => {
  try {
    const res = await worksetsAPI.getRemontWorksetsActions();
    return res?.call_types;
  } catch (e) {}
};
export const updateWorksetList = async remont_id => {
  try {
    return await worksetsAPI.updateList(remont_id);
  } catch (e) {}
};
export const callWorksetAction = async (remont_id, body) => {
  try {
    return await worksetsAPI.callAction(remont_id, body);
  } catch (e) {}
};

export const completeWorkset = async (
  remont_id,
  body,
  setIsFetching = () => {}
) => {
  setIsFetching(true);
  try {
    const res = await worksetsAPI.completeWorkset(remont_id, body);
    setIsFetching(false);
    return res;
  } catch (e) {
    setIsFetching(false);
  }
};
export const masterCompleteWorkset = async (
  remont_id,
  body,
  setIsFetching = () => {}
) => {
  setIsFetching(true);
  try {
    const res = await worksetsAPI.masterCompleteWorkset(remont_id, body);
    setIsFetching(false);
    return res;
  } catch (e) {
    setIsFetching(false);
  }
};
export const callMasterToWorkset = async (remont_id, body, setLoading) => {
  try {
    setLoading(true);
    const res = await worksetsAPI.callMaster(remont_id, body);
    setLoading(false);
    if (!res) return;
    const { remont_work_sets, num } = res;
    return { remont_work_sets, num };
  } catch (e) {
    setLoading(false);
  }
};
