import React from "react";
import { PageTitle } from "../../components/common/pageTitle";
import Statuses from "../remonts/Statuses";
import { Box } from "@material-ui/core";
import { CheckRemontPermission } from "../remonts/CheckRemontPermission";

export const RemontStatuses = () => {
  return (
    <CheckRemontPermission>
      <PageTitle title="Статусы" />
      <Box style={{ padding: 20 }}>
        <Statuses singleRemont />
      </Box>
    </CheckRemontPermission>
  );
};
