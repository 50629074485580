import React from "react";
import CollapseHeader from "../CollapseHeader";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";

const MainRemontInfo = ({ data = {}, isOwnPage = false }) => {
  const { resident } = useSelector(state => state.remontsState);
  if (!Object.keys(data).length) {
    return null;
  }
  return (
    <Box
      display={"flex"}
      style={{
        borderTop: isOwnPage ? "1px solid #D2D3D9" : "none",
        borderBottom: "1px solid #D2D3D9",
        padding: "10px 20px",
        backgroundColor: "#fff"
      }}
      mb={isOwnPage ? 0 : 2}
    >
      <Box display="flex" flexWrap="wrap">
        <CollapseHeader resident={resident} {...data} />
      </Box>
    </Box>
  );
};

export default MainRemontInfo;
