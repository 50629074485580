import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import NumberFormat from "react-number-format";
import { plannedSumDetails } from "./api";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 5,
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)",
    borderRadius: 4
  },
  row_td: {
    borderRight: "1px solid #F0F2F6"
  },
  cell: {
    borderLeft: "1px solid #F0F2F6",
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 120,
    padding: 5
  },
  cell_first: {
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 150,
    padding: 5
  },
  header: {
    verticalAlign: "top",
    fontSize: 20,
    color: "#0C4B86",
    fontWeight: 600,
    padding: 5
  },
  head: {
    borderLeft: "1px solid #F0F2F6",
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 120,
    color: "#A5A7B5",
    padding: 5
  },
  head_first: {
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 150,
    color: "#A5A7B5",
    padding: 5
  }
}));

export default function PlannedSum({ data = {} }) {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [details, setDetails] = useState({});

  useEffect(() => {
    plannedSumDetails(data)
      .then(res => {
        setDetails(res?.data);
      })
      .catch(e => {
        addCloseSnack(e?.response?.data?.error);
      });
  }, [data, addCloseSnack]);

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={4} align="left" className={classes.header}>
              Расшифровка базовой суммы
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.head}>Пакет</TableCell>
            <TableCell className={classes.head} align="left">
              Сумма
            </TableCell>
            <TableCell className={classes.head} align="left">
              Площадь
            </TableCell>
            <TableCell className={classes.head} align="left">
              Общая сумма
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.cell_first} align="center">
              {details?.preset_name}
            </TableCell>
            <TableCell className={classes.cell} align="right">
              <span>
                <NumberFormat
                  value={details?.price}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  title={details?.price}
                />
              </span>
            </TableCell>
            <TableCell className={classes.cell} align="right">
              {details?.area}
            </TableCell>
            <TableCell className={classes.cell} align="right">
              <span>
                <NumberFormat
                  value={details?.price}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  title={details?.price}
                />
              </span>{" "}
              x {details?.area}=
              <span>
                <NumberFormat
                  value={details?.total}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  title={details?.total}
                />
              </span>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
