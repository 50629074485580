import { externalWorksetMastersAPI } from "./api";

export const getRemontAmount = async remont_id => {
  if (!remont_id) return;
  try {
    const res = await externalWorksetMastersAPI.getRemontAmount(remont_id);
    if (!res) return;
    return res?.amounts;
  } catch (e) {}
};
export const getBrigadaInfo = async remont_id => {
  if (!remont_id) return;
  try {
    const res = await externalWorksetMastersAPI.getBrigadaInfo(remont_id);
    if (!res) return;
    return res?.data;
  } catch (e) {}
};
export const getExternalMasters = async (remont_id, setIsFetching) => {
  if (!remont_id) return;
  try {
    setIsFetching(true);
    const res = await externalWorksetMastersAPI.getExternalMasters(remont_id);
    if (!res) return;
    setIsFetching(false);
    return res?.external_masters;
  } catch (e) {
    setIsFetching(false);
  }
};
export const getRemontServiceMasters = async (remont_id, setIsFetching) => {
  if (!remont_id) return;
  try {
    setIsFetching(true);
    const res = await externalWorksetMastersAPI.getRemontServiceMasters(
      remont_id
    );
    if (!res) return;
    setIsFetching(false);
    return res?.remont_masters;
  } catch (e) {
    setIsFetching(false);
  }
};
export const addMasterToWorkset = async (remont_id, body) => {
  if (!remont_id) return;
  try {
    const res = await externalWorksetMastersAPI.addMasterToWorkset(
      remont_id,
      body
    );
    if (!res?.status) return;
    return res?.external_masters;
  } catch (e) {}
};
