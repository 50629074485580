import React from "react";
import { numberWithCommas } from "../../../utils";
import ApartmentIcon from "@material-ui/icons/Apartment";

export const Amount = ({value}) => {
  return (
    <span style={{whiteSpace: "nowrap"}}>
      {numberWithCommas(value)}
    </span>
  );
};

export const ComplexIcon = () => <ApartmentIcon
  style={{
    display: "inline-block",
    marginRight: 16,
    color: "#72768F",
    fontSize: "17px"
  }}
/>
