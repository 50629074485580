import React, { useEffect, useState } from "react";
import { FiltersWrapper } from "../../../common/blocks";
import { AutoSuggest } from "../../../common/form/AutoSuggest";
import { useDispatch, useSelector } from "react-redux";
import { getHeadTeamEmployeesData } from "../../../../services/redux/actions/app-actions";
import { Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "../../../common/form/CustomInput";
import { CButton } from "../../../common/buttons";

export const TeamFilters = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [filters, setFilters] = useState({
    head_team_employee_id: "",
    team_master_fio: "",
    is_active: ""
  });
  const [loading, setLoading] = useState(false);

  const { headTeamEmployees } = useSelector(state => state.appState);

  useEffect(() => {
    dispatch(getHeadTeamEmployeesData());
  }, [dispatch]);

  const onFiltersChange = (name, value) => {
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
  };

  const handleSubmit = async () => {
    setLoading(true);
    onSubmit && (await onSubmit(filters));
    setLoading(false);
  };

  return (
    <FiltersWrapper>
      {headTeamEmployees && (
        <div className={classes.filterItem}>
          <AutoSuggest
            withMargin
            list={headTeamEmployees}
            showKey={"fio"}
            valueKey={"employee_id"}
            placeholder="Выберите старшего бригадира"
            label="Выберите старшего бригадира"
            value={filters?.head_team_employee_id || ""}
            onChange={e =>
              onFiltersChange("head_team_employee_id", e?.target?.value)
            }
          />
        </div>
      )}
      <div className={classes.filterItem}>
        <CustomInput
          type="text"
          fullWidth
          multiline
          label="ФИО мастера"
          placeholder="Введите ФИО"
          value={filters.team_master_fio}
          onChange={e => onFiltersChange("team_master_fio", e?.target?.value)}
        />
      </div>
      <div>
        <label htmlFor={"is_active_check"}>Активный</label>
        <Checkbox
          id={"is_active_check"}
          indeterminate={filters.is_active === false}
          checked={filters.is_active === true}
          onChange={e => {
            const checked = e.target.checked;
            if (filters?.is_active === false && !!checked) {
              return onFiltersChange("is_active", "");
            }
            onFiltersChange("is_active", checked);
          }}
        />
      </div>
      <CButton mode={"auto"} loading={loading} onClick={handleSubmit}>
        Поиск
      </CButton>
    </FiltersWrapper>
  );
};

export const useStyles = makeStyles({
  filterItem: {
    minWidth: 250
  }
});
