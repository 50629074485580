import React from "react";
import { Box, Grid } from "@material-ui/core";
import { PageTitle } from "../components/common/pageTitle";
import { RemontWrapper } from "../components/common/RemontWrapper";
import { RemontWorksets } from "./remonts/Works";
import { CheckRemontPermission } from "./remonts/CheckRemontPermission";

const RemontWorks = () => {
  return (
    <CheckRemontPermission>
      <RemontWrapper>
        <Grid item xs={12}>
          <PageTitle title="Работы" />
          <Box style={{ padding: 20 }}>
            <RemontWorksets singleRemont />
          </Box>
        </Grid>
      </RemontWrapper>
    </CheckRemontPermission>
  );
};

export default RemontWorks;
