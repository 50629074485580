import React, { useEffect, useState } from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { fetchCards } from "./api";
import { useDispatch, useSelector } from "react-redux";
import { setColumns } from "../../../services/redux/actions/problemsActions";
import ProblemContainer from "./ProblemContainer";
import { Link, useParams } from "react-router-dom";
import { CustomLoader } from "../../../components/common/CustomLoader";
import { WrapperScrollbar } from "../../../components/common/WrapperScrollbar";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { PAGES } from "../../../constants";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "relative",
    maxWidth: 415,
    width: "100%",
    border: "1px solid #E2E3ED",
    borderRadius: 4,
    backgroundColor: "#F4F5F7"
  },
  header: {
    padding: "8px 10px",

    borderBottom: "5px solid #C2C4CE",
    borderRadius: 4
  },
  header_title: {
    color: "#72768F",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: "1px"
  },
  inner_content: {
    padding: 10,
    height: "80vh",
    overflowY: "auto"
  },
  gradient: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 151,
    backgroundImage:
      "linear-gradient(180deg, rgba(244, 245, 247, 0) 3.12%, #F4F5F7 42.19%)",
    [theme.breakpoints.down(768)]: {
      width: "100%"
    }
  },
  btn: {
    display: "block",
    margin: "0 auto",
    marginTop: 100,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: "#fff",
    backgroundColor: "#0C4B86",
    borderRadius: 4,
    padding: "7px 23px"
  }
}));

const ProblemsList = () => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const dispatch = useDispatch();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const { columns } = useSelector(state => state.problemsState);

  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    if (remont_id) {
      setIsFetching(true);
      fetchCards({ remont_id })
        .then(res => {
          setIsFetching(false);
          dispatch(setColumns(res));
        })
        .catch(e => {
          addCloseSnack(e?.response?.data?.error);
          setIsFetching(false);
        });
    }
  }, [remont_id, dispatch, addCloseSnack]);

  const problemsCount = columns?.reduce((acc, curr) => {
    acc += curr.card_cnt;
    return acc;
  }, 0);

  return (
    <Box className={classes.wrapper}>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.header}
      >
        <Typography className={classes.header_title}>
          Существующие обращения
        </Typography>
        <Typography className={classes.header_title}>
          {problemsCount}
        </Typography>
      </Box>
      <WrapperScrollbar className={classes.inner_content}>
        {isFetching ? (
          <CustomLoader />
        ) : (
          columns?.map(el => {
            return (
              <ProblemContainer key={el.col_id} {...el} col_id={el.col_id} />
            );
          })
        )}
      </WrapperScrollbar>
      <Box className={classes.gradient}>
        <Link to={PAGES.problems} style={{ textDecoration: "none" }}>
          <Button className={classes.btn}>Перейти в обращения</Button>
        </Link>
      </Box>
    </Box>
  );
};

export default ProblemsList;
