import {
  fetchBik,
  getContractors,
  getHeadTeamEmployees,
  getMasterTypes,
  getTeams
} from "../../../components/features/teams/api";
import { getMasters, getRemontMasters, getRemonts, getTeamMasters } from "../../../components/features/accounting/api";
import cookiesService from "../../cookiesService";
import { handleError } from "../../api/helpers";
import { checkAuth } from "../../api/api_app";
import { COOKIES_FIELDS, PAGES } from "../../../constants";
import { getStatusGroups } from "../../../pages/remonts/services/api";
import { getIntegratedPaymentTypes } from "../../../components/features/accounting/contractors/services";
import { getCities, getCompanies, getMenuList } from "../../../components/layout/api";
import { getUserProfileInfo } from "../../../pages/profile/api";
import { resetAccountingState } from "./accounting-actions";
import { resetProblemsState } from "./problemsActions";
import { resetRemontState } from "./remonts-actions";
import { removeDangerSymbols } from "../../../utils";
import { getMasterPaymentsStatuses } from "../../../pages/mastersPayments/services";

export const SET_INIT = "app:setInit";
export const SET_AUTH = "app:setAuth";
export const SET_HEAD_TEAM_EMPLOYEES = "app:setHeadTeamEmployees";
export const SET_RESIDENTS = "app:setResidents";
export const SET_PAGE_TITLE = "app:setPageTitle";
export const SET_HEADER_BACK = "app:setHeaderBack";
export const SET_BIK_OPTIONS = "app:setBikOptions";
export const SET_ROLES = "app:setRoles";
export const SET_MODAL_LOADING = "app:setModalLoading";
export const SET_STAGES = "app:setStages";
export const SHOW_POPUP = "app:showPopUp";
export const SET_MENU_STATE = "SET_MENU_STATE";
export const SET_MENU = "SET_MENU";
export const SET_HEADER = "app:setHeader";
export const SET_FILTER_FIELDS = "app:setFilterFields";
export const SET_MASTER_TYPES = "app:setMasterTypes";
export const SET_MASTERS = "app:setMasters";
export const SET_COST_MASTERS = "app:setCostMasters";
export const SET_TEAMS = "app:setTeams";
export const SET_CONTRACTORS = "app:setContractors";
export const SET_STATUS_GROUPS = "app:setStatusGroups";
export const SET_INTEGRATED_PAYMENT_TYPES = "app:setIntegratedPaymentTypes";
export const SET_REMONTS = "app:setRemonts";
export const SET_OKK_DATA = "SET_OKK_DATA";
export const SET_MATERIAL_SHIP = "SET_MATERIAL_SHIP";
export const SET_DATA_USER = "SET_DATA_USER";
export const SET_LIMITS = "SET_LIMITS";
export const SET_CITIES = "SET_CITIES";
export const SET_COMPANIES = "SET_COMPANIES";
export const RESET_APP_STATE = "RESET_APP_STATE";
export const SET_MASTER_DOCUMENT_TYPES = "SET_MASTER_DOCUMENT_TYPES";
export const SET_COST_STATUSES = "SET_COST_STATUSES";

export const setInitialize = (init = true) => {
  return {
    type: SET_INIT,
    payload: {
      init
    }
  };
};
export const resetAppState = () => {
  return {
    type: RESET_APP_STATE
  };
};

export const setAuth = (auth = true) => {
  return {
    type: SET_AUTH,
    payload: {
      auth
    }
  };
};
export const setPageTitle = (title = "") => {
  return {
    type: SET_PAGE_TITLE,
    payload: title
  };
};
export const setHeaderBack = (payload = null) => {
  return {
    type: SET_HEADER_BACK,
    payload
  };
};

export const setHeadTeamEmployees = data => {
  return {
    type: SET_HEAD_TEAM_EMPLOYEES,
    payload: data
  };
};
export const setIntegratedPaymentTypes = data => {
  return {
    type: SET_INTEGRATED_PAYMENT_TYPES,
    payload: data
  };
};

export const setRoles = roles => {
  return {
    type: SET_ROLES,
    payload: {
      roles
    }
  };
};
export const setCities = payload => {
  return {
    type: SET_CITIES,
    payload
  };
};
export const setCompanies = payload => {
  return {
    type: SET_COMPANIES,
    payload
  };
};

export const setResidents = residents => {
  return {
    type: SET_RESIDENTS,
    payload: {
      residents
    }
  };
};

export const setBikOptions = payload => {
  return {
    type: SET_BIK_OPTIONS,
    payload
  };
};
export const setCostStatuses = payload => {
  return {
    type: SET_COST_STATUSES,
    payload
  };
};

export const setStages = payload => {
  return {
    type: SET_STAGES,
    payload
  };
};

export const setModalLoading = loading => {
  return {
    type: SET_MODAL_LOADING,
    payload: loading
  };
};

export const showPopUp = (show = false, type = "", data = {}, ...props) => {
  return {
    type: SHOW_POPUP,
    payload: {
      show,
      type,
      data,
      ...props
    }
  };
};

export const setMasterTypes = (data = {}) => {
  return {
    type: SET_MASTER_TYPES,
    payload: data
  };
};

export const setTeams = (data = []) => {
  return {
    type: SET_TEAMS,
    payload: data
  };
};

export const setContractors = (data = []) => {
  return {
    type: SET_CONTRACTORS,
    payload: data
  };
};

export const setStatusGroups = (data = []) => {
  return {
    type: SET_STATUS_GROUPS,
    payload: data
  };
};

export const setMasterDocuments = (data = []) => {
  return {
    type: SET_MASTER_DOCUMENT_TYPES,
    payload: data
  };
};

export const setMasters = (data = {}) => {
  return {
    type: SET_MASTERS,
    payload: data
  };
};
export const setCostMasters = (data = {}) => {
  return {
    type: SET_COST_MASTERS,
    payload: data
  };
};

export const setRemonts = (data = {}) => {
  return {
    type: SET_REMONTS,
    payload: data
  };
};

export const toggleMenuState = () => {
  return {
    type: SET_MENU_STATE
  };
};

export const setMenu = payload => {
  return {
    type: SET_MENU,
    payload
  };
};

export const setHeader = payload => {
  return {
    type: SET_HEADER,
    payload
  };
};

export const setFilterFields = payload => {
  return {
    type: SET_FILTER_FIELDS,
    payload
  };
};

export const setOkkData = payload => {
  return {
    type: SET_OKK_DATA,
    payload
  };
};

export const setMaterialShipData = payload => {
  return {
    type: SET_MATERIAL_SHIP,
    payload
  };
};

export const setDataUser = payload => {
  return {
    type: SET_DATA_USER,
    payload
  };
};
export const setLimits = payload => {
  return {
    type: SET_LIMITS,
    payload
  };
};

export const initialize = redirect => async dispatch => {
  const cookiesServiceInstance = cookiesService.getService();

  let isQueryCredentialsExist = false;
  let accessToken;
  let refreshToken;
  if (window?.location?.pathname === PAGES.main) {
    const query = new URLSearchParams(window.location.search);
    accessToken = query.get(COOKIES_FIELDS.accessToken);
    refreshToken = query.get(COOKIES_FIELDS.refreshToken);

    try {
      accessToken = removeDangerSymbols(accessToken);
      refreshToken = removeDangerSymbols(refreshToken);
    } catch (e) {
    }
    if (accessToken && refreshToken) {
      isQueryCredentialsExist = true;
    }
  }
  try {
    if (!cookiesServiceInstance.isCredentialsExist()) {
      if (isQueryCredentialsExist) {
        cookiesServiceInstance.setLoginData({ token: { access: accessToken, refresh: refreshToken }, user: {} });
      } else {
        if (window?.location?.pathname !== PAGES.register && window?.location?.pathname !== PAGES.forgetPassword)
          await redirect(PAGES.login);
        return dispatch(setInitialize());
      }
    }
    await checkAuth();
    dispatch(setAuth(true));
  } catch (e) {
    if (e?.response?.status === 401) {
      return await handleError(e);
    } else {
      await redirect(PAGES.login);
      cookiesServiceInstance.clearToken();
    }
  }
  dispatch(setInitialize());
};

export const getBikOptions = () => async (dispatch, getState) => {
  const { bikOptions } = getState().appState;
  if (bikOptions) return;
  return fetchBik()
    .then(res => dispatch(setBikOptions(res)))
    .catch(() => {
    });
};

export const getTeamsData = () => async dispatch => {
  try {
    const res = await getTeams();
    if (res) dispatch(setTeams(res));
  } catch (e) {
  }
};
export const getMasterTypesData = () => async (dispatch, getState) => {
  try {
    const { master_types } = getState().appState;
    if (!!master_types?.length) return;
    const masterTypes = await getMasterTypes();
    masterTypes && dispatch(setMasterTypes(masterTypes));
  } catch (e) {
  }
};
export const getIntegratedPaymentTypesData = (
  setIsFetching = () => {
  }
) => async (dispatch, getState) => {
  try {
    const { integratedPaymentTypes } = getState().appState;
    if (!!integratedPaymentTypes?.length) return;
    setIsFetching(true);
    const types = await getIntegratedPaymentTypes();
    setIsFetching(false);
    types && dispatch(setIntegratedPaymentTypes(types));
  } catch (e) {
  }
};

export const getHeadTeamEmployeesData = () => async dispatch => {
  try {
    const res = await getHeadTeamEmployees();
    if (res) dispatch(setHeadTeamEmployees(res));
  } catch (e) {
  }
};

export const getRemontsData = () => async dispatch => {
  try {
    const res = await getRemonts();
    if (res) dispatch(setRemonts(res));
  } catch (e) {
    dispatch(setRemonts([]));
  }
};

export const getMastersData = () => async (dispatch, getState) => {
  try {
    const { masters } = getState().appState;
    if (masters?.length) return;
    const res = await getMasters();
    if (res) dispatch(setMasters(res));
  } catch (e) {
    dispatch(setMasters([]));
  }
};
export const getCostMastersData = (team_id, remont_id) => async dispatch => {
  try {
    const res = team_id
      ? await getTeamMasters(team_id)
      : remont_id
        ? await getRemontMasters(remont_id)
        : await getMasters();
    if (res) dispatch(setCostMasters(res));
  } catch (e) {
    dispatch(setCostMasters([]));
  }
};

export const getContractorsData = () => async (dispatch, getState) => {
  try {
    const { contractors } = getState().appState;
    if (contractors?.length) return;
    const res = await getContractors();
    if (res) dispatch(setContractors(res));
  } catch (e) {
    dispatch(setContractors([]));
  }
};
export const getCostStatuses = () => async (dispatch, getState) => {
  try {
    const { costStatuses } = getState().appState;
    if (costStatuses?.length) return;
    const res = await getMasterPaymentsStatuses();
    if (res) dispatch(setCostStatuses(res));
  } catch (e) {
    dispatch(setCostStatuses([]));
  }
};
export const getStatusGroupsData = () => async dispatch => {
  try {
    const res = await getStatusGroups();
    if (res) dispatch(setStatusGroups(res));
  } catch (e) {
    dispatch(setStatusGroups([]));
  }
};
export const getCitiesData = () => async (dispatch, getState) => {
  try {
    const { cities } = getState().appState;
    if (cities?.length) return;
    const res = await getCities();
    if (res) dispatch(setCities(res));
  } catch (e) {
    dispatch(setCities([]));
  }
};
export const getCompaniesData = () => async (dispatch, getState) => {
  try {
    const { companies } = getState().appState;
    if (companies?.length) return;
    const res = await getCompanies();
    if (res) dispatch(setCompanies(res?.data));
  } catch (e) {
    dispatch(setCompanies([]));
  }
};
export const getMenu = setIsFetching => async (dispatch, getState) => {
  const { menu } = getState().appState;
  if (menu?.length) return;
  setIsFetching(true);
  getMenuList()
    .then(res => {
      setIsFetching(false);
      if (res && res?.length === 1 && !!res[0]) {
        dispatch(setMenu(res[0]?.sub_menus));
      } else if (res) {
        dispatch(setMenu(res));
      }
    })
    .catch(() => {
      setIsFetching(false);
    });
};
export const getUserData = setLoading => async (dispatch, getState) => {
  const { dataUser } = getState().appState;
  if (dataUser) return;

  setLoading(true);
  getUserProfileInfo()
    .then(data => {
      dispatch(setDataUser(data));
      setLoading(false);
    })
    .catch(() => {
      setLoading(false);
    });
};
export const resetReduxState = () => async dispatch => {
  dispatch(resetAppState());
  dispatch(resetAccountingState());
  dispatch(resetProblemsState());
  dispatch(resetRemontState());
};
