import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getBrigadaInfo } from "./services";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../components/common/CustomTable/CustomTable";
import { numberWithCommas } from "../../../utils";

export const BrigadaInfo = ({amount}) => {
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [isFetching, setIsFetching] = useState(false);
  const [info, setInfo] = useState(null);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const res = await getBrigadaInfo(remont_id);
      setIsFetching(false);
      if (res) setInfo(res);
    };
    getData();
  }, [remont_id]);

  return (
    <CustomTable headItems={headItems} isFetching={isFetching} isEmpty={!info}>
      <CustomTableRow>
        <CustomTableCell>{info?.preset_kit_name}</CustomTableCell>
        <CustomTableCell>{info?.team_name}</CustomTableCell>
        <CustomTableCell>{info?.team_master_fio}</CustomTableCell>
        <CustomTableCell align={'right'}>{amount?.payed_amount !== undefined && `${numberWithCommas(amount?.payed_amount)} ${amount?.payed_precent ? `(${amount?.payed_precent} %)` : ''}`}</CustomTableCell>
        <CustomTableCell align={'right'}>{amount?.remaining_amount !== undefined && `${numberWithCommas(amount?.remaining_amount)} ${amount?.remaining_percent ? `(${amount?.remaining_percent} %)` : ''}`}</CustomTableCell>
        <CustomTableCell align={"right"}>
          {numberWithCommas(info?.team_amount)}
        </CustomTableCell>
      </CustomTableRow>
    </CustomTable>
  );
};

const headItems = [
  { label: "Пакет", align: "left" },
  { label: "Бригада", align: "left" },
  { label: "Бригадир", align: "left" },
  { label: "Оплачено", align: "right" },
  { label: "Остаток", align: "right" },
  { label: "Сумма", align: "right" }
];
