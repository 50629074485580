import React from "react";
import styles from "./WrapperScrollbar.module.scss";

export const WrapperScrollbar = React.forwardRef(
  ({ className, children, type, id, style, ...other }, ref) => {
    const classNames = [
      styles.wrapper,
      type ? styles[`wrapper_type_${type}`] : "",
      className
    ].join(" ");

    return (
      <div
        style={style}
        id={id || ""}
        className={classNames}
        {...other}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);
