import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { setPageTitle } from "../../../services/redux/actions/app-actions";

export const PageTitle = ({ title, num, children, description = "", className = "" }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageTitle(children || `${title}${num === undefined ? '' : ` - ${num}`}${description ? ` ${description}` : ''}`));
    return () => {
      dispatch(setPageTitle(null));
    };
  }, [dispatch, title, num, children, description]);

  return (
    <Box className={clsx(classes.pageTitle, className)}>
      {title && (
        <div className={classes.pageTitleWrapper}>
          <Typography className={classes.pageTitleText}>
            {title} {num ? ` - ${num}` : null}{" "}
          </Typography>
          {description && <Typography className={classes.pageTitleDescription}>{description}</Typography>}
        </div>
      )}
      {children}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  pageTitle: {
    background: "#FFFFFF",
    boxShadow: " 0px 5px 20px rgba(108, 108, 108, 0.15)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "18px 0",
    height: "64px",
    [theme.breakpoints.down(768)]: {
      display: "none",
      height: "50px"
    }
  },
  pageTitleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 5
  },
  pageTitleText: {
    fontWeight: "500",
    fontSize: "20px",
    color: "#494C62",
    marginLeft: "25px",
    lineHeight: "22px",
    [theme.breakpoints.down(576)]: {
      marginLeft: 15,
      fontSize: "18px"
    }
  },
  pageTitleDescription: {
    fontWeight: "400",
    fontSize: "17px",
    color: "#494C62",
    lineHeight: "20px",
    [theme.breakpoints.down(576)]: {
      fontSize: "18px"
    }
  }
}));
