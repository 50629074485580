import {
  getIntegratedPaymentBalanceAPI,
  getIntegratedPaymentTypesAPI
} from "../../teams/api";

export const getIntegratedPaymentTypes = async () => {
  try {
    const res = await getIntegratedPaymentTypesAPI();
    return res?.data;
  } catch (e) {}
};
export const getIntegratedPaymentBalance = async code => {
  try {
    const res = await getIntegratedPaymentBalanceAPI(code);
    return res?.data;
  } catch (e) {}
};
