import instance from "../../../../services/api";

export const getRequestReasons = async () => {
  return await instance(true)
    .get("partner/request_additional/reasons/")
    .then(res => res?.data);
};

export const getMaterialTypes = async () => {
  return await instance(true)
    .get("partner/request_additional/material_types/")
    .then(res => res?.data);
};

export const getMaterials = async body => {
  return await instance(true)
    .post("partner/request_additional/get_data/", body)
    .then(res => res?.data);
};

export const getConvertedValue = async body => {
  return await instance(true)
    .post("partner/request_additional/change_qty/", body)
    .then(res => res?.data);
};
