import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setFilterFields,
  setHeader
} from "../../services/redux/actions/app-actions";
import { useNavigate } from "react-router";
import { getJKList } from "./api";
import { makeStyles } from "@material-ui/core/styles";
import DownloadXlsx from "../../components/common/DownloadXlsx";
import { FILTER_FIELDS, FILTER_SHOW_KEYS, PAGES } from "../../constants";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles(theme => ({
  select: {
    width: 200,
    float: "left",
    marginRight: 10
  },
  button: {
    backgroundColor: "#0C4B86",
    width: 130,
    height: 30,
    color: "#ffffff",
    marginTop: 25,
    borderRadius: 4,
    [theme.breakpoints.down(600)]: {
      marginLeft: 0
    }
  },
  icon_btn: {
    backgroundColor: "#0C4B86",
    width: 45,
    height: 30,
    color: "#ffffff",
    marginTop: 25,
    borderRadius: 4
  },
  filter_form: {
    [theme.breakpoints.down(600)]: {
      display: "flex",
      flexDirection: "column",
      paddingBottom: "10px !important"
    },
  }
}));

const remontFilter = [
  {
    id: "1",
    name: "Все ремонты",
    value: 0
  },
  {
    id: "2",
    name: "С опозданием",
    value: 1
  },
  {
    id: "3",
    name: "С опрережением",
    value: 2
  }
];
const stageFilter = [
  {
    id: "3",
    stage_name: "На 1 этапе",
    value: 1
  },
  {
    id: "4",
    stage_name: "На 2 этапе",
    value: 2
  },
  {
    id: "5",
    stage_name: "На 3 этапе",
    value: 3
  },
  {
    id: "6",
    stage_name: "На 4 этапе",
    value: 4
  }
];
const defectFilter = [
  {
    id: "1",
    defect_name: "Все",
    value: 0
  },
  {
    id: "2",
    defect_name: "С замечаниями",
    value: 1
  },
  {
    id: "3",
    defect_name: "Без замечаний",
    value: 2
  }
];

const { showRemonts, showResidents, showDefect, showStage } = FILTER_SHOW_KEYS;
const { residentTxt, remontTxt, stageTxt, defextTxt } = FILTER_FIELDS;
const emptyFilterFields = {
  [residentTxt]: null,
  [remontTxt]: 0,
  [stageTxt]: 0,
  [defextTxt]: 0
};

const ReportOkkStageFilter = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { addCloseSnack } = useCustomSnackbar();
  const { resident, remont, stage, defect } = useSelector(
    state => state.appState.filterFields
  );

  const classes = useStyles();
  const initialValues = {
    [residentTxt]: resident || "",
    [remontTxt]: remont || "",
    [stageTxt]: stage || "",
    [defextTxt]: defect || ""
  };
  const [requestedResidents, setRequestedResidents] = useState([]);

  const [selectedFilterFields, setSelectedFilterFields] = useState(
    initialValues
  );

  const clearFilterFields = useCallback(() => {
    dispatch(setFilterFields({ ...emptyFilterFields }));
    setSelectedFilterFields({ ...emptyFilterFields });
  }, [dispatch]);

  const selectChangeHandler = (name = "", value = "") => {
    setSelectedFilterFields(prev => ({ ...prev, [name]: value }));
  };

  const handleAcceptFilterFields = useCallback(() => {
    dispatch(
      setFilterFields({
        ...selectedFilterFields
      })
    );
  }, [dispatch, selectedFilterFields]);

  useEffect(() => {
    handleAcceptFilterFields();
  }, [handleAcceptFilterFields]);

  useEffect(() => {
    clearFilterFields();

    getJKList()
      .then((answer = {}) => {
        setRequestedResidents(answer.data.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
        history(PAGES.reportOkkStage);
      });

    dispatch(
      setHeader({
        showBack: false,
        isList: true,
        showCancel: true
      })
    );
  }, [dispatch, addCloseSnack, history, clearFilterFields]);

  return (
    <form style={{ float: "left" }} className={classes.filter_form}>
      <div className={classes.select}>
        <AutoSuggest
          list={requestedResidents}
          name={residentTxt}
          showKey={showResidents}
          valueKey={"resident_id"}
          value={selectedFilterFields[residentTxt]}
          placeholder="Все жк"
          label="Жилой комплекс"
          className={classes.select}
          onChange={e => selectChangeHandler(e.target.name, e.target.value)}
        />
      </div>
      <div className={classes.select}>
        <AutoSuggest
          list={remontFilter}
          name={remontTxt}
          showKey={showRemonts}
          valueKey={"value"}
          value={selectedFilterFields[remontTxt]}
          placeholder="Все ремонты"
          label="Ремонты"
          className={classes.select}
          onChange={e => selectChangeHandler(e.target.name, e.target.value)}
        />
      </div>
      <div className={classes.select}>
        <AutoSuggest
          list={stageFilter}
          name={stageTxt}
          showKey={showStage}
          valueKey={"value"}
          value={selectedFilterFields[stageTxt]}
          placeholder="Все этапы"
          label="Этапы"
          className={classes.select}
          onChange={e => selectChangeHandler(e.target.name, e.target.value)}
        />
      </div>
      <div className={classes.select}>
        <AutoSuggest
          list={defectFilter}
          name={defextTxt}
          showKey={showDefect}
          valueKey={"value"}
          value={selectedFilterFields[defextTxt]}
          placeholder="Все"
          label="Замечания ОКК"
          className={classes.select}
          onChange={e => selectChangeHandler(e.target.name, e.target.value)}
        />
      </div>

      <DownloadXlsx
        url="/partner/report_stages_and_okk/xlsx/"
        params={{
          resident_id: selectedFilterFields[residentTxt],
          remont_performing_code: selectedFilterFields[remontTxt],
          stage_code: selectedFilterFields[stageTxt],
          remark_code: selectedFilterFields[defextTxt]
        }}
        title="Отчет ОКК"
      />
    </form>
  );
};

export default ReportOkkStageFilter;
