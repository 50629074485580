import React, { useState } from "react";
import ReportOkkStageTable from "./reportOkkStageTable";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ReportOkkStageFilter from "./reportOkkStageFilter";
import { useDispatch, useSelector } from "react-redux";
import { reportOkkStage } from "./api";
import { setOkkData } from "../../services/redux/actions/app-actions";
import EmptyOrShow from "components/common/EmptyOrShow";
import { Box } from "@material-ui/core";
import { PageTitle } from "../../components/common/pageTitle";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)",
    borderRadius: 4
  },
  noPadding: {
    padding: 0,
    margin: 0
  },
  totalSum: {
    color: "#494C62",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center"
  },
  tableWrapper: {
    marginTop: 10,
    maxWidth: "100%",
    overflowX: "auto"
  }
}));

export const ReportOkkStage = () => {
  document.title = "Отчет ОКК";
  const dispatch = useDispatch();
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { filterFields } = useSelector(state => state.appState);
  const { resident, remont, stage, defect } = filterFields;

  React.useEffect(() => {
    setLoading(true);
    const params = {
      resident_id: resident || null,
      remont_performing_code: remont || 0,
      stage_code: stage || 0,
      remark_code: defect || 0
    };
    reportOkkStage(params)
      .then(answer => {
        setData(answer);
        setLoading(false);
        dispatch(setOkkData(answer?.data));
      })
      .catch(e => {
        const message = e?.response?.data?.error;
        message && addCloseSnack(message);
        setLoading(false);
      });
  }, [resident, remont, stage, defect, dispatch, addCloseSnack]);

  return (
    <>
      <PageTitle title="Отчет ОКК" />
      <div className={classes.noPadding} style={{ padding: "20px" }}>
        <Box display="flex" flexDirection="column">
          <CollapseBlock tabletMode>
            <ReportOkkStageFilter setData={setData} />
            <div style={{ float: "right", marginBottom: 5, marginRight: 15 }}>
              <Typography
                variant="h6"
                component="h2"
                style={{ color: "#9194A9", fontSize: 12, fontWeight: 600 }}
              >
                Общее кол-во
              </Typography>
              <Typography className={classes.totalSum} color="textSecondary">
                {data["total"]}
              </Typography>
            </div>
          </CollapseBlock>

          <Box className={classes.tableWrapper}>
            <EmptyOrShow loading={loading}>
              <ReportOkkStageTable data={data["data"]} className={classes.root} />
            </EmptyOrShow>
          </Box>
        </Box>
      </div>
    </>
  );
};
