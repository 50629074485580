import instance from "../../services/api";

export const mastersPaymentsAPI = {
  async getMasterPayments(params) {
    return await instance()
      .get("/partner/contractor_costs/master_payment/read/all/", { params })
      .then(res => res?.data);
  },
  async getMasterPaymentsStatuses(params) {
    return await instance()
      .get("/partner/contractor_commons/cost_statuses/", { params })
      .then(res => res?.data);
  },
  async getMasterPaymentsSignedDocs(params) {
    return await instance()
      .get("/partner/contractor_costs/master_payment/signed_document/read/", {
        params
      })
      .then(res => res?.data);
  },
  async getGroupedMasterPayments(params) {
    return await instance()
      .get("/partner/contractor_costs/master_payment/", { params })
      .then(res => res?.data);
  },
  async makePayment(body) {
    return await instance()
      .post("/partner/contractor_costs/master_payment/sign/", body)
      .then(res => res?.data);
  },
  async cancelGroupedPayment(body, params) {
    return await instance()
      .post("/partner/contractor_costs/master_payment/cancel/", body, {
        params
      })
      .then(res => res?.data);
  }
};
