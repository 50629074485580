import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "../team_detail.module.scss";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../../common/CustomTable/CustomTable";
import { cc_format, numberWithCommas } from "../../../../../utils";
import StarIcon from "@material-ui/icons/Star";
import {
  getMasterTypesData,
  getTeamsData,
  showPopUp
} from "../../../../../services/redux/actions/app-actions";
import { useDispatch } from "react-redux";
import { ActiveCheckbox } from "../../index";
import { useHttp } from "../../../../../utils/hooks/http.hook";
import { getTeamMasters, getTeamMastersByRemont } from "../../api";
import { Alert } from "@material-ui/lab";
import { CustomLoader } from "../../../../common/CustomLoader";
import { useCustomSnackbar } from "../../../../../utils/hooks/useCustomSnackbar";
import { useTableStyles } from "../../../accounting/styles";
import { MastersFilter } from "./masters_filter";
import {
  DeleteIconWrapper,
  DocIconWrapper,
  EditIconWrapper
} from "../../../../common/blocks";
import { MODALS_NAMES } from "../../../../../constants";
import { getMastersColumns, getMastersList } from "../../services";
import { MasterPhoneNumbers } from "./MasterPhoneNumbers";

export const TeamDetailMasters = ({
  id,
  team_id,
  onTeamChange = () => {},
  remont_id = null,
  amount
}) => {
  const dispatch = useDispatch();
  const { addDefaultSnack } = useCustomSnackbar();
  const classesTable = useTableStyles();
  const { request } = useHttp();
  const [isMainMasterExist, setIsMainMasterExist] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [mastersCount, setMastersCount] = useState(0);
  const [masters, setMasters] = useState([]);
  const [filters, setFilters] = useState({ search: "" });
  const [teamId, setTeamId] = useState(team_id);

  const isRemontMode = !!remont_id;
  const isTeamMode = !!team_id;
  const allMastersMode = !remont_id && !team_id;

  useEffect(() => {
    dispatch(getTeamsData());
    dispatch(getMasterTypesData());
  }, [dispatch]);

  const getAllMastersData = useCallback(async (filters = {}) => {
    setFetching(true);
    const res = await getMastersList(filters || {});
    setFetching(false);
    setMastersCount(res?.count);
    setMasters(res?.masters);
  }, []);

  useEffect(() => {
    const getDetailData = async () => {
      try {
        if (allMastersMode) return await getAllMastersData();
        setFetching(true);
        const res = remont_id
          ? await getTeamMastersByRemont(remont_id)
          : await getTeamMasters(id);
        setFetching(false);
        setMastersCount(res?.count);
        setMasters(res?.team_masters);
        setIsMainMasterExist(res?.is_main_master_exist);
        res?.team_id && setTeamId(res?.team_id);
      } catch (e) {
        setFetching(false);
      }
    };
    getDetailData();
  }, [id, remont_id, allMastersMode, getAllMastersData]);

  const onDocClick = team_master => {
    dispatch(
      showPopUp(true, MODALS_NAMES.teamMasterDocuments, {
        team_master_id: team_master.team_master_id,
        title: `Список документов мастера (${team_master.team_master_fio})`,
        close: true
      })
    );
  };

  const onDeleteClick = (id, team_id) => {
    dispatch(
      showPopUp(true, MODALS_NAMES.confirmModal, {
        onSubmit: handleDelete,
        id: { id, team_id }
      })
    );
  };

  const onEditClick = body => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addTeamMaster, {
        onSubmit: onSubmitUpdate,
        body,
        allMastersMode,
        isTeamMode,
        team_id: teamId || team_id
      })
    );
  };

  const onAddClick = () => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addTeamMaster, {
        onSubmit,
        isTeamMode,
        allMastersMode
      })
    );
  };

  const onSubmit = async (data, team_id) => {
    const res = await request(
      `partner/teams/${teamId || team_id || data?.team_id}/masters/`,
      "post",
      {
        ...data,
        type: allMastersMode ? "masters" : "",
        master_type_id: Number(data?.master_type_id)
      },
      {},
      { isModal: true }
    );
    if (res?.status) {
      addDefaultSnack("Мастер успешно создан");
      setMasters(res?.team_masters || res?.masters);
      setIsMainMasterExist(res?.is_main_master_exist);
      setMastersCount(res?.count);
      dispatch(showPopUp(false));
      onTeamChange({
        team_id: teamId,
        is_main_master_exist: res?.is_main_master_exist,
        count: res?.count
      });
    }
  };

  const onSubmitUpdate = async (data, team_id) => {
    if (!data) return;
    const res = await request(
      `partner/teams/${teamId || team_id || data?.team_id}/masters/${
        data?.team_master_id
      }/update_master/`,
      "put",
      {
        ...data,
        type: allMastersMode ? "masters" : "",
        master_type_id: Number(data?.master_type_id)
      },
      {},
      { isModal: true }
    );
    if (res?.status) {
      addDefaultSnack("Мастер успешно изменен");
      const { team_masters, masters } = res;
      setMasters(team_masters || masters);
      setIsMainMasterExist(res?.is_main_master_exist);
      setMastersCount(res?.count);
      onTeamChange({
        team_id: teamId,
        is_main_master_exist: res?.is_main_master_exist,
        count: res?.count
      });
      dispatch(showPopUp(false));
    }
  };

  const handleDelete = async ({ id: team_master_id, team_id }) => {
    const res = await request(
      `/partner/teams/${teamId ||
        team_id}/masters/${team_master_id}/delete_master/`,
      "delete",
      { team_master_id, params: { type: allMastersMode ? "masters" : "" } },
      {},
      { isModal: true }
    );
    if (res?.status) {
      addDefaultSnack("Мастер успешно удален");
      setMasters(res?.team_masters || res?.masters);
      setMastersCount(res?.count);
      setIsMainMasterExist(res?.is_main_master_exist);
      onTeamChange({
        team_id: teamId,
        is_main_master_exist: res?.is_main_master_exist,
        count: res?.count
      });
      dispatch(showPopUp(false));
    }
  };

  const handleFiltersChange = async (name, value) => {
    setFilters(prev => ({ ...prev, [name]: value }));
    if (name === "search") return;

    const filtersCorrect = {};
    const filtersBody = { ...filters, [name]: value };
    for (let key in filtersBody) {
      filtersCorrect[key] = filtersBody[key] === false ? "" : filtersBody[key];
    }
    await getAllMastersData(filtersCorrect);
  };

  const computedData = useMemo(() => {
    if (!masters) return [];
    let edited = [...masters];
    if (filters.search) {
      edited = edited.filter(item =>
        item?.team_master_fio
          ?.toLowerCase()
          ?.includes(filters.search?.toLowerCase())
      );
    }
    return edited;
  }, [masters, filters]);

  const headItems = useMemo(() => {
    return getMastersColumns(allMastersMode);
  }, [allMastersMode]);

  return (
    <>
      {allMastersMode && (
        <MastersFilter
          filters={filters}
          handleFiltersChange={handleFiltersChange}
        />
      )}
      {fetching ? (
        <CustomLoader />
      ) : (
        <div>
          <div className={styles.team_detail__header}>
            <h2>{`${isRemontMode ? "Бригада" : "Мастера"} (${mastersCount ||
              0})`}</h2>
            {!isMainMasterExist && !allMastersMode && (
              <div className={styles.team_detail__alert}>
                <Alert severity="warning">Не выбран бригадир!</Alert>
              </div>
            )}
          </div>
          <div className={styles.team_detail__flex}>
            <Button
              className={clsx(classesTable.btn)}
              variant="outlined"
              onClick={onAddClick}
            >
              Добавить
            </Button>
            {amount !== undefined && (
              <p className={styles.team_detail__master}>
                Сумма: <span>{numberWithCommas(amount)}</span>
              </p>
            )}
          </div>
          <CustomTable
            headItems={headItems}
            stickyHeader
            isEmpty={!masters?.length}
            containerClassName={styles.team_detail__table}
          >
            {computedData?.map(item => {
              return (
                <CustomTableRow key={item?.team_master_id}>
                  <CustomTableCell align="left">
                    {item?.team_master_fio || ""}
                  </CustomTableCell>
                  {allMastersMode && (
                    <CustomTableCell align="left">
                      {`${item?.team_id}, ${item.team_name}`}
                    </CustomTableCell>
                  )}
                  <CustomTableCell align="left">
                    {item.team_master_doc_num}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    {item?.master_type_name || ""}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    {cc_format(item?.iban, true)}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    <MasterPhoneNumbers phone_numbers={item?.phone_numbers} />
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    {cc_format(item?.card_num)}
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    <span className={styles.team_detail__symbol}>
                      {item.is_main_master && <StarIcon fill={"#ffc40d"} />}
                    </span>
                  </CustomTableCell>
                  <CustomTableCell
                    align="center"
                    className={clsx(
                      item.is_active
                        ? classesTable.active
                        : classesTable.inActive
                    )}
                  >
                    {!!item.is_active && <ActiveCheckbox />}
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    <div className={classesTable.actions}>
                      <DocIconWrapper onClick={() => onDocClick(item)} />
                      <EditIconWrapper onClick={() => onEditClick(item)} />
                      <DeleteIconWrapper
                        onClick={() =>
                          onDeleteClick(item?.team_master_id, item?.team_id)
                        }
                      />
                    </div>
                  </CustomTableCell>
                </CustomTableRow>
              );
            })}
          </CustomTable>
        </div>
      )}
    </>
  );
};
