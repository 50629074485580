import React, { useCallback } from "react";
import NumberFormat from "react-number-format";
import { TextField } from "@material-ui/core";

export const InputSumMask = ({
                               name,
                               label,
                               value,
                               onChange,
                               error,
                               onBlur,
                               onEnter,
                               helperText,
                               placeholder,
                               format = null,
                               suffix = "",
                               allowLeadingZeros = false,
                               allowNegative = false,
                               mask = true,
                               integer = false,
                               disabled = false,
                               min = null,
                               max = null,
                               className = ""
                             }) => {
  const inputProps = {
    allowNegative,
    allowLeadingZeros,
    min,
    max,
    integer,
    placeholder,
    mask,
    disabled,
    value: value === 0 ? 0 : value || "",
    suffix,
    name
  };
  if (format) {
    inputProps.format = format;
    inputProps.mask = "_";
  }

  const onKeyDown = e => {
    if (!onEnter || e?.key !== "Enter") return;
    onEnter(e);
  };

  return (
    <TextField
      fullWidth
      label={label}
      value={value || ""}
      onChange={onChange}
      name={name}
      suffix={suffix}
      className={className}
      id={name}
      onKeyDown={onKeyDown}
      onBlur={e => onBlur && onBlur(e)}
      InputProps={{
        inputComponent: NumberFormatCustom
      }}
      inputProps={inputProps}
      helperText={helperText}
      error={!!error}
    />
  );
};

function NumberFormatCustom({
                              inputRef,
                              onChange,
                              onEnter,
                              integer,
                              min,
                              mask,
                              max = null,
                              name,
                              ...other
                            }) {
  const isValueAllowed = useCallback(
    values => {
      return max === null ? true : (values.floatValue || null) <= max;
    },
    [max]
  );
  const onValueChange = useCallback(
    values => {
      const value = values.value;
      const valueSlice = value.slice(0, 2);
      const correctValue =
        value === "0" ? 0 : (valueSlice === "0." ? value : Number(value)) || "";
      onChange({
        target: {
          name,
          value: correctValue
        }
      });
    },
    [onChange, name]
  );
  const onBlurCapture = useCallback(() => {
    if (typeof other.value === "number") return;
    onChange &&
    onChange({
      target: {
        name,
        value: other.value === "" ? "" : Number(other.value) || 0
      }
    });
  }, [onChange, name, other]);
  const onKeyDown = useCallback(
    e => {
      if (!onEnter) return;
      if (e.key === "Enter") {
        onChange({
          target: {
            name,
            value: other.value
          }
        });
      }
    },
    [onChange, name, onEnter, other]
  );
  return (
    <NumberFormat
      getInputRef={inputRef}
      onBlurCapture={onBlurCapture}
      enterKeyHint={"enter"}
      onValueChange={onValueChange}
      isAllowed={isValueAllowed}
      min={min === null ? 0 : min}
      allowNegative={false}
      thousandSeparator={mask ? " " : ""}
      decimalSeparator={integer ? null : "."}
      allowedDecimalSeparators={[".", ","]}
      {...other}
      onKeyDown={onKeyDown}
    />
  );
}
