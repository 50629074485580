import { forContractorsAPI } from "./api";

export const getContractorsData = async (type) => {
  try {
    const res = await forContractorsAPI.getContractorsData(type)
    return res?.data
  } catch (e) {}
}
export const registerNewContractor = async (body) => {
  try {
    return await forContractorsAPI.registerContractor(body)
  } catch (e) {}
}
export const getContractorInfo = async () => {
  try {
    const res = await forContractorsAPI.getContractorInfo()
    return res?.data
  } catch (e) {}
}