import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReportTable from "./reportTable";
import EmptyOrShow from "components/common/EmptyOrShow";
import ReportFilter from "./reportFilter";
import { PageTitle } from "../../components/common/pageTitle";

const useStyles = makeStyles(() => ({
  content: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    padding: "20px"
  }
}));

export const ReportMaterialShip = () => {
  document.title = "Отчет по отгрузке материалов";
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [flatNum, setFlatNum] = useState("");

  return (
    <>
      <PageTitle title={"Отчет по отгрузке материалов"} />
      <div className={classes.content}>
        <ReportFilter
          setLoading={setLoading}
          setFlatNum={setFlatNum}
          flatNum={flatNum}
        />
        <EmptyOrShow loading={loading}>
          <ReportTable flatNum={flatNum} />
        </EmptyOrShow>
      </div>
    </>
  );
};
