import React from "react";
import { PageTitle } from "../../components/common/pageTitle";
import { ExecutiveReport } from "./ExecutiveReport";

const title = "Отчеты для руководителей";
export const ExecutiveReportPage = () => {
  document.title = title;

  return (
    <>
      <PageTitle title={title} />
      <ExecutiveReport />
    </>
  );
};
