import React from "react";
import { Box, FormControl } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const CommentUploadFile = ({
  handleChange,
  multiple = true,
  absolute = true
}) => {
  const style = absolute
    ? {
        position: "absolute",
        left: "71%",
        marginTop: 7
      }
    : {};
  return (
    <FormControl style={style}>
      <Box style={{ marginTop: absolute ? 6 : 0 }}>
        <label
          htmlFor="upload-files"
          style={{
            fontSize: "14px",
            lineHeight: "15px",
            color: "#000",
            whiteSpace: "nowrap"
          }}
        >
          <input
            style={{
              display: "none"
            }}
            id="upload-files"
            name="upload-files"
            type="file"
            multiple={multiple}
            onChange={handleChange}
          />
          <div style={{ cursor: "pointer" }}>
            <Box style={{ transform: "rotate(38deg)" }}>
              <AttachFileIcon color="primary" />
            </Box>
          </div>
        </label>
      </Box>{" "}
    </FormControl>
  );
};

export default CommentUploadFile;
