import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ContractJournalFile from "./ContractJournalFile";

export const useStyles = makeStyles({
  popper: {
    position: "absolute",
    top: 20,
    left: 0,
    width: 150,

    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 10%) 5px 5px 25px",
    borderRadius: 4,
    border: "1px solid rgb(210, 211, 217)",

    zIndex: 100,
    display: "flex",
    flexDirection: "column"
  },
  file: {
    fontSize: 15,
    cursor: "pointer",
    padding: "7px 5px",
    transition: "all .2s linear",
    display: "inline-flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#ECEEFD"
    },
    "& a": {
      fontSize: 15,
      color: "#1a1a1a",
      textDecoration: "none"
    }
  },
  disabled: {
    pointerEvents: "none",
    color: "lightgray"
  }
});

const Popper = ({
  fileNames,
  remont_id,
  type_code,
  contractor_agreement_list_id,
  document_url
}) => {
  const classes = useStyles();
  return (
    <>
      {fileNames.length > 0 ? (
        <Box className={classes.popper}>
          {fileNames?.map((el, i) => (
            <ContractJournalFile
              key={i}
              name={el}
              type_code={type_code}
              agreement_id={contractor_agreement_list_id}
              remont_id={remont_id}
              document_url={document_url}
            />
          ))}
        </Box>
      ) : null}
    </>
  );
};

export default Popper;
