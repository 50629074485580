import instance from "./index";
import cookiesService from "../cookiesService";

export const checkAuth = () => {
  const cookiesServiceInstance = cookiesService.getService();
  const token = cookiesServiceInstance.getAccessToken();
  return instance(true, null, {
    showToast: false,
    throwError: true
  }).post(`/auth/api/token/verify/`, { token });
};
export const getConstructorLanding = async () => {
  try {
    const res = await instance(true, 'https://office.smartremont.kz', {isBasic: true, isThirdParty: true})
      .get(`/react/landing/main-data/`).then(res => res?.data)
      .catch(() => {});
    return res?.data
  } catch (e) {}
};
export const getPackageData = async (id) => {
  try {
    const res = await instance(true, 'https://office.smartremont.kz', {isBasic: true, isThirdParty: true})
      .get(`/react/landing/preset-kit-img-list/id/${id}`)
      .then((res) => res?.data)
      .catch(() => {});
    return res?.data
  } catch (e) {}
};