import { makeStyles } from "@material-ui/core/styles";

export const useTableStyles = makeStyles({
  root: {
    maxHeight: "auto",
    marginTop: "15px"
  },
  btn: {
    width: "auto",
    height: 38,
    borderRadius: 4,
    color: "#fff",
    cursor: "pointer",
    padding: "5px 12px",
    whiteSpace: "nowrap",
    backgroundColor: "#0C4B86",
    minWidth: "auto",
    border: "none",

    "&:hover": {
      backgroundColor: "#0C4B86"
    }
  },
  paymentBtn: {
    backgroundColor: "#53ce4a !important",
    "&:hover": {
      backgroundColor: "#399b34 !important"
    }
  },
  searchWrapper: {
    marginTop: 15,
    display: "flex",
    alignItems: "center",
    gap: 15,
    flexWrap: "wrap"
  },
  iconWrapper: {
    display: "flex"
  },
  editIcon: {
    width: "18px",
    cursor: "pointer",
    color: "#3d4977"
  },
  deleteIcon: {
    color: "#b61818"
  },
  disabled: {
    color: "#ccc",
    fill: "#ccc",
    cursor: "default"
  },
  actions: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    justifyContent: "center",
    flexWrap: "nowrap"
  },
  active: {
    color: "green"
  },
  inActive: {
    color: "red"
  },
  link: {
    textDecoration: "none",
    color: "#172d7a",
    transition: "all .3s",
    "&:hover": {
      textDecoration: "underline"
    }
  }
});
