import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CButton } from "../../../../common/buttons";
import { InputSumMask } from "../../../../common/form/InputSumMask";
import { numberWithCommas } from "../../../../../utils";
import styles from "../add_cost_income.module.scss";
import { ArrowBack } from "@material-ui/icons";
import { showPopUp } from "../../../../../services/redux/actions/app-actions";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { InputWarning } from "../../../../common/form/warnings";
import { MODALS_NAMES } from "../../../../../constants";

const splitSymbol = "debt__";
export const AddCostDebtInfo = ({ data }) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const { modalLoading } = useSelector(state => state.appState);

  const { cost_origin, cost_remaining, costs, backData } = data;
  const [debtsPayments, setDebtsPayments] = useState(costs || []);
  const [remainValue, setRemainValue] = useState(cost_remaining?.cost_sum || 0);

  const onInputChange = async (debt_id, cost_sum) => {
    const editedPayments = debtsPayments?.map(item => {
      if (item?.debt_id === debt_id) return { ...item, cost_sum };
      return item;
    });
    const newRemainValue = editedPayments?.reduce(
      (prev, current) => prev + current?.cost_sum,
      0
    );
    const costSum = cost_origin?.cost_sum - newRemainValue;
    setDebtsPayments(editedPayments);
    setRemainValue(costSum > 0 ? costSum : 0);
  };
  const onSubmit = async () => {
    const { onSubmit: submit } = data;
    const body = {
      cost_origin,
      costs: debtsPayments.concat([
        { ...cost_remaining, cost_sum: remainValue }
      ])
    };
    return submit && (await submit(body));
  };

  const onBackClick = async () => {
    dispatch(showPopUp(true, MODALS_NAMES.addCost, backData));
  };

  return (
    <div className={styles.accounting__cost_modal}>
      <div className={styles.accounting__back} onClick={onBackClick}>
        <ArrowBack />
        Назад
      </div>
      <div
        className={clsx(
          styles.accounting__header,
          styles.accounting__header_debt
        )}
      >
        <h2 className={styles.accounting__title}>
          Подтверждение списаний долгов
        </h2>
      </div>
      <div className={styles.accounting__remaining_wrapper}>
        <h3 className={styles.accounting__remaining}>
          Сумма расхода:{" "}
          <span>{numberWithCommas(cost_origin?.cost_sum)} тг.</span>
        </h3>
        <h3 className={styles.accounting__remaining}>
          Остаток после списания долгов:{" "}
          <span>{numberWithCommas(remainValue)} тг.</span>
        </h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.accounting__form_items}>
          {debtsPayments?.map(item => {
            const name = `${splitSymbol}${item?.debt_id}`;
            const debtName = (
              <span>
                {`Погашение долга от ${
                  item?.date_start
                }, в сумме ${numberWithCommas(item?.debt_sum)} тг.`}
                <br />
                {`(${item?.percent_type || ""}, ${item?.debt_percent ||
                  ""}%, остаток долга: ${numberWithCommas(
                  item?.debt_sum_remaining
                )} тг. )`}
              </span>
            );
            return (
              <div
                key={item?.debt_id}
                className={clsx(styles.accounting__form_itemDebt)}
              >
                <p className={styles.accounting__debtName}>{debtName}</p>
                <Controller
                  name={name}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputSumMask
                      type="number"
                      fullWidth
                      min={0}
                      max={item?.debt_sum_remaining || 0}
                      label="Сумма списания"
                      placeholder="Введите сумму списания"
                      wrapperClassname={styles.accounting__input_wrapper}
                      value={value}
                      onChange={e => {
                        onInputChange(item?.debt_id, e?.target.value);
                        onChange(e);
                      }}
                    />
                  )}
                  defaultValue={item?.cost_sum || 0}
                  rules={{
                    max: item?.debt_sum_remaining
                  }}
                />
                {!!errors[name] && (
                  <InputWarning>Сумма превышает остаток долга</InputWarning>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles.accounting__btn_wrapper}>
          <CButton
            loading={modalLoading}
            disabled={modalLoading}
            type={"submit"}
            className={styles.accounting__btn}
          >
            Сохранить
          </CButton>
        </div>
      </form>
    </div>
  );
};
