import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { fetchStatuses, getMaterialsList, searchMaterialRequests } from "./api";
import { makeStyles } from "@material-ui/core/styles";
import { PageTitle } from "../../components/common/pageTitle";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../components/common/CustomTable/CustomTable";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

const useStyles = makeStyles({
  search: {
    "& .MuiFormControl-root": {
      width: "100%"
    },
    "& .MuiFormLabel-root": {
      fontSize: 18,
      fontWeight: 600,
      color: "rgba(0, 0, 0, 0.45)"
    }
  },
  title: {
    marginTop: 20,
    textAlign: "center",
    fontSize: 25,
    fontWeight: 600
  },
  container: {
    marginTop: 20,
    maxHeight: "79vh"
  },
  space: {
    marginBottom: 10,
  }
});

export const MaterialSearch = () => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [filters, setFilters] = useState({
    material_id: null,
    provider_request_status_id: null
  });

  useEffect(() => {
    setIsLoading(true);
    getMaterialsList()
      .then(res => {
        setIsLoading(false);
        setMaterials(res.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
        setIsLoading(false);
      });
  }, [addCloseSnack]);

  useEffect(() => {
    fetchStatuses()
      .then(res => {
        setStatusList(res.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [addCloseSnack]);

  const asyncFetchMaterials = async params => {
    try {
      setIsLoading(true);
      const { data } = await searchMaterialRequests(params);
      setTableData(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      addCloseSnack(err.response.data.error);
    }
  };

  const handleChange = async e => {
    const params = { ...filters, [e.target.name]: e.target.value };
    setFilters(params);

    await asyncFetchMaterials(params);
  };

  return (
    <>
      <PageTitle title={"Поиск по материалам"} />
      <Box padding={2.5}>
        <CollapseBlock tabletMode className={classes.space}>
          <Box display="flex" flexWrap={'wrap'} alignItems={"flex-end"}>
            <Box width={300} marginRight={3}>
              <AutoSuggest
                withMargin
                list={materials}
                showKey={"material_name"}
                valueKey={"material_id"}
                name={"material_id"}
                label="Выберите материал"
                value={filters.material_id}
                onChange={handleChange}
              />
            </Box>
            <Box width={300}>
              <AutoSuggest
                withMargin
                list={statusList}
                showKey={"provider_request_status_name"}
                valueKey={"provider_request_status_id"}
                name={"provider_request_status_id"}
                label="Все статусы"
                value={filters.provider_request_status_id}
                onChange={handleChange}
              />
            </Box>
          </Box>
        </CollapseBlock>
        <CustomTable
          withMaxHeight={false}
          containerClassName={classes.container}
          isEmpty={!tableData?.length}
          isFetching={isLoading}
          headItems={headerTitles}
        >
          {tableData?.map(el => (
            <CustomTableRow key={el.provider_request_item_id}>
              <CustomTableCell>{el?.resident_name}</CustomTableCell>
              <CustomTableCell>{el?.flat_num}</CustomTableCell>
              <CustomTableCell>{el?.material_name}</CustomTableCell>
              <CustomTableCell>
                {el?.qty_atom}
                {el?.atom_unit_name}
              </CustomTableCell>
              <CustomTableCell>{el?.status_name}</CustomTableCell>
              <CustomTableCell>{el?.date_shipping}</CustomTableCell>
            </CustomTableRow>
          ))}
        </CustomTable>
      </Box>
    </>
  );
};

export default MaterialSearch;

//жк, кв, Название материала Количество Статус Дата отгрузки
const headerTitles = [
  { label: "ЖК" },
  { label: "Квартира" },
  { label: "Название материала" },
  { label: "Количество" },
  { label: "Статус" },
  { label: "Дата отгрузки" }
];
