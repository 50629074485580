import React from "react";
import styles from "./ImgWrapper.module.scss";
import { MAIN_URL } from "../../../../constants";

const LOGO_DEFAULT_FILE_NAME =
  process.env.REACT_APP_LOGO_DEFAULT_URL || "logo_gray.svg";
const Logo = require(`assets/images/logo/${LOGO_DEFAULT_FILE_NAME}`);
export const CustomImage = ({
  src,
  defaultSrc = `assets/images/logo/${LOGO_DEFAULT_FILE_NAME}`,
  alt,
  className,
  style = {},
  isStaticImg,
  widthOnError = null,
  postFix = MAIN_URL,
  ...other
}) => {
  const onImgError = e => {
    if (!!defaultSrc) {
      e.target.style.visibility = "visible";
      e.target.src = Logo;
      if(widthOnError)
        e.target.style.width = `${widthOnError}px`
    } else e.target.style.visibility = "hidden";
  };

  const onImgLoad = e => {
    e.target.style.visibility = "visible";
  };

  return (
    <img
      {...other}
      className={`${styles.image__custom} ${className}`}
      src={src ? (isStaticImg ? src : `${postFix}${src}`) : Logo}
      onLoad={onImgLoad}
      onError={onImgError}
      alt={alt || ""}
      style={style}
    />
  );
};
