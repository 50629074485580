import React from "react";
import { Typography } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { TabPanel } from "../../../components/common/CustomTabs";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  selected: {
    backgroundColor: "#fff"
  },
  paramName: {
    fontSize: 14,
    fontWeight: 600,
    color: "#9194A9",
    marginBottom: 10,
    letterSpacing: "0.04em"
  },
  paramValue: {
    fontSize: 16,
    fontWeight: 600,
    color: "#0C4B86",
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  wrapper: {
    border: "1px solid #D2D3D9",
    boxShadow: "5px 5px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px 0px 4px 4px",
    maxWidth: 200,
    padding: "10px 0"
  },
  collapse_item: {
    padding: "5px 12px",
    "&:nth-child(even)": {
      backgroundColor: "#EAECFB"
    },
    "&:nth-child(odd)": {
      backgroundColor: "#FFF"
    }
  },
  popper: {
    border: "1px solid #D2D3D9",
    boxShadow: "5px 5px 25px rgba(0, 0, 0, 0.1)"
  },
  root_tab: {
    "& .MuiBox-root": {
      padding: "0"
    }
  },
  box: {
    maxWidth: 200
  },
  paramItem: {
    position: "relative"
  },
  info: {
    width: 200,
    position: "absolute",
    backgroundColor: "#FFFFFF",
    boxShadow: "5px 5px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px 0px 4px 4px",
    border: "1px solid #D2D3D9",
    zIndex: 1
  }
}));
const MeasureListItem = ({
  param_name,
  param_value,
  unit_name,
  info,
  extra_info
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    if (!extra_info) return;
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  return (
    <TabPanel classes={{ root: classes.root_tab }} padding={"15px 20px"}>
      <Typography className={classes.paramName}>{param_name}</Typography>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={clsx(classes.box, classes.paramItem)}>
          <Typography className={classes.paramValue} onClick={handleClick}>
            {param_value} {unit_name} {extra_info ? <ExpandMore /> : null}
          </Typography>
          {open && (
            <div className={classes.info}>
              {info?.map(el => {
                return (
                  <Typography
                    key={el?.room_id}
                    className={classes.collapse_item}
                  >
                    {el.room_name} ({el.param_value} {el.unit_name})
                  </Typography>
                );
              })}
            </div>
          )}
        </div>
      </ClickAwayListener>
    </TabPanel>
  );
};

export default MeasureListItem;
