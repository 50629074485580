import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useStyles } from "./ChangeProrabModal";
import { useHttp } from "../../../../utils/hooks/http.hook";
import { CButton } from "../../../../components/common/buttons";
import { getTeams } from "../../../../components/features/teams/api";
import { AutoSuggest } from "../../../../components/common/form/AutoSuggest";
import { useCustomSnackbar } from "../../../../utils/hooks/useCustomSnackbar";

const ChangeBrigadaModal = ({ handleClose, data }) => {
  const classes = useStyles();
  const { loading, request } = useHttp();
  const { addDefaultSnack, addCloseSnack } = useCustomSnackbar();
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(data?.team_id || "");
  const { remont_id, setData } = data;

  useEffect(() => {
    getTeams()
      .then(res => {
        setTeams(res);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [addCloseSnack]);

  const handleChange = e => {
    setSelectedTeam(e.target.value);
  };

  const handleClick = async () => {
    const res = await request(`/partner/remont/${remont_id}/brigada/`, "post", {
      team_id: selectedTeam
    });
    if (res) {
      setData(res);
      handleClose();
      addDefaultSnack("Бригада успешно назначена");
    }
  };
  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.title}>Изменить бригаду</Typography>
      <Box>
        <AutoSuggest
          withMargin
          list={teams}
          showKey={"team_name"}
          valueKey={"team_id"}
          label="Выберите бригаду"
          value={selectedTeam}
          onChange={handleChange}
        />
        <Box display="flex" mt={2}>
          <Button
            className={classes.btn}
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            Отменить
          </Button>
          <CButton
            className={classes.btn}
            onClick={handleClick}
            loading={loading}
            disabled={!Boolean(selectedTeam) || loading}
          >
            Сохранить
          </CButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangeBrigadaModal;
