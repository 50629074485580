import React from "react";
import { Box, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CloseSquare, Download } from "react-iconly";
import Typography from "@material-ui/core/Typography";
import { MAIN_URL } from "../../../constants";
import { FaRegFileExcel, ImFileWord } from "react-icons/all";
import ImageIcon from "@material-ui/icons/Image";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const useStyles = makeStyles({
  file_body: {
    marginRight: 20,
    marginBottom: 10
  },
  file_wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "rgba(204, 204, 204, 0.25)",
    overflow: "hidden",
    borderRadius: 15,
    padding: "6px 15px",
    maxWidth: 200,
    width: "100%",
    height: 30,
    marginRight: 8,
    marginBottom: 10
  },
  file: {
    maxWidth: 100,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "17px",
    color: "#000",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
});

const styles = {
  fontSize: "18px",
  marginRight: 7
};
const getFileIcon = (file_ext) => {
  switch (file_ext) {
    case "pdf":
      return <PictureAsPdfIcon style={{ ...styles, fill: "#e33030" }}  />;
    case "xlsx":
      return <FaRegFileExcel style={{ ...styles, fill: "#27AE60" }}  />;
    case "docx":
      return <ImFileWord style={{ ...styles, fill: "#3050FF" }}  />;
    case "jpeg":
      return <ImageIcon style={{ ...styles, fill: "#177578" }}  />;
    case "png":
      return <ImageIcon style={{ ...styles, fill: "#177578" }}  />;
    default:
      return <HelpOutlineIcon style={styles} />;
  }
};
const MessageFile = ({
  isFormMessage,
  handleClick,
  el,
  file_name,
  file_ext,
  file_url,
  progress
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.file_body}>
      <Box className={classes.file_wrapper}>
        {isFormMessage ? (
          <>
            <Typography className={classes.file} title={el?.name}>
              {el?.name}
            </Typography>
            <CloseSquare
              style={{ marginLeft: 7, cursor: "pointer" }}
              set="light"
              primaryColor="#0C4B86"
              onClick={() => handleClick(el?.name)}
            />
          </>
        ) : (
          <>
            {getFileIcon(file_ext)}
            <Typography className={classes.file} title={file_name}>
              {file_name}
            </Typography>
            <a
              href={`${MAIN_URL}${file_url}`}
              target="_blank"
              style={{ display: "inline-flex" }}
              rel="noreferrer noopener"
            >
              <Download
                style={{ marginLeft: 7, cursor: "pointer" }}
                set="light"
                primaryColor="#0C4B86"
              />
            </a>
          </>
        )}
      </Box>
      {isFormMessage && progress > 0 ? (
        <LinearProgress variant="determinate" value={progress} />
      ) : null}
    </Box>
  );
};

export default MessageFile;
