import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/common/pageTitle";
import { CustomSearch } from "../../components/common/form/CustomSearch";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { fetchResidentList } from "../Problems/api";
import { getProrabList } from "../remonts/RemontInfo/services/api";
import { fetchCompletedStages, fetchStages } from "./api";
import { format } from "date-fns";
import { matchSorter } from "match-sorter";
import DownloadXlsx from "../../components/common/DownloadXlsx";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { CustomDatePicker } from "../../components/common/form/CustomDatePicker";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../components/common/CustomTable/CustomTable";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { getContractors } from "../remonts/services/api";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

const useStyles = makeStyles({
  btn_wrapper: {
    "& button": {
      margin: 0
    }
  }
});

export const CompletedStage = () => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const headerTitles = [
    { label: "ID" },
    { label: "Подрядчик" },
    { label: "Прораб" },
    { label: "Этап" },
    { label: "Дата завершения" },
    { label: "ЖК" },
    { label: "Квартира" },
    { label: "Площадь" }
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filterState, setFilterState] = useState({
    resident_id: null,
    date_begin: format(new Date(), "dd.MM.yyyy"),
    date_end: format(new Date(), "dd.MM.yyyy"),
    employee_id: null,
    stage_id: null,
    contractor_id: null
  });
  const fetchFilterData = async () => {
    const [residents, prorabs, contractors, stages] = await Promise.all([
      fetchResidentList(),
      getProrabList(),
      getContractors(),
      fetchStages()
    ]).catch(() => {});
    setFilterData({
      residents: residents.data,
      prorabs: prorabs.data,
      contractors: contractors.data,
      stages: stages.data
    });
  };

  const { residents, prorabs, stages } = filterData;
  const {
    resident_id,
    date_begin,
    date_end,
    employee_id,
    stage_id
  } = filterState;

  const handleChange = e => {
    setFilterState(prev => ({
      ...prev,
      [e.target.name]: e.target.value || null
    }));
  };

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    fetchFilterData();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchCompletedStages(filterState)
      .then(res => {
        setLoading(false);
        setData(res.data);
      })
      .catch(err => {
        setLoading(false);
        addCloseSnack(err.response?.data.error);
      });
  }, [filterState, addCloseSnack]);

  const filteredList = matchSorter(data, searchTerm, {
    keys: ["area", "flat_num", "remont_id", "contractor_name"]
  });

  return (
    <div>
      <PageTitle title="Отчет по этапам" />
      <div style={{ padding: 20 }}>
        <CollapseBlock>
          <Box display="flex" flexWrap={'wrap'} gridGap={'10px'} justifyContent="space-between" mb={1}>
            <Box width={340}>
              <CustomSearch
                value={searchTerm}
                handleChange={handleSearchChange}
              />
            </Box>
            <Box className={classes.btn_wrapper}>
              <DownloadXlsx
                url={`/partner/report_completed_stage/xlsx/`}
                params={filterState}
                title="Отчет по завершениям этапов"
              />
            </Box>
          </Box>
          <Box display="flex" flexWrap="wrap" gridGap={'12px'} alignItems={"flex-end"}>
            <Box width={200}>
              <AutoSuggest
                list={residents}
                value={resident_id}
                showKey={"resident_name"}
                valueKey={"resident_id"}
                name={"resident_id"}
                placeholder="Выберите ЖК"
                label="Жилой комплекс"
                onChange={handleChange}
              />
            </Box>
            <Box width={185}>
              <AutoSuggest
                list={prorabs}
                value={employee_id}
                showKey={"fio"}
                valueKey={"employee_id"}
                name={"employee_id"}
                placeholder="Выберите прораба"
                label="Прораб"
                onChange={handleChange}
              />
            </Box>
            <Box width={145}>
              <CustomDatePicker
                fullWidth
                label={"Дата начала"}
                placeholder={"Выберите дату"}
                name={"date_begin"}
                value={date_begin}
                onChange={handleChange}
              />
            </Box>
            <Box width={145}>
              <CustomDatePicker
                fullWidth
                label={"Дата конца"}
                placeholder={"Выберите дату"}
                name={"date_end"}
                value={date_end}
                onChange={handleChange}
              />
            </Box>
            <Box width={155}>
              <AutoSuggest
                list={stages}
                value={stage_id}
                showKey={"stage_contractor_name"}
                valueKey={"stage_id"}
                name={"stage_id"}
                placeholder="Выберите этап"
                label="Этапы"
                onChange={handleChange}
              />
            </Box>
          </Box>
        </CollapseBlock>
        <Box marginTop={'10px'}>
          <CustomTable
            stickyHeader
            isFetching={loading}
            isEmpty={!filteredList?.length}
            headItems={headerTitles}
          >
            {filteredList?.map((el, i) => (
              <CustomTableRow key={i}>
                <CustomTableCell>{el.remont_id}</CustomTableCell>
                <CustomTableCell>{el.contractor_name}</CustomTableCell>
                <CustomTableCell>{el.mp_fio}</CustomTableCell>
                <CustomTableCell>{el.stage_name}</CustomTableCell>
                <CustomTableCell>{el.stage_date}</CustomTableCell>
                <CustomTableCell>{el.resident_name}</CustomTableCell>
                <CustomTableCell>{el.flat_num}</CustomTableCell>
                <CustomTableCell>{el.area}</CustomTableCell>
              </CustomTableRow>
            ))}
          </CustomTable>
        </Box>
      </div>
    </div>
  );
};
