import React from "react";
import { LinearProgress } from "@material-ui/core";

export default function EmptyOrShow({ loading = true, show = true, children }) {
  function render() {
    return (
      <>
        {loading ? (
          <LinearProgress color="primary" />
        ) : (
          <>{show ? <>{children}</> : null}</>
        )}
      </>
    );
  }

  return <>{render()}</>;
}
