import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, DialogActions, FormControl } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { getContractors } from "../../../pages/remonts/services/api";
import { setMasterConstractor, setRemontsReport } from "../../../services/redux/actions/remonts-actions";
//Всплывашки сбоку
import { AutoSuggest } from "../../common/form/AutoSuggest";
import { useHttp } from "../../../utils/hooks/http.hook";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { CustomDialogTitle } from "../index";

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#0C4B86",
    minWidth: "400px"
  },
  content: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#9194A9",
    textAlign: "center"
  },
  add: {
    background: "#0C4B86",
    borderRadius: "4px",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.08em",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#4a81ed !important"
    },
    "&:disabled": { color: "#fff", backgroundColor: "#ccc" }
  },
  cancel: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.08em",
    color: "#0C4B86",
    border: " 1px solid #0C4B86",
    borderRadius: "4px"
  },
  subHeader: {
    background: " #ECEEFD",
    borderRadius: "4px 4px 0px 0px",
    color: "#9194A9",
    height: "50px",
    display: "flex",
    alignItems: "center"
  },
  id: {
    marginRight: "25px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.04em"
  },
  listItem: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  formControl: {
    width: "100%"
  }
}));

export const AddConstractor = ({ data, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading, request } = useHttp();
  const { addDefaultSnack, addCloseSnack } = useCustomSnackbar();
  const { constractors } = useSelector(state => state.remontsState);
  const [contractor_id, setContractor_id] = useState("");

  useEffect(() => {
    getContractors()
      .then(answer => {
        dispatch(setMasterConstractor(answer.data));
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [dispatch, addCloseSnack]);

  const handleSubmit = async () => {
    if (!contractor_id) return;

    const res = await request(
      `/partner/report_remonts/remont/${data.remontId}/`,
      "put",
      { contractor_id }
    );
    if (res) {
      dispatch(setRemontsReport(res));
      handleClose();
      addDefaultSnack("Подрядчик успешно изменен");
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <CustomDialogTitle>
          {"Назначить подрядчика на работу"}
        </CustomDialogTitle>
      </Box>
      <Box>
        <FormControl className={classes.formControl}>
          <AutoSuggest
            list={constractors}
            value={contractor_id}
            showKey={"contractor_name"}
            valueKey={"contractor_id"}
            placeholder="Выберите подрядчика"
            label="Выберите подрядчика"
            onChange={e => setContractor_id(e?.target?.value)}
            defaultValue={""}
          />
        </FormControl>

        <DialogActions
          style={{ justifyContent: "space-between", marginTop: "15px" }}
        >
          <Button
            autoFocus
            fullWidth
            className={classes.add}
            size="medium"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Подождите" : "Сохранить"}
          </Button>
          <Button
            onClick={handleClose}
            className={classes.cancel}
            size="medium"
            fullWidth
          >
            Отменить
          </Button>
        </DialogActions>
      </Box>
    </>
  );
};
