import React, { useState } from "react";
import styles from "./teams.module.scss";
import { PageTitle } from "../../common/pageTitle";
import { TeamsComponent } from "./Teams";
import { CustomTabPanel, CustomTabs } from "../../common/CustomTabs";
import { ExternalMasters } from "./detail/masters/ExternalMasters";
import { ServiceMasters } from "./detail/masters/ServiceMasters";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

export const Teams = () => {
  document.title = "Бригады";
  const [activeTabId, setActiveTabId] = useState(0);

  const handleTabChange = (e, id) => {
    setActiveTabId(id);
  };

  const tabs = [
    { label: "Список бригад" },
    { label: "Внешние мастера" },
    { label: "Мастера по услугам" }
  ];
  return (
    <>
      <PageTitle title={"Бригады"} />
      <div className={styles.teams}>
        <CustomTabs
          onChange={handleTabChange}
          value={activeTabId}
          tabs={tabs}
        />
        <CustomTabPanel value={activeTabId} index={0}>
          <TeamsComponent />
        </CustomTabPanel>
        <CustomTabPanel value={activeTabId} index={1}>
          <ExternalMasters />
        </CustomTabPanel>
        <CustomTabPanel value={activeTabId} index={2}>
          <ServiceMasters />
        </CustomTabPanel>
      </div>
    </>
  );
};

export const ActiveCheckbox = () => (
  <CheckBoxIcon
    style={{
      display: "inline-block",
      color: "#60cc48",
      fontSize: "18px"
    }}
  />
);
