import React, { useCallback, useEffect, useState } from "react";
import { getBrigadirCosts } from "../api";
import { getLastAndFirstDayOfMonth, numberWithCommas } from "../../../../utils";
import styles from "../payment-types.module.scss";
import { PageTitle } from "../../../common/pageTitle";
import { CostsTable } from "../costs/CostsTable";
import { getCostContractorTypesFilterData } from "../../../../services/redux/actions/accounting-actions";
import { useDispatch } from "react-redux";
import { BrigadirCostFilters } from "../filter/BrigadirCostsFilters";
import { CollapseBlock } from "../../../common/CollapseBlock/collapseBlock";

const {first, last} = getLastAndFirstDayOfMonth()
const filtersDefaultValue = {
  date_of_begin: first,
  date_of_end: last,
}
export const BrigadirCosts = () => {
  document.title = "Оплаты бригадира";
  const dispatch = useDispatch();
  const [cost, setCost] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    dispatch(getCostContractorTypesFilterData());
  }, [dispatch]);

  const getAmountSum = useCallback(arr => {
    try {
      const amount = arr?.reduce((prev, item) => prev + item?.cost_sum, 0) || 0;
      setAmount(amount ? Number(amount.toFixed(2)) : amount);
    } catch (e) {
    }
  }, []);

  const getCostData = useCallback(
    async filters => {
      try {
        setIsFetching(true);
        const res = await getBrigadirCosts(filters || filtersDefaultValue);
        setIsFetching(false);
        getAmountSum(res);
        setCost(res);
      } catch (e) {
        setIsFetching(false);
      }
    },
    [getAmountSum]
  );

  useEffect(() => {
    getCostData();
  }, [getCostData]);

  const handleFiltersSubmit = async options => {
    const filters = {};
    setCost([]);
    for (let key in options) {
      if (!!options[key] && options[key] !== "''") {
        filters[key] = options[key];
      }
    }
    return await getCostData(filters);
  };

  const title = `Оплаты (${numberWithCommas(amount)})`;
  return (
    <>
      <PageTitle title={title} />
      <div className={`${styles.accounting}`}>
        <div className={styles.accounting__content}>
          <CollapseBlock className={styles.accounting__header}>
            <BrigadirCostFilters
              handleSubmit={handleFiltersSubmit} defaultValues={filtersDefaultValue}
            />
          </CollapseBlock>
          <CostsTable
            isBrigadirCostsMode
            isTeamMode
            data={cost}
            actions={false}
            isFetching={isFetching}
          />
        </div>
      </div>
    </>
  );
};
