import React, { useEffect } from "react";
import { Button, SwipeableDrawer } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { makeStyles } from "@material-ui/core/styles";
import SideBarContent from "./SideBarContent";
import { useDispatch, useSelector } from "react-redux";
import {
  setSideBarContent,
  toggleDrawer
} from "../../services/redux/actions/problemsActions";

const useStyles = makeStyles(theme => ({
  sidebar_content: {
    width: 391,
    height: "100%",
    [theme.breakpoints.down(768)]: {
      width: "100%"
    }
  },
  menu_btn: {
    backgroundColor: "#fff",
    marginLeft: "auto",
    boxShadow: "0px 5px 10px rgba(108, 108, 108, 0.1)",
    borderRadius: 3,
    flexShrink: 0,
    color: "#0C4B86",
    "& .MuiButton-startIcon ": {
      backgroundColor: "#0C4B86",
      borderRadius: "50%",
      color: "#fff",
      padding: 5
    },
    "&:hover": {
      boxShadow: "none"
    }
  },
  drawer: {
    backgroundColor: "#F8FAFB",
    border: "1px solid #D2D3D9",
    boxShadow: "none",
    overflow: "hidden",
    [theme.breakpoints.down(768)]: {
      width: "100%"
    }
  }
}));

const ProblemsSideBar = () => {
  const { isDrawerOpen, card_id } = useSelector(state => state.problemsState);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = cond => {
    dispatch(toggleDrawer(cond));
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      dispatch(setSideBarContent("Меню", card_id));
    }
  }, [isDrawerOpen, dispatch, card_id]);

  return (
    <>
      <Button
        onClick={() => handleClick(true)}
        variant="contained"
        className={classes.menu_btn}
        startIcon={<MoreHorizIcon />}
      >
        Меню
      </Button>
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => handleClick(false)}
        onOpen={() => handleClick(true)}
        BackdropProps={{ invisible: true }}
        classes={{
          paper: classes.drawer
        }}
      >
        <div className={classes.sidebar_content}>
          <SideBarContent handleClick={handleClick} />
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default ProblemsSideBar;
