import React from "react";
import { Paper, Popper } from "@material-ui/core";

const Tooltip = ({ title, anchorEl }) => {
  return (
    <Popper
      style={{ zIndex: 10005 }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      placement="top-end"
    >
      <Paper style={{ padding: 5 }}>{title}</Paper>
    </Popper>
  );
};

// Usage
export const HtmlTooltip = ({ children, title }) => {
  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleTooltipOpen = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleTooltipClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
        {children}
      </div>
      <Tooltip anchorEl={anchorEl} title={title} />
    </div>
  );
};
