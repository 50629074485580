import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReportTable from "./reportTable";
import ReportFilter from "./reportFilter";
import { PageTitle } from "../../components/common/pageTitle";
import { reportTeachFeature } from "./api";

const useStyles = makeStyles(() => ({
  noPadding: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    padding: "20px"
  }
}));

export const ReportAddWorks = () => {
  document.title = "Отчет по тех.особенностям";
  const classes = useStyles();
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  const handleChange = resident_ids => {
    setIsFetching(true);
    reportTeachFeature({ resident_ids })
      .then(answer => {
        setIsFetching(false);
        setData(answer.data);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };
  return (
    <>
      <PageTitle title={"Отчет по тех.особенностям"} />
      <div className={classes.noPadding} style={{}}>
        <ReportFilter handleChange={handleChange} />
        <ReportTable data={data} isFetching={isFetching} />
      </div>
    </>
  );
};
