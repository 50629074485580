import React, { useEffect, useState } from "react";
import { getPackageData } from "../../services/api/api_app";
import { useNavigate, useParams } from "react-router";
import { PageTitle } from "../../components/common/pageTitle";
import styles from './styles.module.scss'
import { LinearProgress } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { showPopUp } from "../../services/redux/actions/app-actions";
import { MODALS_NAMES, PAGES_ROUTE_KEY } from "../../constants";
import { useDispatch } from "react-redux";
import { BackBtn } from "../../components/common/blocks";

export const OurWorksDetail = () => {
  const dispatch = useDispatch()
  const router = useNavigate()
  const { id } = useParams();
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true)
      const res = await getPackageData(Number(id));
      setIsFetching(false)
      if (!res) return;
      setData(res);
    };
    getData();
  }, [id]);

  const onImageClick = (idx) => {
    dispatch(showPopUp(true, MODALS_NAMES.gallery, {
      activeIndex: idx,
      images: data?.photo_list
    }))
  }

  const closeDetail = () => {
    router(PAGES_ROUTE_KEY.ourWorks)
  }
  return (
    <>
      <PageTitle title={data?.name || 'Готовый объект'} />
      <div className={styles.wrapper}>
        {isFetching && <LinearProgress />}

        <BackBtn onClick={closeDetail} padding mobile={false} />
        {data?.description && <div className={styles.desc} dangerouslySetInnerHTML={{ __html: data.description }}></div>}
        <div className={styles.photos}>
          {data.photo_list?.map((item, index) => {
            return (
              <LazyLoadImage
                effect="blur" wrapperClassName={styles.photos__item}
                key={item.id}
                src={"https://office.smartremont.kz" + item.img_mini_url}
                alt="package_img"
                onClick={() => onImageClick(index)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

