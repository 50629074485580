//ПОлучить выбранные работы
import instance from "../../services/api";

export const getSelectedWorks = () => {
  return instance(true).get("/partner/work_select/read_works/");
};
//ПОлучить список с этапами
export const getWorksList = () => {
  return instance(true).get("/partner/work_select/works_by_stages/");
};

//Удалить элемент
export const deleteWorkItem = (params = {}) => {
  return instance(true).post("/partner/work_select/work_item_delete/", params);
};

//Выбрать все

export const getAllWorks = () => {
  return instance(true).get("/partner/work_select/works_list/");
};

//Выбрать все

export const deleteAllWorks = () => {
  return instance(true).get("/partner/work_select/work_list_delete/");
};

//Добавить работу
export const addSelectedWork = (params = {}) => {
  return instance(true).post("/partner/work_select/works_item/", params);
};

//Взять цвет
export const getWorkColor = () => {
  return instance(true).get("/partner/work_select/works_colors/");
};

//Установить цвет
export const setWorkColor = (params = {}) => {
  return instance(true).post("/partner/work_select/works_colors_up/", params);
};
