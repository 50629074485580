import React from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, TableCell, TableRow } from "@material-ui/core";

import NumberFormat from "react-number-format";
import clsx from "clsx";
import { showPopUp } from "../../services/redux/actions/app-actions";
import { MODALS_NAMES } from "../../constants";

const useStyles = makeStyles({
  table_row: {
    backgroundColor: "#fff",
    borderLeft: "1px solid #F0F2F6 !important"
  },
  cell: {
    fontSize: 12,
    lineHeight: "16px",
    borderRight: "1px solid #F0F2F6 !important",
    borderBottom: "1px solid #F0F2F6 !important",
    padding: 5
  },
  comment: {
    maxWidth: 150
  },
  status_button: {
    display: "flex",
    gap: "5px"
  },
  accept: {
    color: "#fff",
    backgroundColor: "#81CD67FF",

    "&:hover": {
      backgroundColor: "#81CD67FF"
    }
  },
  reject: {
    color: "#fff",
    backgroundColor: "#FF6262",

    "&:hover": {
      backgroundColor: "#FF6262"
    }
  }
});

const TableRowComp = ({
  setPenalty,
  penalty_id,
  remont_id,
  resident_name,
  flat_num,
  contractor_name,
  penalty_type_name,
  stage_name,
  penalty_amount,
  rowversion,
  is_accept,
  acceptance_comment,
  acceptance_date,
  executor_fio
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const accept_buttons = () => {
    if (is_accept === true) {
      return (
        <Button
          className={classes.accept}
          variant="contained"
          disableElevation={true}
          disabled={true}
        >
          Принять
        </Button>
      );
    } else if (is_accept === false) {
      return (
        <Button
          className={classes.reject}
          variant="contained"
          disableElevation={true}
          disabled={true}
        >
          {!is_accept ? "Не принят" : "Не принимать"}
        </Button>
      );
    } else {
      return (
        <>
          <Button
            className={classes.accept}
            variant="contained"
            disableElevation={true}
            onClick={() =>
              dispatch(
                showPopUp(true, MODALS_NAMES.penaltyComment, {
                  isAccept: true,
                  id: penalty_id,
                  setPenalty: setPenalty
                })
              )
            }
          >
            Принять
          </Button>
          <Button
            className={classes.reject}
            variant="contained"
            disableElevation={true}
            onClick={() =>
              dispatch(
                showPopUp(true, MODALS_NAMES.penaltyComment, {
                  isAccept: false,
                  id: penalty_id,
                  setPenalty: setPenalty
                })
              )
            }
          >
            Не принимать
          </Button>
        </>
      );
    }
  };

  return (
    <TableRow className={classes.table_row}>
      <TableCell align="right" className={classes.cell}>
        {penalty_id}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {remont_id}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {resident_name}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        {flat_num}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {contractor_name}
      </TableCell>
      <TableCell
        align="left"
        className={classes.cell}
        style={{
          textDecoration: "underline",
          cursor: "pointer"
        }}
        onClick={() =>
          dispatch(
            showPopUp(true, MODALS_NAMES.penaltyDetails, {
              id: penalty_id
            })
          )
        }
      >
        {penalty_type_name}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {stage_name}
      </TableCell>
      <TableCell align="right" className={classes.cell}>
        <NumberFormat
          value={penalty_amount}
          displayType={"text"}
          thousandSeparator={"."}
          decimalSeparator={","}
        />
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        {rowversion}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {executor_fio}
      </TableCell>
      <TableCell align="left" className={clsx(classes.cell, classes.comment)}>
        {acceptance_comment}
      </TableCell>
      <TableCell align="center" className={clsx(classes.cell, classes.comment)}>
        {acceptance_date}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        <Box className={classes.status_button}>{accept_buttons()}</Box>
      </TableCell>
    </TableRow>
  );
};

export default TableRowComp;
