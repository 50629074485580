import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { setSideBarContent } from "../../services/redux/actions/problemsActions";
import { useDispatch } from "react-redux";
import ArchiveIcon from "@material-ui/icons/Archive";
import RestoreIcon from "@material-ui/icons/Restore";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: 15,
    border: "0.5px solid #E2E3ED",
    borderRadius: 4,
    marginBottom: 10,
    cursor: "pointer"
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: "#9194A9"
  },
  icon: {
    "& svg": {
      width: 20,
      height: 20
    }
  }
});

const iconStyles = {
  marginRight: 12,
  color: "#0C4B86",
  display: "inline-flex",
  fontSize: "22px"
};
const MenuItem = ({ title }) => {
  const classes = useStyles();
  const getIcon = title => {
    switch (title) {
      case "Архив":
        return <ArchiveIcon style={iconStyles} className={classes.icon} />;
      case "История":
        return <RestoreIcon style={iconStyles} />;
      default:
        break;
    }
  };
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setSideBarContent(title));
  };
  return (
    <Box className={classes.wrapper} onClick={handleClick}>
      {getIcon(title)}
      <Typography className={classes.title}>{title}</Typography>
    </Box>
  );
};

export default MenuItem;
