import React from "react";
import { ComplexesList } from "../remonts/complexesList/complexesList";
import { PAGES_ROUTE_KEY } from "../../constants";

export const RemontsList = () => {
  document.title = "Список ремонтов";
  return (
    <ComplexesList
      pageTitle={"Список ремонтов"}
      remontRoute={PAGES_ROUTE_KEY.remontInfo}
    />
  );
};

export default RemontsList;
