import instance from "../../../services/api";

export const externalWorksetMastersAPI = {
  async getRemontAmount(remont_id) {
    return await instance(true)
      .get(`/partner/remont/${remont_id}/amount/`)
      .then(res => res?.data);
  },
  async getExternalMasters(remont_id) {
    return await instance(true)
      .get(`/partner/remont/${remont_id}/external_masters/read/`)
      .then(res => res?.data);
  },
  async getRemontServiceMasters(remont_id) {
    return await instance(true)
      .get(`/partner/remont/${remont_id}/remont_masters/service/read/`)
      .then(res => res?.data);
  },
  async addMasterToWorkset(remont_id, body) {
    return await instance(true)
      .post(`/partner/remont/${remont_id}/external_masters/add/`, body)
      .then(res => res?.data);
  },
  async getBrigadaInfo(remont_id) {
    return await instance(true)
      .get(`/partner/remont/${remont_id}/preset/`)
      .then(res => res?.data);
  }
};
