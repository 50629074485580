import React from "react";
import SideBarHeader from "./SideBarHeader";
import { Box } from "@material-ui/core";
import MenuItem from "./MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  sidebar: {
    padding: 16
  },
  menu_items: {
    paddingBottom: 20,
    marginBottom: 20,
    borderBottom: "1px solid #F0F2F6"
  },
  changes: {
    height: "58vh",
    overflow: "auto",
    marginTop: 20
  }
});

const MenuSideBar = ({ handleClick }) => {
  const classes = useStyles();
  return (
    <Box>
      <SideBarHeader title="Меню" handleClick={handleClick} />

      <Box className={classes.sidebar}>
        <Box className={classes.menu_items}>
          <MenuItem title="Архив" />
          <MenuItem title="История" />
        </Box>
      </Box>
    </Box>
  );
};

export default MenuSideBar;
