import instance from "../../services/api";

export const getReportMasterPayments = () => {
  return instance(true)
    .get(`/partner/report/contractor_cost/master_payment/`)
    .then((res = {}) => res?.data);
};

export const getReportMasterPaymentsData = async () => {
  try {
    const res = await getReportMasterPayments()
    return res.data
  } catch (e) {}
}
