import React, { useCallback, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import { fetchContractList } from "./api";
import ContractJournalBody from "./ContractJournalBody";
import ContactJournalHead from "./ContactJournalHead";
import ContractJournalHeader from "./ContractJournalHeader";
import EmptyOrShow from "../../components/common/EmptyOrShow";
import { PageTitle } from "../../components/common/pageTitle";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

export const TableContext = React.createContext();

export const TableProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const value = [data, setData, searchTerm, setSearchTerm];
  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
};

const headerTitles = [
  "ID документа",
  "ID Ремонта",
  "ЖК",
  "Квартира",
  "Наименование документа",
  "Сумма",
  "Инициатор",
  "Контрагент",
  "Статус",
  "Документы"
];
const useStyles = makeStyles({
  body: {
    padding: 20
  },
  wrapper: {
    overflow: "auto"
  }
});
export const ContractJournal = () => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    remont_id: "",
    contractor_agreement_list_status_id: "",
    contractor_id: ""
  });
  const [savedFilters, setSavedFilters] = useState({
    remont_id: "",
    contractor_id: ""
  });
  const [loading, setLoading] = useState(false);

  const getContractorList = useCallback(
    async (params = {}) => {
      setLoading(true);
      try {
        const res = await fetchContractList(params);
        setLoading(false);
        setData(res.data);
      } catch (e) {
        setLoading(false);
        const message = e?.response?.data?.error;
        message && addCloseSnack(message);
      }
    },
    [addCloseSnack]
  );

  useEffect(() => {
    getContractorList();
  }, [getContractorList]);

  const onFiltersChange = e => {
    setFilters(prev => ({
      ...prev,
      [e?.target.name]: Number(e?.target?.value) || ""
    }));
  };

  const getDataByParams = useCallback(async () => {
    if (filters.contractor_id || filters.remont_id) {
      setSavedFilters(filters);
      await getContractorList(filters);
    }
  }, [filters, getContractorList]);

  return (
    <>
      <PageTitle title={"Журнал договоров"} />
      <Box className={classes.body}>
        <TableProvider>
          <ContractJournalHeader
            getDataByParams={getDataByParams}
            filters={filters}
            onFiltersChange={onFiltersChange}
            loading={loading}
          />
          <EmptyOrShow loading={loading}>
            <div className={classes.wrapper}>
              <ContactJournalHead headerTitles={headerTitles} />
              <ContractJournalBody
                filters={savedFilters}
                data={data}
                setData={setData}
              />
            </div>
          </EmptyOrShow>
        </TableProvider>
      </Box>
    </>
  );
};




