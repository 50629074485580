import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { useSelector } from "react-redux";
import { CButton } from "../../components/common/buttons";
import { Navigate, useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { InputWarning } from "../../components/common/form/warnings";
import { Logo } from "../../components/common/Logo";
import { PAGES } from "../../constants";
import { CLink } from "../../components/common/blocks/CustomLink";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { requestNewPassword } from "./services";
import { useAuthStyles } from "./login";

export const ForgetPage = () => {
  const classes = useAuthStyles();
  const { addDefaultSnack } = useCustomSnackbar();
  const history = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm();
  const { isUserAuthenticated } = useSelector(state => state.appState);
  const [loading, setLoading] = useState(false);

  const onSubmit = async body => {
    setLoading(true);
    const res = await requestNewPassword(body);
    setLoading(false);
    if (!res?.status) return;
    addDefaultSnack("Новый пароль был отправлен на указанную почту");
    history(PAGES.login);
  };

  if (isUserAuthenticated) return <Navigate to={PAGES.main} replace={true} />;
  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{ backgroundColor: "#fff", textAlign: "center" }}
    >
      <div className={classes.paper}>
        <Logo className={classes.logo} alt="" size={"large"} />
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <div>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  value={value}
                  id="email"
                  label="Email"
                  autoComplete="email"
                  style={{ color: "#818181", fontSize: 18 }}
                  onChange={onChange}
                />
              )}
              rules={{ required: true }}
              defaultValue={""}
            />
            {errors.email && <InputWarning>Это поле обязательно</InputWarning>}
          </div>
          <div className={classes.link}>
            <CLink to={PAGES.login}>Войти</CLink>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <CButton
              type="submit"
              loading={loading}
              disabled={loading}
              className={classes.submit}
              onClick={onSubmit}
            >
              Отправить
            </CButton>
          </div>
        </form>
      </div>
    </Container>
  );
};

