import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cancelGroupedPayment, getGroupedMastersPayments, makeMastersPayment } from "../services";
import { CustomTable, CustomTableCell, CustomTableRow } from "../../../components/common/CustomTable/CustomTable";
import { isChrome, numberWithCommas } from "../../../utils";
import { PageTitle } from "../../../components/common/pageTitle";
import styles from "../styles.module.scss";
import { Collapse } from "@material-ui/core";
import { LoaderData } from "../../../components/common/LoaderData";
import { NotFound } from "../../../components/common/text";
import Checkbox from "@material-ui/core/Checkbox";
import { CButton } from "../../../components/common/buttons";
import { useDispatch, useSelector } from "react-redux";
import { getIntegratedPaymentTypesData } from "../../../services/redux/actions/app-actions";
import { AutoSuggest } from "../../../components/common/form/AutoSuggest";
import { Alert } from "@material-ui/lab";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { makeStyles } from "@material-ui/core/styles";
import { MasterPaymentsFilters } from "../filters";
import { CustomConfirm } from "../../../components/common/blocks/CustomConfirm";
import CloseIcon from "@material-ui/icons/Close";
import { Amount } from "../../../components/common/blocks/Amount";
import { DEFAULT_WALLET } from "../../../services";
import { CollapseBlock } from "../../../components/common/CollapseBlock/collapseBlock";
import clsx from "clsx";

const useStyles = makeStyles({
  checkbox: {
    color: "#0C4B86"
  }
});
export const GroupedMastersPayments = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const [payments, setPayments] = useState([]);
  const [collapse, setCollapse] = useState({});
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingPayments, setLoadingPayments] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [fetchingTypes, setFetchingTypes] = useState(false);
  const [filters, setFilters] = useState({ integrated_payment_type_code: DEFAULT_WALLET });
  const { integratedPaymentTypes } = useSelector(state => state.appState);

  useEffect(() => {
    dispatch(getIntegratedPaymentTypesData(setFetchingTypes));
  }, [dispatch]);

  const getData = useCallback(async () => {
    setIsFetching(true);
    const res = await getGroupedMastersPayments(filters);
    setIsFetching(false);
    setPayments(res || []);
  }, [filters]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleCollapse = (id, e) => {
    if (e.target.type === "checkbox") return;
    setCollapse(prev => ({ ...prev, [id]: !prev[id] }));
  };
  const handleSelect = (contractor_id, contractor_cost_id, value, ids) => {
    if (contractor_cost_id === null) {
      if (value) {
        const payment = payments.find(
          item => item.contractor_id === contractor_id
        );
        const sum = payment.contractor_costs.reduce(
          (sum, cur) => sum + cur.cost_sum_with_tax,
          0
        );
        if (payment.balance < sum)
          return addCloseSnack("Сумма платежей превышает баланс");
      }
      setSelected(prevState => ({
        ...prevState,
        [contractor_id]: value ? ids : []
      }));
    } else {
      if (value) {
        const payment = payments.find(
          item => item.contractor_id === contractor_id
        );
        const sum = getCollectedSum(
          contractor_id,
          payment.contractor_costs,
          contractor_cost_id
        );
        if (payment.balance < sum)
          return addCloseSnack("Сумма платежей превышает баланс");

        setSelected(prevState => ({
          ...prevState,
          [contractor_id]: prevState[contractor_id]
            ? [...prevState[contractor_id], contractor_cost_id]
            : [contractor_cost_id]
        }));
      } else {
        setSelected(prevState => ({
          ...prevState,
          [contractor_id]: prevState[contractor_id]
            ? prevState[contractor_id].filter(id => id !== contractor_cost_id)
            : []
        }));
      }
    }
  };

  const handlePay = async () => {
    setLoading(true);
    const res = await makeMastersPayment(selected, filters);
    if (!res || !res?.redirect_url) return setLoading(false);
    if (isChrome()) {
      setLoading(false);
      return window.open(res?.redirect_url);
    }
    window.location.href = res?.redirect_url;
  };

  const cancelPayment = async contractor_cost_id => {
    if (!contractor_cost_id) return;
    setLoadingPayments(prevState => ({
      ...prevState,
      [contractor_cost_id]: true
    }));
    const res = await cancelGroupedPayment({ contractor_cost_id }, filters);
    setLoadingPayments(prevState => ({
      ...prevState,
      [contractor_cost_id]: false
    }));
    if (!res) return;
    addDefaultSnack();
    setPayments(res || []);
  };

  const isPaymentBtnAvailable = useMemo(() => {
    return !!Object.keys(selected).find(item => !!selected[item]?.length);
  }, [selected]);

  const onChange = (key, value) => {
    if (!value) return;
    setFilters(prev => ({ ...prev, [key]: value || "" }));
  };

  const getCollectedSum = (contractor_id, costs, new_contractor_id) => {
    let contractorIds = [];
    if (selected[contractor_id]?.length)
      contractorIds = [...selected[contractor_id]];
    if (new_contractor_id)
      contractorIds = [...contractorIds, new_contractor_id];
    let sum = 0;
    if (costs)
      costs.forEach(item => {
        if (contractorIds.includes(item.contractor_cost_id))
          sum += item.cost_sum_with_tax;
      });
    return sum;
  };

  const onFiltersChange = (filters = {}) =>
    setFilters(prev => ({ ...prev, ...filters }));
  const handleCheckChange = (e, item) => {
    e.stopPropagation();
    handleSelect(
      item.contractor_id,
      null,
      e?.target?.checked,
      item.contractor_costs?.map(
        item => item.contractor_cost_id
      )
    );
  };


  return (
    <div>
      <PageTitle title={"Оплаты мастерам"} description={"(Ожидают оплаты)"} />
      {!filters.integrated_payment_type_code && (
        <Alert severity="warning">Не выбран кошелек!</Alert>
      )}
      <div className={styles.content}>
        <CollapseBlock className={styles.header}>
          <div className={clsx(styles.filters, styles.header)}>
            <AutoSuggest
              list={integratedPaymentTypes}
              showKey={"integrated_payment_type_name"}
              valueKey={"integrated_payment_type_code"}
              value={filters?.integrated_payment_type_code}
              onChange={e =>
                onChange("integrated_payment_type_code", e.target.value)
              } loading={fetchingTypes}
              placeholder="Выберите кошелек"
              label="Кошелек"
              defaultValue={""}
            />
          </div>
          <CButton
            onClick={handlePay}
            loading={loading}
            disabled={!isPaymentBtnAvailable}
            mode={"auto"}
          >
            Подписать и оплатить
          </CButton>
          <div className={styles.margin}>
            <MasterPaymentsFilters
              getData={onFiltersChange}
              disabled={!filters.integrated_payment_type_code}
            />
          </div>
        </CollapseBlock>
        <CustomTable
          headItems={headItems}
          isFetching={isFetching}
          isEmpty={!payments?.length}
          withMaxHeight={false}
          noDataText={
            filters.integrated_payment_type_code
              ? "Ничего не найдено"
              : "Выберите кошелек"
          }
        >
          {payments?.map(item => {
            return (
              <React.Fragment key={item.contractor_id}>
                <CustomTableRow
                  onClick={(e) => handleCollapse(item.contractor_id, e)} className={"pointer"}>
                  <CustomTableCell align={"center"}>
                    <Checkbox
                      color={"primary"}
                      className={classes.checkbox}
                      disabled={
                        !item.contractor_costs?.length || item.balance === null
                      }
                      checked={
                        item.contractor_costs?.length > 0 &&
                        selected[item.contractor_id]?.length ===
                        item.contractor_costs?.length
                      }
                      indeterminate={
                        selected[item.contractor_id]?.length > 0 &&
                        selected[item.contractor_id]?.length !==
                        item.contractor_costs?.length
                      }
                      onChange={e => handleCheckChange(e, item)}
                    />
                  </CustomTableCell>
                  <CustomTableCell>
                    <strong
                      className={"pointer"}
                    >{`${item.contractor_name} (Баланс: ${numberWithCommas(
                      item.balance,
                      "Не зарегистрировано"
                    )}) ${
                      getCollectedSum(item.contractor_id, item.contractor_costs)
                        ? `Собрано: ${numberWithCommas(
                          getCollectedSum(
                            item.contractor_id,
                            item.contractor_costs
                          )
                        )}`
                        : ""
                    }`}</strong>
                  </CustomTableCell>
                  <CustomTableCell align={"right"}>
                    <strong>{numberWithCommas(item.sum)}</strong>
                  </CustomTableCell>
                  <CustomTableCell align={"right"}>
                    {item.count}
                  </CustomTableCell>
                </CustomTableRow>
                <CustomTableRow isBinaryDiv={false}>
                  <CustomTableCell
                    openTab={!!collapse[item.contractor_id]}
                    colSpan={15}
                  >
                    <Collapse
                      in={collapse[item.contractor_id]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {isFetching ? (
                        <LoaderData small />
                      ) : !!item.contractor_costs?.length ? (
                        <div className={styles.collapse__content}>
                          <CustomTable headItems={childrenDeadItems}>
                            {item.contractor_costs?.map(cost => {
                              return (
                                <CustomTableRow key={cost?.contractor_cost_id}>
                                  <CustomTableCell align={"center"}>
                                    <Checkbox
                                      color={"primary"}
                                      disabled={item.balance === null}
                                      className={classes.checkbox}
                                      checked={
                                        !!selected[
                                          item.contractor_id
                                          ]?.includes(cost?.contractor_cost_id)
                                      }
                                      onChange={e =>
                                        handleSelect(
                                          item.contractor_id,
                                          cost?.contractor_cost_id,
                                          e?.target?.checked
                                        )
                                      }
                                    />
                                  </CustomTableCell>
                                  <CustomTableCell>
                                    {cost.team_master_fio}
                                  </CustomTableCell>
                                  <CustomTableCell align={"center"}>
                                    {cost.cost_date}
                                  </CustomTableCell>
                                  <CustomTableCell align={"right"}>
                                    {cost?.remont_id}
                                  </CustomTableCell>
                                  <CustomTableCell>
                                    {cost.address}
                                  </CustomTableCell>
                                  <CustomTableCell
                                    style={
                                      cost.error
                                        ? { background: "#ff8f8f" }
                                        : {}
                                    }
                                  >
                                    {cost.error}
                                  </CustomTableCell>
                                  <CustomTableCell align={"left"}>
                                    {cost?.comment}
                                  </CustomTableCell>
                                  <CustomTableCell
                                    align={"right"}
                                  >
                                    <Amount value={numberWithCommas(cost?.cost_sum)} />
                                  </CustomTableCell>
                                  <CustomTableCell
                                    align={"right"}
                                  >
                                    <Amount value={numberWithCommas(cost?.tax_sum)} />
                                  </CustomTableCell>
                                  <CustomTableCell
                                    align={"right"}
                                  >
                                    <Amount value={numberWithCommas(cost?.cost_sum_with_tax)} />
                                  </CustomTableCell>
                                  <CustomTableCell align={"center"}>
                                    <CustomConfirm
                                      title={
                                        "Вы действительно хотите отменить платеж?"
                                      }
                                      loading={
                                        loadingPayments[
                                          cost?.contractor_cost_id
                                          ]
                                      }
                                      onSubmit={() =>
                                        cancelPayment(cost?.contractor_cost_id)
                                      }
                                    >
                                      <CButton
                                        className={styles.collapse__btn}
                                        mode={"auto"}
                                        disabled={
                                          !!loadingPayments[
                                            cost?.contractor_cost_id
                                            ]
                                        }
                                      >
                                        <CloseIcon />
                                      </CButton>
                                    </CustomConfirm>
                                  </CustomTableCell>
                                </CustomTableRow>
                              );
                            })}
                          </CustomTable>
                        </div>
                      ) : (
                        <NotFound />
                      )}
                    </Collapse>
                  </CustomTableCell>
                </CustomTableRow>
              </React.Fragment>
            );
          })}
        </CustomTable>
      </div>
    </div>
  );
};

const headItems = [
  { label: "", style: { width: "60px" } },
  { label: "Подрядчик", align: "left" },
  { label: "Сумма", align: "right" },
  { label: "Кол-во", align: "right" }
];
const childrenDeadItems = [
  { label: "", style: { width: "60px" } },
  { label: "ФИО" },
  { label: "Дата создания", align: "center" },
  { label: "ID ремонта", align: "right" },
  { label: "Адрес" },
  { label: "Ошибка" },
  { label: "Комментарии" },
  { label: "Сумма", align: "right" },
  { label: "Комиссия", align: "right" },
  { label: "Сумма с комиссией", align: "right" },
  { label: "Отменить", align: "center" }
];
