import instance from "../../services/api";

export const fetchContractList = params => {
  return instance(true)
    .post(`/partner/contractor_agreement_list/`, params)
    .then((res = {}) => {
      return res;
    });
};

export const getSpecificationXml = params => {
  return instance(true)
    .post(`/partner/contractor_agreement_list/specification/xml/`, params)
    .then((res = {}) => {
      return res;
    });
};
export const getAccessionXml = body => {
  return instance(true)
    .post(`/partner/contractor_agreement_list/accession_agreement/xml/`, body)
    .then((res = {}) => {
      return res;
    });
};
export const signSpecificationXml = params => {
  return instance(true)
    .post(`/partner/contractor_agreement_list/specification/sign/`, params)
    .then((res = {}) => {
      return res;
    });
};
export const signAccessionXml = params => {
  return instance(true)
    .post(
      `/partner/contractor_agreement_list/accession_agreement/sign/`,
      params
    )
    .then((res = {}) => {
      return res;
    });
};
