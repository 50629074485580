import React, { useEffect, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import { getDetailInfo } from "./api";
import { Box } from "@material-ui/core";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 5,
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)",
    borderRadius: 4
  },
  row_td: {
    borderRight: "1px solid #F0F2F6"
  },
  cell: {
    borderLeft: "1px solid #F0F2F6",
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 120,
    padding: 5
  },
  cell_first: {
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 75,
    padding: 5
  },
  header: {
    verticalAlign: "top",
    fontSize: 20,
    color: "#0C4B86",
    fontWeight: 600
  },
  head: {
    borderLeft: "1px solid #F0F2F6",
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 150,
    color: "#A5A7B5",
    padding: 5
  },
  head_first: {
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 100,
    color: "#A5A7B5",
    padding: 5
  },
  dop_zayavka: {
    color: "#0C4B86",
    fontSize: 12
  }
}));
const ReportMaterialModal = ({ data }) => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    getDetailInfo(data.remont_id, {
      material_type_id: data.material_type_id
    })
      .then(res => {
        setMaterials(res.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [addCloseSnack, data.remont_id, data.material_type_id]);

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.head_first} align="left">
              Номер заявки
            </TableCell>
            <TableCell className={classes.head} align="left">
              Тип
            </TableCell>
            <TableCell className={classes.head} align="left">
              Поставщик
            </TableCell>
            <TableCell className={classes.head} align="left">
              Название материала
            </TableCell>
            <TableCell className={classes.head} align="left">
              Статус
            </TableCell>{" "}
            <TableCell className={classes.head} align="left">
              Дата
            </TableCell>{" "}
            <TableCell className={classes.head} align="left">
              Количество
            </TableCell>{" "}
            <TableCell className={classes.head} align="left">
              Количество (ед. изм)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {materials?.map(el => (
            <TableRow key={el.provider_request_item_id}>
              <TableCell className={classes.cell_first} align="right">
                {el.provider_request_item_id}
              </TableCell>
              <TableCell className={classes.cell} align="left">
                {el.type}{" "}
                {el.reason_name ? (
                  <Box className={classes.dop_zayavka}>Доп заявка</Box>
                ) : null}
              </TableCell>
              <TableCell className={classes.cell} align="left">
                {el.provider_name}
              </TableCell>
              <TableCell className={classes.cell} align="left">
                {el.material_name}
              </TableCell>{" "}
              <TableCell className={classes.cell} align="center">
                {el.status_name}
              </TableCell>{" "}
              <TableCell className={classes.cell} align="center">
                {el.date_shipping}
              </TableCell>{" "}
              <TableCell className={classes.cell} align="center">
                {el.qty_atom} {el.atom_unit_name}
              </TableCell>{" "}
              <TableCell className={classes.cell} align="center">
                {el.qty_consume} {el.consume_unit_name}
              </TableCell>{" "}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportMaterialModal;
