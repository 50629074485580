import React, { useState } from "react";
import { Avatar, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { changeComment, deleteComment, deleteFile } from "./api";
import LocalAddedFile from "./LocalAddedFile";
import ProblemCommentBox from "./ProblemCommentBox";
import CommentsFile from "./CommentsFile";
import { source } from "../../services/api";
import axios from "axios";
import { useSelector } from "react-redux";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

export const useStyles = makeStyles({
  modal: {
    maxWidth: 875,
    width: "100%",
    padding: 25
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 25
  },
  header_title: {
    color: "#0C4B86",
    fontSize: 18,
    fontWeight: 500
  },
  content: {
    display: "flex",
    justifyContent: "space-between",

    marginBottom: 10
  },
  content_title: {
    fontSize: 12,
    fontWeight: 600,
    color: "#494C62",
    lineHeight: "15px"
  },
  main_content: {
    maxWidth: 582,
    width: "100%",
    marginBottom: 25
  },
  aside_content: {
    width: 168
  },
  problem_info: {
    paddingLeft: 30,
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  select_box: {
    maxWidth: 256,
    width: "48%",
    marginBottom: 25
  },
  label: {
    color: "#9194A9",
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 11,
    display: "block"
  },
  select: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: "0.01em",
    color: "#303030",
    lineHeight: 1.5,
    width: "100%"
  },
  text_field: {
    width: "100%"
  },
  aside_btn: {
    fontSize: 12,
    fontWeight: 500,
    color: "#494C62",
    padding: "8px 12px",
    backgroundColor: "#EAECF0",
    borderRadius: 4,
    marginBottom: 10,
    maxWidth: 168,
    width: "100%",
    height: 30,
    textAlign: "left",
    textTransform: "none !important"
  },
  text_area: {
    width: "100%",
    border: "1px solid #E2E3ED",
    borderRadius: 4,
    backgroundColor: "#fff",
    fontSize: 14,
    color: "#494C62",
    outline: "none",
    letterSpacing: "0.01em",
    resize: "none",
    whiteSpace: "pre-wrap"
  },
  comment_box: {
    display: "flex"
  },
  avatar: {
    marginRight: 15,
    width: 30,
    height: 30
  },
  name: {
    display: "inline-block",
    marginRight: 10,
    fontSize: 12,
    fontWeight: 600,
    color: "#494C62"
  },
  date: {
    display: "inline-block",
    fontSize: 11,
    fontWeight: 400,
    color: "#9194A9"
  },
  edit: {
    fontSize: 11,
    fontWeight: 500,
    color: "#9194A9",
    display: "inline-block",
    marginRight: 10,
    textDecoration: "underline",
    cursor: "pointer"
  },
  text_area_wrapper: {
    border: "1px solid #E2E3ED",
    borderRadius: 4,
    padding: 10
  },
  text_area_btn: {
    backgroundColor: "#0C4B86",
    padding: "7px 10px",
    height: 30,
    textAlign: "center",
    color: "#fff",
    borderRadius: 4,
    boxShadow: "none",
    marginRight: 20
  },
  close_icon: {
    color: "#0C4B86",
    transform: "translateY(8px)",
    cursor: "pointer"
  }
});
const CommentsBox = ({
  fio,
  rowversion,
  comments,
  comment_id,
  card_id,
  setState,
  files,
  state,
  options,
  width,
  setWidth
}) => {
  const classes = useStyles();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const [text, setText] = useState(comments);
  const [isEditable, setIsEditable] = useState(false);
  const [file, setFile] = useState([]);
  const [addedFiles, setAddedFiles] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const { show } = useSelector(state => state.appState.popup);

  let fd = new FormData();
  const handleSaveComment = () => {
    fd.append("comments", text);
    for (const key of Object.keys(file)) {
      fd.append("files", file[key]);
    }
    setIsDisabled(true);

    changeComment(card_id, comment_id, fd, options)
      .then(res => {
        setState({
          ...state,
          comment: res?.data
        });
        addDefaultSnack("Комментарий успешно изменен");
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          if (show) {
            setIsDisabled(false);
            setAddedFiles([]);
            setIsEditable(false);
            setFile([]);
            setWidth(0);
          }
        } else {
          addCloseSnack(err.response.data.error);
        }
        setText(comments);
      })
      .finally(() => {
        if (show) {
          setIsDisabled(false);
          setAddedFiles([]);
          setIsEditable(false);
          setFile([]);
          setWidth(0);
        }
      });
  };
  const handleRemoveComment = () => {
    if (window.confirm("Вы действительно хотите удалить комментарий?")) {
      deleteComment(card_id, comment_id)
        .then(res => {
          setState({
            ...state,
            comment: res?.data
          });
          addDefaultSnack("Комментарий успешно удален");
        })
        .catch(err => {
          addCloseSnack(err?.response?.data?.error);
        });
    }
  };
  const handleCloseIconClick = () => {
    source.cancel();
    setIsEditable(false);
    setText(comments);
    setAddedFiles([]);
    setFile([]);
  };
  const handleUploadChange = e => {
    setFile(e.target.files);
    const filesNames = Object.values(e.target.files).map(el => el.name);
    setAddedFiles(filesNames);
  };
  const handleEditClick = () => {
    setIsEditable(true);
  };
  const handleChange = e => setText(e.target.value);
  const handleDelete = (card_id, card_file_id) => {
    deleteFile(card_id, card_file_id)
      .then(res => {
        setState({
          ...state,
          comment: res.data
        });
        addDefaultSnack("Файл успешно удален");
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  };

  return (
    <Box className={classes.comment_box}>
      <Avatar className={classes.avatar} />
      <Box style={{ width: "100%", marginBottom: 15 }}>
        <Typography className={classes.name}>{fio}</Typography>
        <Typography className={classes.date}>{rowversion}</Typography>
        {isEditable ? (
          <ProblemCommentBox
            handleChange={handleChange}
            value={text}
            disabled={isDisabled}
            handleClick={handleSaveComment}
            handleClose={handleCloseIconClick}
            handleUpload={handleUploadChange}
          />
        ) : (
          <Typography className={classes.text_area} style={{ padding: 10 }}>
            {comments}
          </Typography>
        )}

        {isEditable ? (
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            {addedFiles?.map((el, i) => (
              <div
                key={i}
                style={{ maxWidth: 170, width: "100%", marginRight: 15 }}
              >
                <LocalAddedFile width={width} name={el} />
              </div>
            ))}
          </Box>
        ) : null}

        {files?.map(el => {
          return (
            <CommentsFile
              key={el.card_file_id}
              {...el}
              handleDelete={handleDelete}
            />
          );
        })}

        {isEditable ? null : (
          <Box>
            <Typography className={classes.edit} onClick={handleEditClick}>
              Изменить
            </Typography>
            <Typography className={classes.edit} onClick={handleRemoveComment}>
              Удалить
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CommentsBox;
