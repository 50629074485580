import { getDetailIncome, getIncome } from "../api";

export const getIncomeDataService = async (
  remont_id,
  filters,
  alternative,
  setIsFetching
) => {
  try {
    setIsFetching(true);
    const res = alternative
      ? await getDetailIncome(remont_id)
      : await getIncome(filters || {});
    setIsFetching(false);
    return res || [];
  } catch (e) {
    setIsFetching(false);
    return [];
  }
};
