import instance from "../../services/api";

export const reportDelivery = (params = {}) => {
  return instance(true).post("/partner/delivery_report/", params);
};

export const getJKList = () => {
  return instance(true).get("/partner/read_resident_by_contractor/");
};

export const getDetailInfo = (remont_id, params = {}) => {
  return instance(true).post(
    `/partner/delivery_report/remont/${remont_id}/`,
    params
  );
};
