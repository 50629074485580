import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import MessageFile from "./MessageFile";

const useStyles = makeStyles({
  message_wrapper: {
    display: "flex",
    marginBottom: 35
  },
  message_box: {
    marginLeft: 15,
    maxWidth: "75%",
    width: "100%"
  },
  avatar: {
    width: 30,
    height: 30,
    "& .MuiAvatar-fallback": {
      width: "100%",
      height: "100%"
    }
  },
  name: {
    display: "inline-block",
    fontSize: 14,
    fontWeight: 600,
    color: "#494C62",
    lineHeight: "15px",
    marginRight: 9
  },
  date: {
    display: "inline-block",
    fontSize: 13,
    fontWeight: 400,
    color: "#9194A9",
    lineHeight: "13px"
  },
  comment: {
    padding: 10,
    border: "1px solid #E2E3ED",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "150%",
    color: "#494C62",
    marginTop: 9,
    marginBottom: 5,
    borderRadius: 4,
    whiteSpace: "pre-wrap",
    display: "inline-block"
  },
  files: {
    display: "flex",
    flexWrap: "wrap"
  },
  comment_wrapper: {
    marginBottom: 5
  }
});

const Message = ({ chat_fio, files, message, date_chat }) => {
  const classes = useStyles();
  return (
    <Box className={classes.message_wrapper}>
      <Avatar className={classes.avatar} />
      <Box className={classes.message_box}>
        <Typography className={classes.name}>{chat_fio}</Typography>
        <Typography className={classes.date}>{date_chat}</Typography>
        <Box className={classes.comment_wrapper}>
          <Box component="p" className={classes.comment}>
            {message}
          </Box>
        </Box>
        <Box className={classes.files}>
          {files?.map(el => (
            <MessageFile key={el.stage_chat_file_id} {...el} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Message;
