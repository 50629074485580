import instance from "../../services/api";

export const reportCostOfPayment = (params, reqParams = {}) => {
  return instance(true, null, reqParams)
    .post("/partner/report_costs_of_payment_by_resident/", params)
    .then((res = {}) => {
      return res;
    });
};

export const getJKList = () => {
  return instance(true).get("/partner/read_resident_by_contractor/");
};

export const getPaymentHist = (params = {}) => {
  return instance(true).post("/partner/report_costs_of_payment_stage/", params);
};

export const plannedSumDetails = remont_id => {
  return instance(true).get(
    `/partner/report_costs_of_payment_by_report_remontst_by_resident/${remont_id}/`
  );
};

export const getHomeBuilders = async () => {
  return await instance(true).get(`/partner/home_builders/`);
};
