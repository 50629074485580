import React from "react";
import { LinearProgress, Typography } from "@material-ui/core";
import { useStyles } from "./CommentsFile";
const LocalAddedFile = ({ width, name }) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.file}>{name}</Typography>
      {width > 0 && <LinearProgress variant="determinate" value={width} />}
    </>
  );
};

export default LocalAddedFile;
