import instance from "../../services/api";

export const getPenalty = params => {
  return instance(true).post("/partner/penalty_report/", params);
};

export const getPenaltyReport = id => {
  return instance(true).get(`/partner/penalty_report/${id}/`);
};

export const acceptance = (id, params) => {
  return instance(true).post(
    `/partner/penalty_report/${id}/acceptance/`,
    params
  );
};
