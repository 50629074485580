import React from "react";
import { Avatar, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { deleteParticipant } from "./api";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  name: {
    marginLeft: 15,
    fontSize: 12,
    fontWeight: 600,
    color: "#494C62"
  },
  name_wrapper: {
    marginBottom: 20
  },
  avatar: {
    width: 30,
    height: 30
  },
  icon: {
    marginLeft: "auto",
    color: "#0C4B86",
    cursor: "pointer"
  }
});

const SingleParticipant = ({ fio, employee_id, cardId, setParticipants }) => {
  const classes = useStyles();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const handleClick = () => {
    deleteParticipant(cardId, employee_id)
      .then(res => {
        setParticipants(res.data);
        addDefaultSnack("Участник успешно удален");
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  };
  return (
    <Box mb={2}>
      <Box display="flex" alignItems="center" className={classes.name_wrapper}>
        <Avatar className={classes.avatar} />
        <Typography className={classes.name}>{fio}</Typography>
        <CloseIcon className={classes.icon} onClick={handleClick} />
      </Box>
    </Box>
  );
};

export default SingleParticipant;
