import React from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import { showRightDrawerAction } from "../../../services/redux/actions/remonts-actions";
import CancelIcon from "@material-ui/icons/Cancel";
import Drawer from "@material-ui/core/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { WrapperScrollbar } from "../../../components/common/WrapperScrollbar";
import { AddRequest } from "./AddRequest";

const drawerWidth = 375;
const drawerWidthRequest = 1000;

export const RequestsRightDrawer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const remontsState = useSelector(state => state.remontsState);
  const { showRightDrawer } = remontsState,
    { show } = showRightDrawer;

  return (
    <Drawer
      variant={window.innerWidth <= 768 ? undefined : "permanent"}
      open={show}
      anchor="right"
      className={clsx(classes.drawer, classes.drawerRequest, {
        [classes.drawerOpen]: show,
        [classes.drawerClose]: !show
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: show,
          [classes.drawerClose]: !show
        })
      }}
    >
      <WrapperScrollbar className={classes.content}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.header}
        >
          <h1 className={classes.headerTitle}>Создание доп. заявки</h1>
          <IconButton
            onClick={() => {
              dispatch(showRightDrawerAction(false, {}));
            }}
          >
            <CancelIcon color="primary" />
          </IconButton>
        </Box>
        <AddRequest show={show} />
      </WrapperScrollbar>
    </Drawer>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    overflowX: "hidden"
  },
  content: {
    overflowY: "scroll",
    overflowX: "hidden"
  },
  drawer: {
    width: drawerWidth,
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "340px"
    }
  },
  drawerRequest: {
    width: drawerWidthRequest,
    maxWidth: drawerWidthRequest,
    "& > .MuiPaper-root": {
      width: drawerWidthRequest,
      maxWidth: drawerWidthRequest
    },
    [theme.breakpoints.down(768)]: {
      width: '100%',
      maxWidth: '100%',
      "& > .MuiPaper-root": {
        width: '100%',
        maxWidth: '100%',
      },
    }
  },
  drawerOpen: {
    width: "inherit",
    backgroundColor: "rgb(253, 253, 253)",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.down("md")]: {
      width: "340px"
    }
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    backgroundColor: "rgb(248, 248, 248)",
    width: 0,
    "& > .MuiPaper-root": {
      width: 0
    }
  },
  header: {
    minHeight: "50px",
    paddingLeft: "10px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #D2D3D9"
  },
  headerTitle: {
    fontSize: "18px"
  }
}));
