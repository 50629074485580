import React from "react";
import { Box, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { source } from "../../services/api";
const useStyles = makeStyles({
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: "#494C62",
    textAlign: "center",
    width: "100%"
  },
  close: {
    marginLeft: "auto",
    color: "#0C4B86",
    cursor: "pointer"
  }
});

const ModalTitle = ({ title, setOpen }) => {
  const classes = useStyles();

  const handleClose = () => {
    source.cancel();
    setOpen(false);
  };
  return (
    <Box display="flex">
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.close}>
        <CloseIcon onClick={handleClose} />
      </Typography>
    </Box>
  );
};

export default ModalTitle;
