import React from "react";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { LoaderData } from "./LoaderData";

const useStyles = makeStyles({
  btn: {
    width: 200,
    height: 38,
    padding: 10,
    borderRadius: 4,
    color: props => props.variant === "secondary" ? "#0C4B86" : "#fff",
    backgroundColor: props => props.variant === "secondary" ? "#fff" : "#0C4B86",
    cursor: "pointer",
    border: props => props.variant === "secondary" ? "1px solid #0C4B86" : "none",
    "&:hover": {
      backgroundColor: props => props.variant === 'secondary' ? "#fff" : "#0C4B86",
      color: props => props.variant === "secondary" ? "#0764bd" : "#fff",
    }
  },
  btnAuto: {
    width: "auto",
    minWidth: "unset"
  },
  disabled: {
    backgroundColor: "rgba(70,70,70,0.12) !important",
    color: "#4f4f4f",
  }
});

export const CButton = ({
                          loading,
                          className,
                          disabled,
                          mode = "",
                          onClick = () => {
                          },
                          children, variant = "",
                          type,
                          ...other
                        }) => {
  const classes = useStyles({ variant });
  return (
    <Button
      type={type || "button"}
      disabled={disabled || loading}
      onClick={onClick}
      mode={mode}
      className={clsx(
        classes.btn,
        { [classes.btnAuto]: mode === "auto" },
        className || "", {[classes.disabled]: disabled || loading}
      )}
      classes={{ disabled: classes.disabled }}
      {...other} color={"primary"}
    >
      {loading ? <LoaderData small white /> : children || "Добавить"}
    </Button>
  );
};
