import React from "react";
import styles from "../team_detail.module.scss";
import { formatPhoneNumber } from "../../../../../utils";
import StarIcon from "@material-ui/icons/Star";
import clsx from "clsx";
import { useMastersStyles } from "../../styles";

export const MasterPhoneNumbers = ({ phone_numbers }) => {
  const classes = useMastersStyles();
  return (
    <>
      {!!phone_numbers?.length && (
        <div className={styles.team_detail__phone_wrapper}>
          {phone_numbers?.map(item => {
            return (
              <div
                key={item?.team_master_phone_id}
                className={styles.team_detail__phone_item}
              >
                {formatPhoneNumber(item.team_master_phone_number)}
                {item?.is_main_phone && !!item?.team_master_phone_number && (
                  <div
                    className={styles.team_detail__phone_main}
                    title={"Основной номер"}
                  >
                    <StarIcon
                      className={clsx(classes.starIcon, classes.starIconSmall)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
