import React from "react";
import { Paper } from "@material-ui/core";
import MainTabs from "./MainTabs";

const RemontsHeader = ({
  value,
  menuItem,
  tabsData,
  handleChange,
  setMenuItem,
}) => {
  return (
    <Paper
      elevation={0}
      style={{
        background: "#FFFFFF",
        borderBottom: "1px solid #D2D3D9",
        borderRadius: "0",
      }}
    >
      <MainTabs
        value={value}
        menuItem={menuItem}
        handleChange={handleChange}
        setMenuItem={setMenuItem}
        tabsData={tabsData}
      />
    </Paper>
  );
};

export default RemontsHeader;
