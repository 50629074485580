import React, { Fragment, useEffect, useState } from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Badge, Collapse } from "@material-ui/core";
import List from "@material-ui/core/List";
import { Link, useLocation } from "react-router-dom";
import { PAGES } from "../../../constants";
import clsx from "clsx";
import { useStyles } from "../index";
import { MenuListItem } from "./index";

const MenuItem = ({
  icon,
  sub_menus,
  menu_name,
  open,
  count,
  handleClick,
  setOpen,
  getIcon
}) => {
  const renderMenuTree = (sub_menus, menu_name, icon, count) => {
    return (
      <CollapsedMenuItem
        open={open}
        setOpen={setOpen}
        sub_menus={sub_menus}
        menu_name={menu_name}
        icon={icon}
        count={count}
        getIcon={getIcon}
        handleClick={handleClick}
        renderMenuTree={renderMenuTree}
      />
    );
  };

  return <>{renderMenuTree(sub_menus, menu_name, icon, count)}</>;
};

export default MenuItem;

const CollapsedMenuItem = ({
  icon,
  sub_menus,
  menu_name,
  getIcon,
  collapsed,
  setOpen,
  open,
  count,
  renderMenuTree,
  handleClick
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(collapsed || false);

  useEffect(() => {
    if (!open) {
      setIsCollapsed(false);
    }
  }, [open]);

  return (
    <>
      <ListItem
        isCollapsed={isCollapsed}
        onClick={() => {
          setIsCollapsed(prev => !prev);
          if (!open) {
            setOpen(true);
          }
        }}
        style={{
          color: "#9194A9",
          cursor: "pointer",
          height: 45
        }}
        el={{ icon, menu_name, count }}
        open={open}
        getIcon={getIcon}
      />
      <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
        <List component="div" disablePadding style={{ marginLeft: 30 }}>
          {sub_menus.map(el => {
            return (
              <Fragment key={el.menu_id}>
                {!!el?.sub_menus?.length ? (
                  <>{renderMenuTree(el?.sub_menus, el?.menu_name, el?.icon, el?.count)}</>
                ) : (
                  <Link
                    to={PAGES[el.menu_action] ? `${PAGES[el.menu_action]}/${el.what || ""}` : '/'}
                    menu_action={el.menu_action ? PAGES[el.menu_action] || "/" : "/"}
                    className={clsx(classes.listItem, {
                      [classes.active]:
                        location.pathname ===
                        `${PAGES[el.menu_action]}${
                          el.what ? `/${el.what}` : "/"
                        }`
                    })}
                    onClick={handleClick}
                  >
                    <ListItem el={el} open={open} getIcon={getIcon} />
                  </Link>
                )}
              </Fragment>
            );
          })}
        </List>
      </Collapse>
    </>
  );
};

const ListItem = ({
  open,
  getIcon,
  el,
  onClick = () => {},
  style = {},
  isCollapsed = null
}) => {
  const classes = useStyles();
  const iconComp = getIcon(el?.icon);
  return (
    <MenuListItem open={open} style={style} onClick={onClick}>
      {!!iconComp && (
        <ListItemIcon
          className={clsx({
            [classes.itemIconCollapsed]: !open
          })}
          style={{ minWidth: open ? 36 : 54 }}
        >
          {
            el.count
              ? <Badge overlap={'rectangular'} color="primary" badgeContent={el.count} >
                {iconComp}
              </Badge>
              : iconComp
          }
        </ListItemIcon>
      )}
      {open && <ListItemText primary={el.menu_name} />}
      {open && isCollapsed !== null ? (
        isCollapsed ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )
      ) : null}
    </MenuListItem>
  );
};
