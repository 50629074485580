import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomTable, CustomTableCell, CustomTableRow } from "../../../components/common/CustomTable/CustomTable";
import { makeStyles } from "@material-ui/core/styles";
import { getKeyReceiveData, getRemontKeyCounts, keyReceiveTableColumns } from "./services";
import CustomInput from "../../../components/common/form/CustomInput";

export const KeyReceive = () => {
  const classes = useStyles();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const [counts, setCounts] = useState({ key_cnt: "", trinket_cnt: "" });

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true);
      const res = await getKeyReceiveData(remont_id);
      setIsFetching(false);
      if (!res) return;
      setData(res);
    };
    getData();
  }, [remont_id]);

  useEffect(() => {
    const getCounts = async () => {
      const res = await getRemontKeyCounts(remont_id);
      if (!res) return;
      setCounts(res);
    };
    getCounts();
  }, [remont_id]);

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.headerItem}>
          <CustomInput value={counts.key_cnt} disabled placeholder={"Кол-во ключей"} label={"Кол-во ключей"} />
        </div>
        <div className={classes.headerItem}>
          <CustomInput value={counts.trinket_cnt} disabled placeholder={"Кол-во брелоков"} label={"Кол-во брелоков"} />
        </div>
      </div>
      <CustomTable
        headItems={keyReceiveTableColumns}
        isEmpty={!data?.length}
        isFetching={isFetching}
      >
        {data?.map(el => {
          return (
            <CustomTableRow key={el?.key_receive_id}>
              <CustomTableCell align={"center"}>
                {el.key_receive_id}
              </CustomTableCell>
              <CustomTableCell align={"center"}>{el.receive_date}</CustomTableCell>
              <CustomTableCell>{el.key_from}</CustomTableCell>
              <CustomTableCell>{el.key_to}</CustomTableCell>
              <CustomTableCell>{el.comments}</CustomTableCell>
              <CustomTableCell>{el.employee_fio}</CustomTableCell>
            </CustomTableRow>
          );
        })}
      </CustomTable>
    </div>
  );
};


const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    alignItems: "flex-end",
    flexWrap: 'wrap',
    gap: 15,
    marginBottom: 15
  },
  headerItem: {
    width: 120
  }
}));
