import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ReportComment from "pages/reportOkkStage/reportComment";
import PaymentHist from "pages/reportCostPayment/paymentHistory";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { AddConstractor } from "./modals/constructorModal";
import ReportMaterialModal from "../../pages/reportMaterialShip/ReportMaterialModal";
import ProblemsModal from "../../pages/Problems/ProblemsModal";
import SingleCardModal from "../../pages/Problems/SingleCardModal";
import { ConfirmModal } from "./modals/ConfirmModal";
import DeliveryModal from "../../pages/reportOkkStage/DeliveryModal";
import PlannedSum from "../../pages/reportCostPayment/PlannedSum";
import ChangeProrabModal from "../../pages/remonts/RemontInfo/modals/ChangeProrabModal";
import ExecutiveReportModal from "../../pages/executiveReport/ExecutiveReportModal";
import CheckListModal from "../../pages/remonts/CheckList/CheckListModal";
import ChangeLimitModal from "../../pages/reportLimit/changeLimitModal";
import PenaltyDetailsModal from "../../pages/reportPenalty/Modals/penaltyDetailsModal";
import Comment from "../../pages/reportPenalty/Modals/Comment";
import { AddTeamMember } from "../features/teams/modals/AddTeamMember/AddTeamMember";
import { AddTeamMaster } from "../features/teams/modals/AddTeamMaster/AddTeamMaster";
import { AddIncomeModal } from "../features/accounting/modals/AddIncome/AddIncomeModal";
import { AddCostModal } from "../features/accounting/modals/AddCost/AddCostModal";
import ChangeBrigadaModal from "../../pages/remonts/RemontInfo/modals/ChangeBrigadaModal";
import { MODALS_NAMES } from "../../constants";
import { TextModal } from "./modals/TextModal";
import { RequestsApproveModal } from "../features/requests/modals/RequestsApproveModal/RequestsApproveModal";
import { RequestShipHistoryModal } from "../features/requests/modals/RequestShipHistory";
import { RequestStatusesHistoryModal } from "../features/requests/modals/RequestStatusesHistory";
import { RequestsDateModal } from "../features/requests/modals/RequestsDateModal/RequestsDateModal";
import { AddTeamDebt } from "../features/teams/modals/AddTeamDebt/AddTeamDebt";
import { AddCostDebtInfo } from "../features/accounting/modals/AddCostDebtInfo/AddCostDebtInfo";
import clsx from "clsx";
import { TeamMasterDocumentsModal } from "./modals/TeamMasterDocumentsModal";
import { showPopUp } from "../../services/redux/actions/app-actions";
import { AddMasterToWorkset } from "./modals/AddMasterToWorkset";
import { AddExternalTeamMaster } from "../features/teams/modals/AddTeamMaster/AddExternalTeamMaster";
import { ChangeContractorModal } from "../../pages/remonts/RemontInfo/modals/ChangeContractorModal";
import { GalleryModal } from "./modals/Gallery";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopUp() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { popup } = useSelector(state => state.appState);
  const { show, type, data } = popup;

  const handleClose = () => {
    dispatch(showPopUp(false));
  };

  return (
    <Dialog
      open={show}
      maxWidth="lg"
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="form-dialog-title2"
    >
      <DialogContent className={clsx("p-10", classes.content)}>
        {(!!data?.close || type === MODALS_NAMES.confirmModal) && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            className={classes.header}
          >
            <IconButton
              color="primary"
              onClick={handleClose}
              className={classes.close__btn}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <div className={classes.popupContent}>
          {modals.map((item, i) => {
            const Component = item.component;
            return (
              type === item?.type && (
                <Component key={i} data={data} handleClose={handleClose} />
              )
            );
          })}
        </div>
      </DialogContent>
    </Dialog>
  );
}

const modals = [
  { component: ReportComment, type: MODALS_NAMES.okkReport },
  { component: PaymentHist, type: MODALS_NAMES.paymentHist },
  { component: AddConstractor, type: MODALS_NAMES.constractorAdd },
  { component: ReportMaterialModal, type: MODALS_NAMES.materialReport },
  { component: ProblemsModal, type: MODALS_NAMES.problemsModal },
  { component: SingleCardModal, type: MODALS_NAMES.singleCardModal },
  { component: ConfirmModal, type: MODALS_NAMES.confirmModal },
  { component: DeliveryModal, type: MODALS_NAMES.deliveryModal },
  { component: PlannedSum, type: MODALS_NAMES.plannedSum },
  { component: ChangeProrabModal, type: MODALS_NAMES.prorabChange },
  { component: ExecutiveReportModal, type: MODALS_NAMES.executiveReportModal },
  { component: CheckListModal, type: MODALS_NAMES.checkListModal },
  { component: ChangeBrigadaModal, type: MODALS_NAMES.brigadaChange },
  { component: ChangeContractorModal, type: MODALS_NAMES.changeContractor },
  { component: ChangeLimitModal, type: MODALS_NAMES.changeLimit },
  { component: PenaltyDetailsModal, type: MODALS_NAMES.penaltyDetails },
  { component: Comment, type: MODALS_NAMES.penaltyComment },
  { component: AddTeamMember, type: MODALS_NAMES.addTeamMember },
  { component: AddTeamMaster, type: MODALS_NAMES.addTeamMaster },
  {
    component: AddExternalTeamMaster,
    type: MODALS_NAMES.AddExternalTeamMaster
  },
  { component: AddTeamDebt, type: MODALS_NAMES.addTeamDebt },
  { component: AddIncomeModal, type: MODALS_NAMES.addIncome },
  { component: AddCostModal, type: MODALS_NAMES.addCost },
  { component: AddCostDebtInfo, type: MODALS_NAMES.addCostDebtInfo },
  { component: AddMasterToWorkset, type: MODALS_NAMES.addMasterToWorkset },
  { component: TextModal, type: MODALS_NAMES.textModal },
  { component: RequestsDateModal, type: MODALS_NAMES.requestsDate },
  {
    component: TeamMasterDocumentsModal,
    type: MODALS_NAMES.teamMasterDocuments
  },
  { component: RequestShipHistoryModal, type: MODALS_NAMES.requestShipHistory },
  {
    component: RequestStatusesHistoryModal,
    type: MODALS_NAMES.requestStatusesHistory
  },
  { component: RequestsApproveModal, type: MODALS_NAMES.approveRequestModal },
  { component: GalleryModal, type: MODALS_NAMES.gallery }
];

const useStyles = makeStyles(() => ({
  popupContent: {
    width: "auto",
    height: "auto",
    "& > div": {
      width: "inherit"
    }
  },
  title: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#0C4B86",
    minWidth: "400px",
    padding: "0"
  },
  header: {
    position: "relative",
    marginBottom: "5px"
  },
  close__btn: {
    position: "absolute",
    right: "-18px",
    top: "-20px"
  },
  content: {
    background: "#F8FAFB",
    boxShadow: "0px 5px 20px rgba(102, 102, 102, 0.15)",
    borderRadius: "4px",
    padding: "20px"
  }
}));

export const CustomDialogTitle = ({ children }) => {
  const classes = useStyles();
  return (
    <DialogTitle id="removet-dialog-title" className={classes.title}>
      {children}
    </DialogTitle>
  );
};
