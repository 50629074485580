import * as api from "pages/remonts/services/api";
import { setStages } from "./app-actions";
import { getStagesList } from "../../../components/features/accounting/api";
import { fetchResidentList } from "../../../pages/Problems/api";

export const RESET_REMONT_STATE = "RESET_REMONT_STATE";
export const GET_RESIDENTS_LIST = "GET_RESIDENTS_LIST";
export const UPDATE_REMONT_WORK_VALUES = "UPDATE_REMONT_WORK_VALUES";
export const GET_RESIDENTS_FILTER = "GET_RESIDENTS_FILTER";
export const GET_RESIDENT = "GET_RESIDENT";
export const SET_MASTERS_LIST = "SET_MASTERS_LIST";

export const SET_LOADING = "SET_LOADING";
export const SHOW_RIGHT_DRAWER = "SHOW_RIGHT_DRAWER";

export const SET_MASTER_CONSTRACTORS = "SET_MASTER_CONSTRACTORS";

export const SET_REMONTS_REPORT = "SET_REMONTS_REPORT";

export const SET_PROJECTS = "SET_PROJECTS";
export const SET_STATUSES_LIST = "SET_STATUSES_LIST";
export const SET_MESSAGES = "SET_MESSAGES";
export const RESET_RESIDENT = "RESET_RESIDENT";
export const SET_REMONTS_PAYMENTS = "SET_REMONTS_PAYMENTS";

export const resetRemontState = () => {
  return {
    type: RESET_REMONT_STATE,
  };
};
export const setResidents = data => {
  return {
    type: GET_RESIDENTS_LIST,
    payload: !!data?.error ? [] : data
  };
};

export const updateRemontWorkValues = data => {
  return {
    type: UPDATE_REMONT_WORK_VALUES,
    payload: data
  };
};

export const getResidents = (
  status_id = "",
  stage_id = "",
  service_status = null,
  setLoading = () => {}
) => async dispatch => {
  setLoading(true);
  try {
    const data = await api.getResidentsList({
      remont_status_id: status_id,
      service_status,
      stage_id
    });
    setLoading(false);
    if (!!data?.error) return;
    dispatch(setResidents(data));
  } catch (error) {
    setLoading(false);
  }
};

export const updateResidentWorksValues = (remont_id = "") => async dispatch => {
  if (!remont_id) return;
  try {
    const data = await api.getResidentsList({ remont_id });
    if (data && !!data?.length && !!data[0]) {
      dispatch(updateRemontWorkValues(data[0]));
    }
  } catch (error) {}
};

export const setRemontLoading = loading => {
  return {
    type: SET_LOADING,
    payload: loading
  };
};

export const setMasterConstractor = data => {
  return {
    type: SET_MASTER_CONSTRACTORS,
    payload: data
  };
};

export const getResidentsForFilter = (statusId = "") => async dispatch => {
  try {
    const { data } = await fetchResidentList(statusId);
    dispatch({
      type: GET_RESIDENTS_FILTER,
      payload: !!data?.error ? [] : data
    });
  } catch (error) {}
};

const setResident = (data) => {
  return {
    type: GET_RESIDENT,
    payload: data
  }
}
export const getResident = remont_id => async dispatch => {
  if (!remont_id)
    return dispatch(setResident({}));
  try {
    const { data } = await api.getResidentByRemontId(remont_id);
    dispatch(setResident(data));
  } catch (e) {
    dispatch(setResident({}));
  }
};

export const showRightDrawerAction = (show = false, data = {}) => {
  return {
    type: SHOW_RIGHT_DRAWER,
    payload: {
      show,
      data
    }
  };
};

export const setMasterList = masters => ({
  type: SET_MASTERS_LIST,
  payload: masters
});

export const setRemontsReport = payload => {
  return {
    type: SET_REMONTS_REPORT,
    payload
  };
};

export const setProjects = payload => {
  return {
    type: SET_PROJECTS,
    payload
  };
};
export const setStatusesList = payload => {
  return {
    type: SET_STATUSES_LIST,
    payload
  };
};

export const setMessages = payload => {
  return {
    type: SET_MESSAGES,
    payload
  };
};

export const resetResident = () => {
  return {
    type: RESET_RESIDENT
  };
};

export const getStagesData = () => async (dispatch, getState) => {
  const { stages } = getState().appState;
  if (!!stages) return;
  try {
    const res = await getStagesList();
    if (res) {
      dispatch(setStages(res));
    }
  } catch (e) {}
};
