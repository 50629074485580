import React, { useCallback, useContext, useRef } from "react";
import { Box } from "@material-ui/core";
import ContractJournalRow from "./ContractJournalRow";
import { TableContext } from "./index";
import { useVirtual } from "react-virtual";
import { makeStyles } from "@material-ui/core/styles";
import { matchSorter } from "match-sorter";
import { WrapperScrollbar } from "../../components/common/WrapperScrollbar";

const useStyles = makeStyles({
  row_outer: {
    height: "calc(100vh - 200px)",
    overflow: "auto",
    minWidth: 800
  },
  row_inner: {
    width: "100%",
    position: "relative"
  }
});

const ContractJournalBody = ({ data, setData, filters }) => {
  const parentRef = useRef();
  const classes = useStyles();
  const [, , searchTerm] = useContext(TableContext);

  const sortedData = matchSorter(data, searchTerm, {
    keys: [
      "resident_name",
      "contractor_agreement_list_id",
      "remont_id",
      "flat_num",
      "document_name",
      "agreement_sum",
      "contragent_name",
      "status_name"
    ]
  });

  const rowVirtualizer = useVirtual({
    size: sortedData.length,
    parentRef,
    estimateSize: useCallback(() => 130, [])
  });

  return (
    <WrapperScrollbar ref={parentRef} className={classes.row_outer}>
      <Box
        className={classes.row_inner}
        style={{
          height: `${rowVirtualizer.totalSize}px`
        }}
      >
        {rowVirtualizer.virtualItems.map(item => {
          const el = searchTerm ? sortedData[item.index] : data[item.index];
          return (
            <ContractJournalRow
              {...el}
              item={item}
              filters={filters}
              key={el.contractor_agreement_list_id}
              setData={setData}
            />
          );
        })}
      </Box>
    </WrapperScrollbar>
  );
};

export default ContractJournalBody;
