import React from "react";
import { useStyles } from "../measure/MeasureContent";
import AdditionalInfo from "./AdditionalInfo/AdditionalInfo";
import MainRemontInfo from "./MainRemontInfo";
import RemontInfoHeader from "./RemontInfoHeader";
import { CustomLoader } from "../../../components/common/CustomLoader";
import { NotFound } from "../../../components/common/text";

const RemontInfo = ({ data, isFetching, setRemontData }) => {
  const classes = useStyles();
  if (isFetching) return <CustomLoader />;
  return (
    <>
      {data?.remont_id ? (
        <>
          <MainRemontInfo data={data} />
          <div className={classes.content}>
            <AdditionalInfo delivery_contact={data?.delivery_contact} />
            <div className={classes.root}>
              <RemontInfoHeader data={data} setRemontData={setRemontData} />
            </div>
          </div>
        </>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default RemontInfo;
