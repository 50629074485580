import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { checkRemontPermissionData } from "./services";
import { useParams } from "react-router-dom";
import { PAGES } from "../../constants";

export const CheckRemontPermission = ({ children, check = true }) => {
  const { remont_id: remont_id_str, remont_status } = useParams();
  const remont_id = Number(remont_id_str);
  const [isAllowed, setIsAllowed] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const checkPermission = async () => {
      const isAllowed = await checkRemontPermissionData(
        remont_id,
        remont_status,
        navigate
      );
      setIsAllowed(!!isAllowed);
      if (!isAllowed) {
        const url = remont_status
          ? `/remonts/${remont_status}`
          : PAGES.remontList;
        navigate(url);
      }
    };
    check && checkPermission();
  }, [remont_id, remont_status, navigate, check]);

  if (!isAllowed) return null;
  return <>{children}</>;
};
