import React, { useEffect, useState } from "react";
import { addMasterToWorkset, getExternalMasters } from "./services";
import { EditIconWrapper } from "../../../components/common/blocks";
import { useDispatch, useSelector } from "react-redux";
import { showPopUp } from "../../../services/redux/actions/app-actions";
import { MODALS_NAMES } from "../../../constants";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { numberWithCommas } from "../../../utils";
import styles from "../../../components/features/teams/detail/team_detail.module.scss";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandLess } from "@material-ui/icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Collapse, Tooltip } from "@material-ui/core";
import { LoaderData } from "../../../components/common/LoaderData";
import clsx from "clsx";
import { NotFound } from "../../../components/common/text";
import { Amount } from "../../../components/common/blocks/Amount";
import { MdLocalAtm } from "react-icons/all";

export const RemontMastersWorksets = ({ remont_id, amount }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { addDefaultSnack } = useCustomSnackbar();
  const [isFetching, setIsFetching] = useState(false);
  const [externalMasters, setExternalMasters] = useState([]);

  useEffect(() => {
    const getLeaveWorksets = async () => {
      const res = await getExternalMasters(remont_id, setIsFetching);
      setExternalMasters(res);
    };
    getLeaveWorksets();
  }, [remont_id]);

  const onSubmit = async body => {
    const res = await addMasterToWorkset(remont_id, body);
    if (!res) return;
    setExternalMasters(res);
    dispatch(showPopUp(false));
    addDefaultSnack();
  };

  const onEditClick = (
    work_set_id,
    work_set_name,
    selected_master_id,
    speciality_id
  ) => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addMasterToWorkset, {
        work_set_name,
        selected_master_id,
        close: true,
        work_set_id,
        remont_id,
        speciality_id,
        onSubmit
      })
    );
  };

  return (
    <>
      <div className={styles.team_detail__flex}>
        <h2>Внешние мастера</h2>
        <div>
          <p className={styles.team_detail__master}>
            Сумма: <span>{numberWithCommas(amount?.external_masters_amount)}</span>
          </p>
          {!!amount?.external_masters_amount_with_tax && (
            <div className={styles.team_detail__amount}>
              Сумма с комиссией: <span>{numberWithCommas(amount?.external_masters_amount_with_tax)}</span>
            </div>
          )}
        </div>
      </div>

      <div className={classes.table}>
        <div className={classes.tableHead}>
          {headItems?.map((item, i) => (
            <div
              key={i}
              className={clsx(
                classes.tableHeadCell,
                classes.cell,
                classes[`cell__${item.align}`]
              )}
            >
              {item.label}
            </div>
          ))}
        </div>
        {isFetching ? (
          <div className={classes.content}>
            <LoaderData small />
          </div>
        ) : externalMasters?.length ? (
          externalMasters?.map((group, index) => {
            return (
              <WorksetRow
                key={group.speciality_id || `group-${index}`}
                onEditClick={onEditClick}
                group={group}
              />
            );
          })
        ) : (
          <div className={classes.content}>
            <NotFound />
          </div>
        )}
      </div>
    </>
  );
};

const WorksetRow = ({ group, onEditClick }) => {
  const classes = useStyles();
  const [openTab, setOpenTab] = useState(false);
  const { is_master_payment } = useSelector(
    state => state.remontsState.resident
  );

  const handleChange = async () => {
    setOpenTab(prevState => !prevState);
  };

  const cost_status = group.cost_status;
  return (
    <React.Fragment>
      <div
        className={classes.rowParent}
        style={{
          backgroundColor: getRowBgColor(
            group.work_set_master_attached_count,
            group.work_set_count,
            is_master_payment
          )
        }}
      >
        <div
          className={classes.allWidthCell}
          onClick={handleChange}
        >{`${group.speciality_name} (${group.work_set_master_attached_count} из ${group.work_set_count})`}</div>
        <div className={classes.cell}>{group.team_master_fio_list}</div>
        <div className={clsx(classes.cell, classes.alignRight)} align={"right"}>
          <Amount value={group?.work_price_amount_sum} />
        </div>
        <div className={clsx(classes.cell, classes.alignRight, classes.cellMark)} align={"right"}>
          <Amount value={group?.tax_sum} />
        </div>
        <div className={clsx(classes.cell, classes.whiteCell, classes.cellAlt)} align={"center"}>
          {cost_status && <Tooltip title={getCostStatusLabel(cost_status)}>
            <div className={classes.amountCell__wrapper}>
              <div className={`${classes.amountCell__color} ${classes[`amountCell__color_${cost_status}`]}`} />
              <MdLocalAtm
                className={`${classes.amountCell__icon} ${classes[`amountCell__color_${cost_status}Icon`]}`} />
            </div>
          </Tooltip>}
        </div>
        <div
          className={clsx(classes.cellCenter, classes.whiteCell)}
          align={"center"}
        >
          {(group.speciality_id && cost_status === null) && (
            <EditIconWrapper
              onClick={() => onEditClick(null, "", null, group.speciality_id)}
            />
          )}
        </div>
        <div className={clsx(classes.cellCenter, classes.whiteCell)}>
          <span
            className={clsx(classes.cellCenter, classes.pointer)}
            onClick={handleChange}
          >
            {openTab ? <ExpandLess /> : <ChevronRightIcon />}
          </span>
        </div>
      </div>

      <Collapse in={openTab} timeout="auto" unmountOnExit>
        {group.work_sets?.map(item => {
          const cost_status = item.cost_status;
          return (
            <div className={classes.row} key={item.work_set_id}>
              <div className={classes.cell}>{item.work_set_name || ""}</div>
              <div className={classes.cell}>{item.team_master_fio || ""}</div>
              <div
                className={clsx(classes.cell, classes.alignRight)}
                align={"right"}
              >
                <Amount value={item.work_price_amount} />
              </div>
              <div
                className={clsx(classes.cell, classes.alignRight, classes.cellMark)}
                align={"right"}
              >
                <Amount value={item.tax} />
              </div>
              <div className={clsx(classes.cell, classes.cellAlt)} align={"center"}>
                {cost_status && <Tooltip title={getCostStatusLabel(cost_status)}>
                  <div className={classes.amountCell__wrapper}>
                    <div className={`${classes.amountCell__color} ${classes[`amountCell__color_${cost_status}`]}`} />
                    <MdLocalAtm
                      className={`${classes.amountCell__icon} ${classes[`amountCell__color_${cost_status}Icon`]}`} />
                  </div>
                </Tooltip>}
              </div>
              <div
                className={classes.cell}
                align={"center"}
                style={{ backgroundColor: "#fff" }}
              >
                {cost_status === null && <EditIconWrapper
                  onClick={() =>
                    onEditClick(
                      item.work_set_id,
                      item.work_set_name,
                      item.team_master_id,
                      group.speciality_id
                    )
                  }
                />}
              </div>
              <div
                className={classes.cell}
                style={{ backgroundColor: "#fff" }}
              />
            </div>
          );
        })}
      </Collapse>
    </React.Fragment>
  );
};

const getCostStatusLabel = (cost_status) => {
  switch (cost_status) {
    case "CREATED":
      return "Создан";
    case "HALF_PAYED":
      return "Есть неоплаченные работы";
    case "PAYED":
      return "Оплачено";
    default:
      return "";
  }
};

const headItems = [
  { label: "Работа", align: "left" },
  { label: "Мастер" },
  { label: "Cумма", align: "right" },
  { label: "Комиссия", align: "right" },
  { label: "Статус", align: "center" },
  { label: "" }
];

const getRowBgColor = (filled, all, colorizeRow) => {
  if (!colorizeRow) return "#fff";
  if (filled === 0) return "rgb(221, 90, 67)";
  if (!all) return "#fff";
  if (filled === all) return "rgb(105, 170, 70)";
  return "rgb(255, 183, 82)";
};
const gridTemplateColumns = "7fr 5fr 90px 60px 50px 30px 30px";
const mobileGridTemplateColumns = "200px 150px 90px 60px 50px 30px 30px";
const useStyles = makeStyles(theme => ({
  table: {
    border: "1px solid #e7e7e7",
    borderRadius: "0.5rem",
    color: " rgba(0, 0, 0, 0.87)",
    width: "100%",
    position: "relative",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    overflowX: "auto !important",
    overflowY: "auto",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff"
  },
  tableHead: {
    display: "grid",
    gridTemplateColumns,
    background: "#ECEEFD",
    minWidth: "100%",

    [theme.breakpoints.down(576)]: {
      gridTemplateColumns: mobileGridTemplateColumns
    }
  },
  tableHeadCell: {
    color: "#696C7C",
    fontSize: "12px",
    fontWeight: "600"
  },
  rowParent: {
    display: "grid",
    gridTemplateColumns,
    borderBottom: "1px solid #e7e7e7",
    width: 'fit-content',
    height: "55px",
    minWidth: "100%",
    "& > div": {
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      lineHeight: "18px",

      "@media (max-width: 768px)": {
        fontSize: "14px"
      }
    },
    [theme.breakpoints.down(576)]: {
      gridTemplateColumns: mobileGridTemplateColumns
    }
  },
  row: {
    display: "grid",
    gridTemplateColumns,
    borderBottom: "1px solid #e7e7e7",

    [theme.breakpoints.down(576)]: {
      gridTemplateColumns: mobileGridTemplateColumns
    }
  },
  cell: {
    padding: "7px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down(576)]: {
      overflow: 'auto'
    }
  },
  cellAlt: {
    padding: "0 0 0 7px"
  },
  alignRight: {
    justifyContent: "flex-end"
  },
  whiteCell: {
    background: "#f3f3f3"
  },
  cellCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  cellMark: {backgroundColor: "#cda54e", fontSize: 13},
  allWidthCell: {
    padding: "7px",
    fontSize: "17px",
    fontWeight: "600",
    cursor: "pointer"
  },
  pointer: {
    cursor: "pointer"
  },
  content: {
    padding: "10px 0"
  },
  cell__right: {
    textAlign: "right",
    justifyContent: "flex-end"
  },
  cell__center: {
    textAlign: "center",
    justifyContent: "center"
  },
  amountCell__color: {
    content: "",
    position: "absolute",
    top: "7px",
    left: "3px",
    width: "14px",
    height: "19px",
    backgroundColor: "#e76d11",
    zIndex: 1
  },
  amountCell__color_PAYED: {
    backgroundColor: "#a5e1a9",
    width: "25px"
  },
  amountCell__color_PAYEDIcon: {
    color: "#00980a"
  },
  amountCell__color_HALF_PAYED: {
    backgroundColor: "#ffcfaa",
    width: "14px"
  },
  amountCell__color_HALF_PAYEDIcon: {
    color: "#ff8b44"
  },
  amountCell__color_CREATED: {
    width: "0"
  },
  amountCell__color_CREATEDIcon: {
    color: "#535353"
  },
  amountCell__icon: {
    fontSize: "34px",
    display: "inline-block",
    position: "relative",
    zIndex: "2"
  },
  amountCell__wrapper: {
    position: "relative",
    overflow: "hidden",
    display: "inline-block",
    height: "30px"
  }
}));
