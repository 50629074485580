import React, { useCallback, useRef } from "react";
import { Box } from "@material-ui/core";
import { WrapperScrollbar } from "../WrapperScrollbar";
import { useVirtual } from "react-virtual";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { CustomLoader } from "../CustomLoader";
import { NotFound } from "../text";
import { getTableMaxHeight } from "../../../utils";
import { TableUpdateBtn } from "../blocks";

export const CustomVirtualTable = ({
                                     data = [],
                                     keyOption = "",
                                     gridTemplateColumns,
                                     headItems = [],
                                     cellsList = [],
                                     isEmpty,
                                     updateDataReq,
                                     width = null,
                                     isFetching = false,
                                     isBinaryDiv = true,
                                     rowHeight
                                   }) => {
  const parentRef = useRef();
  const classes = useStyles({ isEmpty, maxHeight: getTableMaxHeight() });

  const rowVirtualizer = useVirtual({
    size: isFetching ? 0 : data?.length || 0,
    parentRef,
    estimateSize: useCallback(() => rowHeight || 130, [rowHeight])
  });
  return (
    <div className={classes.container}>
      <WrapperScrollbar ref={parentRef} className={classes.row_outer}>
        <VirtualTableHead
          width={width} isFetching={isFetching} updateDataReq={updateDataReq}
          gridTemplateColumns={gridTemplateColumns}
          items={headItems}
        />
        {(isFetching || isEmpty) && <div className={classes.defaultBlock}>
          {isFetching ? <CustomLoader /> : isEmpty && <NotFound />}
        </div>}
        {!isEmpty && <Box
          className={classes.row_inner}
          style={{
            height: isEmpty ? "auto" : `${rowVirtualizer.totalSize}px`
          }}
        >
          {rowVirtualizer.virtualItems.map((item, i) => {
            const el = data[item.index];
            return (
              <VirtualTableRow
                el={el}
                item={item}
                width={width}
                isBinaryDiv={isBinaryDiv}
                gridTemplateColumns={gridTemplateColumns}
                key={!!keyOption ? el[keyOption] : i}
                cellsList={cellsList}
              />
            );
          })}
        </Box>}
      </WrapperScrollbar>
    </div>
  );
};

const VirtualTableRow = ({
                           item,
                           width,
                           el = {},
                           cellsList = [],
                           gridTemplateColumns,
                           isBinaryDiv = true
                         }) => {
  const classes = useStyles({ gridTemplateColumns, width });
  return (
    <Box
      className={clsx(
        classes.row,
        { [classes.classicDiv]: isBinaryDiv },
        { [classes.alternativeDiv]: isBinaryDiv === false }
      )}
      style={{
        transform: `translateY(${item.start}px)`,
        height: `${item.size}px`
      }}
    >
      {cellsList?.map((cell, i) => {
        if(cell.hide) return null
        let styles;
        if (typeof cell.style === "function") {
          styles = cell.style ? cell.style(el) : {};
        } else styles = cell.style ? { ...cell.style } : {};

        let className = cell.className;
        if (typeof cell.className === "function") {
          className = cell.className ? cell.className(el) : {};
        }
        return (
          <Box
            key={i}
            className={clsx(
              classes.border,
              classes[`cellAlign_${cell.align || "left"}`],
              className
            )} sx={styles} onClick={cell.onClick ? () => cell.onClick(el) : () => {
          }}
          >
            {cell.render ? cell.render(el) : el[cell.key]}
          </Box>
        );
      })}
    </Box>
  );
};

const VirtualTableHead = ({ items = [], isFetching, updateDataReq, width, gridTemplateColumns }) => {
  const classes = useStyles({ gridTemplateColumns, width });
  return (
    <Box className={classes.headWrapper}>
      {updateDataReq && <Box className={classes.headTop}>
        <TableUpdateBtn onClick={() => updateDataReq()} isFetching={isFetching} />
      </Box>}
      <Box className={classes.head}>
        {items?.map((item, i) => {
          return (
            <Box key={i} className={clsx(classes.cell)}>
              {item.label || ""}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

const gridTemplateColumns = "65px 65px 1fr 2fr 90px 1fr 130px 2fr";
const useStyles = makeStyles(theme => ({
  container: {
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    borderRadius: "0.5rem",
    color: "rgba(0, 0, 0, 0.87)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff",
    overflow: "auto"
  },
  row_outer: {
    width: "100%",
    backgroundColor: "#fff",
    overflow: "auto",
    maxHeight: props => `${props.maxHeight}px`
  },
  defaultBlock: {
    maxHeight: 60
  },
  row_inner: {
    width: "100%",
    position: "relative"
  },
  headTop: {
    padding: "5px",
  },
  headWrapper: {
    minWidth: props =>
      props.width === null ? "100%" : props.width,
    borderRadius: "4px 4px 0px 0px",
    backgroundColor: "#ECEEFD",
    position: "sticky",
    top: 0,
    zIndex: 1001,
    [theme.breakpoints.down(576)]: {
      width: "max-content"
    }
  },
  head: {
    display: "grid",
    gridTemplateColumns: props =>
      props.gridTemplateColumns || gridTemplateColumns,
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "7px 0",
  },
  row: {
    display: "grid",
    gridTemplateColumns: props =>
      props.gridTemplateColumns || gridTemplateColumns,
    minWidth: props =>
      props.width === null ? "100%" : props.width,
    width: '100%',
    position: "absolute",
    top: 0,
    left: 0,
    "&:hover": {
      backgroundColor: "#ececec"
    }
  },
  classicDiv: {
    "&:nth-child(odd)": {
      backgroundColor: "#f9f9f9",
      "&:hover": {
        backgroundColor: "#ececec"
      }
    }
  },
  alternativeDiv: {
    backgroundColor: "#ffffff",
    "&:nth-child(4n - 1)": {
      backgroundColor: "#f9f9f9",
      "&:hover": {
        backgroundColor: "#ececec"
      }
    }
  },
  border: {
    border: "1px solid #F0F2F6",
    borderTop: "none",
    padding: 5,
    overflow: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: 5,
      height: 5
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0C4B86",
      borderRadius: 4
    }
  },
  cell: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    color: "#696C7C",
    backgroundColor: "#ECEEFD",
    padding: 5,
    borderLeft: "1px solid #F0F2F6"
  },
  cellAlign_right: {
    display: "flex",
    justifyContent: "flex-end"
  },
  cellAlign_left: {
    display: "flex",
    justifyContent: "flex-start"
  },
  cellAlign_center: {
    display: "flex",
    justifyContent: "center"
  }
}));
