import React from "react";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../components/common/CustomTable/CustomTable";
import { useStyles } from "./index";

const WorksReportTable = ({ data, isLoading }) => {
  const classes = useStyles();
  return (
    <CustomTable
      containerClassName={classes.content}
      isEmpty={!data?.length}
      isFetching={isLoading}
      stickyHeader
      headItems={headerTitles}
    >
      {data?.map((el, index) => (
        <CustomTableRow key={el.remont_work_id}>
          <CustomTableCell>{index + 1}</CustomTableCell>
          <CustomTableCell>{el?.resident_name}</CustomTableCell>
          <CustomTableCell>{el?.flat_num}</CustomTableCell>
          <CustomTableCell>{el?.work_name}</CustomTableCell>
          <CustomTableCell>{el?.fio}</CustomTableCell>
          <CustomTableCell>{el?.ready_date}</CustomTableCell>
        </CustomTableRow>
      ))}
    </CustomTable>
  );
};

export default WorksReportTable;

const headerTitles = [
  { label: "№" },
  { label: "Жк" },
  { label: "Квартира" },
  { label: "Работа" },
  { label: "Кто принял" },
  { label: "Дата принятия" }
];
