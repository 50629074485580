import React, { useEffect, useState } from "react";
import UtilityBtn from "../UtilityBtn";
import Box from "@material-ui/core/Box";
import { useDownloadFile } from "./useDownloadFile";
import { useParams } from "react-router-dom";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../../components/common/CustomTable/CustomTable";
import { fetchGpr } from "../services/api";
import { useCustomSnackbar } from "../../../../utils/hooks/useCustomSnackbar";

const headerTitles = [
  { label: "Этап", align: "left" },
  { label: "Работа", align: "left" },
  { label: "Дата начала", align: "left" },
  { label: "Дата окончания", align: "left" },
  { label: "Комментарий", align: "left" }
];
const Gpr = () => {
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const { addCloseSnack } = useCustomSnackbar();
  const { downloadFile, downloading } = useDownloadFile();
  const [gprData, setGprData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchGpr(remont_id)
      .then(res => {
        setLoading(false);
        setGprData(res);
      })
      .catch(e => {
        addCloseSnack(e?.response?.data?.error);
        setLoading(false);
      });
  }, [remont_id, addCloseSnack]);

  return (
    <>
      <Box m={2}>
        <UtilityBtn
          width={230}
          height={38}
          text="Выгрузить в Excel"
          handleClick={() =>
            downloadFile(
              `/partner/remont/${remont_id}/gpr/xlsx/`,
              "Отчет по ГПР"
            )
          }
          disabled={downloading || loading}
        />
      </Box>
      <CustomTable
        withMaxHeight={false}
        headItems={headerTitles}
        isEmpty={!gprData?.length}
        isFetching={loading}
      >
        {gprData?.map(el => (
          <CustomTableRow key={el.gpr_stage_id}>
            <CustomTableCell>{el?.stage_name}</CustomTableCell>
            <CustomTableCell>{el?.work_name}</CustomTableCell>
            <CustomTableCell>{el?.date_begin}</CustomTableCell>
            <CustomTableCell>{el?.date_end}</CustomTableCell>
            <CustomTableCell>{el?.comments}</CustomTableCell>
          </CustomTableRow>
        ))}
      </CustomTable>
    </>
  );
};

export default Gpr;
