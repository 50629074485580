import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import TableContent from "./tableContent";
import NumberFormat from "react-number-format";
import clsx from "clsx";
import { NotFound } from "../../components/common/text";

const useStyles = makeStyles({
  headText: {
    color: "#696C7C",
    fontSize: 12,
    fontWeight: 600,
    padding: "5px !important",
    width: 200
  },
  headRow: {
    backgroundColor: "#ECEEFD",
    verticalAlign: "top"
  },
  cell_stage: {
    borderLeft: "1px solid #F0F2F6",
    width: 180,
    color: "#A5A7B5",
    fontSize: 12,
    fontWeight: 600,
    minWidth: 150
  },
  cell_sum: {
    minWidth: 100,
    color: "#A5A7B5",
    fontSize: 12,
    fontWeight: 600,
    borderLeft: "1px solid #F0F2F6"
  },
  cell_comment: {
    borderLeft: "1px solid #F0F2F6",
    minWidth: 300,
    color: "#A5A7B5",
    fontSize: 12,
    fontWeight: 600
  },
  cell: {
    borderLeft: "1px solid #F0F2F6",
    color: "#A5A7B5",
    fontSize: 12,
    fontWeight: 600,
    minWidth: 100
  },
  cell_resident: {
    borderLeft: "1px solid #F0F2F6",
    color: "#A5A7B5",
    fontSize: 12,
    fontWeight: 600,
    minWidth: 300
  },
  root: {
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)",
    borderRadius: 4,
    marginBottom: 5
  },
  fix_padding: {
    padding: "5px !important"
  }
});

const ReportPartnerPaymentTable = ({ data = {} }) => {
  const classes = useStyles();
  const row = [];

  Object.keys(data).forEach(function(key) {
    row.push(data[key]);
  });
  return (
    <>
      {!!row?.length ? (
        row.map((arr, idx) => (
          <TableContainer key={idx} component={Paper} className={classes.root}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.headRow}>
                  <TableCell align="left" className={classes.headText}>
                    <Typography color="textSecondary">Подрядчик</Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      className={classes.title}
                    >
                      {arr.contractor_name}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.headText}>
                    <Typography color="textSecondary">Дата</Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      className={classes.title}
                    >
                      {arr.date_of_payment}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headText}
                    colSpan={3}
                  >
                    <Typography color="textSecondary">Сумма</Typography>
                    <Typography
                      variant="h6"
                      component="h2"
                      className={classes.title}
                    >
                      <NumberFormat
                        value={arr.total_payment}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.headText}
                    colSpan={3}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    align="left"
                    className={clsx(classes.cell_resident, classes.fix_padding)}
                  >
                    ЖК
                  </TableCell>
                  <TableCell
                    align="left"
                    className={clsx(classes.cell, classes.fix_padding)}
                  >
                    Номер квартиры
                  </TableCell>
                  <TableCell
                    align="left"
                    className={clsx(classes.cell_stage, classes.fix_padding)}
                  >
                    Этап
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={clsx(classes.cell_sum, classes.fix_padding)}
                  >
                    Сумма
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={clsx(classes.cell, classes.fix_padding)}
                  >
                    Комментарий
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={clsx(classes.cell, classes.fix_padding)}
                  >
                    Спецификация
                  </TableCell>
                </TableRow>
                <TableContent data={arr.res_data} />
              </TableBody>
            </Table>
          </TableContainer>
        ))
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default React.memo(ReportPartnerPaymentTable);
