import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ProjectsRow from "./ProjectsRow";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchProjects } from "./api";
import { setProjects } from "../../../services/redux/actions/remonts-actions";
import { CustomLoader } from "../../../components/common/CustomLoader";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { PAGES_ROUTE_KEY } from "../../../constants";
import { setHeaderBack } from "../../../services/redux/actions/app-actions";
import { useNavigate } from "react-router";

const Projects = ({singleRemont}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addCloseSnack } = useCustomSnackbar();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const { projects } = useSelector(state => state.remontsState);
  const { final, measure_reference } = projects;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const goBack = () => {
      navigate(`${PAGES_ROUTE_KEY.remontInfo}/${remont_id}`);
    };
    remont_id && singleRemont && dispatch(setHeaderBack(goBack));
    return () => {
      dispatch(setHeaderBack(null));
    };
  }, [dispatch, remont_id, navigate, singleRemont]);

  useEffect(() => {
    if (remont_id) {
      setLoading(true);
      fetchProjects({ remont_id })
        .then(res => {
          setLoading(false);
          dispatch(setProjects(res));
        })
        .catch(e => {
          addCloseSnack(e?.response?.data?.error);
          setLoading(false);
        });
    }
  }, [remont_id, dispatch, addCloseSnack]);

  if (loading) return <CustomLoader />;
  return (
    <>
      <div className={classes.link}><span className={classes.linkLabel}>Ссылка на 3D замеры - </span> {!!measure_reference && <a href={measure_reference} target={"_blank"}>{measure_reference}</a>}</div>
      <div className={classes.root}>
        <ProjectsRow title="Финальный" data={final} />
      </div>
    </>
  );
};

export default Projects;

const useStyles = makeStyles(() => ({
  root: {
    border: "1px solid #D2D3D9",
    borderRadius: 4
  },
  tabs_header: {
    backgroundColor: "#F8FAFB",
    boxShadow: "none"
  },
  tab_label: {
    color: "#9194A9",
    fontSize: 14,
    fontWeight: 700,
    letterSpacing: "0.08em",
    textTransform: "none",
    width: 221,
    height: 60,
    borderRight: "1px solid transparent",
    borderLeft: "1px solid transparent",
    borderRadius: "4px 4px 0px 0px"
  },
  indicator: {
    background: "none"
  },
  selected: {
    color: "#659DF1",
    borderRightColor: "#D2D3D9",
    borderLeftColor: "#D2D3D9"
  },
  link: {
    fontWeight: 700,
    marginBottom: "5px"
  },
  linkLabel: {
    fontWeight: 500,
  }
}));