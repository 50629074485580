import React, { useEffect, useState } from "react";
import { CustomDatePicker } from "../../../common/form/CustomDatePicker";
import { Box } from "@material-ui/core";
import { useRequestsStyles } from "../index";
import { getRemontStatuses, getRequestStatuses, getResidents } from "../api";
import { AutoSuggest } from "../../../common/form/AutoSuggest";
import { getMaterialsList } from "../../../../pages/materialSearch/api";
import { CustomSearch } from "../../../common/form/CustomSearch";
import { getContractors } from "../../../../pages/remonts/services/api";

export const RequestsFilters = ({
  filters,
  handleChange,
  searchTerm,
  handleChangeSearch
}) => {
  const classes = useRequestsStyles();
  const [residents, setResidents] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [requestStatuses, setRequestStatuses] = useState([]);
  const [remontStatuses, setRemontStatuses] = useState([]);

  useEffect(() => {
    getContractors()
      .then(res => {
        setContractors(res.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    getMaterialsList()
      .then(res => {
        setMaterials(
          res.data?.map(item => ({
            ...item,
            material_name: `${item?.material_id} - ${item?.material_name || ""}`
          }))
        );
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    getResidents()
      .then(res => {
        setResidents(res);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    getRequestStatuses()
      .then(res => {
        setRequestStatuses(res);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    getRemontStatuses()
      .then(res => {
        setRemontStatuses(res);
      })
      .catch(() => {});
  }, []);

  return (
    <div className={classes.filters}>
      <Box width={220}>
        <CustomSearch
          searchTerm={searchTerm}
          handleChange={handleChangeSearch}
        />
      </Box>
      <Box width={200}>
        <CustomDatePicker
          fullWidth
          label={"Дата начала"}
          placeholder={"Выберите дату"}
          name="date_begin"
          value={filters.date_begin}
          onChange={handleChange}
        />
      </Box>
      <Box width={200}>
        <CustomDatePicker
          fullWidth
          label={"Дата окончания"}
          placeholder={"Выберите дату"}
          name={"date_end"}
          value={filters.date_end}
          onChange={handleChange}
        />
      </Box>
      <Box width={180}>
        <AutoSuggest
          withMargin
          list={contractors}
          showKey={"contractor_name"}
          valueKey={"contractor_id"}
          name={"contractor_id"}
          value={filters.contractor_id}
          placeholder="Выберите подрядчика"
          label="Выберите подрядчика"
          onChange={handleChange}
        />
      </Box>
      <Box width={180}>
        <AutoSuggest
          withMargin
          list={residents}
          showKey={"resident_name"}
          valueKey={"resident_id"}
          name={"resident_id"}
          value={filters.resident_id}
          placeholder="Выберите ЖК"
          label="Выберите ЖК"
          onChange={handleChange}
        />
      </Box>
      <Box width={250}>
        <AutoSuggest
          withMargin
          list={materials}
          showKey={"material_name"}
          valueKey={"material_id"}
          name={"material_id"}
          value={filters.material_id}
          placeholder="Выберите материал"
          label="Выберите материал"
          onChange={handleChange}
        />
      </Box>
      <Box width={180}>
        <AutoSuggest
          withMargin
          multiple
          list={requestStatuses}
          showKey={"provider_request_status_name"}
          valueKey={"provider_request_status_id"}
          name={"provider_request_status_id"}
          value={filters.provider_request_status_id}
          placeholder="Статус заявки"
          label="Статус заявки"
          onChange={handleChange}
        />
      </Box>
      <Box width={180}>
        <AutoSuggest
          withMargin
          list={remontStatuses}
          showKey={"status_name"}
          valueKey={"remont_status_id"}
          name={"remont_status_id"}
          value={filters.remont_status_id}
          placeholder="Статус ремонта"
          label="Статус ремонта"
          onChange={handleChange}
        />
      </Box>
    </div>
  );
};
