import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { callOkk } from "./api";
import { setOkkData, showPopUp } from "../../services/redux/actions/app-actions";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { MODALS_NAMES, PAGES_ROUTE_KEY } from "../../constants";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
    padding: 0
  },
  title: {
    fontSize: 14,
    color: "#494C62",
    fontWeight: 600,
    margin: 0
  },
  col: {
    width: 250
  },
  col_main: {
    width: 250
  },
  row: {
    verticalAlign: "middle"
  },
  textData: {
    color: "#000000",
    fontSize: 12,
    fontWeight: 400
  },
  pos: {
    fontSize: 12,
    fontWeight: 600
  },
  call_okk_btn: {
    width: "50%",
    background: "#0C4B86",
    height: 30,
    display: "inline-flex",
    padding: "5px 10px",
    alignItems: "center",
    borderRadius: 4,
    color: "#fff",
    cursor: "pointer",
    justifyContent: "center"
  },
  reset_pad: {
    padding: 10
  },
  empty_data: {
    minHeight: 20
  }
}));

export default function ReportHeadTop({
  data = {},
  stage_1_delay,
  stage_2_delay,
  stage_3_delay
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const { resident, defect, remont, stage } = useSelector(
    state => state.appState.filterFields
  );
  const handleClick = () => {
    const params = {
      remont_id: data?.remont_id,
      resident_id: resident || 0,
      remont_performing_code: remont || 0,
      stage_code: stage || 0,
      remark_code: defect || 0
    };
    callOkk(params)
      .then(res => {
        dispatch(setOkkData(res?.data.data));
        addDefaultSnack("ОКК успешно вызван");
      })
      .catch(err => {
        addCloseSnack(err.response?.data.error);
      });
  };
  const stages = [stage_1_delay, stage_2_delay, stage_3_delay];
  const isOutdated = stages.some(el => el > 0);
  const currentStageCodes = ["3", "4", "5"];
  const statusCodes = ["STAGE_NEW", "DEFECT_EXIST"];

  const handleDeliveryClick = () => {
    dispatch(
      showPopUp(true, MODALS_NAMES.deliveryModal, {
        remont_id: data?.remont_id
      })
    );
  };
  return (
    <TableRow
      className={clsx(classes.row, {
        [classes.outdated]: isOutdated === true
      })}
    >
      <TableCell
        className={classes.col_main}
        classes={{ root: classes.reset_pad }}
      >
        <Link to={`${PAGES_ROUTE_KEY.singleRemont}/${data.remont_id}`}>
          <h5 className={classes.title}>
            <span title="ID ремонта">{data.remont_id}</span>,{" "}
            {data.resident_name}, кв.{data.flat_num}
          </h5>
        </Link>
        <Typography className={classes.pos} color="textSecondary">
          {data.preset_name}
        </Typography>
      </TableCell>
      <TableCell className={classes.col} classes={{ root: classes.reset_pad }}>
        <Typography className={classes.pos} color="textSecondary">
          Площадь
        </Typography>
        <Typography variant="h6" className={classes.textData}>
          {data.area} кв.м
        </Typography>
      </TableCell>
      <TableCell className={classes.col} classes={{ root: classes.reset_pad }}>
        <Typography className={classes.pos} color="textSecondary">
          Дата начала
        </Typography>
        <Typography variant="h6" className={classes.textData}>
          {data.gpr_begin_date}
        </Typography>
      </TableCell>
      <TableCell className={classes.col} classes={{ root: classes.reset_pad }}>
        <Typography className={classes.pos} color="textSecondary">
          Дата сдачи
        </Typography>
        <Typography variant="h6" className={classes.textData}>
          {data.gpr_end_date}
        </Typography>
      </TableCell>
      <TableCell className={classes.col} classes={{ root: classes.reset_pad }}>
        <Typography className={classes.pos} color="textSecondary">
          Проект
        </Typography>
        <Typography
          variant="h6"
          className={clsx(classes.textData, {
            [classes.empty_data]: data.project_accepted_date === null
          })}
        >
          {data.project_accepted_date}
        </Typography>
      </TableCell>
      <TableCell
        className={classes.col}
        style={{ display: "flex", alignItems: "center" }}
        classes={{ root: classes.reset_pad }}
      >
        <Box style={{ width: "50%" }}>
          <Typography
            className={classes.pos}
            color="textSecondary"
            style={{ marginTop: 10 }}
          >
            Текущий статус
          </Typography>
          <Typography variant="h6" className={classes.textData}>
            {data.current_stage}({data.current_stage_status_name})
          </Typography>
        </Box>
        {currentStageCodes.includes(data.current_stage_code) === true &&
        statusCodes.includes(data.status_code) === true ? (
          <Box
            component="span"
            className={classes.call_okk_btn}
            onClick={handleClick}
          >
            Вызов Окк
          </Box>
        ) : null}
        {Number(data.current_stage_code) === 6 &&
        !data.client_receive_status ? (
          <Box
            component="span"
            className={classes.call_okk_btn}
            onClick={handleDeliveryClick}
          >
            Сдача клиенту
          </Box>
        ) : null}
      </TableCell>
    </TableRow>
  );
}
