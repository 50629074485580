import React from "react";
import MeasureContent from "./MeasureContent";

const Measure = ({ singleRemont }) => {
  return (
    <div style={{ minHeight: singleRemont ? "unset" : "80vh" }}>
      <MeasureContent singleRemont={singleRemont} />
    </div>
  );
};

export default Measure;
