import React, { useEffect, useState } from "react";
import { PageTitle } from "../../components/common/pageTitle";
import Box from "@material-ui/core/Box";
import {
  getPaymentReportStages,
  getPaymentsReport,
  getProrabs,
  getProrabsPaymentsReport,
  getReportResidents
} from "./api";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { CustomDatePicker } from "../../components/common/form/CustomDatePicker";
import { CustomTable, CustomTableCell, CustomTableRow } from "../../components/common/CustomTable/CustomTable";
import cookiesService from "../../services/cookiesService";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";
import { makeStyles } from "@material-ui/core/styles";

const headerTitles = [
  { label: "№" },
  { label: "Прораб" },
  { label: "ЖК" },
  { label: "Кв" },
  { label: "Площадь" },
  { label: "Название этапа" },
  { label: "Сумма" },
  { label: "Сумма за минусом налогов" },
  { label: "Дата завершения" }
];

const headerTitlesAssist = [
  { label: "№" },
  { label: "ЖК" },
  { label: "Кв" },
  { label: "Площадь" },
  { label: "Название этапа" },
  { label: "Сумма" },
  { label: "Сумма за минусом налогов" },
  { label: "Дата завершения" }
];
const useStyles = makeStyles({
  filters: {
    display: "flex",
    marginBottom: "12px",
    gap: "12px",
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },
})
// display="flex" mb={3} gridRowGap={'15px'} flexWrap={'wrap'} alignItems={"flex-end"}
const cookiesServiceInstance = cookiesService.getService();
const PaymentReportsAll = ({ isProrabPage = false }) => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [data, setData] = useState({
    prorabs: [],
    residents: [],
    stages: []
  });
  const [filterState, setFilterState] = useState({
    resident_id: null,
    stage_id: null,
    date_from: null,
    date_end: null
  });
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = e => {
    setFilterState({
      ...filterState,
      [e.target.name]: e.target.value || null
    });
  };

  useEffect(() => {
    Promise.all([getPaymentReportStages(), getReportResidents(), getProrabs()])
      .then(([stages, residents, prorabs]) => {
        setData({
          stages: stages.data,
          residents: residents.data,
          prorabs: prorabs.data
        });
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    const fetchData = isProrabPage
      ? getProrabsPaymentsReport
      : getPaymentsReport;
    const timeout = setTimeout(() => {
      setLoading(true);
      fetchData(filterState)
        .then(res => {
          setLoading(false);
          setReports(res.data);
        })
        .catch(e => {
          addCloseSnack(e?.response?.data?.error);
          setLoading(false);
        });
    }, 400);
    return () => clearTimeout(timeout);
  }, [filterState, isProrabPage, addCloseSnack]);

  const userData = cookiesServiceInstance.getUserData();
  const positionCode = userData?.position_code;

  const calcSum = arr => {
    return (
      arr.length &&
      arr?.reduce((acc, curr) => {
        acc += curr;
        return acc;
      })
    );
  };
  const totalSum = reports.filter(el => el.sum >= 0).map(el => el.sum);
  const totalSumAfterTaxes = reports
    .filter(el => el.sum_minus_taxes >= 0)
    .map(el => el.sum_minus_taxes);
  const total = calcSum(totalSum);
  const totalAfterTaxes = calcSum(totalSumAfterTaxes);

  return (
    <>
      <PageTitle
        title={isProrabPage ? "Оплаты прорабам" : "Оплата нач.дирекции"}
      />
      <div style={{ padding: 20 }}>
        <CollapseBlock tabletMode className={classes.filters}>
          <Box style={{ width: 200}}>
            <AutoSuggest
              list={data?.residents}
              value={filterState.resident_id}
              showKey={"resident_name"}
              valueKey={"resident_id"}
              name={"resident_id"}
              placeholder="Выберите ЖК"
              label="Жилой комплекс"
              onChange={handleChange}
            />
          </Box>
          <Box width={145}>
            <AutoSuggest
              list={data?.stages}
              value={filterState.stage_id}
              showKey={"stage_name"}
              valueKey={"stage_id"}
              name={"stage_id"}
              placeholder="Выберите этап"
              label="Этапы"
              onChange={handleChange}
            />
          </Box>
          {positionCode === "PARTNER_ASSIST" && isProrabPage ? (
            <Box width={145}>
              <AutoSuggest
                list={data?.prorabs}
                value={filterState?.employee_id}
                showKey={"fio"}
                valueKey={"employee_id"}
                name={"manager_project_employee_id"}
                placeholder="Выберите прораба"
                label="Прораб"
                onChange={handleChange}
              />
            </Box>
          ) : null}
          <Box style={{ width: 150 }}>
            <CustomDatePicker
              fullWidth
              label={"Дата начала"}
              placeholder={"Выберите дату"}
              name={"date_from"}
              value={filterState.date_from}
              onChange={handleChange}
            />
          </Box>
          <Box style={{ width: 150 }}>
            <CustomDatePicker
              fullWidth
              label={"Дата окончания"}
              placeholder={"Выберите дату"}
              name={"date_end"}
              value={filterState.date_end}
              onChange={handleChange}
            />
          </Box>
        </CollapseBlock>
        <CustomTable
          isFetching={loading}
          isEmpty={!reports?.length}
          stickyHeader
          headItems={isProrabPage ? headerTitles : headerTitlesAssist}
        >
          {reports?.map((el, i) => {
            return (
              <CustomTableRow>
                <CustomTableCell>{i + 1}</CustomTableCell>
                {positionCode === "PARTNER_ASSIST" && !isProrabPage ? null : (
                  <CustomTableCell>{el.fio}</CustomTableCell>
                )}
                <CustomTableCell>{el.resident_name}</CustomTableCell>
                <CustomTableCell align={"right"}>{el.flat_num}</CustomTableCell>
                <CustomTableCell align="right">{el.area}</CustomTableCell>
                <CustomTableCell>{el.stage_name}</CustomTableCell>
                <CustomTableCell>
                  {el.sum ? el.sum.toFixed(1) : ""}
                </CustomTableCell>
                <CustomTableCell>
                  {el.sum_minus_taxes ? el.sum_minus_taxes.toFixed(1) : ""}
                </CustomTableCell>
                <CustomTableCell align="right">
                  {el.date_finish}
                </CustomTableCell>
              </CustomTableRow>
            );
          })}
        </CustomTable>
        <Box display="flex" justifyContent="flex-end">
          {total >= 0 ? (
            <h2 style={{ marginBottom: 0 }}>
              Общая сумма:{" "}
              {new Intl.NumberFormat("ru-RU").format(+total.toFixed(1))}
            </h2>
          ) : null}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          {totalAfterTaxes >= 0 ? (
            <h2 style={{ marginBottom: 0 }}>
              Общая сумма за минусом налогов:{" "}
              {new Intl.NumberFormat("ru-RU").format(
                +totalAfterTaxes.toFixed(1)
              )}
            </h2>
          ) : null}
        </Box>
      </div>
    </>
  );
};

export default PaymentReportsAll;
