import React from "react";
import { CheckRemontPermission } from "../../../pages/remonts/CheckRemontPermission";
import { AccountingLayout } from "./layout";

export const AccountingContainer = props => {
  return (
    <CheckRemontPermission>
      <AccountingLayout {...props} />
    </CheckRemontPermission>
  );
};
