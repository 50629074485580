import React from "react";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
const useStyles = makeStyles({
  header: {
    padding: "8px 10px",
    borderRadius: 4,
    backgroundColor: ({ bg }) => bg,
    cursor: "pointer"
  },
  header_title: {
    fontSize: 14,
    fontWeight: 600,
    color: "#fff",
    letterSpacing: "1px"
  },
  icon: {
    color: "#fff",
    marginLeft: 9,
    transition: "all .2s linear"
  },
  active: {
    transform: "rotate(180deg)"
  }
});
const HeaderTitle = ({ title, count, bg, open }) => {
  const props = {
    bg
  };
  const classes = useStyles(props);
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.header}
    >
      <Typography className={classes.header_title}>{title}</Typography>
      <Box display="flex" alignItems="center">
        <Typography className={classes.header_title}>{count}</Typography>
        <KeyboardArrowDownIcon
          className={clsx(classes.icon, {
            [classes.active]: open === true
          })}
        />
      </Box>
    </Box>
  );
};

export default HeaderTitle;
