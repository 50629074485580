import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { CButton } from "../../../../common/buttons";
import { InputWarning } from "../../../../common/form/warnings";
import { AutoSuggest } from "../../../../common/form/AutoSuggest";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { CustomDatePicker } from "../../../../common/form/CustomDatePicker";
import { InputSumMask } from "../../../../common/form/InputSumMask";
import CustomInput from "../../../../common/form/CustomInput";
import styles from "../add_cost_income.module.scss";
import { getStagesData } from "../../../../../services/redux/actions/remonts-actions";

export const AddIncomeModal = ({ data, handleClose }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, dirtyFields }
  } = useForm();
  const { remonts, stages, modalLoading } = useSelector(
    state => state.appState
  );
  const { contractorIncomeTypes } = useSelector(state => state.accountingState);
  const isEditMode = !!data?.body;
  const isRemontMode = !!data?.remont_id;

  const incomeTypeDefaultValue = data?.body?.contractor_income_type_id || "";
  const type_id = dirtyFields?.contractor_income_type_id
    ? watch("contractor_income_type_id")
    : incomeTypeDefaultValue;

  useEffect(() => {
    dispatch(getStagesData());
  }, [dispatch]);

  const onSubmit = async body => {
    const { onSubmit: submit } = data;
    const reqBody = {
      ...body,
      remont_id: body?.remont_id || data?.remont_id,
      contractor_id: data?.body?.contractor_id || "",
      contractor_income_id: data?.body?.contractor_income_id,
      income_sum: Number(body?.income_sum)
    };
    return submit && (await submit(reqBody));
  };

  const isStageType = useMemo(() => {
    if (!type_id || !contractorIncomeTypes) return false;
    const type = contractorIncomeTypes?.find(
      item => item?.contractor_income_type_id === Number(type_id)
    );
    return type?.contractor_income_type_code === "BY_STAGE";
  }, [type_id, contractorIncomeTypes]);

  const income_sum = isEditMode ? data?.body?.income_sum || "" : "";

  return (
    <div>
      <div className={styles.accounting__header}>
        <h2 className={styles.accounting__title}>{` ${
          data?.body ? "Редактирование" : "Добавление"
        } дохода`}</h2>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form
        className={styles.accounting__form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={styles.accounting__form_item}>
          <Controller
            name="income_sum"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputSumMask
                type="number"
                fullWidth
                min={0}
                label="Сумма"
                placeholder="Введите сумму"
                wrapperClassname={styles.accounting__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={income_sum}
            rules={{
              required: true
            }}
          />
          {errors.income_sum && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        <div
          className={`${styles.accounting__form_item} ${styles.accounting__form_itemDif}`}
        >
          <Controller
            name="income_date"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                type="text"
                fullWidth
                placeholder="Введите дату"
                disableFuture
                wrapperClassname={styles.accounting__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={isEditMode ? data?.body?.income_date || "" : ""}
            rules={{
              required: true
            }}
          />
          {errors.income_date && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        {contractorIncomeTypes && (
          <div className={`${styles.accounting__form_item}`}>
            <Controller
              name="contractor_income_type_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutoSuggest
                  list={contractorIncomeTypes}
                  showKey={"contractor_income_type_name"}
                  valueKey={"contractor_income_type_id"}
                  value={value}
                  withMargin
                  placeholder="Выберите тип дохода"
                  label="Выберите тип дохода"
                  onChange={onChange}
                />
              )}
              defaultValue={
                isEditMode ? data?.body?.contractor_income_type_id || "" : ""
              }
              rules={{
                required: true
              }}
            />
            {errors.contractor_income_type_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}
        {!isRemontMode && remonts && (
          <div className={`${styles.accounting__form_item}`}>
            <Controller
              name="remont_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutoSuggest
                  list={remonts}
                  showKey={"remont_information"}
                  valueKey={"remont_id"}
                  value={value}
                  withMargin
                  placeholder="Выберите ремонт"
                  label="Выберите ремонт"
                  onChange={onChange}
                />
              )}
              defaultValue={isEditMode ? data?.body?.remont_id || "" : ""}
            />
            {errors.remont_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}

        {isStageType && (
          <div className={`${styles.accounting__form_item}`}>
            <Controller
              name="stage_id"
              control={control}
              render={({ field }) => {
                const { onChange, value } = field;
                return (
                  <AutoSuggest
                    withMargin
                    list={stages}
                    showKey={"stage_name"}
                    valueKey={"stage_id"}
                    placeholder="Выберите этап"
                    label="Выберите этап"
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
              defaultValue={isEditMode ? data?.body?.stage_id || "" : ""}
              rules={{
                required: true
              }}
            />
            {errors.stage_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}

        <div className={`${styles.accounting__form_item}`}>
          <Controller
            name="comment"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                type="text"
                fullWidth
                multiline
                label="Комментарий"
                placeholder="Введите комментарий"
                wrapperClassname={styles.add_team__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={isEditMode ? data?.body?.comment || "" : ""}
          />
        </div>
        <CButton
          loading={modalLoading}
          disabled={modalLoading}
          type="submit"
          className={styles.accounting__btn}
        >
          Сохранить
        </CButton>
      </form>
    </div>
  );
};
