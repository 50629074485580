import React, { useEffect, useState } from "react";
import EmptyOrShow from "components/common/EmptyOrShow";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import DownloadXlsx from "../../components/common/DownloadXlsx";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { CustomDatePicker } from "../../components/common/form/CustomDatePicker";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { getContractors } from "../remonts/services/api";

const useStyles = makeStyles(() => ({
  select: {
    display: "flex",
    alignItems: "flex-end",
    flexWrap: "wrap",
  },
  download_excel: {
    "& button": {
      marginTop: 0,
      marginLeft: 20
    }
  },
  datepicker: {
    marginRight: 25
  },
  selectContractor: {
    width: 180
  },
  form: { float: "left", marginBottom: 15 }
}));

const ReportFilter = ({ handleChange, state }) => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();

  const [loading] = useState(false);
  const [contractors, setContractors] = useState([]);

  useEffect(() => {
    getContractors()
      .then(answer => {
        setContractors(answer.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [addCloseSnack]);

  return (
    <EmptyOrShow loading={loading}>
      <div className={classes.form}>
        <div className={classes.select}>
          <div className={classes.datepicker}>
            <CustomDatePicker
              type="text"
              name={"date_begin"}
              absoluteMessage
              label="От:"
              onChange={handleChange}
            />
          </div>
          <div className={classes.datepicker}>
            <CustomDatePicker
              type="text"
              name={"date_end"}
              absoluteMessage
              label="До:"
              onChange={handleChange}
            />
          </div>

          <div>
            <FormControl className={classes.selectContractor}>
              <AutoSuggest
                list={contractors}
                showKey={"contractor_name"}
                valueKey={"contractor_id"}
                name={"contractor_id"}
                value={state?.contractor_id}
                withMargin={false}
                onChange={handleChange}
                placeholder="Подрядчики"
                label="Подрядчики"
                defaultValue={""}
              />
            </FormControl>
          </div>
          <div className={classes.download_excel}>
            <DownloadXlsx
              url="/partner/report_costs_of_payment_by_dates/xlsx/"
              params={state}
              title="Расшифровка оплаты подрядчику"
            />
          </div>
        </div>
      </div>
    </EmptyOrShow>
  );
};

export default ReportFilter;
