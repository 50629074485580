import instance from "../../services/api";

export const getCards = params => {
  return instance(true).post(`/partner/problems/cards/`, params);
};

export const moveCards = params => {
  return instance(true)
    .post(`/partner/problems/cards/move/`, params)
    .then((res = {}) => {
      return res;
    });
};
export const fetchResidentList = (remont_status_id = "") => {
  return instance().get(
    `/partner/remont_constructor/residents_filter/${
      remont_status_id ? `?remont_status_id=${remont_status_id}` : ""
    } `
  );
};

export const fetchResidentFlats = resident_id => {
  return instance(true).get(`/partner/problems/resident/${resident_id}/flats/`);
};
export const fetchCardTypes = () => {
  return instance(true).get(`/partner/problems/card_types/`);
};

export const addNewCard = params => {
  return instance(true).post(`/partner/problems/cards/add/`, params);
};

export const getCardInfo = card_id => {
  return instance(true).get(`/partner/problems/cards/${card_id}/`);
};
export const getCardComments = card_id => {
  return instance(true).get(`/partner/problems/cards/${card_id}/comments/`);
};
export const changeCardInfo = (card_id, params) => {
  return instance(true).patch(`/partner/problems/cards/${card_id}/`, params);
};
export const createComment = (card_id, params, options) => {
  return instance(true).post(
    `/partner/problems/cards/${card_id}/comments/`,
    params,
    options
  );
};

export const changeComment = (card_id, comment_id, params, options) => {
  return instance(true).patch(
    `/partner/problems/cards/${card_id}/comments/${comment_id}/`,
    params,
    options
  );
};
export const deleteComment = (card_id, comment_id) => {
  return instance(true).delete(
    `/partner/problems/cards/${card_id}/comments/${comment_id}/`
  );
};
export const fetchParticipants = card_id => {
  return instance(true).get(`/partner/problems/cards/${card_id}/participants/`);
};
export const fetchAvailableParticipants = card_id => {
  return instance(true).get(
    `/partner/problems/cards/${card_id}/not_participants/`
  );
};
export const addParticipant = (card_id, params) => {
  return instance(true).post(
    `/partner/problems/cards/${card_id}/participants/`,
    params
  );
};
export const deleteParticipant = (card_id, employee_id) => {
  return instance(true).delete(
    `/partner/problems/cards/${card_id}/participants/${employee_id}/`
  );
};
export const getColumns = () => {
  return instance(true).get(`/partner/problems/columns/`);
};
export const fetchPriorities = () => {
  return instance(true).get(`/partner/problems/priorities/`);
};
export const getChanges = card_id => {
  return instance(true).get(`/partner/problems/cards/${card_id}/history/`);
};
export const searchChanges = (params = {}) => {
  return instance(true).post(`/partner/problems/actions/`, params);
};
export const getArchiveCards = () => {
  return instance(true).get(`/partner/problems/cards/archive/`);
};

export const deleteFile = (card_id, file_id) => {
  return instance(true).delete(
    `/partner/problems/cards/${card_id}/files/${file_id}/`
  );
};

export const fetchAttachments = card_id => {
  return instance(true).get(`/partner/problems/cards/${card_id}/files/`);
};

export const uploadFiles = (card_id, params, options = {}) => {
  return instance(true).post(
    `/partner/problems/cards/${card_id}/files/`,
    params,
    options
  );
};

export const getProblemName = params => {
  return instance(true).post(`/partner/problems/card_name/`, params);
};
