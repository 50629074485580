import React, { useState } from "react";
import { Box, TextareaAutosize, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import UtilityBtn from "../remonts/RemontInfo/UtilityBtn";
import { MAIN_URL } from "../../constants";
import EmptyOrShow from "../../components/common/EmptyOrShow";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import { CustomDatePicker } from "../../components/common/form/CustomDatePicker";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../components/common/CustomTable/CustomTable";

const useStyles = makeStyles({
  wrapper: {
    width: "600px !important"
  },
  container: {
    marginTop: 15,
    width: 600
  },
  title: {
    color: "#0C4B86",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "22px"
  },
  icon: {
    color: "#0C4B86",
    cursor: "pointer"
  },
  remontId: {
    marginBottom: 15
  },
  textarea: {
    width: "100%",
    resize: "none",
    borderRadius: 4,
    marginTop: 15,
    marginBottom: 15,
    outline: "none"
  },

  input: {
    opacity: 0,
    visibility: "hidden",
    position: "absolute"
  },
  btn: {
    width: 120,
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    color: "#fff",
    background: "#0C4B86",
    padding: 10,
    borderRadius: 4,
    justifyContent: "space-between",
    cursor: "pointer"
  },
  file_qty: {
    fontWeight: 600
  }
});

const headerTitles = [
  "ID",
  "Дата",
  "Пользователь",
  "Должность",
  "Комментарий",
  "Вложения"
];

const ExecutiveReportModal = ({ handleClose, data }) => {
  const classes = useStyles();
  const [key, setKey] = useState(Math.random());
  const [state, setState] = useState({});
  const [comments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { remont_id, indicator_id } = data;

  const textAreaChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.files ? e.target.files : e.target.value
    });
  };
  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.files ? e.target.files : e.target.value
    });
  };
  const handleClick = () => {
    setLoading(true);
    const params = new FormData();
    if (state?.files?.length) {
      Object.values(state.files).forEach(el => params.append("files", el));
    }
    Object.keys(state).forEach(key => {
      if (key !== "files") params.append(key, state[key]);
    });
  };

  const hideFields = [7, 26];

  const handleRemoveFiles = () => {
    setState({ ...state, files: {} });
    setKey(Math.random());
  };

  return (
    <Box className={classes.wrapper}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Typography className={classes.title}>
          История изменений даты отгрузки
        </Typography>
        <CloseIcon className={classes.icon} onClick={handleClose} />
      </Box>
      <EmptyOrShow loading={loading}>
        <Box>
          <Typography component="h4" className={classes.remontId}>
            № ремонта: {remont_id}
          </Typography>
          {hideFields.includes(+indicator_id) ? null : (
            <>
              <CustomDatePicker
                label="Планируема дата исправления"
                placeholder="Выберите дату"
                name="planned_fix_date"
                value={state?.planned_fix_date}
                onChange={handleChange}
              />

              <TextareaAutosize
                placeholder="Комментарий"
                className={classes.textarea}
                minRows={3}
                maxRows={3}
                cols={3}
                name="comment"
                value={state?.comment}
                onChange={textAreaChange}
              />
              <Box display="flex">
                <Box position="relative">
                  <input
                    id="files"
                    type="file"
                    name="files"
                    multiple={true}
                    className={classes.input}
                    onChange={handleChange}
                    key={key}
                  />
                  <label htmlFor="files" className={classes.label}>
                    <Box className={classes.btn}>
                      <CloudUploadOutlinedIcon />
                      <Box component="span">Загрузить</Box>
                    </Box>
                  </label>
                </Box>
                <Box className={classes.btn} ml={2} onClick={handleRemoveFiles}>
                  <HighlightOffOutlinedIcon />
                  <Box component="span">Очистить</Box>
                </Box>
              </Box>

              {state?.files ? (
                <Box>
                  <Typography className={classes.file_qty}>
                    Выбрано файлов: {Object.values(state.files).length}
                  </Typography>
                </Box>
              ) : null}
            </>
          )}
          <CustomTable
            withMaxHeight={false}
            isEmpty={!comments?.length}
            containerClassName={classes.container}
            headItems={headerTitles}
          >
            {comments?.map((el, i) => {
              return (
                <CustomTableRow key={i}>
                  <CustomTableCell align={"center"}>{i + 1}</CustomTableCell>
                  <CustomTableCell>{el.comment_date}</CustomTableCell>
                  <CustomTableCell>{el.fio}</CustomTableCell>
                  <CustomTableCell>{el.position_name}</CustomTableCell>
                  <CustomTableCell>{el.comment}</CustomTableCell>
                  <CustomTableCell>
                    <ol>
                      {el.files.map(el => {
                        return (
                          <li key={el.remont_indicator_document_id}>
                            <a
                              href={MAIN_URL + el.document_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {el.document_name}
                            </a>
                          </li>
                        );
                      })}
                    </ol>
                  </CustomTableCell>
                </CustomTableRow>
              );
            })}
          </CustomTable>

          {hideFields.includes(+indicator_id) ? null : (
            <Box textAlign="right" mt={2}>
              <UtilityBtn
                width={150}
                text="Сохранить"
                handleClick={handleClick}
                height={30}
                disabled={!Object.values(state).length}
              />
            </Box>
          )}
        </Box>
      </EmptyOrShow>
    </Box>
  );
};

export default ExecutiveReportModal;
