import React from "react";
import styles from "../team_detail.module.scss";
import { CustomSearch } from "../../../../common/form/CustomSearch";
import { AutoSuggest } from "../../../../common/form/AutoSuggest";
import { Checkbox } from "@material-ui/core";
import { useSelector } from "react-redux";

export const MastersFilter = ({ filters, handleFiltersChange }) => {
  const { master_types, teams } = useSelector(state => state.appState);
  return (
    <div className={styles.team_detail__masters_filters}>
      <CustomSearch
        className={styles.team_detail__masters_filtersSearch}
        value={filters.search}
        handleChange={e => handleFiltersChange("search", e?.target?.value)}
      />
      <div className={styles.team_detail__masters_filtersItem}>
        <AutoSuggest
          list={teams}
          showKey={"team_name"}
          valueKey={"team_id"}
          value={filters.team_id || ""}
          placeholder="Выберите бригаду"
          label="Выберите бригаду"
          onChange={e => {
            handleFiltersChange("team_id", e.target.value);
          }}
        />
      </div>
      <div className={styles.team_detail__masters_filtersItem}>
        <AutoSuggest
          list={master_types}
          showKey={"master_type_name"}
          valueKey={"master_type_id"}
          value={filters?.master_type_id}
          onChange={e => handleFiltersChange("master_type_id", e.target.value)}
          placeholder="Выберите тип"
          label="Выберите тип"
          defaultValue={""}
        />
      </div>
      <div>
        <label htmlFor={"is_main_master_check"}>Бригадир</label>
        <Checkbox
          id={"is_main_master_check"}
          checked={!!filters?.is_main_master}
          onChange={e =>
            handleFiltersChange("is_main_master", e?.target?.checked)
          }
        />
      </div>
      <div>
        <label htmlFor={"is_active_check"}>Активный</label>
        <Checkbox
          checked={!!filters?.is_active}
          id={"is_active_check"}
          onChange={e => handleFiltersChange("is_active", e?.target?.checked)}
        />
      </div>
    </div>
  );
};
