import React, { useEffect } from "react";
import styles from "./add_master.module.scss";
import { formatPhoneNumber } from "../../../../../utils";
import StarIcon from "@material-ui/icons/Star";
import clsx from "clsx";
import { Button, Popover } from "@material-ui/core";
import { DeleteIconWrapper, EditIconWrapper } from "../../../../common/blocks";
import { useState } from "react";
import { PhoneInput } from "../../../../common/form/InputNumberMask";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  phoneAddBtn: {
    padding: "5px",
    whiteSpace: "nowrap",
    backgroundColor: "#0C4B86",
    borderRadius: 4,
    color: "#fff",
    minWidth: "auto",

    "&:hover": {
      backgroundColor: "#0C4B86"
    },

    "& .MuiButton-startIcon": {
      margin: 0
    }
  },
  editIcon: {
    width: "18px",
    cursor: "pointer",
    color: "#1f5ac2",
    transition: "all .3s",
    "&:hover": {
      color: "#3768c4"
    }
  },
  deleteIcon: {
    color: "#b61818",
    transition: "all .3s",
    "&:hover": {
      color: "#c23737"
    }
  },
  starIcon: {
    color: "#ff0000",
    fill: "#ff0000"
  },
  starIconSmall: {
    width: "10px"
  },
  mainPhone: {
    position: "absolute",
    top: "-6px",
    right: "2px"
  }
}));

export const PhonesInput = ({ phone_numbers = [], handleChange }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState({});
  const [phones, setPhones] = useState(phone_numbers || []);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [activePhoneId, setActivePhoneId] = useState(null);

  useEffect(() => {
    handleChange({ phones });
  }, [phones, handleChange]);
  useEffect(() => {
    handleChange({ activePhoneId });
  }, [activePhoneId, handleChange]);
  useEffect(() => {
    handleChange({ phoneNumber });
  }, [phoneNumber, handleChange]);

  const handlePopoverOpen = (event, id) => {
    const { currentTarget } = event;
    setAnchorEl(prev => ({ ...prev, [id]: currentTarget }));
  };

  const handlePopoverClose = id => {
    setAnchorEl(prev => {
      return { ...prev, [id]: null };
    });
  };

  const onPhoneEditClick = id => {
    const { team_master_phone_number } = phones?.find(
      item => item?.team_master_phone_id === id
    );
    setPhoneNumber(team_master_phone_number);
    setActivePhoneId(id);
    handlePopoverClose(id);
  };

  const onPhoneDeleteClick = id => {
    const phone = phones?.find(item => item?.team_master_phone_id === id);
    let filtered = phones?.filter(item => item?.team_master_phone_id !== id);
    if (phone?.is_main_phone && !!filtered?.length) {
      filtered[0].is_main_phone = true;
    }
    setPhones([...filtered]);
    handlePopoverClose(id);
  };
  const makePhoneMain = id => {
    setPhones(prev =>
      prev?.map(item => {
        if (item?.team_master_phone_id === id)
          return { ...item, is_main_phone: true };
        return { ...item, is_main_phone: false };
      })
    );
    handlePopoverClose(id);
  };

  const onChangePhone = e => {
    setPhoneNumber(e.target.value);
  };

  const onAddClick = () => {
    if (phoneNumber?.length === 11) {
      setPhones(prev => {
        if (activePhoneId)
          return prev?.map(item => {
            if (item?.team_master_phone_id === activePhoneId)
              return { ...item, team_master_phone_number: phoneNumber };
            return item;
          });
        const isExist = prev?.find(
          item => item?.team_master_phone_number === phoneNumber
        );
        if (isExist) return prev;
        return [
          ...prev,
          {
            team_master_phone_number: phoneNumber,
            isNew: true,
            is_main_phone: !prev?.length,
            team_master_phone_id: new Date().getTime()
          }
        ];
      });
      if (activePhoneId) setActivePhoneId(null);
      setPhoneNumber("");
    }
  };
  return (
    <>
      <div className={styles.add_team__flex}>
        <PhoneInput
          fullWidth
          label="Номер телефона"
          placeholder="Введите номер телефона"
          wrapperClassname={styles.add_team__input_wrapper}
          className={styles.add_team__input_phone}
          value={phoneNumber}
          onChange={onChangePhone}
        />
        <Button
          startIcon={
            <AddCircleOutlineIcon className={styles.add_team__phoneIcon} />
          }
          className={classes.phoneAddBtn}
          onClick={onAddClick}
        />
      </div>
      <div className={styles.add_team__phone_wrapper}>
        {phones?.map(item => {
          return (
            <React.Fragment key={item?.team_master_phone_id}>
              <div
                className={styles.add_team__phone_item}
                onClick={e => handlePopoverOpen(e, item?.team_master_phone_id)}
                aria-owns={
                  !!anchorEl[item?.team_master_phone_id]
                    ? `mouse-over-popover_${item?.team_master_phone_id}`
                    : undefined
                }
                aria-haspopup="true"
              >
                {formatPhoneNumber(item?.team_master_phone_number)}
                {item?.is_main_phone && (
                  <div className={classes.mainPhone}>
                    <StarIcon
                      className={clsx(classes.starIcon, classes.starIconSmall)}
                    />
                  </div>
                )}
              </div>
              <Popover
                id={`mouse-over-popover_${item?.team_master_phone_id}`}
                sx={{
                  pointerEvents: "none"
                }}
                open={!!anchorEl[item?.team_master_phone_id]}
                anchorEl={anchorEl[item?.team_master_phone_id]}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                onClose={() => handlePopoverClose(item?.team_master_phone_id)}
                disableRestoreFocus
              >
                <div className={styles.add_team__phone_actions}>
                  <StarIcon
                    onClick={() => makePhoneMain(item?.team_master_phone_id)}
                    className={clsx(classes.editIcon, classes.starIcon)}
                  />
                  <EditIconWrapper
                    onClick={() => onPhoneEditClick(item?.team_master_phone_id)}
                  />
                  <DeleteIconWrapper
                    onClick={() =>
                      onPhoneDeleteClick(item?.team_master_phone_id)
                    }
                  />
                </div>
              </Popover>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export const getPhoneNumbers = (phonesInfo = {}) => {
  try {
    const { phoneNumber, activePhoneId, phones } = phonesInfo;
    let phone_numbers = [...phones];
    if (!phones?.length) {
      phone_numbers = [
        {
          team_master_phone_number: phoneNumber,
          is_main_phone: true,
          team_master_phone_id: null,
          isNew: true
        }
      ];
      if (!phoneNumber) {
        phone_numbers = "";
      }
    } else if (phoneNumber?.length === 11) {
      if (!!activePhoneId) {
        phone_numbers = phone_numbers.map(item => {
          if (item.team_master_phone_id === activePhoneId)
            return { ...item, team_master_phone_number: phoneNumber };
          return item;
        });
      } else
        phone_numbers.push({
          team_master_phone_number: phoneNumber,
          is_main_phone: false,
          team_master_phone_id: null
        });
    }
    return phone_numbers
      ? phone_numbers?.map(item => {
          return {
            ...item,
            team_master_phone_id: item?.isNew
              ? null
              : item?.team_master_phone_id,
            team_master_phone_number:
              typeof item?.team_master_phone_number === "number"
                ? item?.team_master_phone_number.toString()
                : item?.team_master_phone_number?.replace(/\D/g, "")
          };
        })
      : "";
  } catch (e) {
    return "";
  }
};
