import { MenuItem, Popover, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React, { useState } from "react";
import { remontTabKeys } from "./services";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MainTabs = ({ value, handleChange, tabsData, setMenuItem, menuItem }) => {
  const classes = useStyles();

  const initialTab = tabsData?.additionalTabs
    ? tabsData.additionalTabs?.find(
        (item) => item.grant_code === remontTabKeys[menuItem]
      )
    : null;

  const [state, setState] = useState({
    label: initialTab?.grant_name || "Другое",
    anchor: null,
  });

  const open = Boolean(state.anchor);
  const handleClick = (e) => {
    setState({ ...state, anchor: e.currentTarget });
  };

  const handleClose = () => {
    setState({ ...state, anchor: null });
  };

  const handleMenuItemClick = (item, label) => {
    const menuItem = Object.keys(remontTabKeys).find(
      (key) => remontTabKeys[key] === item
    );
    setState({ label, anchor: null });
    setMenuItem(menuItem);
  };

  return (
    <>
      <div>
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          indicatorColor="primary"
          textColor="primary"
          classes={{ root: classes.tabs_root, scroller: classes.tabs_scroller }}
        >
          {tabsData.mainTabs?.map((item) => {
            return (
              <Tab
                key={item.index}
                classes={{
                  root: classes.tabs_item,
                }}
                label={item?.grant_name || ""}
                {...a11yProps(item.index)}
              />
            );
          })}
          {!!tabsData.additionalTabs?.length && (
            <Tab
              classes={{
                root: classes.tabs_item,
              }}
              label={state.label || "Другое"}
              icon={
                <ArrowDropDownIcon
                  style={{
                    transform: "translateY(3px)",
                  }}
                />
              }
              style={{
                textTransform: "none",
              }}
              onClick={handleClick}
            />
          )}
        </Tabs>
      </div>
      <Popover
        onClose={handleClose}
        open={open}
        anchorEl={state.anchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {tabsData.additionalTabs?.map((item) => {
          return (
            <MenuItem
              key={item?.index}
              onClick={() =>
                handleMenuItemClick(item?.grant_code, item?.grant_name)
              }
            >
              {item?.grant_name || ""}
            </MenuItem>
          );
        })}
      </Popover>
    </>
  );
};

export default MainTabs;

export const useStyles = makeStyles(() => ({
  tabs_root: {
    minHeight: 64,
  },
  tabs_item: {
    fontSize: "14px",
    color: "#9194A9",
    textTransform: "capitalize",
    minWidth: 100,
    "&.MuiTab-labelIcon": {
      minHeight: 0,
    },
    "&:last-child": {
      "& .MuiTab-wrapper": {
        flexDirection: "row-reverse",
        transform: "translateY(-3px)",
      },
    },
  },
  tabs_scroller: {
    display: "flex",
  },
}));
