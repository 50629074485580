import React, { useEffect, useState } from "react";
import { Box, FormControl } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { usePenaltyStyles } from "./index";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { getContractors } from "../remonts/services/api";
import { fetchResidentList } from "../Problems/api";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

const Filters = ({ handleChange, filters, loading, totalSum }) => {
  const classes = usePenaltyStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [residents, setResidents] = useState([]);
  const [contractors, setContractors] = useState([]);

  useEffect(() => {
    getContractors()
      .then(res => {
        setContractors(res.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [addCloseSnack]);

  useEffect(() => {
    fetchResidentList()
      .then(res => {
        setResidents(res.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [addCloseSnack]);

  return (
    <CollapseBlock tabletMode className={classes.filtersWrapper}>
      <FormControl className={classes.filtersItem} disabled={loading}>
        <AutoSuggest
          list={residents}
          value={filters?.resident_id}
          showKey={"resident_name"}
          valueKey={"resident_id"}
          placeholder="Выберите ЖК"
          label="Жилой комплекс"
          onChange={e => handleChange("resident_id", e?.target?.value)}
        />
      </FormControl>
      <FormControl className={classes.filtersItem} disabled={loading}>
        <AutoSuggest
          list={contractors}
          value={filters?.contractor_id}
          showKey={"contractor_name"}
          valueKey={"contractor_id"}
          placeholder="Выберите подрядчика"
          label="Подрядчики"
          onChange={e => handleChange("contractor_id", e?.target?.value)}
        />
      </FormControl>
      <Box className={classes.rightContent}>
        Итого:{" "}
        <Box className={classes.textSum}>
          <NumberFormat
            value={Math.floor(totalSum)}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
          />
          тг
        </Box>
      </Box>
    </CollapseBlock>
  );
};

export default Filters;
