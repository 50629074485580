import React, { useState } from "react";
import clsx from "clsx";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import styles from "./styles.module.scss";

export const CollapseBlock = ({ className, tabletMode, onChange, children, title }) => {
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed(prev => !prev);
    if (onChange)
      onChange(!collapsed);
  };
  return (
    <div title={title || (collapsed ? 'Развернуть' : 'Свернуть')} className={clsx([`${className || ""} ${styles.collapseBlock}`, {
      [styles.collapseBlock__collapsed]: collapsed,
      [styles.collapseBlock__tablet]: tabletMode
    }])}>
      {children}
      <div className={clsx([styles.collapseBlock__expand, {
        [styles.collapseBlock__expand_active]: collapsed,
        [styles.collapseBlock__expand_tablet]: !!tabletMode
      }])} onClick={toggleCollapse}>
        <KeyboardArrowDownIcon
          className={clsx([styles.collapseBlock__expand_collapse, { [styles.collapseBlock__expand_collapsed]: collapsed }])} />
      </div>
    </div>
  );
};

