export const cc_format = (value, iban = false) => {
  if (!value) return "";
  try {
    const v = iban
      ? value.replace(/\s+/g, "")
      : value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    const matches = iban ? v.match(/\w{4,20}/g) : v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || "";
    const parts = [];
    for (let i = 0; i < match.length; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  } catch (e) {
    return value;
  }
};

export const formatPhoneNumber = phone => {
  if (!phone) return "";
  try {
    let phoneNumberString = phone;
    if (phone.length > 10) phoneNumberString = phoneNumberString.slice(1);
    const splited = phone.split(phoneNumberString);
    const code = `+${splited[0] || splited[1] || "7"}`;
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      const intlCode = match[1] ? "+" : "";
      return `${code} ${[
        intlCode,
        "(",
        match[2],
        ") ",
        match[3],
        "-",
        match[4],
        "-",
        match[5]
      ].join("")}`;
    }
    return phoneNumberString;
  } catch (e) {
    return phone;
  }
};
export const numberWithCommas = (x, noDataText = "") => {
  try {
    if (x === undefined || x === null) return noDataText || "";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } catch (e) {
    return "";
  }
};

export const getLocaleDate = (date, withTime = false) => {
  if (!date) return "";
  try {
    const dateString = new Date(date).toLocaleDateString("ru");
    if (dateString === "Invalid Date") {
      const newDate = date?.replaceAll(".", "/");
      const dateString = new Date(newDate).toLocaleDateString("ru");
      if(withTime)
        return `${dateString}, ${new Date(newDate).toLocaleTimeString()}`
      return dateString;
    }
    if(withTime)
      return `${dateString}, ${new Date(date).toLocaleTimeString()}`
    return dateString;
  } catch (e) {
    return "";
  }
};

export const getValidDate = date => {
  if (!date) return "";
  try {
    const year = new Date(date).getFullYear().toString();
    const day = new Date(date).getDate().toString();
    const month = new Date(date).getMonth();
    const monthString = (month + 1).toString();
    return `${year}-${
      monthString?.length === 1 ? `0${monthString}` : monthString
    }-${day?.length === 1 ? `0${day}` : day}`;
  } catch (e) {
    return "";
  }
};
export const isChrome = () => {
  return navigator.userAgent.indexOf("Chrome") !== -1;
};

export const htmlDecode = input => {
  if (!input) return "";
  try {
    const e = document.createElement("div");
    e.innerHTML = input;
    let returnString = "";
    for (let index = 0; index < e.childNodes.length; index++) {
      // case of just a string
      if (e.childNodes[index].nodeValue) {
        returnString += e.childNodes[index].nodeValue;
      }
      // case of HTML
      if (e.childNodes[index].outerHTML) {
        returnString += e.childNodes[index].outerHTML;
      }
    }
    return returnString;
  } catch (e) {
    return "";
  }
};

export function checker(value) {
  return value < 10 ? "0" + value : value;
}

export const kbeOptions = [
  {
    id: "19",
    kbe_name: "Физ лицо резидент РК"
  },
  {
    id: "29",
    kbe_name: "Физ лицо нерезидент РК"
  }
];
export const convertArrayBufferToStr = data => {
  if (!data) return {};
  const decoder = new TextDecoder("utf-8");
  const response = decoder.decode(data);
  return JSON.parse(response);
};

export const cropWord = (word = "", maxLength = 25, defaultStr = "") => {
  if (!word) return defaultStr;
  try {
    const cropped = word.slice(0, maxLength);
    if (word?.length > maxLength) return `${cropped}...`;
    return cropped;
  } catch (e) {
    return defaultStr;
  }
};
export const isArray = value => {
  return Array.isArray(value);
};


const minHeight = 450;
export const getTableMaxHeight = (maxHeight) => {
  if (maxHeight) {
    return maxHeight < minHeight ? minHeight : maxHeight;
  }
  const defaultMaxHeight = window.innerHeight - 200;
  return defaultMaxHeight < minHeight ? minHeight : defaultMaxHeight;
};

export const removeDangerSymbols = (str = "") => {
  if (!str) return "";
  try {
    const dangerousSymbolPattern = /[<>&'"\/\\`|!${}\[\]=+@]/g;
    return str.replaceAll(dangerousSymbolPattern, "");
  } catch (e) {
    return "";
  }
};

export const getLastAndFirstDayOfMonth = () => {
  try {
    const now = new Date();
    const year = now.getFullYear();
    let month = String(now.getMonth() + 1); // Month is zero-based, so add 1
    const lastDay = new Date(year, month, 0).getDate();
    if (month.length === 1)
      month = `0${month}`;
    return { first: `01.${month}.${year}`, last: `${lastDay}.${month}.${year}` };
  } catch (e) {
    return { first: '', last: '' };
  }
};