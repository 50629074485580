import React, { useEffect, useState } from "react";
import styles from "../team_detail.module.scss";
import clsx from "clsx";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../../common/CustomTable/CustomTable";
import { showPopUp } from "../../../../../services/redux/actions/app-actions";
import { useDispatch } from "react-redux";
import { ActiveCheckbox } from "../../index";
import { CustomLoader } from "../../../../common/CustomLoader";
import { useTableStyles } from "../../../accounting/styles";
import {
  DeleteIconWrapper,
  DocIconWrapper,
  EditIconWrapper
} from "../../../../common/blocks";
import { masterTypes, MODALS_NAMES } from "../../../../../constants";
import { getServiceMasters, serviceMastersColumns } from "../../services";
import { MasterPhoneNumbers } from "./MasterPhoneNumbers";
import { Button } from "@material-ui/core";
import { useHttp } from "../../../../../utils/hooks/http.hook";
import { useCustomSnackbar } from "../../../../../utils/hooks/useCustomSnackbar";

export const ServiceMasters = () => {
  const dispatch = useDispatch();
  const classesTable = useTableStyles();
  const { request } = useHttp();
  const { addDefaultSnack } = useCustomSnackbar();
  const [fetching, setFetching] = useState(false);
  const [masters, setMasters] = useState([]);
  const [teamId, setTeamId] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        setFetching(true);
        const data = await getServiceMasters();
        setFetching(false);
        const { team_id, masters } = data;
        setMasters(masters);
        setTeamId(team_id);
      } catch (e) {
        setFetching(false);
      }
    };
    getData();
  }, []);

  const onDocClick = team_master => {
    dispatch(
      showPopUp(true, MODALS_NAMES.teamMasterDocuments, {
        team_master_id: team_master.team_master_id,
        title: `Список документов мастера (${team_master.team_master_fio})`,
        close: true
      })
    );
  };
  const onDeleteClick = id => {
    dispatch(
      showPopUp(true, MODALS_NAMES.confirmModal, {
        onSubmit: handleDelete,
        id
      })
    );
  };

  const onEditClick = body => {
    dispatch(
      showPopUp(true, MODALS_NAMES.AddExternalTeamMaster, {
        onSubmit: onSubmitUpdate,
        body,
        type: masterTypes.service
      })
    );
  };

  const onAddClick = () => {
    dispatch(
      showPopUp(true, MODALS_NAMES.AddExternalTeamMaster, {
        onSubmit,
        type: masterTypes.service
      })
    );
  };

  const onSubmit = async data => {
    const res = await request(
      `partner/teams/${teamId}/masters/`,
      "post",
      {
        ...data,
        result_type: masterTypes.service,
        master_type_id: Number(data?.master_type_id)
      },
      {},
      { isModal: true }
    );
    if (res?.status) {
      addDefaultSnack("Мастер успешно создан");
      setMasters(res?.team_masters || res?.masters);
      dispatch(showPopUp(false));
    }
  };

  const onSubmitUpdate = async data => {
    if (!data) return;
    const res = await request(
      `partner/teams/${teamId}/masters/${data?.team_master_id}/update_master/`,
      "put",
      {
        ...data,
        result_type: masterTypes.service,
        master_type_id: Number(data?.master_type_id)
      },
      {},
      { isModal: true }
    );
    if (res?.status) {
      addDefaultSnack("Мастер успешно изменен");
      const { team_masters, masters } = res;
      setMasters(team_masters || masters);
      dispatch(showPopUp(false));
    }
  };

  const handleDelete = async team_master_id => {
    const res = await request(
      `/partner/teams/${teamId}/masters/${team_master_id}/delete_master/`,
      "delete",
      {
        team_master_id,
        params: {
          result_type: masterTypes.service
        }
      },
      {},
      { isModal: true }
    );
    if (res?.status) {
      addDefaultSnack("Мастер успешно удален");
      setMasters(res?.team_masters || res?.masters);
      dispatch(showPopUp(false));
    }
  };

  return (
    <>
      {fetching ? (
        <CustomLoader />
      ) : (
        <div>
          <div className={styles.team_detail__header}>
            <h2>Мастера по услугам</h2>
          </div>
          <Button
            className={clsx(classesTable.btn)}
            variant="outlined"
            disabled={!teamId}
            onClick={onAddClick}
          >
            Добавить
          </Button>
          <CustomTable
            headItems={serviceMastersColumns}
            stickyHeader
            isEmpty={!masters?.length}
            containerClassName={styles.team_detail__table}
          >
            {masters?.map(item => {
              return (
                <CustomTableRow key={item?.team_master_id}>
                  <CustomTableCell align="left">
                    {item?.team_master_fio || ""}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    {item.team_master_doc_num}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    {item?.specialities
                      ?.map(item => item.speciality_name)
                      .join(", ") || ""}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    <MasterPhoneNumbers phone_numbers={item?.phone_numbers} />
                  </CustomTableCell>
                  <CustomTableCell
                    align="center"
                    className={clsx(
                      item.is_active
                        ? classesTable.active
                        : classesTable.inActive
                    )}
                  >
                    {!!item.is_active && <ActiveCheckbox />}
                  </CustomTableCell>
                  <CustomTableCell align="center">
                    <div className={classesTable.actions}>
                      <DocIconWrapper onClick={() => onDocClick(item)} />
                      <EditIconWrapper onClick={() => onEditClick(item)} />
                      <DeleteIconWrapper
                        onClick={() => onDeleteClick(item?.team_master_id)}
                      />
                    </div>
                  </CustomTableCell>
                </CustomTableRow>
              );
            })}
          </CustomTable>
        </div>
      )}
    </>
  );
};
