import React, { useContext, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { TableContext } from "./index";
import { CustomSearch } from "../../components/common/form/CustomSearch";
import UtilityBtn from "../remonts/RemontInfo/UtilityBtn";
import { useDownloadFile } from "../remonts/RemontInfo/Gpr/useDownloadFile";
import DownloadXlsx from "../../components/common/DownloadXlsx";
import { InputSumMask } from "../../components/common/form/InputSumMask";
import { CButton } from "../../components/common/buttons";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { getContractors } from "../remonts/services/api";
import { useDispatch, useSelector } from "react-redux";
import { getStatusGroupsData } from "../../services/redux/actions/app-actions";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

const useStyles = makeStyles({
  xlsx_button: {
    height: 37,
    margin: 0
  },
  filters: {
    display: "flex",
    alignItems: "flex-end",
    flexWrap: 'wrap',
    gap: "15px"
  },
  filtersRemont: {
    marginTop: "-20px"
  },
  filtersSubmit: {
    width: "auto",
    backgroundColor: "#4a78d2"
  },
  actions: {
    display: "flex",
    flexWrap: 'wrap',
    alignItems: "center",
    gap: "15px",
    justifyContent: "flex-end",

    '@media (max-width: 576px)': {
      justifyContent: "flex-start",
      gap: 10,
    }
  },
  header: {
    display: "flex",
    flexWrap: 'wrap',
    alignItems: "flex-end",
    justifyContent: "space-between",
    gap: "10px",
    marginBottom: 10
  }
});

const ContractJournalHeader = ({
  filters,
  onFiltersChange,
  loading,
  getDataByParams
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addCloseSnack } = useCustomSnackbar();
  const [, , searchTerm, setSearchTerm] = useContext(TableContext);
  const { downloadFile, downloading } = useDownloadFile();
  const [contractors, setContractors] = useState([]);
  const { statusGroups } = useSelector(state => state.appState);

  useEffect(() => {
    dispatch(getStatusGroupsData());
  }, [dispatch]);

  useEffect(() => {
    getContractors().then(answer => {
      setContractors(answer.data);
    });
  }, [addCloseSnack]);

  const handleChange = e => {
    setSearchTerm(e.target.value);
  };

  const params = useMemo(() => {
    return { ...filters, remont_id: Number(filters.remont_id) || "" };
  }, [filters]);
  return (
      <CollapseBlock className={classes.header} >
        <Box className={classes.filters}>
          <Box width={270}>
            <CustomSearch value={searchTerm} handleChange={handleChange} />
          </Box>
          <Box width={180}>
            <AutoSuggest
              list={contractors}
              showKey={"contractor_name"}
              valueKey={"contractor_id"}
              name={"contractor_id"}
              value={filters?.contractor_id}
              withMargin={false}
              onChange={onFiltersChange}
              placeholder="Подрядчики"
              label="Подрядчики"
              defaultValue={""}
            />
          </Box>
          <Box width={180}>
            <AutoSuggest
              list={statusGroups}
              showKey={"contractor_agreement_list_status_group_name"}
              valueKey={"contractor_agreement_list_status_id"}
              name={"contractor_agreement_list_status_id"}
              value={filters?.contractor_agreement_list_status_id}
              withMargin={false}
              onChange={onFiltersChange}
              placeholder="Статус"
              label="Статус"
              defaultValue={""}
            />
          </Box>
          <Box width={120}>
            <InputSumMask
              type="number"
              fullWidth
              min={0} integer mask={false}
              name={"remont_id"}
              onChange={onFiltersChange}
              label="ID ремонта"
              placeholder="Введите ID ремонта"
              classname={classes.filtersRemont}
              value={filters.remont_id}
              onEnter={getDataByParams}
            />
          </Box>
          <CButton
            disabled={loading}
            loading={loading}
            className={classes.filtersSubmit}
            onClick={getDataByParams}
          >
            Поиск
          </CButton>
        </Box>
        <Box className={classes.actions}>
          <UtilityBtn
            text="Договор присоединения Скачать (PDF)"
            height={37} wrap
            handleClick={() =>
              downloadFile(
                "/partner/contractor_agreement_list/accession_agreement/pdf/",
                "Договор присоединения",
                params,
                "pdf"
              )
            }
            disabled={downloading}
          />
          <DownloadXlsx
            url="/partner/contractor_agreement_list/xlsx/"
            params={params}
            title="Журнал договоров"
            className={classes.xlsx_button}
          />
        </Box>
      </CollapseBlock>
  );
};

export default ContractJournalHeader;
