import React, { useCallback, useEffect, useState } from "react";
import styles from "./accounting.module.scss";
import { PageTitle } from "../../../common/pageTitle";
import { getRemontsAccounting } from "./api";
import { numberWithCommas } from "../../../../utils";
import DownloadXlsx from "../../../common/DownloadXlsx";
import { CustomVirtualTable } from "../../../common/CustomTable/CustomVirtualTable";

export const RemontsAccountingReport = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState(null);

  const getDebtData = useCallback(async params => {
    try {
      setIsFetching(true);
      const res = await getRemontsAccounting(params);
      setIsFetching(false);
      if (res) {
        setData(res?.data);
      }
    } catch (e) {
      setIsFetching(false);
    }
  }, []);

  useEffect(() => {
    getDebtData();
  }, [getDebtData]);

  return (
    <>
      <PageTitle title={"Отчет по расходам и доходам"} />
      <div className={styles.amount}>
        <div className={styles.amount__header}>
          <DownloadXlsx
            url="/partner/accounting/report/"
            withoutMargin
            title="Отчет долгов бригады"
            reqType="get"
          />
        </div>
        <CustomVirtualTable
          isFetching={isFetching}
          headItems={headItems}
          cellsList={cellsList}
          stickyHeader
          gridTemplateColumns={"65px minmax(150px, 12fr) 90px 90px 90px"}
          data={data}
          rowHeight={35}
          keyOption={"remont_id"}
          isEmpty={!data?.length}
        />
      </div>
    </>
  );
};

const cellsList = [
  { key: "remont_id" },
  { key: "remont_information", style: {lineHeight: '14px'} },
  {
    key: "income_sum",
    align: "right",
    render: item => numberWithCommas(item.income_sum)
  },
  {
    key: "cost_sum",
    align: "right",
    render: item => numberWithCommas(item.cost_sum)
  },
  {
    key: "profit",
    align: "right",
    render: item => numberWithCommas(item.profit)
  }
];

const headItems = [
  { label: "Ремонт ID", align: "left" },
  { label: "Ремонт", align: "left" },
  { label: "Доходы", align: "right" },
  { label: "Расходы", align: "right" },
  { label: "Разница", align: "right" }
];
