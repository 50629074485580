//Получить Список ЖК с количеством квартир
import instance from "../../../services/api";

export const getResidentsList = (params = { status_id: "", stage_id: "", service_status: null }) => {
  return instance()
    .get(`/partner/remont_constructor/residents/`, { params })
    .then(res => res?.data);
};
export const getStagesList = () => {
  return instance().get(`/partner/stages/all/`);
};
export const getTechProjectEmployees = () => {
  return instance()
    .get("/partner/tech_project_employees/")
    .then(res => res?.data);
};
export const getTechProjectDevelopers = () => {
  return instance()
    .get("/partner/commons/techproject_developers/")
    .then(res => res?.data?.techproject_developers);
};
export const checkRemontPermission = remont_id => {
  return instance()
    .get(`/partner/remont/${remont_id}/check_permission/`)
    .then(res => res?.data);
};

//Получить определенную квартиру
export const getResidentByRemontId = remont_id => {
  return instance().get(`/partner/remont_constructor/remont/${remont_id}`);
};

export const getStatusGroups = () => {
  return instance(true)
    .get("/partner/commons/contractor_agreement_list_statuses/")
    .then(res => res?.data?.status_groups);
};

export const getContractors = () => {
  return instance(true).get("/partner/contractors/");
};

export const getMastersList = () => {
  return instance(true).get("/partner/masters/");
};

export const getRooms = remont_id => {
  return instance(true)
    .get(`/partner/remont/${remont_id}/rooms/`)
    .then((res = {}) => {
      return res?.data;
    });
};

export const getMeasureRooms = params => {
  return instance(true)
    .post(`/partner/measurements/`, params)
    .then((res = {}) => {
      return res?.data;
    });
};

export const getSmetaDiff = params => {
  return instance(true)
    .post(`/partner/measurements/remont_smeta_difference/`, params)
    .then((res = {}) => res?.data);
};

export const deleteWork = (remont_id, params) => {
  return instance(true).post(
    `/partner/remont_constructor/remont/${remont_id}/works/delete/`,
    params
  );
};

export const getDiscrepanciesReport = remont_id => {
  return instance(true)
    .get(`/partner/measurements/remont/${remont_id}/smeta_diff_actual/`)
    .then((res = {}) => res?.data);
};

export const getStages = remont_id => {
  return instance(true)
    .get(`/partner/remont/${remont_id}/stages/`)
    .then(res => res?.data);
};

export const getCallRequests = remont_id => {
  return instance(true)
    .get(`/partner/remont_work_set/${remont_id}/check_group_calls/read/`)
    .then(res => res?.data);
};

export const getDocs = remont_id => {
  return instance(true)
    .get(`/partner/remont/${remont_id}/documents/`)
    .then((res = {}) => res?.data);
};

export const getCheckListByStages = remont_id => {
  return instance(true)
    .get(`/partner/remont/${remont_id}/checklist_stages/`)
    .then((res = {}) => res?.data);
};
export const getCheckListByWorksets = remont_id => {
  return instance(true)
    .get(`/partner/remont/${remont_id}/remont_check_list/`)
    .then((res = {}) => res?.data);
};

export const getCheckListPhotos = (remont_id, checklist_id) => {
  return instance(true).get(
    `/partner/remont/${remont_id}/checklist_stages/${checklist_id}/photos/`
  );
};
