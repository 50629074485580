import React, { useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import clsx from "clsx";
import ReportCard from "./reportCard";
import { Collapse, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";
import ReportHeadTop from "./reportHeadTop";
import TableHead from "@material-ui/core/TableHead";

const useStyles = makeStyles({
  table: {
    minWidth: 300,
    borderCollapse: "collapse",
    borderRadius: 4,
    boxShadow: "0px 5px 20px rgb(108 108 108 / 10%)"
  },
  mainTable: {
    marginBottom: "10px"
  },
  root: {
    width: "100%"
  },
  row: {
    marginBottom: 10,
    verticalAlign: "top",
    borderRadius: 4
  },
  row_table: {
    marginBottom: 10,
    verticalAlign: "top",
    borderRadius: 4,
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)"
  },
  row_card: {
    padding: 0
  },
  row_comment_open: {
    padding: 0,
    backgroundColor: "#0C4B86"
  },
  row_comment_hide: {
    padding: 0,
    backgroundColor: "#FFFFFF"
  },
  open: {
    display: "none"
  },
  heading: {
    color: "#0C4B86"
  },
  icon: {
    float: "right",
    cursor: "pointer"
  },
  td_open: {
    backgroundColor: "#0C4B86"
  },
  row_td: {
    borderRight: "1px solid #F0F2F6"
  },
  td_bottom: {
    borderLeft: "1px solid #F0F2F6",
    paddingBottom: 8
  },
  highlited: {
    backgroundColor: "#ffe8e8"
  },
  head_wrapper: {
    marginBottom: 20,
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)",
    borderRadius: 4
  },
  header: {
    background: "#ECEEFD",
    cursor: "pointer",
    marginTop: 10
  },

  not_active_heading: {
    color: "#a3a3a3"
  },
  default_cursor: {
    cursor: "default"
  },
  active_td: {
    cursor: "pointer",
    transition: "all .2s linear",
    "&:hover": {
      backgroundColor: "#FAFAFF"
    }
  },
  outdated: {
    background: "#ffe8e8"
  }
});
const ReportOkkList = ({
  idx,
  stage_1_delay,
  fact_stage_1_begin_date,
  fact_stage_1_end_date,
  gpr_stage_1_begin_date,
  gpr_stage_1_end_date,
  stage_1_check_cnt,
  stage_1_defect_attempt,
  stage_1_duration,
  currentStageCode,
  stage_2_delay,
  fact_stage_2_begin_date,
  fact_stage_2_end_date,
  gpr_stage_2_begin_date,
  gpr_stage_2_end_date,
  stage_2_check_cnt,
  stage_2_defect_attempt,
  stage_2_duration,
  stage_3_delay,
  fact_stage_3_begin_date,
  fact_stage_3_end_date,
  gpr_stage_3_begin_date,
  gpr_stage_3_end_date,
  stage_3_check_cnt,
  stage_3_defect_attempt,
  stage_3_duration,
  remont_id,
  showModal,
  arr
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const stages = [stage_1_delay, stage_2_delay, stage_3_delay];
  const isOutdated = stages.some(el => el > 0);
  const handleHeadClick = e => {
    const condValues = ["сдача клиенту", "вызов окк"];
    if (!condValues.includes(e.target.textContent.toLowerCase())) {
      setOpen(!open);
    }
  };
  return (
    <>
      <Table
        className={clsx(classes.table, classes.mainTable)}
        aria-label="simple table"
      >
        <TableHead
          onClick={handleHeadClick}
          className={clsx(classes.header, {
            [classes.outdated]: isOutdated === true
          })}
        >
          <ReportHeadTop
            data={arr}
            stage_1_delay={stage_1_delay}
            stage_2_delay={stage_2_delay}
            stage_3_delay={stage_3_delay}
          />
        </TableHead>
      </Table>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <TableContainer
          component={Paper}
          className={classes.row_table}
          key={idx}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableBody>
              <TableRow className={classes.row}>
                <TableCell
                  colSpan={2}
                  className={clsx(classes.row_card, {
                    [classes.highlited]: stage_1_delay > 0
                  })}
                >
                  <ReportCard
                    title="1 Этап"
                    factStageBegin={fact_stage_1_begin_date}
                    factStageEnd={fact_stage_1_end_date}
                    gprStageBegin={gpr_stage_1_begin_date}
                    gprStageEnd={gpr_stage_1_end_date}
                    stageCheckCnt={stage_1_check_cnt}
                    stageDefectAttempt={stage_1_defect_attempt}
                    stageDelay={stage_1_delay}
                    stageDuration={stage_1_duration}
                    currentStageCode={currentStageCode}
                  />
                </TableCell>
                <TableCell
                  colSpan={2}
                  className={clsx(classes.row_card, {
                    [classes.highlited]: stage_2_delay > 0
                  })}
                >
                  <ReportCard
                    title="2 Этап"
                    factStageBegin={fact_stage_2_begin_date}
                    factStageEnd={fact_stage_2_end_date}
                    gprStageBegin={gpr_stage_2_begin_date}
                    gprStageEnd={gpr_stage_2_end_date}
                    stageCheckCnt={stage_2_check_cnt}
                    stageDefectAttempt={stage_2_defect_attempt}
                    stageDelay={stage_2_delay}
                    stageDuration={stage_2_duration}
                    currentStageCode={currentStageCode}
                  />
                </TableCell>
                <TableCell
                  colSpan={2}
                  className={clsx(classes.row_card, {
                    [classes.highlited]: stage_3_delay > 0
                  })}
                >
                  <ReportCard
                    title="3 Этап"
                    factStageBegin={fact_stage_3_begin_date}
                    factStageEnd={fact_stage_3_end_date}
                    gprStageBegin={gpr_stage_3_begin_date}
                    gprStageEnd={gpr_stage_3_end_date}
                    stageCheckCnt={stage_3_check_cnt}
                    stageDefectAttempt={stage_3_defect_attempt}
                    stageDelay={stage_3_delay}
                    stageDuration={stage_3_duration}
                    currentStageCode={currentStageCode}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <TableCell
                  colSpan={2}
                  className={clsx(classes.td_bottom, {
                    [classes.active_td]: stage_1_check_cnt > 0
                  })}
                  onClick={() => {
                    if (stage_1_check_cnt !== 0) {
                      showModal(remont_id, 3);
                    }
                  }}
                >
                  <Typography
                    className={clsx(classes.heading, {
                      [classes.not_active_heading]: stage_1_check_cnt === 0
                    })}
                  >
                    Замечании ОКК - {stage_1_check_cnt}
                    {stage_1_check_cnt === 0 ? (
                      <InfoIcon
                        className={clsx(classes.icon, {
                          [classes.default_cursor]: stage_1_check_cnt === 0
                        })}
                      />
                    ) : (
                      <InfoIcon className={classes.icon} />
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  colSpan={2}
                  className={clsx(classes.td_bottom, {
                    [classes.active_td]: stage_2_check_cnt > 0
                  })}
                  onClick={() => {
                    if (stage_2_check_cnt > 0) {
                      showModal(remont_id, 4);
                    }
                  }}
                >
                  <Typography
                    className={clsx(classes.heading, {
                      [classes.not_active_heading]: stage_2_check_cnt === 0
                    })}
                  >
                    Замечании ОКК - {stage_2_check_cnt}
                    {stage_2_check_cnt === 0 ? (
                      <InfoIcon
                        className={clsx(classes.icon, {
                          [classes.default_cursor]: stage_2_check_cnt === 0
                        })}
                      />
                    ) : (
                      <InfoIcon className={classes.icon} />
                    )}
                  </Typography>
                </TableCell>
                <TableCell
                  colSpan={2}
                  className={clsx(classes.td_bottom, {
                    [classes.active_td]: stage_3_check_cnt > 0
                  })}
                  onClick={() => {
                    if (stage_3_check_cnt > 0) {
                      showModal(remont_id, 5);
                    }
                  }}
                >
                  <Typography
                    className={clsx(classes.heading, {
                      [classes.not_active_heading]: stage_3_check_cnt === 0
                    })}
                  >
                    Замечании ОКК - {stage_3_check_cnt}
                    {stage_3_check_cnt === 0 ? (
                      <InfoIcon
                        className={clsx(classes.icon, {
                          [classes.default_cursor]: stage_3_check_cnt === 0
                        })}
                      />
                    ) : (
                      <InfoIcon className={classes.icon} />
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </>
  );
};

export default ReportOkkList;
