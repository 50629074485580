import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { fetchResidentList } from "../Problems/api";
import { fetchRemontWorks, getFlatsList } from "./api";
import WorksReportTable from "./worksReportTable";
import { PageTitle } from "../../components/common/pageTitle";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { CustomDatePicker } from "../../components/common/form/CustomDatePicker";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

export const useStyles = makeStyles({
  header: {
    display: "flex",
    alignItems: "flex-end",
    flexWrap: 'wrap  '
  },
  wrapper: {
    padding: 20
  },
  content: {
    marginTop: 20
  }
});

const initialState = {
  resident_id: "",
  remont_id: "",
  date_begin: null,
  date_end: null
};

export const WorksReport = () => {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [filters, setFilters] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [residentList, setResidentList] = useState([]);
  const [flatsList, setFlatsList] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchResidentList()
      .then(res => {
        setResidentList(res.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [addCloseSnack]);

  const { resident_id, remont_id, date_begin, date_end } = filters;

  const handleChange = event => {
    const { name, value } = event.target;
    setFilters(prev => ({ ...prev, [name]: value }));

    if (name === "resident_id") {
      if (!value) return setFlatsList([]);
      getFlatsList(value)
        .then(res => {
          setFlatsList(res.data);
        })
        .catch(err => {
          addCloseSnack(err?.response?.data?.error);
        });
    }
  };

  const getRemontWorks = useCallback(() => {
    setIsLoading(true);
    fetchRemontWorks(filters)
      .then(res => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [filters]);

  useEffect(() => {
    getRemontWorks();
  }, [getRemontWorks]);

  return (
    <>
      <PageTitle title={"Отчет по выполненным работам"} />
      <Box className={classes.wrapper}>
        <CollapseBlock tabletMode className={classes.header}>
          <Box width={200} mr={3}>
            <AutoSuggest
              list={residentList}
              value={resident_id}
              showKey={"resident_name"}
              valueKey={"resident_id"}
              name={"resident_id"}
              placeholder="Выберите ЖК"
              label="Жилой комплекс"
              onChange={handleChange}
            />
          </Box>
          <Box width={200} mr={3}>
            <AutoSuggest
              list={flatsList}
              value={remont_id}
              showKey={"flat_num"}
              valueKey={"remont_id"}
              name={"remont_id"}
              placeholder="Все квартиры"
              label={"Номер квартиры"}
              onChange={handleChange}
            />
          </Box>
          <Box width={200} mr={3}>
            <CustomDatePicker
              fullWidth
              placeholder="От"
              label={"Дата"}
              name="date_begin"
              value={date_begin}
              onChange={handleChange}
            />
          </Box>
          <Box width={200}>
            <CustomDatePicker
              fullWidth
              placeholder="До"
              label={"Дата"}
              name={"date_end"}
              value={date_end}
              onChange={handleChange}
            />
          </Box>
        </CollapseBlock>
        <WorksReportTable data={data} isLoading={isLoading} />
      </Box>
    </>
  );
};

export default WorksReport;
