import instance from "../../services/api";

export const getFlatsList = resident_id => {
  return instance(true).get(`/partner/resident/${resident_id}/flats/`);
};
export const fetchRemontWorks = params => {
  return instance(true)
    .post(`/partner/remont_works_report/`, params)
    .then((res = {}) => {
      return res;
    });
};
