import React, { useCallback, useEffect, useState } from "react";
import { reportCostOfPayment } from "./api";
import { makeStyles } from "@material-ui/core/styles";
import ReportPartnerPaymentTable from "./reportPartnerPaymentTable";
import Typography from "@material-ui/core/Typography";
import EmptyOrShow from "components/common/EmptyOrShow";
import ReportFilter from "./reportFilter";
import NumberFormat from "react-number-format";
import { PageTitle } from "../../components/common/pageTitle";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

const useStyles = makeStyles(() => ({
  wrapper: { padding: 20, margin: 0 },
  noPadding: {
    padding: 0,
    margin: 0
  },
  totalSum: {
    color: "#494C62",
    fontSize: 18,
    fontWeight: 600
  },
  header: {
    display: "flex",
    flexWrap: 'wrap',
    alignItems: "center",
    justifyContent: "space-between"
  },
  rightContent: { float: "right", marginBottom: 5 },
  rightContentText: { color: "#9194A9", fontSize: 12, fontWeight: 600 }
}));

export const ReportPartnerPayment = () => {
  document.title = "Расшифровка оплаты подрядчику";
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    date_begin: "",
    date_end: "",
    contractor_id: null
  });

  const handleChange = e => {
    const name = e.target.name;
    setState(prev => ({
      ...prev,
      [name]: !!e.target.value
        ? e.target.value
        : name === "contractor_id"
        ? null
        : ""
    }));
  };

  const fetchReport = useCallback(() => {
    setLoading(true);
    reportCostOfPayment(state)
      .then(res => {
        setLoading(false);
        if (!!res?.data?.error) return addCloseSnack(res?.data?.error);
        setData(res.data);
      })
      .catch(err => {
        setLoading(false);
        addCloseSnack(err?.response?.data?.error);
      });
  }, [addCloseSnack, state]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  return (
    <>
      <PageTitle title={"Расшифровка оплаты подрядчику"} />
      <div className={classes.wrapper}>
        <CollapseBlock tabletMode className={classes.header}>
          <ReportFilter state={state} handleChange={handleChange} />
          <div className={classes.rightContent}>
            <Typography
              variant="h6"
              component="h2"
              className={classes.rightContentText}
            >
              Общая сумма
            </Typography>
            <Typography className={classes.totalSum} color="textSecondary">
              <NumberFormat
                value={data["total_sum"]}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
              />
            </Typography>
          </div>
        </CollapseBlock>
        <EmptyOrShow loading={loading}>
          <ReportPartnerPaymentTable data={data["data"]} />
        </EmptyOrShow>
      </div>
    </>
  );
};
