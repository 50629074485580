import instance from "../../services/api";

export const getExpectedPayments = (resident_id, date_begin, date_end) => {
  return instance(true)
    .get(
      `/partner/resident/${resident_id}/expected_payments/?date_begin=${date_begin}&date_end=${date_end}`
    )
    .then((res = {}) => {
      return res;
    });
};
