import {
  RESET_APP_STATE,
  SET_AUTH,
  SET_BIK_OPTIONS, SET_CITIES, SET_COMPANIES,
  SET_CONTRACTORS,
  SET_COST_MASTERS, SET_COST_STATUSES,
  SET_DATA_USER,
  SET_FILTER_FIELDS,
  SET_HEAD_TEAM_EMPLOYEES,
  SET_HEADER, SET_HEADER_BACK,
  SET_INIT,
  SET_INTEGRATED_PAYMENT_TYPES,
  SET_LIMITS,
  SET_MASTER_DOCUMENT_TYPES,
  SET_MASTER_TYPES,
  SET_MASTERS,
  SET_MATERIAL_SHIP,
  SET_MENU,
  SET_MENU_STATE,
  SET_MODAL_LOADING,
  SET_OKK_DATA, SET_PAGE_TITLE,
  SET_REMONTS,
  SET_RESIDENTS,
  SET_ROLES,
  SET_STAGES,
  SET_STATUS_GROUPS,
  SET_TEAMS,
  SHOW_POPUP
} from "../actions/app-actions";

const initialState = {
  init: false,
  isUserAuthenticated: false,
  dataUser: null,
  menu: null,
  cities: [],
  companies: [],
  pageTitle: null,
  headerBack: null,
  isMainMenuOpen: window?.innerWidth > 768,
  popup: {
    show: false,
    type: "",
    data: {}
  },
  okkData: [],
  materialShip: {},
  header: {
    showBack: true,
    isList: false,
    isTaskList: false,
    isProcessList: false,
    showCancel: false
  },
  filterFields: {
    resident: null,
    stage: 0,
    stages: null,
    remont: 0,
    residentCost: {},
    defect: 0,
    term: {
      from: "",
      to: "",
      contractor_id: ""
    }
  },
  master_types: null,
  masterDocumentTypes: [],
  costMasters: null,
  masters: null,
  remonts: null,
  teams: null,
  contractors: null,
  limitsData: [],
  statusGroups: [],
  integratedPaymentTypes: [],
  headTeamEmployees: null,
  bikOptions: null,
  costStatuses: null,
  residents: null,
  roles: null,
  modalLoading: false
};

export const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INIT:
      return { ...state, init: payload.init };
    case SET_AUTH:
      return { ...state, isUserAuthenticated: payload.auth };
    case SET_DATA_USER:
      return { ...state, dataUser: payload };
    case SHOW_POPUP:
      return { ...state, popup: payload };
    case SET_MODAL_LOADING:
      return { ...state, modalLoading: payload };
    case SET_MENU_STATE:
      return { ...state, isMainMenuOpen: !state.isMainMenuOpen };
    case SET_MENU:
      return { ...state, menu: payload };
    case SET_HEAD_TEAM_EMPLOYEES:
      return { ...state, headTeamEmployees: payload };
    case SET_INTEGRATED_PAYMENT_TYPES:
      return { ...state, integratedPaymentTypes: payload };
    case SET_RESIDENTS:
      return { ...state, residents: payload?.residents };
    case SET_CITIES:
      return { ...state, cities: payload };
    case SET_COMPANIES:
      return { ...state, companies: payload };
    case SET_COST_STATUSES:
      return { ...state, costStatuses: payload };
    case SET_MASTER_DOCUMENT_TYPES:
      return { ...state, masterDocumentTypes: payload };
    case SET_BIK_OPTIONS:
      return { ...state, bikOptions: payload };
    case SET_ROLES:
      return { ...state, roles: payload?.roles };
    case SET_STAGES:
      return { ...state, stages: payload };
    case SET_FILTER_FIELDS:
      return { ...state, filterFields: payload };
    case SET_HEADER:
      return { ...state, header: payload };
    case SET_OKK_DATA:
      return { ...state, okkData: payload };
    case SET_MATERIAL_SHIP:
      return { ...state, materialShip: payload };
    case SET_MASTER_TYPES:
      return { ...state, master_types: payload };
    case SET_PAGE_TITLE:
      return { ...state, pageTitle: payload };
    case SET_HEADER_BACK:
      return { ...state, headerBack: payload };
    case SET_MASTERS:
      return { ...state, masters: payload };
    case SET_COST_MASTERS:
      return { ...state, costMasters: payload };
    case SET_TEAMS:
      return { ...state, teams: payload };
    case SET_CONTRACTORS:
      return { ...state, contractors: payload };
    case SET_STATUS_GROUPS:
      return { ...state, statusGroups: payload };
    case SET_REMONTS:
      return { ...state, remonts: payload };
    case SET_LIMITS:
      return { ...state, limitsData: payload };
    case RESET_APP_STATE:
      return { ...state, ...initialState, init: true };
    default:
      return state;
  }
};
