import React, { useEffect, useState } from "react";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import styles from "./styles.module.scss";
import CustomInput from "../../components/common/form/CustomInput";
import { PhoneInput } from "../../components/common/form/InputNumberMask";
import CommentsFile from "../Problems/CommentsFile";
import { UploadFile } from "../../components/common/form/UploadFile";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { CButton } from "../../components/common/buttons";
import { PAGES_ROUTE_KEY } from "../../constants";
import { useNavigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { registerNewContractor } from "./services";
import { InputWarning } from "../../components/common/form/warnings";
import { CheckBoxItem } from "./form";
import { BackBtn } from "../../components/common/blocks";

export const ContractorsForm = ({ data }) => {
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const router = useNavigate();
  const [step, setStep] = useState(1);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    company_type: "",
    city_id: "",
    work_name: "",
    contractor_org_name: "",
    contractor_address_register: "",
    fact_address: "",
    contractor_director_fio: "",
    phone: "",
    contractor_bin: "",
    email: "",
    iik: "",
    bik: "",
    bank_name: "",
    accountant_name: "",
    accountant_phone: "",
    skill_cnt_arr: [],
    instrument_id_arr: [],
    arrays: {
      doc_files_one: [],
      doc_files_two: [],
      doc_files_three: [],
      work_files: []
    }
  });

  useEffect(() => {
    if (data?.skill_list?.length)
      setFormData(prev => ({ ...prev, skill_cnt_arr: Array.from({ length: data?.skill_list?.length }).fill(0) }));
  }, [data]);

  const onChange = (key, value) => {
    setFormData(prev => ({ ...prev, [key]: value }));
  };
  const nextStep = () => setStep(prevState => prevState + 1);

  const onBackClick = () => {
    if (step === 1)
      return router(PAGES_ROUTE_KEY.forContractors);
    setStep(prevState => prevState - 1);
  };
  const addFile = (key, value) => {
    if (!value) return;
    const newValues = value?.filter(item => !formData.arrays[key].find(file => item.name === file.name && item.lastModified === file.lastModified));
    setFormData(prevState => ({
      ...prevState,
      arrays: { ...prevState.arrays, [key]: prevState.arrays[key] ? [...prevState.arrays[key], ...newValues] : value }
    }));
  };
  const deleteFile = (key, idx) => {
    setFormData(prevState => ({
      ...prevState,
      arrays: { ...prevState.arrays, [key]: prevState.arrays[key]?.filter((item, i) => i !== idx) }
    }));
  };
  const onSubmit = async (body) => {
    if (step === 1) {
      if (!formData.arrays?.doc_files_one?.length
        || !formData.arrays?.doc_files_two?.length
        || !formData.arrays?.doc_files_three?.length)
        return addCloseSnack("Загрузите документы!");
      setFormData(prevState => ({ ...prevState, ...body }));
      return nextStep();
    }
    if (step === 2) {
      if (!formData.instrument_id_arr?.length) return addCloseSnack("Выберите инструменты!");
      nextStep();
    }
    if (step === 3) {
      if (!formData.skill_cnt_arr?.length) return addCloseSnack("Загрузите фотографии!");
      nextStep();
    }
    if (step === 4) {
      if (!formData.arrays.work_files?.length) return addCloseSnack("Загрузите фотографии!");
      const params = new FormData();
      const CONTRACTOR_TYPE = "1";
      params.append("type", CONTRACTOR_TYPE);
      const appendFiles = (arr = [], name) => {
        arr.forEach((el) => {
          params.append(name, el);
        });
      };

      Object.keys(formData).forEach(key => {
        if (key !== "arrays") {
          if (key === "instrument_id_arr" || key === "skill_cnt_arr")
            params.append(key, `{${formData.instrument_id_arr}}`);
          else
            params.append(key, formData[key]);
        }
      });
      appendFiles(formData.arrays["doc_files_one"], "doc_files_one[]");
      appendFiles(formData.arrays["doc_files_two"], "doc_files_two[]");
      appendFiles(formData.arrays["doc_files_three"], "doc_files_three[]");
      appendFiles(formData.arrays["work_files"], "work_files[]");

      setLoading(true);
      const res = await registerNewContractor(params);
      setLoading(false);
      if (!res) return;
      addDefaultSnack("Заявка успешно создана");
      router(PAGES_ROUTE_KEY.forContractors);
    }
  };

  const renderContent = () => {

    switch (step) {
      case 1:
        return <>
          <h1>Шаг 1. Заполните анкетные данные</h1>
          <div className={styles.row}>
            <div className={styles.row__item}>
              <Controller
                name="company_type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoSuggest
                    list={companyTypes}
                    showKey={"company_name"}
                    valueKey={"company_id"}
                    variant={"outlined"}
                    placeholder="Тип организации"
                    label="Тип организации"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.company_type || ""}
                rules={{
                  required: true
                }}
              />
              {errors.company_type && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
            <div className={styles.row__item}>
              <Controller
                name="city_id"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoSuggest
                    list={data?.city_list || []}
                    showKey={"city_name"}
                    valueKey={"city_id"}
                    variant={"outlined"}
                    placeholder="Город"
                    label="Город"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.city_id || ""}
                rules={{
                  required: true
                }}
              />
              {errors.city_id && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.row__item}>
              <Controller
                name="work_name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    fullWidth
                    variant={"outlined"}
                    label="Наименование вида услуг/работ"
                    placeholder="Наименование вида услуг/работ"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.work_name || ""}
                rules={{
                  required: true
                }}
              />
              {errors.work_name && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
            <div className={styles.row__item}>
              <Controller
                name="contractor_org_name"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    fullWidth
                    variant={"outlined"}
                    label="Наименование организации"
                    placeholder="Наименование организации"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.contractor_org_name || ""}
                rules={{
                  required: true
                }}
              />
              {errors.contractor_org_name && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.row__item}>
              <Controller
                name="contractor_address_register"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    fullWidth
                    variant={"outlined"}
                    label="Юридический адрес"
                    placeholder="Юридический адрес"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.contractor_address_register || ""}
                rules={{
                  required: true
                }}
              />
              {errors.contractor_address_register && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
            <div className={styles.row__item}>
              <Controller
                name="fact_address"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    fullWidth
                    variant={"outlined"}
                    label="Фактический адрес"
                    placeholder="Фактический адрес"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.fact_address || ""}
                rules={{
                  required: true
                }}
              />
              {errors.fact_address && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.row__item}>
              <Controller
                name="contractor_director_fio"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    fullWidth
                    variant={"outlined"}
                    label="Директор"
                    placeholder="Директор"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.contractor_director_fio || ""}
                rules={{
                  required: true
                }}
              />
              {errors.contractor_director_fio && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
            <div className={styles.row__item}>
              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    fullWidth
                    variant={"outlined"} className={""}
                    label="Телефон"
                    placeholder="Телефон"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.phone || ""}
                rules={{
                  required: true
                }}
              />
              {errors.phone && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.row__item}>
              <Controller
                name="contractor_bin"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    fullWidth
                    variant={"outlined"}
                    label="ИИН"
                    placeholder="ИИН"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.contractor_bin || ""}
                rules={{
                  required: true
                }}
              />
              {errors.contractor_bin && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
            <div className={styles.row__item}>
              <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    fullWidth
                    variant={"outlined"}
                    label="Email"
                    placeholder="Email"
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={formData?.email || ""}
                rules={{
                  required: true
                }}
              />
              {errors.email && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
          </div>

          <h3>Перечень документов</h3>
          <div className={styles.marginBig}>
            <span className={styles.rowTitle}>Удостоверение личности</span>
            <div className={styles.margin}>
              {!!formData.arrays.doc_files_one?.length && formData.arrays.doc_files_one?.map((item, idx) =>
                <CommentsFile key={`${item.name}_${item.lastModified}`} clickable={false}
                              handleDelete={() => deleteFile("doc_files_one", idx)}
                              file_name={item?.name} />)}
            </div>
            <UploadFile
              name="doc_files_one"
              isAnketa
              onChange={(e) =>
                addFile("doc_files_one", Object.values(e.target.files))
              }
              required="required"
              files={formData.arrays.doc_files_one}
              renderUpload={() => (<span className={styles.attach}><AttachFileIcon />Загрузить</span>)} />
          </div>
          <div className={styles.marginBig}>
            <span className={styles.rowTitle}>Свидетельство или талон индивидуального предпринимателя</span>
            <div className={styles.margin}>
              {!!formData.arrays.doc_files_two?.length && formData.arrays.doc_files_two?.map((item, idx) =>
                <CommentsFile key={`${item.name}_${item.lastModified}`} clickable={false}
                              handleDelete={() => deleteFile("doc_files_two", idx)}
                              file_name={item?.name} />)}
            </div>
            <UploadFile
              name="doc_files_two"
              isAnketa
              onChange={(e) =>
                addFile("doc_files_two", Object.values(e.target.files))
              }
              required="required"
              files={formData.arrays.doc_files_two}
              renderUpload={() => (<span className={styles.attach}><AttachFileIcon />Загрузить</span>)} />
          </div>
          <div className={styles.marginBig}>
            <span className={styles.rowTitle}>Копия банковских реквизитов</span>
            <div className={styles.margin}>
              {!!formData.arrays.doc_files_three?.length && formData.arrays.doc_files_three?.map((item, idx) =>
                <CommentsFile key={`${item.name}_${item.lastModified}`} clickable={false}
                              handleDelete={() => deleteFile("doc_files_three", idx)}
                              file_name={item?.name} />)}
            </div>
            <UploadFile
              name="doc_files_three"
              isAnketa
              onChange={(e) =>
                addFile("doc_files_three", Object.values(e.target.files))
              }
              required="required"
              files={formData.arrays.doc_files_three}
              renderUpload={() => (<span className={styles.attach}><AttachFileIcon />Загрузить</span>)} />
          </div>

          <div>
            <h3>Банковские реквизиты</h3>
            <div className={styles.row}>
              <div className={styles.row__item}>
                <Controller
                  name="iik"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      fullWidth
                      variant={"outlined"}
                      label="ИИК"
                      placeholder="ИИК"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  defaultValue={formData?.iik || ""}
                  rules={{
                    required: true
                  }}
                />
                {errors.iik && (
                  <InputWarning>Это поле обязательно</InputWarning>
                )}
              </div>
              <div className={styles.row__item}>
                <Controller
                  name="bik"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      fullWidth
                      variant={"outlined"}
                      label="БИК"
                      placeholder="БИК"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  defaultValue={formData?.bik || ""}
                  rules={{
                    required: true
                  }}
                />
                {errors.bik && (
                  <InputWarning>Это поле обязательно</InputWarning>
                )}
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.row__item}>
                <Controller
                  name="bank_name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      fullWidth
                      variant={"outlined"}
                      label="Наименование банка"
                      placeholder="Наименование банка"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  defaultValue={formData?.bank_name || ""}
                  rules={{
                    required: true
                  }}
                />
                {errors.bank_name && (
                  <InputWarning>Это поле обязательно</InputWarning>
                )}
              </div>
            </div>
          </div>
          <div>
            <h3>Контактные данные бухгалтера, ответственного лица</h3>
            <div className={styles.row}>
              <div className={styles.row__item}>
                <Controller
                  name="accountant_name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CustomInput
                      type="text"
                      fullWidth
                      variant={"outlined"}
                      label="ФИО"
                      placeholder="ФИО"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  defaultValue={formData?.accountant_name || ""}
                  rules={{
                    required: true
                  }}
                />
                {errors.accountant_name && (
                  <InputWarning>Это поле обязательно</InputWarning>
                )}
              </div>
              <div className={styles.row__item}>
                <Controller
                  name="accountant_phone"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      fullWidth
                      variant={"outlined"} className={""}
                      label="Контактный телефон"
                      placeholder="Контактный телефон"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  defaultValue={formData?.accountant_phone || ""}
                  rules={{
                    required: true
                  }}
                />
                {errors.accountant_phone && (
                  <InputWarning>Это поле обязательно</InputWarning>
                )}
              </div>
            </div>
          </div>
        </>;
      case 2:
        return <>
          <h3>Шаг 2. Укажите имеющиеся инструменты</h3>
          <div className={styles.checkboxes}>
            {data?.instrument_list?.map((i) => (
              <CheckBoxItem
                key={i.instrument_id}
                name={"instrument_id_arr"}
                {...i}
                instrumentIds={formData?.instrument_id_arr}
                handleChange={e =>
                  onChange("instrument_id_arr", e?.target?.value)
                }
              />
            ))}
          </div>
        </>;
      case 3:
        return (
          <>
            <h3>Шаг 3. Заполните информацию по сотрудникам</h3>
            <p style={{ marginBottom: 15 }}>
              *Укажите количество работников каждой специальности
            </p>
            {formData.skill_cnt_arr?.length && <div className="constructor-wrapper">
              {data?.skill_list?.map((i, index) => (
                <EmpItem
                  key={i.skill_id}
                  {...i}
                  handleChange={e =>
                    onChange("skill_cnt_arr", formData.skill_cnt_arr.map((item, idx) => idx === index ? e?.target?.value : item))
                  }
                  value={formData.skill_cnt_arr[index]}
                  name={"skill_cnt_arr"}
                />
              ))}
            </div>}
          </>
        );
      case 4:
        return <>
          <h3>Шаг 4. Прикрепите фотографии выполненных работ</h3>
          <div>
            <p>Загрузите фото</p>
            <div className="contractor__row align-items-end">
              {!!formData.arrays?.work_files?.length &&
                formData.arrays?.work_files?.map((item, idx) => (
                  <CommentsFile key={`${item.name}_${item.lastModified}`} clickable={false}
                                handleDelete={() => deleteFile("work_files", idx)}
                                file_name={item?.name} />))}
              <UploadFile
                name="work_files"
                isAnketa
                onChange={(e) =>
                  addFile("work_files", Object.values(e.target.files))
                }
                required="required"
                files={formData.arrays?.work_files}
                renderUpload={() => (<span className={styles.attach}><AttachFileIcon />Загрузить</span>)} />
            </div>
          </div>
        </>;
      default:
        return null;
    }
  };

  const closeDetail = () => {
    router(PAGES_ROUTE_KEY.forContractors)
  }

  return <>
    <BackBtn onClick={closeDetail} padding mobile={false} />
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      {renderContent()}
      <div className={styles.btns}>
        <CButton type={"button"} disabled={loading} onClick={onBackClick} variant={"secondary"}>Назад</CButton>
        <CButton type={"submit"} loading={loading}>Дальше</CButton>
      </div>
    </form>
  </>;
};

const companyTypes = [
  {
    company_id: 1,
    company_name: "ИП"
  },
  {
    company_id: 2,
    company_name: "ТОО"
  }
];

function EmpItem({ name, skill_name, handleChange, value }) {
  const onPlus = () => {
    handleChange({ target: { name, value: value + 1 } });
  };

  const onMinus = () => {
    const newValue = value - 1 > 0 ? value - 1 : 0;
    handleChange({ target: { name, value: newValue } });
  };

  return (
    <div className={styles.emp__item}>
      <p>{skill_name}</p>
      <div className="filling-item__quantity">
        <button className={styles.emp__item_minus} type={"button"} onClick={onMinus}>
          <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 6.5H2"
              stroke="white"
              strokeWidth={2}
              strokeLinecap="round"
            />
          </svg>
        </button>
        <div className={styles.emp__item_num}>{value}</div>
        <button className={styles.emp__item_plus} type={"button"} onClick={onPlus}>
          <svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 8H4"
              stroke="white"
              strokeWidth={2}
              strokeLinecap="round"
            />
            <path
              d="M8 4V12"
              stroke="white"
              strokeWidth={2}
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}