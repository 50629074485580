import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { useSelector } from "react-redux";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { CButton } from "../../components/common/buttons";
import { Navigate } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { InputWarning } from "../../components/common/form/warnings";
import { Logo } from "../../components/common/Logo";
import cookiesService from "../../services/cookiesService";
import { PAGES } from "../../constants";
import { CLink } from "../../components/common/blocks/CustomLink";
import { doLogin } from "./services";

const cookiesServiceInstance = cookiesService.getService();
export const LogInPage = () => {
  const classes = useAuthStyles();
  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialize, setInitialize] = useState(false);
  const { isUserAuthenticated } = useSelector(state => state.appState);

  useEffect(() => {
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage("logout");
    else
      setInitialize(true);
  }, []);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = async body => {
    setLoading(true)
    const res = await doLogin(body);
    setLoading(false)
    if (!res) return;
    setDisabledSubmit(true);
    cookiesServiceInstance.setLoginData(res);
    window.location.reload();
  };

  if (!initialize) return null;
  if (isUserAuthenticated) return <Navigate to={PAGES.main} replace={true} />;
  return (
    <Container
      component="main"
      maxWidth="xs"
      style={{ backgroundColor: "#fff", textAlign: "center" }}
    >
      <div className={classes.paper}>
        <Logo className={classes.logo} alt="" size={"large"} />
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <div>
            <Controller
              control={control}
              name="login"
              render={({ field: { onChange, value } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  value={value}
                  id="email"
                  label="Email"
                  autoComplete="email"
                  style={{ color: "#818181", fontSize: 18 }}
                  onChange={onChange}
                />
              )}
              rules={{ required: true }}
              defaultValue={""}
            />
            {errors.login && <InputWarning>Это поле обязательно</InputWarning>}
          </div>
          <div>
            <Controller
              control={control}
              name="password"
              render={({ field: { onChange, value } }) => (
                <TextField
                  margin="normal"
                  fullWidth
                  value={value}
                  id="password"
                  label="Пароль"
                  type={showPassword ? "text" : "password"}
                  style={{ color: "#818181", fontSize: 18 }}
                  onChange={onChange}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              )}
              rules={{ required: true }}
              defaultValue={""}
            />
            {errors.password && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
          <div className={classes.link}>
            <CLink to={PAGES.forgetPassword}>Забыли пароль?</CLink>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <CButton
              type="submit"
              loading={loading}
              disabled={disabledSubmit}
              className={classes.submit}
              onClick={onSubmit}
            >
              Войти
            </CButton>
          </div>
        </form>
      </div>
    </Container>
  );
};

export const useAuthStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    backgroundColor: "#fff",
    justifyContent: "center"
  },
  form: {
    width: 269, // Fix IE 11 issue.
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(1),
    height: "auto !important"
  },
  submit: {
    margin: "30px 0",
    background: "linear-gradient(104.82deg, #0C4B86 -17.14%, #E42734 83.14%)",
    height: 50,
    fontSize: 18,
    borderRadius: "0.5rem",
    width: "100%"
  },
  logo: { width: 269, marginBottom: 50 },
  link: {
    display: "flex",
    marginTop: "10px",
    fontSize: "14px",
    color: "#ccc",
    "& a": {
      cursor: "pointer",
      color: "#160c63",
      fontWeight: "700",
      textDecoration: "initial",
      "&:hover": {
        textDecoration: "underline"
      }
    }
  }
}));
