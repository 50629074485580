import React, { useEffect, useState } from "react";
import ModalTitle from "./ModalTitle";
import { Box, Button, FormControl, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { deleteFile, fetchAttachments, uploadFiles } from "./api";
import CommentsFile from "./CommentsFile";
import LocalAddedFile from "./LocalAddedFile";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  modal: {
    maxWidth: 375,
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    padding: "25px 20px",
    position: "absolute",
    zIndex: 100,
    right: 138,
    top: 23
  },
  btn: {
    width: 225,
    height: 38,
    padding: 10,
    color: "#fff",
    backgroundColor: "#0C4B86",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "0.08em",
    borderRadius: 4,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#0C4B86",
      boxShadow: "none"
    }
  }
});

const initialState = {
  isLocalFilesVisible: true,
  formDataFiles: [],
  localAddedFiles: [],
  files: [],
  width: 0
};

const Attachments = ({ setOpen, card_id }) => {
  const classes = useStyles();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const [isDisabled, setIsDisabled] = useState(true);
  const [state, setState] = useState(initialState);
  const fd = new FormData();
  const options = {
    onUploadProgress: progressEvent => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);

      setState({
        ...state,
        width: percent
      });
    }
  };

  const {
    files,
    localAddedFiles,
    width,
    formDataFiles,
    isLocalFilesVisible
  } = state;

  const handleChange = e => {
    const filesNames = Object.values(e.target.files).map(el => el.name);

    setIsDisabled(false);
    setState({
      ...state,
      localAddedFiles: filesNames,
      isLocalFilesVisible: true,
      formDataFiles: e.target.files
    });
  };

  const handleClick = () => {
    for (const key of Object.keys(formDataFiles)) {
      fd.append("files", formDataFiles[key]);
    }
    setIsDisabled(true);
    uploadFiles(card_id, fd, options)
      .then(res => {
        setIsDisabled(true);
        setState({
          ...state,
          files: res.data,
          isLocalFilesVisible: false,
          localAddedFiles: [],
          formDataFiles: {},
          width: 0
        });
      })
      .catch(err => {
        if (err?.response) {
          addCloseSnack(err?.response?.data.error);
          setState(initialState);
        }
      });
  };

  const handleDelete = (card_id, card_file_id) => {
    deleteFile(card_id, card_file_id)
      .then(res => {
        setState({
          ...state,
          files: res.data
        });
        addDefaultSnack("Файл успешно удален");
      })
      .catch(err => {
        addCloseSnack(err.response.data.error);
        setState(initialState);
      });
  };

  useEffect(() => {
    fetchAttachments(card_id)
      .then(res => {
        setState(prev => ({
          ...prev,
          files: res?.data
        }));
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [card_id, addCloseSnack]);

  return (
    <Paper className={classes.modal}>
      <ModalTitle title="Вложения" setOpen={setOpen} />

      <Box mb={2}>
        {files?.map(el => {
          return (
            <CommentsFile
              key={el.card_file_id}
              {...el}
              card_id={card_id}
              handleDelete={handleDelete}
            />
          );
        })}
      </Box>

      {isLocalFilesVisible ? (
        <Box>
          {localAddedFiles?.map((el, i) => {
            return <LocalAddedFile key={i} name={el} width={width} />;
          })}
        </Box>
      ) : null}
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          disabled={isDisabled}
          onClick={handleClick}
          className={classes.btn}
          variant="contained"
        >
          Загрузить
        </Button>
        <FormControl>
          <Box style={{ marginTop: 6 }}>
            <label
              htmlFor="upload-files"
              style={{
                fontSize: "14px",
                lineHeight: "15px",
                color: "#000",
                whiteSpace: "nowrap"
              }}
            >
              <input
                style={{
                  display: "none"
                }}
                id="upload-files"
                name="upload-files"
                type="file"
                multiple
                onChange={handleChange}
              />
              <div style={{ cursor: "pointer", marginLeft: 10 }}>
                <Box style={{ transform: "rotate(38deg)" }}>
                  <AttachFileIcon color="primary" />
                </Box>
              </div>
            </label>
          </Box>{" "}
        </FormControl>
      </Box>
    </Paper>
  );
};

export default Attachments;
