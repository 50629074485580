import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  link: {
    color: "#074ce7",
    transition: "all .3s",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "none"
    }
  }
}));

export const CLink = ({ to, children, ...other }) => {
  const classes = useStyles();
  return (
    <Link className={classes.link} to={to} {...other}>
      {children || ""}
    </Link>
  );
};
