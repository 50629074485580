import { mastersPaymentsAPI } from "./api";

export const getGroupedMastersPayments = async filters => {
  if (!filters?.integrated_payment_type_code) return;
  try {
    const res = await mastersPaymentsAPI.getGroupedMasterPayments(filters);
    return res?.data;
  } catch (e) {
  }
};
export const getMastersPayments = async filters => {
  try {
    const res = await mastersPaymentsAPI.getMasterPayments(filters);
    if (!res?.data?.length) return [];
    const {
      overall_cost_sum: cost_sum,
      overall_tax_sum: tax_sum,
      overall_cost_sum_with_tax: cost_sum_with_tax,
      data
    } = res;
    return [...data, {
      contractor_cost_id: '-111',
      team_master_fio: "Итого", cost_sum,
      tax_sum,
      cost_sum_with_tax
    }];
  } catch (e) {
  }
};
export const getMasterPaymentsStatuses = async filters => {
  try {
    const res = await mastersPaymentsAPI.getMasterPaymentsStatuses(filters);
    return res?.data;
  } catch (e) {
  }
};
export const getMasterPaymentsSignedDocs = async filters => {
  try {
    const res = await mastersPaymentsAPI.getMasterPaymentsSignedDocs(filters);
    if (!res?.data?.length) return [];
    const {
      overall_contractor_sum: sum,
      data
    } = res;
    return [...data, {
      sign_process_id: '-111',
      uuid_date: "Итого", sum,
    }];
  } catch (e) {
  }
};
export const cancelGroupedPayment = async (body, params) => {
  try {
    const res = await mastersPaymentsAPI.cancelGroupedPayment(body, params);
    return res?.data;
  } catch (e) {
  }
};
export const makeMastersPayment = async (selected, params = {}) => {
  try {
    let contractor_cost_ids = [];
    Object.keys(selected).forEach(key => {
      if (selected[key]?.length) {
        contractor_cost_ids = [...contractor_cost_ids, ...selected[key]];
      }
    });
    const res = await mastersPaymentsAPI.makePayment({
      contractor_cost_ids,
      ...params
    });
    return res?.data;
  } catch (e) {
  }
};
