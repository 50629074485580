import React, { lazy, Suspense } from "react";

import { Route, Routes as Switch } from "react-router";
// Страницы
import { PAGES } from "./constants";

import {
  AdditionalWorksReport,
  CompletedStage,
  ContractJournal,
  MaterialSearch,
  Problems,
  RemontInfo,
  RemontPage,
  RemontProblems,
  RemontStatuses,
  ReportAddWorks,
  ReportCostPayment,
  ReportMaterialShip,
  ReportOkkStage,
  ReportPartnerPayment,
  ReportTable,
  SingleRemont,
  WorkChoosePage,
  WorksReport
} from "pages";
import RemontsList from "./pages/remontsList";
import RemontWorks from "./pages/remontWorks";
import RemontRequests from "./pages/remontRequests";
import RemontChat from "./pages/remontChat";
import RemontProjects from "./pages/remontProjects";
import RemontMeasure from "./pages/remontMeasure";
import ExpectedPayments from "./pages/expectedPayments";
import PaymentReportsAll from "./pages/paymentReportsAll";
import ProrabPaymentsReport from "./pages/paymentReportsAll/prorabPaymentsReport";
import ReportLimit from "./pages/reportLimit";
import ReportPenalty from "./pages/reportPenalty";
import { Teams } from "./components/features/teams";
import { AccountingLayout } from "./components/features/accounting/layout";
import { RequestsPage } from "./pages/requests";
import { Contractors } from "./components/features/accounting/contractors";
import { MainPage } from "./pages/main";
import { TeamDebt } from "./components/features/teams/detail/debt";
import { BrigadirCosts } from "./components/features/accounting/brigadirCosts";
import { DebtAmount } from "./components/features/accounting/amount";
import { RemontsAccountingReport } from "./components/features/accounting/remontsAccountingReport";
import { LoaderData } from "./components/common/LoaderData";
import { ExecutiveReportPage } from "./pages/executiveReport";
import { AccountingContainer } from "./components/features/accounting/AccountingContainer";
import { GroupedMastersPayments } from "./pages/mastersPayments/grouped";
import { MastersPayments } from "./pages/mastersPayments";
import { MastersPaymentsSignedDocs } from "./pages/mastersPayments/signedDocs";
import { ExternalMasterPayments } from "./components/features/accounting/externalMasterPayments";
import { ReportMasterPayments } from "./pages/reportMasterPayments";
import { KanbanPage } from "./pages/kanban";
import { ForContractors } from "./pages/forContractors";
import { ForContractorsForm } from "./pages/forContractors/form";
import { CMRStandards } from "./pages/cmrStandarts";
import { OurWorks } from "./pages/ourWorks";
import { OurWorksDetail } from "./pages/ourWorks/ourWorksDetail";
import { ContractorInfo } from "./pages/forContractors/contractorInfo";
import { JurDateWarrantyPage } from "./pages/reportJurDateWarranty";

export const Routes = () => {
  return (
    <Suspense fallback={<LoaderData />}>
      <Switch>
        {routes?.map((item, i) => {
          return (
            <Route key={i} path={item?.path || "/"} element={item?.element}>
              {!!item?.nestedRoute && (
                <Route path={item?.nestedRoute} element={item?.nestedElement} />
              )}
            </Route>
          );
        })}
      </Switch>
    </Suspense>
  );
};

const UserSettingsPageLazy = lazy(() =>
  import("./pages/profile/userSettings/UserSettings")
);

const routes = [
  { path: "/", element: <MainPage /> },
  { path: PAGES.reportOkkStage, element: <ReportOkkStage /> },
  { path: PAGES.contractors, element: <Contractors /> },
  { path: PAGES.reportOkkStage, element: <ReportOkkStage /> },
  { path: PAGES.reportCostPayment, element: <ReportCostPayment /> },
  { path: PAGES.reportPartnerPayment, element: <ReportPartnerPayment /> },
  { path: PAGES.workChoosePage, element: <WorkChoosePage /> },
  {
    path: PAGES.remontsPageRoute,
    element: <RemontPage />,
    nestedRoute: PAGES.remontsPageRouteId,
    nestedElement: <RemontPage />
  },
  { path: PAGES.singleRemont, element: <SingleRemont /> },
  { path: PAGES.remontInfo, element: <RemontInfo /> },
  { path: PAGES.remontWorks, element: <RemontWorks /> },
  { path: PAGES.remontProjects, element: <RemontProjects /> },
  { path: PAGES.remontMeasure, element: <RemontMeasure /> },
  { path: PAGES.remontChat, element: <RemontChat /> },
  { path: PAGES.remontStatuses, element: <RemontStatuses /> },
  { path: PAGES.requests, element: <RequestsPage /> },
  { path: PAGES.remontRequests, element: <RemontRequests /> },
  { path: PAGES.remontProblems, element: <RemontProblems /> },
  {
    path: PAGES.remontAccounting,
    element: <AccountingContainer singleRemont type="all" isOwnPage showFilters={false} />
  },
  { path: PAGES.reportAddWorks, element: <ReportAddWorks /> },
  { path: PAGES.reportTable, element: <ReportTable /> },
  { path: PAGES.reportMaterialShip, element: <ReportMaterialShip /> },
  { path: PAGES.problems, element: <Problems /> },
  { path: PAGES.remontList, element: <RemontsList /> },
  { path: PAGES.worksReport, element: <WorksReport /> },
  { path: PAGES.materialSearch, element: <MaterialSearch /> },
  { path: PAGES.reportStages, element: <CompletedStage /> },
  { path: PAGES.reportExpectedPayments, element: <ExpectedPayments /> },
  {
    path: PAGES.contractJournal,
    element: <ContractJournal />,
    nestedRoute: PAGES.contractJournalType,
    nestedElement: <ContractJournal />
  },
  { path: PAGES.executiveReport, element: <ExecutiveReportPage /> },
  { path: PAGES.jurDateWarranty, element: <JurDateWarrantyPage /> },
  { path: PAGES.additionalWorksReport, element: <AdditionalWorksReport /> },
  { path: PAGES.paymentReportsAll, element: <PaymentReportsAll /> },
  { path: PAGES.prorabPaymentsReport, element: <ProrabPaymentsReport /> },
  { path: PAGES.reportLimit, element: <ReportLimit /> },
  { path: PAGES.reportPenalty, element: <ReportPenalty /> },
  { path: PAGES.teams, element: <Teams /> },
  { path: PAGES.kanban, element: <KanbanPage /> },
  { path: PAGES.debts, element: <TeamDebt /> },
  { path: PAGES.debtsAmount, element: <DebtAmount /> },
  { path: PAGES.externalMasterPayments, element: <ExternalMasterPayments /> },
  { path: PAGES.remontsAccountingReport, element: <RemontsAccountingReport /> },
  {
    path: PAGES.accountingCosts,
    element: <AccountingLayout type="cost" isPaymentMode />
  },
  {
    path: PAGES.accountingIncomes,
    element: <AccountingLayout type="income" />
  },
  { path: PAGES.brigadirCosts, element: <BrigadirCosts /> },
  { path: PAGES.groupedMastersPayments, element: <GroupedMastersPayments /> },
  {
    path: PAGES.mastersPaymentsSignedDocs,
    element: <MastersPaymentsSignedDocs />
  },
  { path: PAGES.mastersPaymentsRoute, element: <MastersPayments /> },
  { path: PAGES.settings, element: <UserSettingsPageLazy /> },
  { path: PAGES.reportMasterPayments, element: <ReportMasterPayments /> },
  { path: PAGES.forContractors, element: <ForContractors /> },
  { path: PAGES.forContractorsForm, element: <ForContractorsForm /> },
  { path: PAGES.contractorInfo, element: <ContractorInfo /> },
  { path: PAGES.cmrStandards, element: <CMRStandards /> },
  { path: PAGES.ourWorks, element: <OurWorks /> },
  { path: PAGES.ourWorksDetail, element: <OurWorksDetail /> },
];
