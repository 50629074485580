import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setHeader,
  setMaterialShipData
} from "../../services/redux/actions/app-actions";
import { PAGES } from "../../constants";
import { useNavigate } from "react-router";
import { getJKList, reportDelivery } from "./api";
import { makeStyles } from "@material-ui/core/styles";
import DownloadXlsx from "../../components/common/DownloadXlsx";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { InputSumMask } from "../../components/common/form/InputSumMask";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

const useStyles = makeStyles(() => ({
  select: {
    width: 200,
    float: "left",
    maxHeight: 100,
    overflowY: "auto",
    overflowX: "hidden",
    "& .MuiFormControl-root": {
      width: "100%"
    }
  },
  filters: { marginBottom: 10, display: "flex", flexWrap: 'wrap', gap: "12px", alignItems: "flex-end" },
  button: {
    backgroundColor: "#0C4B86",
    height: 30,
    color: "#ffffff",
    borderRadius: 4
  },
  loop: {
    animation: `$spin 2000ms infinite linear`,
    color: "#fff"
  },

  "@keyframes spin": {
    "0%": {
      transform: "rotate(0)"
    },
    "100%": {
      transform: "rotate(360deg)"
    }
  },
  flat_num: {
    marginLeft: 20
  }
}));

const ReportFilter = ({ setLoading, setFlatNum, flatNum }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useNavigate();
  const { addCloseSnack } = useCustomSnackbar();
  const [requestedResidents, setRequestedResidents] = useState([]);
  const [residentIds, setResidentIds] = useState([]);

  function handleAcceptFilterFields(resident_ids, flat_num) {
    setLoading(true);
    reportDelivery({ resident_ids, flat_num })
      .then(answer => {
        dispatch(setMaterialShipData(answer.data));
        setLoading(false);
      })
      .catch(e => {
        const message = e?.response?.data?.error;
        message && addCloseSnack(message);
        setLoading(false);
      });
  }

  useEffect(() => {
    getJKList()
      .then((answer = {}) => {
        setRequestedResidents(answer.data.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
        history(PAGES.reportCostPayment);
      });

    dispatch(
      setHeader({
        isList: true,
        showBack: false,
        showCancel: true
      })
    );
  }, [dispatch, addCloseSnack, history]);

  return (
    <CollapseBlock className={classes.filters} tabletMode>
      <div className={classes.select}>
        <AutoSuggest
          name="resident_ids"
          showKey="resident_name"
          label="Жилой комплекс"
          value={residentIds}
          list={requestedResidents}
          valueKey="resident_id"
          placeholder="ЖК"
          multiple showMultipleSelected
          onChange={e => {
            const resident_ids = e.target.value;
            setResidentIds(resident_ids);
            handleAcceptFilterFields(resident_ids, null);
          }}
        />
      </div>
      <div className={classes.select}>
        <InputSumMask
          type="number"
          fullWidth
          min={0}
          onChange={e => {
            setFlatNum(e.target.value);
          }}
          label="Квартира"
          placeholder="Укажите квартиру"
          value={flatNum}
        />
      </div>
      <DownloadXlsx
        url="/partner/delivery_report/xlsx/"
        params={{
          resident_ids: residentIds
        }} withoutMargin
        title="Отчет по отгрузке материалов"
      />
    </CollapseBlock>
  );
};

export default ReportFilter;
