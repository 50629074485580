import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getResident,
  resetResident
} from "../../../services/redux/actions/remonts-actions";

export const RemontWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);

  useEffect(() => {
    dispatch(getResident(remont_id));
    return () => {
      dispatch(resetResident());
    };
  }, [remont_id, dispatch]);

  return <>{children}</>;
};
