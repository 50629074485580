import {
  Box,
  Button,
  CircularProgress,
  ListItem,
  Popover,
  Typography
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

import { getWorkColor } from "./api";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles(() => ({
  selectedWork: {
    background: "#FFFFFF",
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)",
    borderRadius: "4px",
    padding: "0px 0px 0px 25px",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#000000",
    width: "100%"
  },
  selectedId: {
    marginRight: "25px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.04em",
    color: "#9194A9"
  },
  trash: {
    backgroundColor: "#fff",
    border: 0
  },
  color: {
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    cursor: "pointer"
  }
}));

export const SelectedWorks = ({ data, deleteWork, loading, setColorWork }) => {
  const { addCloseSnack } = useCustomSnackbar();
  const [colors, setColors] = useState([]);

  useEffect(() => {
    getWorkColor()
      .then((answer = {}) => {
        setColors(answer.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [addCloseSnack]);

  if (loading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      {Array.isArray(data) ? (
        data?.map(el => {
          return (
            <SelectedWorkItem
              key={el.work_id}
              workItem={el}
              colors={colors}
              deleteWork={deleteWork}
              setColorWork={setColorWork}
            />
          );
        })
      ) : (
        <div>{data.error}</div>
      )}
    </>
  );
};

const SelectedWorkItem = ({ workItem, setColorWork, deleteWork, colors }) => {
  const { work_id, work_name, work_color } = workItem;

  const handleDelete = id => {
    deleteWork(id);
  };
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetColor = (id, color) => {
    setColorWork(id, color);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.selectedWork}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.selectedId}>{work_id}</Typography>
          <Typography>{work_name}</Typography>
        </Box>
        <Box>
          <ListItem
            aria-describedby={id}
            button
            onClick={handleClick}
            style={{ width: "auto", position: "relative" }}
          >
            <Box
              className={classes.color}
              style={{ backgroundColor: `${work_color}` }}
            />

            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <Box p={1}>
              <Typography>Выберите цвет</Typography>
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                justifyContent="space-between"
                style={{ width: "100%" }}
              >
                {colors?.map(el => {
                  return (
                    <Box key={el?.work_color_id} style={{ width: "15%" }}>
                      <Box
                        className={classes.color}
                        key={el.work_color_id}
                        style={{
                          backgroundColor: `${el.work_color_code}`,
                          margin: "2px"
                        }}
                        onClick={() =>
                          handleSetColor(work_id, el.work_color_code)
                        }
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        ml={2}
        className={classes.trash}
      >
        <Button onClick={() => handleDelete(work_id)}>
          <DeleteOutlineIcon fontSize="small" color="error" />
        </Button>
      </Box>
    </Box>
  );
};
