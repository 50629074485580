import instance from "../../../services/api";

export const forContractorsAPI = {
  async getContractorsData(type) {
    return instance(true, 'https://office.smartremont.kz/react', {isBasic: true, isThirdParty: true})
      .get(`/landing/contractors/type/${type}/`)
      .then((res) => res?.data)
  },
  async registerContractor(body) {
    return instance(true)
      .post(`/partner/additional_work/work-anketa/`, body, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => res?.data)
  },
  async getContractorInfo() {
    return instance(true)
      .get(`/partner/additional_work/work-anketa/`)
      .then((res) => res?.data)
  }
}