import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { getMeasureRooms, getRooms } from "../services/api";
import MeasureList from "./MeasureList";
import { useParams } from "react-router-dom";
import { CustomLoader } from "../../../components/common/CustomLoader";
import { PAGES_ROUTE_KEY } from "../../../constants";
import { setHeaderBack } from "../../../services/redux/actions/app-actions";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

export function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #D2D3D9",
    borderRadius: 4,
    [theme.breakpoints.down(576)]: {
      padding: '0',
      border: "none",
    }
  },
  selected: {
    backgroundColor: "#fff",
    borderLeft: "1px solid #D2D3D9",
    borderRight: "1px solid #D2D3D9",
    borderRadius: "4px 4px 0 0 "
  },
  content: {
    padding: 20,
    [theme.breakpoints.down(768)]: {
      padding: '15px 10px',
    }
  },
  tab: {
    width: "20%",
    minWidth: "0 !important",
    [theme.breakpoints.down(768)]: {
      width: 'auto'
    }
  },
  tabs_header: {
    boxShadow: "none",
    backgroundColor: "#F8FAFB"
  }
}));

export default function MeasureContent({singleRemont}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);

  const [isFetching, setIsFetching] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [rooms, setRooms] = useState([]);
  const [roomInfo, setRoomInfo] = useState([]);

  useEffect(() => {
    const goBack = () => {
      navigate(`${PAGES_ROUTE_KEY.remontInfo}/${remont_id}`);
    };
    remont_id && singleRemont && dispatch(setHeaderBack(goBack));
    return () => {
      dispatch(setHeaderBack(null));
    };
  }, [dispatch, remont_id, navigate, singleRemont]);

  const getRoomParams = useCallback(data => {
    setIsFetching(true);
    getMeasureRooms(data)
      .then(res => {
        setIsFetching(false);
        setRoomInfo(res);
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, []);

  const handleChange = (event, newValue) => {
    if (!remont_id) return;
    setValue(newValue);
    setIsFetching(true);
    getRoomParams({ remont_id, room_id: newValue });
  };

  useEffect(() => {
    !!remont_id &&
    getRooms(remont_id)
      .then(res => {
        setRooms(res);
      })
      .catch(() => {
      });
  }, [remont_id]);

  useEffect(() => {
    if (remont_id) {
      getRoomParams({ remont_id, room_id: 0 });
    }
  }, [remont_id, getRoomParams]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.tabs_header}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label="Вся квартира"
            {...a11yProps(0)}
            classes={{
              selected: classes.selected
            }}
            value={0}
            className={classes.tab}
          />
          {rooms?.map(el => {
            return (
              <Tab
                key={el.room_id}
                label={el.room_name}
                {...a11yProps(0)}
                classes={{
                  selected: classes.selected
                }}
                value={el.room_id}
                className={classes.tab}
              />
            );
          })}
        </Tabs>
      </AppBar>
      {isFetching ? (
        <CustomLoader />
      ) : (
        <MeasureList remont_id={remont_id} value={value} data={roomInfo} />
      )}
    </div>
  );
}
