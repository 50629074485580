import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showPopUp } from "../../services/redux/actions/app-actions";
import ReportOkkList from "./ReportOkkList";
import { NotFound } from "../../components/common/text";
import { MODALS_NAMES } from "../../constants";

const ReportOkkStageTable = () => {
  const dispatch = useDispatch();
  const { okkData } = useSelector(state => state.appState);

  const showModal = (remont_id, stage_id) => {
    const data = { stage_id, remont_id, close: true };
    dispatch(showPopUp(true, MODALS_NAMES.okkReport, data));
  };

  if (!okkData?.length) return <NotFound />;

  return okkData?.map((arr, idx) => (
    <ReportOkkList
      key={idx}
      {...arr}
      idx={idx}
      showModal={showModal}
      arr={arr}
    />
  ));
};

export default ReportOkkStageTable;
