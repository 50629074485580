import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setLimits } from "../../services/redux/actions/app-actions";

import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import EmptyOrShow from "../../components/common/EmptyOrShow";
import styles from "../../components/features/accounting/modals/add_cost_income.module.scss";
import { InputSumMask } from "../../components/common/form/InputSumMask";
import { CButton } from "../../components/common/buttons";
import { useHttp } from "../../utils/hooks/http.hook";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& svg": {
      color: "#0C4B86"
    }
  },
  head_title: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "22px",
    color: "#0C4B86"
  },
  body: {
    "& label": {
      fontSize: 16
    }
  },
  body_title: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 10
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px"
  },
  cancel_button: {
    color: "#0C4B86",
    border: "1px solid #0C4B86",
    padding: "5px 15px",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
  change_button: {
    color: "#fff",
    backgroundColor: "#0C4B86",
    padding: "6px 16px",
    "&:hover": {
      backgroundColor: "#0C4B86"
    }
  }
});

const ChangeLimitModal = ({ data, handleClose }) => {
  const dispatch = useDispatch();
  const { loading, request } = useHttp();
  const classes = useStyles();
  const { addDefaultSnack } = useCustomSnackbar();
  const [value, setValue] = useState(data.limit);

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleChangeLimit = async () => {
    const res = await request(`/partner/limits_report/${data.id}/`, "post", {
      limit_sum: +value
    });
    if (res) {
      dispatch(setLimits(res));
      handleClose();
      addDefaultSnack("Успешно");
    }
  };

  return (
    <Box sx={{ display: "grid", gap: "24px", width: "400px !important" }}>
      <Box className={classes.head}>
        <Typography component="h2" className={classes.head_title}>
          Лимит
        </Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <EmptyOrShow loading={false}>
        <Box className={classes.body}>
          <Typography component="h2" className={classes.body_title}>
            {data.name}
          </Typography>
          <InputSumMask
            type="number"
            fullWidth
            min={0}
            label="Лимит"
            placeholder="Введите сумму лимита"
            wrapperClassname={styles.accounting__input_wrapper}
            value={value}
            onChange={handleChange}
          />
        </Box>
        <Box className={classes.footer}>
          <CButton className={classes.cancel_button} onClick={handleClose}>
            Отменить
          </CButton>
          <CButton
            className={classes.change_button}
            loading={loading}
            disabled={loading}
            onClick={handleChangeLimit}
          >
            Изменить
          </CButton>
        </Box>
      </EmptyOrShow>
    </Box>
  );
};

export default ChangeLimitModal;
