import instance from "../../services/api";

export const getPaymentsReport = (params = {}) => {
  return instance(true).post(
    "/partner/partner_assist_payments_report/ ",
    params
  );
};

export const getProrabsPaymentsReport = (params = {}) => {
  return instance(true).post(
    "/partner/manager_project_payments_report/ ",
    params
  );
};

export const getPaymentReportStages = () => {
  return instance(true).get(`/partner/contractor_payments_stages/`);
};

export const getProrabs = () => {
  return instance(true).get(`/partner/manager_project_employees/`);
};

export const getReportResidents = () => {
  return instance(true).get(`/partner/reports_residents/`);
};
