import React, { useMemo } from "react";
import { numberWithCommas } from "../../../../utils";
import { useTableStyles } from "../styles";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { Tooltip } from "@material-ui/core";
import styles from "./costTable.module.scss";
import { withStyles } from "@material-ui/core/styles";
import { isPaymentType } from "./services";
import { CopyIconWrapper, DeleteIconWrapper, EditIconWrapper } from "../../../common/blocks";
import { PAGES_ROUTE_KEY } from "../../../../constants";
import { CustomVirtualTable } from "../../../common/CustomTable/CustomVirtualTable";

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#fd5353",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 700,
    border: "1px solid #ff7878",
    textAlign: "center"
  }
}))(Tooltip);
export const CostsTable = ({
                             data: cost,
                             isFetching,
                             onEditCostClick,
                             getData,
                             onCloneCostClick,
                             isBrigadirCostsMode = false,
                             isRemontMode = false,
                             isTeamMode = false,
                             paymentMode = false,
                             onDeleteCostClick,
                             actions = true
                           }) => {

  const classes = useTableStyles();
  const { costPaymentType } = useSelector(state => state.accountingState);

  const costHeadItems = useMemo(() => [
    { label: "Дата", align: "left", widthTemplate: 80 },
    { label: "Тип", align: "left", widthTemplate: 110, hide: isBrigadirCostsMode },
    { label: "Сумма", align: "left", widthTemplate: 75 },
    { label: "Бригада", align: "left", hide: isTeamMode || isRemontMode, widthTemplate: 114 },
    { label: "Мастер/Бригадир", align: "left", widthTemplate: 114 },
    { label: "Ремонт", align: "left", hide: isRemontMode, widthTemplate: 180 },
    { label: "Этап", align: "left", hide: isRemontMode || isBrigadirCostsMode, widthTemplate: 100 },
    { label: "Специализация", align: "left", widthTemplate: 100, hide: isBrigadirCostsMode },
    { label: "Статус", align: "left", widthTemplate: 75, show: isBrigadirCostsMode },
    { label: "Статус", align: "left", widthTemplate: 65, show: paymentMode },
    { label: "Комментарий", align: "left", widthTemplate: 270, minMax: 'minmax(270px, 12fr)' },
    { label: "", align: "left", widthTemplate: 75, show: actions }
  ], [isTeamMode, isRemontMode, isBrigadirCostsMode, actions, paymentMode]);

  const headItems = useMemo(() => {
    return costHeadItems.filter(item => !item?.hide).filter(item => item.show === undefined || item.show === true);
  }, [costHeadItems]);

  const cellsList = [
    {
      key: "cost_date",
      align: "center"
    },
    {
      key: "contractor_cost_type_name", hide: isBrigadirCostsMode
    },
    {
      key: "cost_sum",
      align: "right",
      render: item => numberWithCommas(item.cost_sum)
    },
    {
      key: "cost_sum",
      align: "left",
      hide: isRemontMode || isTeamMode,
      render: item => item?.team_id ? `${item?.team_id}, ${item?.team_name || ""}` : ''
    },
    {
      key: "team_master_fio",
    },
    {
      key: "remont_information",
      align: "left",
      hide: isRemontMode,
      render: item => <Link
        className={classes.link}
        to={`${PAGES_ROUTE_KEY.singleRemont}/${item?.remont_id}`}
      >
        {item?.remont_information}
      </Link>
    },
    {
      key: "stage_contractor_name",
      hide: isRemontMode || isBrigadirCostsMode
    },
    {
      key: "specialities", hide: isBrigadirCostsMode
    },
    {
      key: "payment_status", show: isBrigadirCostsMode
    },
    {
      key: "comment",
      render: item => <span className={styles.costs__comment}>{item?.comment}</span>
    },
    {
      key: "payment_status",
      hide: !paymentMode,
      render: item => <HtmlTooltip title={item?.error || ""} placement="top">
        <div className={styles.costs__status}>
          {item?.payment_status}
          {!!item?.error && (
            <ErrorOutlineOutlinedIcon className={styles.costs__status_icon} />
          )}
        </div>
      </HtmlTooltip>
    },
    {
      key: "payment_status",
      hide: !actions,
      render: item => (item.contractor_cost_type_code !== 'AUTO_PAYMENT' && costPaymentType &&
        !isPaymentType(
          item?.contractor_cost_type_id,
          costPaymentType
        )) && (
        <div className={classes.actions}>
          <EditIconWrapper
            onClick={() => onEditCostClick && onEditCostClick(item)}
          />
          <CopyIconWrapper
            onClick={() =>
              onCloneCostClick && onCloneCostClick(item)
            }
          />
          <DeleteIconWrapper
            onClick={() =>
              onDeleteCostClick &&
              onDeleteCostClick(item?.contractor_cost_id)
            }
          />
        </div>
      )
    },
  ]

  return (
    <>
      <CustomVirtualTable
        isFetching={isFetching}
        headItems={headItems}
        cellsList={cellsList}
        stickyHeader
        width={headItems.reduce((prev, currentValue) => prev + currentValue.widthTemplate, 0)}
        updateDataReq={getData}
        gridTemplateColumns={headItems.map(item => item.minMax || (item.widthTemplate ? `${item.widthTemplate}px` : null) || '60px').join(' ')}
        data={cost}
        rowHeight={80}
        keyOption={"contractor_cost_id"}
        isEmpty={!cost?.length}
      />
    </>
  );
};
