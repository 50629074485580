import React from "react";
import { Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ModalTitle from "./ModalTitle";
import { changeCardInfo } from "./api";
import { useDispatch, useSelector } from "react-redux";
import { setColumns } from "../../services/redux/actions/problemsActions";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  modal: {
    maxWidth: 375,
    width: "100%",
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    padding: "25px 20px",
    position: "absolute",
    zIndex: 100,
    right: 138,
    top: 23
  },
  subtitle: {
    color: "#494C62",
    marginTop: 10,
    marginBottom: 20,
    fontSize: 12,
    fontWeight: 600,
    textTransform: "uppercase"
  },
  stage_btn: {
    height: 45,
    backgroundColor: "#EAECF0",
    padding: "14px ",
    borderRadius: 4,
    textTransform: "none"
  }
});

const Archiving = ({ cardInfo, setOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cardId } = useSelector(state => state.problemsState);
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const handleClick = () => {
    const copyCardInfo = { ...cardInfo };
    copyCardInfo.is_archive = true;

    changeCardInfo(cardId, copyCardInfo)
      .then(res => {
        if (!res.data.error) {
          dispatch(setColumns(res?.data));
          setOpen(false);
          addDefaultSnack("Карточка успешно архивирована");
        }
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  };
  return (
    <Paper className={classes.modal}>
      <ModalTitle title="Архивация" setOpen={setOpen} />
      <Typography className={classes.subtitle}>
        Вы уверены что хотите отправить данную карточку в архив?
      </Typography>
      <Button fullWidth className={classes.stage_btn} onClick={handleClick}>
        Архивировать
      </Button>
    </Paper>
  );
};

export default Archiving;
