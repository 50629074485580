import React, { useEffect, useState } from "react";
import { getRequestStatusesHistory } from "../../api";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../../common/CustomTable/CustomTable";
import styles from "./RequestStatusesHistory.module.scss";
import { useCustomSnackbar } from "../../../../../utils/hooks/useCustomSnackbar";

export const RequestStatusesHistoryModal = ({ data }) => {
  const { addCloseSnack } = useCustomSnackbar();
  const { provider_request_id } = data;
  const [isFetching, setIsFetching] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    setIsFetching(true);
    getRequestStatusesHistory(provider_request_id)
      .then(res => {
        setIsFetching(false);
        setHistory(res);
      })
      .catch(e => {
        addCloseSnack(e?.response?.data?.error);
        setIsFetching(false);
      });
  }, [provider_request_id, addCloseSnack]);

  return (
    <div className={styles.history}>
      <h2>История изменений заявки #{data?.provider_request_id || ""}</h2>

      <CustomTable
        isEmpty={!history?.length}
        isFetching={isFetching}
        headItems={headItems}
      >
        {history?.map(item => {
          return (
            <CustomTableRow key={item?.provider_request_item_hist_id}>
              <CustomTableCell width={80} align={"center"}>
                {item?.material_id}
              </CustomTableCell>
              <CustomTableCell>{item?.material_name}</CustomTableCell>
              <CustomTableCell>{item?.provider_request_status_name}</CustomTableCell>
              <CustomTableCell>{item?.rowversion || ""}</CustomTableCell>
              <CustomTableCell><span dangerouslySetInnerHTML={{__html: item.fio}} /></CustomTableCell>
            </CustomTableRow>
          );
        })}
      </CustomTable>
    </div>
  );
};

const headItems = [
  { label: "ID" },
  { label: "Наименование" },
  { label: "Статус" },
  { label: "Дата изменения" },
  { label: "Исполнитель" }
];
