import { Button, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CButton } from "../buttons";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import DocIcon from "@material-ui/icons/InsertDriveFile";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import CopyIcon from "@material-ui/icons/FileCopy";
import CloseIcon from "@material-ui/icons/Close";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export const useStyles = makeStyles(theme => ({
  btn: {
    width: "auto",
    height: 38,
    borderRadius: 4,
    color: "#fff",
    cursor: "pointer",
    padding: "5px 12px",
    whiteSpace: "nowrap",
    backgroundColor: "#0C4B86",
    minWidth: "auto",
    border: "none",
    "&:hover": {
      backgroundColor: "#0C4B86"
    }
  },
  headRow: {
    backgroundColor: "#ECEEFD"
  },
  headRowHeader: {
    padding: "0"
  },
  headRowCell: {
    padding: "7px"
  },
  headBtn: {
    backgroundColor: "#3e63c1 !important",
    width: "auto",
    padding: "4px 5px",
    fontSize: "13px",
    height: "auto",
    "&:hover": {
      backgroundColor: "#3e63c1 !important"
    },
    "& svg": {
      marginLeft: "5px"
    }
  },
  editIcon: {
    width: "18px",
    cursor: "pointer",
    color: "#a8691c"
  },
  checkIcon: {
    color: "#13b221"
  },
  docIcon: {
    color: "#3d4977"
  },
  iconBig: {
    width: 25,
    fontSize: 25,
    flexBasis: "content"
  },
  cloneIcon: {
    width: "16px",
    cursor: "pointer",
    color: "#205f8d"
  },
  closeIcon: {
    width: "16px",
    cursor: "pointer",
    color: "#dc0d0d"
  },
  deleteIcon: {
    color: "#b61818"
  },
  disabled: {
    color: "#ccc",
    fill: "#ccc",
    cursor: "default"
  },
  filters: {
    display: "flex",
    alignItems: "center",
    gap: 15,
    flexWrap: "wrap"
  },
  back: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    gap: 0,
    fontSize: "13px",
    lineHeight: "11px",
    cursor: "pointer",
    "& svg": {
      fontSize: "15px"
    }
  },
  backMobile: {
    display: "none",
    [theme.breakpoints.down(768)]: {
      display: "flex"
    }
  },
  backPadding: {
    padding: "8px 0"
  }
}));
export const CustomButton = props => {
  const styles = useStyles();
  return (
    <Button className={styles.btn} variant="outlined" {...props}>
      Добавить
    </Button>
  );
};
export const BackBtn = ({ onClick, padding = false, mobile = true }) => {
  const classes = useStyles();
  return (
    <div onClick={() => onClick && onClick()} className={clsx(classes.back, { [classes.backMobile]: mobile, [classes.backPadding]: padding })}>
      <ArrowBackIosIcon />Назад</div>
  );
};

export const TableUpdateBtn = ({ isFetching, onClick }) => {
  const localClasses = useStyles();
  return <CButton
    className={localClasses.headBtn}
    onClick={() => onClick && onClick()}
    disabled={isFetching}
    loading={isFetching}
  >
    Обновить <CachedOutlinedIcon style={{ color: "#fff", fontSize: "17px" }} />
  </CButton>;
};

export const UpdateTableHeader = ({
                                    onClick = () => {
                                    },
                                    isFetching = false,
                                    side = "left"
                                  }) => {
  const localClasses = useStyles();
  return (
    <TableRow className={localClasses.headRowHeader}>
      <TableCell
        align={side || "left"}
        colSpan={20}
        className={`${localClasses.headRow} ${localClasses.headRowCell}`}
      >
        <TableUpdateBtn onClick={onClick} isFetching={isFetching} />
      </TableCell>
    </TableRow>
  );
};

export const DeleteIconWrapper = ({
                                    disabled,
                                    className = "",
                                    big = false,
                                    ...other
                                  }) => {
  const classes = useStyles();
  return (
    <DeleteIcon
      disabled={disabled}
      className={clsx(
        classes.editIcon,
        classes.deleteIcon,
        { [classes.disabled]: disabled },
        { [classes.iconBig]: big },
        className
      )}
      {...other}
    />
  );
};

export const CheckIconWrapper = ({
                                   disabled = false,
                                   big = false,
                                   className = "",
                                   ...other
                                 }) => {
  const classes = useStyles();
  return (
    <CheckIcon
      disabled={disabled}
      className={clsx(
        classes.editIcon,
        classes.checkIcon,
        { [classes.disabled]: disabled },
        { [classes.iconBig]: big },
        className
      )}
      {...other}
    />
  );
};
export const DocIconWrapper = ({
                                 disabled = false,
                                 className = "",
                                 ...other
                               }) => {
  const classes = useStyles();
  return (
    <DocIcon
      disabled={disabled}
      className={clsx(
        classes.editIcon,
        classes.docIcon,
        { [classes.disabled]: disabled },
        className
      )}
      {...other}
    />
  );
};

export const EditIconWrapper = ({
                                  disabled = false,
                                  className = "",
                                  ...other
                                }) => {
  const classes = useStyles();
  return (
    <EditIcon
      disabled={disabled}
      className={clsx(
        classes.editIcon,
        { [classes.disabled]: disabled },
        className
      )}
      {...other}
    />
  );
};
export const CopyIconWrapper = ({
                                  disabled = false,
                                  className = "",
                                  ...other
                                }) => {
  const classes = useStyles();
  return (
    <CopyIcon
      disabled={disabled}
      className={clsx(
        classes.cloneIcon,
        { [classes.disabled]: disabled },
        className
      )}
      {...other}
    />
  );
};
export const CloseIconWrapper = ({
                                   disabled = false,
                                   className = "",
                                   ...other
                                 }) => {
  const classes = useStyles();
  return (
    <CloseIcon
      disabled={disabled}
      className={clsx(
        classes.closeIcon,
        { [classes.disabled]: disabled },
        className
      )}
      {...other}
    />
  );
};

export const FiltersWrapper = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.filters}>{children}</div>;
};
