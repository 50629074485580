import React from "react";
import { CustomDatePicker } from "../../../../common/form/CustomDatePicker";
import styles from "./requestsdate.module.scss";
import { Controller, useForm } from "react-hook-form";
import { InputWarning } from "../../../../common/form/warnings";
import { CButton } from "../../../../common/buttons";
import { useSelector } from "react-redux";
import CustomInput from "../../../../common/form/CustomInput";

export const RequestsDateModal = ({ data }) => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const { modalLoading } = useSelector(state => state.appState);

  const { onSubmit: onModalSubmit } = data;
  const onSubmit = async body => {
    !!onModalSubmit && (await onModalSubmit(body));
  };
  return (
    <div className={styles.requests}>
      <h1>Изменение даты отгрузки</h1>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.requests__form}>
        {data?.isGroup && (
          <div className={`${styles.requests__form_item}`}>
            <Controller
              name="date_shipping_old"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomDatePicker
                  fullWidth
                  label="Текущая дата отгрузки"
                  placeholder="Введите дату"
                  wrapperClassname={styles.requests__input_wrapper}
                  value={value}
                  onChange={onChange}
                />
              )}
              defaultValue={""}
              rules={{ required: true }}
            />
            {errors.date_shipping_old && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}
        <div className={`${styles.requests__form_item}`}>
          <Controller
            name="date_shipping_new"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                fullWidth
                label="Планируемая дата отгрузки"
                placeholder="Введите дату"
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={""}
            rules={{ required: true }}
          />
          {errors.date_shipping_new && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>

        <div className={`${styles.requests__form_item}`}>
          <Controller
            name="date_shipping_comment"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                type="text"
                fullWidth
                multiline
                label="Комментарий"
                placeholder="Введите комментарий"
                wrapperClassname={styles.add_team__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={""}
          />
        </div>
        <div className={styles.requests__btn_wrapper}>
          <CButton
            loading={modalLoading}
            disabled={modalLoading}
            type="submit"
            className={styles.requests__btn}
          >
            Сохранить
          </CButton>
        </div>
      </form>
    </div>
  );
};
