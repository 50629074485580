import React, { useState } from "react";
import { PageTitle } from "../../components/common/pageTitle";
import { Requests } from "../../components/features/requests";

export const RequestsPage = () => {
  const [requestsCount, setRequestsCount] = useState(null);
  return (
    <div>
      <PageTitle title={`Заявки (${requestsCount || 0})`} />
      <Requests setRequestsCount={setRequestsCount} showFilters />
    </div>
  );
};
