import React from "react";
import { remontTabComponents, remontTabKeys } from "../services";

const AdditionalTab = ({ menuItem }) => {
  const getContent = () => {
    const tab = remontTabComponents[remontTabKeys[menuItem]];
    if (!menuItem || !tab || !tab.component) return null;
    const Component = tab.component;

    return <Component />;
  };

  return <div>{getContent()}</div>;
};

export default AdditionalTab;
