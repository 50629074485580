import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../common/CustomTable/CustomTable";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHttp } from "../../../../utils/hooks/http.hook";
import { showPopUp } from "../../../../services/redux/actions/app-actions";
import { setAccountingAmountTypes } from "../../../../services/redux/actions/accounting-actions";
import { useTableStyles } from "../styles";
import { AccountingFilter } from "../filter";
import { numberWithCommas } from "../../../../utils";
import { useCustomSnackbar } from "../../../../utils/hooks/useCustomSnackbar";
import styles from "../payment-types.module.scss";
import { Link } from "react-router-dom";
import { PageTitle } from "../../../common/pageTitle";
import { DeleteIconWrapper, EditIconWrapper } from "../../../common/blocks";
import { MODALS_NAMES, PAGES_ROUTE_KEY } from "../../../../constants";
import { getIncomeDataService } from "./services";
import { CollapseBlock } from "../../../common/CollapseBlock/collapseBlock";

const useStyles = makeStyles(() => ({
  smart_payment: {
    backgroundColor: "#c0fdb6 !important",
    "&:hover": {
      backgroundColor: "#92cf89 !important",
      transition: "all .3s"
    }
  }
}));

export const AccountingIncomes = ({ alternative = false, remont_id }) => {
  if (!alternative) document.title = "Доходы";
  const classes = useTableStyles();
  const classesLocale = useStyles();
  const dispatch = useDispatch();
  const { request } = useHttp();
  const { addDefaultSnack } = useCustomSnackbar();

  const [filters, setFilters] = useState({
    date_of_begin: null,
    date_of_end: null,
    contractor_income_type_id: null,
    remont_id: null
  });
  const [income, setIncome] = useState([]);
  const [amount, setAmount] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const getAmountSum = useCallback(
    arr => {
      try {
        const amount =
          arr?.reduce((prev, item) => prev + item?.income_sum, 0) || 0;
        dispatch(setAccountingAmountTypes({ income: amount }));
        setAmount(amount ? Number(amount.toFixed(2)) : amount);
      } catch (e) {}
    },
    [dispatch]
  );

  const getIncomeData = useCallback(
    async (remont_id, filters) => {
      const res = await getIncomeDataService(
        remont_id,
        filters,
        alternative,
        setIsFetching
      );
      getAmountSum(res);
      setIncome(res);
    },
    [alternative, getAmountSum]
  );

  useEffect(() => {
    getIncomeData(remont_id);
  }, [getIncomeData, remont_id]);

  const onIncomeSubmit = async data => {
    let endpoint = "/partner/contractor_incomes/";
    if (remont_id) {
      endpoint = `/partner/remont/${remont_id}/accounting/`;
    }
    const res = await request(endpoint, "post", data, {}, { isModal: true });
    if (res?.status) {
      addDefaultSnack("Доход успешно создан");
      getAmountSum(res?.contractor_incomes);
      setIncome(res?.contractor_incomes);
      dispatch(showPopUp(false));
    }
  };

  const onIncomeAddClick = () => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addIncome, {
        onSubmit: onIncomeSubmit,
        remont_id
      })
    );
  };

  const onIncomeSubmitUpdate = async data => {
    let endpoint = `/partner/contractor_incomes/${data?.contractor_income_id}/update/`;
    if (remont_id) {
      endpoint = `/partner/remont/${remont_id}/accounting/contractor_income/${data?.contractor_income_id}/update/`;
    }
    const res = await request(endpoint, "put", data, {}, { isModal: true });
    if (res?.status) {
      addDefaultSnack("Доход успешно изменен");
      getAmountSum(res?.contractor_incomes);
      setIncome(res?.contractor_incomes);
      dispatch(showPopUp(false));
    }
  };

  const onEditIncomesClick = body => {
    dispatch(
      showPopUp(true, MODALS_NAMES.addIncome, {
        onSubmit: onIncomeSubmitUpdate,
        body,
        remont_id
      })
    );
  };

  const handleDeleteIncome = async incomeId => {
    let endpoint = `/partner/contractor_incomes/${incomeId}/delete/`;
    if (remont_id) {
      endpoint = `/partner/remont/${remont_id}/accounting/contractor_income/${incomeId}/delete/`;
    }
    const res = await request(endpoint, "delete", {}, {}, { isModal: true });
    if (res?.status) {
      addDefaultSnack("Доход успешно удален");
      getAmountSum(res?.contractor_incomes);
      setIncome(res?.contractor_incomes);
      dispatch(showPopUp(false));
    }
  };

  const onDeleteIncomeClick = id => {
    dispatch(
      showPopUp(true, MODALS_NAMES.confirmModal, {
        onSubmit: handleDeleteIncome,
        id
      })
    );
  };
  let incomeHeadItems = [
    { label: "Дата", align: "left" },
    { label: "Тип", align: "left" },
    { label: "Сумма", align: "left", width: 200 },
    { label: "Компания", align: "left" },
    { label: "Инициатор", align: "left" },
    { label: "Ремонт", align: "left", width: 200, isRemont: true },
    { label: "Этап", align: "left", isRemont: true },
    { label: "Комментарий", align: "left", width: 350 },
    { label: "", align: "left" }
  ];
  if (!!remont_id) {
    incomeHeadItems = incomeHeadItems.filter(item => !item?.isRemont);
  }
  const onFilterChange = async options => {
    const filters = {};
    setIncome([]);
    for (let key in options) {
      if (!!options[key] && options[key] !== "''") {
        filters[key] = options[key];
      }
    }
    setFilters(filters);
    return await getIncomeData(null, filters);
  };

  const title = `Доходы (${numberWithCommas(amount)})`;
  return (
    <>
      {alternative ? <h2>{title}</h2> : <PageTitle title={title} />}
      <div
        className={`${styles.accounting} ${
          alternative ? styles.accounting__page : ""
        }`}
      >
        <div className={styles.accounting__content}>
          <CollapseBlock className={styles.accounting__header}>
            <Button
              className={clsx(classes.btn)}
              variant="outlined"
              onClick={onIncomeAddClick}
            >
              Добавить
            </Button>
            {!alternative && (
              <AccountingFilter
                type={"income"}
                defaultFilters={filters}
                onFilterChange={onFilterChange}
              />
            )}
          </CollapseBlock>
          <CustomTable
            headItems={incomeHeadItems}
            isEmpty={!income?.length}
            isFetching={isFetching}
            stickyHeader
            containerClassName={classes.root}
          >
            {income?.map((item, i) => {
              return (
                <CustomTableRow
                  className={
                    item?.is_from_payment_stage
                      ? classesLocale.smart_payment
                      : ""
                  }
                  fixed={item?.fixed}
                  fixedDirection="top"
                  key={i}
                >
                  <CustomTableCell align="center" fixed={item?.fixed} width={80}>
                    {item?.income_date}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    {item?.contractor_income_type_name}
                  </CustomTableCell>
                  <CustomTableCell align="right" width={100}>
                    {numberWithCommas(item?.income_sum)}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    {item?.contractor_name || ""}
                  </CustomTableCell>
                  <CustomTableCell align="left">
                    {item?.initiator_fio || ""}
                  </CustomTableCell>
                  {!remont_id && (
                    <CustomTableCell align="left" width={180}>
                      <Link
                        className={classes.link}
                        to={`${PAGES_ROUTE_KEY.singleRemont}/${item?.remont_id}`}
                      >
                        {item?.remont_information}
                      </Link>
                    </CustomTableCell>
                  )}
                  {!remont_id && <CustomTableCell align="left">
                    {item?.stage_contractor_name}
                  </CustomTableCell>}
                  <CustomTableCell align="left">
                    {item?.comment}
                  </CustomTableCell>
                  <CustomTableCell align="center" width={65}>
                    {!item?.is_from_payment_stage && (
                      <div className={classes.actions}>
                        <EditIconWrapper
                          onClick={() => onEditIncomesClick(item)}
                        />
                        <DeleteIconWrapper
                          onClick={() =>
                            onDeleteIncomeClick(item?.contractor_income_id)
                          }
                        />
                      </div>
                    )}
                  </CustomTableCell>
                </CustomTableRow>
              );
            })}
          </CustomTable>
        </div>
      </div>
    </>
  );
};
