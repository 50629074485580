import React, { useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { LoaderData } from "../../LoaderData";

export const CustomAccordion = ({
  label = "",
  children,
  checkbox = false,
  disabled = false,
  isHalfChecked,
  isChecked,
  valueKey = "",
  loading = {},
  renderData = null,
  handleCheckBoxClick
}) => {
  const classes = useStyles();
  const wrapRef = useRef();
  const childrenDataRef = useRef();
  const [expanded, setExpanded] = useState(false);

  const onClick = e => {
    try {
      const isChildren = wrapRef?.current?.contains(e.target);
      if (!isChildren) return;
      const isChildrenData = childrenDataRef.current?.contains(e.target);
      if (e.target?.type === "checkbox" || isChildrenData) return;
    } catch (e) {}
    setExpanded(prev => !prev);
  };
  return (
    <Accordion
      className={classes.root}
      expanded={expanded}
      ref={wrapRef}
      classes={{ expanded: classes.expanded }}
    >
      <AccordionSummary
        onClick={onClick}
        expandIcon={<ExpandMoreIcon />}
        classes={{ content: classes.summary }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {checkbox && (
          <Checkbox
            indeterminate={isHalfChecked}
            checked={isChecked}
            disabled={disabled}
            onClick={handleCheckBoxClick}
            classes={{ disabled: classes.disabled }}
            className={clsx(classes.checkbox, {
              [classes.readyCheckbox]: isChecked
            })}
            color={"primary"}
          />
        )}
        <div className={classes.content}>
          <Typography className={classes.heading}>{label}</Typography>
          {renderData && (
            <div ref={childrenDataRef} className={classes.headingRender}>
              {renderData()}
            </div>
          )}
          {loading[valueKey] && (
            <div className={classes.loader}>
              <LoaderData small />
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 48
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0
    }
  },
  content: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "10px"
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 20
  },
  readyCheckbox: {
    color: "#1aab09 !important",
    borderColor: "#1aab09 !important"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    margin: 0,
    display: "flex",
    alignItems: "center"
  },
  summary: {
    alignItems: "center"
  },
  expanded: {
    margin: "5px 0 !important"
  },
  disabled: {
    color: "rgba(0, 0, 0, 0.26) !important"
  }
}));
