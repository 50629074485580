import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MAIN_URL } from "../../constants";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles(theme => ({
  root: {
    verticalAlign: "top",
    borderTop: "1px solid #F0F2F6"
  },
  details: {
    display: "flex",
    flexDirection: "row"
  },
  content: {
    padding: 5
  },
  secondaryHeading: {
    fontSize: 12,
    fontWeight: 600,
    color: "#9194A9"
  },
  cover: {
    width: 151
  },
  controls: {
    display: "flex",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: "black",
    marginRight: 5
  },
  href: {
    fontWeight: 500,
    fontSize: 14,
    color: "#3190ff"
  },
  image: {
    width: 100
  }
}));

export default function ReportCommentPhoto({ data = {} }) {
  const classes = useStyles();
  const row = [];

  Object.keys(data.remarks).forEach(function(key) {
    row.push(data.remarks[key]);
  });
  return row.map(item => (
    <div className={classes.root} key={item?.remont_check_id}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography
            component="h5"
            variant="h5"
            className={classes.secondaryHeading}
          >
            Фото/видео фиксация
          </Typography>
          <a
            href={MAIN_URL + item.content_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CardMedia
              component="img"
              alt={item.content_name}
              height="100"
              image={MAIN_URL + item.content_url}
              title={item.content_name}
              className={classes.image}
            />
          </a>
        </CardContent>
        <div className={classes.content}>
          <Typography
            component="h5"
            variant="h5"
            className={classes.secondaryHeading}
          >
            Замечание
          </Typography>
          <Typography variant="h4" component="h2" className={classes.title}>
            {item.comments}
          </Typography>
          {item.audio ? (
            <a
              href={MAIN_URL + item.audio_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className={classes.href}>Аудио</span>
            </a>
          ) : null}

          <Typography variant="h4" component="h2" className={classes.title}>
            {item.date_create}
          </Typography>
          <Typography variant="h4" component="h2" className={classes.title}>
            {item.employee}
          </Typography>
        </div>
      </div>
    </div>
  ));
}
