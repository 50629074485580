import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import { getResidentsList } from "../remonts/services/api";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { useReportFiltersStyles } from "./RemontReportFilters";

const JkFilter = ({ handleChange, value }) => {
  const classes = useReportFiltersStyles();
  const [residentList, setResidentList] = useState([]);

  useEffect(() => {
    getResidentsList().then(res => {
      !res?.error && setResidentList(res);
    });
  }, []);

  return (
    <FormControl className={classes.formControl}>
      <AutoSuggest
        list={residentList}
        value={value}
        showKey={"resident_name"}
        valueKey={"resident_id"}
        placeholder="Выберите ЖК"
        label="Жилой комплекс"
        onChange={e => handleChange("resident_id", e?.target?.value)}
      />
    </FormControl>
  );
};

export default JkFilter;
