import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getPaymentHist } from "./api";
import NumberFormat from "react-number-format";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 5,
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)",
    borderRadius: 4
  },
  row_td: {
    borderRight: "1px solid #F0F2F6"
  },
  cell: {
    borderLeft: "1px solid #F0F2F6",
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 120
  },
  cell_first: {
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 150
  },
  header: {
    verticalAlign: "top",
    fontSize: 20,
    color: "#0C4B86",
    fontWeight: 600
  },
  head: {
    borderLeft: "1px solid #F0F2F6",
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 120,
    color: "#A5A7B5"
  },
  head_first: {
    verticalAlign: "top",
    fontSize: 18,
    minWidth: 150,
    color: "#A5A7B5"
  }
}));

export default function PaymentHistory({ data = {} }) {
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [dataGet, setData] = useState({});
  const row = [];

  React.useEffect(() => {
    const params = {
      remont_id: data.remont_id,
      stage_id: data.stage_id
    };
    getPaymentHist(params)
      .then(answer => {
        setData(answer.data.data);
      })
      .catch(err => {
        addCloseSnack(err.response?.data?.error);
      });
  }, [addCloseSnack, data.remont_id, data.stage_id]);

  const stage_num = useMemo(() => {
    let stage_num;
    if (data.stage_id === 3) {
      stage_num = "1";
    } else if (data.stage_id === 4) {
      stage_num = "2";
    } else if (data.stage_id === 5) {
      stage_num = "3";
    } else if (data.stage_id === 6) {
      stage_num = "4";
    } else {
      stage_num = "Доп.оплаты";
    }
    return stage_num;
  }, [data.stage_id]);

  Object.keys(dataGet).forEach(function(key) {
    row.push(dataGet[key]);
  });

  return (
    <TableContainer component={Paper} className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} align="left" className={classes.header}>
              {stage_num} этап
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.head_first} align="left">
              Дата
            </TableCell>
            <TableCell className={classes.head} align="left">
              Сумма
            </TableCell>
            <TableCell className={classes.head} align="left">
              Комментарий
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {row.map(arr => (
            <TableRow key={arr.payment_date}>
              <TableCell className={classes.cell_first} align="center">
                {arr.payment_date}
              </TableCell>
              <TableCell className={classes.cell} align="right">
                <span>
                  <NumberFormat
                    value={arr.payment_amount}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    title={arr.payment_amount}
                  />
                </span>
              </TableCell>
              <TableCell className={classes.cell} align="left">
                {arr.comments}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
