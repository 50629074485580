import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  ClickAwayListener,
  InputLabel,
  NativeSelect,
  Paper,
  TextareaAutosize,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  changeCardInfo,
  createComment,
  getCardComments,
  getCardInfo
} from "./api";
import CommentsBox from "./CommentsBox";
import { useDispatch, useSelector } from "react-redux";
import {
  setCardId,
  setColumns
} from "../../services/redux/actions/problemsActions";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import AsideContent from "./AsideContent";
import LocalAddedFile from "./LocalAddedFile";
import ProblemCommentBox from "./ProblemCommentBox";
import axios from "axios";
import { source } from "../../services/api";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const ProblemModal = ({ data, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const { card_id, col_id, isRemontProblem } = data;
  const [width, setWidth] = useState(0);
  const [info, setInfo] = useState({});
  const [cardInfo, setCardInfo] = useState({
    card_title: info?.card_title || null,
    card_content: info?.card_content || "",
    client_request_id: info?.client_request_id || null,
    card_type_id: info?.card_type_id || null,
    priority_id: info?.priority_id || "",
    is_archiving: info?.is_archiving || null
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [state, setState] = useState({
    comment: [],
    commentText: "",
    isCommentClicked: false,
    isAddCommentClicked: false,
    commentCopy: "",
    files: [],
    addedFiles: []
  });

  const {
    comment,
    commentText,
    isCommentClicked,
    isAddCommentClicked,
    commentCopy,
    files,
    addedFiles
  } = state;
  const { show } = useSelector(state => state.appState.popup);
  const { remont_id } = useSelector(state => state.remontsState.resident);
  const handleCommentChange = e => {
    setState({
      ...state,
      commentText: e.target.value
    });
  };
  const handleChange = e => {
    const name = e.target.name;
    const newState = {
      ...cardInfo,
      [name]: e.target.value
    };
    setCardInfo(newState);
    if (name !== "card_content") {
      changeCardInfo(card_id, newState).then(res => {
        res?.data && dispatch(setColumns(res?.data));
      });
    }
  };

  const handleDoneClick = () => {
    changeCardInfo(card_id, cardInfo).then(res => {
      dispatch(setColumns(res?.data));
      setState({
        ...state,
        isCommentClicked: false
      });
    });
  };
  const handleCloseClick = () => {
    setState({
      ...state,
      isCommentClicked: false
    });
    setCardInfo({
      ...cardInfo,
      card_content: commentCopy
    });
  };
  const handleUploadChange = e => {
    const filesNames = Object.values(e.target.files).map(el => el.name);
    setState({
      ...state,
      addedFiles: filesNames,
      files: e.target.files
    });
  };
  const handleClickAway = () => {
    setState({
      ...state,
      isCommentClicked: false
    });
  };
  const handleKeyDown = () => {
    setState({
      ...state,
      isCommentClicked: true
    });
  };
  const handleDescrClick = () => {
    setState({
      ...state,
      commentCopy: cardInfo.card_content,
      isCommentClicked: true
    });
  };
  const handleCommentClickAway = () => {
    setState({
      ...state,
      isAddCommentClicked: false,
      addedFiles: [],
      commentText: ""
    });
  };
  const handleSaveClick = () => {
    if (!commentText) return;
    setIsDisabled(true);

    fd.append("comments", commentText);
    for (const key of Object.keys(files)) {
      fd.append("files", files[key]);
    }
    createComment(card_id, fd, options)
      .then(res => {
        setState({
          ...state,
          commentText: "",
          files: [],
          isAddCommentClicked: false,
          addedFiles: [],
          comment: res?.data
        });
        addDefaultSnack("Комментарий успешно добавлен");
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          if (show) {
            setState({
              ...state,
              commentText: "",
              files: [],
              isAddCommentClicked: false,
              addedFiles: []
            });
          }
        } else {
          addCloseSnack(err.response.data.error);
        }
      })
      .finally(() => {
        if (show) {
          setIsDisabled(false);
          setWidth(0);
        }
      });
  };
  const handleCloseIconClick = () => {
    source.cancel();
    setState({
      ...state,
      commentText: "",
      isAddCommentClicked: false,
      addedFiles: [],
      files: []
    });
  };
  const handleAddComment = () => {
    setState({
      ...state,
      isAddCommentClicked: true
    });
  };

  useEffect(() => {
    getCardInfo(card_id)
      .then(res => {
        setInfo(res.data);
        setCardInfo({
          card_title: res.data?.card_title || null,
          card_content: res.data?.card_content || "",
          client_request_id: res.data?.client_request_id || null,
          card_type_id: res.data?.card_type_id || null,
          priority_id: res.data?.priority_id || "",
          is_remont_card: isRemontProblem || false,
          remont_id: remont_id || null
        });
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [card_id, remont_id, isRemontProblem, addCloseSnack]);

  useEffect(() => {
    getCardComments(card_id)
      .then(res => {
        setState(prev => ({
          ...prev,
          comment: res?.data
        }));
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [card_id, addCloseSnack]);

  useEffect(() => {
    dispatch(setCardId(card_id));
  }, [dispatch, card_id]);

  let fd = new FormData();

  const options = {
    onUploadProgress: progressEvent => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);

      setWidth(percent);
    }
  };

  return (
    <Paper elevation={0} className={classes.modal}>
      <Box className={classes.header}>
        <Typography className={classes.header_title}>
          Карточка обращения
        </Typography>

        <Typography onClick={handleClose}>
          <CloseIcon
            style={{
              color: "#0C4B86",
              cursor: "pointer"
            }}
          />
        </Typography>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.main_content}>
          <Typography
            className={classes.content_title}
            style={{ marginBottom: 25 }}
          >
            Информация об обращении
          </Typography>
          <Box className={classes.problem_info}>
            <Box>
              <InputLabel className={classes.label}>Жилой комплекс</InputLabel>
              <Box>{info?.resident_name}</Box>
            </Box>
            <Box className={classes.select_box}>
              <InputLabel className={classes.label}>Квартира</InputLabel>
              <Box>{info?.flat_num}</Box>
            </Box>

            <ClickAwayListener onClickAway={handleClickAway}>
              <Box
                style={{ width: "100%", marginBottom: 25 }}
                display="flex"
                flexDirection="column"
              >
                <label className={classes.label} style={{ display: "block" }}>
                  Описание обращения
                </label>
                <TextareaAutosize
                  className={classes.text_field}
                  placeholder="Добавьте подробное описание обращения"
                  defaultValue={info?.card_content}
                  name="card_content"
                  onChange={handleChange}
                  value={cardInfo.card_content}
                  onKeyDown={handleKeyDown}
                  onClick={handleDescrClick}
                />

                {isCommentClicked ? (
                  <Box mt={1} style={{ marginLeft: "auto" }}>
                    <DoneIcon
                      className={classes.icon}
                      onClick={handleDoneClick}
                    />
                    <CloseIcon
                      className={classes.icon}
                      style={{ color: "#f54242" }}
                      onClick={handleCloseClick}
                    />
                  </Box>
                ) : null}
              </Box>
            </ClickAwayListener>

            <Box>
              <InputLabel className={classes.label} style={{ height: 23 }}>
                Тип обращения
              </InputLabel>
              <Box>{info?.card_type_name}</Box>
            </Box>
            <Box className={classes.select_box}>
              <label className={classes.label}>Приоритет</label>
              <NativeSelect
                className={classes.select}
                name="priority_id"
                onChange={handleChange}
                value={cardInfo.priority_id}
              >
                <option hidden>{info?.priority_name} </option>
                {info?.prioritites?.map(el => {
                  return (
                    <option key={el.priority_id} value={el.priority_id}>
                      {el.priority_name}
                    </option>
                  );
                })}
              </NativeSelect>
            </Box>
          </Box>
          <Box>
            <Typography
              className={classes.content_title}
              style={{ marginBottom: 25 }}
            >
              Обсуждение
            </Typography>
            <Box style={{ width: "100%" }}>
              {isAddCommentClicked ? (
                <ClickAwayListener onClickAway={handleCommentClickAway}>
                  <Box className={classes.comment_box}>
                    <Avatar className={classes.avatar} />
                    <Box style={{ width: "100%", marginBottom: 15 }}>
                      <ProblemCommentBox
                        handleChange={handleCommentChange}
                        value={commentText}
                        disabled={isDisabled}
                        handleClick={handleSaveClick}
                        handleClose={handleCloseIconClick}
                        handleUpload={handleUploadChange}
                      />
                    </Box>
                  </Box>
                </ClickAwayListener>
              ) : (
                <Box display="flex" flexDirection="column" mb={3}>
                  <Box display="flex">
                    <Avatar className={classes.avatar} />
                    <TextareaAutosize
                      className={classes.comment}
                      placeholder="Добавить комментарий..."
                      onKeyDown={handleAddComment}
                      onClick={handleAddComment}
                    />
                  </Box>
                </Box>
              )}

              {isAddCommentClicked && (
                <Box
                  style={{
                    paddingLeft: 44,
                    display: "flex",
                    flexWrap: "wrap",
                    marginRight: 15
                  }}
                >
                  {addedFiles?.map((el, i) => (
                    <div
                      key={i}
                      style={{
                        maxWidth: 170,
                        width: "100%",
                        marginRight: 15
                      }}
                    >
                      <LocalAddedFile width={width} name={el} />
                    </div>
                  ))}
                </Box>
              )}

              {comment?.map(el => {
                return (
                  <CommentsBox
                    key={el.comment_id}
                    {...el}
                    card_id={card_id}
                    setState={setState}
                    state={state}
                    options={options}
                    width={width}
                    setWidth={setWidth}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
        <AsideContent
          col_id={col_id}
          cardInfo={cardInfo}
          setCardInfo={setCardInfo}
          card_id={card_id}
        />
      </Box>
    </Paper>
  );
};
const useStyles = makeStyles(theme => ({
  modal: {
    maxWidth: 875,
    width: "100%",
    padding: 25
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 25
  },
  header_title: {
    color: "#0C4B86",
    fontSize: 18,
    fontWeight: 500
  },
  content: {
    display: "flex",
    justifyContent: "space-between",

    marginBottom: 10,
    position: "relative",
    [theme.breakpoints.down(768)]: {
      display: "block"
    }
  },
  content_title: {
    fontSize: 12,
    fontWeight: 600,
    color: "#494C62",
    lineHeight: "15px"
  },
  main_content: {
    maxWidth: 582,
    width: "100%",
    marginBottom: 25
  },

  problem_info: {
    paddingLeft: 30,
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  select_box: {
    maxWidth: 256,
    width: "48%",
    marginBottom: 25
  },
  label: {
    color: "#9194A9",
    fontSize: 12,
    fontWeight: 600,
    marginBottom: 11,
    display: "block"
  },
  select: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: "0.01em",
    color: "#303030",
    lineHeight: 1.5,
    width: "100%"
  },
  text_field: {
    width: "100%",
    border: "none",
    borderBottom: "1px solid #E2E3ED",
    resize: "unset",
    "&::focus-visible": {
      outline: "none",
      padding: 5
    }
  },

  text_area: {
    width: "100%",
    border: "1px solid #E2E3ED",
    borderRadius: 4,
    backgroundColor: "#fff",
    padding: 10,
    fontSize: 14,
    color: "#494C62",
    outline: "none",
    letterSpacing: "0.01em",
    resize: "none"
  },
  comment_box: {
    display: "flex"
  },
  avatar: {
    marginRight: 15,
    width: 30,
    height: 30
  },
  name: {
    display: "inline-block",
    marginRight: 10,
    fontSize: 12,
    fontWeight: 600,
    color: "#494C62"
  },
  date: {
    display: "inline-block",
    fontSize: 11,
    fontWeight: 400,
    color: "#9194A9"
  },
  edit: {
    fontSize: 11,
    fontWeight: 500,
    color: "#9194A9",
    display: "inline-block",
    marginRight: 10,
    textDecoration: "underline",
    cursor: "pointer"
  },
  icon: {
    color: "#0C4B86",
    cursor: "pointer",
    boxShadow: "0px 2px 4px 2px rgba(0, 40, 71, 0.06)",
    width: "1.1em !important",
    height: "1.1em !important",
    margin: 2
  },
  comment: {
    width: "100%",
    border: "none",
    borderBottom: "1px solid #E2E3ED",
    backgroundColor: "#fff",
    fontSize: 14,
    color: "#494C62",
    outline: "none",
    letterSpacing: "0.01em",
    resize: "none"
  },
  text_area_wrapper: {
    border: "1px solid #E2E3ED",
    borderRadius: 4,
    padding: 10
  },
  text_area_btn: {
    backgroundColor: "#0C4B86",
    padding: "7px 10px",
    height: 30,
    textAlign: "center",
    color: "#fff",
    borderRadius: 4,
    boxShadow: "none",
    marginRight: 20
  },
  close_icon: {
    color: "#0C4B86",
    transform: "translateY(8px)",
    cursor: "pointer"
  },
  aside_content: {
    width: 168,
    marginLeft: 25
  }
}));

export default ProblemModal;
