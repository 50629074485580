import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  NativeSelect,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { addNewCard, fetchResidentFlats, getProblemName } from "./api";
import { setColumns } from "../../services/redux/actions/problemsActions";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles(theme => ({
  modal: {
    maxWidth: 875,
    width: "100%",
    backgroundColor: "rgb(248, 250, 251)"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 25
  },
  header_title: {
    color: "#0C4B86",
    fontSize: 18,
    fontWeight: 500
  },
  content: {
    display: "flex",
    justifyContent: "space-between",

    marginBottom: 10
  },
  content_title: {
    fontSize: 12,
    fontWeight: 600,
    color: "#494C62",
    lineHeight: "15px"
  },
  main_content: {
    maxWidth: 582,
    width: "100%"
  },
  problem_info: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down(768)]: {
      paddingLeft: 0
    }
  },
  select_box: {
    maxWidth: 256,
    width: "100%",
    marginBottom: 25
  },
  label: {
    color: "#9194A9",
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 11
  },
  text_label: {
    marginBottom: 4,
    color: "#9194A9",
    fontWeight: 600,
    fontSize: 14
  },
  select: {
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: "0.01em",
    color: "#303030",
    lineHeight: 1.5
  },
  text_field: {
    width: "100%",
    marginBottom: 15
  },
  add_btn: {
    backgroundColor: "#0C4B86",
    color: "#fff",
    marginLeft: "auto",
    padding: "5px 25px",
    borderRadius: 4
  },
  close_icon: {
    color: "#0C4B86",
    cursor: "pointer"
  },
  option: {
    fontSize: 16,
    fontWeight: 500
  },
  search: {
    "& .MuiFormControl-root": {
      width: "100%"
    }
  }
}));

const ProblemsModal = ({ handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const [flats, setFlats] = useState([]);
  const { residentsForFilter, cardTypes, priorities } = useSelector(
    state => state.problemsState
  );

  const handleResidentChange = resident_id => {
    formik.setFieldValue("title", "");
    fetchResidentFlats(+resident_id).then(res => {
      if (res?.data?.error) {
        addCloseSnack(res?.data?.error);
      } else {
        setFlats(res?.data);
      }
    });
  };
  const validationSchema = Yup.object({
    flat: Yup.string().required("Укажите номер квартиры"),
    type: Yup.string().required("Укажите тип обращения"),
    title: Yup.string().required("Укажите название обращения"),
    resident: Yup.string().required("Укажите жилой комплекс")
  });

  const formik = useFormik({
    initialValues: {
      flat: "",
      type: "",
      title: "",
      resident: "",
      priority: null,
      content: "",
      remontId: ""
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      const { flat, type, title, priority, content } = formik.values;
      const params = {
        card_title: title,
        client_request_id: flat,
        card_type_id: type,
        col_id: 35,
        card_content: content,
        priority_id: priority
      };
      addNewCard(params)
        .then(res => {
          dispatch(setColumns(res?.data));
          addDefaultSnack("Успешно");
          handleClose();
        })
        .catch(err => {
          addCloseSnack(err.response.data.error);
        });
    }
  });

  const fetchCardName = async e => {
    try {
      const { data } = await getProblemName({
        client_request_id: e.target.value
      });
      await formik.setFieldValue("title", data);
    } catch (err) {
      addCloseSnack(e?.response?.data?.error);
    }
  };

  return (
    <Paper elevation={0} className={classes.modal}>
      <Box className={classes.header}>
        <Typography className={classes.header_title}>
          Карточка обращения
        </Typography>

        <Typography onClick={handleClose}>
          <CloseIcon className={classes.close_icon} />
        </Typography>
      </Box>
      <Box className={classes.content}>
        <form className={classes.main_content} onSubmit={formik.handleSubmit}>
          <Typography
            className={classes.content_title}
            style={{ marginBottom: 25 }}
          >
            Информация об обращении
          </Typography>
          <Box className={classes.problem_info}>
            <FormControl
              className={classes.select_box}
              required
              error={formik.touched.resident && Boolean(formik.errors.resident)}
            >
              <AutoSuggest
                list={residentsForFilter || []}
                value={formik.values.resident}
                showKey={"resident_name"}
                valueKey={"resident_id"}
                placeholder="Выберите ЖК"
                label="Жилой комплекс"
                id="resident"
                name="resident"
                className={classes.select}
                onChange={e => {
                  formik.handleChange(e);
                  handleResidentChange(e.target.value);
                }}
              />
              <FormHelperText>
                {formik.touched.resident && formik.errors.resident}
              </FormHelperText>
            </FormControl>
            <FormControl
              className={classes.select_box}
              error={formik.touched.flat && Boolean(formik.errors.flat)}
            >
              <AutoSuggest
                list={flats}
                value={formik.values.flat}
                showKey={"flat_num"}
                valueKey={"client_request_id"}
                placeholder="Выберите квартиру"
                label="Квартира"
                id="flat"
                name="flat"
                className={classes.select}
                onChange={e => {
                  formik.handleChange(e);
                  fetchCardName(e);
                }}
              />
              <FormHelperText>
                {formik.touched.flat && formik.errors.flat}
              </FormHelperText>
            </FormControl>
            <FormControl
              style={{ width: "100%", marginTop: 20 }}
              error={
                formik.touched.title &&
                Boolean(formik.errors.title) &&
                !formik.values.resident
              }
            >
              <InputLabel
                className={classes.text_label}
                style={{ display: "block", top: "-40px" }}
                required
              >
                Название обращения
              </InputLabel>
              <TextField
                className={classes.text_field}
                placeholder="Добавьте название обращения"
                value={formik.values.title}
                id="title"
                name="title"
                helperText={
                  formik.touched.title &&
                  formik.errors.title &&
                  !formik.values.resident
                }
                error={
                  formik.touched.title &&
                  Boolean(formik.errors.title) &&
                  !formik.values.resident
                }
                disabled
              />
            </FormControl>
            <FormControl style={{ width: "100%", marginBottom: 10 }}>
              <label
                className={classes.text_label}
                style={{ display: "block" }}
              >
                Описание обращения
              </label>
              <TextField
                className={classes.text_field}
                value={formik.values.content}
                onChange={formik.handleChange}
                placeholder="Добавьте подробное описание обращения"
                id="content"
                name="content"
              />
            </FormControl>

            <FormControl
              className={classes.select_box}
              error={formik.touched.type && Boolean(formik.errors.type)}
            >
              <InputLabel className={classes.label} required>
                Тип обращения
              </InputLabel>
              <NativeSelect
                className={classes.select}
                value={formik.values.type}
                onChange={formik.handleChange}
                id="type"
                name="type"
              >
                <option className={classes.option}>Выберите тип</option>
                {cardTypes?.map(el => {
                  return (
                    <option
                      key={el.card_type_id}
                      value={el.card_type_id}
                      className={classes.option}
                    >
                      {el.card_type_name}
                    </option>
                  );
                })}
              </NativeSelect>
              <FormHelperText>
                {formik.touched.type && formik.errors.type}
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.select_box}>
              <InputLabel className={classes.label}>Приоритет</InputLabel>
              <NativeSelect
                className={classes.select}
                onChange={formik.handleChange}
                id="priority"
                name="priority"
              >
                <option className={classes.option}>Выберите приоритет</option>
                {priorities?.map(el => {
                  return (
                    <option className={classes.option} key={el.priority_id} value={el.priority_id}>
                      {el.priority_name}
                    </option>
                  );
                })}
              </NativeSelect>
            </FormControl>
            <Button className={classes.add_btn} type="submit">
              Добавить
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};

export default ProblemsModal;
