import instance from "../../services/api";

export const reportOkkStage = (params = {}) => {
  return instance(true)
    .post("/partner/report_stages_and_okk/", params)
    .then((res = {}) => {
      return res?.data;
    });
};

export const getJKList = () => {
  return instance(true).get("/partner/read_resident_by_contractor/");
};

export const getDefectList = (params = {}) => {
  return instance(true).post("/partner/defect_list/", params);
};
export const callOkk = (params = {}) => {
  return instance(true).post("/partner/remont_stage/", params);
};

export const deliverClient = (remont_id, params = {}) => {
  return instance(true).post(
    `/partner/remont_receive_date/${remont_id}/`,
    params
  );
};
