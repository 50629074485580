import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { ComplexIcon } from "../../components/common/blocks/Amount";

const useStyles = makeStyles({
  card_header: {
    maxWidth: 395,
    padding: "10px 12px",
    boxShadow: "0px 5px 10px rgba(108, 108, 108, 0.1);",
    border: "1px solid #E2E3ED",
    borderRadius: "4px",
    backgroundColor: "#fff",
    cursor: "pointer",
    marginBottom: 10,
    marginRight: 16,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%"
  },
  card_wrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px"
  },
  card_rate: {
    display: "inline-flex",
    alignItems: "center",
    padding: "6px 14px",
    textTransform: "uppercase",
    color: "#fff",
    borderRadius: "15px",
    fontsize: "10px",
    fontWeight: 600,
    fontFamily: "Montserrat, sans-serif"
  },
  card_icon: {
    marginLeft: "auto",
    cursor: "pointer"
  },
  card_text: {
    paddingBottom: 9,
    borderBottom: "1px solid #E7E9F1"
  },
  resident: {
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 10,
    display: "flex",
    alignItems: "center"
  },
  resident_info: {
    fontSize: 12
  },
  card_footer: {
    marginTop: 7
  },
  card_footer_text: {
    fontSize: 12,
    fontWeight: 500,
    color: "#A5A7B5"
  },
  card_executors: {
    fontSize: 12,
    fontWeight: 500,
    color: "#303030",
    textAlign: "right"
  },
  id: {
    fontSize: 14,
    fontWeight: 500,
    color: "#72768F"
  },
  date: {
    fontSize: 10,
    fontWeight: 400
  }
});

const ArchiveCard = ({
                       card_id,
                       date_create,
                       resident_name,
                       flat_num,
                       card_title,
                       item,
                       executors,
                       card_type_name
                     }) => {
  const classes = useStyles();
  return (
    <Paper
      className={classes.card_header}
      style={{
        transform: `translateY(${item.start}px)`
      }}
    >
      <Box className={classes.card_wrapper}>
        <Box style={{ width: "80px" }}>
          <Typography className={classes.id}>№{card_id}</Typography>
          <Typography className={classes.date}>{date_create}</Typography>
        </Box>
      </Box>
      <Box className={classes.card_text}>
        <Typography className={classes.resident}>
          <ComplexIcon />
          {resident_name}, КВ {flat_num}
        </Typography>{" "}
        <Typography className={classes.resident}>{card_title}</Typography>{" "}
        <Typography className={classes.resident_info}>
          Подробное описание проблемы показывается внутри карточки
        </Typography>
      </Box>
      <Box className={classes.card_footer}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography className={classes.card_footer_text}>
            Тип проблемы:
          </Typography>
          <Typography className={classes.card_executors}>
            {card_type_name}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.card_footer_text}>
            Ответственный:
          </Typography>
          <Typography className={classes.card_executors}>
            {executors}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default ArchiveCard;
