import { changeRemontContractor, getRemontTabulations } from "./api";

export const changeRemontContractorData = async (remont_id, body) => {
  try {
    return await changeRemontContractor(remont_id, body);
  } catch (e) {}
};
export const getRemontTabulationsData = async () => {
  try {
    const res = await getRemontTabulations();
    return res?.tabulations?.map((item, index) => ({ ...item, index }));
  } catch (e) {}
};
