import { executiveReportAPI } from "./api";
import { fetchResidentList } from "../../Problems/api";
import {
  getContractors,
  getTechProjectDevelopers,
  getTechProjectEmployees
} from "../../remonts/services/api";
import { getProjectManagers } from "../../remonts/RemontInfo/services/api";

export const getExecutiveReportData = async body => {
  try {
    const res = await executiveReportAPI.getData(body);
    return res?.mng_report;
  } catch (e) {}
};

export const getFiltersData = async () => {
  try {
    const res = await Promise.all([
      getContractors(),
      fetchResidentList(),
      getTechProjectEmployees(),
      getProjectManagers(),
      executiveReportAPI.getProrabs(),
      getTechProjectDevelopers(),
      executiveReportAPI.getSalePoints(),
      executiveReportAPI.getHeadDirections()
    ]);

    const [
      contractorsResponse,
      residentsResponse,
      techProjectEmployees,
      projectManagersResponse,
      prorabs,
      techProjectDevs,
      salePoints,
      headDirections
    ] = res;
    return {
      contractors: contractorsResponse?.data,
      residents: residentsResponse?.data,
      techProjectEmployees,
      projectManagers: projectManagersResponse?.data,
      prorabs,
      techProjectDevs,
      salePoints,
      headDirections
    };
  } catch (e) {
    return {};
  }
};

export const fieldsToDelete = ["client_request_id", "comment_cnt", "color_id"];
