import { jurDateWarrantyReportAPI } from "./api";
import { PAGES_ROUTE_KEY } from "../../../constants";
import React from "react";
import { Clickable } from "../../../components/common/Clickable";

export const getJurDateWarrantyData = async body => {
  try {
    const res = await jurDateWarrantyReportAPI.getData(body);
    if (!res?.data?.length) return;
    const data = res?.data;
    const item = data[0];
    const headItems = Object.keys(item).map(key => {
      const headItem = {
        align: "left",
        label: key,
        key: key
      };
      if(!isNaN(item[key]))
        headItem.align = 'right'
      const itemKey = key.toLowerCase()
      if(itemKey === "id ремонта" || itemKey === "id заявки" || itemKey === "id грейда")
        headItem.align = "center"
      if (key === "ID ремонта")
        headItem.renderContent = item => <Clickable
          routerLink
          href={`${PAGES_ROUTE_KEY.singleRemont}/${item[key]}`}
        >
          {item[key]}
        </Clickable>;
      return headItem;
    });
    return { headItems, data };
  } catch (e) {
  }
};
