import React from "react";
import Loader from "react-loader-spinner";

export const CustomLoader = () => (
  <Loader
    type="ThreeDots"
    color="#0C4B86"
    height={60}
    width={60}
    style={{
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}
  />
);
