import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  tab_root: {
    color: "#000",
    fontSize: "14px",
    textTransform: "capitalize",
    minWidth: 125
  },
  tabWrapper: {
    padding: props => props.padding ? props.padding : 20,
    background: props => props.background,
    [theme.breakpoints.down(576)]: {
      padding: '10px !important',
    }
  }
}));
export const CustomTabs = ({
                             value,
                             onChange,
                             tabs = [],
                             disabled = false
                           }) => {
  const classes = useStyles();
  return (
    <Tabs
      value={value}
      variant="scrollable"
      disabled={disabled}
      scrollButtons="auto"
      onChange={(e, id) => {
        const selected = tabs[id];
        return onChange && onChange(e, id, selected?.key);
      }}
      aria-label="scrollable auto tabs example"
      indicatorColor="primary"
      textColor="primary"
    >
      {tabs?.map((item, i) => {
        return (
          <Tab
            classes={{
              root: classes.tab_root
            }}
            key={i}
            label={item?.label || ""}
          />
        );
      })}
    </Tabs>
  );
};

export const TabPanel = props => {
  const {
    children,
    value,
    index,
    padding,
    background = "none",
    ...other
  } = props;

  const classes = useStyles({ padding, background });
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={classes.tabWrapper}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export const CustomTabPanel = ({ value, children, index }) => {
  return (
    <TabPanel value={value} index={index} padding={"0"}>
      {children}
    </TabPanel>
  );
};
