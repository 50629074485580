import React from "react";

import { useStyles } from "../Modals/penaltyDetailsModal";
import { Box } from "@material-ui/core";
import NumberFormat from "react-number-format";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../components/common/CustomTable/CustomTable";

const GprTable = ({ penaltyReport }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box sx={{ marginBottom: "18px", fontSize: "18px" }}>
        Штраф за задержку по ГПР
      </Box>
      <CustomTable headItems={headTitle}>
        <CustomTableRow className={classes.table_row}>
          <CustomTableCell align="right" className={classes.cell}>
            <NumberFormat
              value={penaltyReport?.agreement_sum}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
            />
          </CustomTableCell>
          <CustomTableCell align="right" className={classes.cell}>
            {penaltyReport?.overdue_days_cnt}
          </CustomTableCell>
          <CustomTableCell align="right" className={classes.cell}>
            <NumberFormat
              value={penaltyReport?.penalty_amount}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator={","}
            />
          </CustomTableCell>
        </CustomTableRow>
      </CustomTable>
    </Box>
  );
};

export default GprTable;

const headTitle = [
  { label: "Сумма", align: "left" },
  { label: "Кол-во дней", align: "left" },
  { label: "0,1%", align: "left" }
];
