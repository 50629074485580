import React, { useState } from "react";
import styles from "./filter.module.scss";
import { CustomDatePicker } from "../../../common/form/CustomDatePicker";
import { AutoSuggest } from "../../../common/form/AutoSuggest";
import { useSelector } from "react-redux";
import { InputSumMask } from "../../../common/form/InputSumMask";

const defaultFiltersList = {
  date_of_begin: "",
  date_of_end: "",
  team_master_id: "",
  contractor_cost_type_id: "",
  contractor_income_type_id: "",
  remont_id: ""
};
export const AccountingFilter = ({
  type = "cost",
  showTeamFilter = true,
  showMastersFilter = true,
  onFilterChange,
  defaultFilters = defaultFiltersList,
  remonts: remontsState,
  masters: mastersState
}) => {
  const { remonts, masters, teams, contractors } = useSelector(
    state => state.appState
  );
  const { contractorCostTypesFilter, contractorIncomeTypes } = useSelector(
    state => state.accountingState
  );
  const [filters, setFilters] = useState(defaultFilters);
  const [lastSubmitSumValue, setLastSubmitSumValue] = useState({});

  const onChange = (key, value, triggerFilterChange = true) => {
    setFilters(prev => ({ ...prev, [key]: value || null }));
    return (
      triggerFilterChange &&
      onFilterChange &&
      onFilterChange({ ...filters, [key]: value })
    );
  };

  const handleSumChange = key => {
    if (
      filters[key] === lastSubmitSumValue[key] ||
      (!filters[key] && lastSubmitSumValue[key] === undefined)
    )
      return;
    onChange(key, filters[key]);
    setLastSubmitSumValue({ [key]: filters[key] });
  };
  return (
    <div className={styles.filter}>
      <div className={styles.filter__item}>
        <CustomDatePicker
          type="text"
          fullWidth
          absoluteMessage
          placeholder="Дата от"
          value={filters?.date_of_begin}
          onChange={e => onChange("date_of_begin", e.target.value)}
        />
      </div>
      <div className={styles.filter__item}>
        <CustomDatePicker
          type="text"
          fullWidth
          absoluteMessage
          placeholder="Дата до"
          value={filters?.date_of_end}
          onChange={e => onChange("date_of_end", e.target.value)}
        />
      </div>
      {type === "cost" && contractorCostTypesFilter && (
        <div className={`${styles.filter__item} ${styles.filter__itemSelect}`}>
          <AutoSuggest
            list={contractorCostTypesFilter}
            showKey={"contractor_cost_type_name"}
            valueKey={"contractor_cost_type_id"}
            value={filters?.contractor_cost_type_id}
            onChange={e => onChange("contractor_cost_type_id", e.target.value)}
            placeholder="Выберите тип расхода"
            label="Выберите тип расхода"
            defaultValue={""}
          />
        </div>
      )}
      {type === "income" && contractorIncomeTypes && (
        <div className={`${styles.filter__item}`}>
          <AutoSuggest
            list={contractorIncomeTypes}
            showKey={"contractor_income_type_name"}
            valueKey={"contractor_income_type_id"}
            value={filters?.contractor_income_type_id}
            onChange={e =>
              onChange("contractor_income_type_id", e.target.value)
            }
            placeholder="Выберите тип дохода"
            label="Выберите тип дохода"
            defaultValue={""}
          />
        </div>
      )}
      {type === "income" && contractors && (
        <div className={`${styles.filter__item}`}>
          <AutoSuggest
            list={contractors}
            showKey={"contractor_name"}
            valueKey={"contractor_id"}
            value={filters?.contractor_id}
            onChange={e => onChange("contractor_id", e.target.value)}
            placeholder="Выберите компанию"
            label="Выберите компанию"
            defaultValue={""}
          />
        </div>
      )}
      {type === "cost" && teams && showTeamFilter && (
        <div className={`${styles.filter__item}`}>
          <AutoSuggest
            list={teams}
            showKey={"team_name"}
            valueKey={"team_id"}
            value={filters?.team_id}
            onChange={e => onChange("team_id", e.target.value)}
            placeholder="Выберите бригаду"
            label="Выберите бригаду"
            defaultValue={""}
          />
        </div>
      )}
      {(remonts || remontsState) && (
        <div className={`${styles.filter__item} ${styles.filter__itemSelect}`}>
          <AutoSuggest
            list={remontsState || remonts}
            showKey={"remont_information"}
            valueKey={"remont_id"}
            value={filters?.remont_id}
            onChange={e => onChange("remont_id", e.target.value)}
            placeholder="Выберите ремонт"
            label="Выберите ремонт"
          />
        </div>
      )}
      {type === "cost" && (masters || mastersState) && showMastersFilter && (
        <div className={`${styles.filter__item}`}>
          <AutoSuggest
            list={mastersState || masters}
            showKey={"team_master_fio"}
            valueKey={"team_master_id"}
            value={filters?.team_master_id}
            onChange={e => onChange("team_master_id", e.target.value)}
            placeholder="Выберите мастера"
            label="Выберите мастера"
            defaultValue={""}
          />
        </div>
      )}
      {type === "cost" && (
        <div className={`${styles.filter__item} ${styles.filter__item_sum}`}>
          <InputSumMask
            type="number"
            min={0}
            label="Сумма от"
            placeholder="Введите сумму от"
            value={filters.cost_sum_from}
            onChange={e => onChange("cost_sum_from", e.target.value, false)}
            onEnter={() => handleSumChange("cost_sum_from")}
            onBlur={() => handleSumChange("cost_sum_from")}
          />
          <span />
          <InputSumMask
            type="number"
            min={0}
            label="Сумма до"
            placeholder="Введите сумму до"
            value={filters.cost_sum_to}
            onChange={e => onChange("cost_sum_to", e.target.value, false)}
            onEnter={() => handleSumChange("cost_sum_to")}
            onBlur={() => handleSumChange("cost_sum_to")}
          />
        </div>
      )}
    </div>
  );
};
