import {
  deleteTeamMasterDocument,
  getAllMasters,
  getExternalMastersAPI,
  getMastersBySpecializationAPI,
  getServiceMastersAPI,
  getTeamMasterDocuments,
  getTeamMasterDocumentTypes,
  getTeamMasterSpecializationsAPI,
  getTeamsData as getTeams,
  uploadTeamMasterDocuments
} from "./api";
import { setMasterDocuments } from "../../../services/redux/actions/app-actions";

export const getDefaultDocObj = () => ({
  team_master_document_id: new Date().getTime(),
  team_master_document_url: "",
  team_master_document_extension: "",
  team_master_document_type_code: "",
  team_master_document_type_name: "",
  isNew: true
});
export const getMasterDocumentsData = async teamMasterId => {
  try {
    const res = await getTeamMasterDocuments(teamMasterId);
    return res?.team_master_documents || [];
  } catch (e) {
    return [];
  }
};

export const getMasterDocumentTypesData = () => async (dispatch, getState) => {
  try {
    const { appState } = getState();
    if (!!appState?.masterDocumentTypes?.length) return;
    const res = await getTeamMasterDocumentTypes();
    dispatch(setMasterDocuments(res?.team_master_document_types || []));
  } catch (e) {}
};

export const uploadMasterDocumentData = async (team_master_id, body) => {
  try {
    const res = await uploadTeamMasterDocuments(team_master_id, body);
    return res?.team_master_documents;
  } catch (e) {}
};
export const deleteMasterDocumentData = async (
  team_master_id,
  team_master_document_id
) => {
  try {
    const res = await deleteTeamMasterDocument(
      team_master_id,
      team_master_document_id
    );
    return res?.team_master_documents;
  } catch (e) {}
};
export const getTeamsList = async (params = {}) => {
  try {
    return await getTeams(params);
  } catch (e) {}
};
export const getMastersList = async (params = {}) => {
  try {
    return await getAllMasters(params);
  } catch (e) {}
};
export const getServiceMasters = async (params = {}) => {
  try {
    const res = await getServiceMastersAPI(params);
    return { masters: res?.service_masters, team_id: res?.team_id };
  } catch (e) {}
};
export const getExternalMasters = async (params = {}) => {
  try {
    const res = await getExternalMastersAPI(params);
    return { masters: res?.external_masters, team_id: res?.team_id };
  } catch (e) {}
};
export const getTeamMasterSpecializations = async params => {
  try {
    const res = await getTeamMasterSpecializationsAPI(params);
    return res?.specialities;
  } catch (e) {}
};
export const getMastersBySpecialization = async (body) => {
  try {
    const res = await getMastersBySpecializationAPI(body);
    return res?.team_masters;
  } catch (e) {}
};

const headItems = [
  { label: "ФИО", align: "left" },
  { label: "Бригада", align: "left", isTeamRow: true },
  { label: "Номер документа", align: "left" },
  { label: "Тип", align: "left" },
  { label: "IBAN", align: "left" },
  { label: "Номер телефона", align: "left" },
  { label: "Номер карты", align: "left" },
  { label: "Бригадир", align: "left" },
  { label: "Активный", align: "left" },
  { label: "", align: "left" }
];
export const getMastersColumns = allMastersMode => {
  try {
    if (!allMastersMode) {
      return headItems.filter(item => !item?.isTeamRow);
    }
    return headItems;
  } catch (e) {}
};

export const externalMastersColumns = [
  { label: "ФИО", align: "left" },
  { label: "Номер документа", align: "left" },
  { label: "Специализация", align: "left" },
  { label: "Номер телефона", align: "left" },
  { label: "Номер карты", align: "left", name: "credit_card" },
  { label: "Активный", align: "left" },
  { label: "Действия", align: "center" }
];
export const serviceMastersColumns = externalMastersColumns.filter(
  item => item.name !== "credit_card"
);
