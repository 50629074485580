import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Logo } from "../../common/Logo";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { BurgerIcon } from "../menu";
import { toggleMenuState } from "../../../services/redux/actions/app-actions";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { BackBtn } from "../../common/blocks";

export const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { pageTitle, headerBack } = useSelector(state => state.appState);

  const toggleMenu = () => {
    dispatch(toggleMenuState());
  };
  return (
    <header className={clsx(classes.header, { [classes.headerBack]: !!headerBack })}>
      <div className={classes.logoWrapper}>
        {!!headerBack && <BackBtn onClick={headerBack} />}
        <Link to={"/"}>
          <Logo size={"small"} alt="logo_path" />
        </Link>
      </div>
      {pageTitle && <span className={classes.headerTitle}>{pageTitle}</span>}
      <IconButton onClick={toggleMenu} className={classes.menuButton}>
        {theme.direction === "rtl" ? null : (
          <Box>
            <BurgerIcon />
          </Box>
        )}
      </IconButton>
    </header>
  );
};

export const useStyles = makeStyles((theme) => ({
  header: {
    position: "fixed",
    top: 0,
    zIndex: 10,
    display: "none",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    gap: "10px",
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.15)",
    backgroundColor: "#fff",
    padding: "8px 16px",
    height: 64,
    [theme.breakpoints.down(768)]: {
      display: "flex"
    },

    "& a": {
      display: "flex",
      alignItems: "center"
    }
  },
  headerBack: {
    paddingLeft: "10px"
  },
  menuButton: {
    padding: 5
  },
  headerTitle: {
    textAlign: "center",
    lineHeight: "15px"
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "10px"
  }
}));