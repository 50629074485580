import React, { useEffect, useState } from "react";
import UtilityBtn from "../UtilityBtn";
import { Box, InputLabel, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { addProviderInfo } from "../services/api";
import { useParams } from "react-router-dom";
import { useCustomSnackbar } from "../../../../utils/hooks/useCustomSnackbar";

export const useStyles = makeStyles(theme => ({
  input: {
    maxWidth: 544,
    marginRight: 10,

    width: "100%",
    "& .MuiInputBase-root": {
      height: 39,
      borderRadius: 4
    }
  },
  label: {
    marginBottom: 10
  },
  searchWrapper: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      gap: 10
    }
  },
  input_wrapper: {
    maxWidth: 450,
    width: "100%"
  }
}));

const AdditionalInfo = ({ delivery_contact }) => {
  const classes = useStyles();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [text, setText] = useState("");

  const handleChange = e => setText(e.target.value);
  const handleClick = async () => {
    try {
      await addProviderInfo(remont_id, { delivery_contact: text });
      addDefaultSnack("Успешно");
    } catch (err) {
      addCloseSnack(err.response.data.error);
    }
  };

  useEffect(() => {
    setText(delivery_contact ? delivery_contact : "");
  }, [delivery_contact]);

  return (
    <Box mb={2} className={classes.searchWrapper}>
      <InputLabel className={classes.label}>
        Дополнительная информация поставщику
      </InputLabel>
      <TextField
        id="outlined-basic"
        variant="outlined"
        className={classes.input}
        placeholder="Информация поставщику"
        value={text}
        onChange={handleChange}
      />
      <UtilityBtn
        width={172}
        height={38}
        text="Сохранить"
        handleClick={handleClick}
        disabled={!text}
      />
    </Box>
  );
};

export default AdditionalInfo;
