import React, { useCallback, useEffect, useState, useTransition } from "react";
import { reportCostOfPayment } from "./api";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import ReportFilter from "./reportFilter";
import NumberFormat from "react-number-format";
import { PageTitle } from "../../components/common/pageTitle";
import axios from "axios";
import { CustomVirtualTable } from "../../components/common/CustomTable/CustomVirtualTable";
import { numberWithCommas } from "../../utils";
import { showPopUp } from "../../services/redux/actions/app-actions";
import { MODALS_NAMES } from "../../constants";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex", minWidth: 250, flexDirection: "column", padding: "20px",
    [theme.breakpoints.down(768)]: {
      padding: "15px 10px"
    }
  },
  headText: {
    lineHeight: "16px"
  },
  cell: {
    fontSize: 12
  },
  totalSum: {
    color: "#494C62",
    fontSize: 18,
    fontWeight: 600
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: 12,
    gap: "20px"
  },
  greenCell: {
    backgroundColor: "#D5F9DD",
    cursor: "pointer"
  },
  headerTitle: { color: "#9194A9", fontSize: 12, fontWeight: 600 }
}));

export const ReportCostPayment = () => {
  document.title = "Список оплат по ремонтам";
  const classes = useStyles();
  const dispatch = useDispatch();

  const [costPaymentData, setCostPaymentData] = useState({});
  const [isPending, startTransition] = useTransition();
  const { data, total, total_planned_sum, flat_num } = costPaymentData;

  const [isFetching, setIsFetching] = useState(false);
  const [filters, setFilters] = useState({
    resident_ids: null,
    contractor_ids: null,
    home_builder_ids: null
  });

  const getData = useCallback(async (cancelToken) => {
    setIsFetching(true);
    reportCostOfPayment(filters, { cancelToken })
      .then(answer => {
        setIsFetching(false);
        startTransition(() => setCostPaymentData(answer.data));
      })
      .catch(() => {
        setIsFetching(false);
      });
  }, [filters, startTransition]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    getData(source.token);
    return () => {
      source.cancel();
    };
  }, [getData]);

  const showModal = (remont_id, stage_id) => {
    const data = { stage_id, remont_id, close: true };
    dispatch(showPopUp(true, MODALS_NAMES.paymentHist, data));
  };
  const cellsList = [
    {
      style: { fontSize: 12 }, key: "remont_id",
      render: item => `${item.remont_id}, ${item.resident_name} (${item.home_builder_name})`
    },
    { style: { fontSize: 12 }, key: "flat_num" },
    { style: { fontSize: 12 }, key: "area" },
    { style: { fontSize: 12 }, key: "contractor_name" },
    { style: { fontSize: 12 }, key: "cur_stage_name" },
    {
      style: { fontSize: 12 }, key: "cur_stage_date",
      align: "center"
    },
    { style: { fontSize: 12 }, key: "preset_name" },
    {
      style: { fontSize: 12 }, key: "preset_price",
      render: item => numberWithCommas(item.preset_price), align: "right"
    },
    {
      style: { fontSize: 12 }, key: "payment_amount",
      render: item => numberWithCommas(item.payment_amount), align: "right"
    },
    {
      style: { fontSize: 12, cursor: "pointer" }, key: "planned_sum",
      onClick: (item) =>
        dispatch(showPopUp(true, MODALS_NAMES.plannedSum, item.remont_id)),
      render: item => numberWithCommas(item.planned_sum), align: "right"
    },
    {
      style: { fontSize: 12 }, key: "pay_date",
      align: "center"
    },
    {
      style: { fontSize: 12 }, key: "contractor_draft",
      render: item => numberWithCommas(item.contractor_draft), align: "right"
    },
    {
      style: { fontSize: 12 }, key: "stage_1_sum",
      onClick: item => showModal(item.remont_id, 3),
      className: item => item.stage_1_sum > 0 ? classes.greenCell : "",
      render: item => numberWithCommas(item.stage_1_sum), align: "right"
    },
    {
      style: { fontSize: 12 }, key: "stage_2_sum",
      onClick: item => showModal(item.remont_id, 4),
      className: item => item.stage_2_sum > 0 ? classes.greenCell : "",
      render: item => numberWithCommas(item.stage_2_sum), align: "right"
    },
    {
      style: { fontSize: 12 }, key: "stage_3_sum",
      onClick: item => showModal(item.remont_id, 5),
      className: item => item.stage_3_sum > 0 ? classes.greenCell : "",
      render: item => numberWithCommas(item.stage_3_sum), align: "right"
    },
    {
      style: { fontSize: 12 }, key: "stage_4_sum",
      onClick: item => showModal(item.remont_id, 6),
      className: item => item.stage_4_sum > 0 ? classes.greenCell : "",
      render: item => numberWithCommas(item.stage_4_sum), align: "right"
    },
    {
      style: { fontSize: 12 }, key: "addition_sum",
      onClick: item => showModal(item.remont_id, 0),
      className: item => item.addition_sum > 0 ? classes.greenCell : "",
      render: item => numberWithCommas(item.addition_sum), align: "right"
    }
  ];
  return (
    <>
      <PageTitle title={"Список оплат по ремонтам"} />
      <div className={classes.content}>
        <Box className={classes.header}>
          <ReportFilter filters={filters} setFilters={setFilters} />
          <div style={{ display: "flex", flexWrap: "wrap", alignItems: "flex-end", gap: "0 10px" }}>
            <div>
              <Typography
                variant="h6"
                component="h2"
                className={classes.headerTitle}
              >
                Базовая сумма за кв.
              </Typography>
              <Typography className={classes.totalSum} color="textSecondary">
                <NumberFormat
                  value={total}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </Typography>
            </div>
            <div>
              <Typography
                variant="h6"
                component="h2"
                className={classes.headerTitle}
              >
                Общая сумма
              </Typography>
              <Typography className={classes.totalSum} color="textSecondary">
                <NumberFormat
                  value={total_planned_sum}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </Typography>
            </div>
            <div>
              <Typography
                variant="h6"
                component="h2"
                className={classes.headerTitle}
              >
                Кол-во квартир
              </Typography>
              <Typography className={classes.totalSum} color="textSecondary">
                {flat_num}
              </Typography>
            </div>
          </div>
        </Box>
        <CustomVirtualTable
          isFetching={isFetching || isPending}
          headItems={headItems}
          cellsList={cellsList}
          stickyHeader
          updateDataReq={getData}
          width={headItems.reduce((prev, currentValue) => prev + currentValue.width, 0)}
          gridTemplateColumns={headItems.map(item => (item.width ? `${item.width}px` : null) || "60px").join(" ")}
          data={data}
          rowHeight={60}
          isEmpty={!data?.length}
        />
      </div>
    </>
  );
};

const headItems = [
  { label: "ЖК", align: "left", width: 140 },
  { label: "№Кв", align: "left", width: 50 },
  { label: "Площадь", align: "left", width: 53 },
  { label: "Подрядчик", align: "left", width: 130 },
  { label: "Текущий этап", align: "left", width: 100 },
  {
    label: "Дата этапа",
    align: "center", width: 70
  },
  { label: "Пакет", align: "left", width: 80 },
  { label: "Цена пакета", align: "right", width: 50 },
  { label: "Оплаченная сумма", align: "right", width: 88 },
  { label: "Сумма по спецификации", align: "right", width: 88 },
  {
    label: "Последний платеж",
    align: "center", width: 88
  },
  {
    label: (
      <>
        ЧО от <br /> подрядчика
      </>
    ), align: "right", width: 88
  },
  { label: "1 этап", align: "right", width: 88 },
  { label: "2 этап", align: "right", width: 88 },
  { label: "3 этап", align: "right", width: 88 },
  { label: "4 этап", align: "right", width: 88 },
  { label: "Доп. платежи", align: "right", width: 88 }
];
