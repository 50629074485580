import React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const CollapseHeader = ({
  city_name,
  address,
  status_name,
  preset_name,
  remont_id,
  resident_name
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="top" width="100%" flexWrap="wrap">
      <Box style={{ width: 130 }}>
        <Typography
          className={classes.remont}
          style={{
            color: "#0C4B86"
          }}
        >
          ID: №{remont_id}
        </Typography>
        <Typography className={classes.title}>{preset_name}</Typography>
      </Box>
      <Box style={{ width: 130, marginRight: 55 }} className={classes.hide}>
        <Typography
          className={classes.remont}
          style={{
            color: "#494C62"
          }}
        >
          {resident_name}
        </Typography>
      </Box>
      <Box className={clsx(classes.mr, classes.hide)}>
        <Typography className={classes.title}> Город:</Typography>
        <Typography className={classes.desc}>{city_name}</Typography>
      </Box>
      <Box className={classes.mr}>
        <Typography className={classes.title}>Адрес</Typography>
        <Typography className={classes.desc}>{address}</Typography>
      </Box>
      <Box className={clsx(classes.mr, classes.hide)}>
        <Typography className={classes.title}>Статус:</Typography>
        <Typography className={classes.desc}>{status_name}</Typography>
      </Box>
    </Box>
  );
};

export default CollapseHeader;

export const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "15px",
    color: "#9194A9",
    marginRight: "10px"
  },
  remont: {
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "22px"
  },
  desc: {
    fontSize: 12
  },
  mr: {
    marginBottom: 10,
    marginRight: 10
  },
  hide: {
    [theme.breakpoints.down(600)]: {
      display: "none"
    }
  }
}));
