import React, { useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import RemontWork from "./remontWork";

const useStyles = makeStyles({
  row: {
    marginBottom: 10,
    verticalAlign: "top",
    borderRadius: 4
  },
  cell: {
    borderLeft: "1px solid #F0F2F6",
    verticalAlign: "top",
    fontSize: 12,
    padding: "5px !important"
  },
  cell_first: {
    verticalAlign: "top",
    fontWeight: 400,
    fontSize: 12,
    padding: "5px !important"
  }
});

const TableRowList = ({ data = {}, flatNum }) => {
  const classes = useStyles();

  const tableData = useMemo(() => {
    const row = [];
    Object.keys(data).forEach(function(key) {
      row.push(data[key]);
    });
    if (!flatNum || !row?.length) return row;
    return row?.filter(el => !!el?.flat_num?.includes(flatNum));
  }, [data, flatNum]);

  return tableData?.map((arr, idx) => (
    <TableRow className={classes.row} key={idx}>
      <TableCell align="left" className={classes.cell}>
        {arr.resident_name}
      </TableCell>
      <TableCell component="th" scope="row" className={classes.cell_first}>
        {arr.flat_num}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {arr.area}
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        {arr.preset_name}
      </TableCell>
      <RemontWork data={arr.materials_delivery} additionalData={arr} />
    </TableRow>
  ));
};

export default TableRowList;
