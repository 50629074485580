import React from "react";
import JkFilter from "./JkFilter";
import ResponsibleFilter from "./ResponsibleFilter";
import CompanyFilter from "./CompanyFilter";
import { makeStyles } from "@material-ui/core/styles";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

export const useReportFiltersStyles = makeStyles(() => ({
  formControl: {
    minWidth: 200
  },
  wrapper: {
    marginBottom: 10,
    display: "flex",
    alignItems: "flex-end",
    flexWrap: "wrap",
    gap: 15
  }
}));

const RemontReportFilters = ({ handleChange, filters }) => {
  const classes = useReportFiltersStyles();
  return (
    <CollapseBlock tabletMode className={classes.wrapper}>
      <JkFilter handleChange={handleChange} value={filters.resident_id} />
      <ResponsibleFilter />
      <CompanyFilter />
    </CollapseBlock>
  );
};

export default RemontReportFilters;
