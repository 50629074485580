import React, { createRef, useCallback, useEffect, useMemo, useState } from "react";
import SideBarHeader from "./SideBarHeader";
import { Box } from "@material-ui/core";
import SideBarSearch from "./SideBarSearch";
import { getArchiveCards } from "./api";
import ArchiveCard from "./ArchiveCard";
import { makeStyles } from "@material-ui/core/styles";
import { WrapperScrollbar } from "../../components/common/WrapperScrollbar";
import { CustomLoader } from "../../components/common/CustomLoader";
import { useVirtual } from "react-virtual";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  archive_body: {
    marginTop: "16px",
    overflow: "auto",
    height: "81vh"
  },
  row_inner: {
    width: "100%",
    position: "relative"
  }
});

const ArchiveSideBar = ({ handleClick }) => {
  const classes = useStyles();
  const parentRef = createRef();
  const { addCloseSnack } = useCustomSnackbar();
  const [archiveCards, setArchiveCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = e => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setLoading(true);
    getArchiveCards()
      .then(res => {
        setArchiveCards(res?.data);
        setLoading(false);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
        setLoading(false);
      });
  }, [addCloseSnack]);

  const data = useMemo(() => {
    const search = searchTerm.toLowerCase();
    if (!search) return archiveCards;
    return archiveCards?.filter(el => {
      return !!(
        el.card_id?.toString().includes(search) ||
        el.date_create?.includes(search) ||
        el.resident_name?.toLowerCase().includes(search) ||
        el.flat_num?.includes(search) ||
        el.executors?.toLowerCase().includes(search) ||
        el.card_type_name?.toLowerCase().includes(search)
      );
    });
  }, [archiveCards, searchTerm]);

  const rowVirtualizer = useVirtual({
    size: data.length,
    parentRef,
    estimateSize: useCallback(() => 260, [])
  });

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <Box>
      <SideBarHeader title="Архив" handleClick={handleClick} />
      <Box style={{ padding: 16 }}>
        <SideBarSearch
          placeholder="Поиск в архиве..."
          handleChange={handleChange}
        />
        <WrapperScrollbar className={classes.archive_body} ref={parentRef}>
          <Box
            className={classes.row_inner}
            style={{
              height: `${rowVirtualizer.totalSize}px`
            }}
          >
            {rowVirtualizer.virtualItems.map(item => {
              const el = data[item.index];
              return (
                <ArchiveCard
                  key={el.card_id}
                  {...el}
                  item={item}
                  ref={item.measureRef}
                />
              );
            })}
          </Box>
        </WrapperScrollbar>
      </Box>
    </Box>
  );
};

export default ArchiveSideBar;
