import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: "5px !important"
  }
}));
const ResponsibleFilter = () => {
  const classes = useStyles();
  const [value] = useState();
  const handleChange = () => {};

  return (
    <FormControl className={classes.formControl}>
      <label
        style={{
          fontWeight: "600",
          fontSize: "12px",
          lineHeight: "15px",
          color: "#9194A9"
        }}
      >
        Ответственный
      </label>
      <NativeSelect
        value={value}
        onChange={handleChange}
        className={classes.selectEmpty}
        inputProps={{ "aria-label": "age" }}
      >
        <option value="">Все</option>
        <option value={20}>Назначен</option>
        <option value={30}>Не назначан</option>
      </NativeSelect>
    </FormControl>
  );
};

export default ResponsibleFilter;
