import React, { useEffect, useState } from "react";
import { matchSorter } from "match-sorter";
import Box from "@material-ui/core/Box";
import { CustomSearch } from "../../components/common/form/CustomSearch";
import { fetchResidentList } from "../Problems/api";
import { format } from "date-fns";
import { getExpectedPayments } from "./api";
import { Link } from "react-router-dom";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../components/common/CustomTable/CustomTable";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { CustomDatePicker } from "../../components/common/form/CustomDatePicker";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { PAGES_ROUTE_KEY } from "../../constants";
import { PageTitle } from "../../components/common/pageTitle";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";
import { makeStyles } from "@material-ui/core/styles";

const headerTitles = [
  { label: "ID ремонта", align: "left" },
  { label: "Дата платежа", align: "left" },
  { label: "ЖК", align: "left" },
  { label: "КВ", align: "left" },
  { label: "Квадратура", align: "left" },
  { label: "Пакет ремонта", align: "left" },
  { label: "Стоимость за кв.м", align: "left" },
  { label: "Сумма", align: "left" },
  { label: "Подрядчик", align: "left" },
  { label: "Договор", align: "left" },
  { label: "Комментарии", align: "left" }
];
const useStyles = makeStyles(() => ({
  filters: { display: 'flex', flexWrap: 'wrap', gap: '10px', marginBottom: '12px', alignItems: 'flex-end' },
}))
// display="flex" flexWrap={'wrap'} gridRowGap={'13px'} alignItems="flex-end" mb={2}
const ExpectedPayments = () => {
  const { addCloseSnack } = useCustomSnackbar();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [filterState, setFilterState] = useState({
    resident_id: null,
    date_begin: format(new Date(), "dd.MM.yyyy"),
    date_end: format(new Date(), "dd.MM.yyyy")
  });
  const [residents, setResidents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [expectedPayments, setExpectedPayments] = useState([]);

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };
  const filteredList = expectedPayments.length
    ? matchSorter(expectedPayments, searchTerm, {
        keys: [
          "total_sum",
          "flat_num",
          "preset_price",
          "prop_doc_date_num",
          "stage_percent"
        ]
      })
    : [];

  const handleChange = e => {
    setFilterState(prev => ({
      ...prev,
      [e.target.name]: e.target.value || ""
    }));
  };

  const { resident_id, date_begin, date_end } = filterState;
  useEffect(() => {
    fetchResidentList().then(res => {
      setResidents(res.data);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getExpectedPayments(resident_id ? resident_id : 0, date_begin, date_end)
      .then(res => {
        setLoading(false);
        setExpectedPayments(res.data);
      })
      .catch(err => {
        setLoading(false);
        addCloseSnack(err.response.data.error);
      });
  }, [date_begin, date_end, resident_id, addCloseSnack]);

  return (
    <div>
      <PageTitle title={'Отчет по ожидаемым оплатам'} />
      <div style={{ padding: 20 }}>
        <CollapseBlock tabletMode className={classes.filters}>
          <Box width={300}>
            <CustomSearch
              value={searchTerm}
              handleChange={handleSearchChange}
            />
          </Box>
          <Box width={200}>
            <AutoSuggest
              list={residents}
              value={resident_id}
              showKey={"resident_name"}
              valueKey={"resident_id"}
              name={"resident_id"}
              placeholder="Выберите ЖК"
              label="Жилой комплекс"
              onChange={handleChange}
            />
          </Box>
          <Box width={145}>
            <CustomDatePicker
              fullWidth
              label={"Дата начала"}
              placeholder={"Выберите дату"}
              name={"date_begin"}
              value={date_begin}
              onChange={handleChange}
            />
          </Box>
          <Box width={145}>
            <CustomDatePicker
              fullWidth
              label={"Дата конца"}
              placeholder={"Выберите дату"}
              name={"date_end"}
              value={date_end}
              onChange={handleChange}
            />
          </Box>
        </CollapseBlock>
        <CustomTable
          isFetching={loading}
          headItems={headerTitles}
          isEmpty={!filteredList?.length}
        >
          {filteredList?.map((el, i) => (
            <CustomTableRow key={i}>
              <CustomTableCell>
                <Link to={`${PAGES_ROUTE_KEY.singleRemont}/${el.remont_id}`}>
                  {el.remont_id}
                </Link>
              </CustomTableCell>
              <CustomTableCell>{el.payment_date}</CustomTableCell>
              <CustomTableCell>{el.resident_name}</CustomTableCell>
              <CustomTableCell>{el.flat_num}</CustomTableCell>
              <CustomTableCell>{el.area}</CustomTableCell>
              <CustomTableCell>{el.preset_name}</CustomTableCell>
              <CustomTableCell>
                Процент за этап: {el.stage_percent}
                <br />
                Цена за кв.м: {el.preset_price}
              </CustomTableCell>
              <CustomTableCell>{el.total_sum}</CustomTableCell>
              <CustomTableCell>{el.contractor_name}</CustomTableCell>
              <CustomTableCell>{el.prop_doc_date_num}</CustomTableCell>
              <CustomTableCell>{el.comments}</CustomTableCell>
            </CustomTableRow>
          ))}
        </CustomTable>
      </div>
    </div>
  );
};

export default ExpectedPayments;
