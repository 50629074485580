//Получить список меню
import instance from "../../services/api";

export const getMenuList = () => {
  return instance(true)
    .get("/partner/read_menu/")
    .then(res => res?.data);
};

export const getCities = () => {
  return instance(true)
    .get("/partner/cities/")
    .then((res = {}) => res?.data);
};

export const changeCities = params => {
  return instance(true)
    .post("/partner/cities/", params)
    .then((res = {}) => {
      return res;
    });
};

export const getCompanies = () => {
  return instance(true)
    .get("/partner/companies/read/")
    .then((res = {}) => res?.data);
};

export const changeCompany = params => {
  return instance(true)
    .post("/partner/companies/change/", params)
    .then((res = {}) => {
      return res;
    });
};
