import { keyReceiveAPI } from "./api";

export const getKeyReceiveData = async (remontId) => {
  try {
    const res = await keyReceiveAPI.getList(remontId)
    return res?.data
  } catch (e) {}
};
export const getRemontKeyCounts = async (remontId) => {
  try {
    const res = await keyReceiveAPI.getKeyCounts(remontId)
    return res?.data
  } catch (e) {}
};
export const keyReceiveTableColumns = [
  { align: "center", label: "ID" },
  { align: "left", label: "Дата передачи" },
  { align: "left", label: "Кто передал" },
  { align: "left", label: "Кто принял" },
  { align: "left", label: "Комментарий" },
  { align: "left", label: "Кем добавлен" },
];