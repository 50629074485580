import React, { useCallback, useEffect, useState } from "react";
import { getMasterPaymentsSignedDocs } from "./services";
import { CustomTable, CustomTableCell, CustomTableRow } from "../../components/common/CustomTable/CustomTable";
import { numberWithCommas } from "../../utils";
import { PageTitle } from "../../components/common/pageTitle";
import styles from "./styles.module.scss";
import { Collapse } from "@material-ui/core";
import { LoaderData } from "../../components/common/LoaderData";
import { NotFound } from "../../components/common/text";
import { useDownloadFile } from "../remonts/RemontInfo/Gpr/useDownloadFile";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import { MasterPaymentsFilters } from "./filters";
import { Amount } from "../../components/common/blocks/Amount";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

export const MastersPaymentsSignedDocs = () => {
  const { downloadFile } = useDownloadFile();
  const [isFetching, setIsFetching] = useState(false);
  const [signedDocs, setSignedDocs] = useState([]);
  const [collapse, setCollapse] = useState({});
  const [downloadingIds, setDownloadingIds] = useState({});
  const [filtersHeight, setFiltersHeight] = useState(200);

  const getData = useCallback(async (filters = {}) => {
    setIsFetching(true);
    const res = await getMasterPaymentsSignedDocs(filters);
    setIsFetching(false);
    setSignedDocs(res || []);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleCollapse = id => {
    setCollapse(prev => ({ ...prev, [id]: !prev[id] }));
  };
  const handleDownload = async sign_process_id => {
    if (downloadingIds[sign_process_id]) return;
    setDownloadingIds(prevState => ({ ...prevState, [sign_process_id]: true }));
    await downloadFile(
      "/partner/contractor_costs/master_payment/signed_document/",
      "Подписанный документ",
      { sign_process_id },
      "pdf"
    );
    setDownloadingIds(prevState => ({
      ...prevState,
      [sign_process_id]: false
    }));
  };
  return (
    <div>
      <PageTitle title={"Подписанные документы"} />
      <div className={styles.content}>
        <CollapseBlock className={styles.header}>
          <MasterPaymentsFilters setFiltersHeight={setFiltersHeight} getData={getData} statusFilters />
        </CollapseBlock>
        <CustomTable
          headItems={headItems}
          isFetching={isFetching}
          isEmpty={!signedDocs?.length}
          withMaxHeight stickyHeader maxHeight={window.innerHeight - filtersHeight - 64 - 45}
        >
          {signedDocs?.map(item => {
            const isAmount = item.uuid_date === "Итого";
            return (
              <React.Fragment key={item.sign_process_id}>
                <CustomTableRow fixed={isAmount} isBinaryDiv={false} className={"pointer"}
                                onClick={() => handleCollapse(item.sign_process_id)}>
                  <CustomTableCell>
                    <strong className={"pointer"}>{item.uuid_date}</strong>
                  </CustomTableCell>
                  <CustomTableCell>{item.contractor_name}</CustomTableCell>
                  <CustomTableCell align={"right"}>
                    <strong><Amount value={numberWithCommas(item.sum)} /></strong>
                  </CustomTableCell>
                  <CustomTableCell align={"right"}>
                    {item.count}
                  </CustomTableCell>
                  {isAmount
                    ? <CustomTableCell />
                    : <CustomTableCell align={"center"}>
                      {downloadingIds[item.sign_process_id] ? (
                        <LoaderData small />
                      ) : (
                        <DownloadIcon
                          className={"clickable pointer"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDownload(item.sign_process_id);
                          }}
                        />
                      )}
                    </CustomTableCell>}
                </CustomTableRow>
                {!isAmount &&
                  <CustomTableRow isBinaryDiv={false}>
                    <CustomTableCell
                      openTab={!!collapse[item.sign_process_id]}
                      colSpan={15}
                    >
                      <Collapse
                        in={collapse[item.sign_process_id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {isFetching ? (
                          <LoaderData small />
                        ) : !!item.contractor_costs?.length ? (
                          <div className={styles.collapse__content}>
                            <CustomTable headItems={childrenDeadItems}>
                              {item.contractor_costs?.map(cost => {
                                return (
                                  <CustomTableRow key={cost?.contractor_cost_id}>
                                    <CustomTableCell>
                                      {cost.team_master_fio}
                                    </CustomTableCell>
                                    <CustomTableCell align={"center"}>
                                      {cost.cost_date}
                                    </CustomTableCell>
                                    <CustomTableCell align={"right"}>
                                      {cost?.remont_id}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                      {cost.address}
                                    </CustomTableCell>
                                    <CustomTableCell>
                                      {cost.contractor_cost_status_name}
                                    </CustomTableCell>
                                    <CustomTableCell align={"left"}>
                                      {cost?.comment}
                                    </CustomTableCell>
                                    <CustomTableCell align={"right"}>
                                      <strong><Amount value={numberWithCommas(cost?.cost_sum)} /></strong>
                                    </CustomTableCell>
                                    <CustomTableCell align={"right"}>
                                      <strong><Amount value={numberWithCommas(cost?.tax_sum)} /></strong>
                                    </CustomTableCell>
                                    <CustomTableCell align={"right"}>
                                      <strong><Amount value={numberWithCommas(cost?.cost_sum_with_tax)} /></strong>
                                    </CustomTableCell>
                                  </CustomTableRow>
                                );
                              })}
                            </CustomTable>
                          </div>
                        ) : (
                          <NotFound />
                        )}
                      </Collapse>
                    </CustomTableCell>
                  </CustomTableRow>}
              </React.Fragment>
            );
          })}
        </CustomTable>
      </div>
    </div>
  );
};

const headItems = [
  { label: "Дата подписания", align: "left" },
  { label: "Подрядчик", align: "left" },
  { label: "Сумма", align: "right" },
  { label: "Кол-во", align: "right" },
  { label: "", align: "center" }
];
const childrenDeadItems = [
  { label: "ФИО" },
  { label: "Дата создания", align: "center" },
  { label: "ID ремонта", align: "right" },
  { label: "Адрес" },
  { label: "Статус" },
  { label: "Комментарии" },
  { label: "Сумма", align: "right" },
  { label: "Комиссия", align: "right" },
  { label: "Сумма с комиссией", align: "right" }
];
