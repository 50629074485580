import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PageTitle } from "../../../common/pageTitle";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../common/CustomTable/CustomTable";
import { makeStyles } from "@material-ui/core/styles";
import { CustomSearch } from "../../../common/form/CustomSearch";
import { numberWithCommas } from "../../../../utils";
import { UpdateTableHeader } from "../../../common/blocks";
import { getIntegratedPaymentBalance } from "./services";
import { LoaderData } from "../../../common/LoaderData";
import { useDispatch, useSelector } from "react-redux";
import { getIntegratedPaymentTypesData } from "../../../../services/redux/actions/app-actions";

export const Contractors = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const { integratedPaymentTypes } = useSelector(state => state.appState);

  useEffect(() => {
    dispatch(getIntegratedPaymentTypesData(setIsFetching));
  }, [dispatch]);

  const title = `Список ИП (${integratedPaymentTypes?.length || 0})`;
  if (isFetching) return <LoaderData />;
  return (
    <>
      <PageTitle title={title} />
      <div className={classes.wrapper}>
        {integratedPaymentTypes?.map(item => {
          return (
            <ContractorItem
              key={item.integrated_payment_type_id}
              code={item.integrated_payment_type_code}
              title={item.integrated_payment_type_name}
            />
          );
        })}
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: 15,
    width: 700,

    [theme.breakpoints.down(768)]: {
      width: '100%',
    }
  },
  root: {
    maxHeight: "auto",
    marginTop: "15px"
  }
}));

const headItems = [{ label: "№" }, { label: "Название" }, { label: "Баланс" }];

const ContractorItem = ({ code, title }) => {
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const [filters, setFilters] = useState({ search: "" });
  const [data, setData] = useState([]);

  const getContractorsData = useCallback(async () => {
    setIsFetching(true);
    const res = await getIntegratedPaymentBalance(code);
    setIsFetching(false);
    if (res) setData(res);
  }, [code]);

  useEffect(() => {
    getContractorsData();
  }, [getContractorsData]);

  const onSearchChange = e => {
    setFilters(prev => ({ ...prev, search: e.target?.value }));
  };

  const renderTableHeader = () => (
    <UpdateTableHeader onClick={getContractorsData} isFetching={isFetching} />
  );

  const computedData = useMemo(() => {
    if (!filters?.search || !data?.length) return data;
    const search = filters.search?.toString().toLowerCase();
    return data?.filter(
      item =>
        item?.contractor_name?.toLowerCase()?.includes(search) ||
        item?.balance
          ?.toString()
          .toLowerCase()
          .includes(search)
    );
  }, [data, filters.search]);
  return (
    <div>
      <h2>{title}</h2>
      <CustomSearch handleChange={onSearchChange} value={filters.search} />
      <CustomTable
        headItems={headItems}
        updateTable
        isEmpty={!data?.length}
        isFetching={isFetching}
        headerRender={renderTableHeader}
        containerClassName={classes.root}
        stickyHeader
      >
        {computedData?.map((item, i) => {
          return (
            <CustomTableRow
              key={item?.contractor_id}
              fixed={item?.fixed}
              fixedDirection="top"
            >
              <CustomTableCell align="center" fixed={item?.fixed}>
                {i + 1}
              </CustomTableCell>
              <CustomTableCell align="left" fixed={item?.fixed}>
                {item?.contractor_name || ""}
              </CustomTableCell>
              <CustomTableCell align="right">
                {numberWithCommas(item?.balance) || ""}
              </CustomTableCell>
            </CustomTableRow>
          );
        })}
      </CustomTable>
    </div>
  );
};
