import React, { useEffect, useState } from "react";
import SideBarHeader from "./SideBarHeader";
import { Box } from "@material-ui/core";
import SideBarSearch from "./SideBarSearch";
import { getChanges, searchChanges } from "./api";
import RecentChanges from "./RecentChanges";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { WrapperScrollbar } from "../../components/common/WrapperScrollbar";
import { CustomLoader } from "../../components/common/CustomLoader";

const useStyles = makeStyles({
  history_body: {
    overflow: "auto",
    height: "81vh",
    marginTop: "16px"
  }
});

const HistorySideBar = ({ handleClick }) => {
  const classes = useStyles();
  const [changes, setChanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const { card_id } = useSelector(state => state.problemsState);

  const handleSearchClick = searchTerm => {
    setLoading(true);
    searchChanges({ input: searchTerm })
      .then(res => {
        setChanges(res.data);
        setLoading(false);
      })
      .catch(() => {});
  };
  useEffect(() => {
    setLoading(true);
    if (card_id) {
      getChanges(card_id)
        .then(res => {
          setChanges(res?.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      searchChanges({ input: "" })
        .then(res => {
          setChanges(res.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [card_id]);

  if (loading) {
    return <CustomLoader />;
  }

  return (
    <Box>
      <SideBarHeader
        title={card_id ? `История по карте №${card_id}` : "История"}
        handleClick={handleClick}
      />
      <Box style={{ padding: 16 }}>
        {!card_id && (
          <SideBarSearch
            placeholder="Поиск по истории..."
            handleClick={handleSearchClick}
            isHistory
          />
        )}
        <WrapperScrollbar className={classes.history_body}>
          {changes.map(el => {
            return <RecentChanges key={el.action_id} {...el} />;
          })}
        </WrapperScrollbar>
      </Box>
    </Box>
  );
};

export default HistorySideBar;
