import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { PageTitle } from "../../components/common/pageTitle";
import EmptyOrShow from "../../components/common/EmptyOrShow";
import { getReportMasterPaymentsData } from "./api";
import { CustomTable } from "../../components/common/CustomTable/CustomTable";
import { numberWithCommas } from "../../utils";


export const ReportMasterPayments = () => {
  document.title = "Отчет по оплатам мастерам";
  const [isFetching, setIsFetching] = useState(true);
  const [payments, setPayments] = useState([]);

  const getData = useCallback(async () => {
    setIsFetching(true);
    const res = await getReportMasterPaymentsData();
    setIsFetching(false);
    if (!res) return;
    setPayments(res);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <PageTitle title="Отчет по оплатам мастерам" />
      <Box sx={{ padding: 20 }}>
        <EmptyOrShow loading={isFetching}>
          <CustomTable
            updateDataReq={getData}
            headItems={headItems}
            isEmpty={!payments?.length}
            isFetching={isFetching}
            stickyHeader
            rowsData={payments}
          />
        </EmptyOrShow>
      </Box>
    </>
  );
};
const headItems = [
  { label: "ID ремонта", align: "center", key: "remont_id" },
  { label: "Бюджет", align: "right", key: "amount", format: value => numberWithCommas(value) },
  { label: "Оплачено", align: "right", key: "payed_amount", format: value => numberWithCommas(value) },
  { label: "Разница", align: "right", key: "diff", format: value => numberWithCommas(value) }
];

