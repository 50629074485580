import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CustomTableCell } from "../../components/common/CustomTable/CustomTable";

const useStyles = makeStyles({
  cell: {
    borderLeft: "1px solid #F0F2F6",
    verticalAlign: "top",
    fontSize: 12
  }
});

function isDone(status) {
  if (status === "DONE") {
    return <span>Завершен</span>;
  } else {
    return <span></span>;
  }
}
function getColor(status, color) {
  if (status === "NULL") {
    return "#fff";
  } else {
    return color;
  }
}

export default function RemontWork({ data = {} }) {
  const classes = useStyles();
  const row = [];

  Object.keys(data).forEach(function(key) {
    row.push(data[key]);
  });
  return row.map(item => {
    return (
      <CustomTableCell
        align="left"
        className={classes.cell}
        style={{ backgroundColor: getColor(item.status, item.work_color) }}
      >
        {isDone(item.status)}
        {item.work_name}
      </CustomTableCell>
    );
  });
}
