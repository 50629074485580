import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { CButton } from "../../../../common/buttons";
import { getBikOptions } from "../../../../../services/redux/actions/app-actions";
import { InputSumMask } from "../../../../common/form/InputSumMask";
import { InputWarning } from "../../../../common/form/warnings";
import CustomInput from "../../../../common/form/CustomInput";
import { AutoSuggest } from "../../../../common/form/AutoSuggest";
import { CustomDatePicker } from "../../../../common/form/CustomDatePicker";
import clsx from "clsx";
import styles from "./add_debt.module.scss";

export const AddTeamDebt = ({ data }) => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const dispatch = useDispatch();
  const { modalLoading, teams } = useSelector(state => state.appState);
  const { debtTypes } = useSelector(state => state.accountingState);

  const { teamMode, isEdit } = data;

  useEffect(() => {
    dispatch(getBikOptions());
  }, [dispatch]);

  const onSubmit = async body => {
    const { onSubmit: submit } = data;
    return submit && (await submit(body));
  };

  return (
    <div>
      <div className={styles.add_team__header}>
        <h2 className={styles.add_team__title}>{` ${
          isEdit ? "Редактирование" : "Добавление"
        } долга`}</h2>
      </div>

      <form className={styles.add_team__form} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.add_team__form_item}>
          <Controller
            name="debt_sum"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputSumMask
                type="number"
                fullWidth
                min={0}
                label="Сумма"
                placeholder="Введите сумму"
                wrapperClassname={styles.accounting__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.debt_sum || ""}
            rules={{
              required: true
            }}
          />
          {errors.debt_sum && <InputWarning>Это поле обязательно</InputWarning>}
        </div>
        {!!debtTypes && (
          <div className={styles.add_team__form_item}>
            <Controller
              name="debt_type_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutoSuggest
                  list={debtTypes}
                  showKey={"debt_type_name"}
                  valueKey={"debt_type_id"}
                  value={value}
                  onChange={onChange}
                  placeholder="Выберите тип долга"
                  label="Выберите тип долга"
                  defaultValue={""}
                />
              )}
              defaultValue={data?.body?.debt_type_id || ""}
              rules={{
                required: true
              }}
            />
            {errors.debt_type_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}
        <div className={styles.add_team__form_item}>
          <Controller
            name="percent_type"
            control={control}
            render={({ field: { onChange, value } }) => (
              <AutoSuggest
                list={percentTypes}
                showKey={"label"}
                withMargin
                valueKey={"id"}
                value={value}
                placeholder="Выберите тип списания"
                label="Выберите тип списания"
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.percent_type || ""}
            rules={{
              required: true
            }}
          />
          {errors.percent_type && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        {!teamMode && (
          <div className={styles.add_team__form_item}>
            <Controller
              name="team_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AutoSuggest
                  list={teams}
                  showKey={"team_name"}
                  valueKey={"team_id"}
                  value={value}
                  onChange={onChange}
                  placeholder="Выберите бригаду"
                  label="Выберите бригаду"
                  defaultValue={""}
                />
              )}
              defaultValue={data?.body?.team_id || ""}
              rules={{
                required: true
              }}
            />
            {errors.team_id && (
              <InputWarning>Это поле обязательно</InputWarning>
            )}
          </div>
        )}
        <div className={styles.add_team__form_item}>
          <Controller
            name="percent"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputSumMask
                type="number"
                fullWidth
                min={0}
                max={100}
                label="Процент"
                placeholder="Введите процент"
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.percent || ""}
            rules={{
              required: true
            }}
          />
          {errors.percent && <InputWarning>Это поле обязательно</InputWarning>}
        </div>
        <div
          className={clsx(
            styles.add_team__form_item,
            styles.add_team__form_itemMargin
          )}
        >
          <Controller
            name="date_start"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                type="text"
                fullWidth
                label="Дата начала списаний"
                placeholder="Введите дату начала списаний"
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.date_start || ""}
            rules={{
              required: true
            }}
          />
          {errors.date_start && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        <div className={styles.add_team__form_item}>
          <Controller
            name="comments"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomInput
                type="text"
                fullWidth
                label="Комментарии"
                placeholder="Введите комментарии"
                wrapperClassname={styles.add_team__input_wrapper}
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={data?.body?.comments || ""}
          />
        </div>
        <div className={styles.add_team__btn_wrapper}>
          <CButton loading={modalLoading} disabled={modalLoading} type="submit">
            Сохранить
          </CButton>
        </div>
      </form>
    </div>
  );
};

const percentTypes = [
  { id: 1, label: "От суммы платежа" },
  { id: 2, label: "От суммы долга" }
];
