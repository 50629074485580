import React from "react";
import { Box, List, Paper } from "@material-ui/core";
import { WorksetItem } from "./WorksetItem";
import { useWorksetsStyles } from "./index";

export const ResidentWorksets = ({
  data,
  setData,
  disabledCheckbox = false,
  work_set_check_group_id
}) => {
  const classes = useWorksetsStyles();

  return (
    <div className={classes.wrapper}>
      <List
        className={classes.list}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <Box>
          <Paper elevation={0} square>
            <Box style={{ minHeight: "inherit" }}>
              {data?.map(item => {
                return (
                  <WorksetItem
                    key={item.work_set_id}
                    el={item}
                    disabledCheckbox={disabledCheckbox}
                    work_set_check_group_id={work_set_check_group_id}
                    setData={setData}
                  />
                );
              })}
            </Box>{" "}
          </Paper>
        </Box>
      </List>
    </div>
  );
};
