import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {
  CustomTableCell,
  CustomTableRow
} from "../../../components/common/CustomTable/CustomTable";

export const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
    padding: 0
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 18,
    color: "#494C62",
    fontWeight: 600,
    margin: "6px 0"
  },
  col: {
    width: "50px",
    border: "1px solid rgba(224, 224, 224, 1)",
    padding: "11px !important",
    [theme.breakpoints.down("md")]: {
      padding: "0 !important"
    }
  },
  col_main: {
    width: 50
  },
  row: {
    verticalAlign: "middle"
  },
  textData: {
    color: "#000000",
    fontSize: 12,
    fontWeight: 400,
    textAlign: "center"
  },
  pos: {
    fontSize: 12,
    fontWeight: 600
  },
  id: {
    fontSize: 18,
    fontWeight: 600,
    color: "#494C62"
  },
  col_name: {
    width: "20%",
    textAlign: "left",
    border: "1px solid rgba(224, 224, 224, 1)"
  },
  aprove_btn: {
    backgroundColor: "#0C4B86"
  },
  fix_pad: {
    padding: 11
  }
}));
const EstimateTableItems = ({ items }) => {
  const classes = useStyles();

  return (
    <>
      {items?.map(el => {
        return (
          <CustomTableRow className={classes.row} key={el?.material_id}>
            <CustomTableCell
              className={classes.col_name}
              classes={{
                root: classes.fix_pad
              }}
            >
              <Typography
                variant="h6"
                className={classes.textData}
                style={{ textAlign: "left" }}
                title={el.material_id}
              >
                {el.material_name}
              </Typography>
            </CustomTableCell>
            <CustomTableCell
              className={classes.col}
              classes={{
                root: classes.fix_pad
              }}
            >
              <Typography variant="h6" className={classes.textData}>
                {el.smeta_qty}
              </Typography>
            </CustomTableCell>
            <CustomTableCell
              className={classes.col}
              classes={{
                root: classes.fix_pad
              }}
            >
              <Typography variant="h6" className={classes.textData}>
                {el.not_enough_qty}
              </Typography>
            </CustomTableCell>
            <CustomTableCell className={classes.col}>
              <Typography variant="h6" className={classes.textData}>
                {el.not_right_qty}
              </Typography>
            </CustomTableCell>
            <CustomTableCell className={classes.col}>
              <Typography variant="h6" className={classes.textData}>
                {el.additional_agreement_qty}
              </Typography>
            </CustomTableCell>
            <CustomTableCell className={classes.col}>
              <Typography variant="h6" className={classes.textData}>
                {el.rough_qty}
              </Typography>
            </CustomTableCell>{" "}
            <CustomTableCell className={classes.col}>
              <Typography variant="h6" className={classes.textData}>
                {el.error_contractor_qty}
              </Typography>
            </CustomTableCell>{" "}
            <CustomTableCell className={classes.col}>
              <Typography variant="h6" className={classes.textData}>
                {el.avail_qty}
              </Typography>
            </CustomTableCell>{" "}
            <CustomTableCell className={classes.col}>
              <Typography variant="h6" className={classes.textData}>
                {el.bring_to_contractor_qty}
              </Typography>
            </CustomTableCell>{" "}
            <CustomTableCell className={classes.col}>
              <Typography variant="h6" className={classes.textData}>
                {el.create_qty}
              </Typography>
            </CustomTableCell>{" "}
            <CustomTableCell className={classes.col}>
              <Typography variant="h6" className={classes.textData}>
                {el.not_qty}
              </Typography>
            </CustomTableCell>{" "}
            <CustomTableCell className={classes.col}>
              <Typography variant="h6" className={classes.textData}>
                {el.release_qty}
              </Typography>
            </CustomTableCell>{" "}
            <CustomTableCell className={classes.col}>
              <Typography variant="h6" className={classes.textData}>
                {el.ship_qty}
              </Typography>
            </CustomTableCell>
          </CustomTableRow>
        );
      })}
    </>
  );
};

export default EstimateTableItems;
