import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  InputLabel,
  NativeSelect,
  TextField,
  Typography
} from "@material-ui/core";
import {
  addNewCard,
  fetchCardTypes,
  fetchPriorities,
  getProblemName
} from "../../Problems/api";
import { useDispatch, useSelector } from "react-redux";
import { setColumns } from "../../../services/redux/actions/problemsActions";
import { useParams } from "react-router-dom";
import { CustomLoader } from "../../../components/common/CustomLoader";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles(theme => ({
  register_box: {
    maxWidth: 905,
    width: "100%",
    border: "1px solid #D2D3D9",
    borderRadius: 4,
    padding: "20px 25px"
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: "15px",
    color: "#494C62",
    textTransform: "uppercase",
    letterSpacing: "0.08em",
    marginBottom: 25
  },
  group_wrapper: {
    maxWidth: "65%",
    width: "100%",
    marginBottom: 25,
    [theme.breakpoints.down("1445")]: {
      maxWidth: "100%"
    }
  },
  form_group: {
    width: "100%"
  },
  text_field: {
    width: "100%"
  },
  input: {
    "& label": {
      fontSize: 17,
      fontWeight: 600,
      color: "#9194A9"
    },
    "& .MuiInputBase-root": {
      marginTop: 20
    },
    "& .MuiInputBase-root::before": {
      borderBottom: "1px solid #9194A9"
    }
  },
  select_wrapper: {
    maxWidth: "30%",
    width: "100%",
    marginBottom: 25,
    [theme.breakpoints.down("1445")]: {
      maxWidth: "100%"
    }
  },
  label: {
    fontSize: 17,
    fontWeight: 600,
    color: "#9194A9",
    marginBottom: 3
  },
  select: {
    width: "100%",
    "&::before": {
      borderBottom: "1px solid #9194A9"
    }
  },
  btn: {
    backgroundColor: "#0C4B86",
    borderRadius: 4,
    padding: "7px 73px",
    color: "#fff",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: "0.08em",
    "&:hover": {
      backgroundColor: "#0C4B86"
    }
  }
}));

const RegisterProblem = () => {
  const classes = useStyles();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const dispatch = useDispatch();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);

  const resident = useSelector(state => state.remontsState.resident);
  const [selectValues, setSelectValues] = useState({
    types: [],
    priorities: []
  });
  const [isFetching, setIsFetching] = useState(false);

  const initialValues = useMemo(
    () => ({
      col_id: null,
      card_title: "",
      card_content: "",
      client_request_id: resident?.client_request_id,
      card_type_id: "",
      priority_id: "",
      is_remont_card: true,
      remont_id
    }),
    [remont_id, resident]
  );

  const [inputValues, setInputValues] = useState({
    ...initialValues
  });
  const { types, priorities } = selectValues;

  useEffect(() => {
    setInputValues(initialValues);
  }, [remont_id, initialValues]);

  useEffect(() => {
    if (resident?.remont_id) {
      setIsFetching(true);
      getProblemName({ client_request_id: resident?.client_request_id })
        .then(res => {
          setIsFetching(false);
          setInputValues(prev => ({
            ...prev,
            card_title: res.data
          }));
        })
        .catch(e => {
          addCloseSnack(e?.response?.data?.error);
          setIsFetching(false);
        });
    }
  }, [resident.client_request_id, resident.remont_id, addCloseSnack]);

  const fetchSelectValues = async () => {
    const [priorities, types] = await Promise.all([
      fetchPriorities(),
      fetchCardTypes()
    ]).catch(() => {});
    setSelectValues({
      types: types.data,
      priorities: priorities.data
    });
  };

  useEffect(() => {
    fetchSelectValues();
  }, []);

  const handleChange = e => {
    const name = e.target.name;
    setInputValues({
      ...inputValues,
      [name]: e.target.value
    });
  };

  const handleClick = () => {
    addNewCard({
      ...inputValues,
      client_request_id: resident?.client_request_id,
      remont_id
    })
      .then(res => {
        dispatch(setColumns(res?.data));
        addDefaultSnack("Успешно");
        setInputValues(initialValues);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  };

  return (
    <Box className={classes.register_box}>
      <Typography className={classes.title}>
        Регистрация нового обращения
      </Typography>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        {isFetching ? (
          <CustomLoader />
        ) : (
          <>
            <Box className={classes.group_wrapper}>
              <Box className={classes.form_group}>
                <TextField
                  className={classes.text_field}
                  disabled
                  id="card_title"
                  name="card_title"
                  label="Название обращения"
                  placeholder="Добавьте название обращения"
                  value={inputValues.card_title}
                  required
                  onChange={handleChange}
                  classes={{
                    root: classes.input
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Box>
            </Box>
            <Box className={classes.select_wrapper}>
              <InputLabel shrink className={classes.label} required>
                Тип обращения
              </InputLabel>
              <NativeSelect
                className={classes.select}
                name="card_type_id"
                onChange={handleChange}
                value={inputValues.card_type_id}
              >
                <option value="" hidden>
                  Выбрать тип
                </option>
                {types?.map(el => {
                  return (
                    <option key={el.card_type_id} value={el.card_type_id}>
                      {el.card_type_name}
                    </option>
                  );
                })}
              </NativeSelect>
            </Box>
            <Box className={classes.group_wrapper}>
              <Box className={classes.form_group}>
                <TextField
                  className={classes.text_field}
                  label="Описание обращения"
                  placeholder="Добавьте подробное описание обращения"
                  id="card_content"
                  name="card_content"
                  onChange={handleChange}
                  value={inputValues.card_content}
                  classes={{
                    root: classes.input
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Box>
            </Box>
            <Box className={classes.select_wrapper}>
              <InputLabel shrink className={classes.label}>
                Приоритет
              </InputLabel>
              <NativeSelect
                className={classes.select}
                name="priority_id"
                value={inputValues.priority_id}
                required
                onChange={handleChange}
              >
                <option value="" hidden>
                  Выбрать приоритет
                </option>
                {priorities?.map(el => {
                  return (
                    <option key={el.priority_id} value={el.priority_id}>
                      {el.priority_name}
                    </option>
                  );
                })}
              </NativeSelect>
            </Box>
          </>
        )}
      </Box>
      <Box textAlign="right" mt={1}>
        <Button className={classes.btn} onClick={handleClick}>
          Добавить
        </Button>
      </Box>
    </Box>
  );
};

export default RegisterProblem;
