import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getDefectList } from "./api";
import Typography from "@material-ui/core/Typography";
import ReportCommentPhoto from "./reportCommentPhoto";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 5
  },
  column: {
    flexBasis: "33.33%"
  },
  secondaryHeading: {
    fontSize: 12,
    fontWeight: 600,
    color: "#9194A9"
  },
  defect: {
    borderLeft: "1px solid #F0F2F6",
    verticalAlign: "top",
    padding: 0
  },
  conter: {
    width: 40,
    verticalAlign: "top"
  },
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: "black",
    marginRight: 5
  },
  title_name: {
    fontWeight: "bold",
    fontSize: 14,
    color: "black",
    marginRight: 5
  },
  href: {
    fontWeight: 500,
    fontSize: 14,
    color: "#3190ff"
  },
  cell: {
    width: 280,
    verticalAlign: "top"
  }
}));

export default function ReportComment({ data = {} }) {
  const classes = useStyles();
  const [dataGet, setData] = useState({});
  const { addCloseSnack } = useCustomSnackbar();
  const row = [];
  let int = 1;

  React.useEffect(() => {
    const params = {
      remont_id: data.remont_id,
      stage_id: data.stage_id
    };
    getDefectList(params)
      .then(answer => {
        setData(answer.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [data.remont_id, data.stage_id, addCloseSnack]);

  Object.keys(dataGet).forEach(function(key) {
    row.push(dataGet[key]);
  });

  return row.map(item => (
    <TableContainer
      key={item?.check_list_id}
      component={Paper}
      className={classes.root}
      style={{ boxShadow: "0px 5px 20px rgb(108 108 108 / 10%)" }}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell align="center" className={classes.conter}>
              {int++}
            </TableCell>
            <TableCell align="left" className={classes.cell}>
              <Typography
                variant="h5"
                component="h2"
                className={classes.secondaryHeading}
              >
                Название замечания:
              </Typography>
              <Typography
                variant="h4"
                component="h4"
                className={classes.title_name}
              >
                {item.check_name}
              </Typography>
              <span className={classes.title}>Норма: {item.norm}</span>
              <Typography
                variant="h5"
                component="h5"
                className={classes.secondaryHeading}
              >
                Мастер:
              </Typography>
              <Typography variant="h5" component="h5" className={classes.title}>
                {item.master_type}
              </Typography>
            </TableCell>
            <TableCell align="left" className={classes.defect}>
              <ReportCommentPhoto data={item} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  ));
}
