import React from "react";
import styles from "./RequestShipHistory.module.scss";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../../common/CustomTable/CustomTable";

export const ShipHistoryContent = ({ data, history, isFetching }) => {
  return (
    <div className={styles.history}>
      <h2>
        История изменений даты отгрузки заявки #
        {data?.provider_request_id || ""}
      </h2>

      <CustomTable
        stickyHeader
        isEmpty={!history?.length}
        isFetching={isFetching}
        headItems={headItems}
      >
        {history?.map((item, i) => {
          return (
            <CustomTableRow key={item?.provider_shipping_hist_id || i}>
              <CustomTableCell align={"center"} width={80}>
                {i + 1}
              </CustomTableCell>
              <CustomTableCell>{item?.material_name}</CustomTableCell>
              <CustomTableCell>{item?.date_shipping_old}</CustomTableCell>
              <CustomTableCell>{item?.date_shipping_new}</CustomTableCell>
              <CustomTableCell>{item?.date_shipping_comment}</CustomTableCell>
              <CustomTableCell>{item?.fio}</CustomTableCell>
              <CustomTableCell>{item?.rowversion}</CustomTableCell>
            </CustomTableRow>
          );
        })}
      </CustomTable>
    </div>
  );
};

const headItems = [
  { label: "ID" },
  { label: "Материал" },
  { label: "Старая дата отгрузки" },
  { label: "Новая дата отгрузки" },
  { label: "Комментарии" },
  { label: "Работник" },
  { label: "Дата изменения" }
];
