import { appReducer } from "./reducers/appReducer";
import { remontsReducer } from "./reducers/remontsReducer";
import { problemsReducer } from "./reducers/problemsReducer";
import configureStore from "./configureStore";
import { accountingReducer } from "./reducers/accountingReducer";

export default {
  appState: appReducer,
  accountingState: accountingReducer,
  remontsState: remontsReducer,
  problemsState: problemsReducer,
};

export const store = configureStore();
