import React from "react";
import Placehold from "../../../../assets/images/Placehold.svg";
import styles from "./placeholder.module.scss";

export const PlaceholderImg = ({ text }) => {
  return (
    <div>
      <img
        src={Placehold}
        alt={"placeholder"}
        className={styles.placeholder__img}
      />
      <p className={styles.placeholder__text}>{text}</p>
    </div>
  );
};
