import React from "react";

import { useStyles } from "../Modals/penaltyDetailsModal";
import { Box } from "@material-ui/core";
import NumberFormat from "react-number-format";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../components/common/CustomTable/CustomTable";

const SmetaTable = ({ penaltyReport }) => {
  const classes = useStyles();

  const totalSum = penaltyReport.materials?.reduce((accumulator, object) => {
    return accumulator + object.summ;
  }, 0);

  return (
    <Box>
      <Box sx={{ marginBottom: "18px", fontSize: "18px" }}>
        Штраф за перерасход чистовых материалов
      </Box>

      <CustomTable
        headItems={headTitle}
        isEmpty={!penaltyReport?.materials?.length}
      >
        {penaltyReport.materials?.map(el => {
          return (
            <CustomTableRow className={classes.table_row} key={el.material_id}>
              <CustomTableCell align="left" className={classes.cell}>
                {el.material_name}
              </CustomTableCell>
              <CustomTableCell align="right" className={classes.cell}>
                <NumberFormat
                  value={el.price}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </CustomTableCell>
              <CustomTableCell align="right" className={classes.cell}>
                {el.fact_cnt}
                {el.sell_unit_name}
              </CustomTableCell>
              <CustomTableCell align="right" className={classes.cell}>
                {el.smeta_cnt}
                {el.sell_unit_name}
              </CustomTableCell>
              <CustomTableCell align="right" className={classes.cell}>
                {el.diff_cnt}
                {el.sell_unit_name}
              </CustomTableCell>
              <CustomTableCell align="right" className={classes.cell}>
                <NumberFormat
                  value={el.summ}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                />
              </CustomTableCell>
            </CustomTableRow>
          );
        })}
      </CustomTable>
      <Box
        sx={{
          fontSize: "16px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          gap: "5px",
          marginTop: "18px"
        }}
      >
        Итого:{" "}
        <Box sx={{ fontWeight: 600 }}>
          <NumberFormat
            value={totalSum}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
          />
          тг
        </Box>
      </Box>
    </Box>
  );
};

export default SmetaTable;

const headTitle = [
  { label: "Материал", align: "left" },
  { label: "Цена", align: "left" },
  { label: "Отгрузили", align: "left" },
  { label: "Смета", align: "left" },
  { label: "Разница", align: "left" },
  { label: "Сумма", align: "left" }
];
