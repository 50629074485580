import { getExternalMasterPayments } from "./api";

export const getExternalMasterPaymentsData = async () => {
  try {
    const res = await getExternalMasterPayments()
    if(!res) return
    const data = res?.data
    const amount = data?.reduce((prev, cur) => {
      return {work_sum: cur.work_sum + prev.work_sum, payed_sum: cur.payed_sum + prev.payed_sum, awaiting_sum: cur.awaiting_sum + prev.awaiting_sum}
    }, {work_sum: 0, payed_sum: 0, awaiting_sum: 0})
    return {list: data, amount}
  } catch (e) {}
}

