import { makeStyles } from "@material-ui/core/styles";
import { PAGES } from "../../constants";
import { setHeader } from "../../services/redux/actions/app-actions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getHomeBuilders, getJKList } from "./api";
import DownloadXlsx from "../../components/common/DownloadXlsx";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { getContractors } from "../remonts/services/api";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

const useStyles = makeStyles(() => ({
  filters: {
    float: "left", display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    gap: "10px"
  },
  select: {
    width: 250,
    float: "left",
    maxHeight: 100,
    overflowY: "auto",
    overflowX: "hidden",
    "& .MuiFormControl-root": {
      width: "100%"
    }
  }
}));

const ReportFilter = ({ filters, setFilters }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { addCloseSnack } = useCustomSnackbar();

  const classes = useStyles();

  const [residents, setResidents] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [homeBuilders, setHomeBuilders] = useState([]);

  const handleChange = e => {
    setFilters(prev => ({
      ...prev,
      [e.target.name]: e.target.value?.length ? e.target.value : null
    }));
  };

  useEffect(() => {
    getJKList()
      .then((answer = {}) => {
        setResidents(answer.data.data);
      })
      .catch(err => {
        addCloseSnack(err.response?.data?.error);
        history(PAGES.reportCostPayment);
      });

    dispatch(
      setHeader({
        showBack: false,
        isList: true,
        showCancel: true
      })
    );
  }, [dispatch, addCloseSnack, history]);

  useEffect(() => {
    getContractors()
      .then(res => {
        setContractors(res.data);
      })
      .catch(err => {
        addCloseSnack(err.response?.data?.error);
        history(PAGES.reportCostPayment);
      });
  }, [dispatch, addCloseSnack, history]);

  useEffect(() => {
    getHomeBuilders()
      .then(res => {
        setHomeBuilders(res.data);
      })
      .catch(e => {
        addCloseSnack(e.response?.data?.error);
      });
  }, [addCloseSnack]);

  return (
    <CollapseBlock tabletMode className={classes.filters}>
      <div className={classes.select}>
        <AutoSuggest
          list={residents}
          name="resident_ids"
          showKey={"resident_name"}
          valueKey={"resident_id"}
          value={filters.resident_ids}
          placeholder="Все жк"
          label="Жилой комплекс"
          className={classes.select}
          multiple
          onChange={handleChange}
        />
      </div>
      <div className={classes.select}>
        <AutoSuggest
          list={contractors}
          name="contractor_ids"
          showKey={"contractor_name"}
          valueKey={"contractor_id"}
          value={filters.contractor_ids}
          placeholder="Все подрядчики"
          label="Выберите подрядчика"
          className={classes.select}
          multiple
          onChange={handleChange}
        />
      </div>
      <div className={classes.select}>
        <AutoSuggest
          list={homeBuilders}
          name="home_builder_ids"
          showKey={"home_builder_name"}
          valueKey={"home_builder_id"}
          value={filters.home_builder_ids}
          placeholder="Все застройщики"
          label="Выберите застройщика"
          className={classes.select}
          multiple
          onChange={handleChange}
        />
      </div>
      <DownloadXlsx
        url="/partner/report_costs_of_payment_by_resident/xlsx/"
        params={filters} withoutMargin
        title="Список оплат по ремонтам "
      />
    </CollapseBlock>
  );
};

export default ReportFilter;
