import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Box, Collapse } from "@material-ui/core";
import { fieldsToDelete, getExecutiveReportData } from "./services";
import EmptyOrShow from "../../components/common/EmptyOrShow";
import styles from "./executive.module.scss";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../components/common/CustomTable/CustomTable";
import HelpIcon from "@material-ui/icons/Help";
import { CustomPopover } from "../../components/common/CustomPopover";
import { ExecutiveReportFilters } from "./filters";
import { Link } from "react-router-dom";
import { PAGES_ROUTE_KEY } from "../../constants";
import DownloadXlsx from "../../components/common/DownloadXlsx";

export const ExecutiveReport = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [executiveData, setExecutiveData] = useState([]);
  const [openedItems, setOpenedItems] = useState({});
  const [filters, setFilters] = useState({
    resident_id_arr: [],
    contractor_id_arr: [],
    techproject_employee_id_arr: [],
    inner_master_id_arr: [],
    manager_project_id_arr: []
  });

  const getData = useCallback(async () => {
    setIsFetching(true);
    const res = await getExecutiveReportData(filters);
    setIsFetching(false);
    setExecutiveData(res);
  }, [filters]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onMngColorClick = (mng_report_id, color) => {
    const activeItem = openedItems[mng_report_id];
    const isActive = activeItem?.show;
    const isColorSame = color?.color_id === activeItem?.color?.color_id;
    setOpenedItems(prev => {
      return {
        ...prev,
        [mng_report_id]: {
          show: isActive && !isColorSame ? true : !prev[mng_report_id]?.show,
          color: isActive && isColorSame ? null : color
        }
      };
    });
  };

  const handleFiltersChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };

  const getHeadItems = useCallback(mng_report => {
    if (!mng_report?.show) return [];
    const activeColor = mng_report?.color;
    if (!activeColor) return [];
    const dataItem = activeColor.data?.at(0);
    if (!dataItem) return [];
    return Object.keys(dataItem)
      .filter(item => !fieldsToDelete.includes(item))
      .map(label => ({ label }));
  }, []);

  return (
    <Box p={2}>
      <ExecutiveReportFilters
        filters={filters}
        onFiltersChange={handleFiltersChange}
      />
      <EmptyOrShow loading={isFetching}>
        <div className={styles.executive__list}>
          {executiveData?.map(item => {
            const rowItem = openedItems[item.mng_report_id];
            return (
              <Fragment key={item.mng_report_id}>
                <div
                  className={styles.executive__item}
                  style={
                    !!rowItem?.show
                      ? { position: "sticky", top: 0, zIndex: 10 }
                      : {}
                  }
                >
                  <div className={styles.executive__item_title}>
                    <span>{item.stage_num}. </span>
                    {item.mng_report_name}
                  </div>
                  <div className={styles.executive__colors}>
                    {item.colors?.map(color => {
                      const isActiveColor =
                        color?.color_id === rowItem?.color?.color_id;
                      return (
                        <div
                          key={color.color_id}
                          className={`${styles.executive__colors_item} ${
                            isActiveColor
                              ? styles.executive__colors_itemActive
                              : ""
                          }`}
                          onClick={() =>
                            onMngColorClick(item.mng_report_id, color)
                          }
                          style={{
                            backgroundColor: `#${color.color_hex_code}`
                          }}
                        >
                          {!!color.cnt && <span>{color.cnt}</span>}
                        </div>
                      );
                    })}
                  </div>
                  <div className={styles.executive__item_right}>
                    <DownloadXlsx
                      url="/partner/mng_report/excel/"
                      params={{ mng_report_id: item.mng_report_id }}
                      withoutMargin small
                      title={`${item.mng_report_name} - Отчеты для руководителей`}
                      reqType="post"
                    />
                    <CustomPopover title={item.mng_report_desc}>
                      <HelpIcon className={styles.executive__item_help} />
                    </CustomPopover>
                  </div>
                </div>
                <Collapse
                  in={!!rowItem?.show}
                  timeout="auto"
                  style={{ width: "100%" }}
                  unmountOnExit
                >
                  <div
                    style={{
                      border: `2px solid #${rowItem?.color?.color_hex_code}`
                    }}
                  >
                    {!!rowItem?.color?.data?.length ? (
                      <CustomTable headItems={getHeadItems(rowItem)}>
                        {rowItem?.color?.data?.map((item, i) => {
                          const keys = Object.keys(item)?.filter(
                            item => !fieldsToDelete.includes(item)
                          );
                          return (
                            <CustomTableRow key={i}>
                              {keys?.map((key, i) => {
                                return (
                                  <CustomTableCell key={i}>
                                    {key === "ID ремонта" ? (
                                      <Link
                                        to={`${PAGES_ROUTE_KEY.singleRemont}/${item[key]}`}
                                      >
                                        {item[key]}
                                      </Link>
                                    ) : (
                                      <span>{item[key]}</span>
                                    )}
                                  </CustomTableCell>
                                );
                              })}
                            </CustomTableRow>
                          );
                        })}
                      </CustomTable>
                    ) : (
                      <div className={styles.executive__item_noData}>
                        Нет данных для отображения
                      </div>
                    )}
                  </div>
                </Collapse>
              </Fragment>
            );
          })}
        </div>
      </EmptyOrShow>
    </Box>
  );
};
