import React from "react";
import { PageTitle } from "../../components/common/pageTitle";
import CMR_1 from "../../assets/images/cmr/cmr_1.jpg";
import CMR_2 from "../../assets/images/cmr/cmr_2.jpg";
import CMR_3 from "../../assets/images/cmr/cmr_3.jpg";
import CMR_4 from "../../assets/images/cmr/cmr_4.jpg";
import CMR_5 from "../../assets/images/cmr/cmr_5.jpg";
import CMR_6 from "../../assets/images/cmr/cmr_6.jpg";
import CMR_7 from "../../assets/images/cmr/cmr_7.jpg";
import CMR_8 from "../../assets/images/cmr/cmr_8.jpg";
import CMR_9 from "../../assets/images/cmr/cmr_9.jpg";
import CMR_10 from "../../assets/images/cmr/cmr_10.jpg";
import CMR_11 from "../../assets/images/cmr/cmr_11.jpg";
import { makeStyles } from "@material-ui/core";

export const CMRStandards = () => {
  const classes = useStyles();
  return (
    <div>
      <PageTitle title={"Стандарты CМР"} />
      <div className={classes.wrapper}>
        {
          images.map((item, idx) => {
            return <img key={idx} src={item} alt={"cmr_photo"} />;
          })
        }
      </div>
    </div>
  );
};
const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 25,
    padding: 25,
    "& > img": {
      maxWidth: "80%",
      maxHeight: "350px"
    }
  }
}));
const images = [
  CMR_1,
  CMR_2,
  CMR_3,
  CMR_4,
  CMR_5,
  CMR_6,
  CMR_7,
  CMR_8,
  CMR_9,
  CMR_10,
  CMR_11
];