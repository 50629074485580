import instance from "../../../../services/api";

export const getCost = async params => {
  return await instance(true)
    .get("/partner/contractor_costs/", { params })
    .then(res => res?.data);
};

export const getTeamDetailCosts = async (teamId, params) => {
  return await instance(true)
    .get(`partner/teams/${teamId}/masters/costs/`, { params })
    .then(res => res?.data);
};

export const getBrigadirCosts = async params => {
  return await instance(true)
    .get(`partner/contractor_costs/team-main-master/`, { params })
    .then(res => res?.data);
};

export const getRemontsBrigadaData = async remont_id => {
  return await instance(true)
    .get(`partner/remont/${remont_id}/brigada/`)
    .then(res => res?.data);
};

export const getIncome = async params => {
  return await instance(true)
    .get(`/partner/contractor_incomes/`, { params })
    .then(res => res?.data);
};

export const getDetailIncome = async remont_id => {
  return await instance(true)
    .get(`/partner/remont/${remont_id}/accounting/`)
    .then(res => res?.data);
};

export const getRemonts = async () => {
  return await instance(true)
    .get(`/partner/contractor_commons/remonts/`)
    .then(res => res?.data);
};

export const getPaymentTypes = async () => {
  return await instance(true)
    .get(`/partner/team_master_payment/team_master_payment_type/`)
    .then(res => res?.data);
};

export const getTeamRemonts = async team_id => {
  return await instance(true)
    .post(`/partner/contractor_commons/remonts/specific/`, { team_id })
    .then(res => res?.data);
};

export const getMasters = async () => {
  return await instance(true)
    .get(`/partner/contractor_commons/masters/`)
    .then(res => res?.data);
};

export const getRemontMasters = async remontId => {
  return await instance(true)
    .get(`/partner/contractor_commons/team_masters_of_remont/${remontId}/`)
    .then(res => res?.data);
};

export const getTeamMasters = async team_id => {
  return await instance(true)
    .post(`/partner/contractor_commons/masters/specific/`, { team_id })
    .then(res => res?.data);
};

export const getTeamMastersById = async team_id => {
  return await instance(true)
    .get(`/partner/teams/${team_id}/masters/`)
    .then(res => res?.data);
};

export const getTeamMasterInfo = async teamMasterId => {
  return await instance(true)
    .get(`/partner/contractor_commons/team_master_info/${teamMasterId}/`)
    .then(res => res?.data);
};

export const getCostContractorTypes = async () => {
  return await instance(true)
    .get(`partner/contractor_types/cost/`)
    .then(res => res?.data);
};

export const getCostContractorTypesFilter = async () => {
  return await instance(true)
    .get(`/partner/contractor_types/cost_for_filter/`)
    .then(res => res?.data);
};

export const getIncomeContractorTypes = async () => {
  return await instance(true)
    .get(`partner/contractor_types/income/`)
    .then(res => res?.data);
};

export const getCostPaymentType = async () => {
  return await instance(true)
    .get(`/partner/contractor_types/cost/paybox/`)
    .then(res => res?.data);
};

export const getPayboxBalance = async remont_id => {
  return await instance(true)
    .post(`/partner/paybox/getting_balance/${remont_id}/`)
    .then(res => res?.data);
};

export const getStagesList = async () => {
  return await instance(true)
    .get(`/partner/commons/stages/`)
    .then(res => res?.data);
};
