import React, { useState } from "react";

import {
  Typography,
  Box,
  ListItem,
  List,
  ListSubheader,
  ListItemText,
  Collapse,
  ListItemSecondaryAction,
  Checkbox,
  Button
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { WrapperScrollbar } from "../../components/common/WrapperScrollbar";

const useStyles = makeStyles(() => ({
  nav: {
    maxHeight: "770px",
    overflow: "scroll"
  },
  subHeader: {
    background: " #ECEEFD",
    borderRadius: "4px 4px 0px 0px",
    color: "#9194A9"
  },
  id: {
    marginRight: "25px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.04em"
  },
  listItem: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
}));

export const WorkListByStages = ({
  data,
  setAllWorks,
  addWorkItem,
  deleteWork,
  selectedWorks,
  all
}) => {
  const classes = useStyles();
  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        style={{ maxHeight: "770px", backgroundColor: "#fff" }}
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            className={classes.subHeader}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography className={classes.id}>ID</Typography>
                <Typography>Наименование работы</Typography>
              </Box>
              <ListItem button style={{ width: "auto" }}>
                <Button onClick={setAllWorks}>Выбрать все</Button>
              </ListItem>
            </Box>
          </ListSubheader>
        }
      >
        <WrapperScrollbar className={classes.nav}>
          {data?.map(el => {
            return (
              <StagesItem
                key={el.stage_contractor_name}
                stageItem={el}
                addWorkItem={addWorkItem}
                deleteWork={deleteWork}
                selectedWorks={selectedWorks}
                all={all}
              />
            );
          })}
        </WrapperScrollbar>
      </List>
    </>
  );
};

const StagesItem = ({
  stageItem,
  addWorkItem,
  deleteWork,
  selectedWorks,
  all
}) => {
  const { stage_contractor_name, works } = stageItem;
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick} className={classes.listItem}>
        <ListItemText primary={stage_contractor_name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {works?.map(el => {
        return (
          <WorkItem
            key={el.work_id}
            el={el}
            addWorkItem={addWorkItem}
            open={open}
            deleteWork={deleteWork}
            selectedWorks={selectedWorks}
            all={all}
          />
        );
      })}
    </>
  );
};

const WorkItem = ({
  el,
  addWorkItem,
  open,
  deleteWork,
  selectedWorks,
  all = false
}) => {
  const [checked, setChecked] = useState(false);

  const inArray = Array.isArray(selectedWorks)
    ? selectedWorks?.some(item => item.work_id === el.work_id)
    : false;

  const handleToggle = value => () => {
    setChecked(!checked);
    if (inArray) {
      deleteWork(value);
    } else {
      addWorkItem(value);
    }
  };

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItem button>
          <Typography style={{ marginRight: "25px" }}>{el.work_id}</Typography>
          <ListItemText primary={el.work_name} />
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              onChange={handleToggle(el.work_id)}
              color="primary"
              defaultChecked={all ? all : inArray}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Collapse>
  );
};
