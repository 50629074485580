import instance from "../../services/api";

export const fetchCompletedStages = params => {
  return instance(true)
    .post(`/partner/report_completed_stage/`, params)
    .then((res = {}) => {
      return res;
    });
};
export const fetchStages = () => {
  return instance(true)
    .get(`/partner/stages/`)
    .then((res = {}) => {
      return res;
    });
};
