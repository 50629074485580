import React, { useRef } from "react";
import { Box, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  search: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: 4,
    "& input": {
      padding: "9px 9px 9px",
      width: "100%",
      fontSize: 14,
      lineHeigh: "17px"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px !important"
    }
  },
  icon: {
    color: "#0C4B86",
    width: 24,
    height: 24
  },
  search_history: {
    width: "91%",
    backgroundColor: "#fff",
    borderRadius: 4,
    "& input": {
      padding: "9px 9px 9px",
      width: "100%",
      fontSize: 14,
      lineHeigh: "17px"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "4px !important"
    },
    [theme.breakpoints.down(576)]: {
      width: "88%",
    }
  }
}));
const SideBarSearch = ({
                         placeholder,
                         handleChange,
                         isHistory,
                         handleClick
                       }) => {
  const classes = useStyles();
  const refContainer = useRef(null);
  return (
    <>
      {isHistory ? (
        <Box>
          <TextField
            className={classes.search_history}
            ref={refContainer}
            id="outlined-search"
            type="search"
            variant="outlined"
            placeholder={placeholder}
            autoFocus
          />
          <SearchIcon
            className={classes.icon}
            onClick={() => {
              const inputValue =
                refContainer.current.children[0].children[0].value; //using useRef to prevent a lot re-renders
              handleClick(inputValue);
            }}
            style={{
              cursor: "pointer",
              display: "inline-block",
              width: 31,
              marginLeft: 1,
              background: "#fff",
              border: "1px solid #E2E3ED",
              borderRadius: 4,
              height: "34"
            }}
          />
        </Box>
      ) : (
        <TextField
          className={classes.search}
          id="outlined-search"
          type="search"
          variant="outlined"
          placeholder={placeholder}
          onChange={handleChange}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.icon} />
              </InputAdornment>
            )
          }}
        />
      )}
    </>
  );
};

export default SideBarSearch;
