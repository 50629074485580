import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../common/CustomTable/CustomTable";
import { PageTitle } from "../../../common/pageTitle";
import { getExternalMasterPaymentsData } from "./services";
import { numberWithCommas } from "../../../../utils";
import clsx from "clsx";
import { CustomSearch } from "../../../common/form/CustomSearch";
import { Box } from "@material-ui/core";

export const ExternalMasterPayments = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState(null);
  const [amount, setAmount] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const getDebtData = useCallback(async params => {
    setIsFetching(true);
    const res = await getExternalMasterPaymentsData(params);
    setIsFetching(false);
    if (!res) return;
    setData(res?.list);
    setFilteredData(res?.list)
    setAmount(res.amount)
  }, []);

  useEffect(() => {
    getDebtData();
  }, [getDebtData]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if(!data?.length || !searchTerm) setFilteredData(data)
      else {
        setFilteredData(data?.filter(item =>
          item?.team_master_fio?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          String(item?.remont_id)?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          String(item?.work_sum)?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          String(item?.awaiting_sum)?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          String(item?.payed_sum)?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        ));
      }
    }, 600)
    return () => clearTimeout(timeout)
  }, [searchTerm, data]);

  const handleChange = e => setSearchTerm(e.target.value);
  return (
    <>
      <PageTitle title={"Выплаты внешним мастерам"} />
      <div className={styles.amount}>
        <Box marginBottom={2}>
          <CustomSearch value={searchTerm} handleChange={handleChange} />
        </Box>
        <CustomTable
          isFetching={isFetching}
          headItems={headItems}
          stickyHeader
          isEmpty={!filteredData?.length}
        >
          {filteredData?.map((item, i) => {
            return <TableRow key={i} item={item} />;
          })}
          {amount && <TableRow item={amount} />}
        </CustomTable>
      </div>
    </>
  );
};

const TableRow = ({ item }) => {
  return (
    <>
      <CustomTableRow className={clsx({ [styles.amount__bold]: !item.remont_id })}>
        <CustomTableCell>{item?.team_master_fio || "Итого"}</CustomTableCell>
        <CustomTableCell>{item?.remont_id}</CustomTableCell>
        <CustomTableCell align={"right"}>
          {numberWithCommas(item?.work_sum)}
        </CustomTableCell>
        <CustomTableCell align={"right"}>
          {numberWithCommas(item?.awaiting_sum)}
        </CustomTableCell>
        <CustomTableCell align={"right"}>
          {numberWithCommas(item?.payed_sum)}
        </CustomTableCell>
      </CustomTableRow>
    </>
  );
};

const headItems = [
  { label: "ФИО", align: "left" },
  { label: "Ремонт ID", align: "left" },
  { label: "Сумма работ", align: "right" },
  { label: "Ожидает оплату", align: "right" },
  { label: "Оплачено", align: "right" }
];
