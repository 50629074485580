import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { setSideBarContent } from "../../services/redux/actions/problemsActions";
const useStyles = makeStyles({
  header: {
    width: "100%",
    height: 64,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderBottom: "1px solid #D2D3D9",
    padding: "0 22px"
  },
  title: {
    width: "100%",
    textAlign: "center",
    fontSize: 16,
    fontWeight: 600,
    color: "#494C62"
  },
  icon: {
    marginLeft: "auto",
    color: "#0C4B86",
    cursor: "pointer"
  },
  arrow: {
    color: "#0C4B86",
    cursor: "pointer",
    width: 20,
    height: 20
  }
});

const SideBarHeader = ({ title, handleClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { card_id } = useSelector(state => state.problemsState);
  const handleArrowClick = () => {
    dispatch(setSideBarContent("Меню", card_id));
  };
  return (
    <Box className={classes.header}>
      {title !== "Меню" && (
        <ArrowBackIosIcon
          className={classes.arrow}
          onClick={handleArrowClick}
        />
      )}
      <Typography className={classes.title}>{title}</Typography>
      <CloseIcon className={classes.icon} onClick={() => handleClick(false)} />
    </Box>
  );
};

export default SideBarHeader;
