import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { useStyles } from "../../Problems/Card";
import { useDispatch } from "react-redux";
import { showPopUp } from "../../../services/redux/actions/app-actions";
import { Edit } from "react-iconly";
import { MODALS_NAMES } from "../../../constants";
import { getProblemColor } from "../../../services";
import { ComplexIcon } from "../../../components/common/blocks/Amount";

const RemontsProblemCard = ({
  card_id,
  card_title,
  date_create,
  executors,
  priority_name,
  col_id,
  priority_id,
  resident_name,
  flat_num,
  card_type_name
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      showPopUp(true, MODALS_NAMES.singleCardModal, {
        card_id,
        col_id,
        isRemontProblem: true
      })
    );
  };
  return (
    <Paper className={classes.card_header} style={{ cursor: "default" }}>
      <Box className={classes.card_wrapper}>
        <Box style={{ width: "80px" }}>
          <Typography className={classes.id}>№{card_id}</Typography>
          <Typography className={classes.date}>{date_create}</Typography>
        </Box>
        {priority_name ? (
          <Box
            className={classes.card_rate}
            component="span"
            style={{ backgroundColor: getProblemColor(priority_id) }}
          >
            {priority_name}
          </Box>
        ) : null}

        <Box className={classes.card_icon} onClick={handleClick}>
          <Edit style={{ color: "#0C4B86" }} />
        </Box>
      </Box>
      <Box className={classes.card_text}>
        <Typography className={classes.resident}>
          <ComplexIcon/>
          {resident_name}, КВ {flat_num}
        </Typography>{" "}
        <Typography className={classes.resident}>{card_title}</Typography>{" "}
        <Typography className={classes.resident_info}>
          Подробное описание обращения показывается внутри карточки
        </Typography>
      </Box>
      <Box className={classes.card_footer}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography className={classes.card_footer_text}>
            Тип обращения:
          </Typography>
          <Typography className={classes.card_executors}>
            {card_type_name}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.card_footer_text}>
            Ответственный:
          </Typography>
          <Typography className={classes.card_executors}>
            {executors}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default RemontsProblemCard;
