import { useState } from "react";
import instance from "../../../../services/api";
import { format } from "date-fns";
import { saveAs } from "file-saver";

export const useDownloadFile = () => {
  const [downloading, setDownloading] = useState(false);

  const downloadFile = async (
    url = "",
    title = "",
    params = {},
    fileFormat = "xlsx",
    requestType,
    auth = true
  ) => {
    setDownloading(auth);

    const reqParams = { ...params };
    if (requestType === "get") reqParams.responseType = "arraybuffer";
    await instance(true)
      [requestType || "post"](url, reqParams, {
        responseType: "arraybuffer",
      })
      .then((res = {}) => {
        const blob = new Blob([res.data], {
          type:
            fileFormat === "xlsx"
              ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              : fileFormat === "pdf"
              ? "application/pdf"
              : "application/png",
        });
        saveAs(
          blob,
          `${title}- ${format(new Date(), "dd.MM.YYY")}.${fileFormat}`
        );
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };
  return { downloadFile, downloading };
};
