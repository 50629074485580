import React from "react";
import { PageTitle } from "../../components/common/pageTitle";
import Projects from "../remonts/Projects";
import { Box } from "@material-ui/core";
import { CheckRemontPermission } from "../remonts/CheckRemontPermission";

const RemontProjects = () => {
  return (
    <CheckRemontPermission>
      <PageTitle title="Проекты" />
      <Box style={{ padding: 20 }}>
        <Projects singleRemont />
      </Box>
    </CheckRemontPermission>
  );
};

export default RemontProjects;
