import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { CButton } from "../../../../common/buttons";
import CustomInput from "../../../../common/form/CustomInput";
import { InputWarning } from "../../../../common/form/warnings";
import styles from "./add_master.module.scss";
import { Checkbox, IconButton } from "@material-ui/core";
import { AutoSuggest } from "../../../../common/form/AutoSuggest";
import CloseIcon from "@material-ui/icons/Close";
import { getPhoneNumbers, PhonesInput } from "./PhonesInput";
import { CardInput } from "../../../../common/form/InputNumberMask";

export const AddTeamMaster = ({ data, handleClose }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm();
  const { modalLoading, master_types } = useSelector(state => state.appState);
  const [phonesInfo, setPhonesInfo] = useState({
    phones: data?.body?.phone_numbers || [],
    phoneNumber: "",
    activePhoneId: ""
  });

  const onSubmit = async body => {
    const { onSubmit: submit } = data;
    return (
      submit &&
      (await submit(
        {
          ...body,
          team_master_id: data?.body?.team_master_id,
          phone_numbers: getPhoneNumbers(phonesInfo),
          master_type_id: Number(body?.master_type_id),
          team_master_doc_num: body?.team_master_doc_num?.toUpperCase() || ""
        },
        data?.body?.team_id
      ))
    );
  };

  const handlePhoneChange = useCallback(
    b => setPhonesInfo(prev => ({ ...prev, ...b })),
    []
  );

  const isMainMaster = watch("is_main_master");
  return (
    <div>
      <div className={styles.add_team__header}>
        <h2 className={styles.add_team__title}>{` ${
          data?.body ? "Редактирование" : "Добавление"
        } мастера`}</h2>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <form className={styles.add_team__form} onSubmit={handleSubmit(onSubmit)}>
        <div>
          <span className={styles.add_team__label}>Личные данные</span>
          <div className={styles.add_team__form_row}>
            <div className={styles.add_team__form_item}>
              <Controller
                name="team_master_fio"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    type="text"
                    fullWidth
                    label="ФИО"
                    placeholder="Введите ФИО"
                    wrapperClassname={styles.add_team__input_wrapper}
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={data?.body?.team_master_fio || ""}
                rules={{
                  required: true
                }}
              />
              {errors.team_master_fio && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
            <div className={styles.add_team__form_item}>
              <Controller
                name="team_master_doc_num"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    uppercase
                    type="text"
                    fullWidth
                    label="Номер документа"
                    placeholder="Введите номер документа"
                    wrapperClassname={styles.add_team__input_wrapper}
                    value={value}
                    onChange={onChange}
                  />
                )}
                defaultValue={data?.body?.team_master_doc_num || ""}
                rules={{
                  required: true
                }}
              />
              {errors.team_master_doc_num && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
          </div>
          <div className={styles.add_team__form_row}>
            <div className={styles.add_team__form_item}>
              <PhonesInput
                phone_numbers={phonesInfo.phones}
                handleChange={handlePhoneChange}
              />
            </div>
            <div className={styles.add_team__form_item}>
              <Controller
                name="master_type_id"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <AutoSuggest
                    list={master_types}
                    showKey={"master_type_name"}
                    withMargin
                    valueKey={"master_type_id"}
                    value={value}
                    placeholder="Выберите тип"
                    label="Выберите тип"
                    onChange={onChange}
                  />
                )}
                defaultValue={data?.body?.master_type_id || ""}
                rules={{
                  required: true
                }}
              />
              {errors.master_type_id && (
                <InputWarning>Это поле обязательно</InputWarning>
              )}
            </div>
          </div>
          {data?.isTeamMode && isMainMaster && (
            <div className={styles.add_team__form_row}>
              <div className={styles.add_team__form_item}>
                <Controller
                  name="card_num"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CardInput
                      fullWidth
                      label="Номер карты"
                      placeholder="Введите номер карты"
                      wrapperClassname={styles.add_team__input_wrapper}
                      className={styles.add_team__input_phone}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  defaultValue={data?.body?.card_num || ""}
                  rules={{
                    required: false,
                    maxLength: 16,
                    minLength: 16
                  }}
                />
                {errors.card_num && (
                  <InputWarning>
                    {errors.card_num?.type === "required"
                      ? "Это поле обязательно"
                      : "Не корректный номер карты"}
                  </InputWarning>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={styles.add_team__form_row}>
          <div>
            <label htmlFor={"is_main_master_modal_check"}>Бригадир</label>
            <Controller
              name="is_main_master"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  id={"is_main_master_modal_check"}
                  checked={!!value}
                  onChange={onChange}
                />
              )}
              defaultValue={!!data?.body?.is_main_master}
            />
          </div>
          <div className={`${styles.add_team__form_itemCheckbox}`}>
            <label htmlFor={"is_active_modal_check"}>Активный</label>
            <Controller
              name="is_active"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  id={"is_active_modal_check"}
                  checked={!!value}
                  onChange={onChange}
                />
              )}
              defaultValue={
                data?.body?.is_active || data?.body?.is_active !== false
              }
            />
          </div>
        </div>
        <div className={styles.add_team__btn_wrapper}>
          <CButton
            loading={modalLoading}
            disabled={modalLoading}
            type="submit"
            className={styles.add_team__btn}
          >
            Сохранить
          </CButton>
        </div>
      </form>
    </div>
  );
};
