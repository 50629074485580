import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  NativeSelect,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  addSelectedWork,
  deleteAllWorks,
  deleteWorkItem,
  getAllWorks,
  getSelectedWorks,
  getWorksList,
  setWorkColor
} from "./api";

import { SelectedWorks } from "./selectedWorks";
import { WorkListByStages } from "./workListBtStage";

import _ from "lodash";
import { Link } from "react-router-dom";
import { PageTitle } from "../../components/common/pageTitle";
import { CustomSearchBar } from "../../components/common/form/CustomSearchBar";
import { PAGES } from "../../constants";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px;",
    color: "#9194A9"
  },
  button: {
    background: "#FFFFFF",
    boxShadow: "0px 5px 10px rgba(108, 108, 108, 0.1)",
    borderRadius: "4px",
    color: "#FF6262"
  },
  back_btn: {
    backgroundColor: "#0C4B86",
    borderRadius: 4,
    boxShadow: "none",
    color: "#fff"
  }
}));

export const WorkChoosePage = () => {
  const classes = useStyles();

  const [selectedWorks, setSelectedWorks] = useState([]);
  const [workList, setWorkList] = useState([]);
  const [all, setAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterItem, setFilterItem] = useState("Все этапы");
  const [searchItem, setSearchItem] = useState("");

  useEffect(() => {
    setLoading(true);
    getSelectedWorks()
      .then((answer = {}) => {
        setSelectedWorks(answer.data);
        getWorksList()
          .then((answer = {}) => {
            setWorkList(answer.data);
            setLoading(false);
          })
          .catch(() => {});
      })
      .catch(() => {});
  }, []);

  const deleteWork = async id => {
    const params = {
      work_id: id
    };

    await deleteWorkItem(params)
      .then((answer = {}) => {
        setSelectedWorks(answer.data);
      })
      .catch(() => {});
  };

  const setAllWorks = async () => {
    setLoading(true);

    await getAllWorks()
      .then((answer = {}) => {
        setSelectedWorks(answer.data);
        getWorksList()
          .then((answer = {}) => {
            setWorkList(answer.data);
            setLoading(false);
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  const deleteWorks = async () => {
    setAll(false);
    await deleteAllWorks()
      .then((answer = {}) => {
        setSelectedWorks(answer.data);
      })
      .catch(() => {});
  };

  const setColorWork = async (id, color) => {
    const params = {
      work_id: id,
      work_color: color
    };

    await setWorkColor(params)
      .then((answer = {}) => {
        setSelectedWorks(answer.data);
      })
      .catch(() => {});
  };

  const addWorkItem = value => {
    const params = {
      work_id: value
    };
    addSelectedWork(params)
      .then((answer = {}) => {
        setSelectedWorks(answer.data);
      })
      .catch(() => {});
  };

  const handleChange = e => {
    setFilterItem(e.target.value);
  };

  const filterStages = (arr, value) => {
    return _.filter(arr, function(o) {
      return o.stage_contractor_name === value;
    });
  };

  const deepFlterItems = (data, value) => {
    return data.map(obj => {
      const newArray = obj.works.filter(
        el =>
          el.work_name
            .toLowerCase()
            .trim()
            .indexOf(value.toLowerCase().trim()) > -1
      );
      return Object.assign({}, obj, { works: newArray });
    });
  };

  let filteredItems;

  if (filterItem !== "Все этапы") {
    filteredItems = deepFlterItems(
      filterStages(workList, filterItem),
      searchItem
    );
  } else {
    filteredItems = deepFlterItems(workList, searchItem);
  }

  return (
    <>
      <PageTitle title={"Выбор работ по техническим особенностям"} />
      <Box style={{ padding: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <CustomSearchBar
              value={searchItem}
              onChange={value => setSearchItem(value)}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Этапы</InputLabel>
              <NativeSelect
                id="demo-simple-select"
                value={filterItem}
                onChange={handleChange}
              >
                <option value="Все этапы">Все этапы</option>
                <option value="Этап 1">Этап 1</option>
                <option value="Этап 2">Этап 2</option>
                <option value="Этап 3">Этап 3</option>
                <option value="Акт приема-передачи клиенту">
                  Прием-передача клиенту
                </option>
              </NativeSelect>
            </FormControl>
            <Link
              to={PAGES.reportAddWorks}
              style={{
                textDecoration: "none",
                marginLeft: "20px",
                marginTop: "11px",
                display: "inline-block"
              }}
            >
              <Button variant="contained" className={classes.back_btn}>
                Назад
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Box mt={2} mb={2}>
              <Typography variant="h2" className={classes.title}>
                Список работ
              </Typography>
            </Box>
            <WorkListByStages
              data={filteredItems}
              setAllWorks={setAllWorks}
              addWorkItem={addWorkItem}
              deleteWork={deleteWork}
              selectedWorks={selectedWorks}
              all={all}
            />
          </Grid>
          <Grid item xs={7}>
            <Box
              mt={2}
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h2" className={classes.title}>
                Выбранные работы для отображения в отчете тех. особенностей
              </Typography>
              <Button
                size="small"
                onClick={deleteWorks}
                className={classes.button}
              >
                Удалить Все
              </Button>
            </Box>
            <SelectedWorks
              data={selectedWorks}
              deleteWork={deleteWork}
              loading={loading}
              setColorWork={setColorWork}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
