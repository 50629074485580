import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PageTitle } from "../../components/common/pageTitle";
import MainRemontInfo from "../remonts/RemontInfo/MainRemontInfo";
import { fetchRemontInfo } from "../remonts/RemontInfo/services/api";
import { setHeaderBack, showPopUp } from "../../services/redux/actions/app-actions";
import clsx from "clsx";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { useHttp } from "../../utils/hooks/http.hook";
import { MODALS_NAMES, PAGES, PAGES_ROUTE_KEY } from "../../constants";
import { CheckRemontPermission } from "../remonts/CheckRemontPermission";

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: 20
  },
  title: {
    fontSize: 18,
    fontWeight: 600
  },
  link: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    padding: 10,
    height: 38,
    width: 200,
    backgroundColor: "#0C4B86",
    textDecoration: "none",
    color: "#fff",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10,
    "&:not(:last-child)": {
      marginRight: 15
    },
    [theme.breakpoints.down(600)]: {
      width: "100%",
      marginRight: "0 !important"
    }
  },
  not_visible: {
    display: "none"
  }
}));

export const RemontInfo = () => {
  return (
    <CheckRemontPermission>
      <RemontInfoWrapper />
    </CheckRemontPermission>
  );
};

export const RemontInfoWrapper = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { request } = useHttp();
  const dispatch = useDispatch();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const { addDefaultSnack } = useCustomSnackbar();
  const [data, setData] = useState(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const goBack = () => {
      navigate(PAGES.remontList);
    };
    dispatch(setHeaderBack(goBack));
    return () => {
      dispatch(setHeaderBack(null));
    };
  }, [dispatch, navigate]);

  useEffect(() => {
    fetchRemontInfo(remont_id)
      .then(res => {
        setData(res?.data);
      })
      .catch(() => navigate(PAGES.remontList));
  }, [remont_id, navigate]);

  const handleClick = async () => {
    const params = {
      remont_id,
      resident_id: data?.resident_id,
      remont_performing_code: 0,
      stage_code: 0,
      remark_code: 0
    };
    const res = await request(
      "/partner/remont_stage/",
      "post",
      params,
      {},
      { isModal: true }
    );
    if (res) {
      dispatch(showPopUp(false));
      addDefaultSnack("ОКК успешно вызван");
      setIsVisible(false);
    }
  };

  const allowedStageIds = ["3", "4", "5"];

  if (!data) return null;
  return (
    <>
      <PageTitle title="Инфо о ремонте" />
      <Box>
        <MainRemontInfo data={data} isOwnPage />
      </Box>
      <Box className={classes.wrapper}>
        <Box display="flex" flexWrap="wrap">
          <Link
            className={classes.link}
            to={`${PAGES_ROUTE_KEY.remontWorks}/${remont_id}`}
          >
            Работы
          </Link>
          <Link
            className={classes.link}
            to={`${PAGES_ROUTE_KEY.remontRequests}/${remont_id}`}
          >
            Заявки
          </Link>
          <Link
            className={classes.link}
            to={`${PAGES_ROUTE_KEY.remontChat}/${remont_id}`}
          >
            Чат
          </Link>
          <Link
            className={classes.link}
            to={`${PAGES_ROUTE_KEY.remontProjects}/${remont_id}`}
          >
            Проекты
          </Link>
          <Link
            className={classes.link}
            to={`${PAGES_ROUTE_KEY.remontMeasure}/${remont_id}`}
          >
            Замеры
          </Link>
          <Link
            className={classes.link}
            to={`${PAGES_ROUTE_KEY.remontStatuses}/${remont_id}`}
          >
            Статусы
          </Link>
          <Link
            className={classes.link}
            to={`${PAGES_ROUTE_KEY.remontProblems}/${remont_id}`}
          >
            Обращения
          </Link>
          {data?.is_show_finance && (
            <Link
              className={classes.link}
              to={`${PAGES_ROUTE_KEY.remontAccounting}/${remont_id}`}
            >
              Учет
            </Link>
          )}
          {(allowedStageIds.includes(data?.stage_code) &&
            data?.status_code === "DEFECT_EXIST") ||
          (allowedStageIds.includes(data?.stage_code) &&
            data?.status_code === "STAGE_NEW") ? (
            <Box
              component="span"
              className={clsx(classes.link, {
                [classes.not_visible]: isVisible === false
              })}
              onClick={() =>
                dispatch(
                  showPopUp(true, MODALS_NAMES.confirmModal, {
                    onSubmit: handleClick,
                    title: "Вы уверены что хотите вызвать окк?",
                    btnLabel: "Вызвать",
                    type: "default"
                  })
                )
              }
            >
              Вызов Окк
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default RemontInfo;
