import {
  getRemontsBrigadaData,
  getTeamMasterInfo,
  getTeamMastersById,
  getTeamRemonts
} from "../../api";

export const addCostServices = {
  getTeamRemontsData: async team_id => {
    try {
      const res = await getTeamRemonts(team_id);
      return res || [];
    } catch (e) {}
    return [];
  },
  getTeamMastersData: async team_id => {
    try {
      const res = await getTeamMastersById(team_id);
      if (res) {
        const { team_masters, main_master } = res;
        return {
          team_masters,
          team_master_id: main_master?.team_master_id || ""
        };
      }
    } catch (e) {
      return {
        team_masters: [],
        team_master_id: ""
      };
    }
  },
  getRemontsTeamData: async remont_id => {
    try {
      const res = await getRemontsBrigadaData(remont_id);
      if (res) {
        const { team_masters, main_master, team_id } = res;
        return {
          team_masters,
          team_master_id: main_master?.team_master_id || "",
          team_id: team_id || ""
        };
      }
    } catch (e) {
      return {
        team_masters: [],
        team_master_id: "",
        team_id: ""
      };
    }
  },
  onTeamMasterChange: async (team_master_id, isPaymentMode) => {
    if (!team_master_id || !isPaymentMode) return null;
    try {
      const masterInfo = await getTeamMasterInfo(team_master_id);
      return masterInfo || null;
    } catch (e) {
      return null;
    }
  }
};
