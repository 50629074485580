import React, { useState } from "react";
import { MAIN_SYSTEM_URL } from "../../constants";
import { useSelector } from "react-redux";
import styles from './kanban.module.scss'
import { LinearProgress } from "@material-ui/core";

export const KanbanPage = () => {
  const { dataUser } = useSelector(state => state.appState);
  const [isFetching, setIsFetching] = useState(true)

  if (!dataUser?.token)
    return null;
  return <div>
    {isFetching && <LinearProgress />}
    <iframe onLoad={() => setIsFetching(false)} title={'Kanban'} width="100%" height="700px" className={styles.kanban__wrapper}
            src={`${MAIN_SYSTEM_URL}/system/login-url/token/${dataUser?.token}/?redirect_page=/kanban/boards/view_mode/frame/`}></iframe>
  </div>;
};