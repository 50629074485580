import React, { useState } from "react";
import { Box, Button, FormControl, TextareaAutosize } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MessageFile from "./MessageFile";
import { sendMessage } from "./api";
import { useDispatch } from "react-redux";
import { setMessages } from "../../../services/redux/actions/remonts-actions";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  form_wrapper: {
    backgroundColor: "#F4F5F7",
    padding: "15px 17px"
  },
  send_form: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  input: {
    width: "100%",
    margin: "0 14px",
    "& .MuiInputBase-root": {
      height: 32,
      background: "#fff",
      borderRadius: 4
    }
  },
  btn: {
    borderRadius: 4,
    boxShadow: "none",
    padding: 9,
    width: 145,
    height: 32,

    "&:hover": {
      backgroundColor: "#0C4B86",
      boxShadow: "none"
    }
  },
  no_border: {
    border: "none"
  },
  files_wrapper: {
    marginTop: 10,
    display: "flex",
    flexWrap: "wrap"
  }
});

const MessageForm = ({ remont_id }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();

  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const options = {
    onUploadProgress: progressEvent => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setProgress(percent);
    }
  };

  const handleMessageChange = e => {
    setMessage(e.target.value);
  };

  const handleUploadChange = e => {
    const addedFiles = Object.values(e.target.files);
    const allFiles = [...files, ...addedFiles];
    const uniqueFiles = [
      ...new Map(allFiles.map(item => [item["name"], item])).values()
    ];
    setFiles(uniqueFiles);
  };

  const handleRemoveFile = name => {
    const newLocalFiles = files?.filter(el => name !== el.name);
    setFiles(newLocalFiles);
  };

  const handleClick = () => {
    const formData = new FormData();
    formData.append("message", message);
    if (files.length) {
      files.forEach(el => formData.append("files", el));
    }
    if (remont_id && message) {
      setLoading(true);
      sendMessage(remont_id, formData, options)
        .then(res => {
          setLoading(false);
          dispatch(setMessages(res.data));
          setMessage("");
          setFiles([]);
          setProgress(0);
          addDefaultSnack("Сообщение успешно отправлено");
        })
        .catch(err => {
          setLoading(false);
          addCloseSnack(err.response.data.error);
        });
    }
  };

  return (
    <Box className={classes.form_wrapper}>
      <Box className={classes.send_form}>
        <FormControl>
          <Box>
            <label
              htmlFor="upload-files"
              style={{
                fontSize: "14px",
                lineHeight: "15px",
                color: "#000",
                whiteSpace: "nowrap"
              }}
            >
              <input
                style={{
                  display: "none"
                }}
                id="upload-files"
                name="upload-files"
                type="file"
                multiple
                onChange={handleUploadChange}
              />
              <div style={{ cursor: "pointer" }}>
                <Box style={{ transform: "rotate(38deg)" }}>
                  <AttachFileIcon color="primary" />
                </Box>
              </div>
            </label>
          </Box>{" "}
        </FormControl>
        <TextareaAutosize
          value={message}
          onChange={handleMessageChange}
          className={classes.input}
          id="outlined-basic"
          variant="outlined"
          placeholder="Введите сообщение"
          style={{
            border: "none",
            padding: 8,
            borderRadius: 4,
            outline: "none",
            resize: "none"
          }}
        />
        <Button
          className={classes.btn}
          variant="contained" color={'primary'}
          disabled={loading || !message}
          onClick={handleClick}
        >
          Отправить
        </Button>
      </Box>
      {files.length ? (
        <Box className={classes.files_wrapper}>
          {files?.map(el => (
            <MessageFile
              key={el.name}
              el={el}
              handleClick={handleRemoveFile}
              isFormMessage
              progress={progress}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default MessageForm;
