import React, { useEffect, useState } from "react";
import StatusesListItem from "./StatusesListItem";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setStatusesList } from "../../../services/redux/actions/remonts-actions";
import { fetchStatuses } from "./api";
import { CustomTable } from "../../../components/common/CustomTable/CustomTable";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { PAGES_ROUTE_KEY } from "../../../constants";
import { setHeaderBack } from "../../../services/redux/actions/app-actions";
import { useNavigate } from "react-router";

const headOptions = [
  { label: "", align: "left" },
  { label: "", align: "left" },
  { label: "Статус", align: "left" },
  { label: "Дата и время", align: "left" },
  { label: "Фио", align: "left" }
];
const Statuses = ({singleRemont}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addCloseSnack } = useCustomSnackbar();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const { statusesList } = useSelector(state => state.remontsState);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const goBack = () => {
      navigate(`${PAGES_ROUTE_KEY.remontInfo}/${remont_id}`);
    };
    remont_id && singleRemont && dispatch(setHeaderBack(goBack));
    return () => {
      dispatch(setHeaderBack(null));
    };
  }, [dispatch, remont_id, navigate, singleRemont]);

  useEffect(() => {
    if (remont_id) {
      dispatch(setStatusesList([]));
      setIsFetching(true);
      fetchStatuses({ remont_id })
        .then(res => {
          dispatch(setStatusesList(res));
          setIsFetching(false);
        })
        .catch(e => {
          addCloseSnack(e?.response?.data?.error);
          setIsFetching(false);
        });
    }
  }, [remont_id, dispatch, addCloseSnack]);

  return (
    <CustomTable
      headItems={headOptions}
      isFetching={isFetching}
      isEmpty={!statusesList?.length}
    >
      {statusesList?.map((el, i) => (
        <StatusesListItem key={i} {...el} remont_id={remont_id} />
      ))}
    </CustomTable>
  );
};

export default Statuses;
