import React, { useCallback, useEffect, useState } from "react";
import { getCards } from "./api";
import CardList from "./CardList";
import { DragDropContext } from "react-beautiful-dnd";
import ProblemsHeader from "./ProblemsHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  copyColumns,
  setColumns
} from "../../services/redux/actions/problemsActions";
import instance from "../../services/api";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PageTitle } from "../../components/common/pageTitle";
import EmptyOrShow from "../../components/common/EmptyOrShow";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  main_area: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    overflow: "auto",
    height: "80vh"
  }
});

export const Problems = () => {
  document.title = "Обращения";
  const classes = useStyles();
  const dispatch = useDispatch();
  const { addCloseSnack } = useCustomSnackbar();

  const { columns, copyCols } = useSelector(state => state.problemsState);
  const [columnOrder, setColumnOrder] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const [values, setValues] = useState({
    priority_id: null,
    resident_id: null,
    card_type_ids: []
  });

  const triggerFilter = useCallback(() => {
    const params = {
      resident_id: values.resident_id || null,
      priority_id: values?.priority_id || null,
      card_type_ids: values?.card_type_ids || []
    };
    setIsFetching(true);
    getCards(params)
      .then(res => {
        setIsFetching(false);
        dispatch(setColumns(res?.data));
      })
      .catch(e => {
        const message = e?.response?.data?.error;
        message && addCloseSnack(message);
        setIsFetching(false);
      });
  }, [values, dispatch, addCloseSnack]);

  useEffect(() => {
    triggerFilter();
  }, [triggerFilter]);

  useEffect(() => {
    const column = columns?.map(el => el.col_id);
    setColumnOrder(column);
  }, [columns]);

  const onDragEnd = result => {
    const { destination, draggableId, source } = result;

    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const finishCol = columns.find(
      el => el.col_id === Number(destination.droppableId)
    );
    const startCol = columns.find(
      el => el.col_id === Number(source.droppableId)
    );

    if (finishCol.col_id === Number(startCol.col_id)) {
      const newCards = startCol.cards;
      const card = newCards.find(el => el.card_id === Number(draggableId));

      newCards.splice(source.index, 1);
      newCards.splice(destination.index, 0, card);

      dispatch(setColumns(columns));
    }

    if (finishCol.col_id !== startCol.col_id) {
      const startCards = startCol.cards;
      const finishCards = finishCol.cards;

      const card = startCards.find(el => el.card_id === Number(draggableId));

      finishCards.splice(destination.index, 0, card);
      startCards.splice(source.index, 1);

      dispatch(setColumns(columns));
    }

    //backend request
    const prevCard = finishCol.cards[destination.index - 1]
      ? finishCol.cards[destination.index - 1].card_id
      : null;
    const nextCard = finishCol.cards[destination.index]
      ? finishCol.cards[destination.index].card_id
      : null;
    const params = {
      from_col_id: +source.droppableId,
      to_col_id: +destination.droppableId,
      current_card_id: +draggableId,
      next_card_id: nextCard,
      prev_card_id: prevCard
    };

    instance(true)
      .post(`/partner/problems/cards/move/`, params)
      .then((res = {}) => {
        dispatch(setColumns(res.data));
      })
      .catch(err => {
        dispatch(setColumns(copyCols));
        addCloseSnack(err.response?.data?.error);
      });
  };

  const onDragStart = () => {
    const columnsStateCopy = [...columns];
    dispatch(copyColumns(columnsStateCopy));
  };
  return (
    <div>
      <PageTitle title="Обращения" />
      <div style={{ padding: 20 }}>
        <ProblemsHeader setValues={setValues} values={values} />
        <EmptyOrShow loading={isFetching}>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Box className={classes.main_area}>
              {columnOrder.map(el => {
                const column = columns.find(elem => el === elem.col_id);
                return <CardList key={el} column={column} />;
              })}
            </Box>
          </DragDropContext>
        </EmptyOrShow>
      </div>
    </div>
  );
};
