export const serviceStatuses = {
  WAITING: "WAITING",
  DONE: "DONE",
  PROCESSING: "PROCESSING"
};

export const serviceStatusLabels = {
  [serviceStatuses.WAITING]: "Ожидает вызова",
  [serviceStatuses.DONE]: "Завершен",
  [serviceStatuses.PROCESSING]: "В процессе"
};
export const serviceStatusOptions = [
    { value: 'ALL', label: "Все" },
    { value: serviceStatuses.WAITING, label: "Ожидает вызова" },
    { value: serviceStatuses.DONE, label: "Завершен" },
    { value: serviceStatuses.PROCESSING, label: "В процессе" }
  ];

export const getStageName = stage_id => {
  switch (stage_id) {
    case 0:
      return "Нет этапа";
    case 1:
      return "Встреча на объекте";
    case 2:
      return "Проект ремонта";
    case 3:
      return "1 этап";
    case 4:
      return "2 этап";
    case 5:
      return "3 этап";
    case 6:
      return "4 этап";
    default:
      return;
  }
};
