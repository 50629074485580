import React, { useEffect, useState } from "react";
import AddWorksTable, { useStyles } from "./AddWorksTable";
import { getAdditionalWorks } from "./api";
import { Box } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { CustomLoader } from "../../../components/common/CustomLoader";
import { CustomSearch } from "../../../components/common/form/CustomSearch";

const AdditionalWorks = () => {
  const classes = useStyles();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [data, setData] = useState([]);
  const [posCode, setPosCode] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAdditionalWorks(remont_id)
      .then(res => {
        setLoading(false);
        setData(res?.ds);
        setPosCode(res?.position_code);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [remont_id]);

  if (!data?.length && !loading) {
    return (
      <h2 style={{ color: "#aabbcc", textAlign: "center" }}>
        {" "}
        По данному ремонту нет утвержденных доп работ
      </h2>
    );
  }

  const handleChange = e => setSearchTerm(e.target.value);
  return (
    <div style={{ marginBottom: 20 }}>
      <Box className={classes.search_wrapper}>
        <CustomSearch value={searchTerm} handleChange={handleChange} />
      </Box>
      {loading ? (
        <CustomLoader />
      ) : (
        data?.map(el => {
          return (
            <AddWorksTable
              key={el.ds_id}
              {...el}
              posCode={posCode}
              searchTerm={searchTerm}
            />
          );
        })
      )}
    </div>
  );
};

export default AdditionalWorks;
