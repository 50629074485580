import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showPopUp, setLimits } from "../../services/redux/actions/app-actions";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";

import { PageTitle } from "../../components/common/pageTitle";
import NumberFormat from "react-number-format";
import EmptyOrShow from "../../components/common/EmptyOrShow";
import { getLimits } from "./api";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { MODALS_NAMES } from "../../constants";

const headTitle = [
  "ID",
  "Подрядчик",
  "Город",
  "План",
  "Факт",
  "План+факт",
  "Перелимит",
  "Лимит"
];

const useStyles = makeStyles({
  table_row: {
    borderLeft: "1px solid #F0F2F6 !important"
  },
  cell: {
    fontSize: 12,
    lineHeight: "16px",
    borderRight: "1px solid #F0F2F6 !important",
    borderBottom: "1px solid #F0F2F6 !important",
    padding: 5
  },
  head_cell: {
    fontWeight: 600,
    color: "#696C7C",
    backgroundColor: "#ECEEFD"
  },
  limit_cell: {
    "& svg": {
      width: 18,
      height: 18
    }
  },
  red_bg: {
    color: "#fff",
    backgroundColor: "#FF6262 !important"
  }
});

const ReportLimit = () => {
  document.title = "Отчет по лимитам";
  const dispatch = useDispatch();
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const [loading, setLoading] = useState(true);
  const { limitsData } = useSelector(state => state.appState);

  useEffect(() => {
    setLoading(true);
    getLimits()
      .then(res => {
        setLoading(false);
        setLimits(res);
        dispatch(setLimits(res));
      })
      .catch(e => {
        addCloseSnack(e?.response?.data?.error);
        setLoading(false);
      });
  }, [dispatch, addCloseSnack]);

  return (
    <>
      <PageTitle title="Отчет по лимитам" />
      <Box sx={{ padding: 20 }}>
        <EmptyOrShow loading={loading}>
          <TableContainer>
            <Table aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {headTitle.map((el, i) => {
                    return (
                      <TableCell
                        key={i}
                        align="left"
                        className={clsx(classes.cell, classes.head_cell)}
                      >
                        {el}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {limitsData?.map(el => {
                  return (
                    <TableRow
                      key={el.contractor_id}
                      className={classes.table_row}
                    >
                      <TableCell align="right" className={classes.cell}>
                        {el.contractor_id}
                      </TableCell>
                      <TableCell align="left" className={classes.cell}>
                        {el.contractor_name}
                      </TableCell>
                      <TableCell align="left" className={classes.cell}>
                        {el.city_name}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={clsx(classes.cell, {
                          [classes.red_bg]: el.plan_amount > el.contractor_limit
                        })}
                      >
                        <NumberFormat
                          value={el.plan_amount}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                        />
                      </TableCell>
                      <TableCell align="right" className={classes.cell}>
                        <NumberFormat
                          value={el.payment_amount}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                        />
                      </TableCell>
                      <TableCell align="right" className={classes.cell}>
                        <NumberFormat
                          value={el.payment_amount + el.plan_amount}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                        />
                      </TableCell>
                      <TableCell align="right" className={classes.cell}>
                        <NumberFormat
                          value={
                            el.limit_sum - (el.payment_amount + el.plan_amount)
                          }
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                        />
                      </TableCell>
                      <TableCell
                        align="right"
                        className={clsx(classes.cell, classes.limit_cell)}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          sx={{ gap: "5px" }}
                        >
                          <Typography>
                            <NumberFormat
                              value={el.limit_sum}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                            />
                          </Typography>
                          <Tooltip title="Изменить лимит">
                            <IconButton
                              size="small"
                              onClick={() =>
                                dispatch(
                                  showPopUp(true, MODALS_NAMES.changeLimit, {
                                    id: el.contractor_id,
                                    name: el.contractor_name,
                                    limit: el.limit_sum
                                  })
                                )
                              }
                            >
                              <CreateIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </EmptyOrShow>
      </Box>
    </>
  );
};

export default ReportLimit;
