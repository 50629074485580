import React, { useState } from "react";
import styles from "./filter.module.scss";
import { useSelector } from "react-redux";
import { AutoSuggest } from "../../../../../common/form/AutoSuggest";
import { CustomDatePicker } from "../../../../../common/form/CustomDatePicker";
import { Checkbox } from "@material-ui/core";
import { InputSumMask } from "../../../../../common/form/InputSumMask";

const defaultFiltersList = {
  date_create_begin: "",
  date_create_end: "",
  date_start_begin: "",
  date_start_end: "",
  team_id: "",
  debt_type_id: "",
  is_finished: "",
  percent_type: ""
};
export const DebtFilter = ({
  showTeamFilter = true,
  onFilterChange,
  defaultFilters = {}
}) => {
  const { teams } = useSelector(state => state.appState);
  const { debtTypes } = useSelector(state => state.accountingState);
  const [filters, setFilters] = useState({ ...defaultFiltersList, ...defaultFilters });
  const [lastSubmitSumValue, setLastSubmitSumValue] = useState({});

  const onChange = (key, value, triggerFilterChange = true) => {
    const getValue = (value, filters) =>
      value || (value === false ? (filters[key] === false ? "" : false) : "");
    setFilters(prev => ({
      ...prev,
      [key]: getValue(value, prev)
    }));
    return (
      triggerFilterChange &&
      onFilterChange &&
      onFilterChange({ ...filters, [key]: getValue(value, filters) })
    );
  };

  const handleSumChange = key => {
    if (
      filters[key] === lastSubmitSumValue[key] ||
      (!filters[key] && lastSubmitSumValue[key] === undefined)
    )
      return;
    onChange(key, filters[key]);
    setLastSubmitSumValue({ [key]: filters[key] });
  };
  return (
    <div className={styles.filter}>
      <div className={styles.filter__item}>
        <CustomDatePicker
          type="text"
          fullWidth
          absoluteMessage
          placeholder="Дата создания от"
          value={filters?.date_create_begin}
          onChange={e => onChange("date_create_begin", e.target.value)}
        />
      </div>
      <div className={styles.filter__item}>
        <CustomDatePicker
          type="text"
          fullWidth
          absoluteMessage
          placeholder="Дата создания до"
          value={filters?.date_create_end}
          onChange={e => onChange("date_create_end", e.target.value)}
        />
      </div>
      <div className={styles.filter__item}>
        <CustomDatePicker
          type="text"
          fullWidth
          absoluteMessage
          placeholder="Дата начала списаний от"
          value={filters?.date_start_begin}
          onChange={e => onChange("date_start_begin", e.target.value)}
        />
      </div>
      <div className={styles.filter__item}>
        <CustomDatePicker
          type="text"
          fullWidth
          absoluteMessage
          placeholder="Дата начала списаний до"
          value={filters?.date_start_end}
          onChange={e => onChange("date_start_end", e.target.value)}
        />
      </div>
      {debtTypes && (
        <div className={`${styles.filter__item}`}>
          <AutoSuggest
            list={debtTypes}
            showKey={"debt_type_name"}
            valueKey={"debt_type_id"}
            value={filters?.debt_type_id}
            onChange={e => onChange("debt_type_id", e.target.value)}
            placeholder="Выберите тип долга"
            label="Выберите тип долга"
            defaultValue={""}
          />
        </div>
      )}
      {teams && showTeamFilter && (
        <div className={`${styles.filter__item}`}>
          <AutoSuggest
            list={teams}
            showKey={"team_name"}
            valueKey={"team_id"}
            value={filters?.team_id}
            onChange={e => onChange("team_id", e.target.value)}
            placeholder="Выберите бригаду"
            label="Выберите бригаду"
            defaultValue={""}
          />
        </div>
      )}
      <div className={`${styles.filter__item}`}>
        <AutoSuggest
          list={percentTypes}
          showKey={"label"}
          withMargin
          valueKey={"id"}
          value={filters.percent_type}
          placeholder="Выберите тип списания"
          label="Выберите тип списания"
          onChange={e => onChange("percent_type", e.target.value)}
        />
      </div>
      <div className={`${styles.filter__item} ${styles.filter__item_sum}`}>
        <InputSumMask
          type="number"
          min={0}
          label="Сумма от"
          placeholder="Введите сумму от"
          value={filters.debt_sum_from}
          onChange={e => onChange("debt_sum_from", e.target.value, false)}
          onEnter={() => handleSumChange("debt_sum_from")}
          onBlur={() => handleSumChange("debt_sum_from")}
        />
        <span />
        <InputSumMask
          type="number"
          min={0}
          label="Сумма до"
          placeholder="Введите сумму до"
          value={filters.debt_sum_to}
          onChange={e => onChange("debt_sum_to", e.target.value, false)}
          onEnter={() => handleSumChange("debt_sum_to")}
          onBlur={() => handleSumChange("debt_sum_to")}
        />
      </div>
      <div className={`${styles.filter__item}`}>
        <label htmlFor="debts_checkbox">Списан</label>
        <Checkbox
          id={"debts_checkbox"}
          indeterminate={filters.is_finished === false}
          checked={filters.is_finished === true}
          onChange={e => {
            const checked = e.target.checked;
            if (filters?.is_finished === false && !!checked) {
              return onChange("is_finished", false);
            }
            onChange("is_finished", checked);
          }}
        />
      </div>
    </div>
  );
};

const percentTypes = [
  { id: 1, label: "От суммы платежа" },
  { id: 2, label: "От суммы долга" }
];
