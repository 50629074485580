import React, { useState } from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { format } from "date-fns";
import clsx from "clsx";
import { deliverClient } from "./api";
import { setOkkData } from "../../services/redux/actions/app-actions";
import { useDispatch } from "react-redux";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  wrapper: {
    width: 350,
    height: 191
  },
  header: {
    fontWeight: 500,
    color: "#000"
  },
  picker: {
    width: "45%"
  },
  btn: {
    width: "48%",
    borderRadius: 4,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none"
    }
  },
  btn_filled: {
    backgroundColor: "#0C4B86",
    "&:hover": {
      backgroundColor: "#0C4B86"
    }
  },
  text: {
    fontSize: 17
  },
  icon: {
    cursor: "pointer",
    color: "#0C4B86"
  }
});

const DeliveryModal = ({ data, handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [date, setDate] = useState(format(new Date(), "dd.MM.yyyy"));
  const [time, setTime] = useState(format(new Date(), "HH:mm"));
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const handleDateChange = (e, v) => {
    setDate(v);
  };
  const handleTimeChange = (e, v) => {
    setTime(v);
  };

  const handleClick = async () => {
    const { remont_id } = data;
    const params = {
      receive_date: `${date} ${time}`
    };
    if (remont_id) {
      try {
        const { data } = await deliverClient(remont_id, params);
        dispatch(setOkkData(data?.data));
        handleClose();
        addDefaultSnack("Успешно");
      } catch (err) {
        addCloseSnack(err?.response?.data?.error);
      }
    }
  };

  return (
    <Box className={classes.wrapper}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Typography className={classes.text}>Дата сдачи клиенту</Typography>
        <CloseIcon className={classes.icon} onClick={handleClose} />
      </Box>
      <Box
        mt={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <KeyboardDatePicker
            className={classes.picker}
            disableToolbar
            variant="inline"
            label="Дата встречи"
            format="dd.MM.yyyy"
            id="date-picker-inline"
            inputValue={date}
            onChange={handleDateChange}
            autoOk={true}
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            invalidDateMessage="Укажите дату"
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <KeyboardTimePicker
            className={classes.picker}
            ampm={false}
            variant="inline"
            inputValue={time}
            onChange={handleTimeChange}
            label="Время встречи"
            KeyboardButtonProps={{ disabled: true, style: { display: "none" } }}
            format="HH:mm"
            invalidDateMessage="Укажите время"
          />
        </MuiPickersUtilsProvider>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={3}
      >
        <Button
          variant="outlined"
          color="primary"
          className={classes.btn}
          onClick={handleClose}
        >
          Закрыть
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          className={clsx(classes.btn, classes.btn_filled)}
        >
          Сохранить
        </Button>
      </Box>
    </Box>
  );
};

export default DeliveryModal;
