import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NotFound } from "../text";
import clsx from "clsx";
import { CustomLoader } from "../CustomLoader";
import { WrapperScrollbar } from "../WrapperScrollbar";
import { UpdateTableHeader } from "../blocks";
import { getTableMaxHeight } from "../../../utils";

export const CustomTable = ({
                              headItems = [],
                              isEmpty = false,
                              isFetching = false,
                              rowsData = null,
                              footer = null,
                              withMaxHeight = true,
                              maxHeight = null,
                              wrapperClassName = "",
                              containerClassName = "",
                              noDataText = "Ничего не найдено",
                              children,
                              headCellClassName = "",
                              headerRender = null,
                              stickyHeader = false,
                              updateDataReq,
                              updateBtnSide = "",
                              highlight = false
                            }) => {
  const classes = useStyles({ maxHeight: stickyHeader ? getTableMaxHeight(maxHeight) : 'auto' });

  return (
    <div
      className={clsx(
        wrapperClassName,
        containerClassName,
        footer ? classes.containerFooter : ""
      )}
    >
      <WrapperScrollbar
        className={clsx(
          classes.container,
          containerClassName,
          { [classes.tableContainer]: !!withMaxHeight && !isEmpty },
          { [classes.tableFooter]: footer },
          { [classes.containerFooterHeight]: footer }
        )}
      >
        <Table
          aria-label="simple table"
          stickyHeader={stickyHeader}
          classes={{ root: clsx({ [classes.table]: highlight }) }}
        >
          <TableHead className={classes.headRow}>
            {!!headerRender && headerRender()}
            {!!updateDataReq &&
              <UpdateTableHeader side={updateBtnSide} onClick={updateDataReq} isFetching={isFetching} />}
            <TableRow>
              {headItems?.map((item, i) => {
                return (
                  <TableCell
                    key={i}
                    classes={{
                      stickyHeader: !!headerRender ? classes.stickyHeader : ""
                    }}
                    className={clsx(
                      classes.headCell,
                      headCellClassName,
                      item?.className || ""
                    )}
                    style={item.style ? item.style : {}}
                    align={item?.align || "left"}
                  >
                    {!!item?.render ? (
                      <div className={classes.headText}>{item.render()}</div>
                    ) : (
                      <HeadItemText>{item.label || ""}</HeadItemText>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          {!isFetching && (children ? <TableBody>{children}</TableBody> : <>
            {
              rowsData?.map((item, i) => {
                return <CustomTableRow key={i}>
                  {
                    headItems.map((headItem, idx) => {

                      return <CustomTableCell
                        align={headItem.align || 'left'}
                        key={idx}
                      >
                        {headItem.format ? headItem.format(item[headItem.key]) : (headItem.renderContent ? headItem.renderContent(item) : item[headItem.key])}
                      </CustomTableCell>
                    })
                  }
                </CustomTableRow>;
              })
            }
          </>)}
        </Table>
        {isFetching ? (
          <CustomLoader />
        ) : (
          isEmpty && <NotFound>{noDataText}</NotFound>
        )}
      </WrapperScrollbar>
      {!!footer && (
        <div className={classes.footer}>
          <TableRow>
            <TableCell align={"left"}>{"Всего"}</TableCell>
            <TableCell align={"right"}>{"20000"}</TableCell>
          </TableRow>
        </div>
      )}
    </div>
  );
};

export const CustomTableRow = ({
                                 children,
                                 className,
                                 fixedDirection = "bottom",
                                 fixed = false,
                                 isBinaryDiv = true,
                                 highlight = false,
                                 isNew = false,
                                 ...other
                               }) => {
  const classes = useStyles();
  return (
    <TableRow
      className={clsx(
        classes.row,
        { [classes.new_row]: !!isNew },
        {
          [`${classes.fixedRow} ${classes[`fixed_${fixedDirection}`]}`]: fixed
        },
        { [classes.highlightRow]: highlight },
        { [classes.classicDiv]: isBinaryDiv },
        { [classes.alternativeDiv]: isBinaryDiv === false },
        className
      )}
      {...other}
    >
      {children}
    </TableRow>
  );
};

export const CustomTableCell = ({
                                  children,
                                  className,
                                  openTab,
                                  align,
                                  fixed = false,
                                  ...other
                                }) => {
  const classes = useStyles();
  return (
    <TableCell
      align={align || "left"}
      className={clsx(
        classes.border,
        { [classes.fixedCell]: fixed },
        { [classes.borderless]: openTab === false },
        className
      )}
      {...other}
    >
      {children}
    </TableCell>
  );
};

export const HeadItemText = ({ children }) => (
  <span className={useStyles().headText}>{children}</span>
);

const useStyles = makeStyles({
  headCell: {
    padding: "5px !important",
    borderLeft: "1px solid #F0F2F6",
    backgroundColor: "#ECEEFD !important",
    lineHeight: "16px"
  },
  table: {
    borderCollapse: "collapse"
  },
  headText: {
    color: "#696C7C",
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "16px"
  },
  tableContainer: {
    maxHeight: props => `${props.maxHeight}px`
  },
  container: {
    width: "100%",
    position: "relative",
    overflowY: "auto",
    overflowX: "auto !important",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
    borderRadius: "0.5rem",
    color: "rgba(0, 0, 0, 0.87)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#fff"
  },
  containerFooterHeight: {
    maxHeight: "calc(100vh - 230px) !important"
  },
  containerFooter: {
    borderRadius: "0.5rem",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
  },
  headRow: {
    backgroundColor: "#ECEEFD"
  },
  root: {
    marginBottom: 15
  },
  border: {
    border: "1px solid #F0F2F6",
    padding: "2px 5px"
  },
  borderless: {
    padding: "0 5px"
  },
  cursor: {
    cursor: "pointer"
  },
  row: {
    "&:hover": {
      backgroundColor: "#ececec"
    }
  },
  classicDiv: {
    "&:nth-child(odd)": {
      backgroundColor: "#f9f9f9",
      "&:hover": {
        backgroundColor: "#ececec"
      }
    }
  },
  alternativeDiv: {
    backgroundColor: "#ffffff",
    "&:nth-child(4n - 1)": {
      backgroundColor: "#f9f9f9",
      "&:hover": {
        backgroundColor: "#ececec"
      }
    }
  },
  footer: {
    border: "1px solid #F0F2F6",
    padding: "5px",
    borderRadius: "0 0 0.5rem 0.5rem"
  },
  tableFooter: {
    borderRadius: "0.5rem 0.5rem 0 0",
    boxShadow: "none"
  },
  fixedRow: {
    position: "sticky",
    backgroundColor: "#ECEEFD !important",
    "&:hover": {
      backgroundColor: "#ECEEFD !important"
    }
  },
  fixed_top: {
    top: 0
  },
  fixed_bottom: {
    bottom: 0
  },
  fixedCell: {
    fontWeight: "700 !important"
  },
  highlightRow: {
    borderBottom: "2px solid #ccc !important"
  },
  stickyHeader: {
    top: "45px"
  },
  new_row: {
    backgroundColor: "#a6e081 !important"
  }
});
