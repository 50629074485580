import React, { useState } from "react";
import styles from "./filter.module.scss";
import { CustomDatePicker } from "../../../common/form/CustomDatePicker";
import { CButton } from "../../../common/buttons";
import { InputSumMask } from "../../../common/form/InputSumMask";
import { useEffect } from "react";
import { getCostStatuses } from "../../../../services/redux/actions/app-actions";
import { useDispatch, useSelector } from "react-redux";
import { AutoSuggest } from "../../../common/form/AutoSuggest";

export const BrigadirCostFilters = ({
                                      handleSubmit, defaultValues = {}
                                    }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    date_of_begin: "",
    date_of_end: "",
    remont_id: "", ...defaultValues
  });
  const [loading, setLoading] = useState(false);
  const { costStatuses } = useSelector(state => state.appState);

  useEffect(() => {
    dispatch(getCostStatuses());
  }, [dispatch]);

  const onChange = (key, value) => {
    setFilters(prev => ({ ...prev, [key]: value || null }));
  };

  const onSubmit = async () => {
    setLoading(true);
    handleSubmit &&
    await handleSubmit(filters);
    setLoading(false);
  };

  return (
    <div className={styles.filter}>
      <div className={styles.filter__item}>
        <CustomDatePicker
          type="text"
          fullWidth
          absoluteMessage
          placeholder="Дата от"
          value={filters?.date_of_begin}
          onChange={e => onChange("date_of_begin", e.target.value)}
        />
      </div>
      <div className={styles.filter__item}>
        <CustomDatePicker
          type="text"
          fullWidth
          absoluteMessage
          placeholder="Дата до"
          value={filters?.date_of_end}
          onChange={e => onChange("date_of_end", e.target.value)}
        />
      </div>
      <div className={styles.filter__item}>
        <InputSumMask
          type="number"
          fullWidth integer mask={false}
          min={0}
          name={"remont_id"}
          onChange={e => onChange("remont_id", e.target.value)}
          label="ID ремонта"
          placeholder="Введите ID ремонта"
          value={filters.remont_id}
          onEnter={onSubmit}
        />
      </div>
      <div className={styles.filter__item}>
        <AutoSuggest
          list={costStatuses}
          showKey={"contractor_cost_status_name"}
          valueKey={"contractor_cost_status_id"}
          value={filters?.contractor_cost_status_id}
          onChange={e =>
            onChange("contractor_cost_status_id", e.target.value)
          }
          placeholder="Выберите статус"
          label="Статус"
          defaultValue={""}
        />
      </div>
      <CButton mode={"auto"} loading={loading} onClick={onSubmit}>
        Поиск
      </CButton>
    </div>
  );
};
