import { setPayboxBalance } from "../../../../services/redux/actions/accounting-actions";
import {
  getCost,
  getPayboxBalance,
  getTeamDetailCosts,
  getTeamMasters,
  getTeamRemonts
} from "../api";

export const isPaymentType = (cost_type_id, costPaymentTypes) => {
  try {
    if (!cost_type_id || !costPaymentTypes?.length) return false;
    return !!costPaymentTypes.find(
      item => item?.contractor_cost_type_id === Number(cost_type_id)
    );
  } catch (e) {
    return false;
  }
};

export const getPayboxBalanceData = (
  remont_id,
  setFetchingBalance
) => async dispatch => {
  try {
    setFetchingBalance(true);
    const res = await getPayboxBalance(remont_id);
    setFetchingBalance(false);
    res?.status === "ok" && dispatch(setPayboxBalance({ ...res }));
  } catch (e) {
    dispatch(setPayboxBalance({ balance: 0 }));
    setFetchingBalance(false);
  }
};

export const getCostDataService = async (team_id, filters, setIsFetching) => {
  try {
    setIsFetching(true);
    const res = team_id
      ? await getTeamDetailCosts(team_id, filters || {})
      : await getCost(filters || {});
    setIsFetching(false);
    return res;
  } catch (e) {
    setIsFetching(false);
  }
};

export const getRemontsDataService = async team_id => {
  if (!team_id) return null;
  try {
    const res = await getTeamRemonts(team_id);
    return res || null;
  } catch (e) {
    return null;
  }
};
export const getMastersDataService = async team_id => {
  if (!team_id) return null;
  try {
    const res = await getTeamMasters(team_id);
    return res || null;
  } catch (e) {
    return null;
  }
};

export const getBodyWithType = (data, isRemontMode, isTeamMode) => {
  const body = { ...data };
  body.type = "";
  if (isRemontMode) {
    body.type = "remont";
  } else if (isTeamMode) {
    body.type = "brigada";
  }
  return body;
};
