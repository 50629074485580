import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setHeader } from "../../services/redux/actions/app-actions";
import { PAGES } from "../../constants";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router";
import { getJKList } from "./api";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import DownloadXlsx from "../../components/common/DownloadXlsx";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { CollapseBlock } from "../../components/common/CollapseBlock/collapseBlock";

const useStyles = makeStyles(() => ({
  select: {
    width: 300,
    float: "left",
    marginRight: 5,
    maxHeight: 60,
    overflowY: "auto",
    overflowX: "hidden",
    "& .MuiFormControl-root": {
      width: "100%"
    }
  },
  button: {
    backgroundColor: "#0C4B86",
    color: "#ffffff",
    borderRadius: 4
  },
  wrapper: { display: "flex", gap: "12px", flexWrap: 'wrap', alignItems: "flex-end" }
}));

const ReportFilter = ({ handleChange }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const classes = useStyles();

  const [requestedResidents, setRequestedResidents] = useState([]);
  const [residentIds, setResidentIds] = useState([]);

  const handleAcceptFilterFields = e => {
    const resident_ids = e.target.value;
    setResidentIds(resident_ids);
    handleChange(resident_ids);
  };

  useEffect(() => {
    getJKList()
      .then((answer = {}) => {
        setRequestedResidents(answer.data.data);
      })
      .catch(() => {
        history(PAGES.reportCostPayment);
      });

    dispatch(
      setHeader({
        isList: true,
        showBack: false,
        showCancel: true
      })
    );
  }, [dispatch, history]);
  return (
    <CollapseBlock tabletMode className={classes.wrapper}>
      <div className={classes.select}>
        <AutoSuggest
          list={requestedResidents}
          value={residentIds}
          showKey={"resident_name"}
          valueKey={"resident_id"}
          name={"resident_id"}
          placeholder="Выберите ЖК"
          label="Жилой комплекс"
          multiple
          onChange={handleAcceptFilterFields}
        />
      </div>

      <Link
        style={{ color: "#fff", textDecoration: "none", whiteSpace: "no-wrap" }}
        to={PAGES.workChoosePage}
      >
        <Button className={classes.button} style={{ whiteSpace: "no-wrap" }}>
          Настройки
        </Button>
      </Link>
      <DownloadXlsx
        url="/partner/tech_features/xlsx/"
        params={{
          resident_ids: residentIds
        }} withoutMargin
        title="Отчет по тех особенностям"
      />
    </CollapseBlock>
  );
};

export default ReportFilter;
