import { Box, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "../index";
import clsx from "clsx";

export const BlockItem = ({title, children, titleInline}) => {
  const classes = useStyles();

  return <Box className={clsx([classes.mr, {[classes.titleInline]: titleInline}])}>
    <Typography className={classes.title}>{title}</Typography>
    {!!children && <Typography className={classes.subtitle}>{children}</Typography>}
  </Box>
}