import {
  COPY_COLUMNS, RESET_PROBLEMS_STATE,
  SET_CARD_ID,
  SET_CARD_TYPES,
  SET_COLUMNS,
  SET_PRIORITIES,
  SET_RESIDENTS_FOR_FILTER,
  SET_SEARCH_TERM,
  SET_SIDEBAR_CONTENT,
  TOGGLE_DRAWER
} from "../actions/problemsActions";

const initialState = {
  columns: [],
  copyCols: [],
  cardId: null,
  searchTerm: "",
  sideBarContent: "Меню",
  isDrawerOpen: false,
  card_id: null,
  priorities: null,
  cardTypes: null,
  residentsForFilter: null
};
export const problemsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COLUMNS:
      return { ...state, columns: payload };
    case COPY_COLUMNS:
      return { ...state, copyCols: payload };
    case SET_CARD_ID:
      return { ...state, cardId: payload };
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: payload };
    case SET_SIDEBAR_CONTENT:
      return {
        ...state,
        sideBarContent: payload.type,
        card_id: payload.card_id
      };
    case TOGGLE_DRAWER:
      return { ...state, isDrawerOpen: payload };
    case SET_CARD_TYPES:
      return { ...state, cardTypes: payload };
    case SET_PRIORITIES:
      return { ...state, priorities: payload };
    case SET_RESIDENTS_FOR_FILTER:
      return { ...state, residentsForFilter: payload };
    case RESET_PROBLEMS_STATE:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
