import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "./menu";
import { DRAWER_WIDTH } from "../../constants";
import { useSelector } from "react-redux";
import { Header } from "./header";

const windowWidth = window?.innerWidth;
const isMobileDevice = windowWidth <= 768;

export const MainLayout = ({ children }) => {
  const classes = useStyles();
  const { isMainMenuOpen } = useSelector(state => state.appState);

  return (
    <div>
      <Header />
      <div className={classes.root}>
        <Menu />
        <main
          className={classes.content}
          style={{
            width: isMainMenuOpen
              ? isMobileDevice
                ? "100%"
                : `calc(100% - ${DRAWER_WIDTH}px`
              : "100%"
          }}
        >
          {children}
        </main>
      </div>
    </div>
  );
};

export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  paper: {
    overflowX: "hidden"
  },
  menuButton: {
    padding: 5
  },
  drawerContent: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
  },
  hide: {
    display: "none"
  },
  listWrapper: { padding: "8px 16px", flexGrow: 1, overflowY: "auto" },
  list: { padding: 0 },
  drawer: {
    width: DRAWER_WIDTH,
    position: "relative",
    overflowX: "hidden"
  },
  drawerOpen: {
    width: props => props.isReactNative ? '100vw' : DRAWER_WIDTH,
    transition: "width .1s linear",
    [theme.breakpoints.down(768)]: {
      width: '100vw !important'
    }
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(8) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    },
    [theme.breakpoints.down(768)]: {
      width: 0
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1, 2),
    ...theme.mixins.toolbar,
    minHeight: "60px",
    [theme.breakpoints.down(576)]: {
      minHeight: "64px"
    },

    "& a": {
      display: 'flex'
    }
  },
  content: {
    flexGrow: 1
  },
  toolbarOpen: {
    flexDirection: "row"
  },
  toolbarClose: {
    flexDirection: "column",
    padding: "20px 0 0 0"
  },
  listItemSolo: {
    gap: 20
  },
  listItem: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.04em",
    color: "#9194A9",
    textDecoration: "none",
    height: 45,
    "& li": {
      height: 45
    }
  },
  active: {
    color: "#0C4B86"
  },
  itemIconCollapsed: { display: "flex", justifyContent: "center" },
  ml: { marginRight: "0" },
  mr: {
    marginRight: "-20px",
    [theme.breakpoints.down(760)]: {
      marginRight: 0
    }
  }
}));
