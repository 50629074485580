import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//Всплывашки сбоку
import { PAGES, PAGES_ROUTE_KEY } from "../../constants";
//Actions
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { PlaceholderImg } from "../../components/common/blocks/PlaceholderImg";
import { TabPanel as CustomTabPanel } from "../../components/common/CustomTabs";
import { RemontWrapper } from "../../components/common/RemontWrapper";
import { WrapperScrollbar } from "../../components/common/WrapperScrollbar";
import { setHeaderBack } from "../../services/redux/actions/app-actions";
import {
  setMasterList,
  showRightDrawerAction,
} from "../../services/redux/actions/remonts-actions";
import AdditionalTab from "./AdditionTab";
import { CheckRemontPermission } from "./CheckRemontPermission";
import { ComplexesList } from "./complexesList/complexesList";
import { fetchRemontInfo } from "./RemontInfo/services/api";
import RemontsHeader from "./RemontsHeader";
import { remontTabComponents, remontTabKeys } from "./services";
import { getMastersList } from "./services/api";
import { getRemontTabulationsData } from "./RemontInfo/services";

const DEFAULT_OPEN_TAB = 0;

export const RemontPage = ({ isSingleRemont }) => {
  return (
    <CheckRemontPermission>
      <RemontPageWrapper isSingleRemont={isSingleRemont} />
    </CheckRemontPermission>
  );
};
const RemontPageWrapper = ({ isSingleRemont }) => {
  document.title = "Ремонты";
  const dispatch = useDispatch();
  const router = useNavigate();
  const classes = useStyles();
  const { remont_id: remont_id_str, remont_status } = useParams();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const remont_id = Number(remont_id_str);

  const { resident } = useSelector((state) => state.remontsState);
  const [value, setValue] = useState(
    Number(query?.get("active")) || DEFAULT_OPEN_TAB
  );

  const changeActiveTab = useCallback((value) => {
    setValue(value);
  }, []);

  const handleChange = (_, newValue) => {
    setValue(newValue);
    dispatch(showRightDrawerAction(false, {}));
  };

  useEffect(() => {
    getMastersList().then((answer = {}) => {
      dispatch(setMasterList(answer.data));
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(showRightDrawerAction(false, {}));
  }, [remont_id, dispatch]);

  const showMobileSidebar = useMemo(() => {
    if (window.innerWidth > 768) return true;
    return !remont_id;
  }, [remont_id]);

  const showContent = useMemo(() => {
    if (window.innerWidth > 768) return true;
    return !!remont_id;
  }, [remont_id]);

  useEffect(() => {
    const goBack = () => {
      router(`${PAGES.remontsPage}/${remont_status}`);
    };
    !!remont_id && dispatch(setHeaderBack(goBack));
    return () => {
      dispatch(setHeaderBack(null));
    };
  }, [dispatch, remont_status, router, remont_id, value]);
  return (
    <RemontWrapper>
      <Grid container className={classes.wrapper}>
        {!isSingleRemont && (
          <Grid
            item
            xs={3}
            className={clsx(classes.sidebar, {
              [classes.hidden]: !showMobileSidebar,
            })}
          >
            <ComplexesList
              min
              remontRoute={`${PAGES_ROUTE_KEY.remontsPage}/${remont_status}`}
            />
          </Grid>
        )}

        {showContent && (
          <Grid
            item
            xs={isSingleRemont || window.innerWidth <= 768 ? 12 : 9}
            className={classes.content}
          >
            <Box
              display="flex"
              flexDirection="column"
              style={{
                height: "100vh",
                backgroundColor: !resident?.remont_id ? "#fff" : null,
              }}
            >
              <Main
                handleChange={handleChange}
                changeActiveTab={changeActiveTab}
                value={value}
                remont_id={remont_id}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </RemontWrapper>
  );
};

const Main = ({ handleChange, value, remont_id, changeActiveTab }) => {
  const classes = useStyles();
  const history = useNavigate();
  const location = useLocation();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();

  const [menuItem, setMenuItem] = useState(query.get("tab") || "");
  const [remontData, setRemontData] = useState({});
  const [remontTabulations, setRemontTabulations] = useState([]);
  const [isFetchingRemontData, setIsFetchingRemontData] = useState(false);

  useEffect(() => {
    const isActiveTabExist = menuItem && !!remontTabKeys[menuItem];
    if (menuItem && !isActiveTabExist) {
      setMenuItem("");
      changeActiveTab && changeActiveTab(DEFAULT_OPEN_TAB);
    }
    history(`${location.pathname}?active=${value}&tab=${menuItem}`);
  }, [menuItem, value, history, changeActiveTab, location.pathname]);

  useEffect(() => {
    setIsFetchingRemontData(true);
    remont_id &&
      fetchRemontInfo(remont_id)
        .then((res) => {
          setIsFetchingRemontData(false);
          setRemontData(res?.data);
        })
        .catch(() => {
          setIsFetchingRemontData(false);
        });
  }, [remont_id]);

  useEffect(() => {
    const getData = async () => {
      const res = await getRemontTabulationsData();
      setRemontTabulations(res || []);
    };
    getData();
  }, []);

  const tabsData = useMemo(() => {
    if (remontTabulations?.length > 7) {
      return {
        mainTabs: remontTabulations.slice(0, 7),
        additionalTabs: remontTabulations.slice(7),
      };
    }
    return { mainTabs: remontTabulations, additionalTabs: null };
  }, [remontTabulations]);

  const getTabProps = (tabCode) => {
    if (tabCode === remontTabKeys.remontInfo) {
      return {
        data: remontData,
        isFetching: isFetchingRemontData,
        setRemontData,
      };
    }
    if (tabCode === remontTabKeys.remontInfoMaster) {
      return {
        remontData,
        setRemontData,
      };
    }
    return {};
  };

  if (!remont_id) {
    return (
      <Box className={classes.placeholder_wrapper}>
        <PlaceholderImg text="Выберите ремонт" />
      </Box>
    );
  }

  if (!tabsData.mainTabs?.length) return null;
  return (
    <>
      <RemontsHeader
        value={value}
        menuItem={menuItem}
        handleChange={handleChange}
        setMenuItem={setMenuItem}
        tabsData={tabsData}
      />
      <WrapperScrollbar className={clsx(classes.nav)}>
        {tabsData?.mainTabs?.map((item) => {
          const tab = remontTabComponents[item.grant_code];
          if (!tab || !tab.component) return null;
          const Component = tab.component;
          const props = tab.props ? { ...tab.props } : {};
          const propsComponent = tab.propsComponent
            ? { ...tab.propsComponent, ...getTabProps(item.grant_code) }
            : { ...getTabProps(item.grant_code) };
          return (
            <CustomTabPanel
              key={item.index}
              value={value}
              index={item.index}
              {...props}
            >
              <Component {...propsComponent} />
            </CustomTabPanel>
          );
        })}
        <CustomTabPanel value={value} index={7}>
          <AdditionalTab menuItem={menuItem} />
        </CustomTabPanel>
      </WrapperScrollbar>
    </>
  );
};

export const useStyles = makeStyles((theme) => ({
  tabs_root: {
    minHeight: 64,
  },
  tabs_scroller: {
    display: "flex",
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: "#373737",
  },
  subtitle: {
    fontSize: 14,
  },
  titleInline: {
    display: "flex",
    gap: "5px",
    padding: "0 15px",
    [theme.breakpoints.down(768)]: {
      padding: 0,
    },
  },
  select: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#0C4B86",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
  subHeader: {
    background: "#ECEEFD",
    borderRadius: "4px 4px 0px 0px",
    padding: "16px",
  },
  listItem: {
    "& > *": {
      marginRight: "10px",
      marginLeft: "10px",
    },
  },
  nav: {
    flexGrow: 1,
    paddingRight: "6px",
    overflowY: "auto",
    backgroundColor: "#fff",
    minHeight: "625px",
  },
  box: {
    background: "#F8F8F8",
    border: "1px solid #ECEEFD",
    padding: "10px 20px",
    borderRadius: "50%",
  },
  placeholder_wrapper: {
    textAlign: "center",
    backgroundColor: "#fff",
    "& img": {
      maxWidth: "100%",
    },
    [theme.breakpoints.down(768)]: {
      display: "none",
    },
  },
  box_item: {
    width: 65,
  },
  additionalBtn: {
    borderRadius: 4,
    margin: "0",
    padding: "5px",
    width: "fit-content",
    fontSize: "13px",
    lineHeight: "18px",
  },
  info__wrapper: {
    padding: "16px",
    [theme.breakpoints.down(576)]: {
      padding: "0",
      flexWrap: "wrap",
      gap: "15px",
    },
  },
  info_items: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 0.8fr)",
    gridColumnGap: "0px",
    gridRowGap: "12px",
    [theme.breakpoints.down(576)]: {
      display: "block",
    },
  },
  info__column_center: {
    width: "40%",
    [theme.breakpoints.down(768)]: {
      width: "auto",
    },
  },
  info__column_right: {
    width: "19%",
    [theme.breakpoints.down(768)]: {
      width: "auto",
    },
  },
  btn: {
    width: "100%",
    height: "auto",
    minHeight: "28px",
    borderRadius: 4,
    boxShadow: "none",
    fontSize: 14,
    lineHeight: "17px",
    textAlign: "center",
    fontWeight: 400,
    "&:hover": {
      boxShadow: "none",
    },
    textTransform: "none",
  },
  confirm: {
    backgroundColor: "#0C4B86",
    color: "#fff",
    marginTop: 15,
    "&:hover": {
      backgroundColor: "#437ae7",
    },
  },
  cancel: {
    backgroundColor: "#FF6262",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ff4646",
    },
  },
  download_docs: {
    "& div": {
      top: 25,
    },
    "& p": {
      display: "inline-flex",
    },
  },
  green: {
    color: "green",
    fontWeight: 600,
  },
  blue: {
    color: "#0C4B86",
    cursor: "pointer",
  },
  red: {
    color: "#FF6262",
    fontWeight: 600,
  },
  hidden: {
    display: "none",
  },
  wrapper: {
    flexWrap: "nowrap",
    [theme.breakpoints.down(768)]: {
      display: "block",
    },
  },
  content: {
    minWidth: 450,
    [theme.breakpoints.down(768)]: {
      minWidth: "unset",
    },
  },
  contentCollapsed: {
    maxWidth: "calc(100% - 60px)",
    flexBasis: "calc(100% - 60px)",
  },
  sidebar: {
    minWidth: 220,
    maxWidth: "unset",
    transition: "all .3s",
  },
}));
