import React, { useEffect, useState } from "react";
import { getRemontServiceMasters } from "./services";
import {
  CustomTable,
  CustomTableCell,
  CustomTableRow
} from "../../../components/common/CustomTable/CustomTable";

export const RemontServiceMasters = ({ remont_id }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [masters, setMasters] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const res = await getRemontServiceMasters(remont_id, setIsFetching);
      setMasters(res);
    };
    getData();
  }, [remont_id]);

  return (
    <>
      <h2 className={"table__title"}>Мастера по услугам</h2>
      <CustomTable
        headItems={headItems}
        isEmpty={!masters?.length}
        isFetching={isFetching}
      >
        {masters?.map(item => {
          return (
            <CustomTableRow key={item.work_set_id}>
              <CustomTableCell>{item.work_set_name}</CustomTableCell>
              <CustomTableCell>{item.team_master_fio || ""}</CustomTableCell>
            </CustomTableRow>
          );
        })}
      </CustomTable>
    </>
  );
};

const headItems = [
  { label: "Работа", align: "left" },
  { label: "Мастер", align: "left" }
];
