import React, { useEffect, useState } from "react";
import { getConstructorLanding } from "../../services/api/api_app";
import { PageTitle } from "../../components/common/pageTitle";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router";
import { PAGES_ROUTE_KEY } from "../../constants";
import { LinearProgress } from "@material-ui/core";

export const OurWorks = () => {
  const router = useNavigate()
  const [data, setData] = useState();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsFetching(true)
      const res = await getConstructorLanding();
      setIsFetching(false)
      if (!res) return;
      let { preset_kit_info_list = [] } = res;
      setData(preset_kit_info_list);
    };
    getData();
  }, []);

  const handleClick = (id) => {
    if(!id) return
    router(`${PAGES_ROUTE_KEY.ourWorks}/${id}`)
  };
  return (
    <>
      <PageTitle title={"Готовые объекты"} />
      <div className={styles.wrapper}>
        {isFetching && <LinearProgress />}
        <div className={styles.packages}>
          {
            data?.map(item => {
              return <div key={item.id} className={styles.packages__item} onClick={() => handleClick(item.id)}>
                <img className={styles.packages__item__img} src={"https://office.smartremont.kz" + item.img_mini_url}
                     alt="" />
                <h5 className={styles.packages__item__name}>{item?.name}</h5>
              </div>;
            })
          }
        </div>
      </div>
    </>
  );
};
