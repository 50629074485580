import React, { useLayoutEffect, useState } from "react";
import { Box, Button, ClickAwayListener, Typography } from "@material-ui/core";
import { useStyles } from "../index";
import AsideTxtConstructor from "./AsideTxtConstructor";
import { acceptRemont } from "./services/api";
import { useDispatch, useSelector } from "react-redux";
import CreateIcon from "@material-ui/icons/Create";
import { showPopUp } from "../../../services/redux/actions/app-actions";
import clsx from "clsx";
import { ExpandMore } from "@material-ui/icons";
import Popper from "../../contractJournal/Popper";
import { getSpecificationXml, signSpecificationXml } from "../../contractJournal/api";
import { signXml } from "../../../utils/signXml";
import { useCustomSnackbar } from "../../../utils/hooks/useCustomSnackbar";
import { useHttp } from "../../../utils/hooks/http.hook";
import { MODALS_NAMES } from "../../../constants";
import cookiesService from "../../../services/cookiesService";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { BlockItem } from "./BlockItem";
import { showRightDrawerAction } from "../../../services/redux/actions/remonts-actions";
import { RequestsRightDrawer } from "../Requests/RequestsRightDrawer";

const cookiesServiceInstance = cookiesService.getService();
const RemontInfoHeader = ({ data, setRemontData: setData }) => {
  const classes = useStyles();
  const { request } = useHttp();
  const dispatch = useDispatch();
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isNotVisible, setIsNotVisible] = useState(false);
  const { resident_id, is_master_payment } = useSelector(
    state => state.remontsState.resident
  );

  const {
    remont_id,
    area,
    remont_date_begin,
    remont_date_end,
    prorab_name,
    prorab_employee_id,
    contractor_id,
    contractor_name,
    project_manager_name,
    project_manager_phone,
    techproject_employee_fio,
    techproject_employee_phone,
    are_visible_btns,
    sign_btn,
    show_sign_btn,
    contractor_agreement_list_id,
    sign_text,
    contractor_status_text,
    stage_code,
    contractor_agreement_list_type_code: type_code,
    status_code
  } = data;
  const userData = cookiesServiceInstance.getUserData();
  const positionCode = userData?.position_code;
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [fileNames, setFileNames] = useState([]);

  const handleProrabChange = () => {
    dispatch(showPopUp(true, MODALS_NAMES.prorabChange, { setData, remont_id, prorab_employee_id }));
  };

  const handleContractorChange = () => {
    dispatch(showPopUp(true, MODALS_NAMES.changeContractor, { setData, remont_id, contractor_id }));
  };

  const handleChangeStatus = status => {
    acceptRemont(remont_id, {
      is_accept: status === "accept"
    })
      .then(res => {
        setData(res.data);
        addDefaultSnack("Успешно");
      })
      .catch(err => {
        addCloseSnack(err.response.data.error);
      });
  };

  const handleClickAway = () => {
    setIsPopperOpen(false);
  };

  useLayoutEffect(() => {
    if (type_code === "CONTRACTOR_ACCESSION") {
      setFileNames(["Договор присоединения"]);
    } else if (!!type_code) {
      setFileNames(["Спецификация (договор)"]);
    }
  }, [type_code]);

  const handlePopperClick = () => {
    setIsPopperOpen(!isPopperOpen);
  };

  const handleSignRemont = async () => {
    setIsLoading(true);
    try {
      const response = await getSpecificationXml({
        agreement_id: Number(contractor_agreement_list_id),
        remont_id,
        type_code
      }).catch(err => {
        setIsLoading(false);
        addCloseSnack(err.response.data.error || "Что-то пошло не так");
      });
      const xml = response.data;

      const signValidatedXml = async params => {
        try {
          await signSpecificationXml(params);
          addDefaultSnack("Успешно");
        } catch (err) {
          addCloseSnack(err.response.data.error || "Что-то пошло не так");
        } finally {
          setIsLoading(false);
        }
      };
      signXml("PKCS12", "SIGNATURE", xml, "signXmlBack", xml).then(res => {
        const { unsigned_message, signed_message } = res;
        const params = {
          unsigned_message,
          signed_message,
          remont_id: Number(remont_id),
          contractor_id_from_filter: "",
          remont_id_from_filter: "",
          type_code,
          contractor_agreement_list_id
        };
        signValidatedXml(params);
      });
    } catch (err) {
      setIsLoading(false);
      addCloseSnack(err?.response.data.error || "Что-то пошло не так");
    }
  };

  const handleCallOkk = async () => {
    const params = {
      remont_id,
      resident_id,
      remont_performing_code: 0,
      stage_code: 0,
      remark_code: 0
    };
    const res = await request(
      "/partner/remont_stage/",
      "post",
      params,
      {},
      { isModal: true }
    );
    if (res) {
      dispatch(showPopUp(false));
      addDefaultSnack("ОКК успешно вызван");
      setIsNotVisible(true);
    }
  };

  const stageCodes = ["3", "4", "5"];
  const statusCodes = ["STAGE_NEW", "DEFECT_EXIST"];

  return (
    <Box className={classes.info__wrapper} display="flex" alignItems="top">
      <Box>
        <Box className={classes.info_items}>
          <BlockItem title={"Дата сдачи:"}>{remont_date_end}</BlockItem>
          <BlockItem title={"Площадь:"}>{area}</BlockItem>
          <BlockItem title={"Дата начала:"}>{remont_date_begin}</BlockItem>
          <BlockItem title={"Прораб:"}>
            {prorab_name}
            <CreateIcon
              style={{
                width: 14,
                height: 14,
                cursor: "pointer",
                transform: "translate(3px,2px)"
              }}
              onClick={handleProrabChange}
            />
          </BlockItem>
          <BlockItem title={"Подрядчик:"}>
            {contractor_name}
            <CreateIcon
              style={{
                width: 14,
                height: 14,
                cursor: "pointer",
                transform: "translate(3px,2px)"
              }}
              onClick={handleContractorChange}
            /></BlockItem>
          <BlockItem title={"Проектировщик:"}>
            {techproject_employee_fio}{techproject_employee_phone ? <><br />{` ${techproject_employee_phone}`}</> : ""}
          </BlockItem>
          <BlockItem title={"Проектный менеджер:"}>
            {project_manager_name}{project_manager_phone ? <><br />{` ${project_manager_phone}`}</> : ""}
          </BlockItem>
        </Box>
        {stageCodes.includes(stage_code) &&
        statusCodes.includes(status_code) ? (
          <Box
            width={150}
            className={clsx({
              [classes.hidden]: isNotVisible === true || !remont_id
            })}
          >
            <Button
              component="span"
              className={clsx(classes.btn, classes.confirm)}
              onClick={() =>
                dispatch(
                  showPopUp(true, MODALS_NAMES.confirmModal, {
                    onSubmit: handleCallOkk,
                    title: "Вы уверены что хотите вызвать окк?",
                    btnLabel: "Вызвать",
                    type: "default"
                  })
                )
              }
            >
              Вызов Окк
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box className={classes.info__column_center}>
        <AsideTxtConstructor {...data} remont_id={remont_id} />
        <BlockItem titleInline title={"Автоматическая оплата мастерам:"}>
          {is_master_payment ? (
            <DoneIcon style={{ color: "#19cd19" }} />
          ) : (
            <CloseIcon color={"error"} />
          )}
        </BlockItem>
      </Box>
      <Box
        className={classes.info__column_right}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Button
          className={classes.additionalBtn}
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch(showRightDrawerAction(true));
          }}
        >
          Доп. заявка
        </Button>
        <RequestsRightDrawer/>
        {positionCode === "PARTNER_ASSIST" ||
        positionCode === "MANAGER_PROJECT" ||
        positionCode === "SMART_BRIGADIER" ? null : (
          <>
            <Box position="relative">
              <ClickAwayListener onClickAway={handleClickAway}>
                <Box className={classes.download_docs}>
                  <Typography
                    onClick={handlePopperClick}
                    className={classes.blue}
                  >
                    Выгрузка <ExpandMore />
                  </Typography>
                  {isPopperOpen ? (
                    <Popper
                      fileNames={fileNames}
                      type_code={type_code}
                      remont_id={remont_id}
                      contractor_agreement_list_id={
                        contractor_agreement_list_id
                      }
                    />
                  ) : null}
                </Box>
              </ClickAwayListener>
            </Box>
            {show_sign_btn || sign_btn ? (
              <Button
                className={clsx(classes.btn, classes.confirm)}
                onClick={handleSignRemont}
                disabled={!sign_btn || isLoading}
                variant="contained"
              >
                {isLoading ? "Загрузка..." : "Подписать ремонт"}
              </Button>
            ) : null}
            {!!sign_text ? (
              <Typography className={classes.green}>{sign_text}</Typography>
            ) : null}

            {are_visible_btns ? (
              <Box mt={2}>
                <Button
                  className={clsx(classes.btn, classes.cancel)}
                  onClick={() => handleChangeStatus("decline")}
                >
                  Отказать
                </Button>
                <Button
                  className={clsx(classes.btn, classes.confirm)}
                  onClick={() => handleChangeStatus("accept")}
                >
                  Принять
                </Button>
              </Box>
            ) : null}
            {contractor_status_text ? (
              <Typography
                className={
                  contractor_status_text === "Отправлен" ||
                  contractor_status_text === "Подтвержден"
                    ? classes.green
                    : contractor_status_text === "Отказан"
                      ? classes.red
                      : ""
                }
              >
                {contractor_status_text}
              </Typography>
            ) : null}
          </>
        )}
      </Box>
    </Box>
  );
};

export default RemontInfoHeader;
