import React from "react";
import { CustomDatePicker } from "../../../../common/form/CustomDatePicker";
import styles from "./requests_approve.module.scss";
import { Controller, useForm } from "react-hook-form";
import { InputWarning } from "../../../../common/form/warnings";
import { CButton } from "../../../../common/buttons";
import { useSelector } from "react-redux";

export const RequestsApproveModal = ({ data }) => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();
  const { modalLoading } = useSelector(state => state.appState);

  const { onSubmit: onModalSubmit } = data;
  const onSubmit = async body => {
    !!onModalSubmit && (await onModalSubmit(body));
  };
  return (
    <div className={styles.requests}>
      <h1>Утверждение подрядчиком</h1>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.requests__form}>
        <div className={`${styles.requests__form_item}`}>
          <Controller
            name="date_shipping"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomDatePicker
                fullWidth
                label="Утвердить для даты"
                placeholder="Введите дату"
                value={value}
                onChange={onChange}
              />
            )}
            defaultValue={""}
            rules={{ required: true }}
          />
          {errors.date_shipping && (
            <InputWarning>Это поле обязательно</InputWarning>
          )}
        </div>
        <div className={styles.requests__btn_wrapper}>
          <CButton
            loading={modalLoading}
            disabled={modalLoading}
            type="submit"
            className={styles.requests__btn}
          >
            Сохранить
          </CButton>
        </div>
      </form>
    </div>
  );
};
