import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 275,
    padding: 0
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 18,
    color: "#494C62",
    fontWeight: 600,
    margin: "6px 0"
  },
  col: {
    width: 70,
    borderLeft: "1px solid #F0F2F6",
    padding: 5,
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "#ECEEFD"
    }
  },
  col_main: {
    width: 60
  },
  row: {
    background: "#ECEEFD",
    verticalAlign: "middle"
  },
  textData: {
    color: "#000000",
    fontSize: 12,
    fontWeight: 400
  },
  pos: {
    fontSize: 12,
    fontWeight: 600
  }
}));

export default function HeadWork({ data = {} }) {
  const classes = useStyles();
  const row = [];

  Object.keys(data).forEach(function(key) {
    row.push(data[key]);
  });
  return row.map((arr, idx) => (
    <TableCell className={classes.col} key={idx}>
      <Typography className={classes.pos} color="textSecondary">
        {arr.material_type_name}
      </Typography>
    </TableCell>
  ));
}
