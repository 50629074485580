import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";

import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";

import Paper from "@material-ui/core/Paper";
import { useSelector } from "react-redux";

import ReportTableHead from "./reportTableHead";
import TableRowList from "./tableRowList";

const useStyles = makeStyles(theme => ({
  table: {
    borderCollapse: "collapse",
    borderRadius: 4,
    boxShadow: "0px 5px 20px rgb(108 108 108 / 10%)",
    minWidth: "max-content"
  },
  row_table: {
    marginBottom: 10,
    verticalAlign: "top",
    borderRadius: 4,
    boxShadow: "0px 5px 20px rgba(108, 108, 108, 0.1)",
    maxWidth: 1400,
    [theme.breakpoints.up("xl")]: {
      maxWidth: 1800
    },
    overflowX: "auto"
  },
  container: {
    maxHeight: "80vh"
  }
}));

const ReportTable = ({ flatNum }) => {
  const classes = useStyles();
  const { materialShip } = useSelector(state => state.appState);
  const { material_types, remonts } = materialShip;
  return (
    <>
      <Paper className={classes.row_table}>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-label="simple table"
            stickyHeader
          >
            <TableHead>
              <ReportTableHead data={material_types} />
            </TableHead>
            <TableBody>
              <TableRowList data={remonts} flatNum={flatNum} />
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ReportTable;
