import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { useStyles } from "./Popper";
import { useDownloadFile } from "../remonts/RemontInfo/Gpr/useDownloadFile";
import clsx from "clsx";
import { MAIN_URL } from "../../constants";

const ContractJournalFile = ({
  agreement_id,
  remont_id,
  name,
  type_code,
  document_url
}) => {
  const classes = useStyles();
  const { downloadFile, downloading } = useDownloadFile();

  const handleClick = () => {
    if (downloading) return;
    downloadFile(
      "/partner/contractor_agreement_list/specification/pdf/",
      name,
      { agreement_id: Number(agreement_id), remont_id, type_code },
      "pdf"
    );
  };
  return (
    <Box
      className={clsx(classes.file, {
        [classes.disabled]: downloading
      })}
      onClick={handleClick}
    >
      {name === "Договор присоединения" ? (
        <a
          target="_blank"
          href={MAIN_URL + document_url}
          rel="noopener noreferrer"
        >
          {name}
        </a>
      ) : (
        name
      )}
      {downloading ? (
        <CircularProgress size={15} style={{ marginLeft: 10 }} />
      ) : null}
    </Box>
  );
};

export default ContractJournalFile;
