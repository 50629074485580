import React from "react";
import { Box, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles({
  avatar: {
    width: 30,
    height: 30,
    marginRight: 15,
    backgroundColor: "#9194A9"
  },
  name: {
    fontSize: 12,
    fontWeight: 600,
    color: "#494C62"
  },
  date: {
    fontSize: 11,
    fontWeight: 400,
    color: "#9194A9",
    paddingLeft: 9
  },
  action: {
    fontSize: 12,
    fontWeight: 400,
    color: "#000"
  }
});
const RecentChanges = ({ fio, date_create, action_content }) => {
  const classes = useStyles();
  return (
    <Box display="flex" mb={2} mt={2}>
      <Avatar className={classes.avatar} />
      <Box>
        <Typography className={classes.name}>
          {fio}
          <Box component="span" className={classes.date}>
            {date_create}
          </Box>
        </Typography>
        <Typography className={classes.action}>{action_content}</Typography>
      </Box>
    </Box>
  );
};

export default RecentChanges;
