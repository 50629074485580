import React, { useEffect, useState } from "react";
import { getCheckListByStages } from "../services/api";
import { useParams } from "react-router-dom";
import { CustomLoader } from "../../../components/common/CustomLoader";
import { CheckListStagesTable } from "./CheckListStagesTable";

export const CheckListByStages = () => {
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);
  const [loading, setLoading] = useState(false);
  const [checklists, setChecklists] = useState([]);

  useEffect(() => {
    setLoading(true);
    getCheckListByStages(remont_id)
      .then(res => {
        setLoading(false);
        setChecklists(res);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [remont_id]);

  return (
    <div>
      {loading ? (
        <CustomLoader />
      ) : (
        <CheckListStagesTable
          remont_id={remont_id}
          data={checklists}
          childrenItemProp={"checklists"}
          parentsItemProp={"parent_checklists"}
        />
      )}
    </div>
  );
};
