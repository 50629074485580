import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import HeadWork from "./headWork";

const useStyles = makeStyles(() => ({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 18,
    color: "#494C62",
    fontWeight: 600,
    margin: "6px 0"
  },
  col: {
    borderLeft: "1px solid #F0F2F6",
    width: 60,
    padding: "5px !important",
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "#ECEEFD"
    }
  },
  col_main: {
    width: 60,
    padding: "5px !important",
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "#ECEEFD"
    }
  },
  row: {
    background: "#ECEEFD",
    verticalAlign: "middle"
  },
  textData: {
    color: "#000000",
    fontSize: 12,
    fontWeight: 400
  },
  pos: {
    fontSize: 12,
    fontWeight: 600
  },
  col_resident: {
    borderLeft: "1px solid #F0F2F6",
    width: 110,
    padding: "5px !important",
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "#ECEEFD"
    }
  }
}));

export default function ReportTableHead({ data = {} }) {
  const classes = useStyles();
  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.col_resident}>
        <Typography className={classes.pos} color="textSecondary">
          ЖК
        </Typography>
      </TableCell>
      <TableCell className={classes.col_main}>
        <Typography className={classes.pos} color="textSecondary">
          №Кв
        </Typography>
      </TableCell>
      <TableCell className={classes.col}>
        <Typography className={classes.pos} color="textSecondary">
          Площадь
        </Typography>
      </TableCell>
      <TableCell className={classes.col}>
        <Typography
          className={classes.pos}
          style={{ width: 100 }}
          color="textSecondary"
        >
          Пакет
        </Typography>
      </TableCell>
      <HeadWork data={data} />
    </TableRow>
  );
}
