import { getDebtTypesData } from "../../../components/features/teams/api";
import {
  getCostContractorTypes,
  getCostContractorTypesFilter,
  getCostPaymentType,
  getIncomeContractorTypes,
  getPaymentTypes
} from "../../../components/features/accounting/api";

export const SET_DEBT_TYPES = "accounting:setDebtTypes";
export const SET_COST_CONTRACTOR_TYPES = "accounting:setCostContractorTypes";
export const SET_COST_CONTRACTOR_TYPES_FILTER =
  "accounting:setCostContractorTypesFilter";
export const SET_INCOME_CONTRACTOR_TYPES =
  "accounting:setIncomeContractorTypes";
export const SET_PAYBOX_BALANCE = "accounting:setPayboxBalance";
export const SET_PAYMENT_TYPES = "accounting:setPaymentTypes";
export const SET_COST_PAYMENT_TYPE = "accounting:setCostPaymentType";
export const RESET_ACCOUNTING_STATE = "RESET_ACCOUNTING_STATE";
export const SET_ACCOUNTING_AMOUNT = "SET_ACCOUNTING_AMOUNT";
export const SET_ACCOUNTING_AMOUNT_TYPES = "SET_ACCOUNTING_AMOUNT_TYPES";

export const setDebtTypes = data => {
  return {
    type: SET_DEBT_TYPES,
    payload: data
  };
};

export const setCostContractorTypes = (data = {}) => {
  return {
    type: SET_COST_CONTRACTOR_TYPES,
    payload: data
  };
};

export const setCostContractorTypesFilter = (data = {}) => {
  return {
    type: SET_COST_CONTRACTOR_TYPES_FILTER,
    payload: data
  };
};
export const resetAccountingState = () => {
  return {
    type: RESET_ACCOUNTING_STATE,
  };
};

export const setAccountingAmount = amount => {
  return {
    type: SET_ACCOUNTING_AMOUNT,
    payload: amount
  };
};

export const setAccountingAmountTypes = data => {
  return {
    type: SET_ACCOUNTING_AMOUNT_TYPES,
    payload: data
  };
};

export const setIncomeContractorTypes = (data = {}) => {
  return {
    type: SET_INCOME_CONTRACTOR_TYPES,
    payload: data
  };
};

export const setPayboxBalance = (data = 0) => {
  return {
    type: SET_PAYBOX_BALANCE,
    payload: data
  };
};

export const setPaymentTypes = (data = []) => {
  return {
    type: SET_PAYMENT_TYPES,
    payload: data
  };
};

export const setCostPaymentType = (data = {}) => {
  return {
    type: SET_COST_PAYMENT_TYPE,
    payload: data
  };
};

export const getDebtTypes = () => async (dispatch, getState) => {
  try {
    const { appState } = getState();
    if (!!appState?.debtTypes) return;
    const res = await getDebtTypesData();
    if (res) dispatch(setDebtTypes(res));
  } catch (e) {}
};

export const getCostContractorTypesFilterData = () => async (
  dispatch,
  getState
) => {
  try {
    const { appState } = getState();
    if (!!appState?.contractorCostTypesFilter) return;
    const res = await getCostContractorTypesFilter();
    if (res) dispatch(setCostContractorTypesFilter(res));
  } catch (e) {
    dispatch(setCostContractorTypesFilter([]));
  }
};

export const getCostContractorTypesData = () => async (dispatch, getState) => {
  try {
    const { appState } = getState();
    if (!!appState?.contractorCostTypes) return;
    const res = await getCostContractorTypes();
    if (res) dispatch(setCostContractorTypes(res));
  } catch (e) {
    dispatch(setCostContractorTypes([]));
  }
};

export const getIncomeContractorTypesData = () => async (
  dispatch,
  getState
) => {
  try {
    const { appState } = getState();
    if (!!appState?.contractorIncomeTypes) return;
    const res = await getIncomeContractorTypes();
    if (res) dispatch(setIncomeContractorTypes(res));
  } catch (e) {
    dispatch(setIncomeContractorTypes([]));
  }
};

export const getPaymentData = () => async (dispatch, getState) => {
  try {
    const { appState } = getState();
    if (!!appState?.paymentTypes) return;
    const res = await getPaymentTypes();
    if (res) dispatch(setPaymentTypes(res));
  } catch (e) {
    dispatch(setPaymentTypes([]));
  }
};

export const getCostPaymentObj = () => async (dispatch, getState) => {
  try {
    const { appState } = getState();
    if (!!appState?.costPaymentType) return;
    const res = await getCostPaymentType();
    res && dispatch(setCostPaymentType(res));
  } catch (e) {
    dispatch(setCostPaymentType([]));
  }
};
