import React from "react";
import { Routes } from "../../../Routes";
import { MainLayout } from "../index";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { PAGES } from "../../../constants";

export const Content = () => {
  const { isUserAuthenticated } = useSelector(state => state.appState);
  if (!isUserAuthenticated) return <Navigate to={PAGES.login} replace={true} />;
  return (
    <MainLayout>
      <Routes />
    </MainLayout>
  );
};
