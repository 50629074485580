import React from "react";
import RegisterProblem from "./RegisterProblem";
import ProblemsList from "./ProblemsList";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { PAGES_ROUTE_KEY } from "../../../constants";
import { setHeaderBack } from "../../../services/redux/actions/app-actions";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  form_wrapper: {
    maxWidth: 905,
    width: "100%"
  },
  block: {
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
    [theme.breakpoints.down(768)]: {
      display: "block"
    }
  }
}));

const RemontsProblem = ({singleRemont}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { remont_id: remont_id_str } = useParams();
  const remont_id = Number(remont_id_str);

  useEffect(() => {
    const goBack = () => {
      navigate(`${PAGES_ROUTE_KEY.remontInfo}/${remont_id}`);
    };
    remont_id && singleRemont && dispatch(setHeaderBack(goBack));
    return () => {
      dispatch(setHeaderBack(null));
    };
  }, [dispatch, remont_id, navigate, singleRemont]);
  return (
    <Box className={classes.block}>
      <Box className={classes.form_wrapper}>
        <RegisterProblem />
      </Box>
      <ProblemsList />
    </Box>
  );
};

export default RemontsProblem;
