import React, { useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Checkbox, FormHelperText, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import StarIcon from "@material-ui/icons/Star";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = makeStyles(() => ({
  option: {
    fontSize: 14
  },
  optionContent: {
    display: 'flex',
    alignItems: 'center'
  },
  optionMin: {
    fontSize: 12
  },
  wrapper: {
    width: "100%"
  },
  tag: {
    display: "none"
  },
  star: {
    fontSize: 15,
    color: "#ffe418",
    marginLeft: "4px"
  }
}));

export const AutoSuggest = ({
                              label = "",
                              name = "",
                              value = "",
                              onChange,
                              error = false,
                              loading = false,
                              errMessage,
                              variant='standard',
                              list = [],
                              specialOptionTitle = "",
                              getSpecialOption,
                              showKey = "name",
                              valueKey = "id",
                              required = false,
                              multiple = false,
                              showMultipleSelected = false,
                              min = false,
                              withMargin = false,
                              defaultValue = "",
                              ...other
                            }) => {
  const classes = styles();

  function handleChange(event, value) {
    const e = { target: { name, value: value ? value[valueKey] : "" } };
    if (multiple) {
      e.target.value = value.map(el => el[valueKey]);
    }
    return onChange && onChange(e, value);
  }

  const inputValue = useMemo(() => {
    if (multiple) {
      if (!value?.length) return [];
      try {
        return value?.map(id => {
          const current = list?.find(listItem => listItem[valueKey] === id);
          return current || {};
        });
      } catch (e) {
        return [];
      }
    }
    if (typeof value === "object") return value;
    const item = list?.find(item => item[valueKey] === value);
    return item || "";
  }, [value, list, valueKey, multiple]);

  return (
    <div className={classes.wrapper}>
      <Autocomplete
        id={name} loading={loading}
        name={name}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        noOptionsText="Не найдено"
        options={list || []}
        getOptionLabel={option =>
          (typeof option[showKey] === "number"
            ? option[showKey].toString()
            : option[showKey]) || ""
        }
        classes={{
          tag: classes.tag,
          option: clsx(classes.option, { [classes.optionMin]: min })
        }}
        value={inputValue}
        onChange={handleChange}
        renderInput={params => (
          <TextField name={name} {...params} variant={variant || 'standard'} className="w-100" label={label} />
        )}
        renderTags={selected => {
          return showMultipleSelected
            ? selected?.map((item, i) => (
              <span key={item[valueKey]}>{`${item[showKey]}${
                selected?.length > 1 && i !== selected.length - 1 ? ", " : ""
              }`}</span>
            ))
            : `Выбрано (${selected?.length || 0}) `;
        }}
        renderOption={(option, { selected }) => (
          <div className={classes.optionContent} title={specialOptionTitle || ""}>
            {!!multiple && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ margin: 0 }}
                checked={selected}
              />
            )}
            {(typeof option[showKey] === "number"
              ? option[showKey].toString()
              : option[showKey]) || ""}
            {getSpecialOption && getSpecialOption(option) && <StarIcon className={classes.star} />}
          </div>
        )}
        className={withMargin ? "" : classes.select}
        {...other}
      />
      {error ? (
        <FormHelperText error={error}>{errMessage}</FormHelperText>
      ) : null}
    </div>
  );
};
