import React from "react";
import { PageTitle } from "../../components/common/pageTitle";
import Chat from "../remonts/Chat";
import { CheckRemontPermission } from "../remonts/CheckRemontPermission";

const RemontChat = () => {
  return (
    <CheckRemontPermission>
      <PageTitle title="Чат" />
      <Chat singleRemont />
    </CheckRemontPermission>
  );
};

export default RemontChat;
