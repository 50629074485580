import React, { useEffect, useMemo, useState } from "react";
import { Box, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  addParticipant,
  fetchAvailableParticipants,
  fetchParticipants
} from "./api";
import { useSelector } from "react-redux";
import SingleParticipant from "./SingleParticipant";
import ModalTitle from "./ModalTitle";
import { AutoSuggest } from "../../components/common/form/AutoSuggest";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  modal: {
    padding: "25px 20px",
    backgroundColor: "#fff",
    borderRadius: 4,
    maxWidth: 375,
    width: "100%",
    position: "absolute",
    zIndex: 100,
    right: 138,
    top: 23
  },
  search: {
    width: "100%",
    margin: "21px 0 15px",
    "& input": {
      padding: "9px 9px 9px 37px",
      width: "100%",
      fontSize: 14,
      lineHeigh: "17px",
      border: "1px solid #E2E3ED"
    }
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: "15px",
    color: "#494C62",
    textTransform: "uppercase",
    marginBottom: 15
  },

  add_btn: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%"
    }
  }
});

const Participants = ({ setOpen }) => {
  const classes = useStyles();
  const { cardId } = useSelector(state => state.problemsState);
  const [participants, setParticipants] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [availableParticipants, setAvailableParticipants] = useState([]);
  const { addCloseSnack, addDefaultSnack } = useCustomSnackbar();

  const handleChange = (cardId, params) => {
    addParticipant(cardId, params)
      .then(res => {
        setParticipants(res.data);
        addDefaultSnack("Участник успешно добавлен");
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  };

  const handleSearchTermChange = e => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    fetchParticipants(cardId)
      .then(res => {
        setParticipants(res?.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [cardId, addCloseSnack]);

  useEffect(() => {
    fetchAvailableParticipants(cardId)
      .then(res => {
        setAvailableParticipants(res?.data);
      })
      .catch(err => {
        addCloseSnack(err?.response?.data?.error);
      });
  }, [cardId, addCloseSnack]);

  const computedParticipants = useMemo(() => {
    if (!participants?.length || !searchTerm) return participants;
    return participants.filter(el =>
      el.fio.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [participants, searchTerm]);

  return (
    <Paper className={classes.modal}>
      <ModalTitle title="Участники" setOpen={setOpen} />
      <form noValidate autoComplete="off">
        <TextField
          className={classes.search}
          id="outlined-search"
          type="search"
          variant="outlined"
          placeholder="Поиск участников..."
          value={searchTerm}
          onChange={handleSearchTermChange}
        />
      </form>
      <Typography className={classes.subtitle}>
        существующие участники
      </Typography>
      {computedParticipants?.map(el => {
        return (
          <SingleParticipant
            key={el.employee_id}
            {...el}
            cardId={cardId}
            setParticipants={setParticipants}
          />
        );
      })}

      <Box style={{ width: "100%" }}>
        <AutoSuggest
          id="combo-box-demo"
          list={availableParticipants}
          showKey={"fio"}
          valueKey={"employee_id"}
          className={classes.add_btn}
          noOptionsText="Участник не найден"
          label={"Выберите участника"}
          placeholder={"Выберите участника"}
          value={participants}
          onChange={e => {
            handleChange(cardId, { employee_id: e.target.value });
          }}
        />
      </Box>
    </Paper>
  );
};

export default Participants;
