import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RemontReportFilters from "./RemontReportFilters";
import { useDispatch, useSelector } from "react-redux";
import { PageTitle } from "../../components/common/pageTitle";
import { reportRemonts } from "./api";
import { setRemontsReport } from "../../services/redux/actions/remonts-actions";
import { CustomTable, CustomTableCell, CustomTableRow } from "../../components/common/CustomTable/CustomTable";
import { IconButton } from "@material-ui/core";
import NumberFormat from "react-number-format";
import Box from "@material-ui/core/Box";
import { showPopUp } from "../../services/redux/actions/app-actions";
import CreateIcon from "@material-ui/icons/Create";
import { useCustomSnackbar } from "../../utils/hooks/useCustomSnackbar";
import { MODALS_NAMES } from "../../constants";

const useStyles = makeStyles({
  root: {
    maxHeight: "calc(100vh - 185px)"
  },
  greenCell: {
    backgroundColor: "#D5F9DD",
    borderLeft: "1px solid #F0F2F6",
    maxWidth: 180,
    fontSize: 12,
    padding: "5px !important"
  },
  cell: {
    borderLeft: "1px solid #F0F2F6",
    maxWidth: 180,
    fontSize: 12,
    padding: "5px !important"
  }
});
export const ReportTable = () => {
  document.title = "Отчет по ремонтам";
  const dispatch = useDispatch();
  const classes = useStyles();
  const { addCloseSnack } = useCustomSnackbar();
  const { remontReports } = useSelector(state => state.remontsState);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    resident_id: ""
  });

  const fetchRemonts = useCallback(async () => {
    setLoading(true);
    reportRemonts(filters)
      .then(res => {
        setLoading(false);
        !res?.error && dispatch(setRemontsReport(res));
      })
      .catch(e => {
        const message = e?.response?.data?.error;
        message && addCloseSnack(message);
        setLoading(false);
      });
  }, [dispatch, filters, addCloseSnack]);

  useEffect(() => {
    fetchRemonts();
  }, [fetchRemonts]);

  const handleChange = (name, value) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <PageTitle title="Отчет по ремонтам" />
      <div style={{ padding: 20 }}>
        <RemontReportFilters handleChange={handleChange} filters={filters} />
        <CustomTable
          withMaxHeight={false}
          headItems={headItems}
          isFetching={loading}
          isEmpty={!remontReports?.length}
          containerClassName={classes.root}
        >
          {remontReports?.map((el, i) => {
            return (
              <CustomTableRow key={i}>
                <CustomTableCell component="th" scope="row">
                  {el?.resident_name}
                </CustomTableCell>
                <CustomTableCell align="right">{el?.flat_num}</CustomTableCell>
                <CustomTableCell align="left">{el?.stage_name}</CustomTableCell>
                <CustomTableCell align="right">
                  <NumberFormat
                    value={Math.floor(el?.plan_amount)}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    title={Math.floor(el?.plan_amount)}
                  />
                </CustomTableCell>
                <CustomTableCell align="right">
                  <NumberFormat
                    value={Math.floor(el?.payment_amount)}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    title={Math.floor(el?.payment_amount)}
                  />
                </CustomTableCell>
                <CustomTableCell align="right">{el?.area} м2</CustomTableCell>
                <CustomTableCell
                  className={
                    el?.specification_num_sequence > 0
                      ? classes.greenCell
                      : classes.cell
                  }
                >
                  <Box
                    sx={{ display: "flex", alignItems: "flex-end", gap: "5px" }}
                  >
                    <Box sx={{ lineHeight: "24px" }}>{el?.contractor_name}</Box>
                    <IconButton
                      size="small"
                      onClick={() => {
                        dispatch(
                          showPopUp(true, MODALS_NAMES.constractorAdd, {
                            remontId: el?.remont_id,
                            contractorName: el?.contractor_name
                          })
                        );
                      }}
                    >
                      <CreateIcon style={{ width: "18px", height: "18px" }} />
                    </IconButton>
                  </Box>
                </CustomTableCell>
              </CustomTableRow>
            );
          })}
        </CustomTable>
      </div>
    </>
  );
};

const headItems = [
  { label: "ЖК", align: "left", width: 250 },
  { label: "КВ", align: "left" },
  { label: "Этап", align: "left" },
  { label: "По спецификации", align: "left" },
  { label: "Факт", align: "left" },
  { label: "Площадь", align: "left" },
  { label: "Подрядчик", align: "center" }
];
